import {
    SET_PO_ITEM,
    SET_MATERIAL_ITEM, 
    SET_MATERIAL_LEN_QTY, 
    SET_PROJECT_MATERIAL_ID, 
    SET_PROJECT_DATA,
    SET_ESTIMATION_DATA,
    SET_SELECTED_SCOPE, 
    SET_PO_FORM_DATA,
    SET_MATERIAL_FORM_DATA,
    SET_SHOPFAB_FORM_DATA,
    SET_FREIGHT_DEL_FORM_DATA,
    SET_FIELD_INSTALL_FORM_DATA,
    SET_FREIGHT_DELIVERY_ID, 
    SET_LOGIN_USER_DATA,
    SET_SHOPFAB_ERRORS,
    SET_LOCATION_HEATNUMBER,
    SET_JOB_NUMBER,
    SET_PROJECT_NAME,
    SET_FREIGHTDEL_ERRORS,
    SET_FCCREWERRORS,
    SET_FCCREW_ERRORS,
    SET_FIELD_MEASURE,
    SET_CALENDAR_CLICKED_NAME
} from './actionType';

export const setFieldMeasureData=(fieldMeasureFormData)=>({
    type:SET_FIELD_MEASURE,
    fieldMeasureFormData:fieldMeasureFormData
})

export const setPOItemList = (poItemList) =>({
    type:SET_PO_ITEM,
    poItemList:poItemList
});

export const setMaterialItemList = (materialItemList) =>({
    //console.log("HI data",materialItemList);
   // return ({
    type:SET_MATERIAL_ITEM,
    materialItemList:materialItemList
    //})
});

export const setMaterialLenQty = (materialLenQtyList) => ({
    type:SET_MATERIAL_LEN_QTY,
    materialLenQtyList:materialLenQtyList
});

export const setProjectMaterialId = (projectMaterialId) => ({
    type:SET_PROJECT_MATERIAL_ID,
    projectMaterialId:projectMaterialId
});

export const setProjectData = (projectData) => ({
    type:SET_PROJECT_DATA,
    projectData:projectData
});

export const setEstimationData = (estimationData) => ({
    type:SET_ESTIMATION_DATA,
    estimationData:estimationData
});

export const setSelectedScopeItemID = (selectedScopeItemID) => ({
   
    type:SET_SELECTED_SCOPE,
    selectedScopeItemID:selectedScopeItemID
});

export const setPOFormData = (POFormData) => ({
   
    type:SET_PO_FORM_DATA,
    POFormData:POFormData
});

export const setMaterialFormData = (materialFormData) => ({
   
    type:SET_MATERIAL_FORM_DATA,
    materialFormData:materialFormData
});

export const setShopFabFormData = (shopFabFormData) => ({
   
    type:SET_SHOPFAB_FORM_DATA,
    shopFabFormData:shopFabFormData
});

export const setFreightDelFormData = (freightDelFormData) => ({
   
    type:SET_FREIGHT_DEL_FORM_DATA,
    freightDelFormData:freightDelFormData
});

export const setFieldInstallFormData = (fieldInstallFormData) => ({
    type:SET_FIELD_INSTALL_FORM_DATA,
    fieldInstallFormData:fieldInstallFormData
});

export const setFreightDeliveryId = (FreightDeliveryId) => ({
    type:SET_FREIGHT_DELIVERY_ID,
    FreightDeliveryId:FreightDeliveryId
});

export const setLoginUserData = (loginUserData) => ({
    
    type:SET_LOGIN_USER_DATA,
    loginUserData:loginUserData
});

export const setShopFabError = (errors) =>({
    type:SET_SHOPFAB_ERRORS,
    shopFabErrors:errors
});

export const setFreightDelError = (errors) =>({
    type:SET_FREIGHTDEL_ERRORS,
    freightDelErrs:errors
});

export const setFcCrewError = (errors) =>({
    type:SET_FCCREW_ERRORS,
    fcCrewErrs:errors
});


export const setLocationAndHeatNumberData = (locationAndHeatNumber) =>({
    type:SET_LOCATION_HEATNUMBER,
    heatNumberAndLocationList:locationAndHeatNumber
})
export const setJobNumber = (jobNumber) =>({
    
    type:SET_JOB_NUMBER,
    selectedJobNumber:jobNumber
})

export const setProjectName = (projectName) =>({
    
    type:SET_PROJECT_NAME,
    projectName:projectName
})

export const setCalendarName = (calendarName) =>({
    
    type:SET_CALENDAR_CLICKED_NAME,
    calendarName:calendarName
})