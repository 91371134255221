import react, { Component } from 'react';
import { Link } from "react-router-dom";
import masterDataService from '../services/MasterDataService';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import { rules, Permission } from "../../abac.config";
import AccesDenied from '../common/AccessDenied'
import { createNotification } from '../helpers/Notification';
import { getApiUrl } from '../helpers/Util';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ConfirmBox from'../common/ConfirmBox'
 class ShopMaintenanceList extends Component {
    constructor(props) {
        super(props);
        this.state={
            ShopMaintenanceListData:[],
            search: "",
            isShowConfirmBox: false,
            loader: false,
            removeId:"",
            linkedShop:false,
        }
    }
    componentDidMount = () => {
        this.getShopMaintenanceDescriptionList();        
    }

        getShopMaintenanceDescriptionList = async ()=>{
            masterDataService.getShopMaintenanceList()
            .then((response)=>{
                console.log({response})
                if(response.status){
                    let responseData=response.data.data;
                    this.handleshopMaintenanceDescriptionList(responseData);
                }
                console.log({ locationList: response });
            })
            .catch((error)=>{
                console.log({error});
                if(error.response.status === 401){
                    createNotification("error" , "Your login session has been expired!");
                    localStorage.setItem("user-token","");
                    this.props.history.push("/login");
                }else{
                    alert("catch else block list");
                    createNotification("error",error.response.data.error.message);
                }
            });
            
        }

        handleDeleteShop = (rowId)=>{
          this.setState({removeId:rowId,isShowConfirmBox:true})
          masterDataService.checkLinkedShop(rowId)
          .then((response)=>{
            console.log("response",response);
            console.log("response.data.length",response.data.length);
              if(response.data.length > 0 ){
                console.log(" > 0");

                  // createNotification("success", "Shopmaintenance item  deleted successfully");
                  this.setState({linkedShop: true,isShowConfirmBox:true, loader: false });
              }
              else if(response.data.length == 0 ){
                console.log(" 0");
                this.setState({linkedShop: false,isShowConfirmBox:true, loader: false });
              }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              createNotification("error", "Your login session has been expired!");
              localStorage.setItem("user-token", "");
              this.props.history.push("/login");
            } else {
              createNotification("error", error.response.data.error.message);
              this.setState({ removeId: "",isShowConfirmBox:false, loader: false });
            }
          });
        }
        handleConfirmModal=(isYes,id="")=>{
          const{removeId}=this.state;
          
          if(isYes){
              this.setState({
                  isShowConfirmBox:!this.state.isShowConfirmBox,
                  loader:true,
              });
              
              masterDataService.deleteShopMaintenanceItemById(removeId)
              .then((response)=>{
                  if(response.status){
                      createNotification("success", "Shop maintenance item  deleted successfully.");
                      this.setState({removeId: "",isShowConfirmBox:false, loader: false });
                      this.getShopMaintenanceDescriptionList();
                  }
              })
              .catch((error) => {
                if (error.response.status === 401) {
                  createNotification("error", "Your login session has been expired!");
                  localStorage.setItem("user-token", "");
                  this.props.history.push("/login");
                } else {
                  createNotification("error", error.response.data.error.message);
                  this.setState({ removeId: "",isShowConfirmBox:false, loader: false });
                }
              });
          } else {
            this.setState({ removeId: "",isShowConfirmBox:false, loader: false });
          }
        }

        handleshopMaintenanceDescriptionList=(responseData)=>{
            const URL=getApiUrl();
            let listArr =[];
            responseData && 
            responseData.map((item)=>{
                let itemData={...item };
                itemData.role=itemData.role_fkey && itemData.role_fkey.title;
                itemData.status = itemData.status;
              listArr.push(itemData);
            });
            console.log({ listArr });
            this.setState({ShopMaintenanceListData: listArr });
            console.log(this.state.ShopMaintenanceListData);
        };
    
       
        
        actionFormat=(cell,row,rowIndex,formatExtraData)=>{
            return(
                <div>
                    <td>
                      <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                       <Link to={`/shopMaintenance/${row.id}`}>
                           <i className="uil uil-pen font-size-18"></i>
                       </Link>
                      </li>
                      <li className="list-inline-item">
                       <a href="javascript:void(0)" className="text-danger">
                        <i className="uil uil-trash-alt font-size-18" onClick={()=> this.handleDeleteShop(row.id) }></i>
                       </a>
                      </li>
                      </ul>
                    </td>
                </div>
            )
        }
        nameFormat=(cell,row,rowIndex,formatExtraData)=>{
          return(
            <div>
            <a href="#" className="text-body">
              {row.name}
            </a>
          </div>
          )
        }
    
    render() {
        const{ShopMaintenanceListData,isShowConfirmBox}=this.state;
        const handleOnTableChange = (type, newState) =>{
          console.log("type",type);
          if(type === "sort") {
            if(newState.sortOrder == "asc"){
              ShopMaintenanceListData.sort((obj1,obj2) => {
               return  obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
               ;     
            }); 
          }
            else
            {ShopMaintenanceListData.sort((obj2,obj1) => {
                return obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
                ; 
             });
            }
          }
          
      }
           const columns=[{ dataField: "name",sort:true, text: "Name",formatter:this.nameFormat },
                          { dataField: "status", text: "Status",sort:true },
                          {dataField :" " ,text:"Action",formatter:this.actionFormat}];

                          const sizePerPageOptions = [
                            {
                              text: '10',
                              value: 10,
                            },
                            {
                              text: '25',
                              value: 25,
                            },
                            {
                              text: '30',
                              value: 30,
                            },
                            {
                              text: '50',
                              value: 50,
                      
                            },
                            {
                              text: '100',
                              value: 100,
                            },
                            // Add more options as needed
                          ];                

        let roleKey=this.props.loginUserData.roleKey;
        const isAccess=(roleKey !=="" && rules[roleKey][Permission.MASTER_WRITE]) ? true:false;

        return (
            <div>
                {!isAccess ?<AccesDenied/>:
                <div className="main-content">
                      <ConfirmBox openModal={isShowConfirmBox}
                       confirmMsg={this.state.linkedShop ?
                         "Are you sure you want to delete this item ? Deleting this item will delete all corresponding maintenance history related to the item." 
                         :"Are you sure to delete this Item?"}
                       handleConfirmModal={this.handleConfirmModal} 
                       />
                    <div className="page-content">
                      <div className="container-fluid">
                         <div className="row">
                              <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                  <ol className="breadcrumb m-0">
                                    <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                                    &nbsp; / &nbsp;
                                    <Link to={`/ShopMaintenance`}><li className="breadcrumb-item">Shop Maintenance Description</li></Link>
                                  </ol>
                                </div>
                                <h4 className="mb-0">Shop Maintenance Description List </h4>
                              </div>
                            </div>
                            <div className="row">
                             <div className="col-lg-12">
                               <div className="card card-block">
                                <div className="card-body">
                                 <div className="row mb-2">
                                  <div className="col-md-6">
                                   <div className="mb-3">
                                    <Link to="/AddShopMaintenance">         
                                      <button className="btn btn-success waves-effect waves-light">
                                       <i className="uil uil-plus"></i> Add New
                                      </button>
                                    </Link>   
                                  </div>
                            </div>

                            <div className="col-md-6">
                           <div className="form-inline float-md-end mb-3">
                              <div className="search-box ms-2"></div>
                             </div>
                            
                           </div>
                         </div>
                         <BootstrapTable 
                         remote={{
                          pagination:false,
                          sort:true
                         }}
                         keyField="id"
                         data={ShopMaintenanceListData} columns={columns}
                         onTableChange={handleOnTableChange}
                         pagination={paginationFactory({   sizePerPageList:sizePerPageOptions })}  />
                        </div>
                       </div>               
                      </div>
                     </div>    
                    </div>
                  </div>
                </div>
              }
            </div>
        );
    }
}
const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
  };
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
  }, dispatch);

  export default connect(mapStateToProps, mapDispatchToProps)(ShopMaintenanceList);