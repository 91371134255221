import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Link } from "react-router-dom";
import { rules,Permission } from '../../abac.config';
import AccessDenied from '../common/AccessDenied';
import SimpleReactValidator from 'simple-react-validator';
import { createNotification } from '../helpers/Notification';
import masterDataService from '../services/MasterDataService';

// import { connect } from 'react-redux'

class AddTitle extends Component {
  constructor(props){
    super(props);
    this.state={
      name:"",
      button:"Save",
      certiId:"",
      headingText:"Add"
    }
   this.validator=new SimpleReactValidator();
  }
  componentDidMount=()=>{
     const{match}=this.props;
    console.log(match);
  }

handleResetState=()=>{
  this.setState({name:""});
}
  handleOnChange=(event)=>{
      this.setState({name:event.target.value});
  }
  handleSaveTiles=()=>{
    const{name}=this.state;
     const{match}=this.props;
     console.log(match);
    if(this.validator.allValid()){

      let nm=name.trim();
      let payload={
        name:nm.trim(),
      }
        masterDataService.addTitles(payload).then((response)=>{
          if(response.status === 200){
            createNotification("success","Tiles added successfully.");
             this.handleResetState(); 
             this.props.history.push("/title");
          }
           
        })
        .catch((error)=>{
          if (error.response.status === 401) {
            createNotification(
              "error",
              "Your login session has been expired!"
            );
            localStorage.clear();
            this.props.history.push("/login");
          } else {
            createNotification("error", error.response.data.error.message);
          
          }
        })
      
    
  }else{
      this.validator.showMessages();
      this.forceUpdate();
  }
  }
  render() {
    const{name,headingText}=this.state
    let roleKey = this.props.loginUserData.roleKey;
    const isAccess = (roleKey!=="" && rules[roleKey][Permission.MASTER_WRITE]) ?true:false;
    return (
      <div>
        {!isAccess ? <AccessDenied /> : (
          <div className="main-content addUser-form">
            <div className="page-content">
              <div className="container-fluid">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <ol className="breadcrumb m-0">
                    <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                    &nbsp; / &nbsp;
                    <Link to={`/title`}><li className="breadcrumb-item">Title</li></Link>
                    &nbsp; / &nbsp;
                    {/* <Link to={`/addCertificate`}><li className="breadcrumb-item">Add</li></Link> */}
                    
                  {/* remove thisd */}
                          <Link to={`/addTitle`}><li className="breadcrumb-item">Add</li></Link>
                       
                  </ol>
                </div>
                <h4 className="mb-0">{headingText} Title </h4>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-block">
                      <div className="card-body">
                        <div className="wizard clearfix">
                          <div className="content clearfix">
                            <form className="form-project ">
                              <div className="row">
                                <div className="col-md-4 col-sm-12">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      for="validationCustom01"
                                    >
                                      Title{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input type="text"
                                      className="form-control"
                                      id="validationCustom01"
                                      name="name"
                                      onChange={this.handleOnChange}
                                      placeholder="Name"
                                      value={name}
                                      
                                    />
                                    {
                                      this.validator.message(
                                        "name",
                                        this.state.name,
                                        "required|max:70",
                                        { className: "text-danger" }
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="row"></div>
                            </form>
                          </div>
                          <div className="actions clearfix">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={() => this.handleSaveTiles()}
                            >
                              <i className="uil uil-edit"></i>{" "}
                              {this.state.button}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (props) => {
  return {
      loginUserData: props.loginUserData,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddTitle);
