import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { rules, Permission } from "../../abac.config";
import AccessDenied from "../common/AccessDenied";
import SimpleReactValidator from "simple-react-validator";
import { createNotification } from "../helpers/Notification";
import masterDataService from "../services/MasterDataService";

// import { connect } from 'react-redux'

class AddShopDesignation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      designation: "",
      button: "Save",
      headingText: "Add",
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount = () => {
    const { match } = this.props;
    console.log("match--", match);
  };
  handleResetState = () => {
    this.setState({ designation: ""});
  };
  handleOnChange = (event) => {
    if (event.target.name === "designation") {
      this.setState({ designation: event.target.value });
    }
  };
  handleSaveShopDesignation = () => {
    const { designation} = this.state;
    const { match } = this.props;
    console.log(match);
    if (this.validator.allValid()) {
      let nm = designation.trim();
      let payload = {
        designation: nm.trim(),
      };

      masterDataService
        .addShopDesignation(payload)
        .then((response) => {
          if (response.status === 200) {
            createNotification("success", "Shop Designation added successfully.");
            this.handleResetState();
            this.props.history.push("/shopDesignation");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            createNotification("error", "Your login session has been expired!");
            localStorage.clear();
            this.props.history.push("/login");
          } else {
            createNotification("error", error.response.data.error.message);
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    const { designation, headingText } = this.state;
    let roleKey = this.props.loginUserData.roleKey;
    const isAccess =
      roleKey !== "" && rules[roleKey][Permission.MASTER_WRITE] ? true : false;
    return (
      <div>
        {!isAccess ? (
          <AccessDenied />
        ) : (
          <div className="main-content addUser-form">
            <div className="page-content">
              <div className="container-fluid">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <ol className="breadcrumb m-0">
                    <Link to={`/#`}>
                      <li className="breadcrumb-item">Home</li>
                    </Link>
                    &nbsp; / &nbsp;
                    <Link to={`/shopDesignation`}>
                      <li className="breadcrumb-item">Shop Designation</li>
                    </Link>
                    &nbsp; / &nbsp;
                    {/* <Link to={`/addShopDesignation`}><li className="breadcrumb-item">Add</li></Link> */}
                   
                      <Link to={`/addShopDesignation`}>
                        <li className="breadcrumb-item">Add</li>
                      </Link>
                   
                  </ol>
                </div>
                <h4 className="mb-0">{headingText} Shop Designation</h4>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-block">
                      <div className="card-body">
                        <div className="wizard clearfix">
                          <div className="content clearfix">
                            <form className="form-project ">
                              <div className="row">
                                <div className="col-md-4 col-sm-12">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      for="validationCustom01"
                                    >
                                      Shop Designation{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="validationCustom01"
                                      name="designation"
                                      onChange={this.handleOnChange}
                                      placeholder="Designation"
                                      value={designation}
                                    />
                                    {this.validator.message(
                                      "designation",
                                      this.state.designation,
                                      "required|max:70",
                                      { className: "text-danger" }
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row"></div>
                            </form>
                          </div>
                          <div className="actions clearfix">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={() => this.handleSaveShopDesignation()}
                            >
                              <i className="uil uil-edit"></i>{" "}
                              {this.state.button}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddShopDesignation);
