import React, { Component } from 'react'
//importing redux fucntions
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import ProjectInventory from './InventoryComponents/ProjectInventory';
import ManualInventory from './InventoryComponents/ManualInventory';
import AccessDenied from '../../../common/AccessDenied';

class InventoryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopList: [],
      loader: false,
      selectedShopId: 0,
      slocationId: 0,
      rackArray: [],
      inventoryDisplayList: [],
      locationList: [],
      ActiveId: 0,
      selectedRackId: 0,
      isRackOpenPopUp: false,
      isInventoryDeatilsOpenPopUp: false,
      isShowConfirmBox: false,
      counter: 0,
      removeId: 0,
      selectedInventoryId: 0,
      search: "",
      projectInventoryTabflag: false,
      manualInventoryTabFlag: false,

      pageChange: 1,
      skip: 0,
      limit: 10,
      count: 0,
      filterData: `?filter[skip]=0&filter[limit]=10`,
    }
  }
  componentDidMount = () => {
    localStorage.removeItem('shopMaintenancePaginationConstants');
    localStorage.removeItem('fieldMaintenancePaginationConstants')
  }

  handleTabSelected = (key) => {
    if (key === "projectInventory") {

      this.setState({ projectInventoryTabflag: true, manualInventoryTabFlag: false });
    }
    if (key === "manualInventory") {

      this.setState({ manualInventoryTabFlag: true, projectInventoryTabflag: false });
    }
  }

  render() {
    let roleKey = this.props.loginUserData.roleKey;
    const denyInventory = (roleKey !== "" && (roleKey == "fieldmanager" || roleKey == "transport" ||roleKey=="serviceperson")) ? true : false;
    return (
      <>
      {
        !denyInventory ?  <div>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <ol className="breadcrumb m-0">
                  <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                  &nbsp; / &nbsp;
                  <Link to={`/inventory`}><li className="breadcrumb-item">Inventory</li></Link>
                </ol>
              </div>
              <h4 className="mb-0">Inventory</h4>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body project-index">
                      <Tabs
                        defaultActiveKey="projectInventory"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3"
                        onSelect={this.handleTabSelected}
                      >
                        <Tab eventKey="projectInventory" title="Project Inventory">
                          <ProjectInventory projectInventoryTabflag={this.state.projectInventoryTabflag} />
                        </Tab>
                        <Tab eventKey="manualInventory" title="Manual Inventory">
                          <ManualInventory manualInventoryTabFlag={this.state.manualInventoryTabFlag} />
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>:<AccessDenied/>
      }
      </>
     
    )
  }
}
const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InventoryView);
