import React from "react";

export default function AccessDenied() {
  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div style={{ color: "red" }}>Denied</div>
        </div>
      </div>
    </div>
  );
}
