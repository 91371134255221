import React, { Component } from "react";
import { createNotification } from "../helpers/Notification";
import Loader from "../common/Loader";
import {
  checkValidPasswpord,
} from "../helpers/Util";
import SimpleReactValidator from "simple-react-validator";

import { setLoginUserData } from "../project/redux/projectAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import projectServices from "../project/services/projectServices";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      oldPassword: "",
      password: "",
      confirmPassword: "",
      loader: false,
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = () => {
    // this.getLoginUserData();
  };

  handleOnChange = (event) => {
    if (event.target.name === "password") {
      this.setState({ password: event.target.value });
    } else if (event.target.name === "confirmPassword") {
      this.setState({ confirmPassword: event.target.value });
    } else if (event.target.name === "oldPassword") {
      this.setState({ oldPassword: event.target.value });
    }
  };

  changePassword = () => {
    const { oldPassword, password, confirmPassword } = this.state;
    
    if (this.validator.allValid()) {
      if (password !== "" && confirmPassword !== password) {
        createNotification(
          "info",
          "Confirm Password and Password do not match!"
        );
        return false;
      } else if (
        password !== "" &&
        confirmPassword !== "" &&
        !checkValidPasswpord(password)
      ) {
        createNotification(
          "info",
          "Passwords must be 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character!"
        );
        return false;
      }

      let payload = { oldPassword, password, confirmPassword };
      try {
        projectServices
          .updatePasswordByToken(payload)
          .then((res) => {
            if (res.data.status) {
              createNotification("success", "Password changed successfully");
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              createNotification(
                "error",
                "Your login session has been expired!"
              );
              localStorage.clear();
              this.props.history.push("/login");
              this.setState({ loader: false });
            } else {
              createNotification("error", error.response.data.error.message);
              this.setState({ loader: false });
            }
          });
      } catch (error) {
        console.log({ error });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { loader } = this.state;
    
    return (
      <div>
        {loader ? (
          <Loader />
        ) : (
          <div className="main-content addUser-form">
            <div className="page-content">
              <div className="container-fluid">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Change Password</h4>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-block">
                      <div className="card-body">
                        <div className="wizard clearfix">
                          <div className="content clearfix">
                            <form className="form-project ">
                              <div className="row"></div>
                              <div className="row">
                                <div className="col-md-4 col-sm-12">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      for="validationCustom01"
                                    >
                                      Old Password{" "}
                                      <span style={{ color: "red" }}> *</span>
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      value={this.state.oldPasswordname}
                                      name="oldPassword"
                                      onChange={this.handleOnChange}
                                      placeholder="Old Password"
                                      required=""
                                    />
                                    {this.validator.message(
                                      "oldPassword",
                                      this.state.oldPassword,
                                      "required|max:50",
                                      { className: "text-danger" }
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4 col-sm-12">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      for="validationCustom02"
                                    >
                                      New Password 
                                      <span style={{ color: "red" }}> *</span>
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="validationCustom02"
                                      name="password"
                                      onChange={this.handleOnChange}
                                      placeholder="New Password"
                                      required=""
                                    />
                                    {this.validator.message(
                                      "New password",
                                      this.state.password,
                                      "required|max:50",
                                      { className: "text-danger" }
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      for="validationCustom02"
                                    >
                                      Confirm Password 
                                      <span style={{ color: "red" }}> *</span>
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="validationCustom02"
                                      name="confirmPassword"
                                      onChange={this.handleOnChange}
                                      placeholder="Confirm Password"
                                      required=""
                                    />
                                     {this.validator.message(
                                      "confirmPassword",
                                      this.state.confirmPassword,
                                      "required|max:50",
                                      { className: "text-danger" }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="actions clearfix">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={() => this.changePassword()}
                            >
                              <i className="uil uil-save"></i> Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLoginUserData: setLoginUserData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
