import React, { Component } from "react";

class ViewFilesPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    
  }


  render() {
    const { closeModal, fileData: uploadedFiles,fieldMeasureFlag } = this.props;
    console.log(uploadedFiles);
    let detailDrawingDocs
    if(this.props.fieldMeasureFlag){
      detailDrawingDocs=uploadedFiles!==undefined && uploadedFiles.length > 0 ? uploadedFiles:[]
    }else if(this.props.freightDeliveryFlag){
      detailDrawingDocs=uploadedFiles!==undefined && uploadedFiles.length > 0 ? uploadedFiles:[]
    }
    else{
       detailDrawingDocs =
      uploadedFiles.length > 0 ? (uploadedFiles[0].detailDrawingDocs ? uploadedFiles[0].detailDrawingDocs :[]) : [];
    }
    // console.log(detailDrawingDocs);
    
    // let docId = uploadedFiles.length > 0 ? uploadedFiles[0].id : "";
    return (
      <div>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Uploaded Files
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => closeModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-3 accordWrap">
              <div className="col-md-12" style={{height:"450px"  }}>
                {detailDrawingDocs.length <= 0 &&
                  <h3 style={{color:'red', textAlign:'center'}}>
                      No File Uploaded
                  </h3>
                }
                <table style={{ width: "100%" }}>
                  {detailDrawingDocs.length > 0 &&
                    detailDrawingDocs.map((fileData, i) => {
                      let filePath = fileData.upload_fkey?.filePath;
                      let fileName = fileData.upload_fkey?.fileName;
                      let fileId = fileData.id;
                      return (
                        <tr key={i}>
                          <td>
                            <a
                              className="d-inline-block"
                              //href="#"
                              onClick={() => window.open(filePath, "_blank")}
                            >
                              {fileName}
                            </a>
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <a
                              className="btn btn-primary waves-effect waves-light"
                             // href="#"
                              onClick={() => window.open(filePath, "_blank")}
                            >
                              View
                            </a>
                           
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewFilesPopUp;
