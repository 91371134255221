import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useLocation } from "react-router-dom";
import { rules, Permission } from "../../abac.config";
import AccessDenied from "../common/AccessDenied";
import SimpleReactValidator from "simple-react-validator";
import { createNotification } from "../helpers/Notification";
import Editor from "./Editor";
import SmsEditor from "./SmsEditor";
import Loader from "../common/Loader";
import { Button } from "react-bootstrap";
import projectServices from "../project/services/projectServices";
import ModalPopUp from "../common/ModalPopUp";
import EmployeeListPopup from "./EmployeeListPopup";

// import { connect } from 'react-redux'

class SendMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phoneNumber: 0,
      message: "",
      button: "Send",
      headingText: "Send mail and text",
      backLocation: "",
      loader: false,
      isShowEmployeeListPopup: false,
      currentTab: "mail",
      previousPage: 1,
      previousSkip: 0,

      previousLimit: 10,
      previousSearch: ""
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount = () => {
    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    const { match } = this.props;
    console.log(match);
    // check if window history is undefined or not 
    console.log(window.history.state);
    if (window.history.state !== null && window.history.state.state !== undefined) {

      this.setState({
        previousPage: window.history.state.state.data.currentPage,
        previousSkip: window.history.state.state.data.currentSkip,
        previousLimit: window.history.state.state.data.currentLimit,
        previousSearch: window.history.state.state.data.search
      })




    }


    // check from where this component is called - acc to that it will call different api's
    if (window.history.state == null) {

      this.props.history.push("/employeeContact");
    }
    //-------> when it is single
    else if (window.history.state !== null && window.history.state.state !== undefined && window.history.state.state.data.key == "Single") {
      localStorage.setItem('empConPagination', JSON.stringify({ previousPage: window.history.state.state.data.currentPage, previousSkip: window.history.state.state.data.currentSkip, previousLimit: window.history.state.state.data.currentLimit, previousSearch: window.history.state.state.data.search }));

      this.setState({ loader: false, backLocation: "Single" }, () => {
        this.getEmployeeDetail(match.params.Id);
      });
    } else {
      //-------->when it is group
      // groupid is received from group-send mail

      this.handleGroupMail(); // when it is group - no api call , get the id from props.
      this.setState({
        backLocation: "Group",
        headingText: "Send mail and text to group"
      });
      this.getGroupDetail(match.params.Id, filters);
    }
  };
  handleGroupMail = () => {
    const { match } = this.props;

    this.setState({
      email: match.params.Id,
      loader: false,
    });
  };
  getEmployeeDetail = (empId) => {
    projectServices
      .getHumanResourceEmployeeById(empId)
      .then((response) => {
        this.setState({
          name: response.data[0].employee_name,
          email: response.data[0].email_address,
          phoneNumber: response.data[0].phone_number,
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getGroupDetail = (groupId) => {
    projectServices
      .getGroupDetailsById(groupId)
      .then((response) => {
        this.setState({
          name: response.data.name,
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleOnChange = (event) => {
    this.setState({ name: event.target.value });
  };

  sendEmail = (e) => {
    e.preventDefault();
  };

  handleAllEmployee = () => {
    this.setState({ isShowEmployeeListPopup: true });
  };

  handleCloseModal = () => {
    this.setState({ isShowEmployeeListPopup: false });
  };

  handleTabs = (e) => {
    if (e.target.id === "mail") {
      this.setState({ currentTab: "mail" });
    } else {
      // this.getDropdownData();
      this.setState({ currentTab: "sms" });
    }
  };

  render() {
    const {
      name,
      email,
      phoneNumber,
      message,
      headingText,
      loader,
      backLocation,
      isShowEmployeeListPopup,
      currentTab,
      previousPage,
      previousSkip,
      previousLimit,
      previousSearch
    } = this.state;
    let roleKey = this.props.loginUserData.roleKey;

    // console.log("previousPage",window.history.state.state.data.currentPage);
    const isAccess =
      roleKey !== "" && rules[roleKey][Permission.MASTER_WRITE] ? true : false;
    const data = { key: previousPage, keySkip: previousSkip, keyLimit: previousLimit, keySearch: previousSearch };
    return (
      <div>
        {loader === true ? (
          <Loader />
        ) : (
          <div className="main-content addUser-form">
            <ModalPopUp
              componentData={
                <EmployeeListPopup
                  closeModal={this.handleCloseModal}
                  groupId={email}
                />
              }
              openModal={isShowEmployeeListPopup}
            />
            <div className="page-content">
              <div className="container-fluid">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <ol className="breadcrumb m-0">
                    <Link to={`/#`}>
                      <li className="breadcrumb-item">Home</li>
                    </Link>
                    &nbsp; / &nbsp;
                    <Link to={{
                      pathname: `/employeeContact`,
                      state: { data: data },
                    }}>
                      <li className="breadcrumb-item">Employee Contact</li>
                    </Link>
                    &nbsp; / &nbsp;
                    {/* remove thisd */}
                    {backLocation == "Single" ? (
                      <Link to={`/sendMail/${this.props.match.params.Id}`}>
                        <li className="breadcrumb-item">Send mail and text</li>
                      </Link>
                    ) : (
                      <>
                        <Link to={`/allGroupList`}>
                          <li className="breadcrumb-item">Group List</li>
                        </Link>
                        &nbsp; / &nbsp;
                        <Link to={`/sendMail/${this.props.match.params.Id}`}>
                          <li className="breadcrumb-item">
                            Send mail and text
                          </li>
                        </Link>
                      </>
                    )}
                  </ol>
                </div>
                <h4 className="mb-0">{headingText} </h4>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-block">
                      <div className="card-body">
                        <div className="wizard clearfix">
                          <div className="content clearfix">
                            <form className="form-project ">
                              {backLocation == "Group" ? (
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={this.handleAllEmployee}
                                  >
                                    All employees
                                  </button>
                                </div>
                              ) : (
                                <></>
                              )}
                            </form>
                            <div className="mb-4">
                              <ul class="nav nav-tabs">
                                <li class="nav-item">
                                  <a
                                    id="mail"
                                    class={
                                      currentTab == "mail"
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    aria-current="page"
                                    href="#"
                                    onClick={this.handleTabs}
                                  >
                                    Mail
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <a
                                    id="sms"
                                    class={
                                      currentTab == "sms"
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    href="#"
                                    onClick={this.handleTabs}
                                  >
                                    SMS
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {currentTab == "mail" ? (
                              <Editor
                                email={email}
                                backLocation={backLocation}
                                name={name}
                              />
                            )
                              :
                              (
                                <SmsEditor
                                  email={email}
                                  phoneNumber={phoneNumber}
                                  backLocation={backLocation}
                                  name={name}
                                />
                              )
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SendMail);
