import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ConfirmBox from "../common/ConfirmBox";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { rules, Permission } from "../../abac.config";
import SimpleReactValidator from 'simple-react-validator';
import { createNotification } from '../helpers/Notification';
import projectServices from '../project/services/projectServices';
class AddTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headingText:"",
            isShowConfirmBox: false,
            name: "",
            templateText:"",
            button:"Save",
            templateId:0,

        }
        this.validator = new SimpleReactValidator();
    }


    componentDidMount = () => {
        const {match} = this.props;
        console.log(window.history.state.state );
        if(window.history.state.state !== undefined){
            
            localStorage.setItem('templatePaginationConstant',JSON.stringify({previousPage:window.history.state.state.data.pageChange,previousLimit:window.history.state.state.data.limit,
                previousSkip:window.history.state.state.data.skip
                }))
        }
        if(match.path == "/addTemplate/:templateId" && match.params.templateId !== null){
            
            this.setState({
                button:"Edit",
                templateId:match.params.templateId,
            })
            console.log('path',match.params.templateId);
            this.getTemplateById(match.params.templateId);

        }

    }
    getTemplateById = (id) =>{
        
        projectServices
        .getTemplateDetailsById(id)
        .then((response) => {
          if (response.status === 200) {
            console.log("respose",response);
            this.setState({
                name:response.data.subject,
                templateText:response.data.content,
                loader:false,
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    handleOnChange = (event) => {
        if (event.target.name === "name") {
            this.setState({ name: event.target.value, lengthFlag: false, inputErrorFlag: false });
        } else if (event.target.name === "templateText") {
            this.setState({ templateText: event.target.value });
        }
    };

    handleSaveTemplate = () => {
        const { name,templateText,button,templateId } = this.state;
        let payload = { 
            subject :name,
            content:templateText
        } 

          if (this.validator.allValid()) {
            if(button == 'Save') {
                projectServices
                .saveTemplate( payload)
                .then((response) => {
                  if (response.status === 200) {
                    createNotification("success", "Template saved successfully!");
                    this.props.history.push("/templateList");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
                projectServices
                .editTemplate(templateId,payload)
                .then((response) => {
                  if (response.status === 204) {
                    createNotification("success", "Template edited successfully!");
                    this.props.history.push("/templateList");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            this.validator.showMessages();
            this.forceUpdate();
          }


        
        
    }

    render() {
        const { loader, isShowConfirmBox,templateText,button,templateId } = this.state;
        let roleKey = this.props.loginUserData.roleKey;
        const isAccess = (roleKey !== "" && rules[roleKey][Permission.MASTER_WRITE]) ? true : false;

        return (
            <div>
                {/* <AllowedTo
            perform={Permission.MASTER_WRITE}
            no={() =><AccessDenied />}
        > */}
                {/* {!isAccess ? <AccessDenied />  : ( */}
                <div className="main-content addUser-form">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <ol className="breadcrumb m-0">
                                    <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                                    &nbsp; / &nbsp;
                                    <Link to={`/employeeContact`}><li className="breadcrumb-item">Employee Contact </li></Link>
                                    &nbsp; / &nbsp;
                                     <Link to={`/templateList`}><li className="breadcrumb-item">Template</li></Link>
                                    &nbsp; / &nbsp;
                                 
                          {    this.props.match.path == `/addTemplate/:templateId`
                           ? 
                           (<Link to={`/addTemplate/${templateId}`}><li className="breadcrumb-item">{button}</li></Link> )
                           :
                           (<Link to={`/addTemplate`}><li className="breadcrumb-item">{button}</li></Link>) }
                                      </ol>
                            </div>
                            <h4 className="mb-0">{this.state.headingText} Template</h4>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card card-block">
                                        <div className="card-body">
                                            <div className="wizard clearfix">
                                                <div className="content clearfix">
                                                    <form className="form-project ">
                                                        <div className="row">
                                                            <div className="col-md-8 col-sm-12">
                                                                <div className="mb-3">
                                                                    <label
                                                                        className="form-label"
                                                                        for="validationCustom01"
                                                                    >
                                                                        Name{" "}
                                                                        <span style={{ color: "red" }}>*</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="validationCustom01"
                                                                        name="name"
                                                                        onChange={(e) => this.handleOnChange(e)}
                                                                        placeholder="Name"
                                                                        required=""
                                                                        value={this.state.name}
                                                                    />
                                                                    {this.validator.message(
                                                                        "name",
                                                                        this.state.name,
                                                                        "required|max:70",
                                                                        { className: "text-danger" }
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-10 col-sm-12">
                                                                <div className="mb-3">
                                                                    <div className="mb-3">
                                                                        <label
                                                                            className="form-label"
                                                                            for="validationCustom01"
                                                                        >
                                                                            Message{" "}
                                                                            <span style={{ color: "red" }}>*</span>
                                                                        </label>
                                                                        <textarea
                                                                            placeholder="Type here"
                                                                            required=""
                                                                            name="templateText"
                                                                            className="form-control"
                                                                            rows="3"
                                                                            onChange={(e) => this.handleOnChange(e)}
                                                                            value={templateText}
                                                                            maxLength="700"
                                                                            // disabled={isDisable}
                                                                        />
                                                                        {this.validator.message(
                                                                            "Message",
                                                                            this.state.templateText,
                                                                            "required|max:700",
                                                                            { className: "text-danger" }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row"></div>
                                                    </form>
                                                </div>
                                                <div className="actions clearfix">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary waves-effect waves-light"
                                                        onClick={() => this.handleSaveTemplate()}
                                                    >
                                                        <i className="uil uil-edit"></i>{" "}
                                                        {this.state.button}
                                                    </button>
                                                </div>
                                                <ConfirmBox
                                                    openModal={isShowConfirmBox}
                                                    confirmMsg="This entity might being used in one or more projects. Inactivating will remove it from there. Do you still want to continue?"
                                                    handleConfirmModal={this.handleConfirmModal}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* )} */}
                {/* </AllowedTo> */}
            </div>
        )
    }
}
const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplate);
