import { useState, useEffect } from "react";
import { createNotification } from "../helpers/Notification";
import { rules, Permission } from "../../abac.config";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import projectServices from "../project/services/projectServices";
import SimpleReactValidator from "simple-react-validator";
import { setFreightDelFormData } from "../project/redux/projectAction";
import { useRef } from "react";
const AddTransportItem = (props) => {
    const [transportItem, setTransportItem] = useState({
        quantity: "",
        mark: "",
        weight: "",
        profile: "",
        itemLoaded: false,
        itemReceived: false,
    });
    const [headingData, setHeadingData] = useState({
        popUpheading: "Add Item",
        buttonText: "Add Item",
    })

    const simpleValidator = useRef(new SimpleReactValidator())
    const [, forceUpdate] = useState();

    const [validator] = useState(new SimpleReactValidator());

    const [isSaving, setIsSaving] = useState(false);

    // Handle form input changes
    const handleOnChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value.trim();
        setTransportItem({ ...transportItem, [name]: newValue });

    };




    useEffect(() => {
        // Your API call logic here using debouncedTransportItem

        if (props.projectFreightDeliveryId && props.itemId && props.projectFreightDeliveryId != 0) {
            getFreightDeliverySingleItem(props.projectFreightDeliveryId, props.itemId);
            setHeadingData({ ...headingData, popUpheading: "Update Item", buttonText: "Update Item" })
        }

    }, []); // Only include transportItem in the dependency array

    const getFreightDeliverySingleItem = (projectFreightDeliveryId, itemId) => {
        projectServices.getFreightDelSingleItem(projectFreightDeliveryId, itemId).then((response) => {

            let resData = response.data.data
            setTransportItem({
                ...transportItem,
                quantity: (resData.quantity !== null && resData.quantity !== "") ? resData.quantity : "",
                mark: (resData.mark !== null && resData.mark !== "") ? resData.mark : "",
                weight: (resData.weight !== null && resData.weight !== "") ? resData.weight : "",
                profile: (resData.profile !== null && resData.profile !== "") ? resData.profile : "",
                itemLoaded: (resData.itemLoaded !== null) ? resData.itemLoaded : false,
                itemReceived: (resData.itemReceived !== null) ? resData.itemReceived : false,
            })
        })
            .catch((error) => {
                console.log(error);
            })
    }

    // Handle save action
    const handleOnSave = () => {
        const formValid = simpleValidator.current.allValid()
        // if (validator.allValid()) {
        if (formValid) {
            // if (props.projectFreightDeliveryId && props.itemId && props.projectFreightDeliveryId != 0) {
            //     props.updateSingleTransportItem(transportItem, props.projectFreightDeliveryId, props.itemId)
            // } else {
                props.handleSaveTransportItem(transportItem)
            // }
        } else {

            simpleValidator.current.showMessages()
            forceUpdate(1)
            // validator.showMessages();

            // validator.forceUpdate();

        }
    };

    return (
        <div>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">{headingData.popUpheading}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.closeModal()}>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="mb-3 ">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label className="form-label">Mark</label>
                                    <input className="form-control" type="text" name="mark" onChange={(e) => handleOnChange(e)} value={transportItem.mark} />
                                    {simpleValidator.current.message('mark',transportItem.mark,  'required', { className: 'text-danger' })}
                                    
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label className="form-label">Quantity</label>
                                    <input className="form-control" type="text" name="quantity" onChange={(e) => handleOnChange(e)} value={transportItem.quantity} 
                                     
                                     />

                                    {simpleValidator.current.message('quantity', transportItem.quantity,  'required|max:100', { className: 'text-danger' })}
                                    
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label className="form-label">Weight</label>
                                    <input className="form-control" type="text" name="weight" value={transportItem.weight} onChange={(e) => handleOnChange(e)} />
                                    {simpleValidator.current.message('weight', transportItem.weight,  'required|max:100', { className: 'text-danger' })}
                                    
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label className="form-label">Profile</label>
                                    <input className="form-control" type="text" name="profile"
                                        value={transportItem.profile}
                                        onChange={(e) => handleOnChange(e)} />
                                    {simpleValidator.current.message('profile', transportItem.profile, 'required|max:700', { className: 'text-danger' })}
                                    
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                                <div className="mb-3">
                                    <label className="form-label mx-2">Item Loaded</label>
                                    <input className="form-check-input  mx-2" type="checkbox" name="itemLoaded"
                                        checked={transportItem.itemLoaded}
                                        onChange={(e) => handleOnChange(e)} />

                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="mb-3">
                                    <label className="form-label mx-2">Item Received</label>
                                    <input className="form-check-input  mx-2" type="checkbox" name="itemReceived"
                                        checked={transportItem.itemReceived}
                                        onChange={(e) => handleOnChange(e)} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex align-items-center justify-content-center">
                    <button type="button" className="btn btn-primary waves-effect waves-light" onClick={handleOnSave}><i className="uil uil-plus"></i> {headingData.buttonText}</button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (props) => {
    // mapStateToProps logic here
    return {
        loginUserData: props.loginUserData,
    }
};

// const mapDispatchToProps = (dispatch) => {
//     // mapDispatchToProps logic here
//     bindActionCreators({
//         setFreightDelFormData: setFreightDelFormData,
//     },dispatch)
// };
const mapDispatchToProps = (dispatch) => {
    return {
        setFreightDelFormData: bindActionCreators(setFreightDelFormData, dispatch),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AddTransportItem)