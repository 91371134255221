import React, { useRef, useEffect, useState } from 'react';
import { isTablet, isMobile, isDesktop } from 'react-device-detect'; // Importing isTablet and isMobile from react-device-detect

const CameraCapture = (props) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [images, setImages] = useState([]);
  const [devices, setDevices] = useState([]); 
  const [selectedDeviceId, setSelectedDeviceId] = useState(''); 
  const [isFullScreen, setIsFullScreen] = useState(false); // State to manage fullscreen mode

 
  const isCompatibleDevice = isTablet;

  useEffect(() => {
    if (!isCompatibleDevice) return;

    const getCameraDevices = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        stream.getTracks().forEach(track => track.stop());

        const devices = await navigator.mediaDevices.enumerateDevices(); // Enumerate all media devices
        const videoDevices = devices.filter(device => device.kind === 'videoinput'); // Filter out video input devices

        setDevices(videoDevices); // Set the list of devices
        if (videoDevices.length > 0) {
          setSelectedDeviceId(videoDevices[0].deviceId); // Set the default camera to the first one
        }
      } catch (err) {
        console.error("Error fetching devices or accessing camera: ", err);
        // alert("Camera access is required to use this feature. Please check your browser settings.");
      }
    };

    getCameraDevices();
  }, [isCompatibleDevice]);

  useEffect(() => {
    if (!isCompatibleDevice) return;

    const getCameraStream = async () => {
      if (selectedDeviceId) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: { exact: selectedDeviceId } },
          });
          if (videoRef.current) {
            videoRef.current.srcObject = stream; // Assign the stream to the video element
          }
        } catch (err) {
          console.error("Error accessing the camera: ", err);
        }
      }
    };

    getCameraStream();

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        let stream = videoRef.current.srcObject;
        let tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
      }
    };
  }, [selectedDeviceId, isCompatibleDevice]);

  const captureImage = () => {
    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
    const imageDataUrl = canvasRef.current.toDataURL('image/png');
    setImages(prevImages => [...prevImages, imageDataUrl]);
  };

  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleSaveImage = () => {
    if(images.length>0){
      props.handleSaveImages(images)
    }
    

  }

  if (!isCompatibleDevice) {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between',padding:'20px', alignItems: 'center' }}>
          <h4 className='text-center'>This feature is only available on tablet device.</h4>
          <button
            type="button"
            className="btn-close"
            style={{ cursor: 'pointer' }}
            aria-label="Close"
            onClick={() => props.closeModal(false)}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* Container for the select and heading */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', padding: '5px 10px', borderBottom: '1px solid #e5e5e5' }}>

        <h2 className='text-center' style={{ flexGrow: 1, textAlign: 'center', marginBottom: '0px' }}>Camera Pop-up</h2>
        <button
          type="button"
          className="btn-close"
          style={{ cursor: 'pointer' }}
          aria-label="Close"
          onClick={() => props.closeModal(false)}
        />
      </div>

      <div style={{ padding: '0px 10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label style={{ marginRight: '10px', fontSize: '16px' }}>Select Camera: </label>
          <select className="form-select w-200" onChange={(e) => setSelectedDeviceId(e.target.value)} value={selectedDeviceId}>
            {devices.map((device, index) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label || `Camera ${index + 1}`}
              </option>
            ))}
          </select>
        </div>
        <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', marginTop: '10px', }}>
          <video ref={videoRef} autoPlay style={{ width: '100%', border: "2px solid", margin: '0 auto', width: '80%' }} />
        </div>
        {/* <video ref={videoRef} autoPlay style={{ width: '100%', maxHeight: '300px' }} /> */}
        {/* capture image  */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <button onClick={captureImage} style={{ fontSize: '16px' }} className="btn btn-primary view-files-btn mt-1 mx-2">
            Capture Image
          </button>
        </div>
        <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480" />

        <div className="mt-2">
          <h3>Captured Images</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {images.map((image, index) => (
              <div key={index} style={{ position: 'relative', margin: '10px' }}>
                <img src={image} alt={`Captured ${index}`} style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                <button
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    background: 'red',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  onClick={() => removeImage(index)}
                >
                  ✕
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="actions clearfix saveBtn text-center">
        <button
          style={{ width: "150px" }}
          role="menuitem"
          className="btn btn-primary waves-effect waves-light"
          onClick={handleSaveImage}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default CameraCapture;
