//----------------IMPORT SECTION STARTS HERE------------------

//React Parent Component------------------------------------
import React, { Component } from "react";

// All API Calls handled here-------------------------------
import projectServices from "../../../services/projectServices";

// Loader for component grid before data rendering----------
import Loader from "../../../../common/Loader";

// Notification Message-------------------------------------
import { createNotification } from "../../../../helpers/Notification";
import { Link } from "react-router-dom";

//----------------IMPORT SECTION ENDS HERE------------------

class ShopMaintenanceHistoryDetail extends Component {
    constructor(props){
        super(props);
        let details = this.props.location;
        this.state = {
            itemName:details.state.name,
            maintenanceShopMasterId:(details.state ? details.state.maintenanceShopMasterId:''),
            itemID:details.state.maintenanceShopMasterId,
            historyID:details.state.id,
            historyDetail:[],
            loader:false,
            ShopMaintenanceMasterData:[],
            desCheckbox:[],
        }
    }

//--------------------------------------------------------------------------//    

    componentDidMount = () => {
        this.getShopMaintenanceMasterData();
        this.getShopHistoryDetails(this.state.itemID,this.state.historyID);
    }

//--------------------------------------------------------------------------//

    // Get all the list for Description from Master table-------
    getShopMaintenanceMasterData = () => {
        this.setState({loader:true});
        projectServices.getShopMaintenanceMasterData().then((response)=>{
            this.setState({ShopMaintenanceMasterData:response.data.data,loader:false});
        }).catch((error) => {
            if (error.response.status === 401) {
                createNotification("error", "Your login session has been expired!!");
                localStorage.setItem('user-token', "");
                this.props.history.push('/login');
            } else {
                createNotification("error", error.response.data.error.message);
            }
            console.log(error);
        })
    }

//------------------------------------------------------------------------------//    

    getShopHistoryDetails = (itemID,historyID) => {
        this.setState({loader:true});
        projectServices.getShopHistoryDetails(itemID,historyID).then((response) => {
            this.setState({historyDetail:response.data.data,loader:false});
            let checkedValue = [];
            checkedValue = this.state.historyDetail[0].maintenanceShopDataHistories.map((itemVal) => {
                return itemVal.fkey_maintenanceShopMaintenanceType.id;
            })
            this.setState({desCheckbox:checkedValue});
        }).catch((error)=>{
            console.log(error);
        })
    }

//--------------------------------------------------------------------------------------------//
    
    render(){
        
        const {historyDetail,loader,ShopMaintenanceMasterData,desCheckbox,itemName} = this.state;
        ShopMaintenanceMasterData.sort((a, b) => (a.id > b.id) ? 1 : -1)
        
        return(
            <>
            {historyDetail.length !=0 ? (
                <div className="main-content shopPersonal">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <ol className="breadcrumb m-0">
                                    <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                                    &nbsp; / &nbsp;
                                    <Link to={`/maintenance`}><li className="breadcrumb-item">Shop Maintenance</li></Link>    
                                    &nbsp; / &nbsp;
                                    <a href={`/maintenance/history`} onClick={() => {this.props.history.push({pathname: '/maintenance/history',state: {itemID:this.state.itemID,itemName:this.state.itemName}})}}>History</a>
                                    &nbsp; / &nbsp;
                                    <Link to={`/maintenance/history/details`}><li className="breadcrumb-item">Details</li></Link>
                                </ol>
                            </div>
                            <h4 className="mb-0 text-left">Service History Details<i className="uil uil-angle-right-b"></i>{itemName}</h4>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="project-scope-block">
                                                <div className="">
                                                    <div className="col-md-12 pt-3 pb-2">
                                                        {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                            <div class="fixTableHead">
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Description</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        ShopMaintenanceMasterData.map((dta) =>
                                                                            <div class="tableCell">{dta.name}</div>
                                                                        )
                                                                    )}
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Status</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        ShopMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                {(desCheckbox.indexOf(dta.id) > -1) ? 
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        name="desCheckbox" 
                                                                                        checked
                                                                                        disabled
                                                                                    />
                                                                                ) 
                                                                                :
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        name="desCheckbox" 
                                                                                        disabled
                                                                                    />
                                                                                ) 
                                                                                }
                                                                                    
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                            </div>
                                                        {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <label className="col-md-4">Comments</label>
                                                            <textarea readOnly type="text" className="form-control col-md-8" placeholder="Comments..." name="comments" value={historyDetail[0].comments}/>
                                                        </div>
                                                    </div><br/>
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <label className="col-md-10">Serviced by</label>
                                                            <input readOnly type="text" autoComplete="off" className="form-control col-md-2" name="serviceBy" placeholder="Service person name" value={historyDetail[0].serviceBy}/>
                                                        </div>
                                                        <div className="col-md-10"></div>
                                                    </div><br/>
                                                    <div className="text-center">
                                                        <button className="btn btn-primary" onClick={() => {this.props.history.push({pathname: '/maintenance/history',state: {itemID:this.state.itemID,itemName:this.state.itemName}})}}>Back</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
                :
                (<></>)
                }
            </>
        );
    }
}

export default ShopMaintenanceHistoryDetail;