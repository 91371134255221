//----------------IMPORT SECTION STARTS HERE------------------

//React Parent Component------------------------------------
import React, { Component } from "react";

// All API Calls handled here-------------------------------
import projectServices from "../../../services/projectServices";

// Loader for component grid before data rendering----------
import Loader from "../../../../common/Loader";

// Notification Message-------------------------------------
import { createNotification } from "../../../../helpers/Notification";
import { Link } from "react-router-dom";

//----------------IMPORT SECTION ENDS HERE------------------

class FieldMaintenanceHistoryDetail extends Component {
    constructor(props){
        super(props);
        let details = this.props.location;
        this.state = {
            itemName:details.state.name,
            itemID:details.state.id,
            historyID:details.search.replace('?',''),
            historyDetail:[],
            loader:false,
            FieldMaintenanceMasterData:[],
            checked_n_good:[],
            needs_future_attention:[],
            changed_required:[],
        }
    }

//--------------------------------------------------------------------------//    

    componentDidMount = () => {
        this.getFieldMaintenanceMasterData();
        this.getFieldHistoryDetails(this.state.itemID,this.state.historyID);
    }

//--------------------------------------------------------------------------//
    
    // Get all the list for Description from Master table-------
    getFieldMaintenanceMasterData = () => {
        this.setState({loader:true});
        projectServices.getFieldMaintenanceMasterData().then((response)=>{
            this.setState({FieldMaintenanceMasterData:response.data.data,loader:false});
        }).catch((error) => {
            if (error.response.status === 401) {
                createNotification("error", "Your login session has been expired!!");
                localStorage.setItem('user-token', "");
                this.props.history.push('/login');
            } else {
                createNotification("error", error.response.data.error.message);
            }
            console.log(error);
        })
    }

//------------------------------------------------------------------------------//    

    getFieldHistoryDetails = (itemID,historyID) => {
        this.setState({loader:true});
        projectServices.getFieldHistoryDetails(itemID,historyID).then((response) => {
            this.setState({historyDetail:response.data.data,loader:false});
            let checkedVal_forCheckedAndGood = [];
            checkedVal_forCheckedAndGood = this.state.historyDetail[0].maintenanceFieldDataHistories.map((item) =>{
                if(item.checkAndGood === true){
                        return item.maintenanceFieldMaintenanceTypeId;
                }
            })
           
            let checkedVal_forNeedFurther    = [];
            checkedVal_forNeedFurther = this.state.historyDetail[0].maintenanceFieldDataHistories.map((item) =>{
                if(item.needFurther === true){
                    return item.maintenanceFieldMaintenanceTypeId;
                }
            })
            
            let checkedVal_forChangeRequired = [];
            checkedVal_forChangeRequired = this.state.historyDetail[0].maintenanceFieldDataHistories.map((item) =>{
                if(item.changeRequired ===  true){
                    return item.maintenanceFieldMaintenanceTypeId;
                }
            })    
            this.setState({
                            checked_n_good        : [...new Set(checkedVal_forCheckedAndGood.filter(e => e != null).sort())],
                            needs_future_attention: [...new Set(checkedVal_forNeedFurther.filter(e => e != null).sort())],
                            changed_required      : [...new Set(checkedVal_forChangeRequired.filter(e => e != null).sort())]
                        })
        }).catch((error)=>{
            console.log(error);
        })
    }

//--------------------------------------------------------------------------------------------//
    
    render(){
        
        const {historyID,historyDetail,loader,FieldMaintenanceMasterData,itemName} = this.state;
        FieldMaintenanceMasterData.sort((a, b) => (a.id > b.id) ? 1 : -1)
        
        return(
            <>
            {historyDetail.length !=0 ? (
                <div className="main-content shopPersonal">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <ol className="breadcrumb m-0">
                                    <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                                    &nbsp; / &nbsp;
                                    <a href={`/maintenance`} onClick={() => {this.props.history.push({pathname: '/maintenance',state: {statusFlag:true}})}}><li className="breadcrumb-item">Field Maintenance</li></a>    
                                    &nbsp; / &nbsp;
                                    <a href={`/maintenance/field/history`} onClick={() => {this.props.history.push({pathname: '/maintenance/field/history',state: this.props.location.state})}}>History</a>
                                    &nbsp; / &nbsp;
                                    <a href={`/maintenance/field/history/details?${historyID}`} onClick={() => {this.props.history.push({pathname:'/maintenance/field/history/details',state:this.props.location.state,search:`${historyID}`})}}><li className="breadcrumb-item">Details</li></a>
                                </ol>
                            </div>
                            <h4 className="mb-0 text-left">Service History Details<i className="uil uil-angle-right-b"></i>{itemName}</h4>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="project-scope-block">
                                                <div className="">
                                                    <div className="col-md-12 pt-3 pb-2">
                                                        {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                            <div class="fixTableHead maintenanceField">
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Description</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta) =>
                                                                            <div class="tableCell">{dta.name}</div>
                                                                        )
                                                                    )}
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Checked and good</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                {(this.state.checked_n_good.indexOf(dta.id) > -1) ? 
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        checked
                                                                                        disabled
                                                                                    />
                                                                                ) 
                                                                                :
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        disabled
                                                                                        />
                                                                                ) 
                                                                                }
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Needs future attention</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                {(this.state.needs_future_attention.indexOf(dta.id) > -1) ? 
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        checked
                                                                                        disabled
                                                                                    />
                                                                                ) 
                                                                                :
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        disabled
                                                                                     />
                                                                                ) 
                                                                                }
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Changed Required</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                {(this.state.changed_required.indexOf(dta.id) > -1) ? 
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        checked
                                                                                        disabled
                                                                                    />
                                                                                ) 
                                                                                :
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        disabled   
                                                                                    />
                                                                                ) 
                                                                                }
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                            </div>
                                                        {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <label className="col-md-4">Comments</label>
                                                            <textarea readOnly type="text" className="form-control col-md-8" placeholder="Comments..." name="comments" value={historyDetail[0].comments}/>
                                                        </div>
                                                    </div><br/>
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <label className="col-md-10">Serviced by</label>
                                                            <input readOnly type="text" autoComplete="off" className="form-control col-md-2" name="serviceBy" placeholder="Service person name" value={historyDetail[0].serviceBy}/>
                                                        </div>
                                                        <div className="col-md-10"></div>
                                                    </div><br/>
                                                    <div  className="text-center">
                                                       
                                                        <button className="btn btn-primary " onClick={() => {this.props.history.push({pathname: '/maintenance/field/history',state: this.props.location.state})}}>Back</button>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
                :
                (<></>)
                }
            </>
        );
    }
}

export default FieldMaintenanceHistoryDetail;