import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from '../../common/Loader';
import { rules, Permission } from "../../../abac.config";
import { AllowedTo } from "react-abac";
import projectServices from "../../project/services/projectServices";
import DatePicker from "react-datepicker";
import { dbDateFormat } from "../../helpers/Util";
import {
	setMaterialItemList,
	setProjectMaterialId,
	setMaterialLenQty,
	setPOFormData,
	setMaterialFormData,
} from "../redux/projectAction";
import moment from "moment";
import { createNotification } from "../../helpers/Notification";
import { Link } from "react-router-dom";


class updateRecievingDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projectData: [],
			materialItem: [],
			locationList: [],
			vendorList: [],
			gradeList: [],
			poList: [],
			jobNumber: 0,
			projectTitle: "",
			location:0,
			projectId: 0,
			selectedScope: 0,
			vendor: 0,
			po: "",
			transmittal: "",
			drawing: "",
			heatNumber: "",
			gradeId: 0,
			gradeName: "",
			projectPoMaterialsId: 0,
			receivedBy: "",
			comments: "",
			dateOrdered: "",
			dateExpected: "",
			dateReceived: "",
			material: 0,
			MaterialId: 0,
			materialName: "",
			recievindId: null,
			materialData: [],
			Loader: false,
			projectItemName: '',
			ScopeName: '',
			loader: false,
			poNumber: "",
			materialRowId: 0,
			previousPage:1,
			previousSkip:0,
			previousLimit:10,
			previousVendorId:0,
			previousPoNumber:0,
			previousProjectId:0,
			initialState: {
				projectMaterialId: 0,
				dateOrdered: "",
				dateExpected: "",
				dateReceived: "",
				po: "",
				transmittal: "",
				drawing: "",
				comments: "",
				vendor: "",
				location: "",
				receivedBy: "",
				material: 0,
			},
		};
	}
	handleResetFormState = () => {
		this.setState({
			location: 0,
			vendor: 0,
			po: "",
			transmittal: "",
			drawing: "",
			receivedBy: "",
			comments: "",
			dateOrdered: "",
			dateExpected: "",
			dateReceived: "",
			material: 0,
			projectMaterialId: 0,
			location: 0,
		});
	};

	componentDidMount = () => {

		const { match } = this.props;
		// const data = this.props.location.state;
		const data = window.history.state.state.data.row;
		console.log("data",window.history.state.state.data);

		// console.log({ recievingData: data });

		if (data !== undefined && data.ponumber_fkey !== undefined && data.ponumber_fkey.project_fkey.id !== undefined && data.ponumber_fkey.scopeItemId !== undefined) {
			const projid = data.ponumber_fkey.project_fkey.id;
			const scid = data.ponumber_fkey.scopeItemId;
			const jno = data.ponumber_fkey.project_fkey.jobNumber;
			const ptitle = data.ponumber_fkey.project_fkey.title;
			const poId = data.ponumber_fkey.poId ? data.ponumber_fkey.poId : 0;
			const projItem = data.projectItem
			const poNum = data.poNumber;
			const projscope = data.projectScope;
			const rowId = data.id ? data.id : 0;

			if(window.history.state.state !== undefined){
			console.log("data currentVendorList",window.history.state.state.data);
				this.setState({
				previousPage:window.history.state.state.data.currentPage,
				previousSkip:window.history.state.state.data.currentSkip,
				previousLimit:window.history.state.state.data.currentLimit,
				previousVendorId:window.history.state.state.data.currentVendorId,
				previousPoNumber:window.history.state.state.data.currentPoNumber,
				previousProjectId:window.history.state.state.data.currentProjectId,
				})
			}
			if (match.path === "/displayrecieving/:recievingId" && match.params.recievingId !== null) {
				
				if (projid && scid)
					this.setState({ projectId: projid, selectedScope: scid, jobNumber: jno, projectTitle: ptitle, projectItemName: projItem, ScopeName: projscope, projectPoMaterialsId: poId, poNumber: poNum, materialRowId: rowId }, () => {
						this.updateMaterialData();
					});
				this.setState({
					projectId: projid,
					selectedScope: scid
				});
			}
		} else {
			localStorage.setItem("user-token", "");
			this.props.history.push("/receiving");
		}


	}
	updateMaterialData = () => {

		this.getProjectPo();
		this.props.setMaterialLenQty([]);
		this.getMaterialLenAndQty(this.state.materialRowId)
		this.getLocationList();
		this.getVendorsList();
		this.getGradeList()
	}

// get po records of the project based on their scope id -------------------------------

	getProjectPo = () => {
		const { projectId, selectedScope, projectPoMaterialsId } = this.state;
		const { setPOFormData, POFormData } = this.props;
		let POFormDataClone = { ...POFormData };
		if (projectId !== 0) {
			projectServices.getProjectPoData(projectId, selectedScope, projectPoMaterialsId).then((res) => {
				let resdata = res.data.data
				this.setState({
					poList: res.data.data,
					dateOrdered: resdata.orderedDate !== null ? new Date(moment(resdata.orderedDate).format("MM/DD/YYYY")) : "",
					dateExpected: resdata.expectedDate !== null ? new Date(moment(resdata.expectedDate).format("MM/DD/YYYY")) : "",
					dateReceived: resdata.receivedDate !== null ? new Date(moment(resdata.receivedDate).format("MM/DD/YYYY")) : "",
					vendor: Number(resdata.vendor),
					comments: resdata.comments,
					transmittal: resdata.transmittal,
					receivedBy: resdata.receivedBy
				})
				setPOFormData({
					...POFormDataClone,
					dateOrdered: resdata.orderedDate !== null ? new Date(moment(resdata.orderedDate).format("MM/DD/YYYY")) : "",
					dateExpected: resdata.expectedDate !== null ? new Date(moment(resdata.expectedDate).format("MM/DD/YYYY")) : "",
					dateReceived: resdata.receivedDate !== null ? new Date(moment(resdata.receivedDate).format("MM/DD/YYYY")) : "",
					po: resdata.id !== null ? resdata.id : "",
					poNumber: resdata.poNumber !== null ? resdata.poNumber : "",
					transmittal: resdata.transmittal !== null ? resdata.transmittal : "",
					comments: resdata.comments !== null ? resdata.comments : "",
					vendor: resdata.vendor !== null ? Number(resdata.vendor) : "",
					receivedBy: resdata.receivedBy !== null ? resdata.receivedBy : "",
				});
				

			})
				.catch((error) => {
					//console.log({ error });
					if (error.response && error.response.status === 401) {
						createNotification("error", "Your login session has been expired!");
						localStorage.setItem("user-token", "");
						this.props.history.push("/login");
					} else {
						createNotification("error", error.response.data.error.message);
					}
				});
		}



	}


	getLocationList = () => {
		projectServices.getLocation().then((res) => {
			this.setState({ locationList: res.data.data });
		}).catch((error) => {
			console.log(error);
		});
	}

	getVendorsList = () => {
		projectServices.getVendors().then((res) => {
			this.setState({ vendorList: res.data.data });
		}).catch((error) => {
			console.log(error);
		});
	}

	getGradeList = () => {
		projectServices.getActiveGrade().then((res) => {
			this.setState({ gradeList: res.data.data });
		}).catch((error) => {
			console.log(error);
		});
	}

	getMaterialLenAndQty = (id) => {
		projectServices.getSingleMaterialLenAndQty(id).then((res) => {
			let data=res.data.data
			this.setState({heatNumber:data[0].heatNumber!==null?data[0].heatNumber:"",location:data[0].location!==null?data[0].location:0})
			this.props.setMaterialLenQty(res.data.data);
		}).catch((error) => {
			console.log(error);
		});
	};

	handlePOChange = (event) => {
		const { setPOFormData, POFormData } = this.props;
		let POFormDataClone = { ...POFormData };

		if (event.target.name === "vendor") {
			this.setState({ vendor: event.target.value });
			setPOFormData({ ...POFormDataClone, vendor: event.target.value });
		} else if (event.target.name === "transmittal") {
			this.setState({ transmittal: event.target.value });
			setPOFormData({ ...POFormDataClone, transmittal: event.target.value });
		} else if (event.target.name === "comments") {
			this.setState({ comments: event.target.value });
			setPOFormData({ ...POFormData, comments: event.target.value });
		} else if (event.target.name === "receivedBy") {
			this.setState({ receivedBy: event.target.value });
			setPOFormData({ ...POFormData, receivedBy: event.target.value });
		}
	};


	handleDateChange = (date, type) => {
		let formatedDate = date;
		const { setPOFormData, POFormData } = this.props;
		let POFormDataClone = { ...POFormData };

		if (type === "dateOrdered") {
			if (POFormData.dateExpected !== "" || POFormData.dateReceived !== "") {
				// if (Date.parse(date) <= Date.parse(POFormData.dateReceived)) {
					if (Date.parse(date) <= Date.parse(POFormData.dateExpected)) {
						this.setState({ dateOrdered: formatedDate });
						setPOFormData({ ...POFormDataClone, dateOrdered: formatedDate });
					} else {
						createNotification("error", "Order date should be less or equal to expected date");
					}
				// } else {
				// 	createNotification("error", "Order date should be less than or equal to received date");
				// }
			} else {
				this.setState({ dateOrdered: formatedDate });
				setPOFormData({ ...POFormDataClone, dateOrdered: formatedDate });
			}
		} else if (type === "dateExpected") {
			if (POFormData.dateOrdered !== "") {
				if (Date.parse(date) >= Date.parse(POFormData.dateOrdered)) {
					this.setState({ dateExpected: formatedDate });
					setPOFormData({ ...POFormDataClone, dateExpected: formatedDate });
				} else {
					createNotification("error", "Expected date should be greater than or equal to Order date");
				}
			} else {
				this.setState({ dateExpected: formatedDate });
				setPOFormData({ ...POFormDataClone, dateExpected: formatedDate });
			}
		} else if (type === "dateReceived") {
			if (POFormData.dateOrdered !== "") {
				if (Date.parse(date) >= Date.parse(POFormData.dateOrdered)) {
					this.setState({ dateReceived: formatedDate });
					setPOFormData({ ...POFormDataClone, dateReceived: formatedDate });
				} else {
					createNotification("error", "Received date should be greater than or equal to Order date");
				}
			} else {
				this.setState({ dateReceived: formatedDate });
				setPOFormData({ ...POFormDataClone, dateReceived: formatedDate });
			}
		}
	};

	resetMaterialForm = () => {
		const { setMaterialFormData, materialFormData } = this.props;
		let materialFormDataClone = { ...materialFormData };
		setMaterialFormData({ ...materialFormDataClone, ...this.state.initialState });
	};


	//function to save data of po ------------------ 
	handleSaveReceivingPo = () => {
		const {
			projectId,
			selectedScope,
			projectPoMaterialsId,
			dateOrdered,
			dateExpected,
			dateReceived,
			vendor,
			receivedBy,
			comments } = this.state;

		let payLoad = {
			orderedDate: dbDateFormat(dateOrdered),
			expectedDate: dbDateFormat(dateExpected),
			receivedDate: dbDateFormat(dateReceived),
			comments: comments === null ? "" : comments,
			vendor: Number(vendor),
			receivedBy: receivedBy === null ? "" : receivedBy,
		};

		projectServices.updateRecievingPoModule(projectId, selectedScope, projectPoMaterialsId, payLoad).then((res) => {
			createNotification("success", "PO updated successfully")
		}).catch((error) => {
			console.log(error);
		})
	}
	handleSaveReceivingMaterial = () => {
		const { projectId, selectedScope, projectPoMaterialsId, MaterialId, heatNumber, location, gradeId,materialRowId } = this.state;
		let data={};
		console.log(location);
		console.log(heatNumber);
		let newMaterialArray=[];
		data.location=location;
		data.heatNumber=heatNumber;
		data.id=materialRowId;
		 newMaterialArray.push(data)
		console.log(newMaterialArray);
		 let matObj = {
			materialItems: newMaterialArray
		  }

		projectServices.UpdateMaterialLengthQtyRecords(matObj).then((response) => {
			createNotification("success", "Material item record updated successfully")
		}).catch((error) => {
			console.log(error);
		})
	}
	handleBackClick = () => {
		this.props.history.push("/receiving");
	}
	hanldeInput = (event, item, index) => {
		this.setState({heatNumber:event.target.value})
	}
	handleOnChangeLocation=(event, item, index)=>{
       const{location}=this.state;
	   if(event.target.name ==="location"){
		this.setState({location:Number(event.target.value)},()=>{
			event.target.selected = true;
		})
	   }
	}
	//--------------------------------------------
	render() {

		const { poItemList: poItem, materialItemList: materialItem, materialLenQtyList, materialFormData, POFormData } = this.props;
		let receivingId = this.props.match.params.recievingId;
		const { vendorList,
			 locationList,
			  projectTitle,
			   jobNumber,
			    ScopeName,
				location,
				heatNumber, 
				loader, 
				gradeList, 
				materialName, 
				poNumber,
				previousPage,
				previousSkip,
				previousLimit,
				previousVendorId,
				previousPoNumber,
				previousProjectId,
			} = this.state;
		//extracting constants from poform data
		const { comments,
			dateOrdered,
			dateExpected,
			dateReceived,
			receivedBy,
			transmittal,
			vendor,
			
		} = POFormData

		

		let roleKey = this.props.loginUserData.roleKey;

		//setting up is disable field 
		const isDisable = (roleKey !== "" && rules[roleKey][Permission.RECEIVING_WRITE]) ? "" : "disabled";
        console.log("previousVendorId,previousPoNumber,previousProjectId",previousVendorId,previousPoNumber,previousProjectId);
		const data = { 
			key: previousPage,
			keySkip:previousSkip ,
			keyLimit:previousLimit ,
			keyVendorId:previousVendorId,
			keyPoNumber:previousPoNumber,
			keyProjectId:previousProjectId
			};
		return (
			<div>
				{loader === true ? <Loader /> :
					<div className="main-content">
						<div className="page-content">
							<div className="container-fluid">
								<div className="project-button-box mb-3" style={{ display: "flex", justifyContent: "flex-start" }} >
									<ol className="breadcrumb m-0">
										<Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
										&nbsp; / &nbsp;
										<Link 
										to={{
											pathname: `/receiving`,
											 state: { data: data },
										}}>
											<li className="breadcrumb-item">Receiving</li></Link>
										&nbsp; / &nbsp;
										<Link to={`/displayrecieving/${receivingId}`}><li className="breadcrumb-item">Edit</li></Link>
									</ol>
								</div>
								<h4>Receiving Details</h4>
								<div className="material-block receive-card card block-height">
									<div className="material-heading">
										<div className="row m-0" >
											<div className="col-lg-4 col-md-12 d-flex align-items-center justify-content-center  material-col-one">
												<div>
													<b>Job # : {`  ${jobNumber} ${projectTitle}`}</b>
												</div>
											</div>
											<div className="col-lg-4 col-md-12 d-flex align-items-center justify-content-center  material-col-two">
												<div>
													<b>	Scope Name : {ScopeName}</b>
												</div>
											</div>
											<div className="col-lg-4 col-md-12 d-flex align-items-center justify-content-center material-col-three">
												<b>PO # : {poNumber}</b>
											</div>
										</div>
									</div>
									<div className="material-content">
										<fieldset disabled={isDisable}>
											<div className="material-content-block">
												<div className="row">
													<div className="col-lg-4">
														<div className="mb-3">
															<label className="form-label">Date Ordered </label>
															<DatePicker
																className="form-control date-form-control"
																onChange={(date) =>
																	this.handleDateChange(date, "dateOrdered")
																}
																dateFormat="MM/dd/yyyy"
																placeholderText="MM/DD/YYYY"
																selected={dateOrdered}
																disabled={isDisable}
															/>
														</div>
													</div>
													<div className="col-lg-4">
														<div className="mb-3">
															<label className="form-label">Date Expected</label>
															<DatePicker
																className="form-control date-form-control"
																onChange={(date) =>
																	this.handleDateChange(date, "dateExpected")
																}
																dateFormat="MM/dd/yyyy"
																placeholderText="MM/DD/YYYY"
																selected={dateExpected}
																disabled={isDisable}
															/>
														</div>
													</div>
													<div className="col-lg-4">
														<div className="mb-3">
															<label className="form-label">Date Received</label>
															<DatePicker
																className="form-control date-form-control"
																onChange={(date) =>
																	this.handleDateChange(date, "dateReceived")
																}
																dateFormat="MM/dd/yyyy"
																placeholderText="MM/DD/YYYY"
																selected={dateReceived}
																disabled={isDisable}
															/>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-lg-4">
														<div className="mb-3">
															<label className="form-label">Vendor</label>
															<select className="form-select" name="vendor" onChange={this.handlePOChange} value={vendor} disabled={isDisable} >
																<option value="0">Select</option>
																{vendorList.length && vendorList.map((itemData) => {
																	return (<option value={itemData.id}> {itemData && itemData.name} </option>);
																})}
															</select>
														</div>
													</div>
													<div className="col-lg-4">
														<div className="mb-3">
															<label className="for-label">Transmittal #</label>
															<input className="form-control" value={transmittal} disabled />
														</div>
													</div>
													<div className="col-lg-4">
														<div className="mb-3">
															<label className="form-label">Received By</label>
															<input placeholder="Type here" className="form-control" type="text" name="receivedBy" onChange={this.handlePOChange} value={receivedBy} disabled={isDisable} maxLength="200" />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-lg-12">
														<div className="mb-3">
															<label className="form-label">Comments</label>
															<textarea placeholder="Type here" className="form-control" rows="3" name="comments" value={comments} onChange={this.handlePOChange} disabled={isDisable} maxLength="700" />
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-lg-12 mt-2">
														<div className="wizard">
															<div className="actions">
																<ul role="menu" aria-label="Pagination">
																	<AllowedTo perform={Permission.RECEIVING_WRITE}>
																		<li><a role="menuitem" className="d-block px-5" onClick={() => this.handleSaveReceivingPo()} disabled={isDisable}>Save</a></li>
																	</AllowedTo>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</fieldset>
									</div>
								</div>

								{/* ----------------------------------Material module of the section------------------------------- */}

								<div className="material-block receive-card card block-height">
									<div className="material-heading">
										<div className="row m-0" >
											<div className="col-lg-4 col-md-12 d-flex align-items-center justify-content-center material-col-three">
												{/* <b>Material Name : {materialName}</b> */}
											</div>
										</div>
									</div>
									<div className="material-content">
										<fieldset disabled={isDisable}>
											<div className="material-content-block">
												<div className="row">
													<div className="col-lg-12">
														<div className="row">
															<div className="col-lg-12">
																<div className="mb-3 table-wrapper-scroll-y my-custom-scrollbar">
																	<table className="table table-fixed headerMinHeight50 table table-bordered table-striped mb-0">
																		<thead className="headerTable">
																			<tr>
																				<th style={{width: '50px'}}>Quantity</th>
																				<th style={{width: '70px'}}>Shape</th>
																				<th style={{width: '70px'}}>Grade</th>
																				<th style={{width: '70px'}}>Dimension</th>
																				<th style={{width: '70px'}}>Length</th>
																				<th style={{width: '70px'}}>Weight</th>
																				<th style={{width: '70px'}}>Heat Number </th>
																				<th style={{width: '120px'}}>Location</th>
																			</tr>
																		</thead>
																		<tbody>
																			{materialLenQtyList.length > 0 && materialLenQtyList.map((item, index) => {
																				return (
																					<tr>
																						<td> {item.quantity} </td>
																						<td> {item.shape} </td>
																						<td> {item.grade} </td>
																						<td> {item.item} </td>
																						<td> {item.length} </td>
																						<td> {item.weight} </td>
																						<td ><input defaultValue={item.heatNumber} onChange={(e) => { this.hanldeInput(e, item, index) }}/ >  </td>
																						<td  disabled={isDisable}>
																							<select style={{width: '120px'}} className="form-select"  name="location"
																								onChange={(event) => this.handleOnChangeLocation(event, item, index)}
																							>
																								<option value="0">Select</option>
																								{locationList.length && locationList.map((itemData, index) => {
																									return (
																										<option value={itemData.id} key={index} selected={itemData.id === item.location}>
																											{itemData && itemData.name}
																										</option>
																									);
																								})}
																							</select></td>
																					</tr>
																				);
																			})}
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													</div>
													<div className="col-lg-4">
													</div>
												</div>
												<div className="row">
													<div className="col-lg-12 mt-2">
														<div className="wizard">
															<div className="actions">
																<ul role="menu" aria-label="Pagination">
																	<AllowedTo perform={Permission.RECEIVING_WRITE}>
																		<li><a role="menuitem" className="d-block px-5" onClick={() => this.handleSaveReceivingMaterial()} disabled={isDisable}>Save</a></li>
																	</AllowedTo>
																	<li> 
																		<Link style={{}} to={{
																			pathname: `/receiving`,
																			state: { data: data },
																			}}
																			className="d-block p-0"
																			> 
																			<button 
																			role="menuitem" 
																			className="d-block btn btn-primary" 
																			disabled={isDisable} 
																			>
																				 Back
																				 </button>
																		</Link>
																																
																		{/* <a role="menuitem"
																		 className="d-block px-5"
																		  onClick={() => this.handleBackClick(true)}
																		   disabled={isDisable} >
																			Back
																		   </a> */}
																		   </li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</fieldset>
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = (props) => {

	return {
		materialItemList: props.materialItemList,
		projectMaterialId: props.projectMaterialId,
		materialLenQtyList: props.materialLenQtyList,
		POFormData: props.POFormData,
		materialFormData: props.materialFormData,
		loginUserData: props.loginUserData,
	};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
	setMaterialItemList: setMaterialItemList,
	setProjectMaterialId: setProjectMaterialId,
	setMaterialLenQty: setMaterialLenQty,
	setPOFormData: setPOFormData,
	setMaterialFormData: setMaterialFormData,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(updateRecievingDetails);
