import React from "react";
import { Component } from "react";
import { bindActionCreators, map } from "redux";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import projectServices from "./services/projectServices";
import { dbDateFormat } from "../helpers/Util";
import { AllowedTo } from "react-abac";
import { Permission } from "../../abac.config";
import { createNotification } from "../helpers/Notification";
import moment from "moment";
import { rules } from "../../abac.config";


class ProjectListItemPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerList: [],
      managerId: "",
      managerName:"",
      jobNumber: "0000",
      startDate: "",
      endDate: "",
      projectName: "",
      description: "",
      addItem: "",
      contract_price: "",
      total_cost: "",
      amount_billed: "",
      cost_percentage: "",
      balance_billed: "",
      due_retention: "",
      scopeItemData: [],
      organization: 1,
      jobNumAndProjName:"",
      old_data: {},
      isShowAlertBox: false,
      addItemVal: "",
      scopeItemLengthLimitFlag: false,
      isEmptyProjectManagerFlag: false,

    }
    this.validator = new SimpleReactValidator();
  }
  componentDidMount = () => {
    console.log("pageChange",this.state.pageChange);
    console.log("data",window.history.state);
    
    const { projectId } = this.props;
    this.getProjectById(projectId);

  }
  getProjectById = (projectId) => {
    projectServices.getProjectById(projectId).then((res) => {
      const { manager_fkey,manager, jobNumber, organization, startDate, endDate, description, scopeItemData, title, projectStatus, amount_billed, balance_billed, contract_price, cost_percentage, due_retention, total_cost } = res.data;
      const scoId = res.data && res.data.scopeItemData && res.data.scopeItemData[0] && res.data.scopeItemData[0].id;
      // this.props.setSelectedScopeItemID(Number(scoId))
      let scopeItems = []
      for (let sc of scopeItemData) {
        let sItem = {
          id: sc.id,
          title: sc.title
        }
        scopeItems.push(sItem);
      }
      const old_data = { title, organization, manager_fkey, jobNumber: Number(jobNumber), startDate: dbDateFormat(startDate), endDate: dbDateFormat(endDate), description, scopeItemData: scopeItems };
      this.setState({
        managerId: manager,
        managerName: res.data.manager_fkey || res.data.hasOwnProperty('manager_fkey') === true ? res.data.manager_fkey.name : "N/A",
        jobNumber: String(jobNumber),
        startDate: new Date(moment(startDate).format('MM/DD/YYYY')),
        endDate: new Date(moment(endDate).format('MM/DD/YYYY')),
        // startDate:startDate,
        // endDate:endDate,
        projectName: title,
        description: description,
        scopeItemData: this.formatScopeItem(scopeItemData),
        jobNumAndProjName:String(jobNumber).concat(title),
        amount_billed: amount_billed !== null ? amount_billed.toString() : "",
        total_cost: total_cost !== null ? total_cost.toString() : '',
        cost_percentage: cost_percentage !== null ? cost_percentage.toString() : "",
        balance_billed: balance_billed !== null ? balance_billed.toString() : "",
        due_retention: due_retention !== null ? due_retention.toString() : "",
        contract_price: contract_price !== null ? contract_price.toString() : '',


      })
    }).catch((error) => {
      console.log(error);
    })
  }
  formatScopeItem = (scopeArr) => {
    let itemArr = [];
    scopeArr.length && scopeArr.forEach((item) => {
      itemArr.push({ id: item.id, title: item.title });
    });
    return itemArr;
  }
  handleOnChange = (event) => {

    if (event.target.name === "total_cost") {
      this.setState({ total_cost: event.target.value, isTotalCostEntered: true });
    } else if (event.target.name === "contract_price") {
      this.setState({ contract_price: event.target.value });
    }
    else if (event.target.name === "amount_billed") {
      this.setState({ amount_billed: event.target.value });
    } else if (event.target.name === "cost_percentage") {
      this.setState({ cost_percentage: event.target.value, isContractPriceEntered: true });
    } else if (event.target.name === "balance_billed") {
      this.setState({ balance_billed: event.target.value });
    } else if (event.target.name === "due_retention") {
      this.setState({ due_retention: event.target.value });
    }

  }
  handleOnBlur = (e) => {

    const { total_cost, amount_billed, contract_price } = this.state
    // alert(total_cost+"????????"+amount_billed)
    if (e.target.name === "total_cost" || e.target.name === "amount_billed") {
      let value;
      if (total_cost !== "" && amount_billed !== "") {
        value = (total_cost / amount_billed) * 100;
        this.setState({ cost_percentage: value.toFixed(2), isTotalCostEntered: false });
      }
    }
  }

  handleOnblurBalanceBill = (e) => {
    const { total_cost, amount_billed, contract_price } = this.state
    if (e.target.name === "amount_billed" || e.target.name === "contract_price") {

      let value;
      if (contract_price !== "" && amount_billed !== "") {
        value = contract_price - amount_billed;
        this.setState({ balance_billed: value.toFixed(3), isContractPriceEntered: false });
      }
    }
  }

  handleSaveProjectRecords = () => {
    
     const { managerId, jobNumber, startDate, endDate, projectName, description, scopeItemData, organization, contract_price, amount_billed, balance_billed, due_retention, total_cost, cost_percentage } = this.state;
    if(this.validator.allValid()){
      this.props.handleUpdateProjectItems(managerId, jobNumber, startDate, endDate, projectName, description, scopeItemData, organization, contract_price, amount_billed, balance_billed, due_retention, total_cost, cost_percentage )
      // this.props.closeModel();
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    const roleKey = this.props.loginUserData.roleKey;
    const { closeModel, } = this.props;
    const { jobNumber, projectName, managerName, contract_price, total_cost, amount_billed, cost_percentage, balance_billed, due_retention, jobNumAndProjName} = this.state;
    const isDisable=(roleKey !== "" && rules[roleKey][Permission.PROJECT_WRITE]) ? "" : "disabled";
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title mt-0 text-dark fw-bold text-center" id="myModalLabel">Edit project details </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => { closeModel(false) }}
          ></button>
        </div>
        <div className="modal-body">
          <div className="mb-3 accordWrap">
            <div className="row">
              <div className="col-md-4 mb-3">
                <label className="col-md-4">Project Manager</label>
                <input required="" autocomplete="off" type="text" className="form-control col-md-2" placeholder="Enter item name here" name="item" onChange={(e) => this.handleOnChange(e)} value={managerName} disabled />
              </div>
              <div className="col-md-4 mb-3">
                <label className="col-md-4">Project name and job number</label>
                <input required="" autocomplete="off"  className="form-control col-md-2" placeholder="Enter item name here" name="item" onChange={(e) => this.handleOnChange(e)} 
                value={jobNumber.concat("-").concat(projectName) } disabled />

              </div>
              <div className="col-md-4 mb-3">
                <label className="col-md-4">Contract Price</label>
                <input required="" autocomplete="off" type="number" className="form-control col-md-2" placeholder="Enter contract price here" name="contract_price" onChange={(e) => this.handleOnChange(e)} value={contract_price} onBlur={(e)=>this.handleOnblurBalanceBill(e)} onKeyUp={(e)=>this.handleOnblurBalanceBill(e)} disabled={isDisable}/>
                {this.validator.message(
                  "contract price",
                  this.state.contract_price,
                  "required|max:20",
                  { className: "text-danger" })}

              </div>
              <div className="col-md-4 mb-3">
                <label className="col-md-4">Total Cost</label>
                <input required="" autocomplete="off" type="number" className="form-control col-md-2" placeholder="Enter total cost here" name="total_cost" onChange={(e) => this.handleOnChange(e)} value={total_cost} onBlur={(e) => this.handleOnBlur(e)} onKeyUp={(e) => this.handleOnBlur(e)} disabled={isDisable}/>
                {this.validator.message(
                  "total cost",
                  this.state.total_cost,
                  "required|max:20",
                  { className: "text-danger" })}

              </div>
              <div className="col-md-4 mb-3">
                <label className="col-md-4">Amount Billed</label>
                <input required="" autocomplete="off" type="number" className="form-control col-md-2" placeholder="Enter amount bill here" name="amount_billed" onChange={(e) => this.handleOnChange(e)} value={amount_billed} onBlur={(e) => { this.handleOnBlur(e); this.handleOnblurBalanceBill(e) }}
                  onKeyUp={(e) => { this.handleOnBlur(e); this.handleOnblurBalanceBill(e) }} disabled={isDisable} />
                {this.validator.message(
                  "amount billed",
                  this.state.amount_billed,
                  "required|max:20",
                  { className: "text-danger" })}

              </div>
              <div className="col-md-4 mb-3">
                <label className="col-md-4">Cost Percentage</label>
                <input required="" autocomplete="off" type="number" className="form-control col-md-2"  name="cost_percentage" onChange={(e) => this.handleOnChange(e)} value={cost_percentage} disabled />
              </div>
              <div className="col-md-4 mb-3">
                <label className="col-md-4">Balance Billed</label>
                <input required="" autocomplete="off" type="number" className="form-control col-md-2"  name="balance_billed" onChange={(e) => this.handleOnChange(e)} value={balance_billed} disabled />
              </div>
              <div className="col-md-4 mb-3">
                <label className="col-md-4">Due retention</label>
                <input required="" autocomplete="off" type="number" className="form-control col-md-2" placeholder="Enter due retention" name="due_retention" onChange={(e) => this.handleOnChange(e)} value={due_retention} />
                {this.validator.message(
                  "Due retention",
                  this.state.due_retention,
                  "required|max:20",
                  { className: "text-danger" })}

              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-center">
          <button type="button" className="btn btn-danger fw-bold" data-bs-dismiss="modal" aria-label="Close" onClick={() => { closeModel() }}><i className="uil uil-times"></i> Cancel</button>
          <AllowedTo perform={Permission.PROJECT_WRITE}><button className="btn btn-primary fw-bold" onClick={this.handleSaveProjectRecords}><i className="uil uil-plus"></i>Update</button></AllowedTo>

        </div>
      </div>

    )
  }
}
const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectListItemPopUp);

