import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { AllowedTo } from 'react-abac';
import {rules,Permission} from "../../abac.config"
import { FaArchive } from "react-icons/fa";
export default function ProjectCard(props) {

    const { title, jobNumber, endDate, onClick, projectId, onDelete } = props;
    let combJobIdStr = `${jobNumber} ${title}`;
    let jobIdLen = combJobIdStr.length;
    let showString = (jobIdLen >= 18) ? `${combJobIdStr.slice(0, 15)}...` : combJobIdStr;
    return (
        <div className="col-lg-3 col-md-4">
            <div className="card" >
                <div className="card-body">

                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Job # - {combJobIdStr}
                            </Tooltip>
                        }

                    >
                        <h5 className="card-title" onClick={() => onClick(projectId)}>Job # - {showString} </h5>
                    </OverlayTrigger>

                    <p className="card-text release-date-label">Release Date:</p>
                    <p className="card-text release-date-text">{`${endDate}`}</p>
                    <AllowedTo perform={Permission.PROJECT_DELETE}>
                        {/* <Icon icon="material-symbols-light:archive" /> */}
                        <FaArchive className='font-size-18 text-primary uil-trash-alt-proj' onClick={() => onDelete(projectId)}/>
                      {/* <i className="uil uil-trash-alt-proj uil-trash-alt font-size-18 text-danger" onClick={() => onDelete(projectId)} ></i> */}
                    </AllowedTo>
                </div>
            </div>
        </div>
    )
}
