import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import masterDataService from "../services/MasterDataService";
import SimpleReactValidator from "simple-react-validator";
import { createNotification } from "../helpers/Notification";
import { Link } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import ConfirmBox from "../common/ConfirmBox";
import { validDocumentArr } from "../helpers/Util";
import Dropzone from "react-dropzone";
import { AllowedTo } from "react-abac";
import { rules } from "../../abac.config";
import { Permission } from "../../abac.config";
import { isValidDocument, checkDrawingFileSize } from "../helpers/Util";
import AddEmployeePopUp from "./AddEmployeePopUp";
import ModalPopUp from "../common/ModalPopUp";
import Loader from "../common/Loader";
import projectServices from "../project/services/projectServices";
import { current } from "@reduxjs/toolkit";

class AddEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certificateCategories: [],
      employeeName: "",
      shopDesignation: 0,
      titles:0,
      phoneNumber: "",
      emailAddress: "",
      dob: "",
      hireDate: "",
      wage: 0,
      wageDateChange: "",
      employeeId: 0,
      index: 0,
      isOpenAddEmployeePop: false,
      certificateData: [],
      previousPage:1,
      previousSkip:0,
      previousLimit:10,
      previousSearch:"",

      birthDate: 0,
      birthMonth: 0,
      employee: "",
      certificateCategoryId: 0,
      selectedFiles: null,
      isShowConfirmBox: false,
      removeCertificateItemId: 0,
      categoryArray: [],
      isCertificateCategorySelected: false,
      isDisable: true,
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = () => {
    const { match } = this.props;
   

    if(window.history.state.state !== undefined){
    console.log(window.history.state.state);
        this.setState({
          previousPage:window.history.state.state.data.currentPage,
          previousSkip:window.history.state.state.data.currentSkip,
          previousLimit:window.history.state.state.data.currentLimit,
          previousSearch: window.history.state.state.data.searchValue
        })
        localStorage.setItem('humanResourcePaginationConstants', JSON.stringify({ currentPage: window.history.state.state.data.currentPage, currentSkip: window.history.state.state.data.currentSkip,currentLimit:window.history.state.state.data.currentLimit,searchValue: window.history.state.state.data.searchValue}));
    }
    if (
      match.path == "/addEmployee/:employeeId" &&
      match.params.employeeId !== null
    ) {
      this.setState(
        { employeeId: Number(match.params.employeeId), isDisable: false },
        () => {
          this.getHumanResourceEmployeeById(Number(match.params.employeeId));
          this.getHumanResourceCertificates();
        }
      );
    }
    this.setState({ loader: true }, () => {
      this.getCertificateCategoties();
    });

  };

  getHumanResourceEmployeeById = (empId) => {
    projectServices
      .getHumanResourceEmployeeById(empId)
      .then((response) => {
        this.setState({
          employeeName: response.data[0].employee_name,
          shopDesignation:  response.data[0].fkey_shopdesignation ? response.data[0].fkey_shopdesignation.designation :'',
          titles: response.data[0].fkey_title ? response.data[0].fkey_title.name : '',
          phoneNumber: response.data[0].phone_number,
          emailAddress: response.data[0].email_address,
          dob: response.data[0].dob,
          hireDate: response.data[0].hire_date,
          wage: response.data[0].wage,
          wageDateChange: response.data[0].wage_date_change,
          employeeId: response.data[0].id,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          createNotification("error", "Your login session has expired .");
          this.props.history.push("/login");
        }
      });
  };

  getCertificateCategoties = () => {
    masterDataService
      .getCertificates()
      .then((response) => {
        // console.log(response);
        this.setState({ certificateCategories: response.data, loader: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getHumanResourceCertificates = () => {
    const { employeeId } = this.state;
    projectServices
      .getHumanResourceCertificates(employeeId)
      .then((response) => {
        this.setState({ loader: false }, () => {
          this.formatCertificateData(response.data);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  formatCertificateData = (resData) => {
    let certiData = [];
    for (let cd of resData) {
      let cdItem = {
        id: cd.id,
        certificationsId: cd.certificationsId,
        fileId: cd.fileId,
        humanResourceId: cd.fkey_humanResource.id,
        filePath: cd.upload_fkey.filePath,
        fileName: cd.upload_fkey.fileName,
      };
      certiData.push(cdItem);
    }
    this.setState({ certificateData: certiData });
  };

  fileChangedHandler = (event, item) => {
    const { catName, certificates, fileArray, employeeId } = this.state;
    const { employeeName } = this.props;
    let files = event.target.files;
    // console.log(files, "????");
    // console.log(item, "<><><><><><><>");
    // this.setState({ certificateCategoryId: item })
    let allowedExtentions = [
      "jpeg",
      "jpg",
      "png",
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
    ];

    if (this.validateExtensions(files, allowedExtentions) && files.length > 0) {
      this.setState(
        { certificateCategoryId: item, selectedFiles: files[0] },
        () => {
          this.handleSaveCertificate(item);
        }
      );
    } else {
      createNotification("error", "File format not supported .");
    }
  };

  validateExtensions = (files, allowedExtentions) => {
    let error = false;
    if (files.length > 0) {
      let fileExt = files[0].name.split(".").pop() || "";
      if (allowedExtentions.indexOf(fileExt) === -1) {
        error = true;
      }
    }
    return !error;
  };

  handleOnchange = (event) => {
    if (event.target.name === "employee_name") {
      this.setState({ employeeName: event.target.value });
    } else if (event.target.name === "shopDesignationId") {
      this.setState({ shopDesignation: event.target.value });
    } else if (event.target.name === "titleId") {
      this.setState({ titles: event.target.value });
    } else if (event.target.name === "phone_number") {
      this.setState({ phoneNumber: event.target.value });
    } else if (event.target.name === "email_address") {
      this.setState({ emailAddress: event.target.value });
    } else if (event.target.name === "dob") {
      this.setState({ dob: event.target.value });
      const dateString = event.target.value;
      const dateObj = new Date(dateString);
    
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // Months are zero-based
      const day = dateObj.getDate();
      this.setState({
         birthDate : day,
        birthMonth :month,
      })
    } else if (event.target.name === "hire_date") {
      this.setState({ hireDate: event.target.value });
    } else if (event.target.name === "wage") {
      this.setState({ wage: event.target.value });
    } else if (event.target.name === "wage_date_change") {
      this.setState({ wageDateChange: event.target.value });
    }
  };

  setCertificateCategoryId = (certificateCatId) => {
    console.log(certificateCatId);
  };

  handleEmployeeNameChange = (event) => {
    if (event.target.name === "employeeName") {
      this.setState({
        employeeName: event.target.value,
      });
    }
  };

  handleSaveCertificate = (catergoryId) => {
    const { selectedFiles, employeeId } = this.state;
    if (
      this.state.certificateCategoryId === catergoryId &&
      selectedFiles !== null
    ) {
      const formData = new FormData();
      formData.append("humanResourceId", employeeId);
      formData.append("certificationsId", catergoryId);
      formData.append("certificate", this.state.selectedFiles);
      //    this.setState({selectedFiles:null});

      projectServices
        .addHumResourceCertificate(formData)
        .then((response) => {
          createNotification("success", "File uploaded successfully.");
          this.setState({ selectedFiles: null, loader: true }, () => {
            this.getHumanResourceCertificates();
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            createNotification("error", "Your login session has expired .");
            this.props.history.push("/login");
          }
        });
    } else {
      createNotification("error", "Add file before submitting .");
      this.setState({ selectedFiles: null });
    }
  };
  handleOpenAddEmployee = () => {
    this.setState({
      isOpenAddEmployeePop: true,
    });
  };

  handleCloseAddEmployeeModal = () => {
    this.setState({ isOpenAddEmployeePop: false });
  };

  saveNewEmployee = (
    employeeName,
    shopDesignation,
    titles,
    phoneNumber,
    emailAddress,
    dob,
    hireDate,
    wage,
    wageDateChange, 
    birthDate,
    birthMonth,
  ) => {
    // if (isNaN(wage)){
    //   wage =0
    // } if (wageDateChange == ""){
    //   wageDateChange = null
    // }
    // console.log("save wage",parseInt(wage));
    let payload = {
      employee_name: employeeName.trim(),
      shopDesignationId: shopDesignation,
      titleId: titles,
      phone_number: phoneNumber.trim(),
      email_address: emailAddress.trim(),
      dob: dob,
      hire_date: hireDate,
      wage: (+wage),
      wage_date_change: wageDateChange,
      birth_date: birthDate,
      birth_month: birthMonth,
    };

    projectServices
      .addHumResourceEmpolpoyee(payload)
      .then((response) => {
        // console.log("response save ---",response);
        createNotification("success", "Employee record added successfully .");
        this.handleCloseAddEmployeeModal();
        this.getHumanResourceEmployeeById(response.data.id);
      })
      .catch((error) => {
         createNotification("error", error.response.data.error.message);
      });
  };
  updateEmployee = (
    employeeName,
    shopDesignation,
    titles,
    phoneNumber,
    emailAddress,
    dob,
    hireDate,
    wage,
    wageDateChange,
    birthDate,
    birthMonth
  ) => {
    const { employeeId } = this.state;
    // if (isNaN(wage)){
    //   wage =0
    // } if (wageDateChange == ""){
    //   wageDateChange = null
    // }

    // console.log("update wage",wage);
    let payload = {
      employee_name: employeeName.trim(),
      shopDesignationId: shopDesignation,
      titleId: titles,
      phone_number: phoneNumber.trim(),
      email_address: emailAddress.trim(),
      dob: dob,
      hire_date: hireDate,
      wage: (+wage),
      wage_date_change: wageDateChange,
      birth_date: birthDate,
      birth_month: birthMonth,
    };
    projectServices
      .updateHumanResourceEmployee(payload, employeeId)
      .then((response) => {
        // console.log("response update ---",response);
        createNotification("success", "Employee records updated successfully.");
        this.handleCloseAddEmployeeModal();
        this.getHumanResourceEmployeeById(employeeId);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          createNotification("error", "Your login session has been expired!");
          localStorage.clear();
          this.props.history.push("/login");
        } else {
          createNotification("error", error.response.data.error.message);
        }

      });
  };
  handleViewCertificate = (filePath) => {
    window.open(filePath, "_blank");
  };
  handleDeleteCertificate = (certificateId) => {
    this.setState({
      removeCertificateItemId: certificateId,
      isShowConfirmBox: true,
    });
  };
  handleConfirmModal = (isYes) => {
    const { removeCertificateItemId } = this.state;
    if (isYes) {
      this.setState({
        isShowConfirmBox: !this.state.isShowConfirmBox,
        loader: true,
      });
      projectServices
        .deleteHumResourceCertificateItem(removeCertificateItemId)
        .then((response) => {
          createNotification("success", " File deleted successfully.");
          this.getHumanResourceCertificates();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ removeId: 0, isShowConfirmBox: false, loader: false });
    }
  };
  render() {
    const roleKey = this.props.loginUserData.roleKey;

    const {
      certificateCategories,
      isShowConfirmBox,
      isDisable,
      loader,
      certificateData,
      employeeName,
      shopDesignation,
      titles,
      previousPage,
      previousSkip,
      previousLimit,
      previousSearch
    } = this.state;
   
    const data = { key: previousPage,keySkip:previousSkip ,keyLimit:previousLimit,keySearch:previousSearch};
    return (
      <div>
        {loader === true ? (
          <Loader />
        ) : (
          <div className="main-content">
            <ConfirmBox
              openModal={isShowConfirmBox}
              confirmMsg="Are you sure to delete this file?"
              handleConfirmModal={this.handleConfirmModal}
            />

            <div className="page-content">
              <div className="container-fluid">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <ol className="breadcrumb m-0">
                    <Link to={`/#`}>
                      <li className="breadcrumb-item">Home</li>
                    </Link>
                    &nbsp; / &nbsp;
                    <Link to={{
                      pathname: `/humanResource`,
                       state: { data: data },
                  }}
                  > <li className="breadcrumb-item">Human Resource</li>
                    </Link>
                    &nbsp; / &nbsp;
                    {this.props.match.path == `/addEmployee` ? (
                      <Link to={`/addEmployee`}>
                        <li className="breadcrumb-item">Add</li>
                      </Link>
                    ) : this.props.match.path == `/addEmployee/:employeeId` ? (
                      <Link
                        to={`/addEmployee/${this.props.match.params.employeeId}`}
                      >
                        <li className="breadcrumb-item">Edit</li>
                      </Link>
                    ) : (
                      ""
                    )}
                  </ol>
                </div>
                <div className="modal-body">
                  {this.state.employeeId === 0 ? (
                    <button
                      className="btn btn-primary"
                      onClick={this.handleOpenAddEmployee}
                    >
                      <i className="uil uil-plus"></i> Add Employee
                    </button>
                  ) : (
                    ""
                  )}

                  <div className="row mt-3">
                    <div className="row mt-3">
                      <div className="col-md-8 mb-3">
                        <label className="headingTxt">Employee Name</label>
                        <input
                          required=""
                          disabled
                          type="text"
                          className="form-control col-md-2"
                          onChange={(e) => {
                            this.handleOnchange(e);
                          }}
                          name="employee_name"
                          value={employeeName}
                        />
                      </div>
                      <div className="col-md-8 mb-3">
                        <label className="headingTxt">Shop Designation</label>
                        <input
                          required=""
                          disabled
                          type="text"
                          className="form-control col-md-2"
                          onChange={(e) => {
                            this.handleOnchange(e);
                          }}
                          name="fkey_shopdesignation.designation"
                          value={shopDesignation === 0 ? "": shopDesignation}
                          // value={shopDesignation}
                        />
                      </div>
                      <div className="col-md-8 mb-3">
                        <label className="headingTxt">Title</label>
                        <input
                          required=""
                          disabled
                          type="text"
                          className="form-control col-md-2"
                          onChange={(e) => {
                            this.handleOnchange(e);
                          }}
                          name="titleId"
                          value={titles === 0 ? "" : titles}
                          // value={titles}
                        />
                      </div>
                      <div className="col-md-8 mb-3">
                        {this.state.employeeId !== 0 ? (
                          <button
                            className="btn btn-primary"
                            onClick={this.handleOpenAddEmployee}
                          >
                            <i className="uil uil-plus"></i> Edit Employee
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="mb-3 accordWrap">
                      <div className="col-sm-12">
                        <h3 className="categoryHeading">Categories </h3>
                      </div>
                      {this.state.employeeId !== 0 ? (
                        <div className="categoryOuter">
                          {certificateCategories.length &&
                            certificateCategories.map((item, index) => {
                              const handleFileChange = (event) => {
                                this.fileChangedHandler(event, item.id);
                              };

                              const handleSaveCertificate = () => {
                                this.handleSaveCertificate(item.id);
                              };

                              // Find the corresponding certificateData for the current item
                              const dataForCurrentCertificate =
                                certificateData.find(
                                  (data) => data.certificationsId === item.id
                                );

                              const shouldShowUploadButtons =
                                !dataForCurrentCertificate ||
                                !dataForCurrentCertificate.filePath ||
                                !dataForCurrentCertificate.fileName;

                              return (
                                <div>
                                  <div className="row">
                                    <div className="col-md-3 mb-2">
                                      <label className="col-md-3">
                                        <h6 className="headingTxt">
                                          {item.name}
                                        </h6>
                                        <p className="categoryData"></p>
                                      </label>
                                    </div>
                                    {dataForCurrentCertificate === undefined ? (
                                      <div className="col-md-4 mb-2">
                                        <div>
                                          {shouldShowUploadButtons ? (
                                            <AllowedTo
                                              perform={Permission.HUMANRESOURCE_WRITE}
                                            >
                                              <div className="fileOuter">
                                                <label
                                                  htmlFor={`csvFileInput-${item.id}`}
                                                  className="mr-3 btn btn-primary"
                                                >
                                                  Upload File
                                                  <input
                                                    type="file"
                                                    id={`csvFileInput-${item.id}`}
                                                    hidden={true}
                                                    onChange={handleFileChange}
                                                  />
                                                </label>
                                                {/* <button className="btn btn-primary" onClick={handleSaveCertificate}>
                                                                                                <i className="uil uil-plus"></i> Submit File
                                                                                            </button> */}
                                              </div>
                                            </AllowedTo>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="col-lg-7 mb-2">
                                        {dataForCurrentCertificate &&
                                        dataForCurrentCertificate.filePath &&
                                        dataForCurrentCertificate.fileName ? (
                                          <div className="fileOuter">
                                            <p className="text-primary mr-3">
                                              File Name:{" "}
                                              {
                                                dataForCurrentCertificate.fileName
                                              }
                                            </p>
                                            <div className="btnWrapper">
                                              <button
                                                className="btn btn-primary mr-3"
                                                onClick={() =>
                                                  this.handleViewCertificate(
                                                    dataForCurrentCertificate.filePath
                                                  )
                                                }
                                              >
                                                View
                                              </button>
                                              <button
                                                className="btn btn-danger"
                                                onClick={() =>
                                                  this.handleDeleteCertificate(
                                                    dataForCurrentCertificate.id
                                                  )
                                                }
                                              >
                                                Delete
                                              </button>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}

                          
                            <div className="row">
                              <div className="col-md-3"></div>
                              <div className="col-md-9">
                              <Link to={{
                                pathname: `/humanResource`,
                                state: { data: data },
                                }}
                                > 
                                <button className="btn btn-primary btn-done"> Done</button>
                              </Link>
                              </div>
                            </div>
                        </div>
                      ) : (
                        <h4 className="text-danger">
                          Please add employee name and shop designation to be
                          able to upload files.
                        </h4>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3"></div>
                </div>
              </div>
              <ModalPopUp
                componentData={
                  <AddEmployeePopUp
                    closeModel={this.handleCloseAddEmployeeModal}
                    saveNewEmployee={this.saveNewEmployee}
                    employeeId={this.state.employeeId}
                    updateEmployee={this.updateEmployee}
                  />
                }
                openModal={this.state.isOpenAddEmployeePop}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
    employeeName: props.employeeName,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AddEmployee);
