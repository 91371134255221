 import rootReducer from '../reducers';

 import projectReducer from '../component/project/redux/projectReducer';

 
 import {legacy_createStore as createStore} from 'redux';


 const store = createStore(projectReducer);

 export default store;