import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AllowedTo } from "react-abac";
import { Permission } from "../../abac.config";
import { createNotification } from "../helpers/Notification";
import { rules } from "../../abac.config";
import SimpleReactValidator from "simple-react-validator";
import projectServices from "../project/services/projectServices";
import { DropDown } from "../common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

class AddEmployeePopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeName: "",
      shopDesignation: 0,
      titles:0,
      phoneNumber: "",
      emailAddress: "",
      dob: "",
      hireDate: "",
      wage: Number,
      wageDateChange: "",
      shopDesignationList: [],
      titlesList: [],
      buttonText: "",
      birthDate: 0,
      birthMonth: 0,
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount = () => {
    let roleKey = this.props.loginUserData.roleKey;
    const isAdmin = roleKey === "admin" ? true : false 
    this.getDropdownData();
    this.getTitlesDropdownData();
    if (this.props.employeeId !== 0) {
      this.setState({ buttonText: "Update Employee" }, () => {
        this.getHumanResourceEmployeeById(this.props.employeeId,isAdmin);
      });
    } else {
      this.setState({ buttonText: "Add Employee" });
    }
  };

  getDropdownData = () => {
    projectServices
      .getAllShopDesignations()
      .then((response) => {
        let responseData = response.data;
        let listArr = [];
        responseData &&
          responseData.map((item) => {
            listArr.push({ lable: item.designation, value: item.id });
          });
        // console.log("lstadata -- ", listArr);
        this.setState({
          shopDesignationList: listArr,
        });

        // console.log("api call response.data.designation", response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          createNotification("error", "Your login session has expired .");
          this.props.history.push("/login");
        }
      });
  };

  getTitlesDropdownData = () => {
    projectServices
      .getAllTitles()
      .then((response) => {
        let responseData = response.data;
        let listArr = [];
        responseData &&
          responseData.map((item) => {
            listArr.push({ lable: item.name, value: item.id });
          });
        // console.log("lstadata -- ", listArr);
        this.setState({
          titlesList: listArr,
        });

        // console.log("api call response.data.designation", response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          createNotification("error", "Your login session has expired .");
          this.props.history.push("/login");
        }
      });
  };
  getHumanResourceEmployeeById = (empId,isAdmin) => {
    projectServices
      .getHumanResourceEmployeeById(empId)
      .then((response) => {
        
           !isAdmin ? (
            this.setState({
              employeeName: response.data[0].employee_name,
              shopDesignation: parseInt(response.data[0].shopDesignationId),
              titles: response.data[0].titleId,
              phoneNumber: response.data[0].phone_number,
              emailAddress: response.data[0].email_address,
              dob: new Date(response.data[0].dob).toISOString().split("T")[0],
              hireDate: new Date(response.data[0].hire_date)
                .toISOString()
                .split("T")[0],
                 wage: response.data[0].wage,
                  wageDateChange: new Date(response.data[0].wage_date_change)
                    .toISOString()
                    .split("T")[0] ,
              employeeId: response.data[0].id,
            }) 
            )
           :  
           this.setState({
            employeeName: response.data[0].employee_name,
            shopDesignation: parseInt(response.data[0].shopDesignationId),
            titles: response.data[0].titleId,
            phoneNumber: response.data[0].phone_number,
            emailAddress: response.data[0].email_address,
            dob: new Date(response.data[0].dob).toISOString().split("T")[0],
            hireDate: new Date(response.data[0].hire_date)
              .toISOString()
              .split("T")[0],
              //  wage: response.data[0].wage,
                // wageDateChange: new Date(response.data[0].wage_date_change)
                //   .toISOString()
                //   .split("T")[0] ,
            employeeId: response.data[0].id,
          });
        
        
        
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          createNotification("error", "Your login session has expired .");
          this.props.history.push("/login");
        }
      });
      console.log("shopDesignationList ---",this.state.shopDesignationList);
  };

  handlePhoneNumberChange = (value) =>{
    console.log("event",value);
    this.setState({ phoneNumber: value });

  }
  handleOnChange = (event) => {
    console.log("set-- ",event.target.value,typeof(event.target.value));
    
    if (event.target.name === "employee_name") {
      this.setState({ employeeName: event.target.value });
    } else if (event.target.name === "shopDesignationId") {
      this.setState({ shopDesignation: parseInt(event.target.value) });
    } else if (event.target.name === "titleId") {
      this.setState({ titles: parseInt(event.target.value) });
    }
    //  else if (event.target.name === "phone_number") {
    //   this.setState({ phoneNumber: event });
    // } 
    else if (event.target.name === "email_address") {
      this.setState({ emailAddress: event.target.value });
    } else if (event.target.name === "dob") {
      // console.log("event.target.value",event.target.value);
      this.setState({ dob: event.target.value });
      const dateString = event.target.value;
      const dateObj = new Date(dateString);
    
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // Months are zero-based
      const day = dateObj.getDate();
      this.setState({
        birthDate: day,
        birthMonth: month,
      });

      console.log("date---", day, month, year);
    } else if (event.target.name === "hire_date") {
      this.setState({ hireDate: event.target.value });
    } else if (event.target.name === "wage") { 
      // console.log("event.target.value",typeof(parseInt(event.target.value)) );
      // console.log("event.target.value",event.target.value );
      this.setState({ wage: event.target.value });
      // if(!event.target.value == ''){
      //   this.setState({ wage: event.target.value });
      // } else {
      //   this.setState({ wage: event.target.value });
      // }
       
    } else if (event.target.name === "wage_date_change") {
      this.setState({ wageDateChange: event.target.value });
    }
  };

  saveEmployeeName = () => {
    const {
      employeeName,
      shopDesignation,
      titles,
      phoneNumber,
      emailAddress,
      dob ,
      hireDate,
      wage,
      wageDateChange,
      birthDate,
      birthMonth,
    } = this.state;

    console.log("popup wage ", wage );
    // this.validator.showMessageFor(this.state.shopDesignation);
    if (shopDesignation === 0 ){
      createNotification("error", "Please Select Shop Designation.");
    } 
   else if (titles === 0 ){
      createNotification("error", "Please Select Title.");

    } else if (isNaN(wage) || wage == ''){
      createNotification("error", "Please Enter Wage.");
    }

      else {
        if (this.validator.allValid()) {
          if (this.props.employeeId === 0) {
            console.log("saveEmployeeName", employeeName, shopDesignation, titles);
            this.props.saveNewEmployee(
              employeeName,
              shopDesignation,
              titles,
              phoneNumber,
              emailAddress,
              dob,
              hireDate,
              wage,
              wageDateChange,
              birthDate,
              birthMonth
            );
          } else {
            this.props.updateEmployee(
              employeeName,
              shopDesignation,
              titles,
              phoneNumber,
              emailAddress,
              dob,
              hireDate,
              wage,
              wageDateChange,
              birthDate,
              birthMonth
            );
          }
        } else {
          this.validator.showMessages();
          this.forceUpdate();
        }
      }
   
  };

  render( ) {
    let roleKey = this.props.loginUserData.roleKey;
    const isAdminCheck = roleKey === "admin" ? true : false 

    const {
      employeeName,
      shopDesignation,
      titles,
      phoneNumber,
      emailAddress,
      dob,
      hireDate,
      wage,
      wageDateChange,
      shopDesignationList,
      titlesList,
      birthDate,
      birthMonth,
    } = this.state;
    const { closeModel } = this.props;
    // let roleKey = this.props.loginUserData.roleKey;
    let isWageEditaBle=(roleKey !== "" && rules[roleKey][Permission.WAGEINFO_WRITE]) ? "" : "disabled"
    let today = new Date();

    // Convert the current date to the yyyy-mm-dd format
    let todayFormatted = today.toISOString().split("T")[0];

    // console.log("render----", shopDesignation, titles);

    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title mt-0 text-dark fw-bold text-center"
            id="myModalLabel"
          >
            Enter human resource details{" "}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              closeModel(false);
            }}
          ></button>
        </div>
        <div className="modal-body">
          <div className="mb-3 accordWrap">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="col-md-4">Employee Name</label>
                <input
                  required=""
                  autocomplete="off"
                  type="text"
                  className="form-control col-md-2"
                  placeholder="Enter employee name here"
                  name="employee_name"
                  onChange={(e) => this.handleOnChange(e)}
                  value={employeeName}
                />
                {this.validator.message(
                  "Employee name",
                  this.state.employeeName,
                  "required|max:30",
                  { className: "text-danger" }
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="col-md-4">Phone Number</label>
                <PhoneInput
                  country={'us'}
                  inputProps={{
                    required:true,
                  }}
                  // required=""
                  // autocomplete="off"
                  // type="number"
                  // className=" col-md-2"
                  // className="number"
                  // placeholder="Enter phone number here"
                  // name="phone_number"
                  onChange={(e) => this.handlePhoneNumberChange(e)}
                  value={phoneNumber}
                />
                {this.validator.message(
                  "Phone Number",
                  this.state.phoneNumber,
                  "required|min:8|max:16",
                  { className: "text-danger" }
                )}
              </div>
              <div className="project-filter-dropdown mb-3  align-items-center">
                <label className="col-md-4">Shop Designation</label>
                <DropDown
                  name="shopDesignationId"
                  firstOption={{ lable: "All Shop Designation", value: 0 }}
                  options={shopDesignationList}
                  onChange={(e) => this.handleOnChange(e)}
                  defaultSelected={this.state.shopDesignation}
                  lable=""
                  value={shopDesignation}
                />

              </div>
              <div className="col-md-6 mb-3">
                <label className="col-md-4">Email Address</label>
                <input
                  required=""
                  autocomplete="off"
                  type="text"
                  className="form-control col-md-2"
                  placeholder="Enter email address here"
                  name="email_address"
                  onChange={(e) => this.handleOnChange(e)}
                  value={emailAddress}
                />
                {this.validator.message(
                  "Email Address",
                  this.state.emailAddress,
                  "required|email",
                  { className: "text-danger" },
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="col-md-4">Date Of Birth</label>
                <input
                  required=""
                  autocomplete="off"
                  type="date"
                  className="form-control col-md-2"
                  placeholder="Enter date of birth here"
                  name="dob"
                  onChange={(e) => this.handleOnChange(e)}
                  value={dob}
                  max={todayFormatted}
                />
                {this.validator.message(
                  "Date Of Birth",
                  this.state.dob,
                  "required",
                  { className: "text-danger" }
                )}
              </div>
              <div className="project-filter-dropdown mb-3 align-items-center">
                <label className="col-md-4">Title</label>
                <DropDown
                  name="titleId"
                  firstOption={{ lable: "All Titles", value: 0 }}
                  options={titlesList}
                  onChange={(e) => this.handleOnChange(e)}
                  defaultSelected={this.state.titles}
                  lable=""
                  value={titles}
                />
                {/* {this.validator.message(
                  "Titles",
                  this.state.titles,
                  "required|max:20",
                  { className: "text-danger" }
                )} */}
              </div>
              <div className="col-md-6 mb-3">
                <label className="col-md-4">Hire date</label>
                <input
                  required=""
                  autocomplete="off"
                  type="date"
                  className="form-control col-md-2"
                  placeholder="Enter hire date here"
                  name="hire_date"
                  onChange={(e) => this.handleOnChange(e)}
                  value={hireDate}
                  max={todayFormatted}
                />
                {this.validator.message(
                  "Hire Date",
                  this.state.hireDate,
                  "required",
                  { className: "text-danger" }
                )}
              </div>

              { !isAdminCheck ? (
                <div className="col-md-6 mb-3">
                <label className="col-md-4">Wage</label>
                <input
                  required=""
                  autocomplete="off"
                  type="number"
                  // step="any"
                  pattern="^\d*(\.\d{0,2})?$"
                  className="form-control col-md-2"
                  placeholder="Enter wage here"
                  name="wage"
                  onChange={(e) => this.handleOnChange(e)}
                  value={wage}
                  disabled={isWageEditaBle}
                />
                {/* {this.validator.message(
                  "Wage",
                  this.state.wage,
                  "required|max:20",
                  { className: "text-danger" }
                )} */}
              </div>
              )
            : " "}
              
              { !isAdminCheck ? (
                <div className="col-md-6 mb-3">
                <label className="col-md-4">Wage Change Date</label>
                <input
                  required=""
                  // disabled={isAdminCheck}
                  autocomplete="off"
                  type="date"
                  className="form-control col-md-2"
                  placeholder="Enter wage change date  here"
                  name="wage_date_change"
                  onChange={(e) => this.handleOnChange(e)}
                  value={wageDateChange}
                />
                {this.validator.message(
                  "Wage Change Date",
                  this.state.wageDateChange,
                  "required",
                  { className: "text-danger" }
                )}
              </div>
              ) 
            : " "
             }
              
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-center">
          <button
            type="button"
            className="btn btn-danger fw-bold"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              closeModel();
            }}
          >
            <i className="uil uil-times"></i> Cancel
          </button>
          <AllowedTo perform={Permission.PROJECT_WRITE}>
            <button
              className="btn btn-primary fw-bold"
              onClick={this.saveEmployeeName}
            >
              <i className="uil uil-plus"></i>
              {this.state.buttonText}
            </button>
          </AllowedTo>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AddEmployeePopUp);
