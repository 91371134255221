import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import projectServices from "../../project/services/projectServices";

import { setFreightDelFormData } from "../../project/redux/projectAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import {
  dbDateFormat,
} from "../../helpers/Util";
import { createNotification } from "../../helpers/Notification";
import { rules, Permission } from "../../../abac.config";
import { AllowedTo } from "react-abac";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaUndoAlt, FaRegWindowClose } from "react-icons/fa";
import SignaturePad from "react-signature-canvas";
import ModalPopUp from "../../common/ModalPopUp";
import { Buffer } from "buffer";
import AddTransportItem from "../AddTransportItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import EditableTransportListItems from "./EditableTransportListItems";
import CameraCapture from "./CameraCapture";
import { dataURLtoBlob } from '../../project/Helper'
import FilesPopUp from "../../project/component/FilesPopUp";
import TransportFilesPopup from "../../project/component/TransportFilesPopup";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
const FreightDeliveryPopUp = (props, ref) => {
  // console.log(props);
  const sigCanvas = React.createRef();

  const [freightDate, setFreightDate] = useState("");
  const [projectName, setProjectName] = useState("");
  const [scope, setScope] = useState("");
  const [shipFrom, setShipFrom] = useState("");
  const [shipTo, setShipTo] = useState("");
  const [comments, setComments] = useState("");
  const [shipperDate, setShipperDate] = useState("");
  const [receiverDate, setReceiverDate] = useState("");
  const [signature, setSignature] = useState("");
  const [receiver, setReceiver] = useState("");
  const [deliveredBy, setDeliveredBy] = useState("");
  const [openFileModal, setOpenFileModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isOpenFreightModal, setIsOpenFreightModal] = useState(false);
  const [isOpenEditableModal, setIsOpenEditableModal] = useState(false);
  const [isOpenCamPopUp, setIsOpenCamPopUp] = useState(false);
  const [nonEmptyDataFlag, setNonEmptyDataFlag] = useState(false);

  const [projectFreightDeliveryId, setProjectFreightDeliveryId] = useState(0);
  const [previousShipperDate, setPreviousShipperDate] = useState("");
  const [isShipperDateChanged, setIsShipperDateChanged] = useState(false);
  const [previousReceiverDate, setPreviousReceiverDate] = useState("");
  const [isReceiverDateChanged, setIsReceiverDateChanged] = useState(false);
  const [isLoadIncompleteDateChaged, setIsLoadIncompleteDateChanged] = useState(false);
  const [previousLoadInCompleteDate, setPreviousLoadIncompleteDate] = useState("");
  const [freightItemList, setFreightItemList] = useState([]);
  const [freightDelItemsId, setFreightDelItemsId] = useState(false);
  const [scrollView, setScrollView] = useState(false);
  const [projName, setProjName] = useState("");
  const [JobNumber, setJobNumber] = useState();
  const [itemId, setItemId] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  // cons[isOpenModal,setIsOpenModal]=useState(false);

  useEffect(() => {
    // console.log("componentDidMount", props);
    props.parentCallback(true);
    getFreightDelivery(props.projectId, props.selectedScope);
  }, []);
  useEffect(() => {
    setIsEdit(props.isEdit);
    getFreightDelItems(props.projectFreightDeliveryId);
    // getImagesTransport();

  }, []);

  const getFreightDelivery = (projectId, selectedScope) => {
    // console.log('inside getFreightDelivery', projectId,selectedScope);
    const { setFreightDelFormData, freightDelFormData } = props;
    let freightDelFormDataClone = { ...freightDelFormData };
    let freightData = {}
    projectServices
      .getFreightDelivery(projectId, selectedScope)
      .then((response) => {
        console.log(response);

        if (response.data.length > 0) {
          let res = response.data[0];
          const signatureImage =
            res.signature !== null
              ? Buffer.from(res.signature.data, "base64")
              : "";
          let canvas1 = document.getElementsByClassName("signatureCanvas");
          let ctx = canvas1[0].getContext("2d");
          let img1 = new Image();
          img1.onload = function () {
            ctx.drawImage(img1, 40, 10);
          };
          img1.src = signatureImage;
          freightData = {
            freightDate:
              res.freightDate !== null
                ? new Date(moment(res.freightDate).format("MM/DD/YYYY"))
                : "",
            projectName: res.project_fkey.title,
            scope: res.scope,
            shipFrom: res.shipFrom,
            shipTo: res.shipTo,
            comments: res.comments,
            receiver: res.receiver,
            deliveredBy: res.deliveredBy,
            onsiteContact: res.onsiteContact,
            shipperDate:
              res.shipperDate !== null
                ? new Date(moment(res.shipperDate).format("MM/DD/YYYY"))
                : "",
            receiverDate:
              res.receiverDate !== null
                ? new Date(moment(res.receiverDate).format("MM/DD/YYYY"))
                : "",
            signature: signatureImage,
            receiverName: (res.receiverName != null || res.receiverName != "") ? res.receiverName : "",
            incompleteLoadDate:
              (res.incompleteLoadDate !== null)
                ? new Date(moment(res.incompleteLoadDate).format("MM/DD/YYYY")) : "",
          };
          setImageFiles(res.hasOwnProperty('projectFreightDeliveryData') ? res.projectFreightDeliveryData : []);
          setIsEdit(true);
          setProjectFreightDeliveryId(res.id);
          getFreightDelItems(res.id);

          setFreightDelFormData({ ...freightDelFormDataClone, ...freightData });
          setJobNumber(res.project_fkey.jobNumber)
          setFreightDelItemsId(res.id);
        } else {
          // cross check
          //   props.setFreightDelFormData({ ...state.initialState });
          setIsEdit(false);
          setProjectFreightDeliveryId(0);
          setJobNumber(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //   setFreightDelFormData(freightData?freightData:{})
  };

  const getImagesTransport = () => {
    projectServices.getImagesTransport(props.projectId, props.selectedScope).then((res) => {
      createNotification("success", "Images uploaded successfully.")
      getFreightDelivery(props.projectId, props.selectedScope);

    }).catch((error) => {
      console.log(error);

    })
  }

  // console.log(props.freightDelFormData);
  const deleteFreightDelItems = (id) => {
    // const { projectFreightDeliveryId } = this.state;
    projectServices.deleteFreightDelItems(projectFreightDeliveryId, id).then(() => {
      createNotification("success", "Transport item deleted successfully. ");
      getFreightDelItems(projectFreightDeliveryId);
    }).catch((error) => {
      console.log(error);
    })
  }

  const handleOnChange = (event) => {

    const { setFreightDelFormData, freightDelFormData } = props;
    // const  freightDelFormData  = props.freightDelFormData;
    // const setFreightDelFormData = props.setFreightDelFormData
    // console.log("event.target.value ",event.target.value);
    // console.log("props handleOnChange",props);
    let freightDelFormDataClone = { ...freightDelFormData };
    if (event.target.name === "projectName") {
      setFreightDelFormData({ ...freightDelFormDataClone, projectName: event.target.value });
    } else if (event.target.name === "scope") {
      setFreightDelFormData({ ...freightDelFormDataClone, scope: event.target.value });
    } else if (event.target.name === "shipFrom") {
      setFreightDelFormData({ ...freightDelFormDataClone, shipFrom: event.target.value, });
    } else if (event.target.name === "shipTo") {
      setFreightDelFormData({ ...freightDelFormDataClone, shipTo: event.target.value });
    } else if (event.target.name === "comments") {
      setFreightDelFormData({ ...freightDelFormDataClone, comments: event.target.value });
    }
    else if (event.target.name === "receiver") {
      setFreightDelFormData({ ...freightDelFormDataClone, receiver: event.target.value });
    }
    else if (event.target.name === "deliveredBy") {
      setFreightDelFormData({ ...freightDelFormDataClone, deliveredBy: event.target.value });
    } else if (event.target.name === "onsiteContact") {
      setFreightDelFormData({ ...freightDelFormDataClone, onsiteContact: event.target.value });
    }
    else if (event.target.name === "receiverName") {
      setFreightDelFormData({ ...freightDelFormDataClone, receiverName: event.target.value });
    }

  }
  const clear = () => {
    sigCanvas.current.clear();
  }
  const handleFreightItemModal = (itemId) => {
    setIsOpenFreightModal(true)

    setItemId(itemId)
  }
  const closeFreightItemModal = () => {
    setIsOpenFreightModal(false);
  }
  const handleEditableModal = (open) => {
    setIsOpenEditableModal(open);
  }

  const handleCameraModal = (open) => {
    setIsOpenCamPopUp(open);
  }

  const handleDateChange = (date, type) => {
    let formatedDate = date
    const { setFreightDelFormData, freightDelFormData } = props;
    let freightDelFormDataClone = { ...freightDelFormData };
    if (type === "shipperDate") {
      let prevShipperDate;
      if (freightDelFormData.receiverDate !== '') {

        if (Date.parse(freightDelFormData.receiverDate) < Date.parse(date)) {
          createNotification("error", "Shipper date should be less than Load complete date");
        } else if (Date.parse(freightDelFormData.incompleteLoadDate) < Date.parse(date)) {
          createNotification("error", "Shipper date should be less than  Incomplete load date");
        }
        else {
          prevShipperDate = freightDelFormDataClone.shipperDate;
          setIsShipperDateChanged(true);
          setPreviousShipperDate(prevShipperDate);
          setFreightDelFormData({ ...freightDelFormDataClone, shipperDate: formatedDate });
        }
      } else {

        if (Date.parse(freightDelFormData.receiverDate) < Date.parse(date)) {
          createNotification("error", "Shipper date should be less than Load complete date");
        } else if (Date.parse(freightDelFormData.incompleteLoadDate) < Date.parse(date)) {
          createNotification("error", "Shipper date should be less than Incomplete load date");
        } else {
          setIsShipperDateChanged(true);
          setPreviousShipperDate(prevShipperDate);
          setFreightDelFormData({ ...freightDelFormDataClone, shipperDate: formatedDate });
        }

      }
    } else if (type === "receiverDate") {
      let prevRecDate;
      if (freightDelFormData.shipperDate !== '') {

        if (Date.parse(freightDelFormData.shipperDate) > Date.parse(date)) {
          createNotification("error", "Load complete date should be greater than Shipper date");
        } else if (Date.parse(freightDelFormData.incompleteLoadDate) > Date.parse(date)) {
          createNotification("error", "Incomplete load complete date should be less than Load complete date");
        }
        else {
          prevRecDate = freightDelFormDataClone.receiverDate;
          setPreviousReceiverDate(prevRecDate);
          setIsReceiverDateChanged(true);
          setFreightDelFormData({ ...freightDelFormDataClone, receiverDate: formatedDate });
        }
      } else {

        if (Date.parse(freightDelFormData.shipperDate) > Date.parse(date)) {
          createNotification("error", "Load complete date should be greater than Shipper date");
        } else if (Date.parse(freightDelFormData.incompleteLoadDate) > Date.parse(date)) {
          createNotification("error", "Incomplete load complete date should be less than Load complete date");
        } else {
          prevRecDate = freightDelFormDataClone.receiverDate;
          setPreviousReceiverDate(prevRecDate);
          setIsReceiverDateChanged(true);
          setFreightDelFormData({ ...freightDelFormDataClone, receiverDate: formatedDate });
        }

      }
    } else if (type === "incompleteLoadDate") {
      let prevIncompLoadDate;
      if (freightDelFormData.shipperDate !== "") {

        if (Date.parse(freightDelFormData.shipperDate) > Date.parse(date)) {
          createNotification("error", "Shipper date should be less than  Incomplete load date");

        } else if (Date.parse(freightDelFormData.receiverDate) < Date.parse(date)) {
          createNotification("error", " Incomplete load date should be less than Load complete date");
        }
        else {

          prevIncompLoadDate = freightDelFormDataClone.incompleteLoadDate;
          setPreviousLoadIncompleteDate(prevIncompLoadDate);
          setIsLoadIncompleteDateChanged(true);
          setFreightDelFormData({ ...freightDelFormDataClone, incompleteLoadDate: formatedDate });
        }
      } else {

        if (Date.parse(freightDelFormData.shipperDate) > Date.parse(date)) {
          createNotification("error", "Shipper date should be less than  Incomplete load date");

        } else if (Date.parse(freightDelFormData.receiverDate) < Date.parse(date)) {
          createNotification("error", "Incomplete load date should be less than Load complete date");
        } else {
          prevIncompLoadDate = freightDelFormDataClone.incompleteLoadDate;
          setPreviousLoadIncompleteDate(prevIncompLoadDate);
          setIsLoadIncompleteDateChanged(true);
          setFreightDelFormData({ ...freightDelFormDataClone, incompleteLoadDate: formatedDate });
        }

      }
    }

  }

  const getFreightDelItems = (projectFreightDeliveryId) => {
    if (projectFreightDeliveryId) {
      projectServices
        .getFreightDelItems(projectFreightDeliveryId)
        .then((res) => {
          if (res.data.data.length > 0) {
            setFreightItemList(res.data.data);
            setNonEmptyDataFlag(true)
          } else {
            setFreightItemList(res.data.data);
            setNonEmptyDataFlag(false)
          }


        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const undoShipperDate = () => {
    const { setFreightDelFormData, freightDelFormData } = props;
    // const{previousReceiverDate,isReceiverDateChanged}=state;
    let freightDelFormDataClone = { ...freightDelFormData };

    if (document.getElementById("undoShipperDate")) {
      if (isShipperDateChanged) {
        setFreightDelFormData({
          ...freightDelFormDataClone,
          shipperDate: previousShipperDate,
        });
      }
    }
  }

  const clearShipperDate = () => {
    const { setFreightDelFormData, freightDelFormData } = props;
    let freightDelFormDataClone = { ...freightDelFormData };
    if (document.getElementById("clearShipperDate")) {
      setIsShipperDateChanged(true);
      setPreviousShipperDate(freightDelFormDataClone.shipperDate);

      setFreightDelFormData({ ...freightDelFormDataClone, shipperDate: null });
    }
  };

  const undoReceiverDate = () => {
    const { setFreightDelFormData, freightDelFormData } = props;
    // const{previousReceiverDate,isReceiverDateChanged}=state;
    let freightDelFormDataClone = { ...freightDelFormData };
    if (document.getElementById("undoReceiverDate")) {
      if (isReceiverDateChanged) {
        setFreightDelFormData({
          ...freightDelFormDataClone,
          receiverDate: previousReceiverDate,
        });
      }
    }
  };
  const clearReceiverDate = () => {
    const { setFreightDelFormData, freightDelFormData } = props;
    let freightDelFormDataClone = { ...freightDelFormData };
    if (document.getElementById("clearReceiverDate")) {
      setIsReceiverDateChanged(true);
      setPreviousReceiverDate(freightDelFormDataClone.receiverDate);

      setFreightDelFormData({ ...freightDelFormDataClone, receiverDate: null });
    }
  };

  const undoIncompleteLoadDate = () => {
    const { setFreightDelFormData, freightDelFormData } = props;
    // const{previousReceiverDate,isReceiverDateChanged}=state;
    let freightDelFormDataClone = { ...freightDelFormData };
    if (document.getElementById("undoIncompleteLoadDate")) {
      if (isLoadIncompleteDateChaged) {
        setFreightDelFormData({
          ...freightDelFormDataClone,
          incompleteLoadDate: previousLoadInCompleteDate,
        });
      }
    }
  }

  const clearIncompleteLoadDate = () => {
    const { setFreightDelFormData, freightDelFormData } = props;
    let freightDelFormDataClone = { ...freightDelFormData };
    if (document.getElementById("clearIncompleteLoadDate")) {
      setIsLoadIncompleteDateChanged(true);
      setPreviousLoadIncompleteDate(freightDelFormDataClone.incompleteLoadDate);

      setFreightDelFormData({ ...freightDelFormDataClone, incompleteLoadDate: null });
    }

  }
  const updateTransportItems = (transportList, projectFreightDeliveryId) => {
    console.log(transportList);
    projectServices.updateFreightDelSingleItem(projectFreightDeliveryId, transportList).then(() => {
      createNotification("success", "item updated successfully.")
      handleEditableModal(false);
      getFreightDelItems(projectFreightDeliveryId);
    }).catch((error) => {
      console.log(error);
    })
  }

  const handleSaveTransportItem = (transportItem) => {
    const transformedTransportItems = [
      {
        quantity: transportItem.quantity || 0,
        mark: transportItem.mark || "",
        weight: transportItem.weight || 0,
        profile: transportItem.profile || "",
        itemLoaded: transportItem.itemLoaded || false,
        itemReceived: transportItem.itemReceived || false
      }
    ];
    projectServices.addFreightDelItems(props.selectedScope, props.projectId, transformedTransportItems).then(() => {
      createNotification("success", "Freight delivery items added successfully")
      getFreightDelItems(props.freightDelItemsId);
      closeFreightItemModal()
    })
      .catch((error) => {
        console.log(error);
      })
  }

 
  const handleSaveImages = (imageTransport) => {
   
    const formData = new FormData();
  
    imageTransport.forEach((imageDataUrl, index) => {
     
  
      // Create an off-screen canvas to resize/compress the image
      const img = new Image();
      img.src = imageDataUrl;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        
        const scaleFactor = .9; // 
        canvas.width = img.width * scaleFactor;
        canvas.height = img.height * scaleFactor;
        
        // Draw the image on the canvas at the new resolution
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        
        // Convert canvas back to data URL (JPEG with quality 0.7 for compression)
        const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.9); // Adjust the quality (0.0 - 1.0)
  
        // Convert compressed data URL to Blob
        const blob = dataURLtoBlob(compressedDataUrl);
        formData.append(`images${index}`, blob, `image-${index}.jpeg`); // Use JPEG extension
  
        // If it's the last image, proceed with API call
        if (index === imageTransport.length - 1) {
          projectServices.addImagesTransport(props.projectId, props.selectedScope, formData)
            .then((res) => {
              console.log(res);
              createNotification("success", "Images uploaded successfully.");
              handleCameraModal(false);
              getFreightDelivery(props.projectId, props.selectedScope);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      };
    });
  };
  

  const handleFileModalPopUp = (isOpen) => {
    setOpenFileModal(isOpen)
  }
 const handleDeleteImages=(fileId)=>{
    projectServices.deleteImagesTransport(props.projectId, props.selectedScope, props.freightDelItemsId, fileId).then((res) => {
      createNotification("success", "Image deleted successfully.")
      getFreightDelivery(props.projectId,props.selectedScope)
     

  }).catch((error) => {
      console.log(error);

  })
  }
  let roleKey = "";
  if (props.loginUserData) {
    roleKey = props.loginUserData.roleKey;
  }
  const isDisable =
    (roleKey !== "" && rules[roleKey][Permission.TRANSPORT_WRITE]) ? "" : "disabled";

  console.log(imageFiles);


  return (
    <div id="project-button-box-id" ref={ref} {...props} >
      {props.scrollStyle ?
        <div style={{
          marginLeft: "10px",
          padding: "20px 20px 0",
        }}
          className="freight-title">Bill of landing Stehl Corp : <span className='text-primary'>{props.freightDelFormData.projectName}-{JobNumber}</span>
        </div>
        : ""}
      {props.scrollStyle ?
        <div style={{
          marginLeft: "10px",
          padding: "20px 20px 0",
        }}
          className="freight-title">
          Scope Item Name : <span className='text-primary'>-{props.scopeName}</span>
        </div>
        : ""}

      <div className="freight-block">
        <div className="freight-content">
          <div className="freight-content-block">
            <fieldset>
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Print Name</label>
                        <textarea
                          placeholder="Type here"
                          required=""
                          className="form-control"
                          rows="3"
                          value={props.freightDelFormData.receiver}
                          name="receiver"
                          onChange={(e) => handleOnChange(e)}
                          maxLength="70"
                          disabled={isDisable}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Delivered By</label>
                        <textarea
                          placeholder="Type here"
                          required=""
                          className="form-control"
                          rows="3"
                          value={props.freightDelFormData.deliveredBy}
                          name="deliveredBy"
                          onChange={(e) => handleOnChange(e)}
                          maxLength="70"
                          disabled={isDisable}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Onsite Contact</label>
                        <textarea
                          placeholder="Type here"
                          required=""
                          className="form-control"
                          rows="3"
                          value={props.freightDelFormData.onsiteContact}
                          name="onsiteContact"
                          onChange={(e) => handleOnChange(e)}
                          maxLength="15"
                          disabled={isDisable}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Ship From</label>
                        <textarea
                          placeholder="Type here"
                          required=""
                          className="form-control"
                          rows="3"
                          value={props.freightDelFormData.shipFrom}
                          name="shipFrom"
                          onChange={(e) => handleOnChange(e)}
                          maxLength="200"
                          disabled={isDisable}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Ship To</label>
                        <textarea
                          placeholder="Type here"
                          required=""
                          className="form-control"
                          rows="3"
                          value={props.freightDelFormData.shipTo}
                          name="shipTo"
                          onChange={(e) => handleOnChange(e)}
                          maxLength="200"
                          disabled={isDisable}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Comment</label>
                        <textarea
                          placeholder="Type here"
                          required=""
                          className="form-control"
                          rows="3"
                          name="comments"
                          value={props.freightDelFormData.comments}
                          onChange={(e) => handleOnChange(e)}
                          maxLength="700"
                          disabled={isDisable}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    //  freightItemList.length > 0 ? 
                    (
                      <div
                        className="row"

                      >
                        <div className="col-lg-12">
                          <div
                            // className= {props.scrollStyle ? "mb-3 table-wrapper-scroll-y my-custom-scrollbar-print": "mb-3 table-wrapper-scroll-y my-custom-scrollbar"} 
                            className={props.scrollStyle ? "mb-3 table-wrapper-scroll-y my-custom-scrollbar-print" : "mb-3 table-wrapper-scroll-y my-custom-scrollbar"}
                          // className="mb-3 table-wrapper-scroll-y my-custom-scrollbar"
                          >
                            <table
                              className="table table-delivery-popup table-fixed headerMinHeight50 table table-bordered table-striped mb-0"
                              disabled={isDisable}
                            >
                              <thead className="headerTable">
                                <tr>
                                  <th className="col-xs-3 col-lg-2">Mark</th>
                                  <th className="col-xs-1 col-lg-2">Quantity</th>
                                  <th className="col-xs-3 col-lg-2">Weight</th>
                                  <th className="col-xs-3 col-lg-2">Profile</th>
                                  <th className="col-xs-3 col-lg-2">Item Loaded</th>
                                  <th className="col-xs-3 col-lg-2">Item Received</th>
                                  <th className="col-xs-2 col-lg-2" colspan="2">
                                    <div className="delete-button-box">
                                      <AllowedTo perform={Permission.TRANSPORT_WRITE}>
                                        <button type="button" className="a_BtnPlus btn-plus"
                                          onClick={() => handleFreightItemModal(0)}
                                        >
                                          <i className="uil uil-plus"></i>
                                        </button>
                                      </AllowedTo>

                                      {nonEmptyDataFlag ? <AllowedTo perform={Permission.TRANSPORTDEFAULTSCOPE_WRITE}>
                                        <OverlayTrigger
                                          key="top"
                                          placement="top"
                                          overlay={
                                            <Tooltip id={`tooltip-top`}>
                                              Edit transport item
                                            </Tooltip>
                                          }
                                        >

                                          <i className="uil uil-pen font-size-16" onClick={() => handleEditableModal(true)}></i>
                                        </OverlayTrigger>
                                      </AllowedTo> : ""}
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {freightItemList.length > 0 &&
                                  freightItemList.map((item, i) => {
                                    // console.log(item);
                                    return (
                                      <tr key={i}>
                                        <td className="col-xs-3 col-lg-2">
                                          {item.mark}
                                        </td>
                                        <td className="col-xs-1 col-lg-2">
                                          {item.quantity}
                                        </td>
                                        <td className="col-xs-3 col-lg-2">
                                          {item.weight}
                                        </td>
                                        <td className="col-xs-3 col-lg-2">
                                          {item.profile}
                                        </td>
                                        <td className="col-xs-3 col-lg-2">
                                          <input id="chkCheckbox" type="checkbox" className="form-check-input" checked={item.itemLoaded} disabled />
                                        </td>
                                        <td className="col-xs-3 col-lg-2">
                                          <input id="chkCheckbox" type="checkbox" className="form-check-input" checked={item.itemReceived} disabled />
                                        </td>
                                        <td className="col-xs-2 col-lg-2" data-rel="$" colspan="2">
                                          <AllowedTo perform={Permission.TRANSPORT_WRITE}>
                                            <div className="d-flex justify-content-center align-items-center">
                                              <OverlayTrigger key="top"
                                                placement="top"
                                                overlay={
                                                  <Tooltip id={`tooltip-top`}>
                                                    Delete transport item
                                                  </Tooltip>} >
                                                <i className="uil uil-times-circle mr-1" onClick={() => deleteFreightDelItems(item.id)}></i>
                                              </OverlayTrigger>
                                              {/* <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                  <Tooltip id={`tooltip-top`}>
                                                    Edit transport item
                                                  </Tooltip>
                                                }
                                              >
                                                <i className="uil uil-pen font-size-16" onClick={() => handleFreightItemModal(item.id)}></i>
                                              </OverlayTrigger> */}

                                            </div>
                                          </AllowedTo>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                    // : ''
                  }

                  <div className="row">
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">
                          Shipper/Delivery Date
                        </label>
                        <div className="row align-items-center">
                          <div className="col-lg-9">

                            <DatePicker
                              className="form-control date-form-control"
                              onChange={(date) =>
                                handleDateChange(date, "shipperDate")
                              }
                              dateFormat="MM/dd/yyyy"
                              placeholderText="MM/DD/YYYY"
                              selected={props.freightDelFormData.shipperDate}
                              disabled={isDisable}
                            />

                          </div>
                          <div className="col-lg-3">
                            <AllowedTo perform={Permission.TRANSPORTDEFAULTSCOPE_WRITE}>
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Undo Shipper Date
                                  </Tooltip>
                                }
                              >
                                <a
                                  href="javascript:void(0)"
                                  className="text-danger"
                                >
                                  <FaUndoAlt
                                    id="undoShipperDate"
                                    onClick={undoShipperDate}
                                  ></FaUndoAlt>
                                </a>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Clear Shipper  Date
                                  </Tooltip>
                                }
                              >
                                <button
                                  type="button"
                                  className="border-0 bg-transparent"
                                >
                                  <FaRegWindowClose
                                    id="clearShipperDate"
                                    onClick={clearShipperDate}
                                  ></FaRegWindowClose>
                                </button>
                              </OverlayTrigger>
                            </AllowedTo>
                          </div>

                        </div>
                      </div>

                    </div>
                    {/* Another date for the shipping */}
                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Incomplete Load Date</label>

                        <div className="row align-items-center">
                          <div className="col-lg-9">
                            <DatePicker
                              className="form-control date-form-control"
                              onChange={(date) =>
                                handleDateChange(date, "incompleteLoadDate")
                              }
                              dateFormat="MM/dd/yyyy"
                              placeholderText="MM/DD/YYYY"
                              selected={props.freightDelFormData.incompleteLoadDate}
                              disabled={isDisable}
                            />
                          </div>
                          <div className="col-lg-3">
                            <AllowedTo perform={Permission.TRANSPORTDEFAULTSCOPE_WRITE}>
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Undo Incomplete Load Date
                                  </Tooltip>
                                }
                              >
                                <a
                                  href="javascript:void(0)"
                                  className="text-danger"
                                >
                                  <FaUndoAlt
                                    id="undoIncompleteLoadDate"
                                    onClick={undoIncompleteLoadDate}
                                  ></FaUndoAlt>
                                </a>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Clear Incomplete Load Date
                                  </Tooltip>
                                }
                              >
                                <button
                                  type="button"
                                  className="border-0 bg-transparent"
                                >
                                  <FaRegWindowClose
                                    id="clearIncompleteLoadDate"
                                    onClick={clearIncompleteLoadDate}
                                  ></FaRegWindowClose>
                                </button>
                              </OverlayTrigger>
                            </AllowedTo>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Load Complete Date</label>
                        {/* <input className="form-control" type="date" value="2019-08-19" /> */}
                        <div className="row align-items-center">
                          <div className="col-lg-9">
                            <DatePicker
                              className="form-control date-form-control"
                              onChange={(date) =>
                                handleDateChange(date, "receiverDate")
                              }
                              dateFormat="MM/dd/yyyy"
                              placeholderText="MM/DD/YYYY"
                              selected={props.freightDelFormData.receiverDate}
                              disabled={isDisable}
                            />
                          </div>
                          <div className="col-lg-3">
                            <AllowedTo perform={Permission.TRANSPORTDEFAULTSCOPE_WRITE}>
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Undo Load Complete Date
                                  </Tooltip>
                                }
                              >
                                <a
                                  href="javascript:void(0)"
                                  className="text-danger"
                                >
                                  <FaUndoAlt
                                    id="undoReceiverDate"
                                    onClick={undoReceiverDate}
                                  ></FaUndoAlt>
                                </a>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Clear Load Complete Date
                                  </Tooltip>
                                }
                              >
                                <button
                                  type="button"
                                  className="border-0 bg-transparent"
                                >
                                  <FaRegWindowClose
                                    id="clearReceiverDate"
                                    onClick={clearReceiverDate}
                                  ></FaRegWindowClose>
                                </button>
                              </OverlayTrigger>
                            </AllowedTo>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4  ">
                      <div className="row align-items-center">
                        {/* <div className="col-lg-9"> */}
                        <label className="form-label">Receiver Name</label>
                        <div className="mb-3  " id="canvasDiv">
                          <input type="text" name="receiverName"
                            className="form-control date-form-control"
                            value={props.freightDelFormData.receiverName}
                            onChange={(e) => handleOnChange(e)}
                          />
                        </div>
                        {/* </div> */}

                      </div>

                    </div>

                    {/* camera icon component */}
                    <div className="col-lg-4 ">
                      <div className="row align-items-center">

                        <label className="form-label">Camera</label>
                        <div className="camera-input">
                          <AllowedTo perform={Permission.TRANSPORT_WRITE}>
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Open camera
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCamera}
                                size="2x"
                                onClick={() => setIsOpenCamPopUp(true)}
                              />
                            </OverlayTrigger>
                          </AllowedTo>
                          <div className="clip-Icon">
                            {
                              imageFiles.length ? <FontAwesomeIcon icon={faPaperclip} className="ml-2 mr-0" /> : ""
                            }
                          </div>
                          <>
                            <button className="view-files-btn" onClick={() => { handleFileModalPopUp(true) }}>View Images</button>
                          </>

                        </div>

                      </div>

                    </div>

                    <div className="col-lg-12">
                      <div className="mb-3" id="canvasDiv">
                        <label className="form-label">Signature Pad</label>
                        <SignaturePad
                          // ref={  setRef}
                          ref={sigCanvas}
                          canvasProps={{
                            className: "signatureCanvas form-control",
                          }}
                        />
                        {!props.scrollStyle ? (
                          <div className="text-center mt-3">
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              onClick={clear}
                              disabled={isDisable}
                            >
                              Reset
                            </button>
                          </div>
                        ) : ""}
                      </div>
                    </div>


                  </div>
                </div>

                {!props.scrollStyle ? (

                  <div className="actions clearfix saveBtn text-center">

                    <button
                      href="javascript:void(0)"
                      role="menuitem"
                      className="btn btn-primary waves-effect waves-light"
                      disabled={isDisable}
                      onClick={() => props.saveFreightDelivery(sigCanvas)}
                    >
                      Save
                    </button>
                  </div>


                ) : ""}
              </div>
              <ModalPopUp
                componentData={<AddTransportItem
                  closeModal={closeFreightItemModal}
                  // updateTransportItems={updateTransportItems}
                  projectFreightDeliveryId={props.freightDelItemsId}
                  itemId={itemId}
                  handleSaveTransportItem={handleSaveTransportItem}


                />}
                openModal={isOpenFreightModal}
              />
              <ModalPopUp
                componentData={<EditableTransportListItems
                  closeModal={handleEditableModal}

                // itemId={itemId}
                // handleSaveTransportItem={handleSaveTransportItem}


                />}
                openModal={isOpenEditableModal}
              />
              <ModalPopUp
                componentData={<CameraCapture
                  closeModal={handleCameraModal}
                  handleSaveImages={handleSaveImages}
                //  updateTransportItems={updateTransportItems}
                // projectFreightDeliveryId={props.freightDelItemsId}
                // itemId={itemId}
                // handleSaveTransportItem={handleSaveTransportItem}


                />}
                openModal={isOpenCamPopUp}
              />
              <ModalPopUp
                componentData={<TransportFilesPopup
                  closeModal={handleFileModalPopUp}
                  imageFiles={imageFiles}
                  projectId={props.projectId}
                  selectedScope={props.selectedScope}
                  freightDelItemsId={props.freightDelItemsId}
                  handleDeleteImages={handleDeleteImages}

                />}
                openModal={openFileModal}
              />
            </fieldset>
          </div>
        </div>
      </div >
    </div >
  );
};

const mapStateToProps = (props) => {
  // console.log(props);
  return {
    freightDelFormData: props.freightDelFormData,
    loginUserData: props.loginUserData,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFreightDelFormData: setFreightDelFormData,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(React.forwardRef(FreightDeliveryPopUp));