import React, { Component } from "react";
import AddEmployeePopUp from "./AddEmployeePopUp";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../common/Loader";
import { rules, Permission } from "../../abac.config";
import { Link } from "react-router-dom";
import { createNotification } from "../helpers/Notification";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination from "../project/component/Pagination/Pagination";
import BootstrapTable from "react-bootstrap-table-next";
import ModalPopUp from "../common/ModalPopUp";
import { dbDateFormat } from "../helpers/Util";
import projectServices from "../project/services/projectServices";
import AccessDenied from "../common/AccessDenied";
import ConfirmBox from "../common/ConfirmBox";
import { AllowedTo } from "react-abac";
import RecentBirthdayPopUp from "./RecentBirthdayPopUp";
import { saveAs } from "file-saver";
import { current } from "@reduxjs/toolkit";

class HumanResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowConfirmBox: false,
      loader: false,
      removeId: 0,
      isOpenAddEmployeePop: false,
      isOpenBirthdayPop: false,
      count: 0,
      humanResourceDisplayList: [],
      search: "",

      //pagination constants
      pageChange: 1,
      skip: 0,
      limit: 10,
      count: 0,
      //length of the response data-----
      responseDataLength:0,
    };
  }

  componentDidMount = () => {
  
   this.getHumaresourceData();
  };

  
  getHumaresourceData = () => {
    if (window.history.state?.state !== undefined) {
      
      console.log(window.history.state);
      const currentPage = window.history?.state?.state?.data.key;
      const currentSkip = window.history?.state?.state?.data.keySkip;
      const currentLimit = window.history?.state?.state?.data.keyLimit;
      const currentSearch = window.history?.state?.state?.data.keySearch;
      
      localStorage.removeItem('shopMaintenancePaginationConstants');
      localStorage.removeItem('fieldMaintenancePaginationConstants');
      
      this.setState({ pageChange: currentPage, skip: currentSkip, loader: true, limit: currentLimit, search: currentSearch }, () => {
        let filters = `?filter[skip]=${currentSkip}&filter[limit]=${currentLimit}`;
        if (this.state.search.length > 0) {
        
          this.getSearchedHumanResourceEmployee(filters);
        } else {
          this.getHumanResourceCount(filters);
        }
      });
    } else if (JSON.parse(localStorage.getItem('humanResourcePaginationConstants')) !== null) {
     
      const hrpagination = JSON.parse(localStorage.getItem('humanResourcePaginationConstants'));
      const { currentPage, currentSkip, currentLimit, searchValue } = hrpagination;
      
      this.setState({ pageChange: currentPage, skip: currentSkip, limit: currentLimit, search: searchValue }, () => {
        let filters = `?filter[skip]=${currentSkip}&filter[limit]=${currentLimit}`;
        if (this.state.search.length > 0) {
          this.getSearchedHumanResourceEmployee(filters);
        } else {
          this.getHumanResourceCount(filters);
        }
      });
    }else{
      
      this.setState({}, () => {
         let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`
        this.getHumanResourceCount(filters);
      });
    }
  
   
  };
  

  getHumanResourceCount = (filters) => {
    // let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    let countReqParam = `where={}`;
    projectServices
      .getHumanResourceCount(countReqParam)
      .then((response) => {
        this.setState({ count: response.data.count }, () => {
          this.getAllHumanResourceEmployees(filters);
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          createNotification("error", "Your login session has expired .");
          this.props.history.push("/login");
        }
      });
  };

  getAllHumanResourceEmployees = (filters) => {
    projectServices
      .getHumanResourcesEmployees(filters)
      .then((response) => {
        setTimeout(() => {
          this.setState({
            humanResourceDisplayList: response.data,
            loader: false,
            responseDataLength:response.data.length,
          });
        }, 500);
        localStorage.removeItem('humanResourcePaginationConstants')
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleCloseAddEmployeeModal = () => {
    this.setState({ isOpenAddEmployeePop: false });
  };
  handleConfirmModal = (isYes) => {
    if (isYes) {
      this.setState({
        isShowConfirmBox: !this.state.isShowConfirmBox,
        loader: true,
      });
      
    const{skip,limit,pageChange,responseDataLength,removeId,search}=this.state;
    let localSkip=0;
    let localPageChange=0;
    let filters; 
    if(responseDataLength===1 && pageChange>1){
       localSkip=skip-limit;
       localPageChange=pageChange-1;
      this.setState({pageChange:localPageChange,skip:localSkip})
         filters = `?filter[skip]=${localSkip}&filter[limit]=${this.state.limit}&filter[where][deleted]=${this.state.flagvalue}`
     }
     else{
         filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}&filter[where][deleted]=${this.state.flagvalue}`
      } 
      projectServices
        .deleteHumanResourceEmployee(removeId)
        .then((response) => {
          createNotification(
            "success",
            "The employee item deleted successfully ."
          );
          if(search.length>0){
            this.getSearchedHumanResourceEmployee(filters);
          }else{
            this.getHumanResourceCount(filters);
          }
          //  this.setState({ pageChange: 1 ,skip:0});
          
        })
        .catch((error) => {
          console.log(error);
        });
      
      
    } else {
      this.setState({ removeId: 0, isShowConfirmBox: false, loader: false });
    }
  };
  actionFormat = (cell, row, rowIndex, formatExtraData) => {
    const {pageChange,skip,search,limit} = this.state;
    const data = { key: "Single",
      currentPage:pageChange,
      currentSkip:skip,
      searchValue:search,
      currentLimit:limit 
    };
    
    console.log(data);
    return (
      <div>
        <td>
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <Link  
              to={{
                pathname: `/addEmployee/${row.id}`,
                state: { data: data },
              }}
              >
                <i className="uil uil-pen font-size-18"></i>
              </Link>
            </li>
            <li className="list-inline-item">
              <a href="javascript:void(0)" className="text-danger">
                <i
                  className="uil uil-trash-alt font-size-18"
                  onClick={() =>
                    this.setState({ removeId: row.id, isShowConfirmBox: true })
                  }
                ></i>
              </a>
            </li>
          </ul>
        </td>
      </div>
    );
  };

  handleRecentBirthdays = () => {
    this.setState({
      isOpenBirthdayPop: true,
    });
  };
  handleCloseRecentBirthdays = () => {
    this.setState({ isOpenBirthdayPop: false });
  };

  downloadHRReport = () => {
    // const{slocationId,isManuallyCreated,search,isSearch}=this.state;
    const URL = process.env.REACT_APP_API_URL;
    projectServices
      .getHumanResourceRecord()
      .then((res) => {
        if (res.data.status) {
          const fileLink = `${URL}${res.data.data}`;
          const pathArr = fileLink.split("/");
          saveAs(
            // `${process.env.REACT_APP_API_URL}'/'${fileLink}`
            fileLink,
            pathArr[pathArr.length - 1]
          );
          createNotification(
            "success",
            "Human Resource record downloaded successfully."
          );
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 422) {
          if (error.response.message) {
            createNotification("error", error.response.message);
          } else {
            createNotification("error", "No Human Resource data exists.");
          }
        }
      });
  };
  handleSearchUser = (event) => {
    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
   if(event.target.name==="search"){
    if(event.target.value!==""){
      this.setState({ search: event.target.value ,pageChange:1,skip:0,limit:10}, () => {
        // this.getAllHumanResourceEmployees(filters);
        this.getSearchedHumanResourceEmployee(filters);
      });
    }else{
      let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
     this.setState({search:"",pageChange:1,limit:10,skip:0},()=>{
      this.getHumanResourceCount(filters);
     })
      
    }
   }
  }

  getSearchedHumanResourceEmployee=(filters)=>{
    // let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
  projectServices.getSearchedHumanResourcesEmployees(this.state.search,filters).then((response)=>{
   
        this.setState({
          humanResourceDisplayList: response.data.data,
          responseDataLength:response.data.data.length,
          count:response.data.count,
         loader:false
        });
        localStorage.removeItem('humanResourcePaginationConstants')
  }).catch((error)=>{
    console.log(error);
  })
  }
  render() {
    let roleKey = this.props.loginUserData.roleKey;
    const isAccess =
      roleKey !== "" && rules[roleKey][Permission.HUMANRESOURCE_WRITE]
        ? true
        : false;
      let isAdmin = roleKey === "admin" ? true : false 
    const {
      isShowConfirmBox,
      loader,
      humanResourceDisplayList,
      pageChange,
      limit,
      count,
      search
    } = this.state;

    const sizePerPageOptions = [
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
      {
        text: '30',
        value: 30,
      },
      {
        text: '50',
        value: 50,

      },
      {
        text: '100',
        value: 100,
      },
      // Add more options as needed
    ];

    //-Pagination Constant-----------------------------------------------------//
    let that = this;
    const pagination = paginationFactory({
      page: pageChange,
      sizePerPage: limit,
      lastPageText: ">>",
      firstPageText: "<<",
      nextPageText: ">",
      prePageText: "<",
      totalSize: count,
      // showTotal: true,
      alwaysShowAllBtns: true,
      sizePerPageList:sizePerPageOptions,
      onPageChange: function (page, sizePerPage) {
         let skp = (page - 1) * sizePerPage;
        console.log(page);
        // let skp = 0 ;
        let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
        that.setState({
          pageChange: page,
          humanResourceDisplayList: [],
          loader: true,
          skip:skp
        },()=>{
          search.length>0?that.getSearchedHumanResourceEmployee(filters): that.getAllHumanResourceEmployees(filters);
        });
      
      // that.getAllHumanResourceEmployees(filters);  
    },
      onSizePerPageChange: function (sizePerPage, page) {
        console.log(page);
         let skp = (page - 1) * limit;
        // let skp = 0;
        that.setState({
          pageChange: page,
          limit: sizePerPage,
          skip:skp,
          humanResourceDisplayList: [],
          loader: true,
        },()=>{
          let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
        search.length>0?that.getSearchedHumanResourceEmployee(filters): that.getAllHumanResourceEmployees(filters);
        });
        
        // that.getAllHumanResourceEmployees(filters);
      },
    });
    //------------------------------------------------------------------------//

    const handleOnTableChange = (type, newState) => {

      if(type === "sort"){
        if (newState.sortOrder === "asc") {
          humanResourceDisplayList.sort((obj1, obj2) => {
            return newState.sortField=="wage"?
            obj1[newState.sortField]-obj2[newState.sortField]:
  
            newState.sortField=="fkey_shopdesignation.designation" ? 
            (obj1.fkey_shopdesignation.designation
              .trim()
              .localeCompare(obj2.fkey_shopdesignation.designation.trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              }))  :
               newState.sortField=="fkey_title.name"  ? 
               (obj1.fkey_title.name
                .trim()
                .localeCompare(obj2.fkey_title.name.trim(), undefined, {
                  numeric: true,
                  sensitivity: "base",
                })) :
             (!obj1[newState.sortField] == null ? 
              obj1[newState.sortField].trim()
              .localeCompare(obj2[newState.sortField].trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              }) : (obj1[newState.sortField]
              .localeCompare(obj2[newState.sortField].trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              })))
          });
        } else {
          humanResourceDisplayList.sort((obj2, obj1) => {
            return   newState.sortField =="wage" ?
            obj1[newState.sortField]-obj2[newState.sortField]:
  
             newState.sortField =="fkey_shopdesignation.designation" ? 
             (obj1.fkey_shopdesignation.designation
              .trim()
              .localeCompare(obj2.fkey_shopdesignation.designation.trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              })) : 
              newState.sortField =="fkey_title.name" ? 
             ( obj1.fkey_title && obj1.fkey_title.name
              .trim()
              .localeCompare(obj2.fkey_title.name.trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              })) 
               :  
               (!obj1[newState.sortField] == null ? 
               obj1[newState.sortField].trim()
              .localeCompare(obj2[newState.sortField].trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              }): (obj1[newState.sortField]
              .localeCompare(obj2[newState.sortField].trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              })) )
          });
        }

      }

      
    };

    const CustomDateFormatter = (cell) => {
      const dateObject = new Date(cell);
      const formattedDate = dateObject.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC", // Make sure to set the correct timezone
      });

      return <span>{formattedDate}</span>;
    };

    const columns = [
      {
        dataField: "employee_name",
        text: "Name",
        sort: true,
        headerStyle: () => {
          return { width: "15%", verticalAlign: "top" };
        },
      },
      {
        // dataField: "shopDesignationId",
        dataField: "fkey_shopdesignation.designation",
        text: "Shop  Designation",
        sort: true,
        headerStyle: () => {
          return { width: "10%", verticalAlign: "top" };
        },
      },
      {
        dataField: "phone_number",
        text: "Phone",
        sort: true,
        headerStyle: () => {
          return { width: "10%", verticalAlign: "top" };
        },
      },
      {
        dataField: "email_address",
        text: "Email",
        sort: true,
        headerStyle: () => {
          return { width: "15%", verticalAlign: "top" };
        },
      },
      {
        dataField: "dob",
        text: "Date Of Birth",
        sort: true,
        headerStyle: () => {
          return { width: "10%", verticalAlign: "top" };
        },
        formatter: CustomDateFormatter,
      },
      {
        dataField: "hire_date",
        text: "Date Of Hire",
        sort: true,
        formatter: CustomDateFormatter,
        headerStyle: () => {
          return { width: "10%", verticalAlign: "top" };
        },
      },
      {
        // dataField: "titleId",
        dataField: "fkey_title.name",
        text: "Title",
        sort: true,
        headerStyle: () => {
          return { width: "10%", verticalAlign: "top" };
        },
      }, 
      {
        dataField: "wage",
        text: "Wage",
        sort: true,
        headerStyle: () => {
          return isAdmin ? {display: "none"} : { width: "10%", verticalAlign: "top" };
        },
        style:()=>{
          return isAdmin ? {display :"none"}: "";
        }
      },
      {
        dataField: "wage_date_change",
        text: "Date Of Wage Change",
        sort: true,
        
        formatter: CustomDateFormatter,
        headerStyle: () => {
          return isAdmin ? {display: "none"}  : { width: "15%", verticalAlign: "top" };
        },
        style:()=>{
          return isAdmin ?  {display :"none"} : "";
        }
      },
      {
        dataField: "",
        text: "Action",
        formatter: this.actionFormat,
        headerStyle: () => {
          return { width: "30%", verticalAlign: "top" };
        },
      },
    ];

    return (
      <div>
        {loader === true ? (
          <Loader />
        ) : (
          <div className="main-content shopPersonal">
            <ConfirmBox
              openModal={isShowConfirmBox}
              confirmMsg="Are you sure to delete this human resource employee?"
              handleConfirmModal={this.handleConfirmModal}
            />
            <div className="page-content">
              <div className="container-fluid">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <ol className="breadcrumb m-0">
                    <Link to={`/#`}>
                      <li className="breadcrumb-item">Home</li>
                    </Link>
                    &nbsp; / &nbsp;
                    <Link to={`/humanResource`}>
                      <li className="breadcrumb-item">Human Resource</li>
                    </Link>
                  </ol>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-4 mb-3"></div>
                  <AllowedTo perform={Permission.HUMANRESOURCE_WRITE}>
                    <div className="d-flex justify-content-between  mt-3 mb-3">
                      <div className="project-button-box m-2">
                        
                        <Link to="/addEmployee">
                          <span className="btn btn-primary">
                            <i className="uil uil-plus"></i> Add Human Resource
                          </span>
                        </Link>
                      </div>
                      <div className="project-button-box m-2">
                        <button
                          variant="outline-primary"
                          className="btn btn-primary waves-effect waves-light"
                          onClick={this.downloadHRReport}
                        >
                          Download report
                        </button>
                      </div>
                      <div className="col-md-3 m-2">
                        <div className="form-inline float-md-end mb-3">
                          <div>
                            <div className="autocomplete-block">
                              <input  autoComplete='off' className="search-inventory-text form-control" type="text" name="search" value={this.state.search} placeholder="Search..." onChange={(e) => this.handleSearchUser(e)} />
                              
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="project-button-box m-2">
                        <button
                          className="btn btn-primary"
                          onClick={this.handleRecentBirthdays}
                        >
                          <i className="uil "></i> Recent Birthdays
                        </button>
                      </div>

                    </div>
                  </AllowedTo>

                  {loader === true ? (
                    <Loader />
                  ) : (
                    <div className="mt-3">
                      <div className="card card-block">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-12 table-responsive">
                              <div className="mt-2 ">
                                <BootstrapTable
                                  remote={true}
                                  keyField="id"
                                  data={
                                    humanResourceDisplayList.length > 0
                                      ? humanResourceDisplayList
                                      : []
                                  }
                                  onTableChange={handleOnTableChange}
                                  columns={columns}
                                  pagination={pagination}
                                  noDataIndication={
                                    loader === true ? (
                                      <Loader />
                                    ) : (
                                      <p style={{ color: "red" }}>
                                        {"No Data Found"}
                                      </p>
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /* model to open items in each project and make them editable */}
                        <ModalPopUp
                          componentData={
                            <RecentBirthdayPopUp
                              closeModel={this.handleCloseRecentBirthdays}
                            // saveNewEmployee={this.saveNewEmployee}
                            // employeeId={this.state.employeeId}
                            // updateEmployee={this.updateEmployee}
                            />
                          }
                          openModal={this.state.isOpenBirthdayPop}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (props) => {
  return {
    projectData: props.projectData,
    selectedScopeItemID: props.selectedScopeItemID,
    loginUserData: props.loginUserData,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(HumanResource);
