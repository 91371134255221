import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Loader from '../../common/Loader';
import axios from 'axios';
import { getLoginToken } from '../../helpers/Util';
import { getApiUrl } from '../../helpers/Util';
import { getManagerList } from '../../project/Helper';
import BootstrapTable from 'react-bootstrap-table-next';
import { setCalendarName } from '../../project/redux/projectAction';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ModalPopUp from '../../common/ModalPopUp';
import { createNotification } from '../../helpers/Notification';
import ScopeItemListComp from './ScopeItemListComp';



import { Link } from "react-router-dom";
import { event } from 'jquery';
class ProjectListingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    //  isTransportDateSelected: this.props.location.state?.isTransportDateSelected,
      projectList: [],
      managerList: [],
      loader: false,
      isModalPopUpOpen: false,
      projectId: 0,

      managerId: '',

      pageChange: 1,
      skip: 0,
      limit: 20,
      count: 0,
    }

  }

  componentDidMount = () => {
    getManagerList().then((response) => {
      this.setState({ managerList: [...response] });
    });
    let filters;
    if(localStorage.getItem('projectListConstants')!==null){
     
      const projectListConstant=JSON.parse(localStorage.getItem('projectListConstants'))
      const{pageLimit,pageSkip,page,managerId}=projectListConstant
      this.setState({limit:pageLimit,skip:pageSkip,pageChange:page,managerId:managerId},()=>{
        filters = `?filter[skip]=${pageSkip}&filter[limit]=${pageLimit}`
        this.getProjectList(filters);
      })
      
    }else{
     
      filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
      this.getProjectList(filters);
    }
    
   
   
  }

  getProjectList = (filters) => {
    const { managerId, } = this.state
    const token = getLoginToken();
    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": `Bearer ${token}`
    }
    this.setState({ loader: true });
    let queryStr = "&";
    if (managerId !== "0" && managerId !== 0 && managerId !== "") {
     
      queryStr = `${queryStr}filter[where][manager]=${managerId}`
    }

    const URL = getApiUrl();
    const url = `${URL}/projects${filters}${queryStr}`;
    let listArr = [];
    axios.get(url, {
      headers: headers
    })
      .then((response) => {
        if (response.status) {
          let responseData = response.data.data;
          let totalCount = response.data.count;
          
          this.setState({ count: totalCount, loader: false });
          this.getProjectData(responseData);
        }
      })
      .catch((error) => {
        if(error.response && error.response.status === 401){
          if(localStorage.getItem('user-token') && localStorage.getItem('user-token') !==""){
              createNotification("error", "Your login session has been expired!");
          }
          localStorage.clear();
          this.props.history.push('/login');
          this.setState({loader:false});
      }else{
          this.setState({loader:false});
      }
      })
    return listArr;
  }
  getProjectData=(projectData)=>{
    let dataList=projectData.map((item,index)=>{
      item.JobNumberAndProjectName=(item.jobNumber && item.title!==""||null)? item.title+" - "+item.jobNumber:"N/A";
      return item;
    })
     dataList.sort((a, b) => {
			if (a.JobNumberAndProjectName && b.JobNumberAndProjectName) {
				return a.JobNumberAndProjectName > b.JobNumberAndProjectName ? 1 : -1
			} else {
				return -1
			}
			// console.log(onlyProjects);
		});
    this.setState({projectList:dataList})
  }
  actionFormat = (cell, row, rowIndex, formatExtraData) => {
    // const data = { key: "group" };

    // const{isTransportDateSelected} =this.props

    return (

      <div>
        {

          <td>
            <ul className="list-inline mb-0">

              <OverlayTrigger
                key="topEdit"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>View Scope Items</Tooltip>
                }
              >

                <li className="list-inline-item">
                  {/* <a className="uil uil-pen font-size-18" onclick={() => this.handleOpenModelClick(row.id)}></a> */}
                  <a href="javascript:void(0)" > <i className="uil uil-eye text-warning action-btn font-size-18" 
                         onClick={()=>this.setState({projectId:row.id},()=>{
                          this.handleOpenModelClick()
                         })}
                        ></i></a>
                </li>
              </OverlayTrigger>




            </ul>
          </td>
        }

      </div>
    );
  };
  handleOpenModelClick = () => {
    // this.props.setCalendarName(this.state.setCalendarName);
    this.setState({ isModalPopUpOpen: true });
    localStorage.setItem('projectListConstants', JSON.stringify({ pageLimit:this.state.limit, pageSkip: this.state.skip, page: this.state.pageChange,managerId:this.state.managerId}));
  }
  handleCloseModal = () => {
    this.setState({ isModalPopUpOpen: false });
  }

  handleOnChange=(event)=>{
    // let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    if (event.target.name === "projectManager") {
      this.setState({ managerId: event.target.value,pageChange:1,skip:0,limit:20},() =>{
          this.getProjectList(`?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`)
      });
    }
  }
  
  render() {
    const { loader, projectList, pageChange, limit, count, isTransportDateSelected, managerList } = this.state;


    const sizePerPageOptions = [
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
      {
        text: '30',
        value: 30,
      },
      {
        text: '50',
        value: 50,

      },
      {
        text: '100',
        value: 100,
      },
      // Add more options as needed
    ];

    const columns = [
      {
        dataField: "JobNumberAndProjectName",
        text: "Project Name and Job Number",
        sort: true,
        headerStyle: () => {
          return { verticalAlign: "top" };
        },
        
      },
     
        
      
      // {
      //   dataField: "",
      //   text: "Total Employee",
      //   sort: true,
      //   headerStyle: () => {
      //     return { width: "30%",verticalAlign: "top" };
      //   },
      // },
      {
        dataField: "",
        text: "Action",
        formatter: this.actionFormat,
        headerStyle: () => {
          return { width: "30%", verticalAlign: "top" };
        },
      },
    ];

    //-Pagination Constant-----------------------------------------------------//
    let that = this;
    const pagination = paginationFactory({
      page: pageChange,
      sizePerPage: limit,
      lastPageText: ">>",
      firstPageText: "<<",
      nextPageText: ">",
      prePageText: "<",
      totalSize: count,
      // showTotal: true,
      alwaysShowAllBtns: true,
      sizePerPageList: sizePerPageOptions,
      onPageChange: function (page, sizePerPage) {
        let skp = (page - 1) * sizePerPage;
        let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
        that.setState({
          pageChange: page,
          skip:skp,
          allGroupList: [],
          loader: true,
        });
        that.getProjectList(filters);
        // this.getAllGroupCount(filters);
      },
      onSizePerPageChange: function (sizePerPage, page) {
        let skp = (page - 1) * limit;
        // let skp =0;
        that.setState({
          pageChange: page,
          skip:skp,
          limit: sizePerPage,
          loader: true,
        });
        let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;

        that.getProjectList(filters);
        // this.getAllGroupCount(filters);
      },
    });
    //------------------------------------------------------------------------//

    const handleOnTableChange = (type, newState) => {
      // console.log("newState.sortField",newState.sortField);
      if (type == "sort") {
        if (newState.sortOrder === "asc") {
          projectList.sort((obj1, obj2) => {
            // return obj1[newState.sortField]
            //   .trim()
            //   .localeCompare(obj2[newState.sortField].trim(), undefined, {
            //     numeric: true,
            //     sensitivity: "base",
            //   });
            if (newState.sortField == "JobNumberAndProjectName"){
              return   obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
            }
            // else if(newState.sortField == "jobNumber"){
            //   return   obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
            // } 
            else {
              return obj1[newState.sortField]-obj2[newState.sortField]
            }
          });
        } else {
          projectList.sort((obj2, obj1) => {
            // return obj1[newState.sortField]
            //   .trim()
            //   .localeCompare(obj2[newState.sortField].trim(), undefined, {
            //     numeric: true,
            //     sensitivity: "base",
            //   });
            // if(newState.sortField == "title"){
            //   return   obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
            // }
            if (newState.sortField == "JobNumberAndProjectName"){
              return   obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
            }
            // else if(newState.sortField == "jobNumber"){
            //   return   obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
            // }
             else {
              return obj1[newState.sortField]-obj2[newState.sortField]
            }
          });
        }
      }
    };

    return (
      <div>
        {loader === true ?<Loader/>:
        <div className="main-content shopPersonal">
          <div className="page-content">
            <div className="container-fluid">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <ol className="breadcrumb m-0">
                  <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                  &nbsp; / &nbsp;
                  {localStorage.getItem('calendarName')=="transportcalendar"? <Link to={`/transport`}><li className="breadcrumb-item">Transport Calendar</li></Link>:
                  localStorage.getItem('calendarName')=='shopcalendar'? <Link to={`/shopPersonal`}><li className="breadcrumb-item">Shop Personal Calendar</li></Link>:localStorage.getItem('calendarName')=="fieldcrewcalendar"? <Link to={`/fieldcrewcalender`}><li className="breadcrumb-item">Field Crew Calendar</li></Link>:""}
                  &nbsp; / &nbsp;
                  <Link to={`/dropDownPage`}><li className="breadcrumb-item">Project Listing</li></Link>
                  
                </ol>
              </div>
              <div className='row mb-3'>
                <div className='col-lg-4'>
                  <label className="form-label"> Project Listing</label>

                  <div className="project-filter-dropdown d-flex align-items-center">
                    <select className="form-select  calendar-drop-down-btn"
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.managerId}
                      name="projectManager">
                      <option value="0">Select Project Manager</option>
                      {managerList.length && managerList.map((itemData) => {
                        return (
                          <option value={itemData.value}>
                            {itemData.lable}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div>

                <div className="card card-block">
                  <div className="card-body">
                    <div className='row'>
                      <div className="col-lg-12 table-responsive">
                        <div className="mt-2 projListing">
                          <BootstrapTable
                            remote={true}
                            keyField="id"
                            data={
                              projectList.length > 0 ? projectList : []
                            }
                            onTableChange={handleOnTableChange}
                            columns={columns}
                            pagination={pagination}
                            noDataIndication={
                              loader === true ? (
                                <Loader />
                              ) : (
                                <p style={{ color: "red" }}>
                                  {"No Data Found"}
                                </p>
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <ModalPopUp
                    componentData={
                      <ScopeItemListComp
                        projectId={this.state.projectId}
                        handleCloseModal={this.handleCloseModal}
                      />
                    }
                    openModal={this.state.isModalPopUpOpen}
                  />

                </div>

              </div>
            </div>
          </div>

        </div>}
        


      </div>
    )
  }
}
const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
    
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
 setCalendarName:setCalendarName
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectListingPage);
