import { Component } from "react";

class FieldItemPopUp extends Component{
    constructor(props){
        super(props);
        // Handle Update Case :---
        let fieldItemArr = [];
        const {fieldDataforUpdate,maintenanceFieldTypeFields,modalStatus} = this.props
        if(modalStatus === 'update'){
            fieldDataforUpdate.maintenanceFieldMasterFields.sort((a, b) => a.maintenanceFieldTypeFieldId - b.maintenanceFieldTypeFieldId);
            maintenanceFieldTypeFields.sort((a,b) => a.id - b.id);
            maintenanceFieldTypeFields &&
            maintenanceFieldTypeFields.map((fieldVal,fieldIndex) => {
                if(fieldDataforUpdate.maintenanceFieldMasterFields && fieldDataforUpdate.maintenanceFieldMasterFields.length === maintenanceFieldTypeFields.length){
                    if(fieldDataforUpdate.maintenanceFieldMasterFields[fieldIndex].maintenanceFieldTypeFieldId === fieldVal.id){
                        fieldItemArr[fieldIndex] = fieldDataforUpdate.maintenanceFieldMasterFields[fieldIndex].value;
                    }
                }else{
                    if(fieldDataforUpdate.maintenanceFieldMasterFields[fieldIndex] !== undefined && fieldDataforUpdate.maintenanceFieldMasterFields[fieldIndex].maintenanceFieldTypeFieldId === fieldVal.id){
                        fieldItemArr[fieldIndex] = fieldDataforUpdate.maintenanceFieldMasterFields[fieldIndex].value;
                    }
                }
            })
        }
        
        // -----
        this.state = {
            fieldState:[],
            fieldStateError:'',
            fieldStateStatus:false,

            itemName:(modalStatus === 'update' ? fieldDataforUpdate.name : ''),
            itemNameError:'',
            itemNameStatus:false,
            
            updateItems:(modalStatus === 'update' && fieldItemArr !== undefined ? fieldItemArr : []),
            updateItemError:[],
            updateItemStatus:false,
        }
    }
    
    //---------------------------------------------------------//
    saveItemDetails = (event) =>{
        event.preventDefault();
        let itemName       = event.target.itemName.value;
        const {fieldState} = this.state;
        const { maintenanceFieldTypeFields } = this.props;
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;
        let isError = false;
        //-----------

        if(itemName !== '' && itemName.trim().length === 0 ){
            isError = true;
            this.setState({itemNameError:"This is an invalid input"});
        }else if(itemName === ''){
            isError=true;
            this.setState({itemNameError:"Field name is required*"});
        }else if(itemName.length>100){
           isError=true;
           this.setState({itemNameError:"The name field cannot be greater then 100 characters"});
        }else{
            this.setState({itemNameError:""});
        }
        
        //-----------
        if(fieldState.length === 0){
            isError=true;
            this.setState({fieldStateError:' is required*'})
        }else if(fieldState.length !== 0){
            this.setState({fieldStateError:''})
            let itemError = [];
            maintenanceFieldTypeFields.map((item,index) => {
                console.log(fieldState[index].value.length);
                if(fieldState[index] !== undefined && fieldState[index].value !== '' && fieldState[index].value.trim().length === 0){
                    isError = true;
                    itemError[index] = 'This is an invalid input'
                }else if(fieldState[index] === undefined || fieldState[index].value === ''){
                    isError = true;
                    itemError[index] = ' is required*';
                }else if(fieldState[index] === undefined && item.name !== event.target.name || fieldState[index].value === ''){
                    isError = true
                    itemError[index] = ' is required*'
                }else if(fieldState[index].value.length>100){
                    isError = true;
                    itemError[index] = ' field cannot be greater then 100 characters *';
                }
                else if(fieldState[index] !== undefined && item.name === event.target.name || fieldState[index].value !== ''){
                    itemError[index] = '';
                }
            })
            if(isError === true){
                this.setState({updateItemError:itemError})
            }else{
                this.setState({updateItemError:itemError})
            }
        }
        
        //-----------
        if(this.state.itemNameStatus === false && this.state.fieldStateStatus=== false && this.state.updateItemStatus === false && isError === false){
            this.props.saveFieldItem(itemName.trim(),fieldState);
        }
    }
//-----------------------------------------------------------------//

    handleOnchange = (event) => {
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;
        const { modalStatus,maintenanceFieldTypeFields } = this.props;
        
        if(modalStatus === 'add'){
             
            //(1) Field Name validation:--
             if(event.target.name === 'itemName'){
                let itmName = event.target.value;
                if(itmName !== '' && itmName.trim().length === 0 ){
                    this.setState({itemNameError:"This is an invalid input",itemNameStatus:true});
                }else if(itmName === ''){
                    this.setState({itemNameError:"Field name is required*",itemNameStatus:true});
                }else{
                    this.setState({itemNameError:"",itemNameStatus:false});
                }
            }

            // Dynamic field content array---    
            let fieldArr = [...this.state.fieldState];
            maintenanceFieldTypeFields.map((fieldValue,fieldIndex) => {
                if(event.target.name === fieldValue.name){
                    fieldArr[fieldIndex] = {
                        maintenanceFieldTypeFieldId:Number(event.target.dataset.id),
                        value:event.target.value.trim()
                    }
                }
            })

            // (2) Dynamic Field Content Validation:--
            if(fieldArr.length === 0 ){
                this.setState({fieldStateError:' is required*',fieldStateStatus:true})
            }else if(fieldArr.length !== 0){
                this.setState({fieldStateError:'',fieldStateStatus:false})
                let itemError = [];
                maintenanceFieldTypeFields.map((item,index) => {
                    let itemNameValue = document.querySelector('input[name=itemName]').value;
                    if(itemNameValue === ''){
                        this.setState({itemNameError:"Field name is required*",itemNameStatus:true});
                    }else if(itemNameValue !== '' && itemNameValue.trim().length === 0){
                        this.setState({itemNameError:"This is an invalid input",itemNameStatus:true});
                    }else{
                        this.setState({itemNameError:"",itemNameStatus:false});
                    }
                    //--------------------------------------------------------//
                    if(fieldArr[index] === undefined && item.name !== event.target.name || fieldArr[index].value === ''){
                        itemError[index] = ' is required*';
                        this.setState({updateItemError:itemError,updateItemStatus:true})
                    }else if(fieldArr[index].value.trim().length === 0){
                        itemError[index] = 'This is an invalid input';
                        this.setState({updateItemError:itemError,updateItemStatus:true})
                    }else if(fieldArr[index] !== undefined && fieldArr[index].value !== '' && item.name === event.target.name ){
                        itemError[index] = '';
                        this.setState({updateItemError:itemError,updateItemStatus:false})
                    }
                })
            }

            this.setState({
                [event.target.name]: event.target.value,
                fieldState:fieldArr,
            });
        
        }else if(modalStatus === 'update'){
            
            //(1) Field Name validation:--
            if(event.target.name === 'itemName'){
                let itmName = event.target.value;
                if(itmName !== '' && itmName.trim().length === 0){
                    this.setState({itemNameError:"This is an invalid input",itemNameStatus:true});
                }else if(itmName === ''){
                    this.setState({itemNameError:"Field name is required*",itemNameStatus:true});
                }else{
                    this.setState({itemNameError:"",itemNameStatus:false});
                }
            }
        
            let updateItems = [...this.state.updateItems];
            let fieldArr    = [...this.state.fieldState];
            maintenanceFieldTypeFields.map((fieldValue,fieldIndex) => {
                if(event.target.name === fieldValue.name){
                    fieldArr[fieldIndex] = {
                        maintenanceFieldTypeFieldId:Number(event.target.dataset.id),
                        value:event.target.value.trim(),
                    }
                    updateItems[fieldIndex] = event.target.value.trim();
                }else{
                    fieldArr[fieldIndex] = {
                        maintenanceFieldTypeFieldId:fieldValue.id,
                        value:updateItems[fieldIndex]
                    }
                }
            })
            
            // (2) Dynamic Field Content Validation:--
            if(fieldArr.length === 0 ){
                this.setState({fieldStateError:' is required*',fieldStateStatus:true})
            }else if(fieldArr.length !== 0){
                this.setState({fieldStateError:'',fieldStateStatus:false})
                let itemError = [];
                maintenanceFieldTypeFields.map((item,index) => {
                    let itemNameValue = document.querySelector('input[name=itemName]').value;
                    if(itemNameValue === ''){
                        this.setState({itemNameError:"Field name is required*",itemNameStatus:true});
                    }else if(itemNameValue !== '' && itemNameValue.trim().length === 0 ){
                        console.log(itemNameValue !== '' && itemNameValue.trim().length === 0);
                        this.setState({itemNameError:"This is an invalid input",itemNameStatus:true});
                    }else{
                        this.setState({itemNameError:"",itemNameStatus:false});
                    }
                    //--------------------------------------------------------//
                    if(fieldArr[index].value === undefined && item.name !== event.target.name || fieldArr[index].value === ''){
                        itemError[index] = ' is required*';
                        this.setState({updateItemError:itemError,updateItemStatus:true})
                    }else if(fieldArr[index].value !== undefined && fieldArr[index].value.trim().length === 0 ){
                        itemError[index] = 'This is an invalid input';
                        this.setState({updateItemError:itemError,updateItemStatus:true})
                    }else if(fieldArr[index] !== undefined && fieldArr[index].value !== '' && item.name === event.target.name ){
                        itemError[index] = '';
                        this.setState({updateItemError:itemError,updateItemStatus:false})
                    }
                })
            }
            
            this.setState({
                [event.target.name]: event.target.value.trim(),
                fieldState:fieldArr,
                updateItems
            });
        }
    }

//-----------------------------------------------------------------------//
    
    updateItemDetails = (event) => {
        event.preventDefault();
        const { fieldState } = this.state;
        const { maintenanceFieldTypeFields } = this.props;
        let itemName = event.target.itemName.value;
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;
        let isError = false;
        let fieldArr = [...fieldState];
        if(fieldState.length === 0){
            maintenanceFieldTypeFields.map((fieldValue,fieldIndex) => {
                fieldArr[fieldIndex] = {
                    maintenanceFieldTypeFieldId:fieldValue.id,
                    value:this.state.updateItems[fieldIndex]
                }
            })
        }
        //-----------
        
        if(itemName !== '' && itemName.trim().length === 0){
            isError = true;
            this.setState({itemNameError:"This is an invalid input"});
        }else if(itemName === ''){
            isError=true;
            this.setState({itemNameError:"Field name is required*"});
        }else if(itemName.length>100){
            isError=true;
            this.setState({itemNameError:"The name field cannot be greater then 100 characters"});

        }else{
            this.setState({itemNameError:""});
        }

        //-----------
        if(fieldArr.length === 0){
            isError=true;
            this.setState({fieldStateError:' is required*'})
        }else if(fieldArr.length !== 0){
            this.setState({fieldStateError:''})
            let itemError = [];
            maintenanceFieldTypeFields.map((item,index) => {
                if(fieldArr[index].value !== undefined && fieldArr[index].value !== '' && fieldArr[index].value.trim().length === 0){
                    isError = true;
                    itemError[index] = 'This is an invalid input'
                }else if(fieldArr[index].value === undefined || fieldArr[index].value === ''){
                    isError = true;
                    itemError[index] = ' is required*';
                }else if(fieldArr[index].value === undefined && item.name !== event.target.name || fieldArr[index].value === ''){
                    isError = true
                    itemError[index] = ' is required*'
                }else if(fieldState[index].value.length>100){
                    isError = true;
                    itemError[index] = ' field cannot be greater then 100 characters.';
                }else if(fieldArr[index].value !== undefined && item.name === event.target.name || fieldArr[index].value !== ''){
                    itemError[index] = '';
                }
            })
            if(isError === true){
                this.setState({updateItemError:itemError})
            }else{
                this.setState({updateItemError:itemError})
            }
        }

        if(this.state.itemNameStatus === false && this.state.fieldStateStatus=== false && this.state.updateItemStatus === false && isError === false){    
            this.props.updateFieldItem(itemName.trim(),(fieldArr.length === 0 ? this.state.fieldState : fieldArr));    
        }    
    }
//----------------------------------------------------------------------------------------------------------//
    render(){
        const {closeModal,modalStatus,maintenanceFieldTypeFields} = this.props;
        return (
            <div>
                {modalStatus === "add" ? 
                    // ADD MODAL FOR ITEM DETAILS STARTS HERE:-----
                    (<form onSubmit={this.saveItemDetails}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0 text-dark fw-bold text-center" id="myModalLabel"> Add item </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModal(false)} ></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3 accordWrap">
                                    <div className="row">
                                        <div className="col-md-3"></div>    
                                        <div className="col-md-6">
                                            <label className="col-md-4">Field Name</label>
                                            <input autoComplete="off" type="text" className="form-control col-md-2" placeholder="Please enter the field name." name="itemName" onChange={(e) => {this.handleOnchange(e)}} />
                                            <p style={{color:"red"}}>{this.state.itemNameError}</p>
                                        </div>
                                        <div className="col-md-3"></div>    
                                    </div>
                                    <hr/>
                                    <div className="row" >    
                                        {maintenanceFieldTypeFields !== undefined && maintenanceFieldTypeFields.map((item,i) => {
                                            let labelName = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
                                            return(
                                            <div className="col-md-4">
                                                <label className="col-md-4">{labelName}</label>
                                                <input autoComplete="off" type="text" className="form-control col-md-2" placeholder={`Please enter ${item.name.toLowerCase()}`} name={`${item.name}`} data-id={item.id} onChange={(e) => {this.handleOnchange(e)}} /> 
                                                {
                                                    this.state.fieldStateError !== '' ?
                                                    <p style={{color:"red"}}>{labelName+' '+this.state.fieldStateError}</p>
                                                :
                                                    <>
                                                        {
                                                            this.state.updateItemError[i] !== undefined && this.state.updateItemError[i] !== '' && this.state.updateItemError[i] !== 'This is an invalid input' ?
                                                            <p style={{color:"red"}}>{labelName+' '+this.state.updateItemError[i]}</p>
                                                        :
                                                            <>
                                                                {
                                                                    this.state.updateItemError[i] !== undefined && this.state.updateItemError[i] !== '' && this.state.updateItemError[i] === 'This is an invalid input' ?
                                                                    <p style={{color:"red"}}>{this.state.updateItemError[i]}</p>
                                                                :
                                                                    <></>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </div>)
                                        })}
                                    </div>    
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button type="button" className="btn btn-danger fw-bold" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModal(false)} >Cancel</button>
                                <button className="btn btn-primary fw-bold"> Save </button>
                            </div>
                        </div>
                    </form>)
                    // ADD MODAL FOR ITEM DETAILS ENDS HERE:------------
                :
                    // UPDATE MODAL FOR ITEM DETAILS STARTS HERE:-------
                    (<form onSubmit={this.updateItemDetails}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0 text-dark fw-bold text-center" id="myModalLabel"> Update item </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModal(false)} ></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3 accordWrap">
                                    <div className="row">
                                        <div className="col-md-3"></div>    
                                        <div className="col-md-6">
                                            <label className="col-md-4">Field Name</label>
                                            <input autoComplete="off" type="text" className="form-control col-md-2" placeholder="Please enter the field name." name="itemName" value={this.state.itemName} onChange={(e) => {this.handleOnchange(e)}}  />
                                            <p style={{color:"red"}}>{this.state.itemNameError}</p>
                                        </div>
                                        <div className="col-md-3"></div>    
                                    </div>
                                    <hr/>
                                    <div className="row upItem" >    
                                        {
                                            maintenanceFieldTypeFields !== undefined && maintenanceFieldTypeFields.map((item,i) => {
                                                let labelName = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
                                                return(
                                                    <div className="col-md-4">
                                                        <label>{labelName}</label>
                                                        <input autoComplete="off" type="text" className="form-control col-md-2" placeholder={`Please enter ${item.name.toLowerCase()}`} name={`${item.name}`} data-id={item.id} value={this.state.updateItems[i]} onChange={(e) => {this.handleOnchange(e)}} /> 
                                                        {
                                                            this.state.fieldStateError !== '' ?
                                                            <p style={{color:"red"}}>{labelName+' '+this.state.fieldStateError}</p>
                                                        :
                                                            <>
                                                                {
                                                                    this.state.updateItemError[i] !== undefined && this.state.updateItemError[i] !== '' && this.state.updateItemError[i] !== 'This is an invalid input' ?
                                                                    <p style={{color:"red"}}>{labelName+' '+this.state.updateItemError[i]}</p>
                                                                :
                                                                    <>
                                                                        {
                                                                            this.state.updateItemError[i] !== undefined && this.state.updateItemError[i] !== '' && this.state.updateItemError[i] === 'This is an invalid input' ?
                                                                            <p style={{color:"red"}}>{this.state.updateItemError[i]}</p>
                                                                        :
                                                                            <></>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>    
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button type="button" className="btn btn-danger fw-bold" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModal(false)} >Cancel</button>
                                <button className="btn btn-primary fw-bold"> Update </button>
                            </div>
                        </div>
                    </form>)
                    // UPDATE MODAL FOR ITEM DETAILS ENDS HERE:-----
                }
            </div>
        )
    }
}

export default FieldItemPopUp;