//----------------IMPORT SECTION STARTS HERE----------------

//React Parent Component------------------------------------
import React, { Component } from "react";

// Notification Message-------------------------------------
import { createNotification } from "../../../../helpers/Notification";

// All API Calls handled here-------------------------------
import projectServices from "../../../services/projectServices";

// Loader for component grid before data rendering----------
import Loader from "../../../../common/Loader";

// date format----------------------------------------------
import { dateFormat, dbDateFormat } from "../../../../helpers/Util";
import DatePicker from "react-datepicker";
import moment from "moment";

// Role based access permission-----------------------------
import { rules, Permission } from "../../../../../abac.config";

// Redux elements and functions-----------------------------
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//----------------IMPORT SECTION ENDS HERE------------------

class ShopMasterDataMaintenance extends Component{
    constructor(props){
        super(props);
        let details=this.props.location;
        this.state ={
            shopID:(details.state ? details.state.shopId : ''),
            shopName:details.search.replace('?','').replace('%20',' '),
            itemID:(details.state ? details.state.id : ''),
            itemName:(details.state ? details.state.name : '' ), 
            status:(details.state ? details.state.status : ''),
            ShopMaintenanceMasterData:[],
            serviceInterval:(details.state ? details.state.serviceInterval : ''),
            lastService:(details.state ? details.state.lastDate : ''),
            nextService:(details.state ? details.state.nextDate : ''),
            desCheckbox:[],
            comments:(details.state && (details.state.status !== 'Pending' && details.state.status !== 'Overdue' && details.state.status !== 'OVERDUE') ? details.state.comments : ''),
            serviceBy:(details.state && (details.state.status !== 'Pending' && details.state.status !== 'Overdue' && details.state.status !== 'OVERDUE') ? details.state.serviceBy : ''),
            loader:false,
            typeOfFocused:'',
            MaintenanceItemDetails:[],
            actionButtonName:'',
            isAccess:false,

            nextServiceError:'',
            nextServiceStatus:false,

            serviceIntervalError:'',
            serviceIntervalStatus:false,
            
            commentsError:'',
            commentsStatus:false,
            
            serviceByError:'',
            serviceByStatus:false,
        }
    }

   //-----------------------------------------------//
   
    componentDidMount = () => {
        this.getShopMaintenanceMasterData();
        if(this.state.status == 'In Progress' || this.state.status == 'Done'){
            this.getMaintenanceItemDetails(this.state.itemID);
        }
    }

   // Get all the list for Description from Master table-------
    getShopMaintenanceMasterData = () => {
        this.setState({loader:true});
        projectServices.getShopMaintenanceMasterData().then((response)=>{
            this.setState({ShopMaintenanceMasterData:response.data.data,loader:false});
        }).catch((error) => {
            if (error.response.status === 401) {
                createNotification("error", "Your login session has been expired!!");
                localStorage.setItem('user-token', "");
                this.props.history.push('/login');
            } else {
                createNotification("error", error.response.data.error.message);
            }
            console.log(error);
        })
    }

   // ADD FORMS-------------------------------------//

    handleOnChangeForAddForms = (e) => {
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;
        let target            = e.target;
        const { desCheckbox } = this.state;
        let checkboxArr       = [...desCheckbox];
        
        if(target.name === "serviceInterval"){
            if(target.value === ''){
                this.setState({serviceIntervalError:'Service interval is required*',serviceIntervalStatus:true})
            }else if(target.value !== '' && target.value.trim().length === 0 ){
                this.setState({serviceIntervalError:'This is an invalid input',serviceIntervalStatus:true})
            }else{
                this.setState({serviceIntervalError:'',serviceIntervalStatus:false})
            }
            this.setState({serviceInterval:target.value});
        }

        if(target.name === 'desCheckbox'){
            let id = Number(target.dataset.id);
            let flag = target.checked;
            if(flag == true){
                checkboxArr.push(id);
            }else{
                checkboxArr = checkboxArr.filter(e => e != id );
            }
            this.setState({desCheckbox:checkboxArr});
        }

        if(target.name === "comments"){
            if(target.value === ''){
                this.setState({commentsError:'Comment is required*',commentsStatus:true})
            }else if(target.value !== '' && target.value.trim().length === 0 ){
                this.setState({commentsError:'This is an invalid input',commentsStatus:true})
            }else{
                this.setState({commentsError:'',commentsStatus:false})
            }
            this.setState({comments:target.value});
        }

        if(target.name === "serviceBy"){
            if(target.value === ''){
                this.setState({serviceByError:'Service by is required*',serviceByStatus:true})
            }else if(target.value !== '' && target.value.trim().length === 0 ){
                this.setState({serviceByError:'This is an invalid input',serviceByStatus:true})
            }else{
                this.setState({serviceByError:'',serviceByStatus:false})
            }
            this.setState({serviceBy:target.value});
        }
    }
   //-----------------------------------------------//
    handleAddFormSubmit = (e) =>{
        e.preventDefault();
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;
        let isError     = false;
        const {serviceInterval,lastService,nextService,desCheckbox,comments,serviceBy,itemID,shopID,itemName,actionButtonName,serviceIntervalStatus,commentsStatus,serviceByStatus} = this.state;
        let data = desCheckbox.map((row) => {
            return {
                status: true,
                maintenanceShopMaintenanceTypeId:row
            }
        })
        
        if(actionButtonName !== "undone" && actionButtonName === "add_save_n_done" && (serviceInterval === null || serviceInterval === '')){
            isError = true;
            this.setState({serviceIntervalError:'Service interval is required*'});
        }else if(actionButtonName !== "undone" && actionButtonName === "add_save_n_done" && (serviceInterval !== null && serviceInterval !== '') && serviceInterval.trim().length === 0){
            isError = true;
            this.setState({serviceIntervalError:'This is an invalid input'});
        }else{
            this.setState({serviceIntervalError:''});
        } 

        if(actionButtonName !== "undone" && actionButtonName === "add_save_n_done" && (comments === null || comments === '')){
            isError = true;
            this.setState({commentsError:'Comments is required*'});
        } else if(actionButtonName !== "undone" && actionButtonName === "add_save_n_done" && (comments !== null && comments !== '') && comments.trim().length === 0 ){
            isError = true;
            this.setState({commentsError:'This is an invalid input'});
        }else{
            this.setState({commentsError:''});
        }
            
        if(actionButtonName !== "undone" && actionButtonName === "add_save_n_done" && (serviceBy === null || serviceBy === '')){
            isError = true;
            this.setState({serviceByError:'Service by is required*'})
        }else if(actionButtonName !== "undone" && actionButtonName === "add_save_n_done" && (serviceBy !== null && serviceBy !== '') && serviceBy.trim().length === 0){
            isError = true;
            this.setState({serviceByError:'This is an invalid input'})
        }else{
            this.setState({serviceByError:''})
        }
        if((lastService === '' || lastService === 'N/A') && (nextService !== '' || nextService !== 'N/A') && (actionButtonName === "add_save_n_done" || actionButtonName === 'add_save') && (Date.parse(new Date()) >= Date.parse(nextService))){
            isError = true;
            createNotification('error','Next Service date should be greater than today\'s date')
        }else if((nextService !== '' || nextService !== 'N/A') && (actionButtonName === "add_save_n_done" || actionButtonName === 'add_save') && (Date.parse(lastService) >= Date.parse(nextService))){
            isError = true;
            createNotification('error','Next Service date should be greater than today\'s date')
        } 
            
        if(lastService !== '' && (nextService === '' || nextService === 'N/A') && (actionButtonName === "add_save_n_done" )){  
            isError = true;
            this.setState({nextServiceError:'Next Service is required*'})
        }else{
            this.setState({nextServiceError:''})
        }
    
        // else if((lastService === '' || lastService === 'N/A') && nextService !== '' && (actionButtonName === "add_save_n_done")){
        //     createNotification('error','Last Service date is required')
        // }
    
        // if((lastService === '' || lastService === 'N/A') && (nextService === '' || nextService === 'N/A') && (actionButtonName === "add_save_n_done")){
        //     isError = true;
        //     createNotification('error','Last & Next Service date is required')
        // }
        
        if(actionButtonName === 'add_save' && (lastService === '' || lastService === 'N/A') && (nextService !== '' && nextService !== 'N/A') && !(Date.parse(new Date()) >= Date.parse(nextService))){
            isError = false;
        }else if(actionButtonName === 'add_save' && ((lastService !== '' && lastService !== 'N/A') && (nextService !== '' && nextService !== 'N/A') && !(Date.parse(lastService) >= Date.parse(nextService)))){
            isError = false;
        }

        if(data.length === 0){
            createNotification("error", "Please select atleast one option to proceed further!");
        }else{
            if(serviceIntervalStatus === false && commentsStatus === false && serviceByStatus === false && isError === false){
                let payLoad = [];
                if(nextService !== '' && nextService !== 'N/A'){
                    payLoad = {name:itemName,serviceInterval:(serviceInterval !== '' && serviceInterval.trim().length !== 0 ? serviceInterval : ''),lastDate:(lastService !== '' && lastService !== 'N/A' ? dbDateFormat(lastService) : dbDateFormat(new Date())),nextDate:dbDateFormat(nextService),status:(actionButtonName == "add_save" ? "Inprogress" : (actionButtonName == "add_save_n_done" ? "Done" : "") ),comments:(comments === null ? '' : ( comments !== '' && comments.trim().length !== 0 ? comments : '')),serviceBy:(serviceBy === null ? '' : ( serviceBy !== '' && serviceBy.trim().length !== 0 ? serviceBy : '')),shopId:shopID,fields:data};
                }else{
                    payLoad = {name:itemName,serviceInterval:(serviceInterval !== '' && serviceInterval.trim().length !== 0 ? serviceInterval : ''),lastDate:(lastService !== '' && lastService !== 'N/A' ? dbDateFormat(lastService) : dbDateFormat(new Date())),status:(actionButtonName == "add_save" ? "Inprogress" : "Done"),comments:(comments === null ? '' : ( comments !== '' && comments.trim().length !== 0 ? comments : '')),serviceBy:(serviceBy === null ? '' : ( serviceBy !== '' && serviceBy.trim().length !== 0 ? serviceBy : '')),shopId:shopID,fields:data};
                }
        
                if((lastService === '' || lastService === 'N/A') && (nextService === '' || nextService === 'N/A') && (actionButtonName === "add_save")){
                    this.setState({lastService:dateFormat(new Date())});
                }   
                projectServices.addShopMaintenanceDetails(itemID,payLoad).then((res) => {
                    createNotification("success", "Shop Maintenance details added successfully!");
                    this.props.history.push('/maintenance');
                }).catch((error) => {
                    console.log(error);
                    this.props.history.push("/maintenance");
                })
            }
        }
    }

   //-----------------------------------------------//
   
   
   // EDIT FORMs-----------------------------------//
   getMaintenanceItemDetails = (itemID) => {
        projectServices.getMaintenanceItemDetails(itemID).then((response) => {
            this.setState({MaintenanceItemDetails:response.data.data.maintenanceShopData});
            let checkedValue = [];
            checkedValue = this.state.MaintenanceItemDetails.map((itemVal) => {
                return itemVal.maintenanceShopMaintenanceTypeId;
            }) 

            this.setState({desCheckbox:checkedValue});
        }).catch((error) => {
            console.log(error);
        })
   }
   //----------------------------------------------//
    handleOnChangeForEditForms = (e) => {
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;
        let target            = e.target;
        const { desCheckbox } = this.state;
        let checkboxArr       = [...desCheckbox];

        if(target.name === "serviceInterval"){
            if(target.value === ''){
                this.setState({serviceIntervalError:'Service interval is required*',serviceIntervalStatus:true})
            }else if(target.value !== '' && target.value.trim().length === 0 ){
                this.setState({serviceIntervalError:'This is an invalid input',serviceIntervalStatus:true})
            }else{
                this.setState({serviceIntervalError:'',serviceIntervalStatus:false})
            }
            this.setState({serviceInterval:target.value});
        }

        if(target.name === 'desCheckbox'){
            let id = Number(target.dataset.id);
            let flag = target.checked;
            if(flag == true){
                checkboxArr.push(id);
            }else{
                checkboxArr = checkboxArr.filter(e => e != id );
            }
            this.setState({desCheckbox:checkboxArr});    
        }

        if(target.name === "comments"){
            if(target.value === ''){
                this.setState({commentsError:'Comments is required*',commentsStatus:true})
            }else if(target.value !== '' && target.value.trim().length === 0 ){
                this.setState({commentsError:'This is an invalid input',commentsStatus:true})
            }else{
                this.setState({commentsError:'',commentsStatus:false})
            }
            this.setState({comments:target.value});
        }

        if(target.name === "serviceBy"){
            if(target.value === ''){
                this.setState({serviceByError:'Service by is required*',serviceByStatus:true})
            }else if(target.value !== '' && target.value.trim().length === 0 ){
                this.setState({serviceByError:'This is an invalid input',serviceByStatus:true})
            }else{
                this.setState({serviceByError:'',serviceByStatus:false})
            }
            this.setState({serviceBy:target.value});
        }
    }

    handleEditFormSubmit = (e) => {
        e.preventDefault();
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;
        let isError = false;
        const {serviceInterval,lastService,nextService,desCheckbox,comments,serviceBy,itemID,shopID,itemName,actionButtonName,serviceIntervalStatus,commentsStatus,serviceByStatus} = this.state;
        let data = desCheckbox.map((row) => {
            return {
                status: true,
                maintenanceShopMaintenanceTypeId:row
            }
        })
        
        if(actionButtonName !== "undone" && actionButtonName === "edit_save_n_done" && (serviceInterval === null || serviceInterval === '')){
            isError = true;
            this.setState({serviceIntervalError:'Service interval is required*'});
        }else if(actionButtonName !== "undone" && actionButtonName === "edit_save_n_done" && (serviceInterval !== null && serviceInterval !== '') && serviceInterval.trim().length === 0){
            isError = true;
            this.setState({serviceIntervalError:'This is an invalid input'});
        }else{
            this.setState({serviceIntervalError:''});
        } 

        if(actionButtonName !== "undone" && actionButtonName === "edit_save_n_done" && (comments === null || comments === '')){
            isError = true;
            this.setState({commentsError:'Comments is required*'})
        }else if(actionButtonName !== "undone" && actionButtonName === "edit_save_n_done" && (comments !== null && comments !== '') && comments.trim().length === 0){

            isError = true;
            this.setState({commentsError:'This is an invalid input'});
        }else{
            this.setState({commentsError:''})
        }
            
        if(actionButtonName !== "undone" && actionButtonName === "edit_save_n_done" && (serviceBy === null || serviceBy === '')){
            isError = true;
            this.setState({serviceByError:'Serviced by is required*'})
        }else if(actionButtonName !== "undone" && actionButtonName === "edit_save_n_done" && (serviceBy !== null && serviceBy !== '') && serviceBy.trim().length === 0 ){
            isError = true;
            this.setState({serviceByError:'This is an invalid input'})
        }else{
            this.setState({serviceByError:''})
        }
           
        if((nextService !== '' && nextService !== 'N/A') && (actionButtonName === "edit_save_n_done" || actionButtonName === 'edit_save') && (Date.parse(lastService) > Date.parse(nextService))){
            isError = true;
            this.setState({lastService:dateFormat(new Date())});
            createNotification('error','Next Service date should be greater than today\'s date')
        } 
            
        if(lastService !== '' && (nextService === '' || nextService === 'N/A') && (actionButtonName === "edit_save_n_done")){  
            isError = true;
            this.setState({nextServiceError:'Next Service is required*'});
        } else{
            this.setState({nextServiceError:''})
        }
            
        // if((lastService === '' || lastService === 'N/A') && (nextService === '' || nextService === 'N/A') && (actionButtonName === "edit_save_n_done")){
        //     createNotification('error','Last & Next Service date is required')
        // }
            
        // if((lastService === '' || lastService === 'N/A') && (nextService !== '' && nextService !== 'N/A') && (actionButtonName === "edit_save_n_done")){
        //     createNotification('error','Last Service date is required')
        // }
        if(actionButtonName === 'edit_save'){
            isError = false;
        }
            
        if(data.length === 0 && actionButtonName !== 'undone'){
            createNotification("error", "Please select atleast one option to proceed further!");
        }else{
            if(serviceIntervalStatus === false && commentsStatus === false && serviceByStatus === false && isError === false){    
                let payLoad = [];
                if(nextService !== '' && nextService !== 'N/A'){
                    payLoad = {name:itemName,serviceInterval:(serviceInterval !== '' && serviceInterval.trim().length !== 0 ? serviceInterval : ''),lastDate:(lastService !== '' && lastService !== 'N/A' ? dbDateFormat(lastService) : dbDateFormat(new Date())),nextDate:dbDateFormat(nextService),status:(actionButtonName == "edit_save" ? "Inprogress" : (actionButtonName == "edit_save_n_done" ? "Done" : (actionButtonName == "undone" ? "Inprogress" : ""))),comments:(comments !== '' && comments.trim().length !== 0 ? comments : '' ),serviceBy:(serviceBy !== '' && serviceBy.trim().length !== 0 ? serviceBy : '' ),shopId:shopID,fields:data};
                }else{
                    payLoad = {name:itemName,serviceInterval:(serviceInterval !== '' && serviceInterval.trim().length !== 0 ? serviceInterval : ''),lastDate:(lastService !== '' && lastService !== 'N/A' ? dbDateFormat(lastService) : dbDateFormat(new Date())),nextDate:dbDateFormat(nextService),status:(actionButtonName == "edit_save" ? "Inprogress" : (actionButtonName == "edit_save_n_done" ? "Done" : (actionButtonName == "undone" ? "Inprogress" : ""))),comments:(comments !== '' && comments.trim().length !== 0 ? comments : '' ),serviceBy:(serviceBy !== '' && serviceBy.trim().length !== 0 ? serviceBy : '' ),shopId:shopID,fields:data};
                }

                if((lastService === '' || lastService === 'N/A') && (nextService !== '' && nextService !== 'N/A') && (actionButtonName === "edit_save") ){
                    this.setState({lastService:dateFormat(new Date())});
                }
                projectServices.editShopMaintenanceDetails(itemID,payLoad).then((res) => {
                    if(actionButtonName === "undone"){
                        let editFormData = {id:itemID,name:itemName,shopId:shopID,status:"In Progress",serviceInterval:serviceInterval,lastDate:lastService,nextDate:nextService,comments:comments,serviceBy:serviceBy,fields:data}
                        this.props.history.push({pathname:'/maintenance/edit',state:(editFormData),search:`${this.state.shopName}`});
                    }else{
                        this.props.history.push('/maintenance');
                        createNotification("success", "Shop Maintenance details updated successfully!");
                    }
                }).catch((error) => {
                    console.log(error);
                    this.props.history.push("/maintenance");
                })
            }
        }
    }

   //----------------------------------------------//
    handleDateChange = (date, type) => {
        let formatedDate = '';
        if(date !== null){
            formatedDate = date
        }else{
            formatedDate = '';
        }
        if (type === "lastService") {
            if(this.state.nextService !== ''){
                if(Date.parse(this.state.nextService) < Date.parse(date)){
                    createNotification("error", "Last Service date should be less than Next Service date");
                }else{
                    this.setState({ lastService: formatedDate });
                }
            }else{
                this.setState({ lastService: formatedDate });
            } 
        } else if (type === "nextService") {
            if(this.state.lastService !== ''){
                if(Date.parse(this.state.lastService) > Date.parse(date)){
                    createNotification("error", "Next Service date should be greater than Last Service Date");
                }else{
                    this.setState({ nextService: formatedDate });
                }
            }else{
                this.setState({ nextService: formatedDate });
            }
        }
    }

    // handle button action, get current buttons name, which one was clicked recently 
    handleButtonClickAction = (e) => {
        this.setState({actionButtonName:e.target.dataset.name});
    }
   //----------------------------------------------//
   
    render(){

        // ACCESS PERMISSION ROLE KEYS WITH THERE PERMISSION RIGHTS----------------------------------
        let roleKey        = this.props.loginUserData.roleKey;
        const isAccessable = (roleKey!=="" && rules[roleKey][Permission.MAINTENANCE_WRITE]) ? true : false;
        // ACCESS PERMISSION ROLE KEYS WITH THERE PERMISSION RIGHTS----------------------------------

        const {ShopMaintenanceMasterData,loader,status,serviceInterval,lastService,nextService,comments,serviceBy,desCheckbox,isAccess} = this.state;  
        ShopMaintenanceMasterData.sort((a, b) => (a.id > b.id) ? 1 : -1)
        return(
            <div>
                <div className="main-content shopPersonal">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <ol className="breadcrumb m-0">
                                    <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                                    &nbsp; / &nbsp;
                                    <Link to={`/maintenance`}><li className="breadcrumb-item">Shop Maintenance</li></Link>    
                                    &nbsp; / &nbsp;
                                    {
                                    this.props.match.path == `/maintenance/add` ?
                                        <Link to={`/maintenance/add`}><li className="breadcrumb-item">Add Maintenance</li></Link>
                                    :this.props.match.path == `/maintenance/edit` ?
                                        <Link to={`/maintenance/edit`}><li className="breadcrumb-item">Edit Maintenance</li></Link>
                                    :this.props.match.path == `/maintenance/view` ?
                                        <Link to={`/maintenance/view`}><li className="breadcrumb-item">View Maintenance</li></Link>
                                    :''
                                    }    
                                </ol>
                            </div>
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0 text-left">{this.state.shopName} - {this.state.itemName}</h4>
                            </div>
                            <div style={isAccessable === true ? {display:"block"} : {display:"none"}} >
                            {//-----------------------------------------------------------------------------------------------------------------------------------------//
                                status === "Pending" || status === "Overdue" || status === "OVERDUE" ?  // ADD FORM
                                (<form onSubmit={this.handleAddFormSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project-scope-block">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <label className="col-md-8">Service Interval</label>
                                                                <input autoComplete="off" type="text" className="form-control col-md-2" placeholder="Please enter service interval." name="serviceInterval" onChange={(event)=>{this.handleOnChangeForAddForms(event);}} value={serviceInterval} maxLength="100"/>
                                                                {this.state.serviceIntervalError !== '' ?
                                                                    <p style={{color:"red"}}>{this.state.serviceIntervalError}</p>
                                                                :
                                                                    <></>
                                                                }
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="col-md-8">Last Service</label>
                                                                {/* <input type="date" className="form-control col-md-2" name="lastService" onChange={(event)=>{this.handleOnChangeForAddForms(event);}}/> */}
                                                                {lastService == '' || lastService == 'N/A' ?
                                                                    <DatePicker 
                                                                        className="form-control date-form-control" 
                                                                        onChange={(date) => {this.handleDateChange(date, "lastService")}} 
                                                                        dateFormat="MM/dd/yyyy" 
                                                                        selected={new Date(moment(new Date()).format('MM/DD/YYYY'))}
                                                                        placeholderText="MM/DD/YYYY"
                                                                        maxDate={moment().toDate()}
                                                                    />
                                                                    :
                                                                    <DatePicker 
                                                                        className="form-control date-form-control" 
                                                                        onChange={(date) => {this.handleDateChange(date, "lastService")}} 
                                                                        dateFormat="MM/dd/yyyy" 
                                                                        selected={new Date(moment(lastService).format('MM/DD/YYYY'))}
                                                                        placeholderText="MM/DD/YYYY"
                                                                        maxDate={moment().toDate()}
                                                                    />
                                                                }
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="col-md-8">Next Service</label>
                                                                {/* <input type="date" className="form-control col-md-2" name="nextService" onChange={(event)=>{this.handleOnChangeForAddForms(event);}}/> */}
                                                                {nextService == '' || nextService == 'N/A' ?
                                                                <>
                                                                <DatePicker 
                                                                    className="form-control date-form-control" 
                                                                    onChange={(date) => {this.handleDateChange(date, "nextService")}} 
                                                                    dateFormat="MM/dd/yyyy" 
                                                                    placeholderText="MM/DD/YYYY"
                                                                    minDate={moment().toDate()}
                                                                />
                                                                {this.state.nextServiceError !== '' ?
                                                                    <p style={{color:"red"}}>{this.state.nextServiceError}</p>
                                                                    :
                                                                    <></>
                                                                }
                                                                </>                
                                                                :
                                                                <DatePicker 
                                                                    className="form-control date-form-control" 
                                                                    onChange={(date) => {this.handleDateChange(date, "nextService")}} 
                                                                    dateFormat="MM/dd/yyyy" 
                                                                    selected={new Date(moment(nextService).format('MM/DD/YYYY'))}
                                                                    placeholderText="MM/DD/YYYY"
                                                                    minDate={moment().toDate()}
                                                                />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 pt-3 pb-2">
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                            <div class="fixTableHead">
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Description</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        ShopMaintenanceMasterData.map((dta) =>
                                                                            <div class="tableCell">{dta.name}</div>
                                                                        )
                                                                    )}
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Status</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        ShopMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                    <input disabled={isAccess === true ? isAccess : false} class="form-check-input" type="checkbox" name="desCheckbox" data-id={dta.id} onChange={(event)=>{this.handleOnChangeForAddForms(event);}}/>
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                            </div>
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label className="col-md-4">Comments</label>
                                                                <textarea autoComplete="off" type="text" className="form-control col-md-8" placeholder="Write your comments here..." name="comments" onChange={(event)=>{this.handleOnChangeForAddForms(event);}}maxLength="700" />
                                                                {this.state.commentsError !== '' ?
                                                                    <p style={{color:"red"}}>{this.state.commentsError}</p>
                                                                :
                                                                    <></>        
                                                                }        
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label className="col-md-10">Serviced by</label>
                                                                <input type="text" autoComplete="off" className="form-control col-md-2" name="serviceBy" placeholder="Enter service person name" onChange={(event)=>{this.handleOnChangeForAddForms(event);}} maxLength="200"/>
                                                                {this.state.serviceByError !== '' ?
                                                                    <p style={{color:"red"}}>{this.state.serviceByError}</p>
                                                                :
                                                                    <></>
                                                                }
                                                            </div>
                                                            <div className="col-md-4"></div>
                                                            <div className="col-md-4">
                                                                <br/>
                                                                <label className="col-md-3"></label>
                                                                <button className="col-md-9 uil uil-eye btn btn-secondary text-light" onClick={() => {this.props.history.push({pathname: '/maintenance/history',state: {itemID:this.state.itemID,itemName:this.state.itemName}})}}>View Service History</button>
                                                            </div>
                                                        </div><br/>
                                                        <div className="text-center">
                                                            <button className="btn btn-success col-md-2" data-name="add_save" onClick={(e) =>this.handleButtonClickAction(e)}>Save</button>&nbsp;
                                                            <button className="btn btn-info col-md-2" data-name="add_save_n_done" onClick={(e) =>this.handleButtonClickAction(e)}>Save & Done</button>&nbsp;
                                                            <button className="btn btn-primary col-md-2" onClick={()=>this.props.history.push('/maintenance')}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>)
                                ://--------------------------------------------------------------------------------------------------------------------------------//
                                status === "In Progress" ? //UPDATE FORM
                                (<form onSubmit={this.handleEditFormSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project-scope-block">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <label className="col-md-8">Service Interval</label>
                                                                <input autoComplete="off" type="text" className="form-control col-md-2" placeholder="Please enter service interval." name="serviceInterval" onChange={(event)=>{this.handleOnChangeForEditForms(event)}} value={serviceInterval} maxLength="100"/>
                                                                {this.state.serviceIntervalError !== '' ?
                                                                    <p style={{color:"red"}}>{this.state.serviceIntervalError}</p>
                                                                :
                                                                    <></>
                                                                }
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="col-md-8">Last Service</label>
                                                                {lastService == '' || lastService == 'N/A' ?
                                                                <DatePicker 
                                                                    className="form-control date-form-control" 
                                                                    onChange={(date) => {this.handleDateChange(date, "lastService")}} 
                                                                    dateFormat="MM/dd/yyyy"
                                                                    selected={new Date(moment(new Date()).format('MM/DD/YYYY'))} 
                                                                    placeholderText="MM/DD/YYYY"
                                                                    maxDate={moment().toDate()}
                                                                />
                                                                :
                                                                <DatePicker 
                                                                    className="form-control date-form-control" 
                                                                    onChange={(date) => {this.handleDateChange(date, "lastService")}} 
                                                                    dateFormat="MM/dd/yyyy" 
                                                                    selected={new Date(moment(lastService).format('MM/DD/YYYY'))}
                                                                    placeholderText="MM/DD/YYYY"
                                                                    maxDate={moment().toDate()}
                                                                />
                                                                }
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="col-md-8">Next Service</label>
                                                                {nextService == '' || nextService == 'N/A' ?
                                                                <>
                                                                    <DatePicker 
                                                                        className="form-control date-form-control" 
                                                                        onChange={(date) => {this.handleDateChange(date, "nextService")}} 
                                                                        dateFormat="MM/dd/yyyy" 
                                                                        placeholderText="MM/DD/YYYY"
                                                                        minDate={moment().toDate()}
                                                                    />
                                                                    {this.state.nextServiceError !== '' ?
                                                                        <p style={{color:"red"}}>{this.state.nextServiceError}</p>
                                                                    :
                                                                        <></>
                                                                    }
                                                                </>
                                                                :
                                                                <DatePicker 
                                                                    className="form-control date-form-control" 
                                                                    onChange={(date) => {this.handleDateChange(date, "nextService")}} 
                                                                    dateFormat="MM/dd/yyyy" 
                                                                    selected={new Date(moment(nextService).format('MM/DD/YYYY'))}
                                                                    placeholderText="MM/DD/YYYY"
                                                                    minDate={moment().toDate()}
                                                                />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 pt-3 pb-2">
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                            <div class="fixTableHead">
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Description</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                       
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        ShopMaintenanceMasterData.map((dta) =>
                                                                            <div class="tableCell">{dta.name}</div>
                                                                        )
                                                                    )}
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Status</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                       
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        ShopMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                {(desCheckbox.indexOf(dta.id) > -1) ? 
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        name="desCheckbox" 
                                                                                        data-id={dta.id} 
                                                                                        onChange={(event)=>{this.handleOnChangeForEditForms(event)}}
                                                                                        checked
                                                                                    />
                                                                                ) 
                                                                                :
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        name="desCheckbox" 
                                                                                        data-id={dta.id} 
                                                                                        onChange={(event)=>{this.handleOnChangeForEditForms(event)}}
                                                                                    />
                                                                                ) 
                                                                                }
                                                                                    
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                            </div>
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label className="col-md-4">Comments</label>
                                                                <textarea autoComplete="off" type="text" className="form-control col-md-8" placeholder="Write your comments here..." name="comments" onChange={(event)=>{this.handleOnChangeForEditForms(event)}} value={comments} maxLength="700"/>
                                                                {this.state.commentsError !== '' ?
                                                                    <p style={{color:"red"}}>{this.state.commentsError}</p>
                                                                :
                                                                    <></>
                                                                }                
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <label className="col-md-10">Serviced by</label>
                                                                <input type="text" autoComplete="off" className="form-control col-md-2" name="serviceBy" placeholder="Enter service person name" onChange={(event)=>{this.handleOnChangeForEditForms(event)}} value={serviceBy} maxLength="200"/>
                                                                {this.state.serviceByError !== '' ?
                                                                    <p style={{color:"red"}}>{this.state.serviceByError}</p>
                                                                :
                                                                    <></>
                                                                }
                                                            </div>
                                                            <div className="col-md-5"></div>
                                                            <div className="col-md-4">
                                                                <br/>
                                                                <label className="col-md-3"></label>
                                                                <button className="col-md-9 uil uil-eye btn btn-secondary text-light" onClick={() => {this.props.history.push({pathname: '/maintenance/history',state: {itemID:this.state.itemID,itemName:this.state.itemName}})}}>View Service History</button>
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-3"></div>
                                                            <button className="btn btn-success col-md-2" data-name="edit_save" onClick={(e) =>this.handleButtonClickAction(e)}>Update</button>&nbsp;
                                                            <button className="btn btn-info col-md-2" data-name="edit_save_n_done" onClick={(e) =>this.handleButtonClickAction(e)}>Save & Done</button>&nbsp;
                                                            <button className="btn btn-primary col-md-2" onClick={()=>this.props.history.push('/maintenance')}>Cancel</button>
                                                            <div className="col-md-3"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>) 
                                ://-------------------------------------------------------------------------------------------------------------------------------------------//
                                status === "Done" ? // VIEW PAGE
                                ( <form onSubmit={this.handleEditFormSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project-scope-block">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <label className="col-md-8">Service Interval</label>
                                                                <input readOnly type="text" className="form-control col-md-2" placeholder="service interval" name="serviceInterval" value={serviceInterval}/>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="col-md-8">Last Service</label>
                                                                <input readOnly type="text" className="form-control col-md-2" name="lastService" value={(lastService == '' || lastService == 'N/A' ? 'mm/dd/yyyy' : lastService)}/>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="col-md-8">Next Service</label>
                                                                <input readOnly type="text" className="form-control col-md-2" name="nextService" value={(nextService == '' || nextService == 'N/A' ? 'mm/dd/yyyy' : nextService)}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 pt-3 pb-2">
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                            <div class="fixTableHead" readOnly>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Description</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        ShopMaintenanceMasterData.map((dta) =>
                                                                            <div class="tableCell">{dta.name}</div>
                                                                        )
                                                                    )}
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Status</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        ShopMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                {(desCheckbox.indexOf(dta.id) > -1) ? 
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        checked
                                                                                        disabled="disabled"
                                                                                    />
                                                                                ) 
                                                                                :
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        disabled="disabled"
                                                                                    />
                                                                                ) 
                                                                                }
                                                                                   <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                            </div>
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                        </div>
                                                        
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label className="col-md-4">Comments</label>
                                                                <textarea readOnly autoComplete="off" type="text" className="form-control col-md-8" placeholder="Comments..." name="comments" value={comments} />
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="col-md-10">Serviced by</label>
                                                                <input type="text" readOnly className="form-control col-md-2" name="serviceBy" placeholder="Service person name" value={serviceBy} />
                                                            </div>
                                                            <div className="col-md-6"></div>
                                                            <div className="col-md-4"><br/>
                                                                <label className="col-md-3"></label>
                                                                <button className="col-md-9 uil uil-eye btn btn-secondary text-light" onClick={() => {this.props.history.push({pathname: '/maintenance/history',state: {itemID:this.state.itemID,itemName:this.state.itemName}})}}>View Service History</button>
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-4"></div>
                                                            <button className="btn btn-warning col-md-2" data-name="undone" onClick={(e) =>this.handleButtonClickAction(e)}>Re-Open</button>&nbsp;
                                                            <button className="btn btn-primary col-md-2" onClick={()=>this.props.history.push("/maintenance")}>Back</button>
                                                            <div className="col-md-4"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>) 
                                ://------------------------------------------------------------------------------------------------------------------------------------------// 
                                (<></>) 
                            }
                            </div>
                            <div style={isAccessable === false ? {display:"block"} : {display:"none"}}>
                            <form>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project-scope-block">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <label className="col-md-8">Service Interval</label>
                                                                <input readOnly type="text" className="form-control col-md-2" placeholder="service interval" name="serviceInterval" value={serviceInterval}/>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="col-md-8">Last Service</label>
                                                                <input readOnly type="text" className="form-control col-md-2" name="lastService" value={(lastService == '' || lastService == 'N/A' ? 'mm/dd/yyyy' : dateFormat(lastService))}/>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="col-md-8">Next Service</label>
                                                                <input readOnly type="text" className="form-control col-md-2" name="nextService" value={(nextService == '' || nextService == 'N/A' ? 'mm/dd/yyyy' : dateFormat(nextService))}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 pt-3 pb-2">
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                            <div class="fixTableHead" readOnly>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Description</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        ShopMaintenanceMasterData.map((dta) =>
                                                                            <div class="tableCell">{dta.name}</div>
                                                                        )
                                                                    )}
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Status</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        ShopMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                {(desCheckbox.indexOf(dta.id) > -1) ? 
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        checked
                                                                                        disabled="disabled"
                                                                                    />
                                                                                ) 
                                                                                :
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        disabled="disabled"
                                                                                    />
                                                                                ) 
                                                                                }
                                                                                   <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                            </div>
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                        </div>
                                                        
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label className="col-md-4">Comments</label>
                                                                <textarea readOnly type="text" className="form-control col-md-8" placeholder="Comments..." name="comment" value={comments} />
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="col-md-10">Serviced by</label>
                                                                <input type="text" readOnly autoComplete="off" required className="form-control col-md-2" name="serviceBy" placeholder="Service person name" value={serviceBy} />
                                                            </div>
                                                            <div className="col-md-6"></div>
                                                            <div className="col-md-4"><br/>
                                                                <label className="col-md-3"></label>
                                                                <button className="col-md-9 uil uil-eye btn btn-secondary text-light" onClick={() => {this.props.history.push({pathname: '/maintenance/history',state: {itemID:this.state.itemID,itemName:this.state.itemName}})}}>View Service History</button>
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-4"></div>
                                                            <button className="btn btn-primary col-md-2" onClick={()=>this.props.history.push("/maintenance")}>Back</button>
                                                            <div className="col-md-4"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
  };
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShopMasterDataMaintenance);