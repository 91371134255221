import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MapStateToProps } from 'react-redux'
import { rules, Permission } from "../../../abac.config";
import DatePicker from "react-datepicker";
import SignaturePad from "react-signature-canvas";
import { Buffer } from "buffer";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaUndoAlt, FaRegWindowClose } from "react-icons/fa";
import { AllowedTo } from 'react-abac';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { getLoginToken } from '../../helpers/Util';
import { getApiUrl } from '../../helpers/Util';
import projectServices from "../../project/services/projectServices";
import { createNotification } from '../../helpers/Notification';
import { DropDown } from "../../common";
import { setFreightDelFormData } from '../../project/redux/projectAction';
import { setFreightDelError } from '../../project/redux/projectAction';
import ModalPopUp from '../../common/ModalPopUp';
import AddTransportItem from '../AddTransportItem';

class DefaultTansportScopeItemData extends Component {
    constructor(props) {
        super(props);
        this.sigCanvas = React.createRef()
        this.state = {
            initialState: {
                freightDate: "",
                projectName: "",
                scope: "",
                shipFrom: "",
                shipTo: "",
                comments: "",
                shipperDate: "",
                receiverDate: "",
                signature: "",
                receiver: "",
                deliveredBy: "",
                onsiteContact: "",
                defaultScopeItemName: ""
            },

            isEdit: false,
            isOpenFreightModal: false,
            projectFreightDeliveryId: 0,
            freightItemList: [],
            selectedScopeName: "",
            selectedFiles: null,
            previousShipperDate: "",
            previousReceiverDate: "",
            previousIncompleteLoadDate: "",
            isShipperDateChanged: false,
            isReceiverDateChanged: false,
            isLoadIncompleteDateChanged: false,
            isShipperDateChanged: false,
            projectList: [],
            transportData: [],
            selectedLabel: "",
            projectId: 0,
            defaultScopeItemId: 0,
            projectFreightDeliveryId: 0,

            itemId: 0

        }

    }

    componentDidMount = () => {
        const { setFreightDelError, freightDelErr } = this.props
        setFreightDelError({ ...freightDelErr, projectNameError: "", receiverDateError: "", shipperDateError: "" });
        this.getProjectList();
    }
    getFreightDelItems = (projectFreightDeliveryId) => {
        if (projectFreightDeliveryId) {
            projectServices
                .getFreightDelItems(projectFreightDeliveryId)
                .then((res) => {
                    this.setState({ freightItemList: res.data.data });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    getProjectList = () => {
        projectServices
            .getCalenderTransport()
            .then((response) => {
                this.setState({ transportData: response.data.data }, () => {
                    this.generateProjectList();
                });


            })
            .catch((error) => {
                if (error.response.status === 401) {
                    createNotification("error", "Your login session has been expired!");
                    localStorage.setItem("user-token", "");
                    this.props.history.push("/login");
                } else {
                    createNotification("error", error.response.data.error.message);
                }
                console.log(error);
            });
    }

    generateProjectList = () => {
        const { projects } = this.state.transportData;
        let onlyProjects = [];
        if (Object.keys(projects).length > 0) {
            for (const [k, val] of Object.entries(projects)) {
                if (onlyProjects.find((item) => item.value === val.projectId)) {

                } else {
                    //    onlyProjects.push({ ...val });
                    // let data=`Job # ${val.project_fkey.jobNumber} -  ${val.project_fkey.title}`;
                    // onlyProjects.push({ lable: data, value: val.projectId })
                    let data = `Job # ${val.project_fkey.jobNumber} - ${val.project_fkey.title}`;
                    onlyProjects.push({ lable: data, value: val.projectId, jobNumber: val.project_fkey.jobNumber })
                }
            }
            this.handleProjectDropDown(onlyProjects)
            // this.setState({ projectList: onlyProjects })
        }
    }
    handleProjectDropDown = (onlyProjects) => {
        console.log(onlyProjects);
        let result = onlyProjects.sort((a, b) => {
            if (a.jobNumber && b.jobNumber) {
                return a.jobNumber > b.jobNumber ? 1 : -1
            } else {
                return -1
            }
            // console.log(onlyProjects);
        });
        console.log(result);
        this.setState({ projectList: [...result] })
    }

    handleOnChange = (event) => {

        const { setFreightDelFormData, freightDelFormData, freightDelErr, setFreightDelError } = this.props;

        let freightDelFormDataClone = { ...freightDelFormData };
        if (event.target.name === "ProjectId") {
            if (event.target.value == "0") {
                this.setState({ projectId: 0, defaultScopeItemId: 0 }, () => {
                    this.setLabel(Number(event.target.value));
                })
            } else {
                setFreightDelError({ ...freightDelErr, projectNameError: "" })
                this.setState({ projectId: Number(event.target.value) }, () => {
                    this.getProjectDefaultScope();
                    this.setLabel(Number(event.target.value));
                })
            }


        } else if (event.target.name === "projectName") {
            setFreightDelFormData({ ...freightDelFormDataClone, projectName: event.target.value });
        } else if (event.target.name === "scope") {
            setFreightDelFormData({ ...freightDelFormDataClone, scope: event.target.value });
        } else if (event.target.name === "shipFrom") {
            setFreightDelFormData({ ...freightDelFormDataClone, shipFrom: event.target.value, });
        } else if (event.target.name === "shipTo") {
            setFreightDelFormData({ ...freightDelFormDataClone, shipTo: event.target.value });
        } else if (event.target.name === "comments") {
            setFreightDelFormData({ ...freightDelFormDataClone, comments: event.target.value });
        }
        else if (event.target.name === "receiver") {
            setFreightDelFormData({ ...freightDelFormDataClone, receiver: event.target.value });
        }
        else if (event.target.name === "deliveredBy") {
            setFreightDelFormData({ ...freightDelFormDataClone, deliveredBy: event.target.value });
        } else if (event.target.name === "onsiteContact") {
            setFreightDelFormData({ ...freightDelFormDataClone, onsiteContact: event.target.value });
        } else if (event.target.name === "defaultScopeName") {
            setFreightDelError({ ...freightDelErr, defaultScopeItemNameError: "" })
            setFreightDelFormData({ ...freightDelFormDataClone, defaultScopeItemName: event.target.value })
        } else if (event.target.name === "receiverName") {
            setFreightDelFormData({ ...freightDelFormDataClone, receiverName: event.target.value });
        }

    }

    setLabel = (selectedValue) => {

        const selectedItem = this.state.projectList.find(item => item.value === selectedValue);

        if (selectedItem) {
            this.setState({ selectedLabel: selectedItem.lable });
        }
    }

    handleDateChange = (date, type) => {
        let formatedDate = date
        const { setFreightDelFormData, freightDelFormData, setFreightDelError, freightDelErr } = this.props;
        let freightDelFormDataClone = { ...freightDelFormData };


        if (type === "shipperDate") {
            let prevdata;
            if (freightDelFormData.receiverDate !== '') {

                setFreightDelError({ ...freightDelErr, shipperDateError: "" })
                if (Date.parse(freightDelFormData.receiverDate) < Date.parse(date)) {
                    createNotification("error", "Shipper's date should be less than Load complete date");
                } else if (Date.parse(freightDelFormData.incompleteLoadDate) < Date.parse(date)) {
                    createNotification("error", "Shipper date should be less than  Incomplete load date");
                } else {
                    prevdata = freightDelFormDataClone.shipperDate;
                    this.setState({ previousShipperDate: prevdata, isShipperDateChanged: true })
                    setFreightDelFormData({ ...freightDelFormDataClone, shipperDate: formatedDate });
                }
            } else {
                if (Date.parse(freightDelFormData.receiverDate) < Date.parse(date)) {
                    createNotification("error", "Shipper date should be less than Load complete date");
                } else if (Date.parse(freightDelFormData.incompleteLoadDate) < Date.parse(date)) {
                    createNotification("error", "Shipper date should be less than Incomplete load date");
                } else {
                    setFreightDelError({ ...freightDelErr, shipperDateError: "" })
                    prevdata = freightDelFormDataClone.shipperDate;
                    this.setState({ previousShipperDate: prevdata, isShipperDateChanged: true })
                    setFreightDelFormData({ ...freightDelFormDataClone, shipperDate: formatedDate });
                }


            }
        } else if (type === "receiverDate") {

            let prevRecDate;
            if (freightDelFormData.shipperDate !== '') {
                setFreightDelError({ ...freightDelErr, receiverDateError: "" })
                if (Date.parse(freightDelFormData.shipperDate) > Date.parse(date)) {
                    createNotification("error", "Load complete date should be greater than Shipper date");
                } else if (Date.parse(freightDelFormData.incompleteLoadDate) > Date.parse(date)) {
                    createNotification("error", "Incomplete load complete date should be less than Load complete date");
                }
                else {
                    prevRecDate = freightDelFormDataClone.receiverDate;
                    this.setState({ previousReceiverDate: prevRecDate, isReceiverDateChanged: true })
                    setFreightDelFormData({ ...freightDelFormDataClone, receiverDate: formatedDate });
                }
            } else {
                if (Date.parse(freightDelFormData.shipperDate) > Date.parse(date)) {
                    createNotification("error", "Load complete date should be greater than Shipper date");
                } else if (Date.parse(freightDelFormData.incompleteLoadDate) > Date.parse(date)) {
                    createNotification("error", "Incomplete load complete date should be less than Load complete date");
                } else {
                    prevRecDate = freightDelFormDataClone.receiverDate;
                    this.setState({ previousReceiverDate: prevRecDate, isReceiverDateChanged: true })
                    setFreightDelFormData({ ...freightDelFormDataClone, receiverDate: formatedDate });
                    // setFreightDelError({ ...freightDelErr, receiverDateError: "Load complete date is required ." })
                }

            }
        } else if (type === "incompleteLoadDate") {
            let prevIncompLoadDate;
            if (freightDelFormData.shipperDate !== "") {
                if (Date.parse(freightDelFormData.shipperDate) > Date.parse(date)) {
                    createNotification("error", "Shipper's date should be less than  Incomplete load date");
                } else if (Date.parse(freightDelFormData.receiverDate) < Date.parse(date)) {
                    createNotification("error", " Incomplete load date should be less than Load complete date");

                } else {
                    prevIncompLoadDate = freightDelFormDataClone.incompleteLoadDate;

                    this.setState({ previousIncompleteLoadDate: prevIncompLoadDate, isLoadIncompleteDateChanged: true })
                    setFreightDelFormData({ ...freightDelFormDataClone, incompleteLoadDate: formatedDate });
                }
            } else {
                if (Date.parse(freightDelFormData.shipperDate) > Date.parse(date)) {
                    createNotification("error", "Shipper date should be less than  Incomplete load date");

                } else if (Date.parse(freightDelFormData.receiverDate) < Date.parse(date)) {
                    createNotification("error", "Incomplete load date should be less than Load complete date");
                } else {
                    prevIncompLoadDate = freightDelFormDataClone.incompleteLoadDate;
                    // setPreviousLoadIncompleteDate(prevIncompLoadDate);
                    //setIsLoadIncompleteDateChanged(true);
                    this.setState({ previousIncompleteLoadDate: prevIncompLoadDate, isLoadIncompleteDateChanged: true })
                    setFreightDelFormData({ ...freightDelFormDataClone, incompleteLoadDate: formatedDate });
                }

            }
        }

    }
    getProjectDefaultScope = () => {
        let value = "DEFAULT";
        let filters = `?filter[where][type]=${value}&filter[where][projectId]=${this.state.projectId}`
        projectServices.getFreightDeliveryDefaultScope(filters, this.state.projectId).then((respone) => {

            this.setState({ defaultScopeItemId: respone.data[0].id });
        })
            .catch((error) => {
                console.log(error);
            })
    }

    UndoDates = () => {
        const { previousShipperDate, previousReceiverDate, isReceiverDateChanged, isShipperDateChanged } = this.state;
        const { setFreightDelFormData, freightDelFormData } = this.props;
        let freightDelFormDataClone = { ...freightDelFormData };
        if (document.getElementById("undoShipperDate")) {
            if (isShipperDateChanged) {
                setFreightDelFormData({ ...freightDelFormDataClone, shipperDate: previousShipperDate });
            }
        }
    }
    undoReceiverDate = () => {
        const { previousShipperDate, previousReceiverDate, isReceiverDateChanged, isShipperDateChanged } = this.state;
        const { setFreightDelFormData, freightDelFormData } = this.props;
        let freightDelFormDataClone = { ...freightDelFormData };
        if (document.getElementById("undoReceiverDate")) {
            if (isReceiverDateChanged) {
                setFreightDelFormData({ ...freightDelFormDataClone, receiverDate: previousReceiverDate });
            }
        }
    }
    clearDates = () => {
        const { previousShipperDate, isShipperDateChanged, isReceiverDateChanged } = this.state;
        const { setFreightDelFormData, freightDelFormData } = this.props;
        let freightDelFormDataClone = { ...freightDelFormData };
        if (document.getElementById("clearShipperDate")) {
            this.setState({ isShipperDateChanged: true, previousShipperDate: freightDelFormDataClone.shipperDate })
            setFreightDelFormData({ ...freightDelFormDataClone, shipperDate: "" })

        }
    }
    clearReceiverDate = () => {
        const { previousShipperDate, isShipperDateChanged, isReceiverDateChanged } = this.state;
        const { setFreightDelFormData, freightDelFormData } = this.props;
        let freightDelFormDataClone = { ...freightDelFormData };
        if (document.getElementById("clearReceiverDate")) {
            this.setState({ isReceiverDateChanged: true, previousReceiverDate: freightDelFormDataClone.receiverDate })
            setFreightDelFormData({ ...freightDelFormDataClone, receiverDate: null })
        }
    }

    undoIncompleteLoadDate = () => {
        const { setFreightDelFormData, freightDelFormData } = this.props;
        // const{previousReceiverDate,isReceiverDateChanged}=state;
        let freightDelFormDataClone = { ...freightDelFormData };
        if (document.getElementById("undoIncompleteLoadDate")) {
            if (this.state.isLoadIncompleteDateChanged) {
                setFreightDelFormData({
                    ...freightDelFormDataClone,
                    incompleteLoadDate: this.state.previousIncompleteLoadDate,
                });
            }
        }
    }

    clearIncompleteLoadDate = () => {
        const { setFreightDelFormData, freightDelFormData } = this.props;
        let freightDelFormDataClone = { ...freightDelFormData };
        if (document.getElementById("clearIncompleteLoadDate")) {
            this.setState({ isLoadIncompleteDateChanged: true, previousIncompleteLoadDate: freightDelFormDataClone.incompleteLoadDate })

            setFreightDelFormData({ ...freightDelFormDataClone, incompleteLoadDate: null });
        }

    }

    handleSaveTransportItem = (transportItem) => {
        projectServices.addFreightDelItems(this.state.defaultScopeItemId, this.state.projectId, transportItem).then((res) => {
            console.log(res);
            createNotification("success", "Freight delivery items added successfully")
            this.setState({ projectFreightDeliveryId: res.data.data.projectFreightDeliveryId }, () => {
                this.getFreightDelItems(res.data.data.projectFreightDeliveryId);
                this.handleCloseModal();
            })

        })
            .catch((error) => {
                console.log(error);
            })
    }


    clear = () => {
        this.sigCanvas.current.clear();
    }
    handleSaveItem = () => {
        const { projectId, defaultScopeItemId, selectedLabel } = this.state;
        // if (this.state.projectId !== 0) {
        this.props.handleSaveDefaultScopeFreightDl(this.sigCanvas, projectId, defaultScopeItemId, selectedLabel);
        //  this.props.closeModal();
        // } else {
        // createNotification("error", "Please select project Name before saving .");
        // }

    }
    deleteFreightDelItems = (id) => {
        const { projectFreightDeliveryId } = this.state;
        projectServices.deleteFreightDelItems(projectFreightDeliveryId, id).then((res) => {
            createNotification("success", "transport item deleted successfully.");
            this.getFreightDelItems(projectFreightDeliveryId);
        }).catch((error) => {
            console.log(error);
        })
    }

    handleFreightItemModal = (itemId) => {
        const { defaultScopeItemId, projectId } = this.state;
        if (defaultScopeItemId && projectId) {
            this.setState({ isOpenFreightModal: true, itemId: itemId })
        } else {
            createNotification("error", "Select a project to add record in the table .")
        }

    }
    handleCloseModal = () => {
        this.setState({ isOpenFreightModal: false });
    }
    render() {

        let roleKey = this.props.loginUserData.roleKey;

        const isDisable =
            roleKey !== "" &&
                rules[roleKey][Permission.TRANSPORTDEFAULTSCOPE_WRITE]
                ? ""
                : "disabled";
        return (
            <div>

                <div id="project-button-box-id" style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "20px 20px 0px",
                    fontSize: "16px"
                }}>

                    <div className="freight-title">Add Item</div>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => this.props.closeModal()}
                    ></button>
                </div>
                <div className="freight-block">
                    <div className="freight-content border-top-0">
                        <div className="freight-content-block">
                            <fieldset>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="project-filter-dropdown mb-3  align-items-center">
                                            <label className="col-md-4">Project List</label>

                                            <DropDown
                                                name="ProjectId"
                                                firstOption={{ lable: "All Projects", value: 0 }}
                                                options={this.state.projectList}
                                                onChange={(e) => this.handleOnChange(e)}
                                                defaultSelected={this.state.projectId}
                                                lable=""
                                                value={this.state.projectId}
                                            />
                                            {this.props.freightDelErr && this.props.freightDelErr.projectNameError ? <p className='text-danger'>{this.props.freightDelErr.projectNameError}</p> : <></>}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb-3">

                                        <label className="col-md-4">Default Scope Item Name</label>
                                        <input required="" autocomplete="off" type="text" className="form-control col-md-2" placeholder="Enter scope item name here" name="defaultScopeName" onChange={(e) => this.handleOnChange(e)} value={this.props.freightDelFormData.defaultScopeItemName} disabled={isDisable} />
                                        {this.props.freightDelErr && this.props.freightDelErr.defaultScopeItemNameError ? <p className='text-danger'>{this.props.freightDelErr.defaultScopeItemNameError}</p> : <></>}

                                    </div>
                                    <div className="col-lg-12">

                                        <div className="row">

                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Print Name</label>
                                                    <textarea
                                                        placeholder="Type here"
                                                        required=""
                                                        className="form-control"
                                                        rows="3"
                                                        value={this.props.freightDelFormData.receiver}
                                                        name="receiver"
                                                        onChange={(e) => this.handleOnChange(e)}
                                                        maxLength="70"
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Delivered By</label>
                                                    <textarea
                                                        placeholder="Type here"
                                                        required=""
                                                        className="form-control"
                                                        rows="3"
                                                        value={this.props.freightDelFormData.deliveredBy}
                                                        name="deliveredBy"
                                                        onChange={(e) => this.handleOnChange(e)}
                                                        maxLength="70"
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Onsite Contact</label>
                                                    <textarea
                                                        placeholder="Type here"
                                                        required=""
                                                        className="form-control"
                                                        rows="3"
                                                        value={this.props.freightDelFormData.onsiteContact}
                                                        name="onsiteContact"
                                                        onChange={(e) => this.handleOnChange(e)}
                                                        maxLength="15"
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="row"

                                            >
                                                <div className="col-lg-12">
                                                    <div
                                                        // className= {props.scrollStyle ? "mb-3 table-wrapper-scroll-y my-custom-scrollbar-print": "mb-3 table-wrapper-scroll-y my-custom-scrollbar"} 
                                                        className={"mb-3 table-wrapper-scroll-y my-custom-scrollbar-print"}
                                                    // className="mb-3 table-wrapper-scroll-y my-custom-scrollbar"
                                                    >
                                                        {/* <table
                                                            className="table table-fixed headerMinHeight50 table table-bordered table-striped mb-0"
                                                            disabled={isDisable}
                                                        >
                                                            <thead className="headerTable">
                                                                <tr>
                                                                    <th className="col-xs-3 col-lg-2">Mark</th>
                                                                    <th className="col-xs-1 col-lg-2">Quantity</th>
                                                                    <th className="col-xs-3 col-lg-3">Weight</th>
                                                                    <th className="col-xs-3 col-lg-3">Profile</th>
                                                                    <th className="col-xs-3 col-lg-3">Item Loaded</th>
                                                                    <th className="col-xs-3 col-lg-3">Item Received</th>
                                                                    <th className="col-xs-2 col-lg-2">
                                                                        <AllowedTo perform={Permission.PROJECT_WRITE}>
                                                                            <button type="button" className="a_BtnPlus btn-plus"
                                                                                onClick={() => this.handleFreightItemModal(0)}
                                                                            >
                                                                                <i className="uil uil-plus"></i>
                                                                            </button>
                                                                        </AllowedTo></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.freightItemList.length > 0 &&
                                  this.state.freightItemList.map((item, i) => {
                                    // console.log(item);
                                    return (
                                      <tr key={i}>
                                        <td className="col-xs-3 col-lg-2">
                                          {item.mark}
                                        </td>
                                        <td className="col-xs-1 col-lg-2">
                                          {item.quantity}
                                        </td>
                                        <td className="col-xs-3 col-lg-2">
                                          {item.weight}
                                        </td>
                                        <td className="col-xs-3 col-lg-2">
                                          {item.profile}
                                        </td>
                                        <td className="col-xs-3 col-lg-2">
                                        <input id="chkCheckbox" type="checkbox" className="form-check-input" checked={item.itemLoaded} disabled/>
                                        </td>
                                        <td className="col-xs-3 col-lg-2">
                                        <input id="chkCheckbox" type="checkbox" className="form-check-input" checked={item.itemReceived} disabled/>
                                        </td>
                                        <td className="col-xs-2 col-lg-2" >
                                          <AllowedTo perform={Permission.PROJECT_WRITE}>
                                           <div>
                                           <i className="uil uil-times-circle mx-auto" onClick={() => this.deleteFreightDelItems(item.id)}></i>
                                           
                                            <i className=" uil uil-pen font-size-18" onClick={() => this.handleFreightItemModal(item.id)}></i>
                                           </div>
                                          </AllowedTo>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                                               
                                                            </tbody>
                                                        </table> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Ship From</label>
                                                    <textarea
                                                        placeholder="Type here"
                                                        required=""
                                                        className="form-control"
                                                        rows="3"
                                                        value={this.props.freightDelFormData.shipFrom}
                                                        name="shipFrom"
                                                        onChange={(e) => this.handleOnChange(e)}
                                                        maxLength="200"
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Ship To</label>
                                                    <textarea
                                                        placeholder="Type here"
                                                        required=""
                                                        className="form-control"
                                                        rows="3"
                                                        onChange={(e) => this.handleOnChange(e)}
                                                        name="shipTo"
                                                        maxLength="200"
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Comment</label>
                                                    <textarea
                                                        placeholder="Type here"
                                                        required=""
                                                        className="form-control"
                                                        rows="3"
                                                        name="comments"
                                                        value={this.props.freightDelFormData.comments}
                                                        onChange={(e) => this.handleOnChange(e)}
                                                        maxLength="700"
                                                        disabled={isDisable}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row"

                                        >
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label className="form-label">
                                                        Shipper/Delivery Date
                                                    </label>
                                                    <DatePicker
                                                        className="form-control date-form-control"
                                                        onChange={(date) =>
                                                            this.handleDateChange(date, "shipperDate")
                                                        }
                                                        dateFormat="MM/dd/yyyy"
                                                        placeholderText="MM/DD/YYYY"
                                                        selected={this.props.freightDelFormData.shipperDate}
                                                        disabled={isDisable}
                                                    />
                                                    {this.props.freightDelErr && this.props.freightDelErr.shipperDateError ? <p className='text-danger'>{this.props.freightDelErr.shipperDateError}</p> : <></>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Incomplete Load Date</label>

                                                    <div className="row align-items-center">
                                                        <div className="col-lg-9">
                                                            <DatePicker
                                                                className="form-control date-form-control"
                                                                onChange={(date) =>
                                                                    this.handleDateChange(date, "incompleteLoadDate")
                                                                }
                                                                dateFormat="MM/dd/yyyy"
                                                                placeholderText="MM/DD/YYYY"
                                                                selected={this.props.freightDelFormData.incompleteLoadDate}
                                                                disabled={isDisable}
                                                            />
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <AllowedTo perform={Permission.PROJECT_WRITE}>
                                                                <OverlayTrigger
                                                                    key="top"
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top`}>
                                                                            Undo Incomplete Load Date
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        className="text-danger"
                                                                    >
                                                                        <FaUndoAlt
                                                                            id="undoIncompleteLoadDate"
                                                                            onClick={this.undoIncompleteLoadDate}
                                                                        ></FaUndoAlt>
                                                                    </a>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger
                                                                    key="top"
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top`}>
                                                                            Clear Incomplete Load Date
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <button
                                                                        type="button"
                                                                        className="border-0 bg-transparent"
                                                                    >
                                                                        <FaRegWindowClose
                                                                            id="clearIncompleteLoadDate"
                                                                            onClick={this.clearIncompleteLoadDate}
                                                                        ></FaRegWindowClose>
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </AllowedTo>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Load Complete Date</label>
                                                    {/* <input className="form-control" type="date" value="2019-08-19" /> */}
                                                    <div className="row align-items-center">
                                                        <div className="col-lg-9">
                                                            <DatePicker
                                                                className="form-control date-form-control"
                                                                onChange={(date) =>
                                                                    this.handleDateChange(date, "receiverDate")
                                                                }
                                                                dateFormat="MM/dd/yyyy"
                                                                placeholderText="MM/DD/YYYY"
                                                                selected={this.props.freightDelFormData.receiverDate}
                                                                disabled={isDisable}
                                                            />
                                                            {this.props.freightDelErr && this.props.freightDelErr.receiverDateError ? <p className='text-danger'>{this.props.freightDelErr.receiverDateError}</p> : <></>}
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <AllowedTo perform={Permission.PROJECT_WRITE}>
                                                                <OverlayTrigger
                                                                    key="top"
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top`}>
                                                                            Undo Receiver Date
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        className="text-danger"
                                                                    >
                                                                        <FaUndoAlt
                                                                            id="undoReceiverDate"
                                                                            onClick={this.undoReceiverDate}
                                                                        ></FaUndoAlt>
                                                                    </a>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger
                                                                    key="top"
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top`}>
                                                                            Clear Receiver Date
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <button
                                                                        type="button"
                                                                        className="border-0 bg-transparent"
                                                                    >
                                                                        <FaRegWindowClose
                                                                            id="clearReceiverDate"
                                                                            onClick={this.clearReceiverDate}
                                                                        ></FaRegWindowClose>
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </AllowedTo>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="row align-items-center">
                                                    {/* <div className="col-lg-9"> */}
                                                    <label className="form-label">Receiver Name</label>
                                                    <div className="mb-3  " id="canvasDiv">
                                                        <input type="text" name="receiverName"
                                                            className="form-control date-form-control"
                                                            value={this.props.freightDelFormData.receiverName}
                                                            onChange={(e) => this.handleOnChange(e)}
                                                        />
                                                    </div>
                                                    {/* </div> */}

                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="mb-3" id="canvasDiv">
                                                    <label className="form-label">Signature Pad</label>
                                                    <SignaturePad
                                                        //  ref={  setRef}
                                                        ref={this.sigCanvas}
                                                        canvasProps={{
                                                            className: "signatureCanvas form-control",
                                                        }}
                                                    />
                                                    <div className="text-center mt-3">
                                                        <button
                                                            className="btn btn-primary waves-effect waves-light"
                                                            onClick={this.clear}
                                                        >
                                                            Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="actions clearfix saveBtn text-center">
                                        <a
                                            href="javascript:void(0)"
                                            role="menuitem"
                                            className="btn btn-primary waves-effect waves-light"
                                            onClick={this.handleSaveItem}
                                        >
                                            Save
                                        </a>
                                    </div>
                                    <ModalPopUp
                                        componentData={<AddTransportItem
                                            closeModal={this.handleCloseModal}
                                            // updateSingleTransportItem={updateSingleTransportItem}
                                            // projectFreightDeliveryId={props.freightDelItemsId}
                                            itemId={this.state.itemId}
                                            handleSaveTransportItem={this.handleSaveTransportItem}


                                        />}
                                        openModal={this.state.isOpenFreightModal}
                                    />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}
const mapStateToProps = (props) => {

    return {
        freightDelFormData: props.freightDelFormData,
        loginUserData: props.loginUserData,
        freightDelErr: props.freightDelErrs
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setFreightDelFormData: setFreightDelFormData,
    setFreightDelError: setFreightDelError,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTansportScopeItemData);

