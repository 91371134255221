import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { getLoginToken, getApiUrl } from '../helpers/Util';
import { createNotification } from '../helpers/Notification';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import LeftBar from '../layout/leftbar';
import AccessDenied from '../common/AccessDenied';
import { rules, Permission } from "../../abac.config";
import { AllowedTo } from "react-abac";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ConfirmBox from "../common/ConfirmBox";
import Loader from "../common/Loader";

class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: [],
            search: "",
            isShowConfirmBox: false,
            loader: false,
            removeId: "",
            //pagination constants---------------
            pageChange: 1,
            skip: 0,
            limit: 10,
            count: 0,
            resDataLen: 0,
        }
    }

    componentDidMount = () => {
       this.handleUserData();
        
    }
    handleUserData=()=>{
        localStorage.removeItem('shopMaintenancePaginationConstants');
        localStorage.removeItem('fieldMaintenancePaginationConstants')
        let filters ;
        if(JSON.parse(localStorage.getItem('userListPaginationConstants'))!==null){
            const userListPaginationConst= JSON.parse(localStorage.getItem('userListPaginationConstants'));
            const{previousLimit,previousPage,previousSkip,previousSearch}=userListPaginationConst;
           
            this.setState({pageChange:previousPage,limit:previousLimit,skip:previousSkip,search:previousSearch
            })
            filters = `?filter[skip]=${previousSkip}&filter[limit]=${previousLimit}`;
            this.getUserList(filters);
            }else{
                filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                this.getUserList(filters);
            }
       
    }

    getUserList = async (filters) => {
        const { search } = this.state;
        console.log(search);
        const token = getLoginToken();
        let result = true;
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        }
        let url;
        const URL = getApiUrl();
        if(JSON.parse(localStorage.getItem('userListPaginationConstants'))!==null){
            const userListPaginationConst= JSON.parse(localStorage.getItem('userListPaginationConstants'));
            const{previousSearch}=userListPaginationConst;
            url=`${URL}/users${filters}&filter[where][search]=${previousSearch}`
        }else{
            url = `${URL}/users${filters}&filter[where][search]=${search}`;
        }
        

        await axios.get(url, {
            headers: headers
        })
            .then((response) => {
                if (response.status) {
                    let responseData = response.data.data;
                    let responseCount = response.data.count;
                    result = false;
                    this.setState({ count: responseCount, resDataLen:responseData.length,loader: true },()=>{
                        this.handleUserList(responseData);
                    });
                    
                   
                }

            })
            .catch((error) => {

                if (error.response.status === 401) {
                    createNotification("error", "Your login session has been expired!");
                    localStorage.clear();
                    this.props.history.push('/login');
                    this.setState({ loader: false });
                } else {
                    createNotification("error", error.response.data.error.message);
                    //this.setState({loader:false});
                }
                console.log({ resetPasswordError: error });

            })

        return result;
    }

    handleUserList = (responseData) => {
        const URL = getApiUrl();
        let listArr = [];
        responseData && responseData.map((item) => {
            let itemData = { ...item };
            itemData.role = itemData.role_fkey && itemData.role_fkey.title;
            itemData.status = itemData.status.charAt(0).toUpperCase() + itemData.status.slice(1);
            itemData.profilePic = itemData.upload_fkey && itemData.upload_fkey.filePath;
            listArr.push(itemData);
        });
        this.setState({ userData: listArr, loader: false });
        localStorage.removeItem('userListPaginationConstants');
    }

    imageFormat = (cell, row, rowIndex, formatExtraData) => {
        const image = (row.profilePic == undefined || row.profilePic == "undefined" || row.profilePic == null) ? '/assets/images/profile-pic.jpeg' : row.profilePic;
        return (
            <div className="userDetialsMin">
                <img src={image} alt="" className="avatar-xs rounded-circle me-2" onError={event => {
                    event.target.src = "/assets/images/profile-pic.jpeg"
                    event.onerror = null
                }} />
                <a href="#" className="text-body" title={row.name}>{row.name}</a>
            </div>
        )
    }

    handleConfirmModal = (isYes, id = "") => {
        if (isYes) {
            const token = getLoginToken();
            const headers = {
                "Authorization": `Bearer ${token}`
            }
            const { removeId,pageChange,skip,limit,resDataLen } = this.state;
            let filters;
            let localSkip = 0;
            let localPageChange = 0;
            if (resDataLen === 1 && pageChange > 1) {
              localPageChange = pageChange - 1;
              localSkip = skip - limit;
              this.setState({ pageChange: localPageChange, skip: localSkip });
              filters = `?filter[skip]=${localSkip}&filter[limit]=${limit}`
            } else {
              filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`
            }
            const URL = getApiUrl();
            
            let url = `${URL}/users/${removeId}`;
            axios.delete(url, {
                headers: headers
            })
                .then((response) => {
                    if (response.status === 200) {
                        createNotification("success", "User delete successfully!");
                        this.getUserList(filters);
                        this.setState({ removeId: "", isShowConfirmBox: false, loader: false });
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        createNotification("error", "Your login session has been expired!");
                        localStorage.clear();
                        this.props.history.push('/login');
                    } else {
                        createNotification("error", error.response.data.error.message);

                    }
                    this.setState({ removeId: "", isShowConfirmBox: false, loader: false });
                    console.log({ resetPasswordError: error });
                })

        } else {
            this.setState({ removeId: "", isShowConfirmBox: false, loader: false });
        }
    };

    actionFormat = (cell, row, rowIndex, formatExtraData) => {
        // console.log('formatter =>', row)
        const{pageChange,skip,limit,search}=this.state
        const data={
            key:"single",
            pageChange:pageChange,
            limit:limit,
            skip:skip ,
            search:search
        }
        
        // const image = (row.profilePic == undefined || row.profilePic == "undefined" || row.profilePic == null)? '/assets/images/profile-pic.jpeg':row.profilePic;
        return (
            <div>
                <td>
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            {/* <a href="#" className="px-2 text-primary"> */}
                            <Link to=
                            // {`/update/${row.id}`} 
                            {{
                                pathname:`/update/${row.id}`,
                                state:{data:data}
                                }}
                            >
                                <i className="uil uil-pen font-size-18"></i>
                            </Link>
                            {/* </a> */}
                        </li>
                        <AllowedTo perform={Permission.USER_DELETE}>
                            <li className="list-inline-item">
                                <a href="javascript:void(0)" className="text-danger">
                                    <i className="uil uil-trash-alt font-size-18" onClick={() => this.setState({ removeId: row.id, isShowConfirmBox: true })}></i>
                                </a>
                            </li>
                        </AllowedTo>
                    </ul>
                </td>
            </div>
        )
    }

    handleSearchUser = (event) => {
        
        this.setState({ search: event.target.value,pageChange:1,skip:0,limit:10 }, () => {
            let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
            this.getUserList(filters);
        });
    }

    render() {
        const { userData, isShowConfirmBox, skip, limit, count, pageChange ,loader,search} = this.state;
        const data={
            key:"single",
            pageChange:pageChange,
            limit:limit,
            skip:skip ,
            search:search
        }
        const handleOnTableChange = (type, newState) => {
            console.log("type", type, newState);
            if (type === "sort") {
                if (newState.sortOrder == "asc") {
                    userData.sort((obj1, obj2) => {
                        console.log("compare --", obj1[newState.sortField]);
                        return newState.sortField == "phone" ?
                            obj1[newState.sortField] - obj2[newState.sortField] :
                            newState.sortField == "profilePic" ?
                                (obj1.name.trim().localeCompare(obj2.name.trim()))
                                :
                                obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim()) //, undefined, { numeric: true, sensitivity: 'base' }
                            ;

                    });
                }
                else {
                    userData.sort((obj2, obj1) => {
                        return newState.sortField == "phone" ?
                            obj1[newState.sortField] - obj2[newState.sortField] :
                            newState.sortField == "profilePic" ?
                                (obj1.name.trim().localeCompare(obj2.name.trim()))
                                :
                                obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim())
                            ;

                    });
                }
            }


        }
        const columns = [{
            dataField: 'profilePic',
            text: 'Name',
            sort: true,
            formatter: this.imageFormat
        },
        {
            dataField: 'email',
            text: 'Email ID',
            sort: true
        }, {
            dataField: 'phone',
            text: 'Phone Number',
            sort: true
        }, {
            dataField: 'role',
            text: 'User Role',
            sort: true
        }, {
            dataField: 'status',
            text: 'Status',
            sort: true
        }, {
            dataField: '',
            text: 'Action',
            formatter: this.actionFormat
        }];
        const sizePerPageOptions = [
            {
                text: '10',
                value: 10,
            },
            {
                text: '25',
                value: 25,
            },
            {
                text: '30',
                value: 30,
            },
            {
                text: '50',
                value: 50,

            },
            {
                text: '100',
                value: 100,
            },
            // Add more options as needed
        ];
        let that = this;
        const pagination = paginationFactory({
            page: pageChange,
            sizePerPage: limit,
            lastPageText: '>>',
            firstPageText: '<<',
            nextPageText: '>',
            prePageText: '<',
            totalSize: count,
            sizePerPageList: sizePerPageOptions,
            // showTotal: true,
            alwaysShowAllBtns: true,
            onPageChange: function (page, sizePerPage) {
                let skp = (page - 1) * sizePerPage;
                let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
                that.setState({ pageChange: page, skip: skp, limit: sizePerPage, loader: true }, () => {
                    that.getUserList(filters);
                })
                // that.getLocationBySearching(page, filters);

                // that.getProjectInventories(filters);
            },
            onSizePerPageChange: function (sizePerPage, page) {
                if (document.getElementsByClassName('dropdown-menu show')[0].classList.contains('show') === true) {
                    document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
                }
                let skp = (page - 1) * limit;
                that.setState({ pageChange: page, skip: skp, limit: sizePerPage, loader: true }, () => {
                    let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
                    that.getUserList(filters);
                })
            }
        });
        let roleKey = this.props.loginUserData.roleKey;
        const isAccess = (roleKey !== "" && rules[roleKey][Permission.USER_WRITE]) ? true : false;


        return (
            <div>
                {this.state.loader === true ? <Loader /> :
                
                <div>

                {!isAccess ? <AccessDenied /> :
                    <div className="main-content">
                        <ConfirmBox
                            openModal={isShowConfirmBox}
                            confirmMsg="Are you sure to delete this user?"
                            handleConfirmModal={this.handleConfirmModal}
                        />
                        <div className="page-content">
                            <div className="container-fluid">


                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <ol className="breadcrumb m-0">
                                                <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                                                &nbsp; / &nbsp;
                                                <Link to={`/user`}><li className="breadcrumb-item">User</li></Link>
                                            </ol>
                                        </div>
                                        <h4 className="mb-0">User List</h4>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card card-block">
                                            <div className="card-body">
                                                <div className="row mb-2">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <Link to={{
                                                               pathname: "/adduser",
                                                               state:{data:data}
                                                               }}>
                                                                <span className="btn btn-success waves-effect waves-light">
                                                                    <i className="uil uil-plus"></i> Add New
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-inline float-md-end mb-3">
                                                            <div className="search-box ms-2">
                                                                <div className="position-relative">
                                                                    <input type="text" className="form-control rounded bg-light border-0" placeholder="Search..." 
                                                                    value={this.state.search}
                                                                    onChange={(e) => this.handleSearchUser(e)} />
                                                                    <i className="uil uil-search search-icon"></i>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                                <BootstrapTable
                                                    remote={true}
                                                    keyField='id'
                                                    data={(userData.length > 0) ? userData : []}
                                                    columns={columns}
                                                    onTableChange={handleOnTableChange}
                                                    // pagination={paginationFactory({ sizePerPageList: sizePerPageOptions })} 
                                                    pagination={pagination}
                                                    noDataIndication={
                                                        loader === true ? (
                                                            <Loader />
                                                        ) : (
                                                            <p style={{ color: "red" }}>
                                                                {"No Data Found"}
                                                            </p>
                                                        )
                                                    }
                                                />

                                                {/* <div className="table-responsive mb-4">
                            <table className="table table-centered table-nowrap mb-0">
                                <thead>
                                    <tr>
                                       
                                        <th scope="col">Name</th>
                                        <th scope="col">Email ID</th>
                                        <th scope="col">Phone Number</th>
                                        
                                        <th scope="col">User Role</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" style={{ width: "200px;" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {userData && userData.map((item) => {

                                        return (<tr>
                                            
                                            <td>
                                                <img src="assets/images/avatar-2.jpg" alt="" className="avatar-xs rounded-circle me-2" />
                                                <a href="#" className="text-body">{item.name}</a>
                                            </td>
                                            <td>{item.email}</td>
                                            <td>{item.phone}</td>

                                            <td>Subscriber</td>
                                            <td>{item.status}</td>
                                            <td>
                                                <ul className="list-inline mb-0">
                                                    <li className="list-inline-item">
                                                        <a href="#" className="px-2 text-primary"><i className="uil uil-pen font-size-18"></i></a>
                                                    </li>
                                                                                                  </ul>
                                            </td>
                                        </tr>)

                                    })

                                    }

                                </tbody>
                            </table>
                        </div> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                }
            </div>
                
                }
                
                
            </div>

        )
    }
}


const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({


}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserList);