import axios from 'axios';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Footer from '../../layout/Footer';
import Loader from '../common/Loader';
import { createNotification } from '../helpers/Notification';
import { getApiUrl } from '../helpers/Util';
export default class ForgotPassword extends Component {
  constructor(props){
      super(props);
      this.state = {
        email:"",
        loader:false
      }
      this.validator = new SimpleReactValidator();
  }
  //checkValidEmail
  handleOnChange = (event) =>{
    const email = event.target.value;
    this.setState({email});
  }

  handleResetState = () =>{

    this.setState({email:""});
  }

  handleOnResetClick = () => {
    
    const { email } = this.state;
    if (this.validator.allValid()) {
    const URL =   getApiUrl();
    const headers = {
      "Content-Type":"application/json",
      "Accept":"application/json"
    }
    this.setState({loader:true});
    const url = `${URL}/forget-password`;
    
    const data = {email};
    axios.post(url, data, {
        headers: headers
      })                                                                            
      .then((response) => {
        if(response.status === 200){
           this.setState({loader:false});
           createNotification("success", response.data.message);
            this.handleResetState();

        }
        console.log({response});
      })
      .catch((error) => {
        if(error.response.status === 404){
          createNotification("error", "This email ID is not registered!");
          this.setState({loader:false});
        }
        console.log({resetPasswordError:error});
      })
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  
  render() {
    return (
      <div>
         {this.state.loader ? <Loader /> :
          <>
            <main className="user-account-page">
        <div className="container-fluid">
          <section className="form-container">
            <div className="row">
              <div className="col-md-6 logo-col">
                <div className="logo-content-frame">
                  <h1 className="logo-frame">
                    <span className="logo"></span>
                  </h1>
                  <h2 className="title">Welcome, User</h2>
                  <label className="lbl">Forgot Password?</label>
                </div>
              </div>
              <div className="col-md-6 form-col">
                <div className="content-frame">
                  <h2 className="title">Forgot Password</h2>

                  <section className="form-frame login-form">
                    <div className="form-group">
                      <input type="text" name="email" placeholder="Email" value={this.state.email} onChange={this.handleOnChange} className="form-control" />
                      {this.validator.message('email', this.state.email, 'required|email', { className: 'text-danger' })}
                    </div>

                    <div className="btn-row">
                      <input type="button" name="signIn" value="Reset My Password" className="btn-rnd-black btn-signIn" onClick={this.handleOnResetClick} />
                      <input type="button" name="signIn" value="Back to Signin" className="btn-rnd-black btn-signIn mt-3" onClick={()=>{this.props.history.push('/login')}} />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>       
        </div>
    </main>
    <Footer />
    </>
        }
        </div>
    )
  }
}
