import axios from "axios";
import React, { Component } from "react";
import { DropDown } from "../common";
import {
  getLoginToken,
  dateFormat,
  dbDateFormat,
  convertToGmt,
} from "../helpers/Util";
import { createNotification } from "../helpers/Notification";
import { getManagerList } from "./Helper";
import SimpleReactValidator from "simple-react-validator";
import { getApiUrl } from "../helpers/Util";
import DatePicker from "react-datepicker";
import AlertBox from "../common/AlertBox";

import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
export default class AddEstimation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // managerList: [],
      project_type: "",
      contractor: "",
      bid_amount: "0",
      bid_invite_date: "",
      bid_due_date: "",
      bid_due_sent_date: "",
      projectName: "",
      addendums: "",
      estimator: "",
      awarded: "No",
    };
    this.itemRef = React.createRef();
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = () => {
    // this.generatebid_amount();
    // getManagerList().then((response) => {
    //     this.generatebid_amount();
    //     this.setState({ managerList: [...response] });
    // })
  };

  handleCloseAlert = () => {
    this.setState({ isShowAlertBox: false });
  };

  handleOnChange = (event) => {

    console.log("event.target.value",event.target.value);
    if (event.target.name === "bid_amount") {
      this.setState({ bid_amount: event.target.value });
    } else if (event.target.name === "bid_invite_date") {
      this.setState({ bid_invite_date: event.target.value });
    } else if (event.target.name === "bid_due_date") {
      this.setState({ bid_due_date: event.target.value });
    } else if (event.target.name === "bid_due_sent_date") {
      this.setState({ bid_due_sent_date: event.target.value });
    } else if (event.target.name === "projectName") {
      this.setState({ projectName: event.target.value });
    } else if (event.target.name === "addendums") {
      this.setState({ addendums: event.target.value });
    } else if (event.target.name === "project_type") {
      this.setState({ project_type: event.target.value });
    } else if (event.target.name === "awarded") {
        this.setState({ awarded: event.target.value });
      
    } else if (event.target.name === "estimator") {
      this.setState({ estimator: event.target.value });
    } else if (event.target.name === "contractor") {
      this.setState({ contractor: event.target.value });
    }
  };

  handleDateChange = (date, type) => {
    let formatedDate = date;
    // if (type === "bid_invite_date") {
    //     this.setState({ bid_invite_date: formatedDate });
    //  }else if (type === "bid_due_date") {
    //      this.setState({ bid_due_date: formatedDate });
    //  }

    if (type === "bid_invite_date") {
      if (this.state.bid_due_date !== "") {
        if (Date.parse(this.state.bid_due_date) < Date.parse(date)) {
          createNotification(
            "error",
            "Bid Invite date should be less than Bid Due Sent date"
          );
        } else {
          this.setState({ bid_invite_date: formatedDate });
        }
      } else {
        this.setState({ bid_invite_date: formatedDate });
      }
    } else if (type === "bid_due_date") {
      if (this.state.bid_invite_date !== "") {
        if (Date.parse(this.state.bid_invite_date) > Date.parse(date)) {
          createNotification(
            "error",
            "Bid Due Sent date should be greater than Bid Invite Date"
          );
        } else {
          this.setState({ bid_due_date: formatedDate });
        }
      } else {
        this.setState({ bid_due_date: formatedDate });
      }
    } else if (type === "bid_due_sent_date") {
      if (this.state.bid_invite_date !== "") {
        if (Date.parse(this.state.bid_invite_date) > Date.parse(date)) {
            createNotification("error", "Bid Due Sent date date should be greater");
        } else {
        this.setState({ bid_due_sent_date: formatedDate });
        }
      } else {
        this.setState({ bid_due_sent_date: formatedDate });
      }
    }
  };

  checkScopeEmpty = (scopeItemData) => {
    let result = false;
    for (let i = 0; i < scopeItemData.length; i++) {
      if (scopeItemData[i].title.trim() === "") {
        createNotification("error", "Scope Item should not be blank!");
        result = true;
        break;
      }
    }
    return result;
  };

  handleSaveProject = (isSaveClick = false) => {
    const {
      project_type,
      bid_amount,
      contractor,
      bid_invite_date,
      bid_due_date,
      bid_due_sent_date,
      projectName,
      addendums,
      estimator,
      awarded,
    } = this.state;

    if (
      this.validator.allValid()
    ) {

      const token = getLoginToken();

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      this.setState({ loader: true });
      const URL = getApiUrl();
      let url = "";
      const data = {
        project_name: projectName,
        bid_amount: Number(bid_amount),
        project_type,
        contractor,
        bid_invite_date: dbDateFormat(bid_invite_date),
        bid_due_date: dbDateFormat(bid_due_date),
        bid_due_sent_date: dbDateFormat(bid_due_sent_date),
        addendums,
        estimator,
        awarded_status: awarded ,
      };

      url = `${URL}/estimations`;
      axios
        .post(url, data, {
          headers: headers,
        })
        .then((response) => {
          if (response.status === 200) {
            createNotification("success", "Estimation added successfully!");

            // check this
            if (isSaveClick) {
                this.props.history.push(`/estimation`);
            } else {
                this.props.history.push(`/estimation`);
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            createNotification("error", "Your login session has been expired!");
            localStorage.clear();
            this.props.history.push("/login");
          } else {
            createNotification("error", error.response.data.error.message);
          }
          console.log({ resetPasswordError: error });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    const awardOption = [{ lable: "Yes" }];
    const ProjectTypeList = [{ 
      lable: "MULTI FAMILY WALK UP" },
     { lable: "MULTI FAMILY WRAP" },
     { lable: "MULTI FAMILY PODIUM" },
     { lable: "MULTI FAMILY MID RISE" },
     { lable: "MULTI FAMILY HIGH RISE" },
     { lable: "COMMERCIAL HIGH RISE" },
     { lable: "HOSPITALITY" },
     { lable: "STUDENT HOUSING"},
     { lable: "ASSISTED LIVING RETAIL"},
     { lable: "MEDICAL"},
     { lable: "TILT UP"},
    ];
    const {
      bid_amount,
      contractor,
      projectName,
      bid_invite_date,
      bid_due_date,
      bid_due_sent_date,
      project_type,
      addendums,
      loader,
      estimator,
      awarded,
    } = this.state;
    return (
      <div>
        {/* <AllowedTo
                    perform={Permission.USER_READ}
                    no={() =><AccessDenied />}
                > */}
        <div className="main-content">
          {/* <AlertBox
                        openModal={this.state.isShowAlertBox}
                        alertMsg="Please click on + button to add scope item."
                        handleConfirmModal={this.handleConfirmModal}
                        handleClose={this.handleCloseAlert}
                    /> */}
          <div className="page-content">
            <div className="container-fluid">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <ol className="breadcrumb m-0">
                  <Link to={`/#`}>
                    <li className="breadcrumb-item">Home</li>
                  </Link>
                  &nbsp; / &nbsp;
                  <Link to={`/estimation`}>
                    <li className="breadcrumb-item">Estimation</li>
                  </Link>
                  &nbsp; / &nbsp;
                  <Link to={`/estimation/add`}>
                    <li className="breadcrumb-item">Create Estimation</li>
                  </Link>
                </ol>
              </div>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Create Estimation</h4>
                {/* <div className="project-button-box">
                            <button type="button" className="btn btn-primary waves-effect waves-light">
                                <i className="uil uil-edit"></i> Save
                            </button>
                        </div> */}
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body bgW">
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <form className="form-project">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom01"
                                  >
                                    Project Name
                                  </label>
                                  <input
                                    type="text"
                                    maxLength={70}
                                    className="form-control"
                                    id="validationCustom01"
                                    placeholder="Project name"
                                    value={projectName}
                                    name="projectName"
                                    onChange={(e) => this.handleOnChange(e)}
                                  />
                                  {this.validator.message(
                                    "Project Name",
                                    projectName,
                                    "required|max:200",
                                    { className: "text-danger" }
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom02"
                                  >
                                    Project Type
                                  </label>
                                  <DropDown
                                    name="project_type"
                                    className="mb-0"
                                    firstOption={{
                                      lable: "Select Project Type",
                                      value: 0,
                                    }}
                                    options={ProjectTypeList}
                                    onChange={(e) => this.handleOnChange(e)}
                                    //defaultSelected={this.state.managerId}
                                    lable=""
                                  />
                                  {/* cross check  */}
                                  {/* {this.validator.message(
                                    "project_type",
                                    project_type,
                                    "required",
                                    { className: "text-danger" }
                                  )} */}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom03"
                                  >
                                    Addendums
                                  </label>
                                  <textarea
                                    className="form-control"
                                    rows="3"
                                    name="addendums"
                                    onChange={(e) => this.handleOnChange(e)}
                                  >
                                    {addendums}
                                  </textarea>
                                  {/* {this.validator.message(
                                    "addendums",
                                    addendums,
                                    "required|max:700",
                                    { className: "text-danger" }
                                  )} */}
                                </div>
                              </div>
                            </div>
                            <div className="row"> 
                            <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom01"
                                  >
                                   General Contractor
                                  </label>
                                  <input
                                    type="text"
                                    maxLength={70}
                                    name="contractor"
                                    className="form-control"
                                    id="validationCustom01"
                                    value={this.state.bidcontractor_amount}
                                    onChange={(e) => this.handleOnChange(e)}
                                  />
                                  {/* {this.validator.message(
                                    "contractor",
                                    this.state.contractor,
                                    "required|min:2|max:30",
                                    { className: "text-danger" }
                                  )} */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom01"
                                  >
                                    Estimator
                                  </label>
                                  <input
                                    type="text"
                                    maxLength={70}
                                    name="estimator"
                                    placeholder="Estimator"
                                    className="form-control"
                                    id="validationCustom01"
                                    value={estimator}
                                    onChange={(e) => this.handleOnChange(e)}
                                    // onBlur={(e) => this.handleOnblurBalanceBill(e)} onKeyUp={(e) => this.handleOnblurBalanceBill(e)}
                                  />
                                  {/* {this.validator.message(
                                    "Estimator",
                                    estimator,
                                    "required|max:20",
                                    { className: "text-danger" }
                                  )} */}
                                </div>
                              </div>
                              </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom01"
                                  >
                                    Bid Invite Date
                                  </label>
                                  <DatePicker
                                    className="form-control date-form-control"
                                    onChange={(date) =>
                                      this.handleDateChange(
                                        date,
                                        "bid_invite_date"
                                      )
                                    }
                                    dateFormat="P"
                                    selected={bid_invite_date}
                                    placeholderText="MM/DD/YYYY"
                                  />

                                  {/* {this.validator.message(
                                    "bid_invite_date",
                                    bid_invite_date,
                                    "required",
                                    { className: "text-danger" }
                                  )} */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom02"
                                  >
                                    Bid Due Date{" "}
                                  </label>
                                  <DatePicker
                                    className="form-control date-form-control"
                                    onChange={(date) =>
                                      this.handleDateChange(
                                        date,
                                        "bid_due_date"
                                      )
                                    }
                                    dateFormat="P"
                                    selected={bid_due_date}
                                    minDate={bid_invite_date}
                                    placeholderText="MM/DD/YYYY"
                                  />
                                  {/* {this.validator.message(
                                    "bid_due_date",
                                    bid_due_date,
                                    "required",
                                    { className: "text-danger" }
                                  )} */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom02"
                                  >
                                    Bid Due Sent Date{" "}
                                  </label>
                                  <DatePicker
                                    className="form-control date-form-control"
                                    onChange={(date) =>
                                      this.handleDateChange(
                                        date,
                                        "bid_due_sent_date"
                                      )
                                    }
                                    dateFormat="P"
                                    selected={bid_due_sent_date}
                                    minDate={bid_invite_date}
                                    placeholderText="MM/DD/YYYY"
                                  />
                                  {/* {this.validator.message(
                                    "bid_due_sent_date",
                                    bid_due_sent_date,
                                    "required",
                                    { className: "text-danger" }
                                  )} */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom01"
                                  >
                                    Awarded
                                  </label>
                                  <DropDown
                                    name="awarded"
                                    className="mb-0"
                                    firstOption={{
                                      lable: "No",
                                      value: "No",
                                    }}
                                    options={awardOption}
                                    onChange={(e) => this.handleOnChange(e)}
                                    lable=""
                                  />
                                  {/* {this.validator.message(
                                    "awarded",
                                    awarded,
                                    "required",
                                    { className: "text-danger" }
                                  )} */}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustom01"
                                  >
                                    Bid Amount
                                  </label>
                                  <input
                                    type="number"
                                    maxLength={6}
                                    name="bid_amount"
                                    className="form-control"
                                    id="validationCustom01"
                                    value={this.state.bid_amount}
                                    onChange={(e) => this.handleOnChange(e)}
                                  />
                                  {/* {this.validator.message(
                                    "bid_amount",
                                    this.state.bid_amount,
                                    "required|integer|min:2|max:6",
                                    { className: "text-danger" }
                                  )} */}
                                </div>
                              </div>
                              
                            </div>
                            <br />
                          </form>
                        </div>
                        <div className="actions clearfix">
                          <ul role="menu" aria-label="Pagination">
                            {/* <li className="disabled" aria-disabled="true"><a href="#previous" role="menuitem">Previous</a></li> */}
                            <li
                              aria-hidden="false"
                              aria-disabled="false"
                              className=""
                            >
                              <a
                                role="menuitem"
                                href="javascript:void(0)"
                                onClick={() => this.handleSaveProject(true)}
                              >
                                Create
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </AllowedTo> */}
      </div>
    );
  }
}
