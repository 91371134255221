import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getApiUrl } from "../helpers/Util";
import { createNotification } from "../helpers/Notification";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import MasterDataService from "../services/MasterDataService";
import ConfirmBox from "../common/ConfirmBox";
import Loader from "../common/Loader";
export default class DrawingTypeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      title:"",
      search: "",
      isShowConfirmBox: false,
      loader: false,
      removeId:""
    };
  }

  componentDidMount = () => {
    this.getDrawingTypeList();
  };

  getDrawingTypeList = async () => {
    MasterDataService.getDrawingTypes()
      .then((response) => {
        if (response.status) {
          let responseData = response.data.data;
          this.handleDrawingTypeList(responseData);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          createNotification("error", "Your login session has been expired!");
          localStorage.clear();
          this.props.history.push("/login");
        } else {
          createNotification("error", error.response.data.error.message);
        }
      });
  };

  handleDrawingTypeList = (responseData) => {
    const URL = getApiUrl();
    let listArr = [];
    responseData &&
      responseData.map((item,index) => {
        let itemData = { ...item };
        itemData.role = itemData.role_fkey && itemData.role_fkey.title;
        itemData.status = itemData.status;
        itemData.project_key = index;
        itemData.profilePic =
          itemData.upload_fkey && URL + itemData.upload_fkey.filePath;
        listArr.push(itemData);
      });
    this.setState({ userData: listArr });
  };

  imageFormat = (cell, row, rowIndex, formatExtraData) => {
    const image =
      row.profilePic == undefined ||
      row.profilePic == "undefined" ||
      row.profilePic == null
        ? "/assets/images/profile-pic.jpeg"
        : row.profilePic;
    return (
      <div>
        <a href="#" className="text-body">
          {row.title}
        </a>
      </div>
    );
  };

  actionFormat = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <td>
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <Link to={`/drawingtype/${row.id}`}>
                <i className="uil uil-pen font-size-18"></i>
              </Link>
            </li>
            <li className="list-inline-item">
              <a href="javascript:void(0)" className="text-danger">
                <i className="uil uil-trash-alt font-size-18" onClick={() => this.setState({removeId:row.id, isShowConfirmBox: true})}></i>
              </a>
            </li>
          </ul>
        </td>
      </div>
    );
  };

  handleConfirmModal = (isYes, id = "") => {
    if (isYes) {
      this.setState({
        isShowConfirmBox: !this.state.isShowConfirmBox,
        loader: true,
      });
      const { removeId } = this.state;
      MasterDataService.deleteDrawingById(removeId)
      .then((response) => {
        if (response.status) {
          createNotification("success", "Drawing Type deleted successfully");
          this.setState({ removeId: "",isShowConfirmBox:false, loader: false });
          this.getDrawingTypeList();
        }
        console.log({ locationList: response });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          createNotification("error", "Your login session has been expired!");
          localStorage.clear();
          this.props.history.push("/login");
          //this.setState({loader:false});
        } else {
          createNotification("error", error?.response?.data.error.message);
          //this.setState({loader:false});
          this.setState({ removeId: "",isShowConfirmBox:false, loader: false });
        }
      });
    } else {
      this.setState({ removeId: "",isShowConfirmBox:false, loader: false });
    }
  };

  handleSearchUser = (event) => {
    this.setState({ search: event.target.value }, () => {
      this.getDrawingTypeList();
    });
  };
  

  render() {
    const { userData,isShowConfirmBox } = this.state;
    const handleOnTableChange = (type, newState) =>{
      console.log("type",type);
      if(type === "sort")  {
        if(newState.sortOrder == "asc"){
          userData.sort((obj1,obj2) => {
           return  obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
           ;     
        }); 
      }
        else
        {userData.sort((obj2,obj1) => {
            return obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
            ; 
         });
        }
      }
  }
   
    const columns = [
      {
        dataField: "title",
        text: "Name",
        sort:true,
        formatter: this.imageFormat,
      },
      {
        dataField: "status",
        text: "Status",
        sort:true
      },
      {
        dataField: "",
        text: "Action",
        formatter: this.actionFormat,
      },
    ];
    const sizePerPageOptions = [
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
      {
        text: '30',
        value: 30,
      },
      {
        text: '50',
        value: 50,

      },
      {
        text: '100',
        value: 100,
      },
      // Add more options as needed
    ];

    return (
      <div>
        <div className="main-content">
        <ConfirmBox
              openModal={isShowConfirmBox}
              confirmMsg="Are you sure to delete this Drawing Type?"
              handleConfirmModal={this.handleConfirmModal}
            />
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <ol className="breadcrumb m-0">
                      <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                      &nbsp; / &nbsp;
                      <Link to={`/drawingtype`}><li className="breadcrumb-item">Drawing Type</li></Link>
                    </ol>
                  </div>
                  <h4 className="mb-0">Drawing Type List</h4>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card card-block">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <Link to="/adddrawingtype">
                              <span className="btn btn-success waves-effect waves-light">
                                <i className="uil uil-plus"></i> Add New
                              </span>
                            </Link>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-inline float-md-end mb-3">
                            <div className="search-box ms-2">
                            </div>
                          </div>
                        </div>
                      </div>
                      <BootstrapTable
                      remote={{
                        pagination:false,
                        sort:true
                       }}
                        keyField="project_key"
                        data={userData}
                        columns={columns}
                        onTableChange={handleOnTableChange}
                       pagination={paginationFactory({   sizePerPageList:sizePerPageOptions })} 
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
