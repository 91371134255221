import axios from 'axios';
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Footer from '../../layout/Footer';
import Loader from '../common/Loader';
import { createNotification } from '../helpers/Notification';
import { checkValidPasswpord, getApiUrl } from '../helpers/Util';
export default class ResetPassword extends Component {
  constructor(props){
    super(props)
    this.state = {
      password:"",
      confirmPassword:"",
      resetKey:"",
      loader:false
    }
    this.validator = new SimpleReactValidator();
  }

  handleOnChange = (event) => {
    if (event.target.name === "password") {
      this.setState({ password: event.target.value });
    } else if (event.target.name === "confirmPassword") {
      this.setState({ confirmPassword: event.target.value });
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {resetKey: props.match.params.resetKey };
  }

  handleResetState = () =>{

    this.setState({
      password:"",
      confirmPassword:"",
      resetKey:""
    });
  }

  componentDidMount = () => {
    const {match} = this.props;
    if(match.params && match.params.resetKey !== ""){
      this.validateToken(match.params.resetKey)
    }
  }

  validateToken = async (resetKey) =>{
    let result = true;
    const headers = {
      "Content-Type":"application/json",
      "Accept":"application/json"
    }
    const URL =   getApiUrl();
    const url = `${URL}/verify-token`;
    const data = {resetKey};
    await axios.post(url, data, {
        headers: headers
      })                                                                            
      .then((response) => {
        if(response.status !== 200){
            result = false;
        }
      })
      .catch((error) => {
        if(error.response.status === 422){
          createNotification("error", "Token Expired!");
          result = false;
        }
      })
      return result;
  }

  handleOnSetPassword = () => {
  
    if(this.validator.allValid()){ 
      if(this.checkInputData()){
      const headers = {
        "Content-Type":"application/json",
        "Accept":"application/json"
      }
      this.setState({loader:true});
      const URL =   getApiUrl();
      const url = `${URL}/reset-password`;

      const { password, confirmPassword, resetKey} = this.state;

      const data = {password, confirmPassword, resetKey};
      axios.put(url, data, {
          headers: headers
        })                                                                            
        .then((response) => {
          if(response.status === 200){
              createNotification("success", "You have successfully changed your password");
              this.handleResetState();
              this.setState({loader:false});
              setTimeout(()=>{this.props.history.push('/login')},1500)
              
          }
          console.log({response});
        })
        .catch((error) => {
            createNotification("error", "Invalid Token!");
            this.setState({loader:false});
            console.log({resetPasswordError:error});
        })
      }
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  checkInputData = () =>{
    const {password,confirmPassword} = this.state;
      if(confirmPassword === "" || password === ""){
          
          if(confirmPassword === "")
          createNotification("error", "Confirm password is Required!");

          if(password === "")
          createNotification("error", "Password is Required!");
          
          return false;
      }else if(confirmPassword !== password){
        createNotification("info", "Confirm Password and Password do not match!");
        return false;
      }
     else if(!checkValidPasswpord(confirmPassword)){
        createNotification("info", "Passwords must be 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character!");
        return false;
     }
     

    return true;
  }

  render() {
    const { password, confirmPassword,loader} = this.state;
    return (
      <div>
        {loader ? <Loader /> :
        
        <>
       
        <main className="user-account-page">
          <div className="container-fluid">
            <section className="form-container">
              <div className="row">
                <div className="col-md-6 logo-col">
                  <div className="logo-content-frame">
                    <h1 className="logo-frame">
                      <span className="logo"></span>
                    </h1>
                    <h2 className="title">Welcome, User</h2>
                    <label className="lbl">Reset Password</label>
                  </div>
                </div>
                <div className="col-md-6 form-col">
                  <div className="content-frame">
                    <h2 className="title">Reset Password</h2>

                    <section className="form-frame login-form">
                      <div className="form-group mb-3">
                        <input type="password" name="password" value={password} onChange={this.handleOnChange} placeholder="New Password" className="form-control" />
                        {this.validator.message('password', password, 'required', { className: 'text-danger' })}
                      </div>
                      <div className="form-group mb-3">
                        <input type="password" name="confirmPassword" value={confirmPassword} onChange={this.handleOnChange} placeholder="Confirm Password" className="form-control" />
                        {this.validator.message('Confirm Password', confirmPassword, 'required', { className: 'text-danger' })}
                      </div>

                      <div className="btn-row">
                        <input type="button" name="signIn" value="Reset Password" className="btn-rnd-black btn-signIn" onClick={() => this.handleOnSetPassword()} />
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        <Footer />
        </>
        }
      </div>
    )
  }
}
