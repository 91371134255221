import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function AlertBox(props) {
  const { handleConfirmModal, openModal, alertMsg, handleClose } = props;

  return (
    <div>
      <Modal show={openModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>{alertMsg}</Modal.Body>
        <Modal.Footer>
           <Button variant="danger" onClick={() => handleClose()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
