
import React, { useRef, useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import FreightDeliveryPopUp from './FreightDeliveryPopUp';
import { setFreightDelFormData } from '../../project/redux/projectAction';

import moment from "moment";
import projectServices from "../../project/services/projectServices";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const Example = (props) => {
  const componentRef = useRef();

  const [projectFreightDeliveryId, setProjectFreightDeliveryId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [freightDelItemsId, setFreightDelItemsId] = useState(false);
  const [scrollStyle, setScrollStyle] = useState(false);
  const [callbackData, setCallbackData] = useState(false);
  const [scrollView, setScrollView] = useState(false);
  const[projName,setProjectName] = useState("");
  const[JobNumber,setJobNumber] =useState();

  const handlePrint = useReactToPrint({

    // onload : () => { console.log("loading");},
    // onBeforePrint:()=>{console.log("before"); },
    // onAfterprint:()=>{ console.log("after"); },
    // onBeforeGetContent : ()=> { setScrollStyle(true);console.log("beofe get content");},
    // onPrintError: () => {console.log("eerrorr"); },
    content: () => componentRef.current,
  });
  // console.log("props");
  //   setScrollStyle(true);
  useEffect(() => {
    getFreightDelivery(props.projectId, props.selectedScope);
  }, []);

  const getFreightDelivery = (projectId, selectedScope) => {
    // console.log('inside getFreightDelivery', projectId,selectedScope);
    projectServices
      .getFreightDelivery(projectId, selectedScope)
      .then((response) => {
        if (response.data.length > 0) {
          let res = response.data[0];
          const signatureImage =
            res.signature !== null
              ? Buffer.from(res.signature.data, "base64")
              : "";
          let canvas1 = document.getElementsByClassName("signatureCanvas");
          let ctx = canvas1[0].getContext("2d");
          let img1 = new Image();
          img1.onload = function () {
            ctx.drawImage(img1, 40, 10);
          };
          img1.src = signatureImage;
          let freightData = {
            freightDate:
              res.freightDate !== null
                ? new Date(moment(res.freightDate).format("MM/DD/YYYY"))
                : "",
            projectName: res.project_fkey.title,
            scope: res.scope,
            shipFrom: res.shipFrom,
            shipTo: res.shipTo,
            comments: res.comments,
            receiver: res.receiver,
            deliveredBy: res.deliveredBy,
            onsiteContact: res.onsiteContact,
            shipperDate:
              res.shipperDate !== null
                ? new Date(moment(res.shipperDate).format("MM/DD/YYYY"))
                : "",
            receiverDate:
              res.receiverDate !== null
                ? new Date(moment(res.receiverDate).format("MM/DD/YYYY"))
                : "",
            signature: signatureImage,
          };

          setIsEdit(true);
          setProjectFreightDeliveryId(res.id);
          setProjectName(res.project_fkey.title);
          setJobNumber(res.project_fkey.jobNumber)
          // console.log('updated res.id',res.id);
          //   console.log('updated props.setFreightDelFormData',props.setFreightDelFormData);
          //console.log(props.freightDelFormData);
          setFreightDelFormData({ ...freightData });

          setFreightDelItemsId(res.id);
        } else {
          // cross check
          //   props.setFreightDelFormData({ ...state.initialState });
          setIsEdit(false);
          setProjectFreightDeliveryId(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const { freightDate, projectName, scope, shipFrom, shipTo, comments, shipperDate, receiverDate, signature,
    receiver, deliveredBy, onsiteContact
  } = props.freightDelFormData;

  //  console.log("top props",props);
  //    console.log("final data 11111",props.freightDelFormData);

  const handleCallback = (childData) => {
    // Update the name in the component's state
    setCallbackData(childData)
    //console.log("childData", childData);
  }
  const handeCloseScrollView = () => {
    // props.parentCallback(false);
    setScrollStyle(true)
    setScrollView(true);
  }
  const handeOpenScrollView = () => {
    // props.parentCallback(true);
    setScrollView(false);
    setScrollStyle(false);
  }
  const closeModal = props.closeModal;
  return (
    <div>
      {/* <ComponentToPrint ref={componentRef} 
      closeModal={props.closeModal}
        saveFreightDelivery={props.saveFreightDelivery}
        isDisable={props.isDisable}
        projectId={props.projectId}
        selectedScope={props.selectedScope}
        />                   */}
      <div
        className="project-button-box"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 20px 0",
        }}
      >
        <div className="freight-title">Bill of landing Stehl Corp : <span className='text-primary'>{projName}-{JobNumber}</span></div>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => closeModal()}
        ></button>
      </div>
      <hr></hr>
      < div className="ScrollButtons" style={{ marginLeft: "250px", marginTop: "10px" }}>
        <button
          style={{ border: scrollView ? "2px solid black" : "" }}
          className="nav justify-content-end btn btn-primary waves-effect waves-light btn-add-item "
          type="button "
          onClick={handeCloseScrollView}>Enable Print mode</button>
        <button
          style={{ border: scrollView ? "" : "2px solid black" }}
          className="nav justify-content-end btn btn-primary waves-effect waves-light btn-add-item "
          type="button "
          onClick={handeOpenScrollView}> Disable Print mode</button>
      </div>
      {/* <hr></hr>   */}
      <button
        disabled={!scrollStyle}
        style={{ marginLeft: "350px" }}
        className="nav justify-content-end btn
       btn-primary waves-effect waves-light btn-add-item mb-2 mt-2"
        type="button "
        onClick={() => { setScrollStyle(true); handlePrint() }} >Print this out!</button>
      <FreightDeliveryPopUp
        parentCallback={handleCallback}
        ref={componentRef}
        closeModal={props.closeModal}
        saveFreightDelivery={props.saveFreightDelivery}
        isDisable={props.isDisable}
        projectId={props.projectId}
        selectedScope={props.selectedScope}
        loginUserData={props.loginUserData}
        isEdit={isEdit}
        scopeName={props.scopeName}
        // projectName={projName}
        // jobNumber={JobNumber}
        // projectFreightDeliveryId ={projectFreightDeliveryId}
        freightDelItemsId={freightDelItemsId}
        scrollStyle={scrollStyle}
      />


    </div>
  );
};

const mapStateToProps = (props) => {
  return {
    freightDelFormData: props.freightDelFormData,
    loginUserData: props.loginUserData,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setFreightDelFormData: setFreightDelFormData,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Example);