import React ,{Component} from "react";
import { Link } from "react-router-dom";
import { createNotification } from "../helpers/Notification";
import BootstrapTable from "react-bootstrap-table-next";
import AccessDenied from "../common/AccessDenied";
import SimpleReactValidator from "simple-react-validator";
import MasterDataService from "../services/MasterDataService";
import { DropDown } from "../common";
import { AllowedTo } from "react-abac";
import ConfirmBox from "../common/ConfirmBox";
import { rules } from "../../abac.config";
import { Permission } from "../../abac.config";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class AddShopMaintenance extends Component{
    constructor(props){
        super(props);
        this.state={
            name:"",
            status: "Active",
            loader: false,
            ShopMaintenanceItemId: null,
            ShopMaintenanceItemData:[],
            headingText:"Add Shop Maintenance",
            button: "Save",
            isShowConfirmBox:false,
             
        };
     this.validator=  new SimpleReactValidator ();
    }
    componentDidMount=()=>{
        const{match}=this.props;
        console.log(match.params);
        if(match.path === "/shopMaintenance/:ShopMaintenanceId" && match.params.ShopMaintenanceItemId !==null){
          this.setState({
            ShopMaintenanceItemId:match.params.ShopMaintenanceId,
            headingText:"Edit",
            button:"Update",
          });
          this.getShopMaintenaceById(match.params.ShopMaintenanceId);
        }
        
    };

    
     onStatusChange=(e)=>{
         const{match}=this.props;
         if(this.state.status ==="Active" && match.path !=="/AddShopMaintenance"){
            this.setState({ isShowConfirmBox: true })
         }else{
            this.setState({ status: e.target.value });
          } 
     }
 
   getShopMaintenaceById=(ShopMaintenanceItemId)=>{
      if(ShopMaintenanceItemId !==""){
        MasterDataService.getShopMaintenanceItemById(ShopMaintenanceItemId)
        .then((response)=>{
          if(response.status){
         let resData  = response.data.data;
           this.setState({
             name:resData.name,
             status:resData.status == "ACTIVE"?"Active":"Inactive",
           });
          }
          console.log({ response: response });
        })
        .catch((error)=>{
          if(error.response.status === 401){
           createNotification("error", "Your login session has been expired!");
           localStorage.setItem("user-token","");
           this.props.history.push("/login");
          }else{
            createNotification("error", error.response.data.error.message);
          }
          console.log({ resetPasswordError: error });
        })        
      }
   }
    handleOnChange =(event)=>{
      //this.setState({[event.target.name]:event.target.value})
      if(event.target.name === "name"){
        this.setState({ name: event.target.value });
    }
    else if (event.target.name === "status") {
       this.setState({ status: event.target.value });
     }
   }
     
   handleSaveShopMaintenance=()=>{
     const{name,status,ShopMaintenanceItemId}=this.state;
     const{match}=this.props;
     
    
     if(this.validator.allValid())
     {   
      this.setState({loader:true})
      let nm=name.trim();
       const data={name:nm,status};
       console.log({payloaddata:data})
       if(match.path === "/shopMaintenance/:ShopMaintenanceId" && ShopMaintenanceItemId !==null)
       {
         MasterDataService.updateShopMaintenanceItemById(ShopMaintenanceItemId,data)
         .then((response)=>{
           console.log({response});
           if(response.status === 200){
            createNotification("success", "Shop maintenance item updated successfully!");
            this.handleResetState();
            this.setState({loader:false});
            this.props.history.push("/ShopMaintenance");
           }          
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            createNotification(
              "error",
              "Your login session has been expired!"
            );
            localStorage.setItem("user-token", "");
            this.props.history.push("/login");
            this.setState({ loader: false });
          } else {
            createNotification("error", error.response.data.error.message);
            this.setState({ loader: false });
          }
          console.log({ resetPasswordError: error });
        });
       } else {
        
         MasterDataService.addShopMaintenanceItem(data)
         .then((response) =>{
           
          if(response.status === 200){
             createNotification("success","Shop maintenance item added successfully");
              this.handleResetState();
              this.setState({loader:false});
              this.props.history.push("/ShopMaintenance");
           }
            
         })
         .catch((error)=>{
          if (error.response.status === 401) {
            createNotification(
              "error",
              "Your login session has been expired!"
            );
            localStorage.clear();
            this.props.history.push("/login");
            this.setState({ loader: false });
          } else {
            createNotification("error", error.response.data.error.message);
            this.setState({ loader: false });
          }
          console.log({ resetPasswordError: error });
         });
            
       }
     }else{
       this.validator.showMessages();
       this.forceUpdate();
     }
   }


   handleResetState = () => {
    this.setState({
      name: "",
      status: "Active",
      ShopMaintenanceItemId: null,
      headingText: "Add",
      button: "Save",
    });
  };
   handleConfirmModal = (isYes) => {
    if(isYes){
      this.setState({ status: "Inactive",isShowConfirmBox: !this.state.isShowConfirmBox  });
    }else{
      this.setState({ status: "Active",isShowConfirmBox: !this.state.isShowConfirmBox  });
    }
  }


    render(){
          const{loader,isShowConfirmBox,ShopMaintenanceItemData}=this.state;
          let roleKey=this.props.loginUserData.roleKey;
          const isAccess = (roleKey!=="" && rules[roleKey][Permission.MASTER_WRITE]) ?true:false;
          return(
            <div>
              {!isAccess ?<AccessDenied/>:(
                <div className="main-content addUser-form">
                  <div className="page-content">
                    <div className="container-fluid">
                     <div className="page-title-box d-flex align-items-center justify-content-between">
                        <ol className="breadcrumb m-0">
                          <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                          &nbsp; / &nbsp;
                          <Link to={`/ShopMaintenance`}><li className="breadcrumb-item">Shop Maintenance Description</li></Link>
                          &nbsp; / &nbsp;
                          {this.props.match.path == `/AddShopMaintenance` ?
                            <Link to={`/AddShopMaintenance`}><li className="breadcrumb-item">Add</li></Link>
                          :this.props.match.path == `/shopMaintenance/:ShopMaintenanceId` ?
                            <Link to={`/shopMaintenance/${this.props.match.params.ShopMaintenanceId}`}><li className="breadcrumb-item">Edit</li></Link>
                          :''}
                        </ol>
                     </div>
                     <h4 className="mb-0">{this.state.headingText}</h4>
                     <div className="row">
                       <div className="col-lg-12">
                         <div className="card card-block">
                            <div className="card-body">
                              <div className="wizard clearfix">
                               <div className="content-clearfix">
                                 <form className="form-project">
                                 <div className="row">
                                   <div className="col-md-5 col-sm-12">
                                    <div className="mb-3">
                                    <label
                                      className="form-label"
                                      for="validationCustom01"
                                    >
                                      Name{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input type="text" 
                                    className="form-control"
                                    id="validationCustom01"
                                    name="name"
                                    onChange={(event)=>{this.handleOnChange(event)}}
                                    placeholder="Name"
                                    value={this.state.name}
                                   />
                                   {
                                      this.validator.message(
                                        "name",
                                        this.state.name,
                                        "required|max:70",
                                        {className:"text-danger"}
                                      ) 
                                   }
                                    </div>
                                   </div>
                                
                                 </div>
                                  <div className="row">
                                  <div className="col-md-5 col-sm-12">
                                      <div className="mb-3">
                                        <label className="form-label"
                                        for="validationCustom01">
                                            Status
                                        </label>
                                        <DropDown name="status"
                                          options={[
                                              {lable:"Active",value:"Active"},
                                              {lable:"Inactive",value:"Inactive"},
                                          ]}
                                          onChange={(e)=> this.onStatusChange(e)}
                                          defaultSelected={this.state.status}
                                          lable=""/>
                                      </div>
                                    </div>
                                  </div>
                            </form>
                            </div>
                               <div className="actions clearfix">
                                  <button type="button"
                                  className="btn btn-primary waves-effect waves-light"
                                  onClick={()=>{this.handleSaveShopMaintenance()}}>
                                    <i className="uil uil-edit"></i>{" "}
                                    {this.state.button}
                                  </button>
                               </div>
                               <ConfirmBox
                            openModal={isShowConfirmBox}
                            confirmMsg="This entity might being used in one or more projects. Inactivating will remove it from there. Do you still want to continue?"
                            handleConfirmModal={this.handleConfirmModal} />
                              </div>
                            </div>
                         </div>
                       </div>
                     </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
        )
    }
}
const mapStateToProps = (props) => {
  return {
      loginUserData: props.loginUserData,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddShopMaintenance);