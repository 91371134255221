import React,{useState} from 'react'
import Modal from 'react-modal';

export default function ModalPopUp(props) {

    const {componentData: ComponentData,openModal,closeModal} = props;
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(true);


    function afterOpenModal() {
        // references are now sync'd and can be accessed.
       // subtitle.style.color = '#f00';
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth:'850px',
            width:'100%'
        },
    };
    
    return (
        <div>
            {/* <button onClick={openModal}>Open Modal</button> */}
            <Modal
                isOpen={openModal}
                onAfterOpen={afterOpenModal}
               // onRequestClose={closeModal}
                ariaHideApp={false}
                style={customStyles}
                contentLabel="Example Modal"
            >
               
                {ComponentData}
               
            </Modal>
        </div>
    )
}
