import React from 'react'

export default function Footer() {
    return (
        <div>
            <footer>
                <div className="footer-content-frame">
                    <label className="lbl-copy">&copy; copyright <a>STEHL.corp</a>, 2021</label>
                    <nav className="foot-list">
                        {/* <a href="privacy_policy.html">Privacy Policy</a> 
                        <a href="terms_condition.html">Terms & conditions</a> */}
                    </nav>
                </div>
            </footer>
        </div>
    )
}
