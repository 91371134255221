import React, { Component } from 'react'
import DatePicker from "react-datepicker";
import projectServices from '../../project/services/projectServices';
import { createNotification } from '../../helpers/Notification';
import { setShopFabFormData } from '../../project/redux/projectAction';
import { setFieldInstallFormData } from '../../project/redux/projectAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { rules, Permission } from "../../../abac.config";
import moment from 'moment';
import ModalPopUp from '../../common/ModalPopUp';
import { setFcCrewError } from '../../project/redux/projectAction';
import { AllowedTo } from "react-abac";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaUndoAlt, FaRegWindowClose } from 'react-icons/fa';
import FilesPopUp from '../../project/component/FilesPopUp';
import { DropDown } from '../../common';


class DefaultFieldCrewScopeItemData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: "",
      title: "Add Item",
      projectList: [],
      fieldCrewList: [],
      fieldCrewData: [],
      selectedShopName: '',
      selectedTableName: '',
      showFilePopUp: false,
      drawingTypeData: [],
      drawingData: [],
      filesToView: [],
      previousCompletionDate: "",
      isPreviousCompletionDateSelected: false,
      projectId: 0,
      defaultScopeItemId: 0,
      selectedLabel: '',
      initialState: {
        startDate: "",
        scheduleCompletionDate: "",
        completionDate: "",
        dailyGoals: "",
        dailyActuals: "",
        description: "",
        comments: "",
        fieldCrew: []
      },
    };
  }
  componentDidMount = () => {
    const { projectId, selectedScope, setFcCrewError, fcCrewErrs } = this.props;
    setFcCrewError({
      ...fcCrewErrs, fieldCrewErrors: "",
      projectNameError: "",
      scheduleCompletionDateError: "",
      startDateError: ""
    })
    this.getCalendarProject();
    //   this.getDrawingType();
    //   this.getDrawingDoc();
    this.getFieldCrew();
    //   this.getFieldInstall(projectId, selectedScope);

  }
  getCalendarProject = () => {
    projectServices.getCalenderFieldCrew(0).then((response) => {
      console.log(response.data.data, ">>>>>>");
      this.setState({ fieldCrewData: response.data.data }, () => {
        this.generateProjectList();
      })

    })
      .catch((error) => {
        console.log({ error });
        if (error.response && error.response.status === 401) {
          createNotification("error", "Your login session has been expired!");
          localStorage.setItem("user-token", "");
          this.props.history.push("/login");
        } else {
          createNotification("error", error.response.data.error.message);
        }
        console.log(error);
      });
  }

  generateProjectList = () => {
    const { projects } = this.state.fieldCrewData;
    let onlyProjects = [];
    if (Object.keys(projects).length > 0) {
      for (const [k, val] of Object.entries(projects)) {


        if (onlyProjects.find((item) => item.value === val.projectId)) {

        } else {
          let data = `Job # ${val.project_fkey.jobNumber} - ${val.project_fkey.title}`;
          onlyProjects.push({ lable: data, value: val.projectId, jobNumber: val.project_fkey.jobNumber })
        }
      }
      this.handleProjectDropDown(onlyProjects)

    }
  }
  handleProjectDropDown = (onlyProjects) => {
    console.log(onlyProjects);
    let result = onlyProjects.sort((a, b) => {
      if (a.jobNumber && b.jobNumber) {
        return a.jobNumber > b.jobNumber ? 1 : -1
      } else {
        return -1
      }
      // console.log(onlyProjects);
    });
   
    this.setState({ projectList: [...result] })
  }
  getFieldCrew = () => {
    projectServices.getFieldCrew().then((res) => {
      this.setState({ fieldCrewList: res.data.data });
    }).catch((error) => {
      console.log(error);
    })
  }
  handleOnChange = (event) => {
    const { setFieldInstallFormData, fieldInstallFormData, setFcCrewError, fcCrewErrs } = this.props;
    let fieldInstallFormDataClone = { ...fieldInstallFormData };
    if (event.target.name === "ProjectId") {
      setFcCrewError({ ...fcCrewErrs, projectNameError: "" });
      this.setState({ projectId: Number(event.target.value) }, () => {
        this.getProjectDefaultScope();
        this.setLabel(Number(event.target.value));
      })
    }
    else if (event.target.name === "dailyGoals") {
      setFieldInstallFormData({ ...fieldInstallFormDataClone, dailyGoals: event.target.value });
    } else if (event.target.name === "dailyActuals") {
      setFieldInstallFormData({ ...fieldInstallFormDataClone, dailyActuals: event.target.value });
    } else if (event.target.name === "description") {
      setFieldInstallFormData({ ...fieldInstallFormDataClone, description: event.target.value, });
    } else if (event.target.name === "comments") {
      setFieldInstallFormData({ ...fieldInstallFormDataClone, comments: event.target.value });
    } else if (event.target.name === "fieldCrew") {
      setFcCrewError({ ...fcCrewErrs, fieldCrewErrors: "" })
      let tableValues = Array.from(event.target.selectedOptions, option => option.value);
      setFieldInstallFormData({ ...fieldInstallFormDataClone, fieldCrew: tableValues });

    } else if (event.target.name === "completionDate") {
      setFieldInstallFormData({ ...fieldInstallFormDataClone, completionDate: event.target.value });
    }
  }

  getProjectDefaultScope = () => {
    let value = "DEFAULT";
    let filters = `?filter[where][type]=${value}&filter[where][projectId]=${this.state.projectId}`
    projectServices.getFreightDeliveryDefaultScope(filters, this.state.projectId).then((respone) => {
      console.log(respone);
      this.setState({ defaultScopeItemId: respone.data[0].id });
    })
      .catch((error) => {
        console.log(error);
      })
  }

  setLabel = (selectedValue) => {

    const selectedItem = this.state.projectList.find(item => item.value === selectedValue);

    if (selectedItem) {
      this.setState({ selectedLabel: selectedItem.lable });
    }
  }

  handleDateChange = (date, type) => {

    let formatedDate = date
    const { setFieldInstallFormData, fieldInstallFormData, setFcCrewError, fcCrewErrs } = this.props;
    let fieldInstallFormDataClone = { ...fieldInstallFormData };
    console.log(fieldInstallFormData);
    if (type === "startDate") {
      setFcCrewError({ ...fcCrewErrs, startDateError: "" });
      if (fieldInstallFormData.scheduleCompletionDate !== "" || fieldInstallFormData.completionDate !== "") {
        if (Date.parse(date) < Date.parse(fieldInstallFormData.scheduleCompletionDate)
          // && Date.parse(date) < Date.parse(fieldInstallFormData.completionDate)
        ) {
          setFieldInstallFormData({ ...fieldInstallFormDataClone, startDate: formatedDate });
        } else {
          createNotification(
            "error",
            "Start date should be less than Scheduled completion date ."
          );
        }
      } else {
        setFieldInstallFormData({ ...fieldInstallFormDataClone, startDate: formatedDate });
      }

    } else if (type === "scheduleCompletionDate") {
      setFcCrewError({ ...fcCrewErrs, scheduleCompletionDateError: "" });
      if (fieldInstallFormData.startDate !== "") {
        if (Date.parse(date) >= Date.parse(fieldInstallFormData.startDate)) {
          setFieldInstallFormData({ ...fieldInstallFormDataClone, scheduleCompletionDate: formatedDate });
        } else {
          createNotification(
            "error",
            "Scheduled completion date should be greater than Start date"
          );
        }
      } else {
        setFieldInstallFormData({ ...fieldInstallFormDataClone, scheduleCompletionDate: formatedDate });
      }
    } else if (type === "completionDate") {
      let prevComplDate;
      if (fieldInstallFormData.startDate !== "") {
        if (Date.parse(date) >= Date.parse(fieldInstallFormData.startDate)) {
          prevComplDate = fieldInstallFormData.completionDate;
          this.setState({ previousCompletionDate: prevComplDate, isPreviousCompletionDateSelected: true })
          setFieldInstallFormData({ ...fieldInstallFormDataClone, completionDate: formatedDate });
        } else {
          createNotification(
            "error",
            "Completion date should be greater than Start date"
          );
        }
      } else {
        prevComplDate = fieldInstallFormData.completionDate;
        this.setState({ previousCompletionDate: prevComplDate, isPreviousCompletionDateSelected: true })
        setFieldInstallFormData({ ...fieldInstallFormDataClone, completionDate: formatedDate });
      }
    }
  }
  getDrawingType = () => {
    projectServices
      .getDrawingType()
      .then((res) => {
        if (res.status) {
          this.setState({ drawingTypeData: res.data.data });
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error);
      });
  };
  handleShowFilePopUp = () => {
    const { showFilePopUp } = this.state;
    this.setState({ showFilePopUp: !showFilePopUp })
  }
  getDrawingDoc = () => {
    const { projectId, selectedScope } = this.props;
    projectServices
      .getDrawingDoc(projectId, selectedScope)
      .then((res) => {
        if (res.status) {
          this.setState({ drawingData: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleShowFilePopUp = () => {
    const { showFilePopUp } = this.state;
    this.setState({ showFilePopUp: !showFilePopUp })
  }
  viewFiles = (typeId) => {
    const { drawingData } = this.state;
    const data = drawingData.filter(
      (item) => Number(item.drawingType) === Number(typeId)
    );

    if (Object.keys(data).length > 0) {
      if (data[0].hasOwnProperty('detailDrawingDocs')) {
        data[0].detailDrawingDocs.sort((a, b) => {
          if (a.upload_fkey.fileName && b.upload_fkey.fileName) {
            return a.upload_fkey.fileName > b.upload_fkey.fileName ? 1 : -1
          } else {
            return 1;
          }
        })
      } else {
        data[0].detailDrawingDocs = [];
      }

    }

    this.setState({ showFilePopUp: true, filesToView: data })
  }

  undoCompletionDate = () => {
    const { setFieldInstallFormData, fieldInstallFormData } = this.props;
    const { previousCompletionDate, isPreviousCompletionDateSelected } = this.state;
    let fieldInstallFormDataClone = { ...fieldInstallFormData };

    if (document.getElementById("undoCompletionDate")) {
      if (isPreviousCompletionDateSelected) {
        setFieldInstallFormData({ ...fieldInstallFormDataClone, completionDate: previousCompletionDate })
      }
    }
  }
  //clear the completion date-----------//
  clearCompletionDate = () => {
    const { setFieldInstallFormData, fieldInstallFormData } = this.props;
    const { previousCompletionDate, isPreviousCompletionDateSelected } = this.state;
    let fieldInstallFormDataClone = { ...fieldInstallFormData };
    if (document.getElementById("clearCompletionDate")) {
      this.setState({ isPreviousCompletionDateSelected: true, previousCompletionDate: fieldInstallFormDataClone.completionDate })
      setFieldInstallFormData({ ...fieldInstallFormDataClone, completionDate: null })
    }
  }
  handleSaveItem = () => {
    const { projectId, defaultScopeItemId, selectedLabel } = this.state;

    this.props.handleSaveDefaultScopeFieldCrew(projectId, defaultScopeItemId, selectedLabel)
    // this.props.closeModal();


  }

  render() {
    const { description, comments, dailyGoals, dailyActuals, releaseDate, scheduled, dateCompleted, fieldCrew, startDate, scheduleCompletionDate, completionDate } = this.props.fieldInstallFormData;
    const { list, fieldCrewList, storedList, selectedShopName, selectedTableName, title, drawingTypeData } = this.state;
    let roleKey = this.props.loginUserData.roleKey;
    const isDisable = (roleKey !== "" && rules[roleKey][Permission.FIELDCREWDEFAULTSCOPE_WRITE]) ? "" : "disabled";
    const { closeModal, projectName, scopeName } = this.props;
    return (
      <div>

        <div
          className="project-button-box"
          style={{ display: "flex", justifyContent: "space-between", padding: "20px 20px 0", fontSize: "16px" }}
        >


          <div className="freight-title">{title}</div>

          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => closeModal()}
          ></button>
        </div>
        <div className="shop-fabrication-block">
          <div className="row">
            <div className="col-lg-12">
              <div className="project-filter-dropdown mb-3  align-items-center">
                <label className="col-md-4">Project List</label>
                <DropDown
                  name="ProjectId"
                  firstOption={{ lable: "All Projects", value: 0 }}
                  options={this.state.projectList}
                  onChange={(e) => this.handleOnChange(e)}
                  defaultSelected={this.state.projectId}
                  lable=""
                  value={this.state.projectId}
                />
                {this.props.fcCrewErrs && this.props.fcCrewErrs.projectNameError ? <p className='text-danger'>{this.props.fcCrewErrs.projectNameError}</p> : <></>}
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label">Scope</label>
                <input
                  placeholder="Type here"
                  className="form-control"
                  type="text"
                  name="scopeName"
                  value={scopeName}
                  disabled
                />
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label">Crew</label>
                <select
                  className="form-select"
                  name="fieldCrew"
                  multiple
                  value={fieldCrew}
                  onChange={(e) => this.handleOnChange(e)}
                  disabled={isDisable}
                >
                  {/* <option value="0">Select</option> */}
                  {fieldCrewList.length > 0 &&
                    fieldCrewList.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                </select>
                {this.props.fcCrewErrs && this.props.fcCrewErrs.fieldCrewErrors ? <p className='text-danger'>{this.props.fcCrewErrs.fieldCrewErrors}</p> : <></>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="mb-3">
                <label className="form-label">Start Date</label>
                <DatePicker className="form-control date-form-control" onChange={(date) => this.handleDateChange(date, "startDate")}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  selected={startDate}
                  disabled={isDisable}
                />
                {this.props.fcCrewErrs && this.props.fcCrewErrs.startDateError ? <p className='text-danger'>{this.props.fcCrewErrs.startDateError}</p> : <></>}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label className="form-label">Scheduled</label>
                <DatePicker className="form-control date-form-control" onChange={(date) => this.handleDateChange(date, "scheduleCompletionDate")}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="MM/DD/YYYY"
                  selected={scheduleCompletionDate}
                  disabled={isDisable}
                />
                {this.props.fcCrewErrs && this.props.fcCrewErrs.scheduleCompletionDateError ? <p className='text-danger'>{this.props.fcCrewErrs.scheduleCompletionDateError}</p> : <></>}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="mb-3">
                <label className="form-label">Completion Date</label>
                <div className="row align-items-center">
                  <div className="col-lg-9">
                    <DatePicker className="form-control date-form-control"
                      onChange={(date) => this.handleDateChange(date, "completionDate")}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="MM/DD/YYYY"
                      selected={completionDate}
                      disabled={isDisable}
                    />
                  </div>
                  <div className="col-lg-3">
                    <AllowedTo perform={Permission.PROJECT_WRITE}>
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            Undo Completion Date
                          </Tooltip>
                        }
                      >

                        <a href="javascript:void(0)" className="text-danger">
                          <FaUndoAlt id='undoCompletionDate' onClick={this.undoCompletionDate}></FaUndoAlt>
                        </a>

                      </OverlayTrigger>
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            Clear Completion Date
                          </Tooltip>
                        }
                      >
                        <button type="button" className='border-0 bg-transparent'>
                          <FaRegWindowClose id='clearCompletionDate' onClick={this.clearCompletionDate}></FaRegWindowClose>
                        </button>
                      </OverlayTrigger>
                    </AllowedTo>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Daily Goals</label>
                {/* >Daily Goals */}
                <textarea
                  placeholder="Type here"
                  required=""
                  className="form-control"
                  rows="3"
                  onChange={(e) => this.handleOnChange(e)}
                  name="dailyGoals"
                  value={dailyGoals}
                  maxLength="700"
                  disabled={isDisable}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Daily Actuals</label>
                <textarea
                  placeholder="Type here"
                  required=""
                  name="dailyActuals"
                  className="form-control"
                  rows="3"
                  onChange={(e) => this.handleOnChange(e)}
                  value={dailyActuals}
                  maxLength="700"
                  disabled={isDisable}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                  placeholder="Type here"
                  required=""
                  name="description"
                  className="form-control"
                  rows="3"
                  onChange={(e) => this.handleOnChange(e)}
                  value={description}
                  maxLength="700"
                  disabled={isDisable}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">Comments</label>
                <textarea
                  placeholder="Type here"
                  required=""
                  name="comments"
                  className="form-control"
                  rows="3"
                  onChange={(e) => this.handleOnChange(e)}
                  value={comments}
                  maxLength="700"
                  disabled={isDisable}
                />
              </div>
            </div>
          </div>
          <ModalPopUp
            componentData={
              <FilesPopUp
                closeModal={this.handleShowFilePopUp}
                fileData={this.state.filesToView}
              />
            }
            openModal={this.state.showFilePopUp}
          />
          <fieldset disabled={isDisable}>
            <div className="actions clearfix saveBtn">
              <a
                href="javascript:void(0)"
                role="menuitem"
                className="btn btn-primary waves-effect waves-light"
                onClick={this.handleSaveItem}
              >
                Save
              </a>
            </div>
          </fieldset>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (props) => {

  return {
    fieldInstallFormData: props.fieldInstallFormData,
    loginUserData: props.loginUserData,
    fcCrewErrs: props.fcCrewErrs
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setFcCrewError: setFcCrewError,
  setFieldInstallFormData: setFieldInstallFormData,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DefaultFieldCrewScopeItemData);