import React, { Component } from 'react';

// Notification Message-------------------------------------
import { createNotification } from "../../../../helpers/Notification";

// All API Calls handled here-------------------------------
import projectServices from "../../../services/projectServices";

// Loader for component grid before data rendering----------
import Loader from "../../../../common/Loader";

// date format----------------------------------------------
import { dateFormat, dbDateFormat } from "../../../../helpers/Util";
import DatePicker from "react-datepicker";
import moment from "moment";

// Role based access permission-----------------------------
import { rules, Permission } from "../../../../../abac.config";

// Redux elements and functions-----------------------------
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

class FieldMasterDataMaintenance extends Component {
    constructor(props){
        super(props);
        let details=this.props.location;
        this.state = {
            fieldName : details.search.replace('?','').replace('%20',' '),
            fieldID:(details.state ? details.state.maintenanceFieldTypeId : '' ),
            
            itemID:(details.state ? details.state.id : ''),
            itemName:(details.state ? details.state.name : '' ), 
            
            status:(details.state ? details.state.status : ''),
            
            lastService:(details.state ? details.state.lastDate : ''),
            
            nextService:(details.state ? details.state.nextDate : ''),
            nextServiceError:'',
            nextServiceStatus:false,
            
            comments:(details.state && (details.state.status !== 'Pending' && details.state.status !== 'Overdue' && details.state.status !== 'OVERDUE') ? details.state.comments : ''),
            commentsError:'',
            commentsStatus:false,

            serviceBy:(details.state && (details.state.status !== 'Pending' && details.state.status !== 'Overdue' && details.state.status !== 'OVERDUE') ? details.state.serviceBy : ''),
            serviceByError:'',
            serviceByStatus:false,

            FieldMaintenanceMasterData:[],
            loader:false,
            actionButtonName:'',
            isAccess:false,
            statusFlag:false,
            btnClicked:false,
            fieldMaintenanceDisplayListArr:[],
            
            dynamicFields:[],
            dynamicFieldsErrorAll:'',
            dynamicFieldsStatusAll:false,
            dynamicFieldsError:'',
            dynamicFieldsStatus:false,
            targetDynamicField:(details.state ? details.state.maintenanceFieldMasterFields : []),
            
            MaintenanceDescriptionDetails:[],

            checked_n_good:[],
            needs_future_attention:[],
            changed_required:[],
        }
    }

    componentDidMount = () => {
        this.getFieldMaintenanceMasterData();
        this.getDynamicFieldsByFieldId();
        if(this.state.status === 'In Progress' || this.state.status === 'Done'){
            this.getFieldMaintenanceItemAndDescriptionDetails(this.state.itemID);
        }
    }

    // Get all the list for Description from Master table-------
    getFieldMaintenanceMasterData = () => {
        this.setState({loader:true});
        projectServices.getFieldMaintenanceMasterData().then((response)=>{
            this.setState({FieldMaintenanceMasterData:response.data.data,loader:false});
        }).catch((error) => {
            if (error.response.status === 401) {
                createNotification("error", "Your login session has been expired!!");
                localStorage.setItem('user-token', "");
                this.props.history.push('/login');
            } else {
                createNotification("error", error.response.data.error.message);
            }
            console.log(error);
        })
    }

    getDynamicFieldsByFieldId = () => {
        this.setState({loader:true});
        const {maintenanceFieldTypeId} = this.props.location.state;
        let fieldID = (maintenanceFieldTypeId !== undefined && maintenanceFieldTypeId !== '' ? maintenanceFieldTypeId : '');
        projectServices.getDynamicFieldsByFieldId(fieldID).then((response) =>{
            this.setState({dynamicFields:response.data.data.maintenanceFieldTypeFields,loader:false});
        }).catch((error) =>{
            if (error.response.status === 401) {
                createNotification("error", "Your login session has been expired!!");
                localStorage.setItem('user-token', "");
                this.props.history.push('/login');
            } else {
                createNotification("error", error.response.data.error.message);
            }
            console.log(error);
        })
    }

    handleAddFormSubmit = (e) =>{
        e.preventDefault();
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;
        let isError = false;
        const {fieldID,itemID,itemName,lastService,nextService,nextServiceStatus,targetDynamicField,dynamicFields,dynamicFieldsStatus,dynamicFieldsStatusAll,checked_n_good,needs_future_attention,comments,commentsStatus,changed_required,serviceBy,serviceByStatus,actionButtonName} = this.state;
        let data1 = checked_n_good.map((item1) => { 
            return {
                checkAndGood:true,
                maintenanceFieldMaintenanceTypeId:item1
            } 
        })
        let data2 = needs_future_attention.map((item2) =>{ 
            return{ 
                needFurther:true,
                maintenanceFieldMaintenanceTypeId:item2
            } 
        })
        let data3 = changed_required.map((item3) =>{ 
            return{ 
                changeRequired:true,
                maintenanceFieldMaintenanceTypeId:item3
            } 
        })
        
        const maintenanceFieldData = [...data1,...data2,...data3];
        
        if((lastService === '' || lastService === 'N/A') && (nextService !== '' || nextService !== 'N/A') && (actionButtonName === "add_save_n_done" || actionButtonName === 'add_save') && (Date.parse(new Date()) >= Date.parse(nextService))){
            isError = true;
            createNotification('error','Next Service date should be greater than today\'s date')
        }else if((nextService !== '' || nextService !== 'N/A') && (actionButtonName === "add_save_n_done" || actionButtonName === 'add_save') && (Date.parse(lastService) >= Date.parse(nextService))){
            isError = true;
            createNotification('error','Next Service date should be greater than today\'s date')
        }

        if(nextService === '' || nextService === 'N/A' && actionButtonName !== 'undone' && actionButtonName === 'add_save_n_done'){
            isError = true;
            this.setState({nextServiceError:'Next Service is required*'});
        }else{
            this.setState({nextServiceError:''});
        } 

        // if(targetDynamicField.length === 0 && actionButtonName !== 'undone' && actionButtonName === 'add_save_n_done'){
        //     this.setState({dynamicFieldsErrorAll:' is required*',dynamicFieldsStatusAll:true})
        // }else if(targetDynamicField.length !== 0){
        //     this.setState({dynamicFieldsErrorAll:'',dynamicFieldsStatusAll:false})
        //     let itemError = [];
        //     dynamicFields.map((item,index) =>{
        //           if(targetDynamicField[index] === undefined && item.name !== e.target.name && targetDynamicField[index].value === '' && actionButtonName !== 'undone' && actionButtonName === 'add_save_n_done'){
        //             itemError[index] = ' is required*';
        //             this.setState({dynamicFieldsError:itemError,dynamicFieldsStatus:true})
        //           }else{
        //             itemError[index]='';
        //             this.setState({dynamicFieldsError:itemError,dynamicFieldsStatus:false});
        //           }  
        //     })
        // }

        if(comments === '' && actionButtonName !== 'undone' && actionButtonName === 'add_save_n_done'){
            isError = true;
            this.setState({commentsError:'Comments is required*'})
        }else if(comments !== '' && actionButtonName !== 'undone' && actionButtonName === 'add_save_n_done' && comments.trim().length === 0){
            isError = true;
            this.setState({commentsError:'This is an invalid input'})
        }else{
            this.setState({commentsError:''})
        }

        if(serviceBy === '' && actionButtonName !== 'undone' && actionButtonName === 'add_save_n_done'){
            isError = true;
            this.setState({serviceByError:'Service by is required*'})
        }else if(serviceBy !== '' && actionButtonName !== 'undone' && actionButtonName === 'add_save_n_done' && serviceBy.trim().length === 0){
            isError = true;
            this.setState({serviceByError:'This is an invalid input'})
        }else{
            this.setState({serviceByError:''})
        }

        if(actionButtonName === 'add_save' && (lastService === '' || lastService === 'N/A') && (nextService !== '' && nextService !== 'N/A') && !(Date.parse(new Date()) >= Date.parse(nextService))){
            isError = false;
        }else if(actionButtonName === 'add_save' && ((lastService !== '' && lastService !== 'N/A') && (nextService !== '' && nextService !== 'N/A') && !(Date.parse(lastService) >= Date.parse(nextService)))){
            isError = false;
        }

        if(data1.length === 0 && data2.length === 0 && data3.length === 0){
            createNotification('error',"No option selected, please select atleast one")
        }
        // else if(data1.length === 0){
        //     createNotification('error',"Please select atleast one option for Checked and good!")
        // }else if(data2.length === 0){
        //     createNotification('error',"Please select atleast one option for Needs future attention!")
        // }else if(data3.length === 0){
        //     createNotification('error',"Please select atleast one option for Changed Required!")
        // }
        
        else if(nextServiceStatus === false && dynamicFieldsStatus === false && dynamicFieldsStatusAll === false && commentsStatus === false && serviceByStatus === false && isError === false){
            let payLoad = [];
            if(nextService !== '' || nextService !== 'N/A'){
                payLoad = {name:itemName,lastDate:(lastService !== '' && lastService !== 'N/A' ? dbDateFormat(lastService) : dbDateFormat(new Date())),nextDate:dbDateFormat(nextService),status:(actionButtonName == "add_save" ? "Inprogress" : (actionButtonName == "add_save_n_done" ? "Done" : "") ),comments:(comments === null ? '' : ( comments !== '' && comments.trim().length !== 0 ? comments : '')),serviceBy:(serviceBy === null ? '' : ( serviceBy !== '' && serviceBy.trim().length !== 0 ? serviceBy : '')),maintenanceFieldTypeId:fieldID,fields:maintenanceFieldData};
            }else{
                payLoad = {name:itemName,lastDate:(lastService !== '' && lastService !== 'N/A' ? dbDateFormat(lastService) : dbDateFormat(new Date())),nextDate:dbDateFormat(nextService),status:(actionButtonName == "add_save" ? "Inprogress" : "Done"),comments:(comments === null ? '' : ( comments !== '' && comments.trim().length !== 0 ? comments : '')),serviceBy:(serviceBy === null ? '' : ( serviceBy !== '' && serviceBy.trim().length !== 0 ? serviceBy : '')),maintenanceFieldTypeId:fieldID,fields:maintenanceFieldData};
            }

            projectServices.addFieldMaintenanceDetails(itemID,payLoad).then((res) => {
                createNotification("success", "Field Maintenance details added successfully!");
                this.props.history.push({pathname: '/maintenance',state: {statusFlag:true}});
            }).catch((error) => {
                console.log(error);
            })
        }
    } 
    
     //----------------------------------------------//
     handleOnChangeForAddForms = (e) => {
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;
        const { dynamicFields,checked_n_good, needs_future_attention, changed_required } = this.state;
        let target        = e.target;
        let checkboxArr1  = [...checked_n_good];
        let checkboxArr2  = [...needs_future_attention];
        let checkboxArr3  = [...changed_required];

        let targetField   = [];
        dynamicFields.length != 0 && dynamicFields.map((item,index) => {
            if(target.name === item.name){
                targetField[index] = target.value;
                this.setState({targetDynamicField:targetField})
            }
        })

        if(target.name === 'checked_n_good'){
            let id = Number(target.dataset.id);
            let flag = target.checked;
            if(flag == true){
                checkboxArr1.push(id);
            }else{
                checkboxArr1 = checkboxArr1.filter(e => e != id );
            }
            this.setState({checked_n_good:checkboxArr1});    
        }

        if(target.name === 'needs_future_attention'){
            let id = Number(target.dataset.id);
            let flag = target.checked;
            if(flag == true){
                checkboxArr2.push(id);
            }else{
                checkboxArr2 = checkboxArr2.filter(e => e != id );
            }
            this.setState({needs_future_attention:checkboxArr2});    
        }

        if(target.name === 'changed_required'){
            let id = Number(target.dataset.id);
            let flag = target.checked;
            if(flag == true){
                checkboxArr3.push(id);
            }else{
                checkboxArr3 = checkboxArr3.filter(e => e != id );
            }
            this.setState({changed_required:checkboxArr3});    
        }

        if(target.name === "comments"){
            if(target.value === ''){
                this.setState({commentsError:"Comments is required*",commentsStatus:true});
            }else if(target.value !== '' && target.value.trim().length === 0){
                this.setState({commentsError:"This is an invalid input",commentsStatus:true});
            }else{
                this.setState({commentsError:"",commentsStatus:false});
            }
            this.setState({comments:target.value});
        }

        if(target.name === "serviceBy"){
            if(target.value === ''){
                this.setState({serviceByError:"Service by is required*",serviceByStatus:true});
            }else if(target.value !== '' && target.value.trim().length === 0 ){
                this.setState({serviceByError:"This is an invalid input",serviceByStatus:true});
            }else{
                this.setState({serviceByError:"",serviceByStatus:false});
            }
            this.setState({serviceBy:target.value});
        }
    }

    // UPDAT FORM -----------------------------------//
    getFieldMaintenanceItemAndDescriptionDetails = (itemID) =>{
        projectServices.getFieldMaintenanceItemAndDescriptionDetails(itemID).then((response) => {
            this.setState({MaintenanceDescriptionDetails:response.data.data.maintenanceFieldData});
            let checkedVal_forCheckedAndGood = [];
            checkedVal_forCheckedAndGood = this.state.MaintenanceDescriptionDetails.map((item) =>{
                if(item.checkAndGood === true){
                        return item.maintenanceFieldMaintenanceTypeId;
                }
            })
           
            let checkedVal_forNeedFurther    = [];
            checkedVal_forNeedFurther = this.state.MaintenanceDescriptionDetails.map((item) =>{
                if(item.needFurther === true){
                    return item.maintenanceFieldMaintenanceTypeId;
                }
            })
            
            let checkedVal_forChangeRequired = [];
            checkedVal_forChangeRequired = this.state.MaintenanceDescriptionDetails.map((item) =>{
                if(item.changeRequired ===  true){
                    return item.maintenanceFieldMaintenanceTypeId;
                }
            })    
            this.setState({
                            checked_n_good        : [...new Set(checkedVal_forCheckedAndGood.filter(e => e != null).sort())],
                            needs_future_attention: [...new Set(checkedVal_forNeedFurther.filter(e => e != null).sort())],
                            changed_required      : [...new Set(checkedVal_forChangeRequired.filter(e => e != null).sort())]
                        })
        }).catch((error)=>{
            console.log(error);
        })

    }

    //----------------------------------------------//
    handleEditFormSubmit = (e) =>{
        e.preventDefault();
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;
        let isError = false;
        const {fieldID,itemID,itemName,lastService,nextService,nextServiceStatus,targetDynamicField,dynamicFields,dynamicFieldsStatus,dynamicFieldsStatusAll,checked_n_good,needs_future_attention,changed_required,comments,commentsStatus,serviceBy,serviceByStatus,actionButtonName} = this.state;
        let data1 = checked_n_good.map((item1) => { 
            return {
                checkAndGood:true,
                maintenanceFieldMaintenanceTypeId:item1
            } 
        })
        let data2 = needs_future_attention.map((item2) =>{ 
            return{ 
                needFurther:true,
                maintenanceFieldMaintenanceTypeId:item2
            } 
        })
        let data3 = changed_required.map((item3) =>{ 
            return{ 
                changeRequired:true,
                maintenanceFieldMaintenanceTypeId:item3
            } 
        })
        
        const maintenanceFieldData = [...data1,...data2,...data3];

        if(nextService === '' || nextService === 'N/A' && actionButtonName !== 'undone' && actionButtonName === 'edit_save_n_done'){
            isError = true;
            this.setState({nextServiceError:'Next Service is required*'});
        } else{
            this.setState({nextServiceError:''});
        }

        // if(targetDynamicField.length === 0 && actionButtonName !== 'undone' && actionButtonName === 'edit_save_n_done'){
        //     this.setState({dynamicFieldsErrorAll:' is required*',dynamicFieldsStatusAll:true})
        // }else if(targetDynamicField.length !== 0){
        //     this.setState({dynamicFieldsErrorAll:'',dynamicFieldsStatusAll:false})
        //     let itemError = [];
        //     dynamicFields.map((item,index) =>{
        //           if(targetDynamicField[index] === undefined && item.name !== e.target.name && targetDynamicField[index].value === '' && actionButtonName !== 'undone' && actionButtonName === 'edit_save_n_done'){
        //             itemError[index] = ' is required*';
        //             this.setState({dynamicFieldsError:itemError,dynamicFieldsStatus:true})
        //           }else{
        //             itemError[index]='';
        //             this.setState({dynamicFieldsError:itemError,dynamicFieldsStatus:false});
        //           }  
        //     })
        // }

        if(comments === '' && actionButtonName !== 'undone' && actionButtonName === 'edit_save_n_done'){
            isError = true;
            this.setState({commentsError:'Comments is required*'})
        }else if(comments !== '' && actionButtonName !== 'undone' && actionButtonName === 'edit_save_n_done' && comments.trim().length === 0){
            isError = true;
            this.setState({commentsError:'This is an invalid input'})
        }else{
            
            this.setState({commentsError:''})
        }

        if(serviceBy === '' && actionButtonName !== 'undone' && actionButtonName === 'edit_save_n_done'){
            isError = true;
            this.setState({serviceByError:'Service By is required*'})
        }else if(serviceBy !== '' && actionButtonName !== 'undone' && actionButtonName === 'edit_save_n_done' && serviceBy.trim().length === 0 ){
            isError = true;
            this.setState({serviceByError:'This is an invalid input'})
        }else{
            this.setState({serviceByError:''})
        }

        if(actionButtonName === 'edit_save'){
            isError = false;
        }
      
        if(data1.length === 0 && data2.length === 0 && data3.length === 0){
            createNotification('error',"No option selected, please select atleast one")
        }
        
        // else if(data1.length === 0){
        //     createNotification('error',"Please select atleast one option for Checked and good!")
        // }else if(data2.length === 0){
        //     createNotification('error',"Please select atleast one option for Needs future attention!")
        // }else if(data3.length === 0){
        //     createNotification('error',"Please select atleast one option for Changed Required!")
        // }
        
        else if(nextServiceStatus === false && dynamicFieldsStatus === false && dynamicFieldsStatusAll === false && commentsStatus === false && serviceByStatus === false && isError=== false){
            let payLoad = [];
            if(nextService !== '' || nextService !== 'N/A'){
                payLoad = {name:itemName,lastDate:(lastService !== '' && lastService !== 'N/A' ? dbDateFormat(lastService) : dbDateFormat(new Date())),nextDate:dbDateFormat(nextService),status:(actionButtonName == "edit_save" ? "Inprogress" : (actionButtonName == "edit_save_n_done" ? "Done" : (actionButtonName == "undone" ? "Inprogress" : ""))),comments:(comments === null ? '' : ( comments !== '' && comments.trim().length !== 0 ? comments : '')),serviceBy:(serviceBy === null ? '' : ( serviceBy !== '' && serviceBy.trim().length !== 0 ? serviceBy : '')),maintenanceFieldTypeId:fieldID,fields:maintenanceFieldData};
            }else{
                payLoad = {name:itemName,lastDate:(lastService !== '' && lastService !== 'N/A' ? dbDateFormat(lastService) : dbDateFormat(new Date())),nextDate:dbDateFormat(nextService),status:(actionButtonName == "edit_save" ? "Inprogress" : (actionButtonName == "edit_save_n_done" ? "Done" : (actionButtonName == "undone" ? "Inprogress" : ""))),comments:(comments === null ? '' : ( comments !== '' && comments.trim().length !== 0 ? comments : '')),serviceBy:(serviceBy === null ? '' : ( serviceBy !== '' && serviceBy.trim().length !== 0 ? serviceBy : '')),maintenanceFieldTypeId:fieldID,fields:maintenanceFieldData};
            }
            
                projectServices.editFieldMaintenanceDetails(itemID,payLoad).then((res) => {
                    if(actionButtonName === "undone"){
                        let editFormData = {id:itemID,name:itemName,maintenanceFieldTypeId:fieldID,status:"In Progress",lastDate:lastService,nextDate:nextService,comments:comments,serviceBy:serviceBy,fields:maintenanceFieldData,maintenanceFieldMasterFields:this.props.location.state.maintenanceFieldMasterFields}
                        this.props.history.push({pathname:'/maintenance/field/edit',state:(editFormData),search:`${this.state.fieldName}`});
                    }else{
                        createNotification("success", "Field Maintenance details updated successfully!");
                        this.props.history.push({pathname: '/maintenance',state: {statusFlag:true}});
                    }
                }).catch((error) => {
                    console.log(error);
                })
            }
        }
    
    //----------------------------------------------//
    handleOnChangeForEditForms = (e) => {
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;
        const { dynamicFields,checked_n_good, needs_future_attention, changed_required } = this.state;
        let target        = e.target;
        let checkboxArr1  = [...checked_n_good];
        let checkboxArr2  = [...needs_future_attention];
        let checkboxArr3  = [...changed_required];
        
        let targetField   = [];
        dynamicFields.length != 0 && dynamicFields.map((item,index) => {
            if(target.name === item.name){
                targetField[index] = target.value;
                this.setState({targetDynamicField:targetField})
            }
        })
        
        if(target.name === 'checked_n_good'){
            let id = Number(target.dataset.id);
            let flag = target.checked;
            if(flag == true){
                checkboxArr1.push(id);
            }else{
                checkboxArr1 = checkboxArr1.filter(e => e != id );
            }
            this.setState({checked_n_good:checkboxArr1});    
        }

        if(target.name === 'needs_future_attention'){
            let id = Number(target.dataset.id);
            let flag = target.checked;
            if(flag == true){
                checkboxArr2.push(id);
            }else{
                checkboxArr2 = checkboxArr2.filter(e => e != id );
            }
            this.setState({needs_future_attention:checkboxArr2});    
        }

        if(target.name === 'changed_required'){
            let id = Number(target.dataset.id);
            let flag = target.checked;
            if(flag == true){
                checkboxArr3.push(id);
            }else{
                checkboxArr3 = checkboxArr3.filter(e => e != id );
            }
            this.setState({changed_required:checkboxArr3});    
        }

        if(target.name === "comments"){
            if(target.value === ''){
                this.setState({commentsError:"comments is required",commentsStatus:true});
            }else if(target.value !== '' && target.value.trim().length === 0){
                this.setState({commentsError:"This is an invalid input",commentsStatus:true});
            }else{
                this.setState({commentsError:"",commentsStatus:false});
            }
            this.setState({comments:target.value});
        }

        if(target.name === "serviceBy"){
            if(target.value === ''){
                this.setState({serviceByError:"serviceBy is required",serviceByStatus:true});
            }else if(target.value !== '' && target.value.trim().length === 0){
                this.setState({serviceByError:"This is an invalid input",serviceByStatus:true});
            }else{
                this.setState({serviceByError:"",serviceByStatus:false});
            }
            this.setState({serviceBy:target.value});
        }
    }
    
     //----------------------------------------------//
     handleDateChange = (date, type) => {
        let formatedDate = '';
        if(date !== null){
            formatedDate = date
        }else{
            formatedDate = '';
        }
        if (type === "lastService") {
            if(this.state.nextService !== ''){
                if(Date.parse(this.state.nextService) < Date.parse(date)){
                    createNotification("error", "Last Service date should be less than Next Service date");
                }else{
                    this.setState({ lastService: formatedDate });
                }
            }else{
                this.setState({ lastService: formatedDate });
            } 
        } else if (type === "nextService") {
            if(this.state.lastService !== ''){
                if(Date.parse(this.state.lastService) > Date.parse(date)){
                    createNotification("error", "Next Service date should be greater than Last Service Date");
                }else{
                    this.setState({ nextService: formatedDate });
                }
            }else{
                this.setState({ nextService: formatedDate });
            }
        }
    }

    // handle button action, get current buttons name, which one was clicked recently 
    handleButtonClickAction = (e) => {
        this.setState({actionButtonName:e.target.dataset.name});
    }

//---------------------------------------------------------------------------------------------------//

    render(){
        // ACCESS PERMISSION ROLE KEYS WITH THERE PERMISSION RIGHTS----------------------------------
        let roleKey        = this.props.loginUserData.roleKey;
        const isAccessable = (roleKey!=="" && rules[roleKey][Permission.MAINTENANCE_WRITE]) ? true : false;
        // ACCESS PERMISSION ROLE KEYS WITH THERE PERMISSION RIGHTS----------------------------------

        const {FieldMaintenanceMasterData,loader,status,lastService,nextService,comments,serviceBy,isAccess,dynamicFields,targetDynamicField} = this.state;  
        FieldMaintenanceMasterData.sort((a, b) => (a.id > b.id) ? 1 : -1)
        return(
            <div>
                <div className="main-content shopPersonal">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                            <ol className="breadcrumb m-0">
                                    <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                                    &nbsp; / &nbsp;
                                    <a href={`/maintenance`} onClick={() => {this.props.history.push({pathname: '/maintenance',state: {statusFlag:true}})}}><li className="breadcrumb-item">Field Maintenance</li></a>    
                                    &nbsp; / &nbsp;
                                    {
                                    this.props.match.path == `/maintenance/field/add` ?
                                        <Link to={`/maintenance/field/add`}><li className="breadcrumb-item">Add Maintenance</li></Link>
                                    :this.props.match.path == `/maintenance/field/edit` ?
                                        <Link to={`/maintenance/field/edit`}><li className="breadcrumb-item">Edit Maintenance</li></Link>
                                    :this.props.match.path == `/maintenance/field/view` ?
                                        <Link to={`/maintenance/field/view`}><li className="breadcrumb-item">View Maintenance</li></Link>
                                    :''
                                    }    
                                </ol>
                            </div>
                            <h4 className="mb-0 text-left">{this.state.fieldName} - {this.state.itemName}</h4>
                            <div style={isAccessable === true ? {display:"block"} : {display:"none"}} >
                            {//-----------------------------------------------------------------------------------------------------------------------------------------//
                                status === "Pending" || status === "Overdue" || status === "OVERDUE" ?  // ADD FORM
                                (<form onSubmit={this.handleAddFormSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project-scope-block">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="col-md-8">Last Service</label>
                                                                {lastService == '' || lastService == 'N/A' ?
                                                                    <DatePicker 
                                                                        className="form-control date-form-control" 
                                                                        onChange={(date) => {this.handleDateChange(date, "lastService")}} 
                                                                        dateFormat="MM/dd/yyyy" 
                                                                        selected={new Date(moment(new Date()).format('MM/DD/YYYY'))}
                                                                        placeholderText="MM/DD/YYYY"
                                                                        maxDate={moment().toDate()}
                                                                    />
                                                                    :
                                                                    <DatePicker 
                                                                        className="form-control date-form-control" 
                                                                        onChange={(date) => {this.handleDateChange(date, "lastService")}} 
                                                                        dateFormat="MM/dd/yyyy" 
                                                                        selected={new Date(moment(lastService).format('MM/DD/YYYY'))}
                                                                        placeholderText="MM/DD/YYYY"
                                                                        maxDate={moment().toDate()}
                                                                    />
                                                                }
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label className="col-md-8">Next Service</label>
                                                                {nextService == '' || nextService == 'N/A' ?
                                                                <>
                                                                <DatePicker 
                                                                    className="form-control date-form-control" 
                                                                    onChange={(date) => {this.handleDateChange(date, "nextService")}} 
                                                                    dateFormat="MM/dd/yyyy" 
                                                                    placeholderText="MM/DD/YYYY"
                                                                    minDate={moment().toDate()}
                                                                />
                                                                {this.state.nextServiceError !== '' ?
                                                                <p style={{color:"red"}}>{this.state.nextServiceError}</p>
                                                                :<></>
                                                                }
                                                                </>
                                                                :
                                                                <DatePicker 
                                                                    className="form-control date-form-control" 
                                                                    onChange={(date) => {this.handleDateChange(date, "nextService")}} 
                                                                    dateFormat="MM/dd/yyyy" 
                                                                    selected={new Date(moment(nextService).format('MM/DD/YYYY'))}
                                                                    placeholderText="MM/DD/YYYY"
                                                                    minDate={moment().toDate()}
                                                                />
                                                                }
                                                            </div>
                                                            {/* for first three elements in array after last and next service date */}
                                                            <div className="col-md-8">
                                                                <div className="row">
                                                                    {dynamicFields.length != 0 && dynamicFields.map((item,index) => {
                                                                        let labelName = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
                                                                        if(index <= 2){
                                                                            return (
                                                                                <div className="col-md-4">
                                                                                    <label class="">{labelName}</label>
                                                                                    <input type='text' className='form-control' readOnly name={`${item.name}`} /*onChange={(event)=>{this.handleOnChangeForAddForms(event);}}*/ placeholder={`please enter ${labelName}`} value={targetDynamicField[index] !== undefined ? targetDynamicField[index].value : ''}/>
                                                                                    {/* {this.state.dynamicFieldsErrorAll !== '' ?
                                                                                    <p style={{color:"red"}}>{labelName+' '+this.state.dynamicFieldsErrorAll}</p>
                                                                                    :<></>
                                                                                    } */}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>

                                                            {/* for elements more than three in array in next row */}
                                                            <div className="col-md-12 pt-2">
                                                                <div className="row">
                                                                    {dynamicFields.length != 0 && dynamicFields.map((item,index) => {
                                                                        let labelName = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
                                                                        if(index > 2){
                                                                            return (
                                                                                <div className="col-md-2">
                                                                                    <label class="">{labelName}</label>
                                                                                    <input type='text' readOnly className='form-control' name={`${item.name}`} /*onChange={(event)=>{this.handleOnChangeForAddForms(event);}}*/ placeholder={`please enter ${labelName}`} value={targetDynamicField[index] !== undefined ? targetDynamicField[index].value : ''}/>
                                                                                    {/* {this.state.dynamicFieldsErrorAll !== '' ?
                                                                                    <p style={{color:"red"}}>{labelName+' '+this.state.dynamicFieldsErrorAll}</p>
                                                                                    :<></>
                                                                                    } */}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 pt-3 pb-2">
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                            <div class="fixTableHead maintenanceField">
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Description</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta) =>
                                                                            <div class="tableCell">{dta.name}</div>
                                                                        )
                                                                    )}
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Checked and good</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                    <input disabled={isAccess === true ? isAccess : false} class="form-check-input" type="checkbox" name="checked_n_good" data-id={dta.id} onChange={(event)=>{this.handleOnChangeForAddForms(event);}}/>
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Needs future attention</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                    <input disabled={isAccess === true ? isAccess : false} class="form-check-input" type="checkbox" name="needs_future_attention" data-id={dta.id} onChange={(event)=>{this.handleOnChangeForAddForms(event);}}/>
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Changed Required</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                    <input disabled={isAccess === true ? isAccess : false} class="form-check-input" type="checkbox" name="changed_required" data-id={dta.id} onChange={(event)=>{this.handleOnChangeForAddForms(event);}}/>
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                            </div>
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label className="col-md-4">Comments</label>
                                                                <textarea autoComplete="off" type="text" className="form-control col-md-8" placeholder="Write your comments here..." name="comments" onChange={(event)=>{this.handleOnChangeForAddForms(event);}} maxLength="700"/>
                                                                <p style={{color:"red"}}>{this.state.commentsError}</p>
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <label className="col-md-10">Serviced by</label>
                                                                <input type="text" autoComplete="off" className="form-control col-md-2" name="serviceBy" placeholder="Enter service person name" onChange={(event)=>{this.handleOnChangeForAddForms(event);}} maxLength="200" />
                                                                <p style={{color:"red"}}>{this.state.serviceByError}</p>
                                                            </div>
                                                            <div className="col-md-5"></div>
                                                            <div className="col-md-4">
                                                                <br/>
                                                                <label className="col-md-3"></label>
                                                                <button className="col-md-9 uil uil-eye btn btn-secondary text-light" onClick={() => {this.props.history.push({pathname: '/maintenance/field/history',state: this.props.location.state})}}>View Service History</button>
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-3"></div>
                                                            <button className="btn btn-success col-md-2" data-name="add_save" onClick={(e) =>this.handleButtonClickAction(e)}>Save</button>&nbsp;
                                                            <button className="btn btn-info col-md-2" data-name="add_save_n_done" onClick={(e) =>this.handleButtonClickAction(e)}>Save & Done</button>&nbsp;
                                                            <button className="btn btn-primary col-md-2" onClick={() => {this.props.history.push({pathname: '/maintenance',state: {statusFlag:true}})}}>Cancel</button>
                                                            <div className="col-md-3"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>)
                                ://--------------------------------------------------------------------------------------------------------------------------------//
                                status === "In Progress" ? //UPDATE FORM
                                (<form onSubmit={this.handleEditFormSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project-scope-block">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="col-md-8">Last Service</label>
                                                                {lastService == '' || lastService == 'N/A' ?
                                                                    <DatePicker 
                                                                        className="form-control date-form-control" 
                                                                        onChange={(date) => {this.handleDateChange(date, "lastService")}} 
                                                                        dateFormat="MM/dd/yyyy" 
                                                                        selected={new Date(moment(new Date()).format('MM/DD/YYYY'))}
                                                                        placeholderText="MM/DD/YYYY"
                                                                        maxDate={moment().toDate()}
                                                                    />
                                                                    :
                                                                    <DatePicker 
                                                                        className="form-control date-form-control" 
                                                                        onChange={(date) => {this.handleDateChange(date, "lastService")}} 
                                                                        dateFormat="MM/dd/yyyy" 
                                                                        selected={new Date(moment(lastService).format('MM/DD/YYYY'))}
                                                                        placeholderText="MM/DD/YYYY"
                                                                        maxDate={moment().toDate()}
                                                                    />
                                                                }
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label className="col-md-8">Next Service</label>
                                                                {nextService == '' || nextService == 'N/A' ?
                                                                <>
                                                                    <DatePicker 
                                                                        className="form-control date-form-control" 
                                                                        onChange={(date) => {this.handleDateChange(date, "nextService")}} 
                                                                        dateFormat="MM/dd/yyyy" 
                                                                        placeholderText="MM/DD/YYYY"
                                                                        minDate={moment().toDate()}
                                                                    />
                                                                    {this.state.nextServiceError !== '' ?
                                                                        <p style={{color:"red"}}>{this.state.nextServiceError}</p>
                                                                    :
                                                                        <></>
                                                                    }
                                                                </>
                                                                :
                                                                <DatePicker 
                                                                    className="form-control date-form-control" 
                                                                    onChange={(date) => {this.handleDateChange(date, "nextService")}} 
                                                                    dateFormat="MM/dd/yyyy" 
                                                                    selected={new Date(moment(nextService).format('MM/DD/YYYY'))}
                                                                    placeholderText="MM/DD/YYYY"
                                                                    minDate={moment().toDate()}
                                                                />
                                                                }
                                                            </div>
                                                            {/* for first three elements in array after last and next service date */}
                                                            <div className="col-md-8">
                                                                <div className="row">
                                                                    {dynamicFields.length != 0 && dynamicFields.map((item,index) => {
                                                                        let labelName = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
                                                                        if(index <= 2){
                                                                            return (
                                                                                <div className="col-md-4">
                                                                                    <label class="">{labelName}</label>
                                                                                    <input readOnly type='text' className='form-control' name={`${item.name}`} /*onChange={(event)=>{this.handleOnChangeForEditForms(event)}}*/ placeholder={`please enter ${labelName}`} value={ targetDynamicField[index] !== undefined ? targetDynamicField[index].value : '' }/>
                                                                                    {/* {this.state.dynamicFieldsErrorAll !== '' ?
                                                                                    <p style={{color:"red"}}>{labelName+' '+this.state.dynamicFieldsErrorAll}</p>
                                                                                    :<></>
                                                                                    } */}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>

                                                            {/* for elements more than three in array in next row */}
                                                            <div className="col-md-12 pt-2">
                                                                <div className="row">
                                                                    {dynamicFields.length != 0 && dynamicFields.map((item,index) => {
                                                                        let labelName = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
                                                                        if(index > 2){
                                                                            return (
                                                                                <div className="col-md-2">
                                                                                    <label class="">{labelName}</label>
                                                                                    <input readOnly type='text' className='form-control' /*onChange={(event)=>{this.handleOnChangeForEditForms(event)}}*/ placeholder={`please enter ${labelName}`} value={ targetDynamicField[index] !== undefined ? targetDynamicField[index].value : '' }/>
                                                                                    {/* {this.state.dynamicFieldsErrorAll !== '' ?
                                                                                    <p style={{color:"red"}}>{labelName+' '+this.state.dynamicFieldsErrorAll}</p>
                                                                                    :<></>
                                                                                    } */}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 pt-3 pb-2">
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                            <div class="fixTableHead maintenanceField">
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Description</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta) =>
                                                                            <div class="tableCell">{dta.name}</div>
                                                                        )
                                                                    )}
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Checked and good</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                {(this.state.checked_n_good.indexOf(dta.id) > -1) ? 
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        name="checked_n_good" 
                                                                                        data-id={dta.id} 
                                                                                        onChange={(event)=>{this.handleOnChangeForEditForms(event)}}
                                                                                        checked
                                                                                        
                                                                                    />
                                                                                ) 
                                                                                :
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        name="checked_n_good" 
                                                                                        data-id={dta.id} 
                                                                                        onChange={(event)=>{this.handleOnChangeForEditForms(event)}}
                                                                                     />
                                                                                ) 
                                                                                }
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Needs future attention</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                {(this.state.needs_future_attention.indexOf(dta.id) > -1) ? 
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        name="needs_future_attention" 
                                                                                        data-id={dta.id} 
                                                                                        onChange={(event)=>{this.handleOnChangeForEditForms(event)}}
                                                                                        checked
                                                                                        
                                                                                    />
                                                                                ) 
                                                                                :
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        name="needs_future_attention" 
                                                                                        data-id={dta.id} 
                                                                                        onChange={(event)=>{this.handleOnChangeForEditForms(event)}}
                                                                                     />
                                                                                ) 
                                                                                }
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Changed Required</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                {(this.state.changed_required.indexOf(dta.id) > -1) ? 
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        name="changed_required" 
                                                                                        data-id={dta.id} 
                                                                                        onChange={(event)=>{this.handleOnChangeForEditForms(event)}}
                                                                                        checked
                                                                                        
                                                                                    />
                                                                                ) 
                                                                                :
                                                                                (
                                                                                    <input 
                                                                                        class="form-check-input" 
                                                                                        type="checkbox" 
                                                                                        name="changed_required" 
                                                                                        data-id={dta.id} 
                                                                                        onChange={(event)=>{this.handleOnChangeForEditForms(event)}}
                                                                                     />
                                                                                ) 
                                                                                }
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                            </div>
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label className="col-md-4">Comments</label>
                                                                <textarea autoComplete="off" type="text" className="form-control col-md-8" placeholder="Write your comments here..." name="comments" onChange={(event)=>{this.handleOnChangeForAddForms(event);}} value={comments} maxLength="700"/>
                                                                <p style={{color:"red"}}>{this.state.commentsError}</p>
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <label className="col-md-10">Serviced by</label>
                                                                <input type="text" autoComplete="off" className="form-control col-md-2" name="serviceBy" placeholder="Enter service person name" onChange={(event)=>{this.handleOnChangeForAddForms(event);}} value={serviceBy} maxLength="200"/>
                                                                <p style={{color:"red"}}>{this.state.serviceByError}</p>

                                                            </div>
                                                            <div className="col-md-5"></div>
                                                            <div className="col-md-4">
                                                                <br/>
                                                                <label className="col-md-3"></label>
                                                                <button className="col-md-9 uil uil-eye btn btn-secondary text-light" onClick={() => {this.props.history.push({pathname: '/maintenance/field/history',state: this.props.location.state})}}>View Service History</button>
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-3"></div>
                                                            <button className="btn btn-success col-md-2" data-name="edit_save" onClick={(e) =>this.handleButtonClickAction(e)}>Update</button>&nbsp;
                                                            <button className="btn btn-info col-md-2" data-name="edit_save_n_done" onClick={(e) =>this.handleButtonClickAction(e)}>Save & Done</button>&nbsp;
                                                            <button className="btn btn-primary col-md-2" onClick={() => {this.props.history.push({pathname: '/maintenance',state: {statusFlag:true}})}}>Cancel</button>
                                                            <div className="col-md-3"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>)
                                ://-------------------------------------------------------------------------------------------------------------------------------------------//
                                status === "Done" ? // VIEW PAGE
                                (<form onSubmit={this.handleEditFormSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project-scope-block">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="col-md-8">Last Service</label>
                                                                <input readOnly type="text" className="form-control col-md-2" name="lastService" value={(lastService == '' || lastService == 'N/A' ? 'mm/dd/yyyy' : lastService)}/>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label className="col-md-8">Next Service</label>
                                                                <input readOnly type="text" className="form-control col-md-2" name="nextService" value={(nextService == '' || nextService == 'N/A' ? 'mm/dd/yyyy' : nextService)}/>
                                                            </div>
                                                            {/* for first three elements in array after last and next service date */}
                                                            <div className="col-md-8">
                                                                <div className="row">
                                                                    {dynamicFields.length != 0 && dynamicFields.map((item,index) => {
                                                                        let labelName = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
                                                                        if(index <= 2){
                                                                            return (
                                                                                <div className="col-md-4">
                                                                                    <label class="">{labelName}</label>
                                                                                    <input readOnly type='text' className='form-control' placeholder={`please enter ${labelName}`} value={ targetDynamicField[index] !== undefined ? targetDynamicField[index].value : '' }/>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>

                                                            {/* for elements more than three in array in next row */}
                                                            <div className="col-md-12 pt-2">
                                                                <div className="row">
                                                                    {dynamicFields.length != 0 && dynamicFields.map((item,index) => {
                                                                        let labelName = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
                                                                        if(index > 2){
                                                                            return (
                                                                                <div className="col-md-2">
                                                                                    <label class="">{labelName}</label>
                                                                                    <input readOnly type='text' className='form-control' placeholder={`please enter ${labelName}`} value={targetDynamicField[index] !== undefined ? targetDynamicField[index].value : ''}/>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 pt-3 pb-2">
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                            <div class="fixTableHead maintenanceField">
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Description</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta) =>
                                                                            <div class="tableCell">{dta.name}</div>
                                                                        )
                                                                    )}
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Checked and good</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                    {(this.state.checked_n_good.indexOf(dta.id) > -1) ? 
                                                                                        (<input 
                                                                                            class="form-check-input" 
                                                                                            type="checkbox" 
                                                                                            checked
                                                                                            disabled
                                                                                        />) 
                                                                                    :
                                                                                        (<input 
                                                                                            class="form-check-input" 
                                                                                            type="checkbox" 
                                                                                            disabled
                                                                                        />) 
                                                                                    }
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Needs future attention</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                    {(this.state.needs_future_attention.indexOf(dta.id) > -1) ? 
                                                                                        (<input 
                                                                                            class="form-check-input" 
                                                                                            type="checkbox" 
                                                                                            checked
                                                                                            disabled
                                                                                        />) 
                                                                                    :
                                                                                        (<input 
                                                                                            class="form-check-input" 
                                                                                            type="checkbox" 
                                                                                            disabled
                                                                                        />) 
                                                                                    }
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Changed Required</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                    {(this.state.changed_required.indexOf(dta.id) > -1) ? 
                                                                                        (<input 
                                                                                            class="form-check-input" 
                                                                                            type="checkbox" 
                                                                                            checked
                                                                                            disabled
                                                                                        />) 
                                                                                    :
                                                                                        (<input 
                                                                                            class="form-check-input" 
                                                                                            type="checkbox" 
                                                                                            disabled
                                                                                        />) 
                                                                                    }
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                            </div>
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label className="col-md-4">Comments</label>
                                                                <textarea readOnly autoComplete="off" type="text" className="form-control col-md-8" placeholder="Write your comments here..." name="comments" value={comments} />
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <label className="col-md-10">Serviced by</label>
                                                                <input readOnly type="text" autoComplete="off" className="form-control col-md-2" name="serviceBy" placeholder="Enter service person name" value={serviceBy} />
                                                            </div>
                                                            <div className="col-md-5"></div>
                                                            <div className="col-md-4">
                                                                <br/>
                                                                <label className="col-md-3"></label>
                                                                <button className="col-md-9 uil uil-eye btn btn-secondary text-light" onClick={() => {this.props.history.push({pathname: '/maintenance/field/history',state: this.props.location.state})}}>View Service History</button>
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-4"></div>
                                                            <button className="btn btn-warning col-md-2" data-name="undone" onClick={(e) =>this.handleButtonClickAction(e)}>Re-Open</button>&nbsp;
                                                            <button className="btn btn-primary col-md-2" onClick={() => {this.props.history.push({pathname: '/maintenance',state: {statusFlag:true}})}}>Back</button>
                                                            <div className="col-md-4"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>) 
                                ://------------------------------------------------------------------------------------------------------------------------------------------// 
                                (<></>) 
                            }
                            </div>
                            <div style={isAccessable === false ? {display:"block"} : {display:"none"}}>
                                <form>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project-scope-block">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <label className="col-md-8">Last Service</label>
                                                                <input readOnly type="text" className="form-control col-md-2" name="lastService" value={(lastService == '' || lastService == 'N/A' ? 'mm/dd/yyyy' : dateFormat(lastService))}/>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label className="col-md-8">Next Service</label>
                                                                <input readOnly type="text" className="form-control col-md-2" name="nextService" value={(nextService == '' || nextService == 'N/A' ? 'mm/dd/yyyy' : dateFormat(nextService))}/>
                                                            </div>
                                                            {/* for first three elements in array after last and next service date */}
                                                            <div className="col-md-8">
                                                                <div className="row">
                                                                    {dynamicFields !== undefined && dynamicFields.length !== 0 && dynamicFields.map((item,index) => {
                                                                        let labelName = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
                                                                        if(index <= 2){
                                                                            return (
                                                                                <div className="col-md-4">
                                                                                    <label class="">{labelName}</label>
                                                                                    <input readOnly type='text' className='form-control' placeholder={`please enter ${labelName}`} value={ targetDynamicField[index] !== undefined ? targetDynamicField[index].value : '' }/>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>

                                                            {/* for elements more than three in array in next row */}
                                                            <div className="col-md-12 pt-2">
                                                                <div className="row">
                                                                    {dynamicFields !== undefined && dynamicFields.length !== 0 && dynamicFields.map((item,index) => {
                                                                        let labelName = item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase()
                                                                        if(index > 2){
                                                                            return (
                                                                                <div className="col-md-2">
                                                                                    <label class="">{labelName}</label>
                                                                                    <input readOnly type='text' className='form-control' placeholder={`please enter ${labelName}`} value={targetDynamicField[index] !== undefined ? targetDynamicField[index].value : ''}/>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 pt-3 pb-2">
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                            <div class="fixTableHead maintenanceField">
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Description</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta) =>
                                                                            <div class="tableCell">{dta.name}</div>
                                                                        )
                                                                    )}
                                                                    {/* -----DESCRIPTION DATA---------- */}
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Checked and good</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                    {(this.state.checked_n_good.indexOf(dta.id) > -1) ? 
                                                                                        (<input 
                                                                                            class="form-check-input" 
                                                                                            type="checkbox" 
                                                                                            checked
                                                                                            disabled
                                                                                        />) 
                                                                                    :
                                                                                        (<input 
                                                                                            class="form-check-input" 
                                                                                            type="checkbox" 
                                                                                            disabled
                                                                                        />) 
                                                                                    }
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Needs future attention</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                    {(this.state.needs_future_attention.indexOf(dta.id) > -1) ? 
                                                                                        (<input 
                                                                                            class="form-check-input" 
                                                                                            type="checkbox" 
                                                                                            checked
                                                                                            disabled
                                                                                        />) 
                                                                                    :
                                                                                        (<input 
                                                                                            class="form-check-input" 
                                                                                            type="checkbox" 
                                                                                            disabled
                                                                                        />) 
                                                                                    }
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                                <div class="fixTableHeadCol">
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                    <div class="tableHeading">Changed Required</div>
                                                                    {/* ---------DIV HEADER---------------- */}
                                                                        
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}
                                                                    {loader === true ? <Loader/> : (
                                                                        FieldMaintenanceMasterData.map((dta,i) =>
                                                                            <div class="tableCell">
                                                                                <div class="form-check form-check-inline m-0">
                                                                                    {(this.state.changed_required.indexOf(dta.id) > -1) ? 
                                                                                        (<input 
                                                                                            class="form-check-input" 
                                                                                            type="checkbox" 
                                                                                            checked
                                                                                            disabled
                                                                                        />) 
                                                                                    :
                                                                                        (<input 
                                                                                            class="form-check-input" 
                                                                                            type="checkbox" 
                                                                                            disabled
                                                                                        />) 
                                                                                    }
                                                                                    <label class="form-check-label"></label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* -----CHECKBOXES EQUAL TO TOTAL No OF DESCRIPTION DATA----- */}	        
                                                                </div>
                                                            </div>
                                                            {/* MIDDLE PART OF MULTIPLE SELECT WITH CHECKBOXES */}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label className="col-md-4">Comments</label>
                                                                <textarea readOnly autoComplete="off" type="text" className="form-control col-md-8" placeholder="Write your comments here..." name="comments" value={comments} />
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <label className="col-md-10">Serviced by</label>
                                                                <input readOnly type="text" autoComplete="off" className="form-control col-md-2" name="serviceBy" placeholder="Enter service person name" value={serviceBy} />
                                                            </div>
                                                            <div className="col-md-5"></div>
                                                            <div className="col-md-4">
                                                                <br/>
                                                                <label className="col-md-3"></label>
                                                                <button className="col-md-9 uil uil-eye btn btn-secondary text-light" onClick={() => {this.props.history.push({pathname: '/maintenance/field/history',state: this.props.location.state})}}>View Service History</button>
                                                            </div>
                                                        </div><br/>
                                                        <div className="row">
                                                            <div className="col-md-4"></div>
                                                            <button className="btn btn-primary col-md-2" onClick={() => {this.props.history.push({pathname: '/maintenance',state: {statusFlag:true}})}}>Back</button>
                                                            <div className="col-md-4"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
  };
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FieldMasterDataMaintenance);