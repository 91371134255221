import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import projectServices from "../project/services/projectServices";
import { createNotification } from "../helpers/Notification";
import Loader from "../common/Loader";
import { Tab } from "react-bootstrap";
import { DropDown } from "../common";
import SimpleReactValidator from 'simple-react-validator';
import ConfirmBox from "../common/ConfirmBox";
// import './index.css'
const Editor = (props) => {
  const editor = useRef(null);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [email, setEmail] = useState("");
  const [currentTab, setCurrentTab] = useState("custom");
  const [loader, setLoader] = useState(false);
  const [templateDropdown, setTemplateDropdown] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(0);
  const [backLocation, setBackLocation] = useState("");
  const [groupName, setGroupName] = useState("");
  const [isMail, setIsMail] = useState(true);


  useEffect(() => {
    setLoader(true);
    handleEmail();
  });

  const handleEmail = () => {
    if (!props.email == "") {
      setEmail(props.email);
      setBackLocation(props.backLocation);
      setGroupName(props.name);
      
    }
    setLoader(false);
  };

  const config = {
    Placeholder: "Start Typing",
  };

  const form = useRef();

  const handleReset = () => {
    setSubject("");
    setContent("");
    setSelectedTemplate(0);
  };
  const handleSendEmail = (e) => {
    const sanitizedContent = content.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, "").trim();
    e.preventDefault();
    let payload = {
      subject: subject,
      content: sanitizedContent,
    };

    if (payload.subject ==''){
      createNotification("error"," Please add subject");
    } else {
      if(backLocation == 'Single'){
        projectServices
      // .sendMail(email, payload)
      .sendIndividualMail(email,isMail, payload)
      .then((response) => {
        if (response.status === 200) {
          createNotification("success", response.data.message);
          handleReset();
        }
      })
      .catch((error) => {
        console.log(error);
      });

      } else {
        projectServices
      // .sendMail(email, payload)
      .sendGroupMail(email,isMail, payload)
      .then((response) => {
        if (response.status === 200) {
          createNotification("success", response.data.message);
          handleReset();
        }
      })
      .catch((error) => {
        console.log(error);
      });

      }
    }

   
  };
  const handleFormChange = (e) => {
    setSubject(e.target.value);
    // validator.showMessages(); 
    // setForceUpdate((prev) => prev + 1); 
  };

  const handleTabs = (e) => {
    if (e.target.id === "custom") {
      setCurrentTab("custom");
    } else {
      getDropdownData();
      setCurrentTab("template");
    }
  };
  const getDropdownData = () => {
    projectServices
      .getTemplateList()
      .then((response) => {
        let responseData = response.data.data;
        let listArr = [];
        responseData &&
          responseData.map((item) => {
            listArr.push({ lable: item.subject, value: item.id });
          });
        setTemplateDropdown(listArr);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          createNotification("error", "Your login session has expired .");
          props.history.push("/login");
        }
      });
  };

  const handleOnChange = (e) => {
    if (e.target.value == 0) {
      setContent("");
      setSelectedTemplate(e.target.value);
      
    } else {
      
      setSelectedTemplate(e.target.value);
      getTemplayeById(e.target.value);
      setLoader(true);
    }
  };

  const getTemplayeById = (id) => {
    if(!id == 0) {
      projectServices
      .getTemplateDetailsById(id)
      .then((response) => {
        if (response.status === 200) {
          setLoader(false);
          setContent(response.data.content);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    } else  if (id == 0 ){
      setContent('')
    }
   
  };
  return (
    <>
      {email == "" ? (
        <div style={{ marginLeft: "20px" }}>
          <h4 style={{ color: "red" }}>
            {" "}
            Please add email for this user and try again !
          </h4>
        </div>
      ) : (
        ""
      )}
      <div>
        {loader == true ? (
          <Loader />
        ) : ( 
          <> 
          <form ref={form} onSubmit={handleSendEmail} className="editorForm">
            <div className="form-group" style={{ maxWidth: 800,marginBottom:20 }}>
              <label>
                To : {backLocation === "Group" ? ` Group - ${groupName} ` : ""}
              </label>
              {backLocation === "Group" ? "" : (<input
                className="form-control"
                type="email"
                name="email"
                value={email}
                disabled
              />)}
            </div>
            <div
              className="form-group"
              style={{ maxWidth: 800, marginBottom: "20px" }}
            >
              <label>Subject</label>
              <input
                className="form-control"
                type="text"
                name="subject"
                onChange={handleFormChange}
                value={subject}
              />
              {/* {validator.message(
                "Subject",
                subject,
                "required|max:70",
                { className: "text-danger" }
              )} */}
            </div>
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  id="custom"
                  class={
                    currentTab == "custom" ? "nav-link active" : "nav-link"
                  }
                  aria-current="page"
                  href="#"
                  onClick={handleTabs}
                >
                  Custom
                </a>
              </li>
              <li class="nav-item">
                <a
                  id="template"
                  class={
                    currentTab == "template" ? "nav-link active" : "nav-link"
                  }
                  href="#"
                  onClick={handleTabs}
                >
                  Template
                </a>
              </li>
            </ul>
            {currentTab == "custom" ? (
              <div className="form-group" style={{ maxWidth: 800 }}>
                <label>Message</label>

                <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  onBlur={(newContent) => setContent(newContent)}
                  onChange={(newContent) => {}}
                  name="content"
                  required = {true}
                />
                {/* {validator.message(
                "Message",
                content,
                "required",
                { className: "text-danger" }
              )} */}
              </div>
            ) : (
              <div
                className="selectTemplate"
                style={{
                  maxWidth: "700px",
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  height: "250px",
                  padding: "10px",
                }}
              >
                <div className="project-filter-dropdown mb-3 align-items-center">
                  <DropDown
                    name="titleId"
                    firstOption={{ lable: "Select template", value: 0 }}
                    options={templateDropdown}
                    onChange={(e) => handleOnChange(e)}
                    defaultSelected={selectedTemplate}
                    lable=""
                    value={selectedTemplate}
                  />
                </div>
                <div>
                  <label>Message :</label>
                  <p> {content} </p>
                </div>
              </div>
            )}

            <div className="form-group" style={{ width: 700, marginTop: 10 }}>
              <input
                type="submit"
                value="Send Mail"
                className="btn btn-primary waves-effect waves-light"
                disabled={email == ""}
              />
            </div>
          </form>
          </>
        )}
      </div>
    </>
  );
};

export default Editor;
