import React from 'react'
import {Form} from 'react-bootstrap';

export default function DropDown(props) {
    const {name,firstOption,options,onChange,defaultSelected,lable} = props;
    return (
        <div>
            <Form.Group controlId="exampleForm.ControlSelect1" >
                { lable && lable !== "" && <Form.Label>{lable}</Form.Label> }
                <Form.Control as="select" className="form-select" name={name} onChange={(e)=>onChange(e)} value={defaultSelected} >
                {firstOption && Object.keys(firstOption).length > 0 &&
                     <option value={firstOption.value}>{firstOption.lable}</option>
                }
                {
                    options && options.length > 0 && options.map((data,i) =>{
                        return <option key={i} value={data.value}>{data.lable}</option> 
                    })
                }
                </Form.Control>
            </Form.Group>
            
        </div>
    )
}
