//----------------IMPORT SECTION STARTS HERE------------------

//React Parent Component------------------------------------
import React, { Component } from "react";

// Bootstrap Table and Pagination---------------------------
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

// Link tab for page redirection----------------------------
import { Link } from "react-router-dom";

// All API Calls handled here-------------------------------
import projectServices from "../../../services/projectServices";

// date format----------------------------------------------
import { dateFormat } from "../../../../helpers/Util";

//----------------IMPORT SECTION ENDS HERE------------------

class ShopMaintenanceHistory extends Component{
    constructor(props){
        super(props);
        this.state = {
            itemID: this.props.location.state.itemID,
            itemName:this.props.location.state.itemName,
            historyList:[],
            maintenanceHistoryDisplayListArr:[]
        }
    }

    //----------------------------------------------------------------------//
    componentDidMount = () => {
        this.getShopHistoryList(this.state.itemID);
    }

    //----------------------------------------------------------------------//

    getShopHistoryList = (shopMasterID) => {
        projectServices.getShopHistoryList(shopMasterID).then((response) => {
            this.setState({historyList:response.data.data});
            this.getMaintenanceHistoryData();
        }).catch((error) => {
            console.log(error);
        })
    }

    //----------------------------------------------------------------------//
    
    actionFormat = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <button title="view history" className="uil uil-eye text-dark action-btn" onClick={() => {this.props.history.push({pathname:'/maintenance/history/details',state:row})}}>View</button>
            </div>    
        )
    }

    // Populate details item list against each selected shop in proper format.
    getMaintenanceHistoryData=()=>{
        const {historyList} = this.state;
        let newDataArray         = historyList.map((item)=>{
            item.serviceInterval = item.serviceInterval;
            item.serviceDate     = dateFormat(item.serviceDate);
            item.lastDate        = item.lastDate !== null ? dateFormat(item.lastDate)  : 'N/A';
            item.nextDate        = item.nextDate !== null ? dateFormat(item.nextDate)  : 'N/A';
            item.status          = (
                                    item.status === "PENDING" ? "Pending" : 
                                    item.status === "INPROGRESS" ? "In Progress" : 
                                    item.status === "DONE" ? "Done" : 
                                    item.status === "OVERDUE" ? "Overdue" :'N/A' 
                                  );
            return item;
        })
        this.setState({maintenanceHistoryDisplayListArr:newDataArray});
    }

    //----------------------------------------------------------------------//    

    render(){
        const {maintenanceHistoryDisplayListArr, itemName} = this.state;
        let sInterval = '';
        let lDate     = '';
        let nDate     = '';
        if(maintenanceHistoryDisplayListArr.length != 0){
            let newArr = maintenanceHistoryDisplayListArr[maintenanceHistoryDisplayListArr.length - 1];
            sInterval = newArr.serviceInterval;
            lDate     = (newArr.lastDate != '' && newArr.lastDate != 'N/A' ? newArr.lastDate : 'mm/dd/yyyy');
            nDate     = (newArr.nextDate != '' && newArr.nextDate != 'N/A' ? newArr.nextDate : 'mm/dd/yyyy');
        }

        const handleOnTableChange = (type, newState) =>{
            console.log("type",type);
            if(type === "sort"){
                if(newState.sortOrder == "asc"){
                    maintenanceHistoryDisplayListArr.sort((obj1,obj2) => {
                   return  obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim())
                   ;     
                }); 
              }
                else
                {maintenanceHistoryDisplayListArr.sort((obj2,obj1) => {
                    return obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim())
                    ; 
                 });
                }
            }
           
        }

        const columns  = [{
            dataField: "serviceDate",
            text: "Service Date",
            sort:true
        },{
            dataField: "status",
            text: "Status",
            sort:true
        },{
            dataField: "comments",
            text: "Comments",
            sort:true
        },{
            dataField: "serviceBy",
            text: "Serviced By",
            sort:true
        },{
            dataField: "",
            text: "Action",
            formatter: this.actionFormat,
        }];
        return (
            <>
                <div className="main-content shopPersonal">
                        <div className="page-content">
                            <div className="container-fluid">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <ol className="breadcrumb m-0">
                                    <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                                    &nbsp; / &nbsp;
                                    <Link to={`/maintenance`}><li className="breadcrumb-item">Shop Maintenance</li></Link>    
                                    &nbsp; / &nbsp;
                                    <Link to={`/maintenance/history`}><li className="breadcrumb-item">History</li></Link>    
                                </ol>
                            </div>
                            <h4 className="mb-0 text-left">Service History 
                                <i className="uil uil-angle-right-b"></i> {itemName}
                            </h4>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="project-scope-block">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label className="col-md-8">Service Interval</label>
                                                    <input readOnly placeholder="Service Interval" type="text" className="form-control col-md-2" name="serviceInterval" value={sInterval}/>
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="col-md-8">Last Service</label>
                                                    <input readOnly type="text" placeholder="mm/dd/yyyy" className="form-control col-md-2" name="lastService" value={lDate}/>
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="col-md-8">Next Service</label>
                                                    <input readOnly type="text" placeholder="mm/dd/yyyy" className="form-control col-md-2" name="nextService" value={nDate}/>
                                                </div>
                                            </div>
                                                <div className="">
                                                    <hr/>
                                                    <BootstrapTable
                                                        keyField="id"
                                                        data={maintenanceHistoryDisplayListArr}
                                                        columns={columns}
                                                        onTableChange={handleOnTableChange}
                                                        pagination={paginationFactory({ sizePerPage: 10})}
                                                        striped
                                                        hover
                                                        noDataIndication={<p style={{color:"red"}}>{'No Data Found'}</p>}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ShopMaintenanceHistory;