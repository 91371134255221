import React, { Component } from "react";
// import AddEmployeePopUp from "./AddEmployeePopUp";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../common/Loader";
import { rules, Permission } from "../../abac.config";
import { Link } from "react-router-dom";
import { createNotification } from "../helpers/Notification";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination from "../project/component/Pagination/Pagination";
import BootstrapTable from "react-bootstrap-table-next";
import ModalPopUp from "../common/ModalPopUp";
import { dbDateFormat } from "../helpers/Util";
import projectServices from "../project/services/projectServices";
import AccessDenied from "../common/AccessDenied";
import ConfirmBox from "../common/ConfirmBox";
import { AllowedTo } from "react-abac";
// import RecentBirthdayPopUp from "./RecentBirthdayPopUp";
import { saveAs } from "file-saver";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class EmployeeContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowConfirmBox: false,
      loader: false,
      removeId: 0,
      isOpenAddEmployeePop: false,
      isOpenBirthdayPop: false,
      humanResourceDisplayList: [],
      search:"",

      //pagination constants
      pageChange: 1,
      skip: 0,
      limit: 10,
      count: 0,
    };
  }

  componentDidMount = () => {
    // console.log("pageChange",this.state.pageChange);
    this.getEmpData();

  };
 getEmpData=()=>{
 
  localStorage.removeItem('AllGroupListPagination');
  let filters;
  // console.log(window.history.state.state);
  // if(window.history.state.state !== undefined){
   
  //   const currentPage = window.history?.state?.state?.data.key!==undefined? window.history?.state?.state?.data.key:this.state.pageChange;
  //   const currentSkip = window.history?.state?.state?.data.keySkip!==undefined? window.history?.state?.state?.data.keySkip:this.state.skip;
  //   const currentLimit=window.history?.state?.state?.data.keyLimit!==undefined? window.history?.state?.state?.data.keyLimit:this.state.limit;
  //  const currentSearch = window.history?.state?.state?.data.keySearch!==undefined? window.history?.state?.state?.data.keySearch:this.state.search;
  
  //   this.setState({ pageChange:currentPage, skip:currentSkip,limit:currentLimit,search:currentSearch},()=>{
  //     filters = `?filter[skip]=${currentSkip}&filter[limit]=${currentLimit}`;
  //     if(this.state.search.length>0){
  //       this.getSearchedHumanResourceEmployee(filters)
  //     }else{
  //       this.getHumanResourceCount(filters);
  //     }
  //   },
      
  //   );
   
  // //  this.getHumanResourceCount(filters);
  // }
  // else 
  if(JSON.parse(localStorage.getItem('empConPagination'))!==null){
    const empContactPagination= JSON.parse(localStorage.getItem('empConPagination'));
    const{previousLimit,previousPage,previousSkip,previousSearch}=empContactPagination;
    // alert(21)
    this.setState({pageChange:previousPage,limit:previousLimit,skip:previousSkip,search:previousSearch},()=>{
      filters = `?filter[skip]=${previousSkip}&filter[limit]=${previousLimit}`;
      if(this.state.search!==""){
        this.getSearchedHumanResourceEmployee(filters)
      }else{
        this.getHumanResourceCount(filters);
      } 
    })
   
   
  }
  else if(JSON.parse(localStorage.getItem('EmployeeContactPagination'))!==null){
  
    const empContactPagination= JSON.parse(localStorage.getItem('EmployeeContactPagination'));
    // console.log(empContactPagination);
    const{previousLimit,previousPage,previousSkip,search}=empContactPagination;
  //  alert(321)
    this.setState({pageChange:previousPage,limit:previousLimit,skip:previousSkip,search:search},()=>{
      filters = `?filter[skip]=${previousSkip}&filter[limit]=${previousLimit}`;
      if(this.state.search!==""){
        this.getSearchedHumanResourceEmployee(filters)
      }else{
        this.getHumanResourceCount(filters);
      } 
    })
    
   
  }
   else{

     filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
     this.getHumanResourceCount(filters);
  }
  
  
    localStorage.removeItem('shopMaintenancePaginationConstants');
    localStorage.removeItem('fieldMaintenancePaginationConstants')
    // this.getHumanResourceCount();
  
 }
  getHumanResourceCount = (filters) => {
    // console.log(filters);
    // let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    localStorage.removeItem('EmployeeContactPagination');
    localStorage.removeItem('empConPagination');
    let countReqParam = `where={}`;
    projectServices
      .getHumanResourceCount(countReqParam)
      .then((response) => {
        this.setState({ count: response.data.count }, () => {
          localStorage.removeItem('empConPagination');
          this.getAllHumanResourceEmployees(filters);
          
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          createNotification("error", "Your login session has expired .");
          this.props.history.push("/login");
        }
      });
  };

  getAllHumanResourceEmployees = (filters) => {
    const {search} = this.state;
    projectServices
      .getHumanResourcesEmployees(filters)
      .then((response) => {
        this.setState({
          humanResourceDisplayList: response.data,
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem('AllGroupListPagination');
        localStorage.removeItem('EmployeeContactPagination');
      });
  };

handleSearchUser = (event) => {
 
  let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
 if(event.target.name==="searchEmployee"){
  if(event.target.value!==""){
    // console.log("handleSearchUser in",event.target.value);
    this.setState({ search: event.target.value ,pageChange:1,skip:0,limit:10}, () => {
      this.getSearchedHumanResourceEmployee(filters);
    });
  }else{
   this.setState({search:"",pageChange:1,skip:0,limit:10},()=>{
    this.getHumanResourceCount(filters);
   })
    
  }
 }
}
getSearchedHumanResourceEmployee=(filters)=>{
 localStorage.removeItem('empConPagination')
 localStorage.removeItem('EmployeeContactPagination')
  // let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
projectServices.getSearchedHumanResourcesEmployees(this.state.search,filters).then((response)=>{
 
      this.setState({
        humanResourceDisplayList: response.data.data,
        count:response.data.count,
       loader:false
      });
})
// .catch((error)=>{
//   console.log(error);
// })
}

  handleCloseAddEmployeeModal = () => {
    this.setState({ isOpenAddEmployeePop: false });
  };
  handleConfirmModal = (isYes) => {
    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    if (isYes) {
      this.setState({
        isShowConfirmBox: !this.state.isShowConfirmBox,
        loader: true,
      });
      const { removeId } = this.state;
      projectServices
        .deleteHumanResourceEmployee(removeId)
        .then((response) => {
          createNotification(
            "success",
            "The employee item deleted successfully ."
          );
          this.setState({ pageChange: 1 });
          this.getHumanResourceCount(filters);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ removeId: 0, isShowConfirmBox: false, loader: false });
    }
  };
  actionFormat = (cell, row, rowIndex, formatExtraData) => {
    const {pageChange,skip,limit,search} = this.state;
    // console.log("pageChange,skip",pageChange,"<><><><><><><><>",skip,limit);
    const data = { key: "Single",currentPage:pageChange,currentSkip:skip,currentLimit:limit,search:search, };
    return (
      <div>
        <td>
          <ul className="list-inline mb-0">
            <OverlayTrigger
              key="topMail"
              placement="top"
              overlay={<Tooltip id={`tooltip-top`}>Send Mail and SMS</Tooltip>}
            >
              <li className="list-inline-item">
                <Link
                  to={{
                    pathname: `/sendMail/${row.id}`,
                    state: { data: data },
                  }}

                  // to={`/sendMail/${row.id}`}
                  // state= {{from:"singleMail"}}
                >
                  <i class="uil uil-envelope-upload font-size-18"></i>
                </Link>
              </li>
            </OverlayTrigger>
          </ul>
        </td>
      </div>
    );
  };

  handleRecentBirthdays = () => {
    this.setState({
      isOpenBirthdayPop: true,
    });
  };
  handleCloseRecentBirthdays = () => {
    this.setState({ isOpenBirthdayPop: false });
  };

  downloadHRReport = () => {
    // const{slocationId,isManuallyCreated,search,isSearch}=this.state;
    const URL = process.env.REACT_APP_API_URL;
    projectServices
      .getHumanResourceRecord()
      .then((res) => {
        if (res.data.status) {
          const fileLink = `${URL}${res.data.data}`;
          const pathArr = fileLink.split("/");
          saveAs(
            // `${process.env.REACT_APP_API_URL}'/'${fileLink}`
            fileLink,
            pathArr[pathArr.length - 1]
          );
          createNotification(
            "success",
            "Human Resource record downloaded successfully."
          );
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 422) {
          if (error.response.message) {
            createNotification("error", error.response.message);
          } else {
            createNotification("error", "No Human Resource data exists.");
          }
        }
      });
  };
  render() {
    let roleKey = this.props.loginUserData.roleKey;
    
    // console.log("roleKey", roleKey);
    const isAccess =
      roleKey !== "" && rules[roleKey][Permission.HUMANRESOURCE_WRITE]
        ? true
        : false;
    const {
      isShowConfirmBox,
      loader,
      humanResourceDisplayList,
      pageChange,
      limit,
      count,
      skip,search
    } = this.state;
    const data = { key: "Single",currentPage:pageChange,currentSkip:skip ,currentLimit:limit,search:search};
    const sizePerPageOptions = [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      // Add more options as needed
    ];

    //-Pagination Constant-----------------------------------------------------//
    let that = this;
    const pagination = paginationFactory({
      page: pageChange,
      sizePerPage: limit,
      lastPageText: ">>",
      firstPageText: "<<",
      nextPageText: ">",
      prePageText: "<",
      totalSize: count,
      // showTotal: true,
      alwaysShowAllBtns: true,
      sizePerPageList: sizePerPageOptions,
      onPageChange: function (page, sizePerPage) {
        let skp = (page - 1) * sizePerPage;
        let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
        that.setState({
          pageChange: page,
          skip:skp,
          humanResourceDisplayList: [],
          loader: true,
        });
       search.length>0 ?that.getSearchedHumanResourceEmployee(filters): that.getAllHumanResourceEmployees(filters);
      },
      onSizePerPageChange: function (sizePerPage, page) {
        let skp = (page - 1) * limit;
        // let skp =0;
        that.setState({
          pageChange: 1,
          skip:skp,
          limit: sizePerPage,
          humanResourceDisplayList: [],
          loader: true,
        });
        let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;

        search.length>0?that.getSearchedHumanResourceEmployee(filters): that.getAllHumanResourceEmployees(filters);
        // that.getHumanResourceCount();
      },
    });
    //------------------------------------------------------------------------//

    const handleOnTableChange = (type, newState) => {
      if(type === "sort"){
        if (newState.sortOrder === "asc") {
          humanResourceDisplayList.sort((obj1, obj2) => {
            // console.log("obj1",obj1.wage_date_change);
            return newState.sortField=="wage"?
            obj1[newState.sortField]-obj2[newState.sortField]:
  
            newState.sortField=="fkey_shopdesignation.designation" ? 
            (obj1.fkey_shopdesignation.designation
              .trim()
              .localeCompare(obj2.fkey_shopdesignation.designation.trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              }))  :
               newState.sortField=="fkey_title.name"  ? 
               (obj1.fkey_title.name
                .trim()
                .localeCompare(obj2.fkey_title.name.trim(), undefined, {
                  numeric: true,
                  sensitivity: "base",
                })) :
             (!obj1[newState.sortField] == null ? 
              obj1[newState.sortField].trim()
              .localeCompare(obj2[newState.sortField].trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              }) : (obj1[newState.sortField]
              .localeCompare(obj2[newState.sortField].trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              })))
          });
        } else {
          humanResourceDisplayList.sort((obj2, obj1) => {
            // console.log("obj1",obj1.wage_date_change);
            return   newState.sortField =="wage" ?
            obj1[newState.sortField]-obj2[newState.sortField]:
  
             newState.sortField =="fkey_shopdesignation.designation" ? 
             (obj1.fkey_shopdesignation.designation
              .trim()
              .localeCompare(obj2.fkey_shopdesignation.designation.trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              })) : 
              newState.sortField =="fkey_title.name" ? 
             ( obj1.fkey_title && obj1.fkey_title.name
              .trim()
              .localeCompare(obj2.fkey_title.name.trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              })) 
               :  
               (!obj1[newState.sortField] == null ? 
               obj1[newState.sortField].trim()
              .localeCompare(obj2[newState.sortField].trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              }): (obj1[newState.sortField]
              .localeCompare(obj2[newState.sortField].trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              })) )
          });
        }

      }
    };

    const CustomDateFormatter = (cell) => {
      const dateObject = new Date(cell);
      const formattedDate = dateObject.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC", // Make sure to set the correct timezone
      });

      return <span>{formattedDate}</span>;
    };

    const columns = [
      {
        dataField: "employee_name",
        text: "Name",
        sort: true,
        headerStyle: () => {
          return { verticalAlign: "top" };
        },
      },
      {
        dataField: "phone_number",
        text: "Phone",
        sort: true,
        headerStyle: () => {
          return { verticalAlign: "top" };
        },
      },
      {
        dataField: "email_address",
        text: "Email",
        sort: true,
        headerStyle: () => {
          return { verticalAlign: "top" };
        },
      },
      {
        // dataField: "shopDesignationId",
        dataField: "fkey_shopdesignation.designation",
        text: "Shop  Designation",
        sort: true,
        headerStyle: () => {
          return { width: "10%", verticalAlign: "top" };
        },
      },
      {
        // dataField: "titleId",
        dataField: "fkey_title.name",
        text: "Title",
        sort: true,
        headerStyle: () => {
          return { width: "10%", verticalAlign: "top" };
        },
      },
      {
        dataField: "",
        text: "Action",
        formatter: this.actionFormat,
        headerStyle: () => {
          return { width: "30%", verticalAlign: "top" };
        },
      },
    ];
  //   <Link
  //   to={{
  //     pathname: `/sendMail/${row.id}`,
  //     state: { data: data },
  //   }}

  //   // to={`/sendMail/${row.id}`}
  //   // state= {{from:"singleMail"}}
  // >
    return (
      <div>
        {loader === true ? (
          <Loader />
        ) : (
          <div className="main-content shopPersonal">
            <ConfirmBox
              openModal={isShowConfirmBox}
              confirmMsg="Are you sure to delete this human resource employee?"
              handleConfirmModal={this.handleConfirmModal}
            />
            <div className="page-content">
              <div className="container-fluid">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <ol className="breadcrumb m-0">
                    <Link to={`/#`}>
                      <li className="breadcrumb-item">Home</li>
                    </Link>
                    &nbsp; / &nbsp;
                    <Link to={`/employeeContact`}>
                      <li className="breadcrumb-item">Employee Contact</li>
                    </Link>
                  </ol>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-4 mb-3"></div>
                  <AllowedTo perform={Permission.HUMANRESOURCE_WRITE}>
                    <div className="d-flex justify-content-between  mt-3 mb-3">
                      <div className="project-button-box">
                        {/* <button className="btn btn-primary"  onClick={this.handleOpenAddEmployee}>
                            <i className="uil uil-plus"></i> Add Employee
                          </button> */}
                        {/* <Link to="/addGroup"> */}
                       <Link to={{
                          pathname:"/addGroup",
                          state:{data}
                          }}>
                          <span className="btn btn-primary">
                            <i className="uil uil-plus"></i> New Group
                          </span>
                        </Link>
                      </div>
                      <div className="project-button-box">
                        <Link to={{
                          pathname:"/allGroupList",
                          state:{data}
                          }}>
                          <span className="btn btn-primary">
                            <i className="uil "></i> All Group
                          </span>
                        </Link>
                      </div>
                      <div className="project-button-box ">
                        <Link to="/templateList">
                          <span className="btn btn-primary">
                            <i className="uil "></i> Templates
                          </span>
                        </Link>
                      </div>
                    </div>
                  </AllowedTo>

                  {loader === true ? (
                    <Loader />
                  ) : (
                    <div className="mt-3">
                      <div className="card card-block">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-12 table-responsive">
                              <div className="col-md-12">
                                <div className="form-inline float-md-end mb-3">
                                  <div className="search-box ms-2">
                                    <div className="position-relative">
                                      <input
                                        name="searchEmployee"
                                        type="text"
                                        className="form-control rounded bg-light border-0"
                                        placeholder="Search..."
                                        value={this.state.search}
                                        onChange={(e) =>
                                          this.handleSearchUser(e)
                                        }
                                      />
                                      <i className="uil uil-search search-icon"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-2 ">
                                <BootstrapTable
                                  remote={true}
                                  keyField="id"
                                  data={
                                    humanResourceDisplayList.length > 0
                                      ? humanResourceDisplayList
                                      : []
                                  }
                                  onTableChange={handleOnTableChange}
                                  columns={columns}
                                  pagination={pagination}
                                  noDataIndication={
                                    loader === true ? (
                                      <Loader />
                                    ) : (
                                      <p style={{ color: "red" }}>
                                        {"No Data Found"}
                                      </p>
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /* model to open items in each project and make them editable */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (props) => {
  return {
    projectData: props.projectData,
    selectedScopeItemID: props.selectedScopeItemID,
    loginUserData: props.loginUserData,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeContact);
