import moment from "moment";
import React, { Component, Suspense } from "react";
import Tab from "react-bootstrap/Tab";
import Loader from "../../common/Loader";
import projectServices from "../services/projectServices";
import { dateFormat, getRoleKey } from "../../helpers/Util";
import Tabs from "react-bootstrap/Tabs";
import ProjectDocuments from "./ProjectDocuments";
import { saveAs } from "file-saver";
import { rules, Permission } from "../../../abac.config";
import { AllowedTo } from "react-abac";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  setJobNumber,
  setProjectData,
  setProjectName,
} from "../redux/projectAction";
const ProjectDetail = React.lazy(() => import("./ProjectDetail"));
const DailyWorkTicket = React.lazy(() => import("./DailyWorkTicket"));
class ProjectView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      projectName: "",
      description: "",
      scopeItemData: [],
      jobNumber: "",
      CreateProjectTxt: "Create Project",
      projectTitle: "",
      isDailyWorkingTicketTabFlag: false,
      search: "",
    };
  }

  handleOnEditClick = () => {
    const { projectId } = this.props.match.params;
    this.props.history.push(`/project/edit/${projectId}`);
  };

  formatScopeItem = (scopeArr) => {
    let itemArr = [];
    scopeArr.length &&
      scopeArr.forEach((item) => {
        itemArr.push({ id: item.id, title: item.title });
      });
    return itemArr;
  };

  componentDidMount = () => {
    if (this.props.isModalOpen) {
      this.setState({ CreateProjectTxt: "Go to project list" });
    }
    localStorage.removeItem("calendarName");
    this.getProjectDetail();
  };

  handleCreateProjectBtn = () => {
    if (this.props.isModalOpen) {
      const { projectId } = this.props.match.params;
      this.props.history.push(`/project`);
    } else {
      this.props.history.push(`/project/add`);
    }
  };

  getProjectDetail = () => {
    const { projectId } = this.props.match.params;
    const { setJobNumber, setProjectName } = this.props;
    projectServices
      .getProjectById(projectId)
      .then((res) => {
        const {
          manager,
          jobNumber,
          startDate,
          endDate,
          description,
          scopeItemData,
          title,
          projectStatus,
        } = res.data;
        this.setState({
          jobNumber: String(jobNumber),
          startDate: startDate,
          endDate: endDate,
          projectName: title,
          description: description,
          projectTitle: title,
          scopeItemData: scopeItemData,
          // this.formatScopeItem(scopeItemData),
        });

        setJobNumber(String(jobNumber));
        setProjectName(title);
      })
      .catch((error) => {});
  };

  downloadReport = () => {
    const { projectId } = this.props.match.params;
    projectServices
      .getProjectReport(projectId)
      .then((res) => {
        if (res.data.status) {
          const fileLink = res.data.data;
          const pathArr = fileLink.split("/");
          saveAs(
            `${process.env.REACT_APP_API_URL}${fileLink}`,
            pathArr[pathArr.length - 1]
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleTabSelected = (key) => {
    if (key === "scopeItems") {
      this.setState({ isDailyWorkingTicketTabFlag: false });
    }
    if (key === "projectDocuments") {
      this.setState({ isDailyWorkingTicketTabFlag: false });
    }
    if (key === "dailyWorkTickets") {
      this.setState({ isDailyWorkingTicketTabFlag: true });
    }
  };
  handleSearchUser = (event) => {
    const {scopeItemData} = this.state
    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
   if(event.target.name==="search"){
    if(event.target.value!=="" ){
        this.setState({ search: event.target.value ,pageChange:1})
        this.getSearchedScopeItem(event.target.value,filters);
    }else{
     this.setState({search:""},()=>{
     this.getProjectDetail();
     })
      
    }
   }
  }
  getSearchedScopeItem=(searchValue,filters)=>{
    const { projectId } = this.props.match.params;
    // let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
  projectServices.getSearchedProjectById(projectId,searchValue,filters).then((response)=>{
        this.setState({
          scopeItemData: response.data,
        //   count:response.data.count,
         loader:false
        });
  }).catch((error)=>{
    console.log(error);
  })
  }

  render() {
    const { projectId } = this.props.match.params;
    let roleKey = this.props.loginUserData.roleKey;
    const {
      jobNumber,
      projectName,
      startDate,
      endDate,
      description,
      scopeItemData,
      CreateProjectTxt,
      projectTitle,
      isDailyWorkingTicketTabFlag,
      search    
    } = this.state;
    const editButtonLable =
      roleKey !== "" && rules[roleKey][Permission.PROJECT_WRITE]
        ? "Edit Project"
        : "View Project";
    const deleteButtonLable =
      roleKey !== "" && rules[roleKey][Permission.PROJECT_DELETE]
        ? "Delete Project"
        : "";
    return (
      <div>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <ol className="breadcrumb m-0">
                  <Link to={`/#`}>
                    <li className="breadcrumb-item">Home</li>
                  </Link>
                  &nbsp; / &nbsp;
                  <Link to={`/project`}>
                    <li className="breadcrumb-item">Projects</li>
                  </Link>
                  &nbsp; / &nbsp;
                  <Link to={`/project/project-view/${projectId}`}>
                    <li className="breadcrumb-item">Job # - {jobNumber}</li>
                  </Link>
                </ol>
              </div>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">{projectName}</h4>
                <div className="project-button-box">
                  <button
                    type="button"
                    onClick={() => this.handleOnEditClick()}
                    className="btn btn-primary waves-effect waves-light btn-create"
                  >
                    <i className="uil uil-edit"></i> {editButtonLable}
                  </button>
                  <AllowedTo perform={Permission.PROJECT_WRITE}>
                    <button
                      type="button"
                      className="btn btn-success waves-effect waves-light btn-create"
                      onClick={() => this.handleCreateProjectBtn()}
                    >
                      <i className="uil uil-plus"></i>
                      {CreateProjectTxt}
                    </button>
                  </AllowedTo>
                  {/* <AllowedTo
                                    perform={Permission.PROJECT_DELETE}>
                                        <button type="button" className="btn btn-danger waves-effect waves-light btn-create" onClick={() => this.handleDeleteProjectBtn()}>
                                         <i className=""></i>{deleteButtonLable}
                                         </button> 
                                    </AllowedTo> */}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body bgW">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">Description</label>
                            <input
                              type="text"
                              className="form-control"
                              value={description}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">Start Date</label>
                            <input
                              className="form-control"
                              type="text"
                              value={dateFormat(startDate)}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="mb-3">
                            <label className="form-label">End Date</label>
                            <input
                              className="form-control"
                              type="text"
                              value={dateFormat(endDate)}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body bgW">
                      <Tabs
                        defaultActiveKey="scopeItems"
                        transition={false}
                        id="noanim-tab-example"              
                        className="mb-3"
                        onSelect={this.handleTabSelected}
                      >
                        
                        <Tab eventKey="scopeItems" title="Scope Items">
                        <div className="col-md-2">
                            <div className="form-inline">
                              <div>
                                <div className="autocomplete-block">
                                  <input
                                    autoComplete="off"
                                    className="search-inventory-text form-control"
                                    type="text"
                                    name="search"
                                    value={this.state.search}
                                    placeholder="Search..."     
                                    onChange={(e) => this.handleSearchUser(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <Suspense fallback={<Loader />}>
                            {scopeItemData.length > 0 && (
                              <ProjectDetail
                                projectId={projectId}
                                props={this.props}
                                scopeItemData={scopeItemData}
                                selectedScopeId={
                                  this.props.selectedScopeItemID === 0
                                    ? scopeItemData[0].id
                                    : this.props.selectedScopeItemID
                                }
                              />
                            )}
                          </Suspense>
                        </Tab>
                        <Tab
                          eventKey="projectDocuments"
                          title="Project Documents"
                        >
                          <ProjectDocuments
                            projectId={projectId}
                            props={this.props}
                            isModalOpen={this.props.isModalOpen}
                          />
                        </Tab>
                        <Tab
                          eventKey="dailyWorkTickets"
                          title="Daily Work Tickets"
                        >
                          <Suspense fallback={<Loader />}>
                            <DailyWorkTicket
                              projectId={projectId}
                              props={this.props}
                              jobNumber={jobNumber}
                              projectTitle={projectTitle}
                              isDailyWorkingTicketTabFlag={
                                isDailyWorkingTicketTabFlag
                              }
                            />
                          </Suspense>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
    selectedScopeItemID: props.selectedScopeItemID,
    selectedJobNumber: props.selectedJobNumber,
    projectName: props.projectName,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setJobNumber: setJobNumber,
      setProjectData: setProjectData,
      setProjectName: setProjectName,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectView);
