import axios from 'axios';
import React, { Component } from 'react'
import {createNotification} from '../helpers/Notification';
import Loader from '../common/Loader';
import {checkValidPasswpord,isImage,checkImageSize,getApiUrl} from '../helpers/Util';
import { FaPen } from 'react-icons/fa';
import SimpleReactValidator from 'simple-react-validator';

import { setLoginUserData } from '../project/redux/projectAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import projectServices from  '../project/services/projectServices';

class UserProfile extends Component {
    constructor(props){
        super(props);
        this.state = {
            token:"",
            name:"",
            email:"",
            phoneNum:"",
            organization:1,
            country:"",
            userId:"",
            password:"",
            confirmPassword:"",
            loader:false,
            selectedFile: null,
            imagePreviewUrl: null,
            onchangeImg:false,
            dbImage:null
        }
        this.validator = new SimpleReactValidator();
    }

    static getDerivedStateFromProps(props, state) {
        const token = localStorage.getItem("user-token");
        const userId = localStorage.getItem("userId");
        return {token,userId };
    }

    fileChangedHandler = event => {
        if (isImage(event.target.files[0].name)) {
            if (checkImageSize(event.target.files[0].size)) {
                this.setState({
                    selectedFile: event.target.files[0]
                })
                let reader = new FileReader();

                reader.onloadend = () => {
                    this.setState({
                        imagePreviewUrl: reader.result,
                        onchangeImg: true
                    });
                }

                reader.readAsDataURL(event.target.files[0])
            } else {
                createNotification("info", "Maximum limit to upload is 5 MB");
            }
        } else {
            createNotification("info", "Please select valid image format!");
        }
    }
 
    onCancelImg = () => {
        this.setState({
            imagePreviewUrl: null,
            onchangeImg:false
        });
    } 

    onSaveImg = () =>{
        const { setLoginUserData, loginUserData } = this.props;
        let loginUserDataClone = { ...loginUserData };

        const {token}  = this.state;
        const headers = {
            "Authorization":`Bearer ${token}`
          }
          this.setState({loader:true});
          const URL =  getApiUrl();
          const url = `${URL}/profileUpload`;
          var fd = new FormData();
 
          fd.append('profileImage', this.state.selectedFile);
          axios.post(url, fd,{
              headers: headers
            })                                                                            
            .then((response) => {
            
              this.setState({
               onchangeImg:false,
               loader:false,
               imagePreviewUrl: null,
               dbImage:response.data.data.filePath
              });

              localStorage.setItem('profileImage',response.data.data.filePath);
              setLoginUserData({ ...loginUserDataClone,profileImage: response.data.data.filePath});
            })
            .catch((error) => {
               
                if(error.response.status === 401){
                    createNotification("error", "Your login session has been expired!");
                    localStorage.clear();
                    this.props.history.push('/login');
                    this.setState({loader:false});
                }else{
                    createNotification("error", error.response.data.error.message);
                    this.setState({loader:false});
                }
            })

    }

    componentDidMount = () => {
        this.getLoginUserData();
    }

    handleOnChange = (event) => {
       
        if (event.target.name === "password") {
            this.setState({ password: event.target.value });
        } else if (event.target.name === "confirmPassword") {
            this.setState({ confirmPassword: event.target.value });
        }else if (event.target.name === "name"){
            this.setState({ name: event.target.value });
        }else if (event.target.name === "email"){
            this.setState({ email: event.target.value });
        }else if (event.target.name === "phone"){
            this.setState({ phoneNum: event.target.value });
        }else if (event.target.name === "organization"){
            this.setState({ organization: event.target.value });
        }else if (event.target.name === "country"){
            this.setState({ country: event.target.value });
        }
    }

    checkInputData = () =>{
        const {name, email,phoneNum,password, confirmPassword,organization,country} = this.state;
        let isValidate = true;
        if (name === "") {
            createNotification("error", "User name is required!");
            isValidate = false;
        }else if(email === ""){
            createNotification("error", "Email is required!");
            isValidate = false;
        } 
        else if(phoneNum === ""){
            createNotification("error", "Phone Number is required!");
            isValidate = false;
        }
        return isValidate;
      }

    getLoginUserData = () => {
        try{
          projectServices.getUserDetailByToken().then((res) => {
            if(res.data.status){
            let result = res.data.data;
            this.setState({name:result.name,email:result.email,phoneNum:result.phone,dbImage:result.profileImage});
                
             }
        }).catch((error) => {
                if(error.response.status === 401){
                    createNotification("error", "Your login session has been expired!");
                    localStorage.clear();
                    this.props.history.push('/login');
                    this.setState({loader:false});
                }else{
                    createNotification("error", error.response.data.error.message);
                    this.setState({loader:false});
                }
            })
        } catch(error) {
          console.log({error})
        }
       
    }

    handleEditUser = () =>{
        const {name, email,phoneNum,userId,organization,country,password,confirmPassword} = this.state;
        const { setLoginUserData, loginUserData } = this.props;
        let loginUserDataClone = { ...loginUserData };

        if (this.validator.allValid()) {
       
            if(password !== "" && confirmPassword === ""){
                createNotification("error", "Confirm Password is required!");
                //isValidate = false;
                return false;
            }else if(password !== "" && confirmPassword !== password){
                createNotification("info", "Confirm Password and Password do not match!");
                return false;
            }
            else if(password !== "" && confirmPassword !== "" && !checkValidPasswpord(password)){
                createNotification("info", "Passwords must be 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character!");
                return false;
             }
            const token = this.state.token;   
            const headers = {
                "Content-Type":"application/json",
                "Accept":"application/json",
                "Authorization":`Bearer ${token}`
              }
              this.setState({loader:true});
              const URL =  getApiUrl();
              const url = `${URL}/edit-profile`;
          
              //const { password, confirmPassword, } = this.state;
              
              let data = {name, email,phone:phoneNum ? phoneNum:"",organization:Number(organization),status:"Active"};
              if(password !==""){
                data.password = password;
              }
              axios.patch(url, data, {
                  headers: headers
                })                                                                            
                .then((response) => {
                  if(response.status === 200){
                     
                      createNotification("success", "Profile Updated successfully!");
                      setLoginUserData({ ...loginUserDataClone, userName: name});
                      localStorage.setItem('userName',name);
                      //this.handleResetState();
                      this.setState({loader:false});
                   }
                })
                .catch((error) => {
                    if(error.response.status === 401){
                        createNotification("error", "Your login session has been expired!");
                        localStorage.clear();
                        this.props.history.push('/login');
                        this.setState({loader:false});
                    }else{
                        createNotification("error", error.response.data.error.message);
                        this.setState({loader:false});
                    }
                })
       // }
        }else{
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
    const {loader,onchangeImg,imagePreviewUrl,dbImage} = this.state;
    const imgUrl = imagePreviewUrl ? imagePreviewUrl : dbImage ;
    let imagePreview = (<div className="imagePreview"></div>);
    if (imgUrl) {
       imagePreview = (<div className="imagePreview" style={{"background-image": `url(${imgUrl})`}} >
           </div>);
    }
        return (
            <div>
            {loader ? <Loader /> :
            <div className="main-content addUser-form">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">My profile</h4>
                            
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card card-block">
                                    <div className="card-body">
                                        <div className="wizard clearfix">
                                        <div className="content clearfix">
                                                    <form className="form-project ">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-3 imgUp uploadFile-image">
                                                                <div className="mb-3 uploadImgWrap">
                                                                   { imagePreview }
                                                                    <label className="btn btn-primary">
                                                                        <FaPen />
                                                                        <input type="file" className="uploadFile img" value=""
                                                                            style={{ width: "0px", height: "0px", overflow: "hidden" }} onChange={this.fileChangedHandler}  />
                                                                    </label>
                                                                </div>


                                                            </div>
                                                            {onchangeImg &&
                                                                <>
                                                                <div className="row">
                                                            <div className="col-sm-12 col-md-2">
                                                                <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => this.onSaveImg()} >
                                                                <i className="uil uil-save"></i> Save
                                                                </button>
                                                                </div>
                                                                <div className="col-sm-12 col-md-2">
                                                                <button type="button" className="btn btn-primary waves-effect waves-light"  onClick={this.onCancelImg} >
                                                                    <i className="uil uil-cancel"></i> Cancel
                                                                </button>
                                                                </div>
                                                                </div>
                                                                <div style={{marginBottom:
                                                                "10px"}}>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-4 col-sm-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label" for="validationCustom01">Name <span style={{color: 'red'}}>*</span></label>
                                                                    <input type="text" className="form-control" value={this.state.name}
                                                                     name="name" onChange={ this.handleOnChange} placeholder="Name"
                                                                        required="" />
                                                                        {this.validator.message('name', this.state.name, 'required|max:50', { className: 'text-danger' })}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-sm-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label" for="validationCustom01">Email ID <span style={{color: 'red'}}>*</span></label>
                                                                    <input type="text" className="form-control" value={this.state.email} 
                                                                    name="email" onChange={this.handleOnChange} placeholder="Email"
                                                                       disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                        <div className="col-md-4 col-sm-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label" for="validationCustom02">Phone Number <span style={{color: 'red'}}>*</span></label>
                                                                    <input type="text" className="form-control" id="validationCustom02" 
                                                                    name="phone"
                                                                    value={this.state.phoneNum}  onChange={this.handleOnChange} placeholder="Phone number"
                                                                       // required=""
                                                                         />
                                                                         {this.validator.message('Phone Number', this.state.phoneNum, 'required|numeric|min:10|max:10', { className: 'text-danger' })}
                                                                </div>
                                                            </div>
                                                       
                                                            {/* <div className="col-md-4 col-sm-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label" for="validationCustom01">Company</label>
                                                                    <input type="text" className="form-control" id="validationCustom01" 
                                                                    name="organization" 
                                                                    value={this.state.organization}  onChange={this.handleOnChange}placeholder="Company"
                                                                        required="" />
                                                                </div>
                                                            </div> */}
                                                            {/* <div className="col-md-4 col-sm-12">
                                                                <div className="mb-3">

                                                                    <label className="form-label" for="validationCustom02">User Role</label>
                                                                    <select className="form-select" aria-label="Default select example">
                                                                        <option selected>Open this select menu</option>
                                                                        <option value="1">One</option>
                                                                        <option value="2">Two</option>
                                                                        <option value="3">Three</option>
                                                                    </select>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        <div className="row">
                                                            {/* <div className="col-md-4 col-sm-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label" for="validationCustom01">Country</label>
                                                                    <input type="text" className="form-control"  value={this.state.country} 
                                                                    name="country" onChange={this.handleOnChange}placeholder="Country"
                                                                        required="" />
                                                                </div>
                                                            </div> */}

                                                        </div>

                                                    </form>
                                                </div>
                                            <div className="actions clearfix">
                                                {/* <ul role="menu" aria-label="Pagination">
                                                    <li className="disabled" aria-disabled="true"><a href="#previous" role="menuitem">Previous</a></li>
                                                    <li aria-hidden="false" aria-disabled="false" className="" ><a href="detail-drawing.html"
                                                        role="menuitem">Next</a></li>
                                                    <li aria-hidden="true" style={{ display: "none" }}><a href="#finish" role="menuitem">Finish</a></li>
                                                </ul> */}
                                                 <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() =>this.handleEditUser()}>
                                    <i className="uil uil-save"></i> Update
                                </button>
                                {/* <button type="button" className="btn btn-primary waves-effect waves-light">
                                    <i className="uil uil-edit"></i> Save
                                    </button> */}
                                {/* <button type="button" className="btn btn-primary waves-effect waves-light">
                                    <i className="uil uil-edit"></i> Create
                                </button>

                                <button type="button" className="btn btn-primary waves-effect waves-light">
                                    <i className="uil uil-edit"></i> Cancel
                                </button> */}

                                            </div>

                                            {/* <div className="project-button-box">
                                <button type="button" className="btn btn-primary waves-effect waves-light">
                                    <i className="uil uil-edit"></i> Edit
                                </button>
                                <button type="button" className="btn btn-primary waves-effect waves-light">
                                    <i className="uil uil-edit"></i> Save
                                    </button>
                                <button type="button" className="btn btn-primary waves-effect waves-light">
                                    <i className="uil uil-edit"></i> Create
                                </button>

                                <button type="button" className="btn btn-primary waves-effect waves-light">
                                    <i className="uil uil-edit"></i> Cancel
                                </button>


                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            }
        </div>
        )
    }
}

const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setLoginUserData: setLoginUserData,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);