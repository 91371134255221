import React,{useState} from 'react'
import Modal from 'react-modal';

export default function ProjectDetailModal(props) {

    const {componentData: ComponentData,openModal,closeModal} = props;
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(true);

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
       // subtitle.style.color = '#f00';
    }

    return (
        <div>
          
            <Modal
                isOpen={openModal}
                onAfterOpen={afterOpenModal}
                contentLabel="Example Modal"
                className="modal-project-detail"
            >
                <div className="closeWrap">
                    <button className="closeBtn btn-primary btn" onClick={() =>closeModal(false)}>Close</button>
                </div>
               {ComponentData}
            </Modal>
        </div>
    )
}
