import {
    SET_PO_ITEM,
    SET_MATERIAL_ITEM,
    SET_MATERIAL_LEN_QTY,
    SET_PROJECT_MATERIAL_ID,
    SET_PROJECT_DATA,
    SET_ESTIMATION_DATA,
    SET_SELECTED_SCOPE,
    SET_PO_FORM_DATA,
    SET_MATERIAL_FORM_DATA,
    SET_SHOPFAB_FORM_DATA,
    SET_FREIGHT_DEL_FORM_DATA,
    SET_FIELD_INSTALL_FORM_DATA,
    SET_FREIGHT_DELIVERY_ID,
    SET_LOGIN_USER_DATA,
    SET_SHOPFAB_ERRORS,
    SET_FREIGHTDEL_ERRORS,
    SET_LOCATION_HEATNUMBER,
    SET_JOB_NUMBER,
    SET_PROJECT_NAME,
    SET_FCCREW_ERRORS,
    SET_CALENDAR_CLICKED_NAME,
    SET_FIELD_MEASURE
} from './actionType';

const initialState = {
    poItemList: [],
    materialItemList: [],
    materialLenQtyList: [],
    heatNumberAndLocationList: [],
    projectMaterialId: 0,
    projectData: {},
    estimationData: {},
    selectedScopeItemID: 0,
    projectName: "",
    selectedJobNumber: 0,
    calendarName: "",
    fieldMeasureFileData:[],
    fieldMeasureFormData:{
        plannedMeasure:"",
        actualMeasure:"",
        comments:""
    },

    POFormData: {
        vendor: 0,
        po: 0,
        poNumber: "",
        transmittal: "",
        grade: "",
        receivedBy: "",
        comments: "",
        dateOrdered: "",
        dateExpected: "",
        dateReceived: "",
    },
    materialFormData: {
        currPage: "",
        location: 0,
        material: 0,
        projectMaterialId: 0
    },
    shopFabFormData: {
        shopName: "",
        tables: [],
        qc: "",
        stored: "",
        description: "",
        comments: "",
        dailyGoals: "",
        dailyActuals: "",
        releaseDate: "",
        scheduled: "",
        dateCompleted: "",
        quantity: ""
    },
    freightDelFormData: {
        freightDate: "",
        projectName: "",
        scope: "",
        shipFrom: "",
        shipTo: "",
        comments: "",
        shipperDate: "",
        receiverDate: "",
        incompleteLoadDate:"",
        signature: "",
        receiver: "",
        deliveredBy: "",
        onsiteContact: "",
        defaultScopeItemName:"",
        receiverName:""
    },
    fieldInstallFormData: {
        startDate: "",
        scheduleCompletionDate: "",
        completionDate: "",
        dailyGoals: "",
        dailyActuals: "",
        description: "",
        comments: "",
        fieldCrew: []
    },
    loginUserData: {
        userName: "",
        roleKey: "",
        profileImage: ""
    },
    FreightDeliveryId: 0
};

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FIELD_MEASURE:
            return{
                ...state,fieldMeasureFormData:action.fieldMeasureFormData
            }
        case SET_PO_ITEM:
            return {
                ...state, poItemList: action.poItemList
            };
        case SET_MATERIAL_ITEM:
            return {
                ...state, materialItemList: action.materialItemList
            };
        case SET_MATERIAL_LEN_QTY:
            return {
                ...state, materialLenQtyList: action.materialLenQtyList
            };
        case SET_PROJECT_MATERIAL_ID:
            return {
                ...state, projectMaterialId: action.projectMaterialId
            };
        case SET_PROJECT_DATA:
            return {
                ...state, projectData: action.projectData
            };
        case SET_ESTIMATION_DATA:
            return {
                ...state, estimationData: action.estimationData
            };
        case SET_SELECTED_SCOPE:
            return {
                ...state, selectedScopeItemID: action.selectedScopeItemID
            };
        case SET_PO_FORM_DATA:
            return {
                ...state, POFormData: action.POFormData
            };
        case SET_MATERIAL_FORM_DATA:
            return {
                ...state, materialFormData: action.materialFormData
            };
        case SET_SHOPFAB_FORM_DATA:
            return {
                ...state, shopFabFormData: action.shopFabFormData
            };
        case SET_FREIGHT_DEL_FORM_DATA:
            return {
                ...state, freightDelFormData: action.freightDelFormData
            };
        case SET_FIELD_INSTALL_FORM_DATA:
            return {
                ...state, fieldInstallFormData: action.fieldInstallFormData
            }
        case SET_FREIGHT_DELIVERY_ID:
            return {
                ...state, FreightDeliveryId: action.FreightDeliveryId
            }

        case SET_LOGIN_USER_DATA:
            return {
                ...state, loginUserData: action.loginUserData
            }


        case SET_SHOPFAB_ERRORS:
            return {
                ...state, shopFabErrs: action.shopFabErrors
            }
        case SET_FREIGHTDEL_ERRORS:
            return {
                ...state, freightDelErrs: action.freightDelErrs
            }

        case SET_FCCREW_ERRORS:
            return {
                ...state, fcCrewErrs: action.fcCrewErrs
            }
        case SET_LOCATION_HEATNUMBER:
            return {
                ...state, heatNumberAndLocationList: action.heatNumberAndLocationList
            }
        case SET_JOB_NUMBER:
            return {
                ...state, selectedJobNumber: action.selectedJobNumber
            }
        case SET_PROJECT_NAME:
            return {
                ...state, projectName: action.projectName
            }
        case SET_CALENDAR_CLICKED_NAME:
            return {
                ...state, calendarName: action.calendarName
            }
        default:
            return state;
    }
}

export default projectReducer;