import React, { useEffect, useState } from 'react';

const Pagination = (props)=>{
    const {currentPage,totalPage,limit,onPageChange,onSizePerPageChange} = props;
   
    let   Total               = totalPage;
    const [limits, setlimits] = useState(limit);
    
    //TOTAL NUMBER OF PAGES:=>
    let totalPages = Math.ceil(Total/limits)

    //--------------------------------------------------------//
    //STATE VARIABLES
    const [activePage, setActivePage]                    = useState(currentPage);
    let   [pageArr, setPageArr]                          = useState([]);
    let   [leftSidePageDropdown,setLeftSidePageDropdown] = useState(false);
    //-------------------------------------------------------//
    useEffect(() =>{
        const pageArray = pageRange(activePage, totalPages);
        console.log(pageArray);
        setPageArr(pageArray);
    },[activePage]) 
    //-------------------------------------------------------//
    const pageRange = (page, pageCount) => {
        var start = page - 2,
            end   = page + 2;
  
        if (end > pageCount) {
            start -= (end - pageCount);
            end = pageCount;
        }
        if (start <= 0) {
            end += ((start - 1) * (-1));
            start = 1;
        }
        end = end > pageCount ? pageCount : end;
        const range = {
            start: start,
            end: end
        };
        return range;
    }
    //---------------------------------------------------------//

    //---------------------------------------------------------//
    const pages = [];
    for (let i=pageArr.start;i<=pageArr.end;i++) {
        pages.push(
            <li class={`page-item ${i===Number(activePage) ? 'active' : ''}`}>
                <a class="page-link" href="#" onClick={(e) => {setActivePage(i); onPageChange(e.target.innerText,activePage);}}>{i}</a>
            </li>
        );
    }

    //---------------------------------------------------------//
    const handlePageChangeDropdown = (e) =>{
        if(leftSidePageDropdown){
            setLeftSidePageDropdown(!leftSidePageDropdown)
        }else{
            setLeftSidePageDropdown(!leftSidePageDropdown)
        }
    }

    //---------------------------------------------------------//
    const handlePageChangeLimit = (e) =>{
        if(leftSidePageDropdown){
            setLeftSidePageDropdown(!leftSidePageDropdown)
        }else{
            setLeftSidePageDropdown(!leftSidePageDropdown)
        }
        setlimits(Number(e.target.text))
        // console.log(e.target.text);
        // if(e.target.innerText == 100){
            onSizePerPageChange(1, Number(e.target.text))    
        // }else{
        //     onSizePerPageChange(activePage, Number(e.target.text));
        // }
        
    }
    //---------------------------------------------------------//

    return(
        <div style={{padding: '0 20px'}}>
            <div className='row'>
                <div className='col-md-1'>
                    <span className={`react-bs-table-sizePerPage-dropdown dropdown custom-pagination-dropdown ${leftSidePageDropdown === true ? 'open show':''}`}>
                        <button id="pageDropDownPagination" type="button" className="btn btn-default btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false" onClick={(e) => handlePageChangeDropdown(e)}>{limits} 
                        </button>
                        <ul className={`dropdown-menu ${leftSidePageDropdown === true ? 'open show':''}`} role="menu" aria-labelledby="pageDropDownPagination">
                            <li role="presentation" className="dropdown-item">
                                <a href="#" tabindex="-1" role="menuitem" data-page="20" onClick={(e) => handlePageChangeLimit(e)}>20</a>
                            </li>
                            <li role="presentation" className="dropdown-item">
                                <a href="#" tabindex="-1" role="menuitem" data-page="40" onClick={(e) => handlePageChangeLimit(e)}>40</a>
                            </li>
                            <li role="presentation" className="dropdown-item">
                                <a href="#" tabindex="-1" role="menuitem" data-page="60" onClick={(e) => handlePageChangeLimit(e)}>60</a>
                            </li>
                            <li role="presentation" className="dropdown-item">
                                <a href="#" tabindex="-1" role="menuitem" data-page="60" onClick={(e) => handlePageChangeLimit(e)}>100</a>
                            </li>
                        </ul>
                    </span>
                </div>
                <div className='col-md-6'></div>
                <div className='col-md-5'>
                    <ul class="pagination">
                        <li class={`page-item ${activePage === 1 ? 'disabled' : ''}`} >
                            <a class="page-link" href="#" onClick={(e) => {onPageChange(e.target.innerText,activePage);setActivePage(1)}}>
                                <span aria-hidden="true" >&#60;&#60;</span>
                            </a>
                        </li>
                        <li class={`page-item ${activePage === 1 ? 'disabled' : ''}`}>
                            <a class="page-link" href="#" onClick={(e) => {onPageChange(e.target.innerText,activePage); {activePage === 1 ? setActivePage(1) : setActivePage(activePage-1)}}}>
                                <span aria-hidden="true">&#60;</span>
                            </a>
                        </li>
                        
                        {pages}
                        
                        <li class={`page-item ${activePage === pageArr.end ? 'disabled' : ''}`}>
                            <a class="page-link" href="#" onClick={(e) => {onPageChange(e.target.innerText,activePage); {activePage === totalPages ? setActivePage(totalPages) : setActivePage(activePage+1)}}}>
                                <span aria-hidden="true">&#62;</span>
                            </a>
                        </li>
                        <li class={`page-item ${activePage === pageArr.end ? 'disabled' : ''}`}>
                            <a class="page-link" href="#" onClick={(e) => {onPageChange(e.target.innerText,totalPage);setActivePage(totalPages)}}>
                                <span aria-hidden="true" >&#62;&#62;</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>    
        </div>
    )
}

export default Pagination;