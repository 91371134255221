import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function ModalPopUp(props) {
  const { handleConfirmModal, openModal, confirmMsg, handleClose } = props;

  return (
    <div>
      <Modal show={openModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmMsg}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleConfirmModal(true)}>
            Yes
          </Button>
          <Button variant="danger" onClick={() => handleConfirmModal(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
