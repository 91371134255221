import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { createNotification } from '../helpers/Notification';
import BootstrapTable from "react-bootstrap-table-next";
import { rules, Permission } from "../../abac.config";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ConfirmBox from "../common/ConfirmBox"
import paginationFactory from 'react-bootstrap-table2-paginator';
import AccessDenied from '../common/AccessDenied';
import Loader from "../common/Loader";
import projectServices from '../project/services/projectServices';


class TemplateLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowConfirmBox: false,
      loader: false,
      removeId: "",
      templateData: [],
      pageChange: 1,
      skip: 0,
      limit: 10,
      count: 0,
      resDataLen: 0,
    }
  }
  componentDidMount = () => {
    this.setState({ loader: true })
    let filters;
    if(JSON.parse(localStorage.getItem('templatePaginationConstant'))!==null){
      const userListPaginationConst= JSON.parse(localStorage.getItem('templatePaginationConstant'));
      const{previousLimit,previousPage,previousSkip}=userListPaginationConst;
     
      this.setState({pageChange:previousPage,limit:previousLimit,skip:previousSkip
      })
      filters = `?filter[skip]=${previousSkip}&filter[limit]=${previousLimit}`;
    }else{
      filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    }
     
    this.getAllTemplates(filters);

  }




  getAllTemplates = (filters) => {

    projectServices
      .getTemplateList(filters)
      .then((response) => {
        // if (response.status === 200) {
        let responseData = response.data.data;
        let responseCount = response.data.count;
        this.setState({
          templateData: responseData,
          resDataLen: responseData.length,
          count: responseCount,
          loader: false,
        });
        localStorage.removeItem("templatePaginationConstant");
        // this.setData(responseData);
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleConfirmModal = (isYes) => {
    if (isYes) {
      this.setState({
        isShowConfirmBox: !this.state.isShowConfirmBox,
        loader: true,
      });
      const { removeId, skip, limit, pageChange, resDataLen } = this.state;
      let filters;
      let localSkip = 0;
      let localPageChange = 0;
      if (resDataLen === 1 && pageChange > 1) {
        localPageChange = pageChange - 1;
        localSkip = skip - limit;
        this.setState({ pageChange: localPageChange, skip: localSkip });
        filters = `?filter[skip]=${localSkip}&filter[limit]=${limit}`
      } else {
        filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`
      }
      projectServices
        .deleteTemplate(removeId)
        .then((response) => {
          createNotification(
            "success",
            "Template deleted successfully ."
          );
          //  this.setState({ pageChange: localPageChange,skip:localSkip },()=>{

          //  });
          this.getAllTemplates(filters);
          // this.props.history.push('/templateList')
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ removeId: 0, isShowConfirmBox: false, loader: false });
    }
  };
  actionFormat = (cell, row, rowIndex, formatExtraData) => {
    const { pageChange, skip, limit } = this.state;
    const data = {
      key: "single",
      pageChange: pageChange,
      limit: limit,
      skip: skip,

    }
    return (
      <div>
        <td>
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <Link to={{
               
                pathname:`/addTemplate/${row.id}`,
                state: { data: data }
              }}>
                {/* <Link to={`/templateList`}> */}
                <i className="uil uil-pen font-size-18"></i>
              </Link>
            </li>
            <li className="list-inline-item">
              <a href="javascript:void(0)" className="text-danger">
                <i className="uil uil-trash-alt font-size-18"
                  onClick={() => this.setState({ removeId: row.id, isShowConfirmBox: true })}
                ></i>
              </a>
            </li>
          </ul>
        </td>
      </div>
    );
  };



  render() {
    const { count, limit, pageChange, loader, templateData,skip, } = this.state;
    const data={
      key:"single",
      pageChange:pageChange,
      limit:limit,
      skip:skip ,
      
  }
    const sizePerPageOptions = [
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
      {
        text: '30',
        value: 30,
      },
      {
        text: '50',
        value: 50,

      },
      {
        text: '100',
        value: 100,
      },
      // Add more options as needed
    ];
    let that = this

    const pagination = paginationFactory({
      page: pageChange,
      sizePerPage: limit,
      lastPageText: ">>",
      firstPageText: "<<",
      nextPageText: ">",
      prePageText: "<",
      totalSize: count,
      // showTotal: true,
      alwaysShowAllBtns: true,
      sizePerPageList: sizePerPageOptions,
      onPageChange: function (page, sizePerPage) {
        let skp = (page - 1) * sizePerPage;

        // let skp = 0 ;
        let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
        that.setState({
          pageChange: page,
          // templateData: [],
          loader: true,
          skip: skp
        }, () => {
          that.getAllTemplates(filters);
        });

        // that.getAllHumanResourceEmployees(filters);  
      },
      onSizePerPageChange: function (sizePerPage, page) {
        console.log(page);
        let skp = (page - 1) * limit;
        // let skp = 0;
        that.setState({
          pageChange: page,
          limit: sizePerPage,
          skip: skp,
          // templateData: [],
          loader: true,
        }, () => {
          let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
          that.getAllTemplates(filters);
        });

        // that.getAllHumanResourceEmployees(filters);
      },
    });

    const handleOnTableChange = (type, newState) => {
      console.log("type", type);
      if (type == 'sort') {
        if (newState.sortOrder == "asc") {
          templateData.sort((obj1, obj2) => {
            return obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
              ;
          });
        }
        else {
          templateData.sort((obj2, obj1) => {
            return obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
              ;
          });
        }

      }

    }
    const columns = [
      {
        dataField: "subject",
        text: "Template Name",
        sort: true,

      },
      {
        dataField: "content",
        text: "Content",
        sort: true
      },
      {
        dataField: "",
        text: "Action",
        formatter: this.actionFormat,
      },
    ];


    let roleKey = this.props.loginUserData.roleKey;
    const isAccess = (roleKey !== "" && rules[roleKey][Permission.MASTER_WRITE]) ? true : false;
    return (
      <div>
        {loader === true ? <Loader /> : <div className="main-content">
          <ConfirmBox
            openModal={this.state.isShowConfirmBox}
            confirmMsg="Are you sure to delete this template?"
            handleConfirmModal={this.handleConfirmModal}
          />
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <ol className="breadcrumb m-0">
                      <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                      &nbsp; / &nbsp;
                      <Link to={`/employeeContact`}><li className="breadcrumb-item">Employee Contact </li></Link>
                      &nbsp; / &nbsp;
                      <Link to={`/templateList`}><li className="breadcrumb-item"> Template </li></Link>
                    </ol>
                  </div>
                  <h4 className="mb-2">Template List</h4>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card card-block">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <Link to={{
                             pathname: "/addTemplate",
                             state:{data:data

                             }}}>
                              <span className="btn btn-success waves-effect waves-light">
                                <i className="uil uil-plus"></i> Add New
                              </span>
                            </Link>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-inline float-md-end mb-3">
                            <div className="search-box ms-2">
                            
                            </div>
                          </div>
                        </div>
                      </div>
                      <BootstrapTable
                        remote={true}
                        keyField="id"
                        data={(templateData.length > 0) ? templateData : []}
                        columns={columns}
                        onTableChange={handleOnTableChange}
                        pagination={pagination}
                        noDataIndication={
                          loader === true ? (
                            <Loader />
                          ) : (
                            <p style={{ color: "red" }}>
                              {"No Data Found"}
                            </p>
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}



      </div>

    )
  }
}
const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TemplateLists);
