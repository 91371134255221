import moment from 'moment';
import store from '../../store';

export const checkValidEmail = (emailId) =>{

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

   return (pattern.test(emailId)) ? true : false;

}

export const checkValidPasswpord = (password) =>{
   return (password.match(/[a-z]/g) && password.match(
        /[A-Z]/g) && password.match(
        /[0-9]/g) && password.match(
        /[^a-zA-Z\d]/g) && password.length >= 8 && password.length > 7 &&  password.length < 16) ? true : false;

}

export const getLoginToken = () =>{
    const userToken = localStorage.getItem("user-token");
    return userToken;
}

export const getApiUrl = () => {
  return process.env.REACT_APP_API_URL;
}

export const dateFormat = (date) =>{
    return moment(date).format('MM/DD/YYYY');
}

export const dbDateFormat = (date) =>{
  // console.log("Inside DATE FUNC")
  // console.log(date)
  let formatedDate =  moment(date).utc().toISOString();
  // console.log(formatedDate)
  return formatedDate;
  // return `${formatedDate}T00:00:00.000Z`;
} 

export const getExtension = (filename) =>{
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }
  
export const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'png':
      case 'jpeg':
        //etc
        return true;
    }
    return false;
  }

  export const isValidDocument = (filename) => {
    const ext = getExtension(filename);
    const fileTypeArr = [ "jpeg", "jpg", "png","pdf", "doc","docx", "odt","xls","xlsx", "ods", "dwg" , "dwf" , "xml", "nc1","dxf"];
    return fileTypeArr.includes(ext.toLowerCase());

  }

  export const checkImageSize = (size) =>{
   
    let file = Math.round((size / 1024));

    if (file <= 5120) {
      return true;
    }
    return false;
  }

  export const checkDrawingFileSize = (size) =>{
   
    let file = Math.round((size / 1024));

    if (file <= 409600) { 
      return true;
    }
    return false;
  }

export const  convertToGmt = (str) => {
    var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

export const dbDateFormatWithTime = (date) =>{
  return moment(date).toISOString();
  // let formatedDate =  moment(date).format('YYYY-MM-DD');
  // return `${formatedDate}T18:30:00.000Z`;
} 

export const formateDateToShow = (date) => {
 return moment(date).add(1, 'day').format('MM/DD/YYYY');
}

export const hasOnlyWhiteSpaces = (str) => {
  if (!str.replace(/\s/g, '').length) {
    return true
  }
  return false;
}

export const getRoleKey = () =>{
  console.log({helperData:store.getState().loginUserData});
  const roleKey = localStorage.getItem("roleKey");
  return roleKey;
}

export const validDocumentArr = () => {
  return [ ".jpeg", ".jpg", ".png",".pdf", ".doc",".docx", ".odt",".xls",".xlsx", ".ods", ".dwg" , ".dwf" , ".xml", ".nc1",".dxf"];
}
