import React, { Component } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
//importing create notification from 
import { createNotification } from '../../../../helpers/Notification';
//importing project  services module 
import projectServices from '../../../services/projectServices';
//importing loader component
import Loader from '../../../../common/Loader';
//import rules and abac provider 
import { rules, Permission } from "../../../../../abac.config"
import { AllowedTo } from "react-abac";
//importing custom modal pop ups -----
import ModalPopUp from '../../../../common/ModalPopUp';
//importing rack modal
import BootstrapTable from 'react-bootstrap-table-next';
//importing pagination 
import paginationFactory from "react-bootstrap-table2-paginator";
//import confirm box
import ConfirmBox from "../../../../common/ConfirmBox"
//importing link from react router dom
//importing Add Inventory rack modal
import UpdateProjectInventory from '../UpdateProjectInventory';
//importing Save as function -------
import { saveAs } from "file-saver";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import MassDeleteInventoryPopUp from './MassDeleteInventoryPopUp';

class ProjectInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shopList: [],
            loader: false,
            selectedShopId: 0,
            slocationId: 0,
            rackArray: [],
            sQuantityOptions: '',
            projectInventoryDisplayList: [],
            locationList: [],
            ActiveId: 0,
            selectedRackId: 0,
            isRackOpenPopUp: false,
            isInventoryDeatilsOpenPopUp: false,
            isMassDeleteProjectInventoriesFlag: false,
            isMassDeleteProjectInventoriesPopUp: false,
            isShowConfirmBox: false,
            counter: 0,
            removeId: 0,
            selectedInventoryId: 0,
            search: "",
            isJobDropDownSelected: false,
            isSearchPoNumber:false,
            isManuallyCreated: false,
            // projectInventoryMaterialData: [],
            suggestionProjectInventory: [],
            isSearch: false,
            suggestionProjectnventoryVar: "",
            isProjectInventorySuggestionSelected: false,
            isLocationSelected: false,
            projectInventoryMaterialData: [],
            suggestionProjectInventory: [],
            isSearch: false,
            suggestionProjectnventoryVar: "",
            isProjectInventorySuggestionSelected: false,
            isLocationSelected: false,
            selectedPONumber: '',
            selectedJobNumber: '',
            isTypeInventoryQuantitySelected: false,
            isTypeInventoryDisabled: false,

            inventoryDropDown: [
                {
                    id: "1",
                    name: "availableInventory",
                    title: "Available Inventory",
                },
                {
                    id: "2",
                    name: "usedInventory",
                    title: "Used Inventory"
                },

            ],
            projectInventoryMaterialData: [],
            suggestionProjectInventory: [],
            isSearch: false,
            suggestionProjectnventoryVar: "",
            isProjectInventorySuggestionSelected: false,
            isLocationSelected: false,
            selectedPONumber: '',
            selectedJobNumber: '0',
            jobNumberList: [],

            pageChange: 1,
            skip: 0,
            limit: 10,
            projectInventorycount: 0,
            filterData: `?filter[skip]=0&filter[limit]=10`,
            resDatalen:0
        }


    }
    componentDidMount = () => {
        let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
        this.getProjectInventoryCount(countReqParam);
        let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
        this.getJobNumberList();
        this.getProjectInventories(filters);
        this.getProjectInventoriesSuggestions();
        this.getLocationMaster();
    }

    componentDidUpdate = (prevProps) => {
        if ((this.props.projectInventoryTabflag !== prevProps.projectInventoryTabflag) && (this.props.projectInventoryTabflag === true)) {
            this.setDefaultProjectInventoryValues();
            let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
            this.getProjectInventoryCount(countReqParam);
            let projectLimitListVar = 10;
            let projectSkipListVar=0
            let filters = `?filter[skip]=${projectSkipListVar}&filter[limit]=${projectLimitListVar}`;
            this.getProjectInventories(filters);
        }
    }
    setDefaultProjectInventoryValues = () => {
        this.setState({
            pageChange: 1, limit: 10, slocationId: 0, search: "", isTypeInventoryQuantitySelected: false, isJobDropDownSelected: false, selectedJobNumber: "0",
            skip:0,resDatalen:0,
            selectedPONumber:"",
            isLocationSelected:false,
            sQuantityOptions: "",isSearchPoNumber:false,
            suggestionManualInventory: [], isSearch: false
        })
    }

    getProjectInventoryCount = (countReqParam) => {
        const { isManuallyCreated } = this.state;



        projectServices.getInventoryCount(countReqParam).then((response) => {
            this.setState({ projectInventorycount: response.data.count })
        })
            .catch((error) => {
                if (error.response.status === 401) {
                    createNotification(
                        "error",
                        "Your login session has been expired!"
                    );
                    localStorage.setItem("user-token", "");
                    this.props.history.push("/login");
                    this.setState({ loader: false });
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    createNotification(
                        "error",
                        "Your login session has been expired!"
                    );
                    localStorage.setItem("user-token", "");
                    this.props.history.push("/login");
                    this.setState({ loader: false });
                }
            })
    }

    getProjectInventories = (filters) => {
        const { isManuallyCreated } = this.state;

        projectServices.getProjectInventoryDetails(filters, isManuallyCreated).then((response) => {
            console.log({ responsedata: response });
            this.setState({  loader: false });
            console.log({ responsedata: response.data,});
            this.getRecievingData(response.data);
            // this.setState({ projectInventoryDisplayList: response.data, count: response.data.count, filterData: filters, loader: false });
        })
            .catch((error) => {
                console.log("error", error);
            })
    }

    //function to close Inventory pop up-----------
    handleCloseAddInventoryModal = () => {
        this.setState({ isInventoryDeatilsOpenPopUp: false })
    }


    //funtion to open add Inventory modal popup and set the inventory id to 0 inorder for new inventory addition
    handleOpenAddInventoryModal = () => {
        const { selectedRackId } = this.state;
        this.setState({ isInventoryDeatilsOpenPopUp: true, selectedInventoryId: 0 })

    }
    //View Inventory details only
    viewInventoryItemDetails = (InventoryId) => {
        this.setState({ isInventoryDeatilsOpenPopUp: true, selectedInventoryId: InventoryId })
    }

    //delete record fron inventory---------------

    handleConfirmModal = (isYes) => {
        

        if (isYes) {
            this.setState({
                isShowConfirmBox: !this.state.isShowConfirmBox
            });
            const{skip,limit,pageChange,resDatalen,removeId}=this.state;
            let localSkip=0;
            let localPageChange=0;
            let filters; 
            if(resDatalen===1 && pageChange>1){
               localSkip=skip-limit;
               localPageChange=pageChange-1;
                this.setState({skip:localSkip,pageChange:localPageChange});
             }else{
                 
             } 
            projectServices.deleteInventoryDetails(removeId).then((response) => {
                createNotification("success", "Inventory item deleted successfully!");
                let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                this.setState({
                    // skip:0,limit:10,pageChange: 1, slocationId: 0, search: "" ,sQuantityOptions:"",selectedJobNumber:"",selectedPONumber:"",isLocationSelected:false,isTypeInventoryQuantitySelected:false,isLocationSelected:false,isJobDropDownSelected:false,isSearchPoNumber:false,isSearch:false 
                },()=>{
                let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`;
                // this.getProjectInventoryCount(countReqParam);
                this.getProjectInventoriesSuggestions();
                this.handlePaginationByCondtion(filters);
                // this.getProjectInventories(filters);
                this.getJobNumberList();
            })
            }).catch((error) => {
                console.log({ errormessage: error });
                if (error.response.status === 401) {
                    createNotification("error", "Your login session has been expired!");
                    localStorage.setItem("user-token", "");
                    this.props.history.push("/login");
                } else {
                    createNotification("error", error.response.data.error.message);
                }
                console.log(error);
            })
            // DELETE ACTION WRITTEN HERE--------END
        } else {
            this.setState({ isShowConfirmBox: false });
        }
    }

    //location from master data--------------
    getLocationMaster = () => {
        projectServices.getLocation().then((response) => {
            // console.log(response);
            this.setState({ locationList: response.data.data })
        }).catch((error) => {
            if (error.response.status === 401) {
                createNotification("error", "Your login session has been expired!!");
                localStorage.setItem('user-token', "");
                this.props.history.push('/login');

            } else {
                createNotification("error", error.response.data.error.message);
            }
            console.log(error);
        })
    }

    handleSearchInventory = (text) => {
        const { projectInventoryMaterialData } = this.state;
        this.setState({ search: text })
        let matches = [];
        if (text.length >= 3) {
            matches = projectInventoryMaterialData.filter(item => {
                const regex = new RegExp(`${text}`, "gi");
                return item.material.match(regex);
            })
        }
        this.setState({ suggestionProjectInventory: matches, isProjectInventorySuggestionSelected: false, isSearch: true, isTypeInventoryDisabled: true ,skip:0,limit:10})





        // }
        if (text.trim() === "") {
            this.setState({ pageChange: 1, slocationId: 0, isTypeInventoryDisabled: false, isSearch: false,skip:0, isJobDropDownSelected: false, isLocationSelected: false,isSearchPoNumber:false,limit:10 },()=>{
                let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
            let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
            this.getProjectInventories(filters);
            this.getProjectInventoryCount(countReqParam);
            });
            

        }
    }


    getProjectInventoriesSuggestions = () => {
        const { isManuallyCreated } = this.state;

        projectServices.getProjectInventorySuggestions(isManuallyCreated).then((response) => {
            this.setState({ projectInventoryMaterialData: response.data })
        }).catch((error) => {
            console.log(error)
        })


    }
    onSuggestionHandler = (item) => {
        this.setState({ search: item, suggestionProjectInventory: [], isProjectInventorySuggestionSelected: true, isSearch: false }, () => {
            this.getSearchedInventory();
        })
    }

    getSearchedInventory = () => {
        const { search, isProjectInventorySuggestionSelected, isManuallyCreated, isLocationSelected, slocationId } = this.state;

        let queryStr = "&";

        queryStr += `search=${search}`;

        let countReqParam;
        if (isProjectInventorySuggestionSelected === true) {
            countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[material]=${search}`
        }
        else {
            countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[material][like]=%${search}%`
        }
        if (isLocationSelected && isProjectInventorySuggestionSelected === false) {

            countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[location]=${slocationId}&where[material][like]=%${search}%`
        } else if (isLocationSelected && isProjectInventorySuggestionSelected === true) {

            countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[location]=${slocationId}&where[material]=%${search}%`
        }
        if (search.trim() !== "" && search.length >= 1) {
            let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
            projectServices.getProjectInventoryDetailsBySearching(filters, search.trim(), isManuallyCreated,
                isProjectInventorySuggestionSelected, isLocationSelected, slocationId).then((response) => {
                    this.setState({  suggestionProjectInventory: [] },()=>{
                        this.getProjectInventoryCount(countReqParam);
                        this.getRecievingData(response.data);

                    });
                    
                }).catch((error) => {
                    if (error.response.status === 401) {
                        createNotification(
                            "erryor",
                            "Your login session has been expired!"
                        );
                        localStorage.setItem("user-token", "");
                        this.props.history.push("/login");
                        this.setState({ loader: false });
                    }
                })
        }

    }
    getSearchedInventoryByJobNum = () => {
        const { selectedJobNumber } = this.state;
        const searchName = 'jobNumber';
        this.getInventoryByFilter(searchName, selectedJobNumber);

    }
    getSearchedInventoryByPoNum = () => {
        const { selectedPONumber } = this.state;
        const searchName = 'poNumber';
        this.getInventoryByFilter(searchName, selectedPONumber);

    }

    handleCalculatedInventory = (event) => {
        console.log(event.target.value);
        const { isManuallyCreated, sQuantityOptions } = this.state;
        if (event.target.value !== "0") {
            let data = event.target.value;
            this.setState({ skip: 0, limit: 10, pageChange: 1, sQuantityOptions: data }, () => {
                let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                this.getUsedAndAvailableInventories(filters, data);
            })


        } else if (event.target.value == "0") {
            this.setState({ sQuantityOptions: event.target.value, isTypeInventoryQuantitySelected: false ,isSearch:false,isSearchPoNumber:false,isJobDropDownSelected:false,isLocationSelected:false,skip: 0, limit: 10, pageChange: 1}, () => {
                let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
                this.getProjectInventoryCount(countReqParam);
                let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                this.getProjectInventories(filters);
            })

        }

    }
    getUsedAndAvailableInventories = (filters, data) => {


        this.setState({ sQuantityOptions: data, isTypeInventoryQuantitySelected: true }, () => {
            this.getUsedAndAvailableInventoriesQuantitiesCount();
            projectServices.getCalculatedInventoryDetails(filters, data, this.state.isManuallyCreated).then((response) => {
              
                this.getRecievingData(response.data);
            }).catch((error) => {
                console.log(error);
            })
            // this.getUsedAndAvailableInventories(filters);
        })
    }

    //get used and available inventory counts----
    getUsedAndAvailableInventoriesQuantitiesCount = () => {
        let countReqParam;
        if (this.state.sQuantityOptions == "usedInventory") {
            countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[quantityUsed][gt]=0`
        } else if (this.state.sQuantityOptions == "availableInventory") {
            countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[quantityLeft][gt]=0`;
        }

        projectServices.getUsedAvailableInventoryCount(countReqParam).then((response) => {
            this.setState({ projectInventorycount: response.data.count });
        }).catch((error) => {
            console.log(error);
        })
    }

    //event to handle searching via location dropdown  list
    handleLocationChange = (event) => {
        const { selectedShopId, page, isLocationSelected } = this.state;
        let queryStr = '';
        let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
        let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`;
        if (event.target.name === "location" && event.target.value !== "0") {
            this.setState({ slocationId: event.target.value, pageChange: 1,skip:0,limit:10, isLocationSelected: true, limit: 10, isTypeInventoryDisabled: true ,loader:true}, () => {
                this.getLocationBySearching(`?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`);
            })
        } else {
            this.setState({ slocationId: 0, pageChange: 1, isLocationSelected: false, limit: 10, isTypeInventoryDisabled: false,isSearch:false,isJobDropDownSelected:false,isSearchPoNumber:false, }, () => {
                this.getProjectInventories(`?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`);
            })
            this.getProjectInventoryCount(countReqParam);

        }
    }

    //function to get the filtered records based on their location fields
    getLocationBySearching = ( filters) => {
        console.log();
        const { limit, slocationId, pageChange, search, isManuallyCreated } = this.state;
        let queryStr = "&";

        if (slocationId !== "" && slocationId !== 0 && queryStr === "&") {
            queryStr = `${queryStr}filter[where][location]=${Number(slocationId)}`
        } else if (slocationId !== "" && slocationId !== 0 && queryStr !== "&") {
            queryStr = `${queryStr}&filter[where][location]=${Number(slocationId)}`
        }
        // let skp = 0;
        // if (page !== undefined) {
        //     skp = (page - 1) * limit;
        // }
        let countReqParam = ""
        if (slocationId !== 0) {
            countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[location]=${slocationId}`
        } else {
            countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
        }
        //let filters = `?filter[skip]=${skp}&filter[limit]=${limit}`;
        projectServices.getProjectInventoriesByLocation(filters, queryStr, isManuallyCreated, search).then((response) => {
            this.getProjectInventoryCount(countReqParam);
            this.setState({ loader:false },()=>{
                this.getRecievingData( response.data);
            });
        }).catch((error) => {
            console.log({ errormessage: error });
        })
    }

    deleteInventoryRecord = (inventoryId) => {
        const roleKey = this.props.loginUserData.roleKey;
        // console.log(roleKey);
        const isDisable =
            roleKey !== "" &&
                rules[roleKey][Permission.INVENTORY_WRITE]
                ? true
                : false;
        // console.log(isDisable);
        if (isDisable === true) {
            this.setState({ removeId: inventoryId, isShowConfirmBox: true });
        }
    }
    actionFormat = (cell, row, rowIndex, formatExtraData) => {
        //Permission to edit the inventory item or not based on the rules defined
        //const{roleKey}=this.props;
        const roleKey = this.props.loginUserData.roleKey;

        // const isAccess =
        //     roleKey !== "" &&
        //         rules[roleKey][Permission.INVENTORY_WRITE]
        //         ? true
        //         : false;
        //   console.log(isAccess);
        return (
            <div>
                <div
                //style={isAccess === true ? { display: "block-inline" } : { display: "none" }}
                >
                    <td>
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <a href="javascript:void(0)" className="text-primary">
                                    <i className="uil uil-pen font-size-18" onClick={() => { this.updateInventoryItem(row.id, row.isManuallyCreated) }}></i>
                                </a>

                            </li>
                            <AllowedTo perform={Permission.INVENTORY_WRITE}>
                                <li className="list-inline-item">
                                    <a href="javascript:void(0)" className="text-danger">
                                        <i className="uil uil-trash-alt font-size-18" onClick={() => this.deleteInventoryRecord(row.id)}></i>
                                    </a>
                                </li>
                            </AllowedTo>
                        </ul>
                    </td>
                </div>

            </div>

        )
    }
    //function to update the inventory details of the page
    updateInventoryItem = (InventoryId, isManuallyCreatedFlag) => {
        this.setState({ isInventoryDeatilsOpenPopUp: true, selectedInventoryId: InventoryId, isManuallyCreated: isManuallyCreatedFlag })
    }
    //update Inventory for a single record----------------------

    handleUpdateInventory = (material, length, quantity, selectedLocationId, selectedGradeId, jobNumber, heatNumber, isManuallyCreatedFlag, pulledBy, usedQuantity, availableQuantity) => {
        const { selectedInventoryId, selectedShopId } = this.state;

        let mt = material.trim();

        // pulledBy
       
        let payload = { material: mt, length: length, quantity: quantity, location: Number(selectedLocationId), gradeId: Number(selectedGradeId), jobNumber: Number(jobNumber), heatNumber: heatNumber, isManuallyCreated: isManuallyCreatedFlag, pulledBy: pulledBy, quantityUsed:usedQuantity, quantityLeft: availableQuantity }
        let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
        projectServices.editInventoryDetails(selectedInventoryId, payload).then((response) => {
            if (response.status) {
                createNotification("success", "Inventory Item updated successfully.");
                this.handleCloseAddInventoryModal();
                
                this.setState({ 
                    // skip:0,limit:10,pageChange: 1, slocationId: 0, search: "" ,sQuantityOptions:"",selectedJobNumber:"",selectedPONumber:"",isSearch:false,isTypeInventoryQuantitySelected:false,isLocationSelected:false,isJobDropDownSelected:false,isSearchPoNumber:false
            },()=>{
                let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                this.handlePaginationByCondtion(filters)
                // this.getProjectInventoryCount(countReqParam);
                // this.getProjectInventories(filters);
                });
                
            }
        })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401) {

                    createNotification(
                        "error",
                        "Your login session has been expired!"
                    );
                    localStorage.setItem("user-token", "");
                    this.props.history.push("/login");
                    this.setState({ loader: false });
                }
            })
    }
    handleDeleteMassInventory = (selectedIds) => {
        projectServices.deleteMassInventories(selectedIds).then((response) => {
            createNotification("success", "Project inventory items deleted succesfully .");
            this.setState({  isTypeInventoryQuantitySelected: false, isSearch: false, isLocationSelected: false, isJobDropDownSelected: false,isSearchPoNumber:false })
            this.handleCloseMassDeleteProjectInventory();
            this.setDefaultProjectInventoryValues();
            let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
            this.getProjectInventoryCount(countReqParam);
            let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
            this.getProjectInventories(filters);
        })
            .catch((error) => {
                console.log({ errormessage: error });
            })
    }
    downloadInventoryReport = () => {
        const { slocationId, isManuallyCreated, search, isSearch } = this.state;
        const URL = process.env.REACT_APP_API_URL;
        projectServices.getInventoryRecord(isManuallyCreated, slocationId, search.trim(), isSearch).then((res) => {
            if (res.data.status) {
                const fileLink = `${URL}${res.data.data}`;
                const pathArr = fileLink.split('/');
                saveAs(
                    // `${process.env.REACT_APP_API_URL}'/'${fileLink}`
                    fileLink
                    ,
                    pathArr[pathArr.length - 1]
                );
                createNotification("success", "Project Inventory record downloaded successfully.")
            }
        }).catch((error) => {
            console.log(error.response.status);
            if (error.response.status === 422) {
                if (error.response.message) {
                    createNotification("error", error.response.message)
                } else {
                    createNotification("error", "No Inventory data exists.");
                }

            }

        })
    }
    handleOpenMassDeleteProjectInventory = () => {
        this.setState({ isMassDeleteProjectInventoriesFlag: true, isMassDeleteProjectInventoriesPopUp: true })
    }
    handleCloseMassDeleteProjectInventory = () => {
        this.setState({ isMassDeleteProjectInventoriesPopUp: false })
    }
    handleOnChange = (event) => {
        console.log("event", event);
        console.log("event.target.name", event.target.name, event.target.value);

        this.setState({ loader: true })
        if (event.target.value !== '') {
            if (event.target.name === "jobNumber") {
                this.setState({ selectedPONumber: '', selectedJobNumber: event.target.value, pageChange: 1, limit: 10,skip:0,isJobDropDownSelected:true }, () => {
                    this.getInventoryByFilterCount(event.target.name, event.target.value);
                })
            } else if (event.target.name === "poNumber") {
                this.setState({ selectedJobNumber: '', selectedPONumber: event.target.value, pageChange: 1, limit: 10 , skip:0,isSearchPoNumber:true }, () => {
                    this.getInventoryByFilterCount(event.target.name, event.target.value);
                })
            }

        } else {


            this.setState({
                pageChange: 1,
                limit:10,
                skip:0,
                projectInventoryDisplayList:[],
                slocationId: 0,
                selectedJobNumber: event.target.value,
                isSearchPoNumber:false,
                isLocationSelected: false,
                isTypeInventoryDisabled: false,
                isJobDropDownSelected: false,
                isSearch: false,
                selectedPONumber: ""

                // selectedJobNumber:event.target.value
            },()=>{
            let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
            this.getProjectInventoryCount(countReqParam);
            let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
            this.getJobNumberList();
            this.getProjectInventories(filters);
            })
            
        }

    }

    // handleOnChange = (event) => {
    //     console.log("event.target.name", event.target.name, event.target.value);


    //             // console.log("event", event);
    //             // console.log("event.target.name", event.target.name, event.target.value);

    //             this.setState({ loader: true })
    //             if (event.target.value !== '') {
    //                 if (event.target.name === "jobNumber") {
    //                     this.setState({ selectedJobNumber: event.target.value, pageChange: 1, limit: 10 ,isJobDropDownSelected:true}, () => {
    //                         this.getInventoryByFilter(event.target.name, event.target.value);
    //                     })
    //                 } else if (event.target.name === "poNumber") {
    //                     this.setState({ selectedPONumber: event.target.value, pageChange: 1, limit: 10 ,isJobDropDownSelected:true }, () => {
    //                         this.getInventoryByFilter(event.target.name, event.target.value);
    //                     })
    //                 }

    //             } else {
    //                 console.log("else");
    //                 this.setState({
    //                     pageChange: 1,
    //                     slocationId: 0,
    //                     selectedPONumber: event.target.value,
    //                     selectedJobNumber: event.target.value,
    //                     isJobDropDownSelected:false,
    //                     isTypeInventoryQuantitySelected:false,
    //                     isSearch:false,
    //                     isLocationSelected:false,

    //                 })
    //                 let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
    //                 let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    //                 this.getProjectInventories(filters);
    //                 this.getProjectInventoryCount(countReqParam);
    //             }



    // }
    getInventoryByFilter = (searchBy, searchValue) => {
        const { pageChange, limit, isManuallyCreated, selectedPONumber ,skip} = this.state;


        // let skp = (pageChange - 1) * limit;
        let filters = `?[skip]=${skip}&[limit]=${limit}`;
        let countReqParam;
        http://18.222.98.66:3300/inventories-po-filter-data?[skip]=0&[limit]=50&isManuallyCreated=false&poNumberLike=p%
        // if (selectedPONumber !== "") {
        //     countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&poNumberLike=${selectedPONumber}%`
        // }
        // queryStr = `${queryStr}&filter[where][projectId]=${selectedProjectId}`
        if (searchValue !== '') {
            // console.log("searchValue", searchValue);
            projectServices.getProjectInventoryDetailsByPoNum(filters, isManuallyCreated, searchBy, searchValue).then((response) => {
                // console.log({ responsedata: response.data });
                let data1 = response.data;

                this.setState({ 
                     loader: false 
                }, () => {
                    // this.getProjectInventoryCount(countReqParam);
                    this.getRecievingData(response.data);
                });
                // })


            })
                .catch((error) => {
                    console.log("error", error);
                })

        } else {

            this.setState({ pageChange: 1, slocationId: 0, selectedPONumber: "" })
            let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
            let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
            this.getProjectInventories(filters);
            this.getProjectInventoryCount(countReqParam);
        }
    }

    getInventoryByFilterCount = (searchBy, searchValue) => {
        const { pageChange, limit, isManuallyCreated } = this.state;
        let skp = (pageChange - 1) * limit;
        let filters = `?filter[skip]=${skp}&filter[limit]=${limit}`;

        projectServices.getProjectInventoryDetailsByPoNumCount(filters, isManuallyCreated, searchBy, searchValue).then((response) => {
            // console.log({ responsedata: response.data });
            let data1 = response.data;
            let DataCount = data1.length;
            // console.log("count main", DataCount);
            this.setState({ projectInventorycount: DataCount 
                // pageChange: 1, limit: 10 
            });
            //  this.setState({ projectInventoryDisplayList: response.data, projectInventorycount: DataCount, pageChange: 1, limit: 10,filterData: filters, loader: false });
            //  this.getRecievingData(response.data);
            this.getInventoryByFilter(searchBy, searchValue);
        })
            .catch((error) => {
                console.log("error", error);
            })
    }


    getJobNumberList = () => {
        console.log("getJobNumberList");
        const { isManuallyCreated } = this.state;
        projectServices.getProjectJobNumber(isManuallyCreated).then((response) => {
            console.log({ responsedata: response.data });
            this.getJobNumberListData(response.data);
            //  this.setState({ jobNumberList: response.data, loader: false });
        })
            .catch((error) => {
                console.log("error", error);
            })
    }
    getJobNumberListData = (responseData) => {
        const uniqueData = responseData.reduce((accumulator, currentValue) => {
            const existingObject = accumulator.find(
                item => item.jobNumber === currentValue.jobNumber
            );
            if (!existingObject) {
                accumulator.push(currentValue);
            }
            return accumulator;
        }, []);
        this.setState({ jobNumberList: uniqueData });
    }

    getJobNumberListData = (responseData) => {
        const uniqueData = responseData.reduce((accumulator, currentValue) => {
            const existingObject = accumulator.find(
                item => item.jobNumber === currentValue.jobNumber
            );
            if (!existingObject) {
                accumulator.push(currentValue);
            }
            return accumulator;
        }, []);
        this.setState({ jobNumberList: uniqueData });
    }

    getRecievingData = (responsedata) => {
         console.log(this.state.pageChange,"pageChange");
        // const newDataArray = this.state.receivingList;
        let receivingDisplayList = responsedata.map((item) => {

            item.material = item.material !== null ? item.material : 'N/A';
            item.jobNumber = item.jobNumber !== null && item.jobNumber !== '' ? item.jobNumber : 'N/A'
            item.poNumber = (item.fkey_poNumber && item.fkey_poNumber.poNumber !== null) ? item.fkey_poNumber.poNumber : 'N/A';

            item.length = item.length !== null && item.length !== "" ? item.length : "N/A";
            item.title = (item.InventoryScopeBTscopeItems && item.InventoryScopeBTscopeItems.title !== null && item.InventoryScopeBTscopeItems.title !== "") ? item.InventoryScopeBTscopeItems.title : 'N/A';
            item.grade = item.grade !== null && item.grade !== "" ? item.grade : "N/A";

            item.quantity = item.quantity !== null && item.quantity !== "" ? item.quantity : "N/A";
            item.heatNumber = item.heatNumber !== null && item.heatNumber !== "" ? item.heatNumber : "N/A";
            item.location = (item.fkey_location && item.fkey_location.name !== null && item.fkey_location.name !== "") ? item.fkey_location.name : 'N/A';
            return item;
        })

        if (true) {
       
            this.setState({
                projectInventoryDisplayList: receivingDisplayList,
                resDatalen:receivingDisplayList.length 
                // pageChange: 1, limit: 10, loader: false
            });
        } else {
            this.setState({ projectInventoryDisplayList: receivingDisplayList });
        }
    }

    handlePaginationByCondtion = (filters) => {
        const { isLocationSelected, slocationId, isSearch, isProjectInventorySuggestionSelected, isTypeInventoryQuantitySelected, isJobDropDownSelected, selectedJobNumber, selectedPONumber
        ,isSearchPoNumber } = this.state;
        if (slocationId !== 0 && isLocationSelected 
            // && isSearch === false && isProjectInventorySuggestionSelected === false
            ) {
          
            this.getLocationBySearching(filters);
        }
        else if ((isSearch === true ||
             isProjectInventorySuggestionSelected === true) 
            //  && slocationId !== 0 && isLocationSelected === true
             ) {
            // alert(21)
            this.getSearchedInventory();
        }
    //     else if ((isSearch === true || isProjectInventorySuggestionSelected === true) 
    //     && slocationId == 0 && isLocationSelected === false
    // ) {
           
    //         this.getSearchedInventory();
    //     }
        else if (isTypeInventoryQuantitySelected === true) {
           // alert(21)
            this.getUsedAndAvailableInventories(filters, this.state.sQuantityOptions)
        }
        else if ((isJobDropDownSelected == true||isSearchPoNumber==true)) {
            
            if (selectedJobNumber !== "") {

            } else if (selectedPONumber !== "") {
                // alert(21)
                this.getInventoryByFilterCount("poNumber", selectedPONumber);
            }
        }
        else {
            
            // let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
            let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
            this.getProjectInventoryCount(countReqParam);
            this.getProjectInventories(filters);
        }
    }

    render() {
        const { pageChange, limit, projectInventorycount, shopList, loader, rackArray, selectedShopId, locationList, suggestionProjectInventory, isShowConfirmBox, projectInventoryDisplayList, selectedInventoryId, isTypeInventoryDisabled, isTypeInventoryQuantitySelected, jobNumberList } = this.state;


        const sizePerPageOptions = [
            {
                text: '10',
                value: 10,
            },
            {
                text: '25',
                value: 25,
            },
            {
                text: '30',
                value: 30,
            },
            {
                text: '50',
                value: 50,

            },
            {
                text: '100',
                value: 100,
            },
            // Add more options as needed
        ];

        let that = this;
        const pagination = paginationFactory({
            page: pageChange,
            sizePerPage: limit,
            lastPageText: '>>',
            firstPageText: '<<',
            nextPageText: '>',
            prePageText: '<',
            totalSize: projectInventorycount,
            sizePerPageList: sizePerPageOptions,
            // showTotal: true,
            alwaysShowAllBtns: true,
            onPageChange: function (page, sizePerPage) {
                let skp = (page - 1) * sizePerPage;
                let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
                that.setState({ pageChange: page, skip: skp, limit: sizePerPage },()=>{
                    that.handlePaginationByCondtion(filters);
                })
                // that.getLocationBySearching(page, filters);
                
                // that.getProjectInventories(filters);
            },
            onSizePerPageChange: function (sizePerPage, page) {
                if (document.getElementsByClassName('dropdown-menu show')[0].classList.contains('show') === true) {
                    document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
                }
                let skp = (page - 1) * limit;
                that.setState({ pageChange: page, skip: skp, limit: sizePerPage }, () => {
                    let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
                    that.handlePaginationByCondtion(filters);               
                })
            }
        });

        //------------------------------------------------------------------------// 

        const handleOnTableChange = (type, newState) => {
            console.log("type", type, newState);
            if (type === "sort") {
                if (newState.sortOrder == "asc") {
                    projectInventoryDisplayList.sort((obj1, obj2) => {
                        return newState.sortField == ("jobNumber" || "quantity" || "heatNumber"||"quantityLeft") ?
                            obj1[newState?.sortField] - obj2[newState?.sortField] :
                            obj1[newState?.sortField]?.trim().localeCompare(obj2[newState?.sortField]?.trim(), undefined, { numeric: true, sensitivity: 'base' })
                            ;

                    });
                }
                else {
                    projectInventoryDisplayList.sort((obj2, obj1) => {
                        return newState.sortField == ("jobNumber" || "quantity" || "heatNumber"||"quantityLeft") ?
                            obj1[newState?.sortField] - obj2[newState?.sortField] :
                            obj1[newState?.sortField]?.trim().localeCompare(obj2[newState?.sortField]?.trim(), undefined, { numeric: true, sensitivity: 'base' })
                            ;

                    });
                }
            }
        }
        const columns = [{
            dataField: "material",
            text: "Item",
            sort: true
        },
        {
            dataField: "jobNumber",
            text: "Job#",
            sort: true

        },
      
        {
            dataField: "title",
            text: "Scope",
            sort: true
        },
        {
            dataField: "poNumber",
            text: "PO No.",
            sort: true

        },
        {
            dataField: "length",
            text: "Length",
            sort: true
        },
       
        {
            dataField: "grade",
            text: "Grade",
            sort: true
        },

        {
            dataField: "quantity",
            text: "Quantity",
            sort: true
        },
        {
            dataField: "heatNumber",
            text: "Heat Number",
            sort: true
        },

        {
            dataField: "location",
            text: "Location",
            sort: true
        },
        {
            dataField: "quantityLeft",
            text: "Available Quantity",
            sort:true
            
        },
        {
            dataField: "",
            text: "Action",
            formatter: this.actionFormat,
        }
        ]
        const roleKey = this.props.loginUserData.roleKey;
        let isAccess=(roleKey==="superadmin"||roleKey==="admin")?"":"disabled";
      
        const isDisable =
            roleKey !== "" &&
                rules[roleKey][Permission.INVENTORY_WRITE]
                ? ""
                : "disabled";


        return (

            <div>
                <div className='d-flex flex-container'>
                    <div className=''>
                        <button variant="outline-primary" className='btn btn-primary waves-effect waves-light mb-2' onClick={this.downloadInventoryReport}>Download Report</button>
                    </div>
                    <div className="">
                        <div className="d-flex mb-2">
                            <label for="inputEmail3" className=" col-form-label mx-1">Job#:</label>

                            <div className="col-sm-10 col-lg-9" style={{ flex: '1' }}>
                                <select className="form-select w-200" onChange={(e) => this.handleOnChange(e)} value={Number(this.state.selectedJobNumber)} name="jobNumber" disabled={
                                    (this.state.isTypeInventoryQuantitySelected || this.state.isLocationSelected || this.state.search||this.state.isSearchPoNumber)?true:false} >
                                    <option value="">Select Job</option>
                                    {jobNumberList.length && jobNumberList.map((itemData) => {
                                        return (<option value={itemData.jobNumber} >  {itemData.jobNumber} </option>);
                                    })}
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className=''>
                        <div className="d-flex mb-2">
                            <label for="inputEmail3" className="col-form-label mx-1">PO#:</label>
                            <div className="" style={{ flex: '1' }}>
                                <input type='text' name="poNumber" className='form-control w-200' placeholder='Enter PO Number'
                                    value={this.state.selectedPONumber}
                                    onChange={(e) => this.handleOnChange(e)}
                                    disabled={
                                        this.state.isTypeInventoryQuantitySelected || this.state.isLocationSelected || this.state.search}
                                />
                            </div>
                            {/* <button className="btn btn-primary search-project-btn"  onClick={this.getSearchedInventoryByPoNum}><i class="uil uil-search"></i></button> */}
                        </div>
                    </div>
                    <div className="">
                        <select className="form-select inventoryLocationDropDownList mb-2 w-200"
                            onChange={this.handleLocationChange}
                            value={Number(this.state.slocationId)}
                            name="location" disabled={(this.state.isJobDropDownSelected || this.state.isTypeInventoryQuantitySelected||this.state.isSearch) ? true : false}>
                            <option value="0">Select Location</option>
                            {locationList.length && locationList.map((itemData) => {
                                return (
                                    <option value={itemData.id}>
                                        {itemData.name}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="d-flex inventorySearch mb-2">

                        <div className='autocomplete-block' style={{ flex: '1' }}>
                            <input autoComplete='off' className="w-200 search-inventory-text form-control" style={{ width: '100%' }} type="text" name="search" placeholder="Search an Inventory" value={this.state.search} onChange={(e) => { this.handleSearchInventory(e.target.value) }} onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    this.getSearchedInventory();
                                }
                            }} disabled={
                                (this.state.isTypeInventoryQuantitySelected || this.state.isJobDropDownSelected||this.state.isLocationSelected) ? true : false} />
                            <div class="autocomplete-items">
                                {suggestionProjectInventory && suggestionProjectInventory.map((suggestion, i) =>
                                    <div key={i} onClick={() => this.onSuggestionHandler(suggestion.material)} >
                                        {suggestion.material}
                                    </div>)}
                            </div>
                        </div>
                        <button className="btn btn-primary search-project-btn" onClick={this.getSearchedInventory}><i class="uil uil-search"></i></button>
                    </div>
                    <div className="mb-2">
                    <button variant="outline-primary mb-2" className='btn btn-primary waves-effect waves-light' onClick={this.handleOpenMassDeleteProjectInventory} disabled={(roleKey==="superadmin"||roleKey==="admin")?"":"disabled"}>Mass Delete Project Inventory</button>
                        
                    </div>
                    <div className="mb-2">
                        <select className="w-200 form-select inventoryLocationDropDownList invent-select"
                            onChange={this.handleCalculatedInventory}
                            value={this.state.sQuantityOptions}
                            name="usedInventoryDropDown" disabled={this.state.isJobDropDownSelected || this.state.isLocationSelected || this.state.isSearch}>
                            <option value="0">All</option>
                            {this.state.inventoryDropDown.length && this.state.inventoryDropDown.map((itemData) => {
                                return (
                                    <option value={itemData.name}>
                                        {itemData.title}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 table-scroll whiteSpacing">
                        <div className="project-scope-block">
                            <div className="react-bootstrap-table">
                                <div className="card card-block">
                                    <div className="card-body">
                                        <div className='table-responsive'>
                                        <BootstrapTable
                                            remote={true}
                                            keyField='id'
                                            data={(projectInventoryDisplayList && projectInventoryDisplayList.length > 0) ? projectInventoryDisplayList : []}
                                            columns={columns}
                                            onTableChange={handleOnTableChange}
                                            pagination={(projectInventoryDisplayList && projectInventoryDisplayList.length > 0) ? pagination : ''}
                                            striped
                                            hover
                                            noDataIndication={(loader === true ? <Loader /> : <p style={{ color: "red" }}>{'No Data Found'}</p>)}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* confirm box for deleting an entry from the table */}
                            <ConfirmBox
                                openModal={isShowConfirmBox}
                                confirmMsg="Are you sure to delete this item?"
                                handleConfirmModal={this.handleConfirmModal} />


                            {/* add Inventory for a specific rack modal pop up */}

                            <ModalPopUp
                                componentData={
                                    <UpdateProjectInventory
                                        inventoryId={selectedInventoryId}
                                        updateInventoryDetails={this.handleUpdateInventory}
                                        closeInventoryModal={this.handleCloseAddInventoryModal}
                                        isManuallyCreatedFlag={this.state.isManuallyCreated}
                                    />}
                                openModal={this.state.isInventoryDeatilsOpenPopUp}
                            />
                            <ModalPopUp
                                componentData={
                                    <MassDeleteInventoryPopUp
                                        projectInventoryFlag={this.state.isMassDeleteProjectInventoriesFlag}
                                        closeModal={this.handleCloseMassDeleteProjectInventory}
                                        handleDeleteMassInventory={this.handleDeleteMassInventory}
                                    />
                                }
                                openModal={this.state.isMassDeleteProjectInventoriesPopUp}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )


    }
}

const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInventory);


