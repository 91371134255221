import React, { Component } from 'react'
import { createNotification } from "../helpers/Notification";
import Loader from "../common/Loader";
import { DropDown } from "../common";
import SimpleReactValidator from "simple-react-validator";
import MasterDataService from "../services/MasterDataService";
import ConfirmBox from '../common/ConfirmBox';
import AccessDenied from '../common/AccessDenied';
import { rules, Permission } from "../../abac.config";
import { AllowedTo } from "react-abac";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import masterDataService from '../services/MasterDataService';
import { Link } from 'react-router-dom';
class AddGrade extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            status: "Active",
            loader: false,
            gradeId: null,
            headingText: "Add Grade",
            button: "Save",
            isShowConfirmBox: false,

        };
        this.validator = new SimpleReactValidator();
    }
    componentDidMount=()=>{
        const{match}=this.props;
        console.log(match.params);
        if(match.path === "/gradeList/:gradeId" && match.params.gradeId !==null){
            this.setState({
                gradeId:match.params.gradeId,
                headingText:"Edit",
                button:"Update",
              });
              this.getGradeItemsById(match.params.gradeId);
            }
        
    }
    getGradeItemsById=(gradeId)=>{
      if(gradeId!==""){
       masterDataService.getGradeById(gradeId).then((response)=>{
        if(response.status){
           let resData=response.data.data;
           console.log(resData);
           this.setState({
            name:resData.name,
            status:resData.status ==="ACTIVE"?"Active":"Inactive",
           })
        }
       }) .catch((error)=>{
        if(error.response.status === 401){
         createNotification("error", "Your login session has been expired!");
         localStorage.setItem("user-token","");
         this.props.history.push("/login");
        }else{
          createNotification("error", error.response.data.error.message);
        }
        console.log({ resetPasswordError: error });
      })        
      }

    }
    handleOnChange =(event)=>{
      //this.setState({[event.target.name]:event.target.value})
      if(event.target.name === "name"){
        this.setState({ name: event.target.value });
    }
    else if (event.target.name === "status") {
       this.setState({ status: event.target.value });
     }
   }
   handleConfirmModal = (isYes) => {
    if(isYes){
      this.setState({ status: "Inactive",isShowConfirmBox: !this.state.isShowConfirmBox  });
    }else{
      this.setState({ status: "Active",isShowConfirmBox: !this.state.isShowConfirmBox  });
    }
  }
  onStatusChange=(e)=>{
    const{match}=this.props;
    if(this.state.status ==="Active" && match.path !=="/addGrade"){
       this.setState({ isShowConfirmBox: true })
    }else{
       this.setState({ status: e.target.value });
     } 
}
handleSaveGrade=()=>{
const{name,status,gradeId}=this.state;
const{match}=this.props;
if(this.validator.allValid()){
  let nm=name.trim();
       const data={name:nm,status};
       console.log({payloaddata:data})
 if(match.path === "/gradeList/:gradeId" && match.params.gradeId !==null){
   masterDataService.updateGradeById(gradeId,data).then((response)=>{
           console.log({response});
           if(response.status === 200){
            createNotification("success", "Grade item updated successfully!");
            this.handleResetState();
            this.setState({loader:false});
            this.props.history.push("/gradeList");
           }          
   })
   .catch((error)=>{
    if (error.response.status === 401) {
      createNotification(
        "error",
        "Your login session has been expired!"
      );
      localStorage.setItem("user-token", "");
      this.props.history.push("/login");
      this.setState({ loader: false });
    } else {
      createNotification("error", error.response.data.error.message);
      this.setState({ loader: false });
    }
    console.log({ resetPasswordError: error });
  });
 }else{
  MasterDataService.addGrade(data)
  .then((response) =>{
    
   if(response.status === 200){
      createNotification("success","Grade item added successfully");
       this.handleResetState();
       this.setState({loader:false});
       this.props.history.push("/gradeList");
    }
     
  })
  .catch((error)=>{
   if (error.response.status === 401) {
     createNotification(
       "error",
       "Your login session has been expired!"
     );
     localStorage.clear();
     this.props.history.push("/login");
     this.setState({ loader: false });
   } else {
     createNotification("error", error.response.data.error.message);
     this.setState({ loader: false });
   }
   console.log({ resetPasswordError: error });
  });
     
 }
}else{
  this.validator.showMessages();
       this.forceUpdate();
}
}
handleResetState = () => {
  this.setState({
    name: "",
    status: "Active",
    gradeId: null,
    headingText: "Add",
    button: "Save",
  });
};

    render() {
        const { loader, isShowConfirmBox } = this.state;
        let roleKey = this.props.loginUserData.roleKey;
        const isAccess = (roleKey !== "" && rules[roleKey][Permission.MASTER_WRITE]) ? true : false;
        return (
            <div>
            {!isAccess ?<AccessDenied/>:(
              <div className="main-content addUser-form">
                <div className="page-content">
                  <div className="container-fluid">
                   <div className="page-title-box d-flex align-items-center justify-content-between">
                      <ol className="breadcrumb m-0">
                        <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                        &nbsp; / &nbsp;
                        <Link to={`/gradeList`}><li className="breadcrumb-item">Grade</li></Link>
                        &nbsp; / &nbsp;
                        {this.props.match.path == `/addGrade` ?
                          <Link to={`/addGrade`}><li className="breadcrumb-item">Add</li></Link>
                        :this.props.match.path == `/gradeList/:gradeId` ?
                          <Link to={`/gradeList/${this.props.match.params.gradeId}`}><li className="breadcrumb-item">Edit</li></Link>
                        :''}
                      </ol>
                   </div>
                   <h4 className="mb-0">{this.state.headingText}</h4>
                   <div className="row">
                     <div className="col-lg-12">
                       <div className="card card-block">
                          <div className="card-body">
                            <div className="wizard clearfix">
                             <div className="content-clearfix">
                               <form className="form-project">
                               <div className="row">
                                 <div className="col-md-5 col-sm-12">
                                  <div className="mb-3">
                                  <label
                                    className="form-label"
                                    for="validationCustom01"
                                  >
                                    Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input type="text" 
                                  className="form-control"
                                  id="validationCustom01"
                                  name="name"
                                  onChange={(event)=>{this.handleOnChange(event)}}
                                  placeholder="Name"
                                  value={this.state.name}
                                 />
                                 {
                                    this.validator.message(
                                      "name",
                                      this.state.name,
                                      "required|max:70",
                                      {className:"text-danger"}
                                    ) 
                                 }
                                  </div>
                                 </div>
                              
                               </div>
                                <div className="row">
                                <div className="col-md-5 col-sm-12">
                                    <div className="mb-3">
                                      <label className="form-label"
                                      for="validationCustom01">
                                          Status
                                      </label>
                                      <DropDown name="status"
                                        options={[
                                            {lable:"Active",value:"Active"},
                                            {lable:"Inactive",value:"Inactive"},
                                        ]}
                                        onChange={(e)=> this.onStatusChange(e)}
                                        defaultSelected={this.state.status}
                                        lable=""/>
                                    </div>
                                  </div>
                                </div>
                          </form>
                          </div>
                             <div className="actions clearfix">
                                <button type="button"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={()=>{this.handleSaveGrade()}}>
                                  <i className="uil uil-edit"></i>{" "}
                                  {this.state.button}
                                </button>
                             </div>
                             <ConfirmBox
                          openModal={isShowConfirmBox}
                          confirmMsg="This entity might being used in one or more projects. Inactivating will remove it from there. Do you still want to continue?"
                          handleConfirmModal={this.handleConfirmModal} />
                            </div>
                          </div>
                       </div>
                     </div>
                   </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
    }
}

const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddGrade);
