
// import { Rules } from "react-abac";
// import { Post } from "./models/Post";
// import { Permission, Role, User } from "./models/User";


let Permission = {
  PROJECT_READ: "PROJECT_READ",
  PROJECT_WRITE: "PROJECT_WRITE",
  PROJECT_DELETE:"PROJECT_DELETE",
  ARCHIVED_PROJECT_DELETE:"PROJECT_DELETE",

  HUMANRESOURCE_READ:"HUMANRESOURCE_READ",
  HUMANRESOURCE_WRITE:"HUMANRESOURCE_WRITE",
  HUMANRESOURCE_DELETE:"HUMANRESOURCE_DELETE",

  USER_READ: "USER_READ",
  USER_WRITE: "USER_WRITE",
  USER_DELETE:"USER_DELETE",

  PROJECTLISTING_READ:"PROJECTLISTING_READ",
  PROJECTLISTING_WRITE:"PROJECTLISTING_WRITE",
  
  MASTER_READ: "MASTER_READ",
  MASTER_WRITE: "MASTER_WRITE",
  
  SCHEDULE_READ: "SCHEDULE_READ",
  SCHEDULE_WRITE: "SCHEDULE_WRITE",
  
  RECEIVING_READ: "RECEIVING_READ",
  RECEIVING_WRITE: "RECEIVING_WRITE",
  
  MAINTENANCE_READ: "MAINTENANCE_READ",
  MAINTENANCE_WRITE: "MAINTENANCE_WRITE",
  
  INVENTORY_READ: "INVENTORY_READ",
  INVENTORY_WRITE: "INVENTORY_WRITE",

  //NEW PERMISSIONS TASKS---------------

  SHOPPERSONAL_READ:"SHOPPERSONAL_READ",
  SHOPPERSONAL_WRITE:"SHOPPERSONAL_WRITE",

  FIELDCREW_READ:"FIELDCREW_READ",
  FIELDCREW_WRITE:"FIELDCREW_WRITE",

  TRANSPORT_READ:"TRANSPORT_READ",
  TRANSPORT_WRITE:"TRANSPORT_WRITE",

  TRANSPORTDEFAULTSCOPE_READ:"TRANSPORTDEFAULTSCOPE_READ",
  TRANSPORTDEFAULTSCOPE_WRITE:"TRANSPORTDEFAULTSCOPE_WRITE",

  SHOPPERSONALDEFAULTSCOPE_READ:"SHOPPERSONALDEFAULTSCOPE_READ",
  SHOPPERSONALDEFAULTSCOPE_WRITE:"SHOPPERSONALDEFAULTSCOPE_WRITE",

  FIELDCREWDEFAULTSCOPE_READ:"FIELDCREWDEFAULTSCOPE_READ",
  FIELDCREWDEFAULTSCOPE_WRITE:"FIELDCREWDEFAULTSCOPE_WRITE",


  ESTIMATE_READ:"ESTIMATE_READ",
  ESTIMATE_WRITE:"ESTIMATE_READ",

  WAGEINFO_READ:"WAGEINFO_READ",
  WAGEINFO_WRITE :"WAGEINFO_WRITE",

  DAILYWORKTICKET_READ:"DAILYWORKTICKET_READ",
  DAILYWORKTICKET_WRITE:"DAILYWORKTICKET_READ"
}

let Role = {
  SUPERADMIN: "superadmin",
  ADMIN: "admin",
  PROJECTMANAGER: "projectmanager",
  RECEIVINGMANAGER:"receivingmanager",
  SERVICEPERSON:"serviceperson",
  YARDMANAGER:"yardmanager",
  USER: "user",
  //NEW ROLES-------
  USERSHOP:"usershop",
  USERFIELD:"userfield",
  SERVICETECH :"servicetech",
  RECEIVINGTECH:"receivingtech",
  TRANSPORT:"transport",
  SHOPMANAGER:"shopmanager",
  FIELDMANAGER:"fieldmanager",
  PRODUCTIONMANAGER:"productionmanager",
  ESTIMATOR:"estimator",

}

let rules = {
  [Role.SUPERADMIN]: {
    [Permission.PROJECT_READ]: true,
    [Permission.PROJECT_WRITE]: true,
    [Permission.PROJECT_DELETE]:true,
    [Permission.ARCHIVED_PROJECT_DELETE]:true,

    [Permission.HUMANRESOURCE_READ]: true,
    [Permission.HUMANRESOURCE_WRITE]: true,
    [Permission.HUMANRESOURCE_DELETE]:true,
    
    [Permission.MASTER_READ]: true,
    [Permission.MASTER_WRITE]: true,
     
    [Permission.USER_READ]: true,
    [Permission.USER_WRITE]: true,
    [Permission.USER_DELETE]:true,
     
    [Permission.SCHEDULE_READ]: true,
    [Permission.SCHEDULE_WRITE]: true,
     
    [Permission.RECEIVING_READ]: true,
    [Permission.RECEIVING_WRITE]: true,

    [Permission.MAINTENANCE_READ]: true,
    [Permission.MAINTENANCE_WRITE]: true,

    [Permission.INVENTORY_READ]: true,
    [Permission.INVENTORY_WRITE]: true,

    [Permission.SHOPPERSONAL_READ]:true,
    [Permission.SHOPPERSONAL_WRITE]:true,

    [Permission.FIELDCREW_READ]:true,
    [Permission.FIELDCREW_WRITE]:true,

    [Permission.TRANSPORT_READ]:true,
    [Permission.TRANSPORT_WRITE]:true,


    [Permission.TRANSPORTDEFAULTSCOPE_READ]:true,
    [Permission.TRANSPORTDEFAULTSCOPE_WRITE]:true,


    [Permission.SHOPPERSONALDEFAULTSCOPE_READ]:true,
    [Permission.SHOPPERSONALDEFAULTSCOPE_WRITE]:true,

    [Permission.FIELDCREWDEFAULTSCOPE_READ]:true,
    [Permission.FIELDCREWDEFAULTSCOPE_WRITE]:true,
    
    [Permission.ESTIMATE_READ]:true,
    [Permission.ESTIMATE_WRITE]:true,

    [Permission.WAGEINFO_READ]:true,
    [Permission.WAGEINFO_WRITE]:true,

    [Permission.DAILYWORKTICKET_READ]:true,
    [Permission.DAILYWORKTICKET_WRITE]:true,



  },
  [Role.ADMIN]: {
    [Permission.PROJECT_READ]: true,
    [Permission.PROJECT_WRITE]: true,
    [Permission.PROJECT_DELETE]: true,
     [Permission.ARCHIVED_PROJECT_DELETE]:true,
    
    [Permission.HUMANRESOURCE_READ]: true,
    [Permission.HUMANRESOURCE_WRITE]: true,
    [Permission.HUMANRESOURCE_DELETE]:true,
    
    [Permission.USER_READ]: true,
    // [Permission.USER_WRITE]: true,
    
    [Permission.SCHEDULE_READ]: true,
    [Permission.SCHEDULE_WRITE]: true,
    
    [Permission.RECEIVING_READ]: true,
    [Permission.RECEIVING_WRITE]: true,

    [Permission.MAINTENANCE_READ]: true,
    [Permission.MAINTENANCE_WRITE]: true,

    [Permission.INVENTORY_READ]: true,
    [Permission.INVENTORY_WRITE]: true,

    [Permission.SHOPPERSONAL_READ]:true,
    [Permission.SHOPPERSONAL_WRITE]:true,

    [Permission.FIELDCREW_READ]:true,
    [Permission.FIELDCREW_WRITE]:true,

    [Permission.TRANSPORT_READ]:true,
    [Permission.TRANSPORT_WRITE]:true,

    [Permission.TRANSPORTDEFAULTSCOPE_READ]:true,
    [Permission.TRANSPORTDEFAULTSCOPE_WRITE]:true,


    [Permission.SHOPPERSONALDEFAULTSCOPE_READ]:true,
    [Permission.SHOPPERSONALDEFAULTSCOPE_WRITE]:true,

    [Permission.FIELDCREWDEFAULTSCOPE_READ]:true,
    [Permission.FIELDCREWDEFAULTSCOPE_WRITE]:true,

    [Permission.ESTIMATE_READ]:true,
    [Permission.ESTIMATE_WRITE]:true,

    [Permission.DAILYWORKTICKET_READ]:true,
    [Permission.DAILYWORKTICKET_WRITE]:true,
   
  },

  [Role.PROJECTMANAGER]: {
    [Permission.PROJECT_READ]: true,
    [Permission.PROJECT_WRITE]: true,
    [Permission.PROJECT_DELETE]: true,
    
    [Permission.SCHEDULE_READ]: true,
    [Permission.SCHEDULE_WRITE]: true,

    [Permission.RECEIVING_READ]: true,
    // [Permission.RECEIVING_WRITE]: true,

    [Permission.MAINTENANCE_READ]: true,
    // [Permission.MAINTENANCE_WRITE]: true,

    [Permission.DAILYWORKTICKET_READ]:true,
    [Permission.DAILYWORKTICKET_WRITE]:true,

    [Permission.SHOPPERSONAL_READ]:true,
    [Permission.SHOPPERSONAL_WRITE]:true,

    [Permission.FIELDCREW_READ]:true,
    [Permission.FIELDCREW_WRITE]:true,

    [Permission.TRANSPORT_READ]:true,
    [Permission.TRANSPORT_WRITE]:true,

    [Permission.TRANSPORTDEFAULTSCOPE_READ]:true,
    [Permission.TRANSPORTDEFAULTSCOPE_WRITE]:true,


    [Permission.SHOPPERSONALDEFAULTSCOPE_READ]:true,
    [Permission.SHOPPERSONALDEFAULTSCOPE_WRITE]:true,

    [Permission.FIELDCREWDEFAULTSCOPE_READ]:true,
    [Permission.FIELDCREWDEFAULTSCOPE_WRITE]:true,
  },

  [Role.RECEIVINGMANAGER]: {
    [Permission.RECEIVING_READ]: true,
    [Permission.RECEIVING_WRITE]: true,
    [Permission.INVENTORY_READ]: true,
    [Permission.INVENTORY_WRITE]: true,

    [Permission.PROJECT_READ]: true,
    
    [Permission.SCHEDULE_READ]: true,
  },

  [Role.SERVICEPERSON]: {
    
    [Permission.MAINTENANCE_READ]: true,
    [Permission.MAINTENANCE_WRITE]: true,

    [Permission.PROJECT_READ]: true,

    [Permission.SCHEDULE_READ]: true,
  },

  [Role.YARDMANAGER]: {
    [Permission.INVENTORY_READ]: true,
    [Permission.INVENTORY_WRITE]: true,

    [Permission.RECEIVING_READ]: true,
    [Permission.RECEIVING_WRITE]: true,

    [Permission.PROJECT_READ]: true,

    [Permission.SCHEDULE_READ]: true,
  },

  [Role.USER]: {
    [Permission.PROJECT_READ]: true,
    
    [Permission.SCHEDULE_READ]: true,
  },
  [Role.USERSHOP]:{
    
    [Permission.SHOPPERSONAL_READ]:true,
    [Permission.SHOPPERSONAL_WRITE]:true,

    [Permission.PROJECT_READ]: true,

    [Permission.SCHEDULE_READ]: true,
    [Permission.SHOPPERSONALDEFAULTSCOPE_READ]:true,
    [Permission.SHOPPERSONALDEFAULTSCOPE_WRITE]:true,

  },
  [Role.USERFIELD]:{
    [Permission.FIELDCREW_READ]:true,
    [Permission.FIELDCREW_WRITE]:true,

    [Permission.DAILYWORKTICKET_READ]:true,
    [Permission.DAILYWORKTICKET_WRITE]:true,

    [Permission.FIELDCREWDEFAULTSCOPE_READ]:true,
    [Permission.FIELDCREWDEFAULTSCOPE_WRITE]:true,

    [Permission.PROJECT_READ]: true,

    [Permission.SCHEDULE_READ]: true,
  },
  [Role.SERVICETECH]:{
    [Permission.MAINTENANCE_READ]: true,
    [Permission.MAINTENANCE_WRITE]: true,

    [Permission.PROJECT_READ]: true,

    [Permission.SCHEDULE_READ]: true,
  },
  [Role.RECEIVINGTECH]:{
    [Permission.RECEIVING_READ]: true,
    [Permission.RECEIVING_WRITE]: true,

    [Permission.INVENTORY_READ]: true,
    [Permission.INVENTORY_WRITE]: true,

    [Permission.PROJECT_READ]: true,

    [Permission.SCHEDULE_READ]: true,
  },
  [Role.TRANSPORT]:{
    [Permission.TRANSPORT_READ]:true,
    [Permission.TRANSPORT_WRITE]:true,

    [Permission.TRANSPORTDEFAULTSCOPE_READ]:true,
    [Permission.TRANSPORTDEFAULTSCOPE_WRITE]:true,

    [Permission.PROJECT_READ]: true,

    [Permission.SCHEDULE_READ]: true,
  },
  [Role.SHOPMANAGER]:{
    [Permission.SHOPPERSONAL_READ]:true,
    [Permission.SHOPPERSONAL_WRITE]:true,

    [Permission.SHOPPERSONALDEFAULTSCOPE_READ]:true,
    [Permission.SHOPPERSONALDEFAULTSCOPE_WRITE]:true,
    
    [Permission.PROJECT_READ]: true,

    [Permission.SCHEDULE_READ]: true,
  },
  [Role.FIELDMANAGER]:{
    [Permission.FIELDCREW_READ]:true,
    [Permission.FIELDCREW_WRITE]:true,
    
    [Permission.DAILYWORKTICKET_READ]:true,
    [Permission.DAILYWORKTICKET_WRITE]:true,

    [Permission.FIELDCREWDEFAULTSCOPE_READ]:true,
    [Permission.FIELDCREWDEFAULTSCOPE_WRITE]:true,

    [Permission.PROJECT_READ]: true,

    [Permission.SCHEDULE_READ]: true,
  },
  [Role.PRODUCTIONMANAGER]:{
    
    [Permission.TRANSPORT_READ]:true,
    [Permission.TRANSPORT_WRITE]:true,

    [Permission.SHOPPERSONAL_READ]:true,
    [Permission.SHOPPERSONAL_WRITE]:true,

    [Permission.INVENTORY_READ]: true,
    [Permission.INVENTORY_WRITE]: true,

    [Permission.PROJECT_READ]: true,
    // [Permission.PROJECT_WRITE]: true,
    [Permission.SCHEDULE_READ]: true,
    [Permission.TRANSPORTDEFAULTSCOPE_READ]:true,
    [Permission.TRANSPORTDEFAULTSCOPE_WRITE]:true,


    [Permission.SHOPPERSONALDEFAULTSCOPE_READ]:true,
    [Permission.SHOPPERSONALDEFAULTSCOPE_WRITE]:true,

    [Permission.FIELDCREWDEFAULTSCOPE_READ]:true,
    [Permission.FIELDCREWDEFAULTSCOPE_WRITE]:true,
    
    [Permission.FIELDCREW_READ]:true,
    [Permission.FIELDCREW_WRITE]:true,

    [Permission.TRANSPORT_READ]:true,
    [Permission.TRANSPORT_WRITE]:true,


    [Permission.SHOPPERSONAL_READ]:true,
    [Permission.SHOPPERSONAL_WRITE]:true,

  },
  [Role.ESTIMATOR]:{
    [Permission.ESTIMATE_READ]:true,
    [Permission.ESTIMATE_WRITE]:true,

    [Permission.PROJECT_READ]: true,

    [Permission.SCHEDULE_READ]: true,
  }

};
export { rules, Permission, Role };