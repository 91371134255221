import FullCalendar from "@fullcalendar/react";
import React, { Component, useState } from "react";
import { dbDateFormat } from "../../helpers/Util";
// import DatePicker from "react-datepicker";
import { createNotification } from "../../helpers/Notification";
import projectServices from "../../project/services/projectServices";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setFcCrewError } from "../../project/redux/projectAction";
import { setFieldInstallFormData } from "../../project/redux/projectAction";
import ModalPopUp from "../../common/ModalPopUp";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from '@fullcalendar/multimonth'
import { rules, Permission } from "../../../abac.config";
import moment from 'moment';
import { setFreightDelFormData } from "../../project/redux/projectAction";
import FieldCrewPopUp from "./FieldCrewPopUp";
import { Link } from "react-router-dom";
//importing api function to get the list of the project managers--------
import { getManagerList } from '../../project/Helper'
import DefaultFieldCrewScopeItemData from "./DefaultFieldCrewScopeItemData";
import AccessDenied from "../../common/AccessDenied";

class FieldCrewCalendar extends Component {

   constructor(props) {
      super(props);
      this.state = {
         isOpenFieldCrewPopUp: false,
         fieldCrewData: [],
         projId: 0,
         scopeId: 0,
         fieldCrewListingFlag: true,
         initialState: {
            startDate: "",
            scheduleCompletionDate: "",
            completionDate: "",
            dailyGoals: "",
            dailyActuals: "",
            description: "",
            comments: "",
            fieldCrew: []
         },
         ActiveId: 0,
         isOpenDefaultScopeItem: false,
         calendarData: [],
         fieldCrewList: [],
         projectName: '',
         scopeName: '',
         fieldCrewName: '',
         selectedFcrewId: [],
         selectedFieldCrew: [],
         onlyFieldCrewList: [],
         checkAll: true,
         singleFieldInstallData: "",
         // checkboxStatus:true,
         FCrewListArr: [],
         //declaration of state variables to store the list of project managers-------------
         managerList: [],
         projectManagerData: [],
         isProjectManagerSelected: false,
         selectedPmId: '0',
         // unSlectedId: 0,
         unSlectedId: [],
         userFieldRole: 'userfield',
         onlyFieldCrewListOne: [],
         isShowFiledCrewList: "hide"
      }
   };

   //-----------------------------------------------------//

   componentDidMount = () => {
      getManagerList().then((response) => {
         this.setState({ managerList: [...response] });
      });
      localStorage.removeItem('calendarName')
      localStorage.removeItem('shopMaintenancePaginationConstants');
      localStorage.removeItem('fieldMaintenancePaginationConstants')
      localStorage.removeItem('projectListConstants');
      this.getCalendarProject(this.state.ActiveId);
      this.getFieldCrew();
   }

   //-----------------------------------------------------//

   getCalendarProject = (fieldCrewIds) => {
      console.log("IDS===>", fieldCrewIds);
      projectServices.getCalenderFieldCrew(fieldCrewIds).then((response) => {
         //console.log(response.data.data, ">>>>>>");
         this.setState({ fieldCrewData: response.data.data }, () => {
            if (this.state.selectedPmId === '0') {
               this.generateCalendarData()
            }
            else {
               this.getProjectListByProjectManager(this.state.selectedPmId)
            }
         })

      })
         .catch((error) => {
            //console.log({ error });
            if (error.response && error.response.status === 401) {
               createNotification("error", "Your login session has been expired!");
               localStorage.setItem("user-token", "");
               this.props.history.push("/login");
            } else {
               createNotification("error", error.response.data.error.message);
            }
            //console.log(error);
         });
   }

   //-----------------------------------------------------//

   getFieldCrew = () => {
      projectServices.getFieldCrew().then((res) => {
         this.setState({ fieldCrewList: res.data.data });
         // //console.log(this.state.fieldCrewList);
         this.handleCheckboxStates();
      }).catch((error) => {
         //console.log(error);
      })
   }

   //-----------------------------------------------------//
   handleCheckboxStates = () => {
      const { selectedFcrewId, fieldCrewList } = this.state;
      //console.log(selectedFcrewId);
      let newArr = [];
      newArr = fieldCrewList.map((item, i) => {
         return { fieldID: item.id, checkboxStatus: true, sortOrder: i };
      });
      this.setState({ FCrewListArr: newArr });
      // //console.log(newArr);
      this.state.FCrewListArr.map((value) => {
         selectedFcrewId.push(value.fieldID);
      })
      // //console.log(selectedFcrewId);
      this.setState({ selectedFcrewId });
   }
   //-----------------------------------------------------//

   generateCalendarData = (isChecked = true) => {
      let calendarData = [];
      let onlyFieldCrewList = [];
      let onlyFieldCrewListOne = [];
      let valObj = {};

      const { projects } = this.state.fieldCrewData;

      if (Object.keys(projects).length > 0) {
         let bgColor;
         let red = "rgb(255, 0, 0)";
         let green = "rgb(30, 130, 76)";
         for (const [, val] of Object.entries(projects)) {
            let tablelist = "";
            let tablearray = [];
            if (val.hasOwnProperty("projectFieldInstallFieldCrews") && val.projectFieldInstallFieldCrews.length > 0) {
               tablearray = val.projectFieldInstallFieldCrews.map((obj) => {
                  return obj.hasOwnProperty('fkey_fieldcrew') ? obj.fkey_fieldcrew.name : ""
               });
            }

            tablelist = tablearray.join(", ");

            // //console.log(tablearray, ">>>>>>>>>>>>");
            if (val.completionDate === "" || val.completionDate === null) {
               bgColor = red
            } else {
               bgColor = green
            }
            let combJobIdStr = `${tablelist}`;
            let showString = combJobIdStr;
            valObj = {
               title: showString,
               start: moment(val.startDate).format("YYYY-MM-DD"),
               end: moment(val.scheduleCompletionDate).add(1, "days").format("YYYY-MM-DD"),
               description: `Job # ${val.project_fkey.jobNumber},  ${val.project_fkey.title},  ${val.scopeitem_fkey.title}`,
               backgroundColor: bgColor,
               extendedProps: {
                  id: val.id,
                  projId: val.projectId,
                  scopeId: val.scopeitem_fkey.id,
                  projectName: val.project_fkey.title,
                  scopeName: val.scopeitem_fkey.title,
                  // fieldCrewKeyName: val.fieldcrew_fkey.name,
                  completionDate: val.completionDate,
               },
            }
            if (isChecked) {
               calendarData.push({ ...valObj });
               onlyFieldCrewListOne.push({ ...val, isCheckedProject: isChecked });
            }
            if (onlyFieldCrewList.find((item) => item.projectId === val.projectId)) {

            } else {
               onlyFieldCrewList.push({ ...val, isCheckedProject: isChecked });
            }
            // else {
            //    calendarData.push(val);

            // }

         }
      }
      //console.log(onlyFieldCrewList, ">>>>>>>");
      this.setState({
         calendarData: [...calendarData],
         // onlyFieldCrewList: [...onlyFieldCrewList],
         onlyFieldCrewListOne: [...onlyFieldCrewListOne],
         fieldCrewListingFlag: false

      });
      this.handleSortingDropDown(onlyFieldCrewList)
   };

   handleSortingDropDown = (onlyProjects) => {

      let result = onlyProjects.sort((a, b) => {
         if (a.jobNumber && b.jobNumber) {
            return a.jobNumber > b.jobNumber ? 1 : -1
         } else {
            return -1
         }
         // console.log(onlyProjects);
      });
      this.setState({ onlyFieldCrewList: [...result] })
   }

   //-----------------------------------------------------//

   handleCloseModal = () => {
      this.setState({
         isOpenFieldCrewPopUp: false,
         projectName: '',
         scopeName: ''
      });
      this.props.setFieldInstallFormData({ ...this.state.initialState });
   };

   //-----------------------------------------------------//

   handleEventClick = (clickInfo) => {
      const { title, start, end, extendedProps } = clickInfo.event;
      const { projId, scopeId, projectName, scopeName } = clickInfo.event.extendedProps;
      this.setState({ projId, scopeId, isOpenFieldCrewPopUp: true, projectName, scopeName });
   };

   //-----------------------------------------------------//

   eventDetail = ({ event }) => {
      let titleDiv = document.createElement("div");
      titleDiv.className = "fc-event-title fc-sticky";
      let descDiv = document.createElement("div");
      titleDiv.innerHTML = event.title;
      descDiv.innerHTML = event.extendedProps.description;
      let arrayOfDomNodes = [titleDiv, descDiv];
      return { domNodes: arrayOfDomNodes };
   };

   //-----------------------------------------------------//

   handleSaveFieldCrew = (isSaveClick = false) => {
      const { startDate, scheduleCompletionDate, completionDate, dailyGoals, dailyActuals, description, comments, fieldCrew } = this.props.fieldInstallFormData;
      const { projId: projectId, scopeId: scopeItemId, selectedFcrewId, isProjectManagerSelected } = this.state;
      let localFieldCrew = [];
      if (Array.isArray(fieldCrew)) {
         localFieldCrew = fieldCrew.map((fieldCrew) => {
            return { fieldCrewId: Number(fieldCrew) };
         })
      }

      const payLoad = { dailyGoals, dailyActuals, description, comments };
      payLoad.fieldCrews = localFieldCrew;
      this.setState({ loader: true });

      if (startDate !== "" && startDate !== null) {
         payLoad.startDate = dbDateFormat(startDate);
      }

      if (scheduleCompletionDate !== "" && scheduleCompletionDate !== null) {
         // payLoad.scheduleCompletionDate = dbDateFormat(moment(scheduleCompletionDate).add(1,"days").format('YYYY-MM-DD')); 
         payLoad.scheduleCompletionDate = dbDateFormat(scheduleCompletionDate);
      }


      // payLoad.completionDate = dbDateFormat(moment(completionDate).add(1,"days").format('YYYY-MM-DD'));
      payLoad.completionDate = dbDateFormat(completionDate);

      projectServices.addFieldInstall(projectId, scopeItemId, payLoad).then((response) => {
         createNotification("success", "Field Crew item updated successfully!");
         if (isProjectManagerSelected === true) {
            // this.getCalendarProject(0);
            this.getCalendarData(projectId, scopeItemId, response.data.data.id)
         } else {
            // this.getCalendarProject(selectedFcrewId);
            this.getCalendarData(projectId, scopeItemId, response.data.data.id)
         }

         this.setState({ isOpenFieldCrewPopUp: false });

      }).catch((error) => {
         //console.log({ error });
         if (error.response.status === 401) {
            createNotification("error", "Your login session has been expired!");
            localStorage.setItem("user-token", "");
            this.props.history.push("/login");
         } else {
            createNotification("error", error.response.data.error.message);
         }
         //console.log(error);
      })
   }
   handleSaveDefaultScopeFieldCrew = (projectId, defaultScopeItemId, selectedLabel) => {
      const { startDate, scheduleCompletionDate, completionDate, dailyGoals, dailyActuals, description, comments, fieldCrew } = this.props.fieldInstallFormData;

      const { setFcCrewError } = this.props;

      let errors = {};
      let localFieldCrew = [];
      if (Array.isArray(fieldCrew)) {
         localFieldCrew = fieldCrew.map((fieldCrew) => {
            return { fieldCrewId: Number(fieldCrew) };
         })
      }

      const payLoad = { dailyGoals, dailyActuals, description, comments };
      payLoad.fieldCrews = localFieldCrew;
      this.setState({ loader: true });

      if (startDate !== "" && startDate !== null) {
         payLoad.startDate = dbDateFormat(startDate);
      }

      if (scheduleCompletionDate !== "" && scheduleCompletionDate !== null) {
         // payLoad.scheduleCompletionDate = dbDateFormat(moment(scheduleCompletionDate).add(1,"days").format('YYYY-MM-DD')); 
         payLoad.scheduleCompletionDate = dbDateFormat(scheduleCompletionDate);
      }

      payLoad.completionDate = dbDateFormat(completionDate);

      if (projectId == 0 || projectId === undefined) {
         errors.projectNameError = "Project name is required .";
      }

      if (!payLoad.startDate) {
         errors.startDateError = "Start date is required .";
      }
      if (!payLoad.scheduleCompletionDate) {
         errors.scheduleCompletionDateError = "Complete date is required .";
      }

      if (payLoad.fieldCrews.length === 0) {
         errors.fieldCrewErrors = "Field crew is required .";
      }


      if (Object.entries(errors).length !== 0) {
         setFcCrewError(errors);
      } else {

         projectServices.addFieldInstall(projectId, defaultScopeItemId, payLoad).then((response) => {
            createNotification("success", "Default scope field crew item added successfully!");
            this.setState({ isOpenFieldCrewPopUp: false, selectedPmId: '0' }, () => {
               this.props.setFieldInstallFormData({ ...this.state.initialState });
               this.getCalendarProject(0);
               this.handleCloseModalDefaultScopeItem();
            });

         }).catch((error) => {
            //console.log({ error });
            if (error.response.status === 401) {
               createNotification("error", "Your login session has been expired!");
               localStorage.setItem("user-token", "");
               this.props.history.push("/login");
            } else {
               createNotification("error", error.response.data.error.message);
            }
            this.props.setFieldInstallFormData({ ...this.state.initialState });
            //console.log(error);
         })

      }


   }
   //-----------------------------------------------------//

   handleFieldCrewClick = (e) => {
      const { selectedFcrewId, FCrewListArr, unSlectedId } = this.state;
      // //console.log(selectedFcrewId);
      this.setState({ fieldCrewListingFlag: true })
      let fieldCrewlistarr = [...selectedFcrewId];
      //console.log(fieldCrewlistarr);
      let id = Number(e.target.dataset.id);
      let unId;
      let flag = e.target.checked;
      // //console.log(flag);

      FCrewListArr.find((value) => {
         if (this.state.isProjectManagerSelected === true && flag === true) {
            // value.checkboxStatus = false;
         }
         if ((value.fieldID == id) && (value.checkboxStatus === true) && flag === false) {
            // alert(123)
            value.checkboxStatus = false;
            unId = fieldCrewlistarr.find(e => e === id);

            unSlectedId.push(unId);
            if (this.state.isProjectManagerSelected === true) {

               fieldCrewlistarr = fieldCrewlistarr.filter(e => e != id);
               // //console.log(fieldCrewlistarr,"><><????");
               this.setState({ unSlectedId }, () => {
                  // fieldCrewlistarr = fieldCrewlistarr.filter(e => e != id);


                  //console.log(fieldCrewlistarr, "<<<<<<<<<<>>>>>>>>>>");
                  if (fieldCrewlistarr.length > 0) {
                     this.setState({ onlyFieldCrewList: [] }, () => {
                        this.getCalendarProject(fieldCrewlistarr);
                        // this.updateCalendaRecordByPmCheckedUnchecked(fieldCrewlistarr);
                     })
                  } else {
                     this.generateCalendarData(false);
                  }
               })

            } else {

               fieldCrewlistarr = fieldCrewlistarr.filter(e => e !== id);
               //console.log(fieldCrewlistarr, "aaaaaaaaaaa");
               unId = fieldCrewlistarr.find(e => e === id);

               if (fieldCrewlistarr.length > 0) {
                  this.setState({ onlyFieldCrewList: [] }, () => {
                     this.getUpdatedFieldCrewRecord(fieldCrewlistarr);
                  })

                  // this.getCalendarProject(fieldCrewlistarr);
               } else {
                  this.generateCalendarData(false);
               }
            }


         } else if ((value.fieldID == id) && (value.checkboxStatus === false && flag === true)) {
            // alert(1234)
            let result = unSlectedId.indexOf(id) > -1;

            // //console.log(result, ">>>>>>>>>>");
            // //console.log(fieldCrewlistarr,"mayank log");
            fieldCrewlistarr.push(id);
            if (this.state.isProjectManagerSelected === true && result === true) {
               // alert(120)
               value.checkboxStatus = true;
               this.setState({ onlyFieldCrewList: [] }, () => {
                  this.getCalendarProject(fieldCrewlistarr);
               })


            }
            else if (this.state.isProjectManagerSelected === true && result === false) {
               //alert(234)
               fieldCrewlistarr = [];
               this.setState({ selectedPmId: '0', isProjectManagerSelected: false, selectedFcrewId: [] }, () => {

                  this.getFieldCrew();
                  this.getCalendarProject();
               })
            } else {
               //alert(3455)
               value.checkboxStatus = true;
               // fieldCrewlistarr.push(id);
               this.getUpdatedFieldCrewRecord(fieldCrewlistarr);
            }

         }
      })
      // //console.log({data:FCrewListArr});
      this.setState({ selectedFcrewId: fieldCrewlistarr, });
      this.setState({ FCrewListArr });
   }



   //-----------------------------------------------------//  

   //-----------------------------------------------------//  

   getUpdatedFieldCrewRecord = (fieldCrewIds) => {
      projectServices.getCalenderFieldCrew(fieldCrewIds).then((response) => {
         //   //console.log(response.data.data,">>>>>>");
         this.setState({ fieldCrewData: response.data.data }, () => {
            this.updateCalanderByFieldCrew(fieldCrewIds)
         })

      })
         .catch((error) => {
            //console.log({ error });

         });


   }
   updateCalanderByFieldCrew = (fieldCrewlistarr, isChecked = true) => {
      // this.getUpdatedFieldCrewRecord(fieldCrewlistarr)
      //   //console.log(this.state.calendarData,">>>>>>>");
      let calendarData = [];
      let valObj = {};
      let onlyFieldCrewList = [];
      let onlyFieldCrewListOne = [];
      const { fieldCrewList, unSlectedId } = this.state;
      const { projects } = this.state.fieldCrewData;
      //console.log({ fieldCrew: projects });


      if (Object.keys(projects).length > 0) {
         let bgColor;
         let red = "rgb(255, 0, 0)";
         let green = "rgb(30, 130, 76)";

         for (const [, val] of Object.entries(projects)) {

            // //console.log(val, ">>>>>>>>>>>>>>>");

            fieldCrewlistarr.map((item) => {
               let resultFieldCrew = val.projectFieldInstallFieldCrews;
               resultFieldCrew.map((crewItem) => {
                  // //console.log(typeof item,">>>>>>>>" ,typeof crewItem.fieldCrewId);
                  let newArray = calendarData.filter(function (item) {

                     return item.id === val.id;
                  });

                  if (item === crewItem.fieldCrewId && newArray.length === 0) {

                     let tablelist = "";
                     let tablearray = [];
                     if (val.hasOwnProperty("projectFieldInstallFieldCrews") && val.projectFieldInstallFieldCrews.length > 0) {
                        tablearray = val.projectFieldInstallFieldCrews.map((obj) => {
                           if (obj.hasOwnProperty("fkey_fieldcrew")) {
                              return obj.fkey_fieldcrew.name;
                           } else {
                              return null; // Or any other value you prefer for undefined records
                           }

                        });
                     }
                     tablelist = tablearray.join(", ")
                     let combJobIdStr = `${tablelist}`;
                     let showString = combJobIdStr;
                     if (val.completionDate === "" || val.completionDate === null) {
                        bgColor = red;
                     } else {
                        bgColor = green;
                     }
                     valObj = {
                        title: showString,
                        id: val.id,
                        start: moment(val.startDate).format("YYYY-MM-DD"),
                        end: moment(val.scheduleCompletionDate).add(1, "days").format("YYYY-MM-DD"),
                        description: `Job # ${val.project_fkey.jobNumber},  ${val.project_fkey.title},  ${val.scopeitem_fkey.title}`,
                        backgroundColor: bgColor,
                        extendedProps: {
                           id: val.id,
                           projId: val.projectId,
                           scopeId: val.scopeitem_fkey.id,
                           projectName: val.project_fkey.title,
                           scopeName: val.scopeitem_fkey.title,
                           // fieldCrewKeyName: val.fieldcrew_fkey.name,
                           completionDate: val.completionDate,
                        },
                     }
                     // calendarData.push({ ...valObj });
                     // onlyFieldCrewList.push({ ...val })
                     if (isChecked) {
                        calendarData.push({ ...valObj });
                        onlyFieldCrewListOne.push({ ...val, isCheckedProject: isChecked });
                     }
                     if (onlyFieldCrewList.find((item) => item.projectId === val.projectId)) {

                     } else {
                        onlyFieldCrewList.push({ ...val, isCheckedProject: isChecked });
                     }
                  } else {


                  }
               })
            });

         }


      }
      // //console.log(calendarData, "?????????");
      // //console.log(this.state.calendarData, ">>>>>>>>>>>>>>>>>");
      this.setState({
         calendarData: [...calendarData], onlyFieldCrewList: [...onlyFieldCrewList],
         onlyFieldCrewListOne: [...onlyFieldCrewListOne], fieldCrewListingFlag: false
      });
   }

   //-----------------------------------------------------//

   updateCalendaRecordByPmCheckedUnchecked = (fieldCrewlistarr) => {
      let calendarData = [];
      let valObj = {};
      const { fieldCrewList, unSlectedId, projectManagerData } = this.state;
      const { projects } = this.state.fieldCrewData;
      if (Object.keys(projects).length > 0) {
         let bgColor;
         let red = "rgb(255, 0, 0)";
         let green = "rgb(30, 130, 76)";

         for (const [, val] of Object.entries(projects)) {

            // //console.log(val, ">>>>>>>>>>>>>>>");
            let pmListData = projectManagerData.filter(item => item.id === val.projectId)
            if (pmListData.length > 0) {
               fieldCrewlistarr.map((item) => {
                  let resultFieldCrew = val.projectFieldInstallFieldCrews;
                  resultFieldCrew.map((crewItem) => {
                     // //console.log(typeof item,">>>>>>>>" ,typeof crewItem.fieldCrewId);
                     let newArray = calendarData.filter(function (item) {

                        return item.id === val.id;
                     });

                     if (item === crewItem.fieldCrewId && newArray.length === 0) {
                        let tablelist = "";
                        let tablearray = [];
                        if (val.hasOwnProperty("projectFieldInstallFieldCrews") && val.projectFieldInstallFieldCrews.length > 0) {
                           tablearray = val.projectFieldInstallFieldCrews.map((obj) => {
                              return obj.hasOwnProperty('fkey_fieldcrew') ? obj.fkey_fieldcrew.name : ""
                           });
                        }
                        tablelist = tablearray.join(", ")
                        let combJobIdStr = `${tablelist}`;
                        let showString = combJobIdStr;
                        if (val.completionDate === "" || val.completionDate === null) {
                           bgColor = red;
                        } else {
                           bgColor = green;
                        }
                        valObj = {
                           title: showString,
                           id: val.id,
                           start: moment(val.startDate).format("YYYY-MM-DD"),
                           end: moment(val.scheduleCompletionDate).add(1, "days").format("YYYY-MM-DD"),
                           description: `Job # ${val.project_fkey.jobNumber},  ${val.project_fkey.title},  ${val.scopeitem_fkey.title}`,
                           backgroundColor: bgColor,
                           extendedProps: {
                              id: val.id,
                              projId: val.projectId,
                              scopeId: val.scopeitem_fkey.id,
                              projectName: val.project_fkey.title,
                              scopeName: val.scopeitem_fkey.title,
                              // fieldCrewKeyName: val.fieldcrew_fkey.name,
                              completionDate: val.completionDate,
                           },
                        }
                        calendarData.push({ ...valObj });
                     } else {


                     }
                  })
               });
            }


         }

         // this.getUpdateFieldCrewArray();
         // this.setState({ FCrewListArr: newArr });
      }
      // //console.log(calendarData, "?????????");
      //  //console.log(this.state.calendarData, ">>>>>>>>>>>>>>>>>");
      this.setState({ calendarData: [...calendarData] });
   }

   //-----------------------------------------------------//

   //-----------------------------------------------------//  

   checkIsActive = (id) => {
      return (this.state.ActiveId === id) ? 'active' : '';
   }

   //----------------------------------------------------//

   updateCalendaRecordByPmChecked = (fieldcrewArray, isChecked = true) => {
      //  //console.log(fieldcrewArray);
      let calendarData = [];
      let valObj = {};
      let onlyFieldCrewList = [];
      let onlyFieldCrewListOne = [];
      const { fieldCrewList, FCrewListArr, projectManagerData } = this.state;
      // //console.log({fieldcrewlist:fieldCrewList});
      // //console.log({fieldCrews:FCrewListArr});
      const { projects } = this.state.fieldCrewData;


      // //console.log(this.state.FCrewListArr,"????????");
      this.setState({ calendarData: [...calendarData] });
      if (Object.keys(projects).length > 0) {

         let bgColor;
         let red = "rgb(255, 0, 0)";
         let green = "rgb(30, 130, 76)";

         for (const [, val] of Object.entries(projects)) {

            // //console.log(val, ">>>>>>>>>>>>>>>");
            let pmListData = projectManagerData.filter(item => item.id === val.projectId)
            if (pmListData.length > 0) {
               fieldcrewArray.map((item) => {
                  let resultFieldCrew = val.projectFieldInstallFieldCrews;
                  resultFieldCrew.map((crewItem) => {
                     // //console.log(typeof item,">>>>>>>>" ,typeof crewItem.fieldCrewId);
                     let newArray = calendarData.filter(function (item) {

                        return item.id === val.id;
                     });

                     if (item === crewItem.fieldCrewId && newArray.length === 0) {
                        let tablelist = "";
                        let tablearray = [];
                        if (val.hasOwnProperty("projectFieldInstallFieldCrews") && val.projectFieldInstallFieldCrews.length > 0) {
                           tablearray = val.projectFieldInstallFieldCrews.map((obj) => {
                              return obj.hasOwnProperty('fkey_fieldcrew') ? obj.fkey_fieldcrew.name : ""

                           });
                        }
                        tablelist = tablearray.join(", ")
                        let combJobIdStr = `${tablelist}`;
                        let showString = combJobIdStr;
                        if (val.completionDate === "" || val.completionDate === null) {
                           bgColor = red;
                        } else {
                           bgColor = green;
                        }
                        valObj = {
                           title: showString,
                           id: val.id,
                           start: moment(val.startDate).format("YYYY-MM-DD"),
                           end: moment(val.scheduleCompletionDate).add(1, "days").format("YYYY-MM-DD"),
                           description: `Job # ${val.project_fkey.jobNumber},  ${val.project_fkey.title},  ${val.scopeitem_fkey.title}`,
                           backgroundColor: bgColor,
                           extendedProps: {
                              id: val.id,
                              projId: val.projectId,
                              scopeId: val.scopeitem_fkey.id,
                              projectName: val.project_fkey.title,
                              scopeName: val.scopeitem_fkey.title,
                              // fieldCrewKeyName: val.fieldcrew_fkey.name,
                              completionDate: val.completionDate,
                           },
                        }
                        // calendarData.push({ ...valObj });
                        // onlyFieldCrewList.push({ ...val })
                        if (isChecked) {
                           calendarData.push({ ...valObj });
                           onlyFieldCrewListOne.push({ ...val, isCheckedProject: isChecked });
                        }
                        if (onlyFieldCrewList.find((item) => item.projectId === val.projectId)) {

                        } else {
                           onlyFieldCrewList.push({ ...val, isCheckedProject: isChecked });
                        }
                     } else {


                     }
                  })
               });

            }

         }

         // this.getUpdateFieldCrewArray();
         // this.setState({ FCrewListArr: newArr });
      }
      // //console.log(calendarData, "?????????");
      // //console.log(this.state.calendarData, ">>>>>>>>>>>>>>>>>");
      this.setState({
         calendarData: [...calendarData],
         // onlyFieldCrewList: [...onlyFieldCrewList],
         onlyFieldCrewListOne: [...onlyFieldCrewListOne]
      });
      this.handleSortingDropDown(onlyFieldCrewList)
   }
   //----------------------------------------------------//

   //-----------------------------------------------------//

   checkUncheckProjectFieldCrew = (value) => {
      const { selectedFcrewId, fieldCrewList, FCrewListArr } = this.state;
      //console.log("SELECTEDFCREWIDS===>", selectedFcrewId);
      let newArray = [];
      // this.setState({isProjectManagerSelected:false})

      if (value === true) {
         fieldCrewList.map((data, index) => {
            newArray.push(data.id);
            FCrewListArr[index].checkboxStatus = value;
         })
         //console.log(FCrewListArr);

         this.setState(FCrewListArr);
         this.setState({ selectedFcrewId: newArray, selectedPmId: '0', isProjectManagerSelected: false });
         if (FCrewListArr.length == newArray.length) {
            this.getCalendarProject(newArray);
         }
      } else {
         fieldCrewList.map((data, index) => {
            FCrewListArr[index].checkboxStatus = value;
         })
         // this.setState({ selectedPmId: '0' })
         this.setState(FCrewListArr);
         this.setState({ selectedFcrewId: newArray, isProjectManagerSelected: false, selectedPmId: '0' });
         this.generateCalendarData(value);
      }
   }

   //-----------------------------------------------------//  

   handleFieldCrewDrop = (eventClick) => {
      const { selectedFcrewId } = this.state;
      //console.log({ eventClick });
      let startDate = eventClick.event.startStr;
      let endDate = eventClick.event.endStr;
      let fieldInstId = eventClick.event.extendedProps.id;
      let projectId = eventClick.event.extendedProps.projId;
      let scopeId = eventClick.event.extendedProps.scopeId;
      let completionDate = eventClick.event.extendedProps.completionDate;
      startDate = dbDateFormat(startDate);

      let payloadData = {
         startDate, scheduleCompletionDate: dbDateFormat(moment(endDate).subtract(1, "days").format('YYYY-MM-DD')),
         //scheduleCompletionDate:dbDateFormat(completionDate),
      };
      payloadData.fieldCrews = [];
      if (startDate && startDate !== "") {
         if ((Date.parse(startDate) > Date.parse(completionDate)) || (Date.parse(moment(endDate).subtract(1, "days")) > Date.parse(completionDate))) {

            //console.log({ startDate: Date.parse(startDate), endDate: Date.parse(endDate), completionDate: Date.parse(completionDate) });
            createNotification("error", "Schedule  date should not  be greater then start date or completion date date");
            this.setState({ selectedPmId: "0", selectedFcrewId: [], isProjectManagerSelected: false }, () => {
               this.getCalendarProject(selectedFcrewId);
               this.getFieldCrew();
            })

            return;
         }
      }

      projectServices.addFieldInstall(projectId, scopeId, payloadData).then(() => {
         createNotification("success", "Field Crew item updated successfully!");
         this.setState({ loader: false }, () => {
            // this.getCalendarProject(selectedFcrewId);
            this.getCalendarData(projectId, scopeId, fieldInstId)
         });
      }).catch((error) => {
         this.setState({ loader: false });
         //console.log({ error });
      })
   }
   getCalendarData = (projectId, scopeId, fieldInstallId) => {
      //alert(456)
      let fieldCrewIds = [];
      projectServices.getSingleFieldInstall(projectId, scopeId, fieldInstallId).then((response) => {
         this.updateSingleCalendarRecord(response.data);
         // this.getUpdatedFieldCrewRecord(fieldCrewIds);
         if (this.state.isProjectManagerSelected === true) {
            this.getCalendarProject([]);
         }
      })
         .catch((error) => {
            //console.log(error);
         })
   }

   updateSingleCalendarRecord = (singleFcInstallData) => {
      const { onlyFieldCrewList, onlyFieldCrewListOne, calendarData, } = this.state;
      let onlyCrewListClone = [...onlyFieldCrewListOne];
      //console.log(singleFcInstallData, "???????");
      let fieldInstallId = singleFcInstallData.id;
      // //console.log(fieldInstallId,"<><><><>");
      let projectObj =
         onlyCrewListClone &&
         onlyCrewListClone.find((data) => Number(data.id) === Number(fieldInstallId));

      console.log(projectObj);

      let projectIndex =
         onlyCrewListClone &&
         onlyCrewListClone.findIndex((data) => Number(data.id) === Number(fieldInstallId));

      //console.log(projectObj, ">>>>");
      //console.log(projectIndex, ">>>>>>>");


      onlyCrewListClone[projectIndex] = {
         ...projectObj,
         completionDate: singleFcInstallData.completionDate,
      }
      console.log(onlyCrewListClone, "onlyCrewListClone");

      // this.setState({
      //    onlyFieldCrewList: [...onlyCrewListClone],
      //    // selectedProjectList: [...onlyProjectListClone],
      //    // fieldCrewData: [...onlyCrewListClone]

      // });
      let calendarDataClone = [...calendarData];
      let projIndex =
         calendarData &&
         calendarData.findIndex(
            (data) => Number(data.extendedProps.id) === Number(fieldInstallId)
         );
      //    console.log(projIndex);

      calendarDataClone.splice(projIndex, 1);
      // calendarDataClone.push({ ...valObj })
      let bgColor;
      let red = "rgb(255, 0, 0)";
      let green = "rgb(30, 130, 76)";
      let tablelist = "";
      let tablearray = [];
      console.log(singleFcInstallData);

      if (singleFcInstallData.hasOwnProperty("projectFieldInstallFieldCrews") && singleFcInstallData.projectFieldInstallFieldCrews.length > 0) {
         tablearray = singleFcInstallData.projectFieldInstallFieldCrews.map((obj) => {
            return obj.hasOwnProperty('fkey_fieldcrew') ? obj.fkey_fieldcrew.name : ""
         });
      }



      tablelist = tablearray.join(", ");
      console.log(tablelist);

      if (projectObj.completionDate === "" || projectObj.completionDate === null) {
         bgColor = red
      } else {
         bgColor = green
      }
      let combJobIdStr = `${tablelist}`;
      let showString = combJobIdStr;
      let valObj = {
         title: showString,
         start: moment(singleFcInstallData.startDate).format("YYYY-MM-DD"),
         end: moment(singleFcInstallData.scheduleCompletionDate).add(1, "days").format("YYYY-MM-DD"),
         description: `Job # ${projectObj.project_fkey.jobNumber},  ${projectObj.project_fkey.title},  ${projectObj.scopeitem_fkey.title}`,
         backgroundColor: bgColor,
         extendedProps: {
            id: projectObj.id,
            projId: projectObj.projectId,
            scopeId: projectObj.scopeitem_fkey.id,
            projectName: projectObj.project_fkey.title,
            scopeName: projectObj.scopeitem_fkey.title,
            // fieldCrewKeyName: projectObj.fieldcrew_fkey.name,
            completionDate: singleFcInstallData.completionDate,
         },
      }

      // this.setState({ calendarData: [...calendarDataClone] });
      this.setState({ calendarData: [...calendarDataClone, valObj] });
   }

   handleProjectManagerOnChange = (e) => {
      const { selectedFcrewId } = this.state;
      if (e.target.value !== '0') {

         this.setState({ selectedPmId: e.target.value, isProjectManagerSelected: true, checkAll: true, ActiveId: 0, selectedFcrewId, onlyFieldCrewList: [] }, () => {
            this.getCalendarProject(0);
            this.checkUncheckProjectWhenPmIsClicked(false);
         });
      } else {
         this.setState({ isProjectManagerSelected: false, selectedPmId: e.target.value, checkAll: true, ActiveId: 0 });
         this.getCalendarProject(0);
         this.getFieldCrew();
         this.getUpdateFieldCrewArray();
      }
   }
   getProjectListByProjectManager = (projectManagerId) => {
      const { selectedPmId } = this.state;
      projectServices.getProjectByProjectManagerId(projectManagerId).then((response) => {
         this.setState({ projectManagerData: response.data });
         this.updateCalendarByProjectManager(response.data, this.state.checkAll);

      })
         .catch((error) => {
            //console.log(error);
         })
   }
   getUpdateFieldCrewArray = () => {
      const { selectedFcrewId, FCrewListArr } = this.state;
      FCrewListArr.map((value) => {
         let data = this.state.fieldCrewList.find(o => o.fieldID === value.id);
         if (value.checkboxStatus === true && data) {
            selectedFcrewId.push(value.fieldID);
         }
      })
      this.setState({ selectedFcrewId })
   }
   //--------function to update the calendar records according to the selection-----------// 

   updateCalendarByProjectManager = (pmData, isChecked = true) => {
      let calendarData = [];
      let valObj = {};
      const { fieldCrewList } = this.state;
      const { projects } = this.state.fieldCrewData;
      let onlyFieldCrewList = [];
      let onlyFieldCrewListOne = [];
      // //console.log(projects);

      if (Object.keys(projects).length > 0) {
         // this.checkUncheckProject(false)
         let newArr = [];
         let bgColor;
         let red = "rgb(255, 0, 0)";
         let green = "rgb(30, 130, 76)";
         for (const [, val] of Object.entries(projects)) {
            // let fieldCrewKeyName = (val && val.fieldcrew_fkey && val.fieldcrew_fkey.name) ? val.fieldcrew_fkey.nam : '';
            let pmListData = pmData.filter(item => item.id === val.projectId)
            // //console.log(val, ">>>>>>>>>>>>>>>");
            if (pmListData.length > 0) {
               fieldCrewList.map((item, i) => {
                  let resultFieldCrew = val.projectFieldInstallFieldCrews;
                  resultFieldCrew.map((crewItem) => {

                     if (item.id === crewItem.fieldCrewId) {


                        this.setState({
                           FCrewListArr: [
                              ...this.state.FCrewListArr.slice(0, i),
                              Object.assign({}, this.state.FCrewListArr[i], { fieldID: item.id, checkboxStatus: true, sortOrder: i }),
                              ...this.state.FCrewListArr.slice(i + 1)
                           ],

                        });
                     } else {

                        let data = newArr.find(o => o.fieldID === item.id);
                        if (!data) {
                           return { fieldID: item.id, checkboxStatus: false, sortOrder: i }
                        }

                     }
                  })
               });
               let tablelist = "";
               let tablearray = [];
               if (val.hasOwnProperty("projectFieldInstallFieldCrews") && val.projectFieldInstallFieldCrews.length > 0) {
                  tablearray = val.projectFieldInstallFieldCrews.map((obj) => {
                     return obj.hasOwnProperty('fkey_fieldcrew') ? obj.fkey_fieldcrew.name : ""
                  });
               }
               tablelist = tablearray.join(", ")
               let combJobIdStr = `${tablelist}`;
               let showString = combJobIdStr;
               if (val.completionDate === "" || val.completionDate === null) {
                  bgColor = red;
               } else {
                  bgColor = green;
               }
               valObj = {
                  title: showString,
                  id: val.id,
                  start: moment(val.startDate).format("YYYY-MM-DD"),
                  end: moment(val.scheduleCompletionDate).add(1, "days").format("YYYY-MM-DD"),
                  description: `Job # ${val.project_fkey.jobNumber},  ${val.project_fkey.title},  ${val.scopeitem_fkey.title}`,
                  backgroundColor: bgColor,
                  extendedProps: {
                     id: val.id,
                     projId: val.projectId,
                     scopeId: val.scopeitem_fkey.id,
                     projectName: val.project_fkey.title,
                     scopeName: val.scopeitem_fkey.title,
                     // fieldCrewKeyName: val.fieldcrew_fkey.name,
                     completionDate: val.completionDate,
                  },
               }
               if (isChecked) {
                  calendarData.push({ ...valObj });
                  onlyFieldCrewListOne.push({ ...val, isCheckedProject: isChecked });
               }
               if (onlyFieldCrewList.find((item) => item.projectId === val.projectId)) {

               } else {
                  onlyFieldCrewList.push({ ...val, isCheckedProject: isChecked });
               }
               // else {
               //    calendarData.push(val);
               // }

            }
            ////console.log("An Iteration ends ....."); 
            // //console.log(this.state.FCrewListArr, ">>>>>>");
         }
         this.getUpdateFieldCrewArray();
         // this.setState({ FCrewListArr: newArr });
      }
      this.setState({
         calendarData: [...calendarData],
         onlyFieldCrewListOne: [...onlyFieldCrewListOne],
         onlyFieldCrewList: [...onlyFieldCrewList], fieldCrewListingFlag: false
      });
   }
   checkUncheckProjectWhenPmIsClicked = (value) => {
      const { selectedFcrewId, fieldCrewList, FCrewListArr } = this.state;
      //console.log("SELECTEDFCREWIDS===>", selectedFcrewId);
      let newArray = [];
      // this.setState({isProjectManagerSelected:false})

      if (value === true) {
         fieldCrewList.map((data, index) => {
            newArray.push(data.id);
            FCrewListArr[index].checkboxStatus = value;
         })
         this.setState(FCrewListArr);
         this.setState({ selectedFcrewId: newArray, selectedPmId: '0' });
         if (FCrewListArr.length == newArray.length) {
            this.getCalendarProject(newArray);
         }
      } else {
         fieldCrewList.map((data, index) => {
            FCrewListArr[index].checkboxStatus = value;
         })
         // this.setState({ selectedPmId: '0' })
         this.setState(FCrewListArr);
         this.setState({ selectedFcrewId: newArray });
         this.generateCalendarData(value);
      }
   }

   handleCloseModalDefaultScopeItem = (selectInfo) => {
      this.setState({
         isOpenDefaultScopeItem: false,
      });
      this.props.setFieldInstallFormData({ ...this.state.initialState });
      this.props.setFcCrewError({
         ...this.props.fcCrewErrs, fieldCrewErrors: "",
         projectNameError: "",
         scheduleCompletionDateError: "",
         startDateError: ""
      });
   };

   handleOpenDefaultTransportItemData = () => {
      this.setState({
         isOpenDefaultScopeItem: true
      })
   }
   //------------------------------------------------------//

   handleDateSelect = (selectInfo) => {


      let roleKey = this.props?.loginUserData?.roleKey;
      const isAccessable =
         roleKey !== "" &&

            rules[roleKey][Permission.PROJECT_WRITE]
            ? true
            : false;
      if (isAccessable) {
         localStorage.setItem("calendarName", "fieldcrewcalendar")
         //  this.props.setCalendarName('transportcalendar');
         // this.setState({isOpenProjectPopUp:true,isTransportDateSelected:true});
         this.props.history.push(
            '/dropDownPage'
         );
      }

   };

   //function to open and close the items dropdown --------

   handleProjectDropDown = () => {

      if (this.state.isShowFiledCrewList === 'hide') {
         this.setState({ isShowFiledCrewList: 'show' })
      } else if (this.state.isShowFiledCrewList === 'show') {
         this.setState({ isShowFiledCrewList: 'hide' })
      }
   }
   handleCloseProjectDropDown = () => {
      this.setState({ isShowFiledCrewList: 'hide' })
   }
   checkUncheckProject = (value) => {
      this.setState({
         checkAll: value,
         selectedProjectList: [],
      });
      if (this.state.isProjectManagerSelected === true) {
         //   this.getProjectListByProjectManager(this.state.selectedPmId);
         this.getCalendarProject(this.state.ActiveId)
      } else {
         //this.generateCalendarData(value)
         this.generateCalendarData(value);
         this.handleCheckboxStates();
      }

   }
   onCheckProject = (event) => {

      let projectId = event.target.id;
      if (projectId > 0) {
         this.updateCheckList(projectId, event.target.checked);
      }
   };

   updateCheckList = (id, isChecked) => {
      const { onlyFieldCrewList, calendarData } = this.state;
      console.log(calendarData);

      // console.log(onlyFieldCrewList,"onlyFieldCrewList");
      // console.log(this.state.onlyFieldCrewListOne,"onlyFieldCrewList");
      let onlyFieldCrewListClone = [...onlyFieldCrewList];

      let projectObj =
         onlyFieldCrewList &&
         onlyFieldCrewList.find((data) => Number(data.projectId) === Number(id));


      let projectIndex =
         onlyFieldCrewList &&
         onlyFieldCrewList.findIndex((data) => Number(data.projectId) === Number(id));

      onlyFieldCrewListClone[projectIndex] = {
         ...projectObj,
         isCheckedProject: isChecked,
      };



      // let varP = onlyProjectListClone.filter((data) => data.isCheckedProject === true);

      this.setState({
         onlyFieldCrewList: [...onlyFieldCrewListClone],
         // selectedProjectList: [...onlyProjectListClone]
      });
      if (isChecked) {
         let calendarDataClone = [];
         let { projects } = this.state.fieldCrewData;
         if (Object.keys(projects).length > 0) {

            let bgColor;
            let red = "rgb(255, 0, 0)";
            let green = "rgb(30, 130, 76)";
            for (const [, val] of Object.entries(projects)) {
               if (val.projectId === Number(id)) {
                  let tablelist = "";
                  let tablearray = [];
                  if (val.hasOwnProperty("projectFieldInstallFieldCrews") && val.projectFieldInstallFieldCrews.length > 0) {
                     tablearray = val.projectFieldInstallFieldCrews.map((obj) => {
                        return obj.hasOwnProperty('fkey_fieldcrew') ? obj.fkey_fieldcrew.name : ""
                     });
                  }

                  tablelist = tablearray.join(", ");

                  // //console.log(tablearray, ">>>>>>>>>>>>");
                  if (val.completionDate === "" || val.completionDate === null) {
                     bgColor = red
                  } else {
                     bgColor = green
                  }
                  let combJobIdStr = `${tablelist}`;
                  let showString = combJobIdStr;
                  let valObj = {
                     title: showString,
                     start: moment(val.startDate).format("YYYY-MM-DD"),
                     end: moment(val.scheduleCompletionDate).add(1, "days").format("YYYY-MM-DD"),
                     description: `Job # ${val.project_fkey.jobNumber},  ${val.project_fkey.title},  ${val.scopeitem_fkey.title}`,
                     backgroundColor: bgColor,
                     extendedProps: {
                        id: val.id,
                        projId: val.projectId,
                        scopeId: val.scopeitem_fkey.id,
                        projectName: val.project_fkey.title,
                        scopeName: val.scopeitem_fkey.title,
                        // fieldCrewKeyName: val.fieldcrew_fkey.name,
                        completionDate: val.completionDate,
                     },
                  }

                  // }
                  calendarDataClone.push(valObj);
               }
            }
         }
         this.setState((prevState) => ({
            calendarData: [...prevState.calendarData, ...calendarDataClone],
         }));
      } else {
         //alert(23)
         let calendarDataClone = [...calendarData];
         for (let k = calendarDataClone.length - 1; k >= 0; k--) {
            const val = calendarDataClone[k];
            if (val.extendedProps.projId === Number(id)) {
               calendarDataClone.splice(k, 1);
            }
         }

         // console.log(calendarDataClone);

         this.setState({ calendarData: [...calendarDataClone] });
      }
      // //console.log(onlyFieldCrewList, ">>>>>>>");
      // this.setState({
      //    calendarData: [...calendarData],
      //    onlyFieldCrewList: [...onlyFieldCrewList],
      //    onlyFieldCrewListOne:[...onlyFieldCrewListOne],
      //    fieldCrewListingFlag:false

      // });
      // this.setState((prevState) => ({
      // 	calendarData: [...prevState.calendarData, ...calendarDataClone],
      // }));

   }

   //-----------------------------------------------------//  

   render() {
      if (this.state.isOpenFieldCrewPopUp) {
         document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "unset";
      }

      let roleKey = this.props.loginUserData.roleKey;
      const isDisable = roleKey !== "" && roleKey !== "projectmanager" && rules[roleKey][Permission.PROJECT_WRITE] ? "" : "disabled";
      const isAddDefaultScopeItem = roleKey !== "" &&
         rules[roleKey][Permission.FIELDCREWDEFAULTSCOPE_WRITE]
         ? ""
         : "disabled";
      const { projId, scopeId, fieldCrewList, calendarData, projectName, scopeName, fieldCrewName, FCrewListArr, managerList, userFieldRole, onlyFieldCrewList, isShowFiledCrewList } = this.state;
      const denyFieldCrew = (roleKey !== "" && (roleKey == "shopmanager" || roleKey == "transport" || roleKey == "Yard/Receiving Tech" || roleKey == "serviceperson")) ? true : false;
      return (
         <>
         {!denyFieldCrew ?   <div>
               <div className="main-content shopPersonal">
                  <div className="page-content">
                     <div className="container-fluid">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                           <ol className="breadcrumb m-0">
                              <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                              &nbsp; / &nbsp;
                              <Link to={`/fieldcrewcalender`}><li className="breadcrumb-item">Field Crew Calendar</li></Link>
                           </ol>
                        </div>
                        <h4 className="mb-0">Field Crew</h4>
                        <div className="row">
                           <div className="col-md-3">
                              <div className="mb-3 mt-3">
                                 <select className="form-select  calendar-drop-down-btn"
                                    onChange={(e) => this.handleProjectManagerOnChange(e)}
                                    value={this.state.selectedPmId}
                                    name="Projectmanager">
                                    <option value="0">Select Project Manager</option>
                                    {managerList.length && managerList.map((itemData) => {
                                       return (
                                          <option value={itemData.value}>
                                             {itemData.lable}
                                          </option>
                                       )
                                    })}
                                 </select>
                              </div>
                           </div>
                           <div className="col-md-3">
                              <div className="mb-3 mt-3">
                                 <a
                                    className="form-select  calendar-drop-down-btn"
                                    href="javascript:void(0)"
                                    onClick={() => this.handleProjectDropDown()}
                                 >
                                    Project
                                 </a>
                                 <div
                                    className={`checkbox-overlay collapse ${isShowFiledCrewList}`}
                                 >
                                    <div className="checkbox-overlay-padding">
                                       <div className="page-title-box d-flex align-items-center justify-content-between">
                                          <div className="checkbox-overlay-links">
                                             <a
                                                href="javascript:void(0)"
                                                onClick={() => this.checkUncheckProject(true)}
                                             >
                                                Check All
                                             </a>
                                             <a
                                                href="javascript:void(0)"
                                                onClick={() => this.checkUncheckProject(false)}
                                                className="uncheck-all"
                                             >
                                                Uncheck All
                                             </a>
                                          </div>
                                          <div className="checkbox-close">
                                             <a
                                                href="javascript:void(0)"
                                                onClick={() => this.handleCloseProjectDropDown()}
                                             >
                                                <i className="uil uil-multiply"></i>
                                             </a>
                                          </div>
                                       </div>
                                       {this.state.onlyFieldCrewList.length > 0 &&
                                          this.state.onlyFieldCrewList.map((data, i) => {
                                             // console.log(this.state.onlyFieldCrewList);

                                             return (
                                                <div key={i} className="form-check">
                                                   <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      id={data.projectId}
                                                      checked={data.isCheckedProject}
                                                      onChange={(e) =>
                                                         this.onCheckProject(e)
                                                      }
                                                   />
                                                   <label
                                                      className="form-check-label"
                                                      htmlFor="formCheck1"
                                                   >
                                                      Job # -{" "}
                                                      {`${data.project_fkey.jobNumber} ${data.project_fkey.title}`}
                                                   </label>
                                                </div>
                                             );
                                          })}
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="mb-3 mt-3">
                                 <button className="add-inventory btn btn-primary"
                                    disabled={isAddDefaultScopeItem}
                                    onClick={this.handleOpenDefaultTransportItemData}>
                                    Add Default Scope Record
                                 </button>
                              </div>
                           </div>
                           <div className="col-12">
                              <div className="card">
                                 <div className="card-body">
                                    <div className="project-scope-block">
                                       <div className="project-navigation-block">
                                          <div className="checkbox-overlay-links p-3">
                                             <a href="javascript:void(0)" onClick={() => this.checkUncheckProjectFieldCrew(true)} >
                                                Check All
                                             </a>
                                             <a href="javascript:void(0)" onClick={() => this.checkUncheckProjectFieldCrew(false)} className="uncheck-all" >
                                                Uncheck All
                                             </a>
                                          </div>
                                          <div className="list-group">
                                             {
                                                fieldCrewList.map((item, i) => {
                                                   if (FCrewListArr[i]) {
                                                      return (
                                                         <div className="btn-group dropend">
                                                            <span for="chkCheckbox" className={` form-check list-group-item list-group-item-action dropdown-toggle  ${this.checkIsActive(item.id)}`} >
                                                               <input id="chkCheckbox" type="checkbox" className="form-check-input" checked={FCrewListArr[i].checkboxStatus} disabled={this.state.fieldCrewListingFlag} onChange={(e) => this.handleFieldCrewClick(e)} data-id={item.id} />
                                                               <label className="form-check-label">{item.name}</label>
                                                            </span>
                                                         </div>
                                                      )
                                                   } else {
                                                      return (
                                                         <div className="btn-group dropend">
                                                            <span for="chkCheckbox" className={` form-check list-group-item list-group-item-action dropdown-toggle  ${this.checkIsActive(item.id)}`} >
                                                               <input id="chkCheckbox" type="checkbox" className="form-check-input"
                                                                  disabled={this.state.fieldCrewListingFlag}
                                                                  onChange={(e) => this.handleFieldCrewClick(e)} data-id={item.id} />
                                                               <label className="form-check-label">{item.name}</label>
                                                            </span>
                                                         </div>
                                                      )
                                                   }
                                                })}
                                          </div>
                                       </div>
                                       <div className="project-details-container">
                                          <div className="card card-block">
                                             <div className="card-body">
                                                <ModalPopUp componentData={
                                                   <FieldCrewPopUp
                                                      closeModal={this.handleCloseModal}
                                                      saveFieldCrew={this.handleSaveFieldCrew}
                                                      isDisable={isDisable}
                                                      projectId={projId}
                                                      selectedScope={scopeId}
                                                      projectName={projectName}
                                                      scopeName={scopeName}
                                                      fieldCrewName={fieldCrewName} />
                                                }
                                                   openModal={this.state.isOpenFieldCrewPopUp}
                                                />
                                                <ModalPopUp componentData={
                                                   <DefaultFieldCrewScopeItemData
                                                      projectId={projId}
                                                      selectedScope={scopeId}
                                                      closeModal={this.handleCloseModalDefaultScopeItem}
                                                      handleSaveDefaultScopeFieldCrew={this.handleSaveDefaultScopeFieldCrew}
                                                   />
                                                }
                                                   openModal={this.state.isOpenDefaultScopeItem}
                                                />
                                                <FullCalendar
                                                   plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, multiMonthPlugin]}
                                                   initialView={"dayGridMonth"}
                                                   headerToolbar={{
                                                      left: "prev,next today",
                                                      center: "title",
                                                      right: "dayGridDay,dayGridWeek,dayGridMonth,multiMonthYear",
                                                   }}
                                                   height="auto"
                                                   select={this.handleDateSelect}

                                                   navLinks={true} // can click day/week names to navigate views
                                                   editable={roleKey !== "" && rules[roleKey][Permission.FIELDCREW_WRITE] ? true : false}
                                                   selectable={true}
                                                   selectMirror={true}
                                                   nowIndicator={true}
                                                   events={calendarData}
                                                   eventClick={this.handleEventClick}
                                                   eventContent={this.eventDetail}
                                                   eventResize={this.handleFieldCrewDrop}
                                                   eventDrop={this.handleFieldCrewDrop}
                                                   longPressDelay={this.handleDateSelect}
                                                   buttonText={{
                                                      today: "Today",
                                                      month: "Month",
                                                      week: "Week",
                                                      day: "Day",
                                                      year: "Year",
                                                   }}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="wizard clearfix">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div >
            </div >:<AccessDenied/>} 
          
         </>

      )
   }
}

const mapStateToProps = (props) => {
   return {
      fieldInstallFormData: props.fieldInstallFormData,
      loginUserData: props.loginUserData,
      fcCrewErrs: props.fcCrewErrs
   }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
   setFieldInstallFormData: setFieldInstallFormData,
   setFcCrewError: setFcCrewError,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FieldCrewCalendar);
