import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import MetisMenu from "@metismenu/react";
import { rules, Permission, Role } from "../../../abac.config";
import { AllowedTo } from "react-abac";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { includes } from "lodash";

// import "metismenujs/dist/metismenujs.css";
class LeftBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false
    };
  }

  getActiveClass = () => { };

  handleDropDown = () => {
    this.setState({
      toggle: !this.state.toggle
    })
  }

  renderMasterLinks = () => {
    const location = this.props.location.pathname;
    let roleKey = this.props.loginUserData.roleKey;
    const isAccess = (roleKey !== "" && rules[roleKey][Permission.MASTER_WRITE]) ? true : false;

    const li = <li>
      <Link to="#" className="has-arrow" onClick={() => this.handleDropDown()}>
        <i className="uil-folder"></i>
        <span>Master Data</span>
      </Link>
      {this.state.toggle ?
        <ul>
          <li>
            <Link
              to="/vendor"
              className={(location.includes('vendor') || location.includes('addvendor')) ? "active" : ""}
            >
              <span>Vendor</span>
            </Link>
          </li>
          <li>
            <Link
              to="/qc"
              className={(location.includes('qc') || location.includes('addqc')) ? "active" : ""}
            >
              <span>QC</span>
            </Link>
          </li>
          <li>
            <Link
              to="/location"
              className={(location.includes('location') || location.includes('addlocation')) ? "active" : ""}
            >
              <span>Location</span>
            </Link>
          </li>
          <li>
            <Link
              to="/table"
              className={(location.includes('table') || location.includes('addtable')) ? "active" : ""}
            >
              <span>Table</span>
            </Link>
          </li>
          <li>
            <Link
              to="/shop"
              className={(location.includes('shop') || location.includes('addshop')) ? "active" : ""}
            >
              <span>Shop</span>
            </Link>
          </li>
          <li>
            <Link
              to="/drawingtype"
              className={(location.includes('drawingtype') || location.includes('adddrawingtype')) ? "active" : ""}
            >
              <span>Drawing Type</span>
            </Link>
          </li>
          <li>
            <Link
              to="/fieldcrew"
              className={(location.includes('fieldcrew') || location.includes('fieldcrew')) ? "active" : ""}
            >
              <span>Field Crew</span>
            </Link>
          </li>
          <li>
            <Link to="/ShopMaintenance"
              className={(location.includes('ShopMaintenance') || location.includes('AddShopMaintenance')) ? "active" : ""}>
              <span>Shop Maintenence</span>
            </Link>
          </li>
          <li>
            <Link to="/FieldMaintenance"
              className={(location.includes('FieldMaintenance') || location.includes('AddFieldMaintenance')) ? "active" : ""}>
              <span>Field Maintenance Listing</span>
            </Link>
          </li>
          <li>
            <Link to="/fieldMaintenancepage"
              className={(location.includes('fieldMaintenancepage') || location.includes('addfieldMaintenancepage')) ? "active" : ""}>
              <span>Field Maintenance </span>
            </Link>
          </li>
          <li>
            <Link to="/gradeList"
              className={(location.includes('gradeList') || location.includes('addGrade')) ? "active" : ""}>
              <span>Grade</span>
            </Link>
          </li>
          <li>
            <Link to="/colorCodeList"
              className={(location.includes('colorCodeList') || location.includes('addColorCode')) ? "active" : ""}
            >
              Color Code
            </Link>
          </li>
          <li>
            <Link to="/certificateList"
              className={(location.includes('certificateList') || location.includes('addCertificate')) ? "active" : ""}
            >
              Certificate List
            </Link>
          </li>
          <li>
            <Link to="/shopDesignation"
              className={(location.includes('shopDesignation') || location.includes('addShopDesignation')) ? "active" : ""}
            >
              Shop Designation
            </Link>
          </li>
          <li>
            <Link to="/title"
              className={(location.includes('title') || location.includes('addTitle')) ? "active" : ""}
            >
              Title
            </Link>
          </li>
        </ul> : null}
    </li>;

    return isAccess ? li : "";

  }
  render() {
    // console.log({ matchData: this.props });
    const location = this.props.location.pathname;
    let roleKey = this.props.loginUserData.roleKey;

    const isHumanPageAccess = (roleKey !== "" && (roleKey == "superadmin" || roleKey == "admin")) ? true : false;
    const estimationAccess = (roleKey !== "" && (roleKey == "superadmin" || roleKey == "admin" || roleKey == "estimator" || roleKey == "projectmanager")) ? true : false;

    const denyChartAndListingAccess = (roleKey !== "" && (roleKey == "fieldmanager" || roleKey == "shopmanager" || roleKey == "transport" || roleKey == "receivingmanager" || roleKey == "serviceperson")) ? true : false;

    const denyShopPersonalAccess = (roleKey !== "" && (roleKey == "fieldmanager" || roleKey == "transport" || roleKey == "receivingmanager" || roleKey == "serviceperson")) ? true : false;

    const denyReceiving = (roleKey !== "" && (roleKey == "fieldmanager" || roleKey == "transport" || roleKey == "shopmanager" || roleKey == "serviceperson")) ? true : false;

    const denyTransport= (roleKey !== "" && (roleKey == "receivingmanager" || roleKey == "shopmanager" || roleKey == "serviceperson")) ? true : false;

    const denyMaintenance = (roleKey !== "" && (roleKey == "fieldmanager" || roleKey == "transport" || roleKey == "shopmanager" || roleKey == "receivingmanager")) ? true : false;

    const denyInventory = (roleKey !== "" && (roleKey == "fieldmanager" || roleKey == "transport" ||roleKey=="serviceperson")) ? true : false;

    const denyFieldCrew = (roleKey !== "" && (roleKey == "shopmanager" || roleKey == "transport" || roleKey == "receivingmanager" || roleKey == "serviceperson")) ? true : false;
    return (
      <div className="vertical-menu mm-active" id="navbarToggle">
        <div className="navbar-brand-box">
          <a className="logo logo-dark">
            <span className="logo-sm">
              <img src="/assets/images/logo.png" alt="" height="42" />
            </span>
            <span className="logo-lg">
              <img src="/assets/images/logo.png" alt="" height="40" />
            </span>
          </a>
        </div>
        <div data-simplebar="init" className="sidebar-menu-scroll">
          <div
            className="simplebar-wrapper"
            style={{ margin: "0px", height: "100%", overflow: "scroll" }}
          >
            <div id="sidebar-menu" className="mm-active">
              <MetisMenu>
                <li>
                  <Link
                    to="/project"
                    className={location.includes('project') ? "active" : ""}
                  >
                    <i className="uil uil-clipboard"></i>
                    <span>Projects</span>
                  </Link>
                </li>

                <li>
                  {roleKey == "superadmin" || roleKey == "admin" || roleKey == "projectmanager" ?
                    <Link
                      to="/archive"
                      className={location.includes('archive') ? "active" : ""}
                    >
                      <i className="uil uil-clipboard"></i>
                      <span>Project Archive</span>
                    </Link> : ""
                  }

                </li>

                <li>
                  {
                    !denyChartAndListingAccess ?
                      <Link
                        to="/chartsProject"
                        className={location.includes('chartsProject') ? "active" : ""}
                      >
                        <i className="uil uil-clipboard"></i>
                        <span>Project Chart</span>
                      </Link> : ""
                  }

                </li>

                <li>
                  {
                    !denyChartAndListingAccess ?
                      <Link
                        to="/listingProjects"
                        className={location.includes('listingProjects') ? "active" : ""}
                      >
                        <i className="uil uil-clipboard"></i>
                        <span>Project Listing</span>
                      </Link> : ""
                  }


                </li>
                <li>
                 {!denyTransport?
                <Link
                to="/transport"
                className={location === "/transport" ? "active" : ""}
              >
                <i className="uil uil-calendar-alt"></i>
                <span>Transport</span>
              </Link>:"" 
                }
                  
                </li>

                <li>{
                  estimationAccess === true ?
                    <Link
                      to="/estimation"
                      className={location === "/estimation" ? "active" : ""}
                    >
                      <i className="uil uil-calendar-alt"></i>
                      <span>Estimation</span>
                    </Link> : ""
                }

                </li>

                <li>
                  {isHumanPageAccess === true ? <Link
                    to="/humanResource"
                    // className={location === "/humanResource" ? "active" : ""}
                    className={(location.includes('humanResource') || location.includes('addEmployee')) ? "active" : ""}
                  >
                    <i className="uil uil-calendar-alt"></i>
                    <span>Human Resource</span>
                  </Link> : " "}

                </li>
                <li>
                  {
                    isHumanPageAccess === true ?
                      <Link
                        to="/employeeContact"
                        //  className={(location.includes('employeeContact') || location.includes('addEmployee'))? "active" : ""}
                        className={(location === "/employeeContact"
                          || location.includes('addGroup')
                          || location.includes('allGroupList')
                          || location.includes('sendMail')
                          || location.includes('templateList')
                          || location.includes('addTemplate')
                        ) ? "active" : ""}
                      >
                        <i className="uil uil-calendar-alt"></i>
                        <span>Employee Contact</span>
                      </Link>
                      : ""
                  }

                </li>
                <li>
                  {
                    !denyShopPersonalAccess ?
                      <Link
                        to="/shopPersonal"
                        className={location === "/shopPersonal" ? "active" : ""}
                      >
                        <i className="uil uil-calendar-alt"></i>
                        <span>Shop Personal</span>
                      </Link> : ""
                  }

                </li>
                <li>
                  {/* <a href="#">
                    <i className="uil uil-receipt"></i>
                    <span>Receiving</span>
                  </a> */}
                  {
                    !denyReceiving ?
                      <Link
                        to="/receiving"
                        className={(location.includes("receiving") || location.includes("displayrecieving")) ? "active" : ""}
                      >
                        <i className="uil uil-calendar-alt"></i>
                        <span>Receiving</span>
                      </Link> : ""
                  }

                </li>
                <li>
                  {
                    !denyMaintenance ?
                      <Link to="/maintenance" className={(location.includes("maintenance") || location.includes("/add?") || location.includes("/edit?") || location.includes("/view?") || location.includes("history")) ? "active" : ""} >
                        <i className="uil uil-calendar-alt"></i>
                        <span>Maintenance</span>
                      </Link> : ""
                  }

                </li>
                <li>
                  {
                    !denyInventory ?
                      <Link to="/inventory" className={(location.includes("inventory")) ? "active" : ""}>
                        <i className="uil-home-alt"></i>
                        <span>Inventory</span>
                      </Link> : ""

                  }
                </li>

                <li>

                  <Link
                    to="/timeOffcalendar"
                    className={location === "/timeOffcalendar" ? "active" : ""}
                  >
                    <i className="uil uil-calendar-alt"></i>
                    <span>TimeOff Calendar</span>
                  </Link>


                </li>
                {
                  !denyFieldCrew ?
                  <li>
                  <Link to="/fieldcrewcalender"
                    className={location === "/fieldcrewcalender" ? "active" : ""} >
                    <i className="uil uil-calendar-alt"></i>
                    <span>Field Crews</span>
                  </Link>
                </li>:"" 
                }
                
                <AllowedTo
                  perform={Permission.USER_WRITE}
                // no={() => <div style={{ color: "red" }}>Denied</div>}
                >
                  <li>
                    <Link
                      to="/user"
                      className={(location.includes('user') || location.includes('adduser') || location.includes('update') || location.includes('restore')) ? "active" : ""}
                    >
                      <i className="uil-users-alt"></i>
                      <span>User Management</span>
                    </Link>
                  </li>
                </AllowedTo>
                {/* {isAccess &&
               this.renderMasterLinks()
                 }  */}
                {this.renderMasterLinks()}
                {/* {isAccess ? this.renderMasterLinks() : null} */}
              </MetisMenu>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({


}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeftBar));
//export default withRouter(LeftBar);