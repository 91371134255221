import React, { Component } from "react";
import { createNotification } from "../helpers/Notification";
import Loader from "../common/Loader";
import {
  getLoginToken,
  getApiUrl,
} from "../helpers/Util";
import axios from "axios";
import { DropDown } from "../common";
import SimpleReactValidator from "simple-react-validator";
import MasterDataService from "../services/MasterDataService";
import ConfirmBox from "../common/ConfirmBox";
import AccessDenied from '../common/AccessDenied';
import { rules, Permission } from "../../abac.config";
import { AllowedTo } from "react-abac";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
class AddQc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      organization: "",
      status: "Active",
      loader: false,
      qcId: null,
      headingText: "Add",
      button: "Save",
      isShowConfirmBox: false,
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = () => {
    const { match } = this.props;
    if (match.path === "/qc/:qcId" && match.params.qcId !== null) {
      this.setState({
        qcId: match.params.qcId,
        headingText: "Edit",
        button: "Update",
      });
      this.getQcById(match.params.qcId);
    }
  };

  getQcById = (qcId) => {
    if (qcId !== "") {
      MasterDataService.getQcById(qcId)
        .then((response) => {
          if (response.status) {
            let resData = response.data.data;
            this.setState({
              name: resData.name,
              email: resData.email,
              phone: resData.phone,
              country: resData.country,
              organization: resData.organization,
              dbImage: resData.upload_fkey ? resData.upload_fkey.filePath : "",
              role: resData.role,
              status: resData.status == "ACTIVE" ? "Active" : "Inactive",
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            createNotification("error", "Your login session has been expired!");
            localStorage.clear();
            this.props.history.push("/login");
            //this.setState({loader:false});
          } else {
            createNotification("error", error.response.data.error.message);
            //this.setState({loader:false});
          }
          console.log({ resetPasswordError: error });
        });
    }
  };

  handleResetState = () => {
    this.setState({
      name: "",
      organization: "",
      status: "Active",
      qcId: null,
      headingText: "Add",
      button: "Save",
    });
  };

  handleOnChange = (event) => {
    if (event.target.name === "name") {
      this.setState({ name: event.target.value });
    } else if (event.target.name === "status") {
      this.setState({ status: event.target.value });
    }
  };

  onStatusChange = (e) => {
    const { match } = this.props;
    if (this.state.status === "Active" && match.path !== "/addqc") {
      this.setState({ isShowConfirmBox: true });
    } else {
      this.setState({ status: e.target.value });
    }
  };

  handleConfirmModal = (isYes) => {
    if (isYes) {
      this.setState({
        status: "Inactive",
        isShowConfirmBox: !this.state.isShowConfirmBox,
      });
    } else {
      this.setState({
        status: "Active",
        isShowConfirmBox: !this.state.isShowConfirmBox,
      });
    }
  };

  handleSaveQc = () => {
    const {
      name,
      organization,
      status,
      qcId,
    } = this.state;
    const { match } = this.props;
    if (this.validator.allValid()) {
      const token = getLoginToken();
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      this.setState({ loader: true });
      const URL = getApiUrl();
      let url = "";
      let nm=name.trim();
      const data = { name:nm, organization: Number(organization), status };

      if (match.path === "/qc/:qcId" && qcId !== null) {
        url = `${URL}/qcs/${qcId}`;
        axios
          .patch(url, data, {
            headers: headers,
          })
          .then((response) => {
            if (response.status === 200) {
              createNotification("success", "QC update successfully!");
              this.handleResetState();
              this.setState({ loader: false });
              this.props.history.push("/qc");
            }
            console.log({ response });
          })
          .catch((error) => {
            if (error.response.status === 401) {
              createNotification(
                "error",
                "Your login session has been expired!"
              );
              localStorage.clear();
              this.props.history.push("/login");
              this.setState({ loader: false });
            } else {
              createNotification("error", error.response.data.error.message);
              this.setState({ loader: false });
            }
            console.log({ resetPasswordError: error });
          });
      } else {
        url = `${URL}/qcs`;
        axios
          .post(url, data, {
            headers: headers,
          })
          .then((response) => {
            if (response.status === 200) {
              createNotification("success", "User is added successfully!");
              this.handleResetState();
              this.setState({ loader: false });
              this.props.history.push("/qc");
            }
            console.log({ response });
          })
          .catch((error) => {
            if (error.response.status === 401) {
              createNotification(
                "error",
                "Your login session has been expired!"
              );
              localStorage.clear();
              this.props.history.push("/login");
              this.setState({ loader: false });
            } else {
              createNotification("error", error.response.data.error.message);
              this.setState({ loader: false });
            }
            console.log({ resetPasswordError: error });
          });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const { loader, isShowConfirmBox } = this.state;
    let roleKey = this.props.loginUserData.roleKey;
    const isAccess = (roleKey!=="" && rules[roleKey][Permission.MASTER_WRITE]) ?true:false;

    return (
      <div>
        {!isAccess ? <AccessDenied /> : (
          <div className="main-content addUser-form">
            <div className="page-content">
              <div className="container-fluid">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <ol className="breadcrumb m-0">
                    <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                    &nbsp; / &nbsp;
                    <Link to={`/qc`}><li className="breadcrumb-item">QC</li></Link>
                    &nbsp; / &nbsp;
                    {this.props.match.path == `/addqc` ?
                      <Link to={`/addqc`}><li className="breadcrumb-item">Add</li></Link>
                    :this.props.match.path == `/qc/:qcId` ?
                      <Link to={`/qc/${this.props.match.params.qcId}`}><li className="breadcrumb-item">Edit</li></Link>
                    :''
                    }
                  </ol>
                </div>
                <h4 className="mb-0">{this.state.headingText} QC</h4>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-block">
                      <div className="card-body">
                        <div className="wizard clearfix">
                          <div className="content clearfix">
                            <form className="form-project ">
                              <div className="row">
                                <div className="col-md-4 col-sm-12">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      for="validationCustom01"
                                    >
                                      Name{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="validationCustom01"
                                      name="name"
                                      onChange={this.handleOnChange}
                                      placeholder="Name"
                                      required=""
                                      value={this.state.name}
                                    />
                                    {this.validator.message(
                                      "name",
                                      this.state.name,
                                      "required|max:70",
                                      { className: "text-danger" }
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4 col-sm-12">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      for="validationCustom01"
                                    >
                                      Status
                                    </label>
                                    <DropDown
                                      name="status"
                                      options={[
                                        { lable: "Active", value: "Active" },
                                        {
                                          lable: "Inactive",
                                          value: "Inactive",
                                        },
                                      ]}
                                      onChange={(e) => this.onStatusChange(e)}
                                      defaultSelected={this.state.status}
                                      lable=""
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row"></div>
                            </form>
                          </div>
                          <div className="actions clearfix">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={() => this.handleSaveQc()}
                            >
                              <i className="uil uil-edit"></i>{" "}
                              {this.state.button}
                            </button>
                          </div>
                          <ConfirmBox
                            openModal={isShowConfirmBox}
                            confirmMsg="This entity might being used in one or more projects. Inactivating will remove it from there. Do you still want to continue?"
                            handleConfirmModal={this.handleConfirmModal}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (props) => {
  return {
      loginUserData: props.loginUserData,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddQc);