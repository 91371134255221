import React, { Component } from 'react'
import AccessDenied from '../common/AccessDenied';
import masterDataService from '../services/MasterDataService';
import SimpleReactValidator from "simple-react-validator";
import { rules, Permission } from "../../abac.config";
import { createNotification } from "../helpers/Notification";
import { AllowedTo } from "react-abac";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import ConfirmBox from '../common/ConfirmBox'

class TitleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleData:[],
      loader:false,
      removeId:"",
     isShowConfirmBox:false,
    } 
  } 
  componentDidMount=()=>{
    this.getAllTitle();
  }
  getAllTitle=()=>{
    masterDataService.getTitles().then((response)=>{
      this.setState({titleData:response.data})
    })
    .catch((error)=>{
      if (error.response.status === 401) {
        createNotification("error", "Your login session has been expired!");
        localStorage.clear();
        this.props.history.push("/login");
        //this.setState({loader:false});
      } else {
        createNotification("error", error.response.data.error.message);
        //this.setState({loader:false});
      }
    })
  }
  actionFormat=(cell,row,rowIndex,formatExtraData)=>{
    return(
        <div>
            <td>
              <ul className="list-inline mb-0">
              <li className="list-inline-item">
               <a href="javascript:void(0)" className="text-danger">
                <i className="uil uil-trash-alt font-size-18" onClick={()=>this.setState({removeId:row.id,isShowConfirmBox:true})}></i>
               </a>
              </li>
              </ul>
            </td>
        </div>
    )
}
handleConfirmModal=(isYes)=>{
  if(isYes){
    this.setState({
        isShowConfirmBox:!this.state.isShowConfirmBox,
        loader:true,
       
      });
      const{removeId}=this.state;
      masterDataService.deleteTitles(removeId).then((response)=>{
        if(response.status===204){

          createNotification("success", "Titles item deleted successfully");
          this.setState({removeId: "",isShowConfirmBox:false, loader: false });
          this.getAllTitle();
      }else{
        createNotification("error",response.data.message);
      }
      }).catch((error)=>{
        if (error.response.status === 401) {
          createNotification("error", "Your login session has been expired!");
          localStorage.setItem("user-token", "");
          this.props.history.push("/login");
        } else {
          createNotification("error", error.response.data.error.message);
          this.setState({ removeId: "",isShowConfirmBox:false, loader: false });
        }
      })
  }else {
    this.setState({ removeId: "",isShowConfirmBox:false, loader: false });
  }
}
  
  render(){
        const { isShowConfirmBox,titleData } = this.state;
        const handleOnTableChange = (type, newState) =>{
          console.log("type",type);
          if(type === "sort") {
            if(newState.sortOrder == "asc"){
              titleData.sort((obj1,obj2) => {
               return  obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
               ;     
            }); 
          }
            else
            {titleData.sort((obj2,obj1) => {
                return obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
                ; 
             });
            }
          }
         
        
      }
        const columns=[
            { dataField: "name", text: "Name",sort:true,formatter:this.nameFormat },
        {dataField :" " ,text:"Action",formatter:this.actionFormat}
    ];
    const sizePerPageOptions = [
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
      {
        text: '30',
        value: 30,
      },
      {
        text: '50',
        value: 50,

      },
      {
        text: '100',
        value: 100,
      },
      // Add more options as needed
    ];  
        let roleKey = this.props.loginUserData.roleKey;
        const isAccess = (roleKey!=="" && rules[roleKey][Permission.MASTER_WRITE]) ?true:false;

        return(
        <>
        {!isAccess ? <AccessDenied />  : 
         <div className="main-content">
         <ConfirmBox
            openModal={isShowConfirmBox}
            confirmMsg="Are you sure to delete this  item?"
            handleConfirmModal={this.handleConfirmModal}
          />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <ol className="breadcrumb m-0">
                    <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                    &nbsp; / &nbsp;
                    <Link to={`/title`}><li className="breadcrumb-item">Title</li></Link>
                  </ol>
                </div>
                <h4 className="mb-0">Title List</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card card-block">
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <Link to="/addTitle">
                            <span className="btn btn-success waves-effect waves-light">
                              <i className="uil uil-plus"></i> Add New
                            </span>
                          </Link>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-inline float-md-end mb-3">
                          <div className="search-box ms-2">
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <BootstrapTable
                      remote={{
                        pagination:false,
                        sort:true
                      }}
                       onTableChange={handleOnTableChange}
                      keyField="id"
                      data={titleData}
                      columns={columns}
                      pagination={paginationFactory({   sizePerPageList:sizePerPageOptions })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        }
        </>)
    }
}
const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
  };
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
  }, dispatch);
  
  export default connect(mapStateToProps, mapDispatchToProps)(TitleList);
