import React, { Component } from 'react'
import DatePicker from "react-datepicker";
import projectServices from '../../project/services/projectServices';
import { createNotification } from '../../helpers/Notification';
import { setShopFabFormData } from '../../project/redux/projectAction';
import { setFieldInstallFormData } from '../../project/redux/projectAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { rules, Permission } from "../../../abac.config";
import moment from 'moment';
import ModalPopUp from '../../common/ModalPopUp';
import { AllowedTo } from "react-abac";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaUndoAlt, FaRegWindowClose } from 'react-icons/fa';
import FilesPopUp from '../../project/component/FilesPopUp';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

class FieldCrewPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Field Crew",
      initialState: {
        projectName: "",
        scope: "",
        startDate: "",
        scheduleCompletionDate: "",
        completionDate: "",
        dailyGoals: "",
        dailyActuals: "",
        description: "",
        comments: "",
        fieldCrew: "",
      },
      fieldCrewList: [],
      selectedShopName: '',
      selectedTableName: '',
      showFilePopUp: false,
      drawingTypeData: [],
      drawingData: [],
      filesToView: [],
      previousCompletionDate:"",
      isPreviousCompletionDateSelected:false
    };
  }
  componentDidMount = () => {
    const { projectId, selectedScope } = this.props;
    if (typeof projectId !== "undefined" && typeof selectedScope !== "undefined") {
      this.getDrawingType();
      this.getDrawingDoc();
      this.getFieldCrew();
      this.getFieldInstall(projectId, selectedScope);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.selectedScope !== prevProps.selectedScope) {
      const { projectId, selectedScope } = this.props;
      this.getFieldInstall(projectId, selectedScope);
    }
  }
  getFieldInstall = (projectId, selectedScope) => {
    projectServices.getFieldInstall(projectId, selectedScope).then((response) => {
      if (response.data.length) {
        let res = response.data[0];
        console.log(res);
        let fieldCrewArray = res.projectFieldInstallFieldCrews.map((obj) => {
          return obj.fieldCrewId;
      })
        let fabData = { description: res.description, comments: res.comments, dailyGoals: res.dailyGoals, dailyActuals: res.dailyActuals, startDate: res.startDate !== null ? new Date(moment(res.startDate).format('MM/DD/YYYY')) : "", scheduleCompletionDate: res.scheduleCompletionDate !== null ? new Date(moment(res.scheduleCompletionDate).format('MM/DD/YYYY')) : "", completionDate: res.completionDate !== null ? new Date(moment(res.completionDate).format('MM/DD/YYYY')) : "", fieldCrew: fieldCrewArray };
        this.props.setFieldInstallFormData({ ...fabData });
      } else {
        this.props.setFieldInstallFormData({ ...this.state.initialState });
      }

    }).catch((error) => {
      console.log(error);
    })
  }
  getFieldCrew = () => {
    projectServices.getFieldCrew().then((res) => {
      this.setState({ fieldCrewList: res.data.data });
    }).catch((error) => {
      console.log(error);
    })
  }
  handleOnChange = (event) => {
    const { setFieldInstallFormData, fieldInstallFormData } = this.props;
    let fieldInstallFormDataClone = { ...fieldInstallFormData };
    if (event.target.name === "dailyGoals") {
      setFieldInstallFormData({ ...fieldInstallFormDataClone, dailyGoals: event.target.value });
    } else if (event.target.name === "dailyActuals") {
      setFieldInstallFormData({ ...fieldInstallFormDataClone, dailyActuals: event.target.value });
    } else if (event.target.name === "description") {
      setFieldInstallFormData({ ...fieldInstallFormDataClone, description: event.target.value, });
    } else if (event.target.name === "comments") {
      setFieldInstallFormData({ ...fieldInstallFormDataClone, comments: event.target.value });
    } else if (event.target.name === "fieldCrew") {
      let tableValues = Array.from(event.target.selectedOptions, option => option.value);
      setFieldInstallFormData({ ...fieldInstallFormDataClone, fieldCrew: tableValues });

    } else if (event.target.name === "completionDate") {
      setFieldInstallFormData({ ...fieldInstallFormDataClone, completionDate: event.target.value });
    }
  }

  handleDateChange = (date, type) => {

    let formatedDate = date
    const { setFieldInstallFormData, fieldInstallFormData } = this.props;
    let fieldInstallFormDataClone = { ...fieldInstallFormData };

    if (type === "startDate") {
      if (fieldInstallFormData.scheduleCompletionDate !== "" || fieldInstallFormData.completionDate !== "") {
        if (Date.parse(date) < Date.parse(fieldInstallFormData.scheduleCompletionDate) 
        // && Date.parse(date) < Date.parse(fieldInstallFormData.completionDate)
        ) {
          setFieldInstallFormData({ ...fieldInstallFormDataClone, startDate: formatedDate });
        } else {
          createNotification(
            "error",
            "Start date should be less than Scheduled completion date ."
          );
        }
      } else {
        setFieldInstallFormData({ ...fieldInstallFormDataClone, startDate: formatedDate });
      }

    } else if (type === "scheduleCompletionDate") {
      if (fieldInstallFormData.startDate !== "") {
        if (Date.parse(date) >= Date.parse(fieldInstallFormData.startDate)) {
          setFieldInstallFormData({ ...fieldInstallFormDataClone, scheduleCompletionDate: formatedDate });
        } else {
          createNotification(
            "error",
            "Scheduled completion date should be greater than Start date"
          );
        }
      } else {
        setFieldInstallFormData({ ...fieldInstallFormDataClone, scheduleCompletionDate: formatedDate });
      }
    } else if (type === "completionDate") {
      let prevComplDate;
      if (fieldInstallFormData.startDate !== "") {
        if (Date.parse(date) >= Date.parse(fieldInstallFormData.startDate)) {
          prevComplDate=fieldInstallFormData.completionDate;
          this.setState({previousCompletionDate:prevComplDate,isPreviousCompletionDateSelected:true})
          setFieldInstallFormData({ ...fieldInstallFormDataClone, completionDate: formatedDate });
        } else {
          createNotification(
            "error",
            "Completion date should be greater than Start date"
          );
        }
      } else {
          prevComplDate=fieldInstallFormData.completionDate;
          this.setState({previousCompletionDate:prevComplDate,isPreviousCompletionDateSelected:true})
        setFieldInstallFormData({ ...fieldInstallFormDataClone, completionDate: formatedDate });
      }
    }
  }
  getDrawingType = () => {
    projectServices
      .getDrawingType()
      .then((res) => {
        if (res.status) {
          this.setState({ drawingTypeData: res.data.data });
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error);
      });
  };
  handleShowFilePopUp = () => {
    const { showFilePopUp } = this.state;
    this.setState({ showFilePopUp: !showFilePopUp })
  }
  getDrawingDoc = () => {
    const { projectId, selectedScope } = this.props;
    projectServices
      .getDrawingDoc(projectId, selectedScope)
      .then((res) => {
        if (res.status) {
          this.setState({ drawingData: res.data.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleShowFilePopUp = () => {
    const { showFilePopUp } = this.state;
    this.setState({ showFilePopUp: !showFilePopUp })
  }
  viewFiles = (typeId) => {
    const { drawingData } = this.state;
    const data = drawingData.filter(
      (item) => Number(item.drawingType) === Number(typeId)
    );

    if (Object.keys(data).length > 0) {
      if (data[0].hasOwnProperty('detailDrawingDocs')) {
        data[0].detailDrawingDocs.sort((a, b) => {
          if (a.upload_fkey.fileName && b.upload_fkey.fileName) {
            return a.upload_fkey.fileName > b.upload_fkey.fileName ? 1 : -1
          } else {
            return 1;
          }
        })
      } else {
        data[0].detailDrawingDocs = [];
      }

    }

    this.setState({ showFilePopUp: true, filesToView: data })
  }

  undoCompletionDate=()=>{
    const { setFieldInstallFormData, fieldInstallFormData } = this.props;
    const{previousCompletionDate,isPreviousCompletionDateSelected}=this.state;
    let fieldInstallFormDataClone = { ...fieldInstallFormData };

    if(document.getElementById("undoCompletionDate")){
      if(isPreviousCompletionDateSelected){
        setFieldInstallFormData({...fieldInstallFormDataClone,completionDate:previousCompletionDate})
      }
    }
  }
  //clear the completion date-----------//
  clearCompletionDate=()=>{
    const { setFieldInstallFormData, fieldInstallFormData } = this.props;
    const{previousCompletionDate,isPreviousCompletionDateSelected}=this.state;
    let fieldInstallFormDataClone = { ...fieldInstallFormData };
    if(document.getElementById("clearCompletionDate")){
      this.setState({isPreviousCompletionDateSelected:true,previousCompletionDate:fieldInstallFormDataClone.completionDate})
      setFieldInstallFormData({...fieldInstallFormDataClone,completionDate:null})
    }
  }

  render() {
    const { description, comments, dailyGoals, dailyActuals, releaseDate, scheduled, dateCompleted, fieldCrew, startDate, scheduleCompletionDate, completionDate } = this.props.fieldInstallFormData;
    const { list, fieldCrewList, storedList, selectedShopName, drawingData,selectedTableName, title, drawingTypeData } = this.state;
    let roleKey = this.props.loginUserData.roleKey;
    const isDisable = (roleKey !== "" && rules[roleKey][Permission.FIELDCREW_WRITE]) ? "" : "disabled";
    const { closeModal, projectName, scopeName } = this.props;
    return (
      <div>

        <div
          className="project-button-box"
          style={{ display: "flex", justifyContent: "space-between", padding: "20px 20px 0", fontSize: "16px" }}
        >
          <span className="fw-bolder">
            {title}
          </span>

          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => closeModal()}
          ></button>
        </div>
        <div className="shop-fabrication-block">
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-3">
                  <label className="form-label">Project</label>
                  <input
                    placeholder="Type here"
                    className="form-control"
                    type="text"
                    name="projectName"
                    value={projectName}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-3">
                  <label className="form-label">Scope</label>
                  <input
                    placeholder="Type here"
                    className="form-control"
                    type="text"
                    name="scopeName"
                    value={scopeName}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="mb-3">
                  <label className="form-label">Crew</label>
                  <select
                    className="form-select"
                    name="fieldCrew"
                    multiple
                    value={fieldCrew}
                    onChange={(e) => this.handleOnChange(e)}
                    disabled={isDisable}
                  >
                    {/* <option value="0">Select</option> */}
                    {fieldCrewList.length > 0 &&
                      fieldCrewList.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="mb-3">
                  <label className="form-label">Start Date</label>
                  <DatePicker className="form-control date-form-control" onChange={(date) => this.handleDateChange(date, "startDate")}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                    selected={startDate}
                    disabled={isDisable}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label className="form-label">Scheduled</label>
                  <DatePicker className="form-control date-form-control" onChange={(date) => this.handleDateChange(date, "scheduleCompletionDate")}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                    selected={scheduleCompletionDate}
                    disabled={isDisable}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label className="form-label">Completion Date</label>
                  <div className="row align-items-center">
                    <div className="col-lg-9">
                      <DatePicker className="form-control date-form-control"
                        onChange={(date) => this.handleDateChange(date, "completionDate")}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="MM/DD/YYYY"
                        selected={completionDate}
                        disabled={isDisable}
                      />
                    </div>
                    <div className="col-lg-3">
                      <AllowedTo perform={Permission.PROJECT_WRITE}>
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Undo Completion Date
                            </Tooltip>
                          }
                        >

                          <a href="javascript:void(0)" className="text-danger">
                            <FaUndoAlt id='undoCompletionDate' onClick={this.undoCompletionDate}></FaUndoAlt>
                          </a>

                        </OverlayTrigger>
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Clear Completion Date
                            </Tooltip>
                          }
                        >
                          <button type="button" className='border-0 bg-transparent'>
                            <FaRegWindowClose id='clearCompletionDate' onClick={this.clearCompletionDate}></FaRegWindowClose>
                          </button>
                        </OverlayTrigger>
                      </AllowedTo>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-4">
                <div className="mb-3">
                  <label className="form-label">View Drawing Files</label>
                  <div className="card card-box-top">
                    <div className="card-body">
                      <div className="card-list-block">
                      {drawingTypeData.length > 0 && drawingTypeData.map((data, index) => {
                            
                            // Check if any item in drawingData matches the current drawingTypeData.id and contains detailDrawingDocs
                            const hasDrawingType = drawingData.some(drawing => drawing.drawingType === data.id && drawing.detailDrawingDocs && drawing.detailDrawingDocs.length>0);
                            return (
                              <div key={index} className="drawing-row-list d-flex align-items-center justify-content-between" >
                                <div className="drawing-Wrap-1">
                                  <div className="drawing-title-1" title={data.title}>{data.title}</div>
                                  <div className="clip-Icon">
                                    {/* conditions because of which paper clip icon will be displayed ------ */}
                                    {hasDrawingType ?

                                      <FontAwesomeIcon icon={faPaperclip} className="ml-2 mr-0" /> : ""}
                                  </div>
                                </div>
                                <>
                                  <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => this.viewFiles(data.id)} >
                                    {" "} View Files
                                  </button>
                                </>

                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {/* <a
                    href="javascript:void(0)"
                    role="menuitem"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => this.viewFiles()}
                  >
                    View Files
                  </a> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Daily Goals</label>
                  {/* >Daily Goals */}
                  <textarea
                    placeholder="Type here"
                    required=""
                    className="form-control"
                    rows="3"
                    onChange={(e) => this.handleOnChange(e)}
                    name="dailyGoals"
                    value={dailyGoals}
                    maxLength="700" 
                    disabled={isDisable}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Daily Actuals</label>
                  <textarea
                    placeholder="Type here"
                    required=""
                    name="dailyActuals"
                    className="form-control"
                    rows="3"
                    onChange={(e) => this.handleOnChange(e)}
                    value={dailyActuals}
                    maxLength="700"
                    disabled={isDisable}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea
                    placeholder="Type here"
                    required=""
                    name="description"
                    className="form-control"
                    rows="3"
                    onChange={(e) => this.handleOnChange(e)}
                    value={description}
                    maxLength="700"
                    disabled={isDisable}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Comments</label>
                  <textarea
                    placeholder="Type here"
                    required=""
                    name="comments"
                    className="form-control"
                    rows="3"
                    onChange={(e) => this.handleOnChange(e)}
                    value={comments}
                    maxLength="700"
                    disabled={isDisable}
                  />
                </div>
              </div>
            </div>
            <ModalPopUp
              componentData={
                <FilesPopUp
                  closeModal={this.handleShowFilePopUp}
                  fileData={this.state.filesToView}
                />
              }
              openModal={this.state.showFilePopUp}
            />
            <fieldset  disabled={isDisable}>
            <div className="actions clearfix saveBtn">
              <a
                href="javascript:void(0)"
                role="menuitem"
                className="btn btn-primary waves-effect waves-light"
                onClick={() => this.props.saveFieldCrew()}
              >
                Save
              </a>
            </div>
          </fieldset>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (props) => {
  return {
    fieldInstallFormData: props.fieldInstallFormData,
    loginUserData: props.loginUserData,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setFieldInstallFormData: setFieldInstallFormData,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FieldCrewPopup);