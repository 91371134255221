 import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import Loader from '../common/Loader';
import projectServices from "../project/services/projectServices";
import { createNotification } from "../helpers/Notification";


 const RecentBirthdayPopUp = (props) => {
    const closeModel = props.closeModel;
    const [birthdayList, setBirthdayList] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(()=>{
        getAllBirthdays();

    },[])
    
    const getAllBirthdays = () =>{
      projectServices
      .getAllBirthdays()
      .then((response) => {
        let responseData = response.data;
        console.log("dataa",response.data);
        // let listArr = [];
        // responseData && responseData.map((item) => {
        //     listArr.push({ lable: item.name, value: item.id });
        // });
        // console.log("lstadata -- ",listArr);
        // this.setState({
        //   titlesList: listArr,
        // });
        setBirthdayList(response.data)

        // console.log("api call response.data.designation", response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          createNotification("error", "Your login session has expired .");
          props.history.push("/login");
        }
      });

    }

    const handleOnTableChange = (type, newState) =>{
        console.log("type",type);
        if(type === "sort") {
          if(newState.sortOrder == "asc"){
            birthdayList.sort((obj1,obj2) => {
           return obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
           ;          
           
        }); 
      }
        else
        {
            birthdayList.sort((obj2,obj1) => {
            return  obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
            ;          
            
         });
        }
        }
      }

      const CustomDateFormatter = ( cell ) => {
        const dateObject = new Date(cell);
        const formattedDate = dateObject.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: 'UTC', // Make sure to set the correct timezone
        });
      
        return <span>{formattedDate}</span>;
      };
      
    const columns = [
        {
            dataField: "employee_name",
            text: "Name",
            sort:true,
            headerStyle: () => {
              return { width: "15%", verticalAlign: "top" };
            },
    
          },
          {
            dataField: "dob",
            text: "Birth Date",
            sort:true,
            headerStyle: () => {
              return { width: "15%", verticalAlign: "top" };
            },
            formatter: CustomDateFormatter,
    
          },
    ]
    return (
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title mt-0 text-dark fw-bold text-center"
              id="myModalLabel"
            >
              Recent Birthdays {" "}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                closeModel(false);
              }}
            ></button>
          </div>
          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-12 table-responsive">
                                <div className="mt-2 ">
                                  <BootstrapTable
                                    remote={true}
                                    keyField="id"
                                    data={
                                        birthdayList.length > 0
                                        ? birthdayList
                                        : []
                                    }
                                    onTableChange={handleOnTableChange}
                                    columns={ columns }
                                    // pagination={pagination}
                                    noDataIndication={
                                      loader === true ? (
                                        <Loader />
                                      ) : (
                                        <p style={{ color: "red" }}>
                                          {"No Data Found"}
                                        </p>
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>          
          </div>
        
      );
 }
 
 export default RecentBirthdayPopUp
 