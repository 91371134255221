import React from 'react'
import { useState, useEffect } from 'react';
import projectServices from '../services/projectServices';
import ConfirmBox from "../../common/ConfirmBox"
import { createNotification } from '../../helpers/Notification';
import { AllowedTo } from 'react-abac';
import { Permission } from '../../../abac.config';
const TransportFilesPopup = (props) => {
   


    const [transportFileDocs, setTransportFileDocs] = useState([]);
    const [isShowConfirmBox, setIsShowConfirmBox] = useState(false);
    const [fileId, setFileId] = useState(0);
    // useEffect(() => {
    //     getImagesTransport()
    // }, [])

    const getImagesTransport = () => {
        projectServices.getImagesTransport(props.projectId, props.selectedScope).then((response) => {
            if (response.data.data.length) {

                let res = response.data.data[0]
                console.log({ response: res });
                setTransportFileDocs(res.hasOwnProperty('projectFreightDeliveryData') ? res.projectFreightDeliveryData : [])
            }
        })
            .catch((error) => {
                console.log(error);

            })
    }
    const handleConfirmModal = (isYes) => {
        if (isYes) {
            props.handleDeleteImages(fileId)
            setIsShowConfirmBox(false);
            setFileId(0);
            // getImagesTransport();
        }
        else {
            setFileId(0);
            setIsShowConfirmBox(false);
        }
    }
 

    return (
        <div>
            <ConfirmBox
                openModal={isShowConfirmBox}
                confirmMsg="Are you sure to delete this image?"
                handleConfirmModal={handleConfirmModal}
            />
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Uploaded Images
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => props.closeModal(false)}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="mb-3 accordWrap">
                        <div className="col-md-12" style={{ height: "450px" }}>
                            {props.imageFiles.length <= 0 ?
                                <h3 style={{ color: 'red', textAlign: 'center' }}>
                                    No Images Uploaded
                                </h3>
                                :
                                <table style={{ width: "100%" }}>
                                    {props.imageFiles.length > 0 &&
                                        props.imageFiles.map((fileData, i) => {
                                            let filePath = fileData.upload_fkey?.filePath;
                                            let fileName = fileData.upload_fkey?.fileName;
                                            let fileId = fileData.id;
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <a
                                                            className="d-inline-block"
                                                            //href="#"
                                                            onClick={() => window.open(filePath, "_blank")}
                                                        >
                                                            {fileName}
                                                        </a>
                                                    </td>
                                                    <td  style={{ textAlign: "right" }}>
                                                       <div style={{display:"flex" ,alignItems:"center",justifyContent:"flex-end"}} >
                                                       <a
                                                            className="btn btn-primary waves-effect waves-light mr-2"
                                                            // href="#"
                                                            onClick={() => window.open(filePath, "_blank")}
                                                        >
                                                            View
                                                        </a>
                                                        <AllowedTo perform={Permission.TRANSPORT_WRITE}>
                                                            <a href="javascript:void(0)" className="text-danger">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-plus btn-danger waves-effect waves-light ml-1"
                                                                    onClick={()=>{setFileId(fileData.id);setIsShowConfirmBox(true)} }
                                                                    >
                                                                        Remove
                                                                </button>

                                                            </a>
                                                        </AllowedTo>
                                                       </div>
                                                       
                                                    </td>
                                                    {/* <td style={{ textAlign: "right",width:'3%' }}>
                                                       
                                                    </td> */}
                                                </tr>
                                            );
                                        })}
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TransportFilesPopup;
