import React, { Component } from "react";
// import AddEmployeePopUp from "./AddEmployeePopUp";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../common/Loader";
import { rules, Permission } from "../../abac.config";
import { Link } from "react-router-dom";
import { createNotification } from "../helpers/Notification";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination from "../project/component/Pagination/Pagination";
import BootstrapTable from "react-bootstrap-table-next";
import ModalPopUp from "../common/ModalPopUp";
import { dbDateFormat } from "../helpers/Util";
import projectServices from "../project/services/projectServices";
import AccessDenied from "../common/AccessDenied";
import ConfirmBox from "../common/ConfirmBox";
import { AllowedTo } from "react-abac";
// import RecentBirthdayPopUp from "./RecentBirthdayPopUp";
import { saveAs } from "file-saver";
import EmployeeListPopup from "./EmployeeListPopup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";

class AllGroupList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowConfirmBox: false,
      isShowEmployeeListPopup: false,
      loader: false,
      removeId: 0,
      groupId: 0,
      isOpenAddEmployeePop: false,
      isOpenBirthdayPop: false,
      allGroupList: [],

      //pagination constants
      pageChange: 1,
      skip: 0,
      limit: 10,
      count: 0,
      //previous paginations -------
      previousPage:1,
      previousSkip:0,
      previousLimit:10,
      search:"",
      resDataLen:0,
    };
  }

  componentDidMount = () => {
    // let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
    console.log(window.history); 
    if(window.history.state.state !== undefined){
      const currentPage = window.history?.state?.state?.data.key
      // this.setState({ pageChange:currentPage})
      localStorage.setItem('EmployeeContactPagination',JSON.stringify({previousPage:window.history.state.state.data.currentPage,previousLimit:window.history.state.state.data.currentLimit,
      previousSkip:window.history.state.state.data.currentSkip,search:window.history.state.state.data.search
      }))
    }
    let filters ;
    if (localStorage.getItem('AllGroupListPagination')!==null) {
      const savedPagination = JSON.parse(localStorage.getItem('AllGroupListPagination'));
      const{currentPage, currentSkip ,currentLimit}=savedPagination;
    //  console.log(currentPage,":::::::",currentSkip);
    
       if(currentPage>=1 && currentSkip>=0){
        // alert(1)
        this.setState({skip:currentSkip,limit:currentLimit,pageChange:currentPage})
         filters = `?filter[skip]=${currentSkip}&filter[limit]=${currentLimit}`;
       } 
    }else{
      // alert(2)
      filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`
     
    }
  
      
      
     
    this.setState({ loader: true }, () => {
      this.getAllGroupCount(filters);
    });
  };

  getAllGroupCount = (filters) => {
    // let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    let countReqParam = `where={}`;
    projectServices
      .getAllGroupCount(countReqParam)
      .then((response) => {
        this.setState({ count: response.data.count }, () => {
          this.getAllGroups(filters);
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          createNotification("error", "Your login session has expired .");
          this.props.history.push("/login");
        }
      });
  };

  getAllGroups = (filters) => {
  
    projectServices
      .getGroups(filters)
      .then((response) => {
        this.setState({
          allGroupList: response.data,
          resDataLen: response.data.length,
          loader: false,
        },()=>{
          this.updateLocalStorage()
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  updateLocalStorage=()=>{
    const{pageChange,limit,skip}=this.state;
    localStorage.setItem('AllGroupListPagination', JSON.stringify({ currentPage: pageChange, currentSkip: skip, currentLimit: limit }));
  }
  handleConfirmModal = (isYes) => {
    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    if (isYes) {
      this.setState({
        isShowConfirmBox: !this.state.isShowConfirmBox,
        loader: true,
      });
      const{skip,limit,pageChange,resDataLen,removeId}=this.state;
      let localSkip=0;
      let localPageChange=0;
      let filters; 
      if(resDataLen===1 && pageChange>1){
         localSkip=skip-limit;
         localPageChange=pageChange-1;
       filters = `?filter[skip]=${localSkip}&filter[limit]=${this.state.limit}`
       this.setState({pageChange:localPageChange,skip:localSkip})
       }else{
           filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`
       } 
      projectServices
        .deleteGroup(removeId)
        .then((response) => {
          createNotification("success", "Group deleted successfully .");
          // this.setState({ });
          this.getAllGroupCount(filters);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ removeId: 0, isShowConfirmBox: false, loader: false });
    }
  };
  actionFormat = (cell, row, rowIndex, formatExtraData) => {
    // const data = { key: "group" };
    const{pageChange,skip,limit,search}=this.state;
    // localStorage.setItem('AllGroupListPagination', JSON.stringify({ currentPage: pageChange, currentSkip:skip,currentLimit:limit}));
    const data = { key: "group",currentPage:pageChange,currentSkip:skip,currentLimit:limit,search:search  };
    return (
      <div>
        <td>
          <ul className="list-inline mb-0">
          <OverlayTrigger
              key="topEdit"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-top`}>Edit</Tooltip>
              }
            >  
            <li className="list-inline-item">
              <Link to={{
                pathname:`/addGroup/${row.id}`
                
                }}>
                <i className="uil uil-pen font-size-18"></i>
              </Link>
            </li>
            </OverlayTrigger>
            
            <OverlayTrigger
              key="topMail"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-top`}>Send Mail and SMS</Tooltip>
              }
            >  
            <li className="list-inline-item">
              <Link
                to={{ pathname: `/sendMail/${row.id}`, state: { data: data } }}
                // to={`/sendMail/${row.id}`}
                // state= {{from:"groupMail"}}
              >
                <i class="uil uil-envelope-upload font-size-18"></i>
              </Link>
            </li>
            </OverlayTrigger>
            
            <OverlayTrigger
              key="topList"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-top`}>Employee List</Tooltip>
              }
            >
              <li className="list-inline-item">
                <a href="javascript:void(0)" className="">
                  <i
                    className="uil uil-users-alt font-size-18"
                    onClick={() =>
                      this.setState({
                        groupId: row.id,
                        isShowEmployeeListPopup: true,
                      })
                    }
                  ></i>
                </a>
              </li>
            </OverlayTrigger>

            <OverlayTrigger
              key="topDelete"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-top`}>Delete</Tooltip>
              }
            > 
             <li className="list-inline-item">
             <a href="javascript:void(0)" className="text-danger">
              <i
                className="uil uil-trash-alt font-size-18"
                onClick={() =>
                  this.setState({ removeId: row.id, isShowConfirmBox: true })
                }
              ></i>
            </a>
          </li>
          </OverlayTrigger>
            
          </ul>
        </td>
      </div>
    );
  };

  handleRecentBirthdays = () => {
    this.setState({
      isOpenBirthdayPop: true,
    });
  };
  handleCloseRecentBirthdays = () => {
    this.setState({ isOpenBirthdayPop: false });
  };

  downloadHRReport = () => {
    // const{slocationId,isManuallyCreated,search,isSearch}=this.state;
    const URL = process.env.REACT_APP_API_URL;
    projectServices
      .getHumanResourceRecord()
      .then((res) => {
        if (res.data.status) {
          const fileLink = `${URL}${res.data.data}`;
          const pathArr = fileLink.split("/");
          saveAs(
            // `${process.env.REACT_APP_API_URL}'/'${fileLink}`
            fileLink,
            pathArr[pathArr.length - 1]
          );
          createNotification(
            "success",
            "Human Resource record downloaded successfully."
          );
        }
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 422) {
          if (error.response.message) {
            createNotification("error", error.response.message);
          } else {
            createNotification("error", "No Human Resource data exists.");
          }
        }
      });
  };
  handleCloseModal = () => {
    this.setState({ isShowEmployeeListPopup: false });
  };

  render() {
    let roleKey = this.props.loginUserData.roleKey;
    const isAccess =
      roleKey !== "" && rules[roleKey][Permission.HUMANRESOURCE_WRITE]
        ? true
        : false;
    const {
      isShowConfirmBox,
      isShowEmployeeListPopup,
      loader,
      allGroupList,
      pageChange,
      limit,
      count,
      groupId,
    } = this.state;


    const sizePerPageOptions = [
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
      {
        text: '30',
        value: 30,
      },
      {
        text: '50',
        value: 50,

      },
      {
        text: '100',
        value: 100,
      },
      // Add more options as needed
    ];

    //-Pagination Constant-----------------------------------------------------//
    let that = this;
    const pagination = paginationFactory({
      page: pageChange,
      sizePerPage: limit,
      lastPageText: ">>",
      firstPageText: "<<",
      nextPageText: ">",
      prePageText: "<",
      totalSize: count,
      // showTotal: true,
      alwaysShowAllBtns: true,
      sizePerPageList:sizePerPageOptions,
      onPageChange: function (page, sizePerPage) {
        let skp = (page - 1) * sizePerPage;
        let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
       
        that.setState({
          pageChange: page,
          allGroupList: [],
          loader: true,
          skip:skp
        });
        that.getAllGroups(filters);
        // this.getAllGroupCount(filters);
      },
      onSizePerPageChange: function (sizePerPage, page) {
        let skp = (page - 1) * limit;
        // let skp =0;
        that.setState({
          pageChange: 1,
          limit: sizePerPage,
          allGroupList: [],
          loader: true,
        });
        let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
        
        that.getAllGroups(filters);
        // this.getAllGroupCount(filters);
      },
    });
    //------------------------------------------------------------------------//

    const handleOnTableChange = (type, newState) => {
      if (type == "sort") {
        if (newState.sortOrder === "asc") {
          allGroupList.sort((obj1, obj2) => {
            return obj1[newState.sortField]
              .trim()
              .localeCompare(obj2[newState.sortField].trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              });
          });
        } else {
          allGroupList.sort((obj2, obj1) => {
            return obj1[newState.sortField]
              .trim()
              .localeCompare(obj2[newState.sortField].trim(), undefined, {
                numeric: true,
                sensitivity: "base",
              });
          });
        }
      }
    };

    const CustomDateFormatter = (cell) => {
      const dateObject = new Date(cell);
      const formattedDate = dateObject.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC", // Make sure to set the correct timezone
      });

      return <span>{formattedDate}</span>;
    };

    const columns = [
      {
        dataField: "name",
        text: "Group Name",
        sort: true,
        headerStyle: () => {
          return { verticalAlign: "top" };
        },
      },
      // {
      //   dataField: "",
      //   text: "Total Employee",
      //   sort: true,
      //   headerStyle: () => {
      //     return { width: "30%",verticalAlign: "top" };
      //   },
      // },
      {
        dataField: "",
        text: "Action",
        formatter: this.actionFormat,
        headerStyle: () => {
          return { width: "30%", verticalAlign: "top" };
        },
      },
    ];

    return (
      <div>
        {loader === true ? (
          <Loader />
        ) : (
          <div className="main-content shopPersonal">
            <ConfirmBox
              openModal={isShowConfirmBox}
              confirmMsg="Are you sure to delete this group?"
              handleConfirmModal={this.handleConfirmModal}
            />

            <ModalPopUp
              componentData={
                <EmployeeListPopup
                  closeModal={this.handleCloseModal}
                  groupId={groupId}
                />
              }
              openModal={isShowEmployeeListPopup}
            />
            <div className="page-content">
              <div className="container-fluid">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <ol className="breadcrumb m-0">
                    <Link to={`/#`}>
                      <li className="breadcrumb-item">Home</li>
                    </Link>
                    &nbsp; / &nbsp;
                    <Link to={`/employeeContact`}>
                      <li className="breadcrumb-item">Employee Contact</li>
                    </Link>
                    &nbsp; / &nbsp;
                    <Link to={`/allGroupList`}>
                      <li className="breadcrumb-item">Group List</li>
                    </Link>
                  </ol>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-4 mb-3"></div>

                  {loader === true ? (
                    <Loader />
                  ) : (
                    <div className="mt-3">
                      <div className="card card-block">
                        <div className="card-body">
                          <div>
                            <h4> All Groups</h4>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 table-responsive">
                              <div className="mt-2 ">
                                <BootstrapTable
                                  remote={true}
                                  keyField="id"
                                  data={
                                    allGroupList.length > 0 ? allGroupList : []
                                  }
                                  onTableChange={handleOnTableChange}
                                  columns={columns}
                                  pagination={pagination}
                                  noDataIndication={
                                    loader === true ? (
                                      <Loader />
                                    ) : (
                                      <p style={{ color: "red" }}>
                                        {"No Data Found"}
                                      </p>
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /* model to open items in each project and make them editable */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (props) => {
  return {
    projectData: props.projectData,
    selectedScopeItemID: props.selectedScopeItemID,
    loginUserData: props.loginUserData,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AllGroupList);
