import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleReactValidator from 'simple-react-validator';
import { DropDown } from '../common';
import { createNotification } from '../helpers/Notification';
import { dbDateFormat, getApiUrl, getLoginToken, getRoleKey } from '../helpers/Util';
import { getManagerList } from './Helper';
import projectServices from './services/projectServices';
import { rules, Permission } from "../../abac.config";
import { AllowedTo } from "react-abac";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setProjectData, setSelectedScopeItemID } from './redux/projectAction';
import Loader from "../common/Loader";
import AlertBox from "../common/AlertBox";
import { Link } from 'react-router-dom';
import{ MdCalculate } from 'react-icons/fa'
class EditProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            managerList: [],
            managerId: "",
            jobNumber: "0000",
            startDate: "",
            endDate: "",
            projectName: "",
            description: "",
            addItem: "",
            contract_price: "",
            total_cost: "",
            amount_billed: "",
            cost_percentage: "",
            balance_billed: "",
            due_retention: "",
            scopeItemData: [],
            organization: 1,
            old_data: {},
            isShowAlertBox: false,
            addItemVal: "",
            scopeItemLengthLimitFlag: false,
            isEmptyProjectManagerFlag: false,
          
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount = () => {
        getManagerList().then((response) => {
            this.setState({ managerList: [...response] });
        })
        this.getProjectById();
    }

    getProjectById = () => {
        let projectId = this.props.match.params.projectId;

        projectServices.getProjectById(projectId).then((res) => {
            setProjectData(res.data);
            
            const { manager, jobNumber, organization, startDate, endDate, description, scopeItemData, title, projectStatus, amount_billed, balance_billed, contract_price, cost_percentage, due_retention, total_cost } = res.data;
            const scoId = res.data && res.data.scopeItemData && res.data.scopeItemData[0] && res.data.scopeItemData[0].id;
            this.props.setSelectedScopeItemID(Number(scoId))
            let scopeItems = []
            for (let sc of scopeItemData) {
                let sItem = {
                    id: sc.id,
                    title: sc.title
                }
                scopeItems.push(sItem);
            }
            const old_data = { title, organization, manager, jobNumber: Number(jobNumber), startDate: dbDateFormat(startDate), endDate: dbDateFormat(endDate), description, scopeItemData: scopeItems };
            this.setState({
                managerId: manager,
                jobNumber: String(jobNumber),
                startDate: new Date(moment(startDate).format('MM/DD/YYYY')),
                endDate: new Date(moment(endDate).format('MM/DD/YYYY')),
                // startDate:startDate,
                // endDate:endDate,
                projectName: title,
                description: description,
                scopeItemData: this.formatScopeItem(scopeItemData),
                old_data: old_data,
                amount_billed: amount_billed!==null?amount_billed.toString():"",
                total_cost: total_cost!==null?total_cost.toString():'',
                cost_percentage: cost_percentage!==null?cost_percentage.toString():"",
                balance_billed: balance_billed!==null?balance_billed.toString():"",
                due_retention: due_retention!==null?due_retention.toString():"",
                contract_price: contract_price!==null?contract_price.toString():'',
                

            })
        }).catch((error) => {
            if (error.response.status === 401) {
                createNotification("error", "Your login session has been expired!");
                localStorage.clear();
                this.props.history.push('/login');
            }
            console.log("ERROR", error)
        })
    }

    handleOnChange = (event) => {
        if (event.target.name === "jobNumber") {
            this.setState({ jobNumber: event.target.value });
        } else if (event.target.name === "startDate") {
            this.setState({ startDate: event.target.value });
        } else if (event.target.name === "endDate") {
            this.setState({ endDate: event.target.value });
        } else if (event.target.name === "projectName") {
            this.setState({ projectName: event.target.value });
        } else if (event.target.name === "description") {
            this.setState({ description: event.target.value });
        } else if (event.target.name === "projectManager") {
            this.setState({ managerId: event.target.value, isEmptyProjectManagerFlag: false });
        } else if (event.target.name === "addItem") {
            this.setState({ addItemVal: event.target.value });
        } else if (event.target.name === "total_cost") {
            this.setState({ total_cost: event.target.value ,isTotalCostEntered:true});
        } else if (event.target.name === "contract_price") {
            this.setState({ contract_price: event.target.value });
        }
        else if (event.target.name === "amount_billed") {
            this.setState({ amount_billed: event.target.value });
        } else if (event.target.name === "cost_percentage") {
            this.setState({ cost_percentage: event.target.value,isContractPriceEntered:true });
        } else if (event.target.name === "balance_billed") {
            this.setState({ balance_billed: event.target.value });
        } else if (event.target.name === "due_retention") {
            this.setState({ due_retention: event.target.value });
        }

    }
    handleOnBlur=(e)=>{
        
        const{total_cost,amount_billed,contract_price}=this.state
        // alert(total_cost+"????????"+amount_billed)
       if(e.target.name==="total_cost"||e.target.name ==="amount_billed"){
        let  value;
        if(total_cost!=="" && amount_billed!==""){
              value=(total_cost/amount_billed)*100;
              this.setState({cost_percentage:value.toFixed(2),isTotalCostEntered:false});
        }
       }        
    }

    handleOnblurBalanceBill=(e)=>{
        const{total_cost,amount_billed,contract_price}=this.state
        if(e.target.name==="amount_billed" || e.target.name ==="contract_price"){

            let value;
            if(contract_price!==""&& amount_billed!==""){
                value=contract_price-amount_billed;
                this.setState({balance_billed:value.toFixed(3),isContractPriceEntered:false});
            }
           }
    }


    onItemFocusOut = (event) => {
        //alert('kokookok')
        if (event.target.value.trim() === "") {
            createNotification("error", "Scope Item should not be blank!");
        }
    }

    handleDateChange = (date, type) => {

        let formatedDate = date
        // if (type === "startDate") {
        //     this.setState({ startDate: formatedDate });
        // } else if (type === "endDate") {
        //     this.setState({ endDate: formatedDate });
        // }


        if (type === "startDate") {
            if (this.state.endDate !== '') {
                if (Date.parse(this.state.endDate) < Date.parse(date)) {
                    createNotification("error", "Start date should be less than End date");
                } else {
                    this.setState({ startDate: formatedDate });
                }
            } else {
                this.setState({ startDate: formatedDate });
            }
        } else if (type === "endDate") {
            if (this.state.startDate !== '') {
                if (Date.parse(this.state.startDate) > Date.parse(date)) {
                    createNotification("error", "End date should be greater than Start Date");
                } else {
                    this.setState({ endDate: formatedDate });
                }
            } else {
                this.setState({ endDate: formatedDate });
            }
        }

    }

    getManagerList = () => {

        const token = getLoginToken();
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${token}`
        }

        const URL = getApiUrl();
        const url = `${URL}/users/projectmanagers`;

        axios.get(url, {
            headers: headers
        })
            .then((response) => {
                if (response.status) {
                    let responseData = response.data.data;
                    let listArr = [];
                    responseData && responseData.map((item) => {
                        listArr.push({ lable: item.name, value: item.id });
                    });
                    this.setState({ managerList: listArr });

                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    createNotification("error", "Your login session has been expired!!");
                    localStorage.setItem('user-token', "");
                    this.props.history.push('/login');

                } else {
                    createNotification("error", error.response.data.error.message);
                }
                console.log({ resetPasswordError: error });
            })
        // }
    }

    addMoreItem = () => {
        if (!this.containsDuplicate()) {
            if (this.state.addItemVal.trim() !== "") {
                if (this.state.addItemVal.length <= 300) {
                    let itemsClone = [...this.state.scopeItemData];
                    let newItemArr = [...itemsClone, { title: this.state.addItemVal }]
                    this.setState({ scopeItemData: newItemArr, addItemVal: "" });
                } else {
                    this.setState({ scopeItemLengthLimitFlag: true });
                }

            }
        } else {
            createNotification("error", "Item already exists in the list")
        }

    }

    containsDuplicate = () => {
        const { scopeItemData, addItemVal } = this.state;
      
        let itemsClone = [...scopeItemData];
        for (let x of itemsClone) {
            if (x.title === addItemVal.trim()) {
                return (true);
            }
        }
        return (false);
    }

    formatScopeItem = (scopeArr) => {
        let itemArr = [];
        scopeArr.length && scopeArr.forEach((item) => {
            itemArr.push({ id: item.id, title: item.title });
        });
        return itemArr;
    }

    changeItem = (event) => {

        let rowIndex = event.target.id;
        let itemId = event.target.getAttribute('dataid');
        const { scopeItemData } = this.state;
        let itemArr = [...scopeItemData];
        itemArr[rowIndex] = { title: event.target.value };
        if (itemId !== "" && Number(itemId) !== 0) {
            itemArr[rowIndex].id = Number(itemId);
        }
        this.setState({ scopeItemData: [...itemArr] });
    }

    removeItem = (rowIndex) => {
        const { scopeItemData } = this.state;
        let itemArr = [...scopeItemData];
        itemArr.splice(rowIndex, 1);
        this.setState({ scopeItemData: [...itemArr] });
    }

    handleOnClickPrevious = (projectId) => {
        this.props.history.push(`/project/${projectId}`);
    }

    checkScopeEmpty = (scopeItemData) => {
        let result = false;
        for (let i = 0; i < scopeItemData.length; i++) {
            // console.log(`${i} ${list[i]}`)
            if (scopeItemData[i].title.trim() === '') {
                createNotification("error", "Scope Item should not be blank!");
                result = true;
                break;

            }
        }
        return result;
    }

    handleUpdateProject = (isNextClick = false) => {
        const { projectId } = this.props.match.params;
        if (this.state.addItemVal !== "") {
            this.setState({ isShowAlertBox: true })
            return;
        }
        // if Login user not authorize for save data
        let roleKey = this.props.loginUserData.roleKey;
        if (roleKey !== "" && !rules[roleKey][Permission.PROJECT_WRITE]) {
            this.props.history.push(`/project/drawing/${projectId}`);
            return;
        }

        const { managerId, jobNumber, startDate, endDate, projectName, description, scopeItemData, organization, contract_price, amount_billed, balance_billed, due_retention, total_cost, cost_percentage } = this.state;
        const data = { managerId, jobNumber, startDate, endDate, projectName, description, scopeItemData, organization, contract_price, amount_billed, balance_billed, due_retention, total_cost, cost_percentage };
    
        if (this.validator.allValid() && !this.checkScopeEmpty(scopeItemData) && (managerId !== '0')) {
            this.setState({ loader: true });
            const data = {
                title: projectName, organization, manager: managerId, jobNumber: Number(jobNumber), startDate: dbDateFormat(startDate), endDate: dbDateFormat(endDate), description, scopeItemData,
                contract_price: Number(contract_price), amount_billed: Number(amount_billed), balance_billed: Number(balance_billed), cost_percentage: Number(cost_percentage), due_retention: Number(due_retention), total_cost: Number(total_cost)
            };

            //if(JSON.stringify(data) !== JSON.stringify(this.state.old_data)){
            projectServices.updateProjectById(projectId, data).then((response) => {
                this.getProjectById();
                this.setState({ loader: false }, () => {
                    if (response && response.data.status) {
                        createNotification("success", "Project update successfully!");
                        if (isNextClick) {
                            setTimeout(() => {
                                this.props.history.push(`/project/drawing/${projectId}`);
                            }, 500)
                        }
                    }
                });

            }).catch((error) => {
                if (error.response.status === 401) {
                    createNotification("error", "Your login session has been expired!!");
                    localStorage.setItem('user-token', "");
                    this.props.history.push('/login');
                    this.setState({ loader: false });
                } else {
                    createNotification("error", error.response.data.error.message);
                    this.setState({ loader: false });
                }
                console.log(error)
            })
            //const { password, confirmPassword, } = this.state;
            // }else{
            //     this.props.history.push(`/project/drawing/${projectId}`);
            // }
        } else if (managerId === '0') {
            this.setState({ isEmptyProjectManagerFlag: true })
            this.validator.showMessages();
            this.forceUpdate();
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleCloseAlert = () => {
        this.setState({ isShowAlertBox: false })
    }

    render() {
        const { jobNumber, managerList, projectName, startDate, endDate, managerId, description, scopeItemData, loader ,contract_price,total_cost,amount_billed,cost_percentage,balance_billed,due_retention} = this.state;
        let projectId = this.props.match.params.projectId;
        let roleKey = this.props.loginUserData.roleKey;
        const isDisable = (roleKey !== "" && rules[roleKey][Permission.PROJECT_WRITE]) ? "" : "disabled";

        return (

            <div>
                {loader === true ? (
                    <Loader />
                ) : (
                    <div className="main-content">
                        <AlertBox
                            openModal={this.state.isShowAlertBox}
                            alertMsg="Please click on + button to add scope item."
                            handleConfirmModal={this.handleConfirmModal}
                            handleClose={this.handleCloseAlert}
                        />
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <ol className="breadcrumb m-0">
                                        <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                                        &nbsp; / &nbsp;
                                        <Link to={`/project`}><li className="breadcrumb-item">Projects</li></Link>
                                        &nbsp; / &nbsp;
                                        <Link to={`/project/edit/${projectId}`}><li className="breadcrumb-item">Edit Project</li></Link>
                                    </ol>
                                </div>
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">Edit Project</h4>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body bgW">
                                                <div className="wizard clearfix">
                                                    <fieldset disabled={isDisable}>
                                                        <div className="content clearfix">
                                                            <form className="form-project">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label" htmlFor="validationCustom01">Project Name</label>
                                                                            <input type="text" className="form-control" id="validationCustom01" placeholder="Project name" value={projectName} name="projectName" onChange={(e) => this.handleOnChange(e)} />
                                                                            {this.validator.message('Project Name', projectName, 'required|max:200', { className: 'text-danger' })}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label" htmlFor="validationCustom02">Project Manager</label>
                                                                            <DropDown
                                                                                name="projectManager"
                                                                                firstOption={{ lable: 'Select Project Manager', value: 0 }}
                                                                                options={managerList}
                                                                                onChange={(e) => this.handleOnChange(e)}
                                                                                defaultSelected={this.state.managerId}
                                                                                lable=""
                                                                            />
                                                                            {this.validator.message('projectManager', managerId, 'required', { className: 'text-danger' })}
                                                                            {this.state.isEmptyProjectManagerFlag === true ? <p className='text-danger'>The project manager field is required.</p> : ""}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <label className="form-label" htmlFor="validationCustom03">Description</label>
                                                                            <textarea className="form-control" rows="3" name="description" onChange={(e) => this.handleOnChange(e)} value={description} />
                                                                            {this.validator.message('description', description, 'required|max:700', { className: 'text-danger' })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3 editProjectDatePickerWrap">
                                                                            <label className="form-label" for="validationCustom01">Start Date</label>
                                                                            <DatePicker className="form-control date-form-control" onChange={(date) => this.handleDateChange(date, "startDate")} dateFormat="MM/dd/yyyy" selected={startDate}
                                                                                //minDate={`${moment().format('ddd MMM DD YYYY hh:mm:ss')} GMT+0530 (India Standard Time)`}
                                                                                placeholderText="MM/DD/YYYY" disabled={isDisable} />

                                                                            {this.validator.message('startDate', startDate, 'required', { className: 'text-danger' })}
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3 editProjectDatePickerWrap">
                                                                            <label className="form-label" for="validationCustom02">End Date </label>
                                                                            <DatePicker className="form-control date-form-control" onChange={(date) => this.handleDateChange(date, "endDate")} dateFormat="MM/dd/yyyy" selected={endDate}
                                                                                minDate={startDate}
                                                                                placeholderText="MM/DD/YYYY"
                                                                                disabled={isDisable}
                                                                            />
                                                                            {this.validator.message('endDate', endDate, 'required', { className: 'text-danger' })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label" for="validationCustom01">Job Number</label>
                                                                            <input type="number" name="jobNumber" className="form-control" id="validationCustom01" value={this.state.jobNumber} onChange={(e) => this.handleOnChange(e)} />
                                                                            {this.validator.message('jobNumber', this.state.jobNumber, 'required|integer|min:2|max:6', { className: 'text-danger' })}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label" htmlFor="validationCustom01">Contract Price</label>
                                                                            <input type="number" name="contract_price" className="form-control" id="validationCustom01" value={contract_price} onChange={(e) => this.handleOnChange(e)} 
                                                                            onBlur={(e)=>this.handleOnblurBalanceBill(e)} onKeyUp={(e)=>this.handleOnblurBalanceBill(e)} 
                                                                            />
                                                                            {this.validator.message('Contract Price',contract_price, 'required|max:20', { className: 'text-danger' })}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label" htmlFor="validationCustom01">Total Cost</label>
                                                                            <input type="number" name="total_cost" className="form-control" id="validationCustom01" value={total_cost} onChange={(e) => this.handleOnChange(e)} 
                                                                             onBlur={(e)=>this.handleOnBlur(e)}onKeyUp={(e)=>this.handleOnBlur(e)}
                                                                            />
                                                                            {this.validator.message('total cost',total_cost, 'required|max:20', { className: 'text-danger' })}
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label" htmlFor="validationCustom01">Amount Billed</label>
                                                                            <input type="number" name="amount_billed" className="form-control" id="validationCustom01" value={this.state.amount_billed} onChange={(e) => this.handleOnChange(e)} onBlur={(e)=>{this.handleOnBlur(e);this.handleOnblurBalanceBill(e)}}
                                                                            onKeyUp={(e)=>{this.handleOnBlur(e);this.handleOnblurBalanceBill(e)}}/>
                                                                            {this.validator.message('amount billed',amount_billed, 'required|max:20', { className: 'text-danger' })}
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label" htmlFor="validationCustom01"> Cost Percentage</label>
                                                                            <input type="number" name="cost_percentage" className="form-control" id="validationCustom01" value={this.state.cost_percentage} onChange={(e) => this.handleOnChange(e)} disabled />
                                                                            {this.validator.message('cost percentage',cost_percentage, 'required|max:20', { className: 'text-danger' })}
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label" htmlFor="validationCustom01">Balance Billed</label>
                                                                            <input type="number" name="balance_billed" className="form-control" id="validationCustom01" disabled value={this.state.balance_billed} onChange={(e) => this.handleOnChange(e)} />
                                                                            {this.validator.message('balance billed',balance_billed, 'required|max:20', { className: 'text-danger' })}
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label" htmlFor="validationCustom01">Due Retention</label>
                                                                            <input type="number" name="due_retention" className="form-control" id="validationCustom01" value={this.state.due_retention} onChange={(e) => this.handleOnChange(e)} />
                                                                            {this.validator.message('due retention',due_retention, 'required|max:20', { className: 'text-danger' })}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <h4 className="card-title">Add Scope of Item</h4>
                                                                {/* <AllowedTo
                                                            perform={Permission.PROJECT_WRITE}
                                                        > */}
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="mb-3 d-flex align-items-center justify-content-end">
                                                                            <input type="text" className="form-control" placeholder="Add item" name="addItem" id="addItem"
                                                                                //ref={this.itemRef} 
                                                                                disabled={isDisable}
                                                                                onChange={(e) => this.handleOnChange(e)}
                                                                                value={this.state.addItemVal}
                                                                            />
                                                                            <button type="button" className="btn btn-plus btn-primary waves-effect waves-light"
                                                                                onClick={() => this.addMoreItem()} disabled={isDisable} >
                                                                                <i className="uil uil-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                        {this.state.scopeItemLengthLimitFlag === true ? <p className='text-danger'>Scope item cannot be grater then 300 characters.</p> : ""}
                                                                        {this.validator.message('ScopeItemData', scopeItemData, 'required', { className: 'text-danger' })}
                                                                    </div>
                                                                </div>
                                                                {/* </AllowedTo> */}
                                                                {
                                                                    scopeItemData && scopeItemData.map((data, index) => {
                                                                        return (
                                                                            <div className="row" key={index}>
                                                                                <div className="col-md-6">
                                                                                    <div className="mb-3 d-flex align-items-center justify-content-end">
                                                                                        <input type="text" className="form-control" placeholder="Add item" name={`item${index}`} value={data.title} id={index} onChange={(e) => this.changeItem(e)}
                                                                                            dataid={data.id} onBlur={(e) => { this.onItemFocusOut(e) }}
                                                                                        />
                                                                                        <AllowedTo
                                                                                            perform={Permission.PROJECT_WRITE}
                                                                                        >
                                                                                            <button type="button" className="btn btn-plus btn-danger waves-effect waves-light"
                                                                                                onClick={() => this.removeItem(index)}>
                                                                                                <i className="uil uil-minus"></i>
                                                                                            </button>
                                                                                        </AllowedTo>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )

                                                                    })
                                                                }


                                                            </form>
                                                        </div>
                                                    </fieldset>
                                                    <div className="actions clearfix">
                                                        <ul role="menu" aria-label="Pagination">
                                                            <li className="disabled" aria-disabled="true"><a href="#previous" role="menuitem">Previous</a></li>
                                                            <AllowedTo
                                                                perform={Permission.PROJECT_WRITE}
                                                            >
                                                                <li aria-hidden="false" aria-disabled="true"><a href="javascript:void(0)" onClick={() => this.handleUpdateProject()}>Save</a></li>
                                                            </AllowedTo>
                                                            <li aria-hidden="false" aria-disabled="false" className="" ><a role="menuitem" href="#" onClick={() => this.handleUpdateProject(true)}>Next</a></li>
                                                            <li aria-hidden="true" style={{ display: "none" }}><a href="#finish" role="menuitem">Finish</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setProjectData: setProjectData,
    setSelectedScopeItemID: setSelectedScopeItemID,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditProject);