import axios from "axios";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import {getApiUrl} from '../../helpers/Util';

import { setLoginUserData } from '../../project/redux/projectAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import projectServices from  '../../project/services/projectServices';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      userName: "",
      profileImage: null,
      isShow:"hide"
    };
  }

  showLogout = () => {
   // this.setState({isShow:this.state.isShow === "hide"?"show":"hide"})
  }

  componentDidMount = () => {
    //  const userToken = localStorage.getItem("user-token");
    const userName = localStorage.getItem("userName");
    const profileImage = localStorage.getItem("profileImage");
    const URL = getApiUrl();
    const image =
      profileImage === undefined ||
      profileImage === "undefined" ||
      profileImage === null ||
      profileImage === ""
        ? "/assets/images/profile-pic.jpeg"
        : profileImage;
    this.setState({ userName, profileImage: image });
  };

  getLoginUserData = () => {
    const { setLoginUserData, loginUserData } = this.props;
        let loginUserDataClone = { ...loginUserData };
    //const { projectId } = this.props.match.params;
    projectServices.getUserDetailByToken().then((res) => {
        if(res.data.status){

          let result = res.data.data;
                setLoginUserData({ ...loginUserDataClone, userName: result.profile.name,profileImage: result.profile.profileImage, roleKey:result.profile.roleKey});
            }
    }).catch((error) => {
        console.log(error);
    })
}

  handleLogout = () => {
    const { setLoginUserData, loginUserData } = this.props;
    let loginUserDataClone = { ...loginUserData };
    setLoginUserData({ ...loginUserDataClone, userName: "",profileImage: "", roleKey:""});
    localStorage.clear();
  };

  render() {
   return (
      <>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              {/* <!-- LOGO --> */}
              <button
                type="button"
                className="btn btn-sm px-3 font-size-20 header-item waves-effect vertical-menu-btn navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarToggle" aria-controls="navbarTogglet" aria-expanded="false" aria-label="Toggle navigation"
              >
                <i className="uil uil-bars"></i>
              </button>
              <div className="navbar-brand-box">
                <a href="index.html" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={Logo} alt="" height="50px" />
                  </span>
                </a>
              </div>
            </div>
            <div className="d-flex">
              <div className="dropdown d-inline-block">
                <button
                  type="button"
                  className="btn header-item waves-effect"
                 // id="page-header-user-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => this.showLogout()}
                >
                  <img
                    className="rounded-circle header-profile-user"
                    src={this.props.loginUserData.profileImage}
                    alt="Header Avatar"
                    onError={event => {
                      event.target.src = "/assets/images/profile-pic.jpeg"
                      event.onerror = null
                    }}
                  />
                  <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">
                    {this.props.loginUserData.userName}
                  </span>
                  <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-end"
                  style={{ margin: "0px" }}
                >
                  {/* item */}
                  <a className="dropdown-item" href="javascript:void(0)">
                  <Link  className="dropdown-item" to="/userprofile" className="has-arrow">
                      <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>{" "}
                      <span className="align-middle" style={{ color: "black" }}>
                        View Profile
                      </span>
                      </Link>
                  </a>
                  <a className="dropdown-item" href="javascript:void(0)">
                  <Link  className="dropdown-item" to="/changepassword" className="has-arrow">
                      <i className="uil uil-lock-alt font-size-18 align-middle text-muted me-1"></i>{" "}
                      <span className="align-middle" style={{ color: "black" }}>
                       Change Password
                      </span>
                      </Link>
                  </a>
                  {/* <a className="dropdown-item d-block" href="#"><i className="uil uil-bell font-size-18 align-middle me-1 text-muted"></i> <span className="align-middle">Notifications</span> <span className="badge bg-soft-success rounded-pill mt-1 ms-2">03</span></a> */}
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    onClick={()=>this.handleLogout()}
                  >
                    <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>{" "}
                    <span className="align-middle">Sign out</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

const mapStateToProps = (props) => {
  return {
      loginUserData: props.loginUserData,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setLoginUserData: setLoginUserData,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);;
