import React, { useState, useEffect, useRef } from 'react';
import { FaUndoAlt, FaRegWindowClose } from "react-icons/fa";
import { createNotification } from "../../helpers/Notification";
import { rules, Permission } from "../../../abac.config";
import projectServices from '../../project/services/projectServices';

const EditableTransportListItems = (props) => {
    const [freightItemList, setFreightItemList] = useState([]);
    const [editing, setEditing] = useState({ row: null, column: null });
    
    const inputRef = useRef(null);

    useEffect(() => {
        getFreightDelItems(props.projectFreightDeliveryId);
    }, []);

    useEffect(() => {
        if (freightItemList.length > 0) {
            const updatedList = freightItemList.map(item => ({
                ...item,
                isEditing: false,
                isEdited: false,
            }));
            setFreightItemList(updatedList);
        }
    }, [freightItemList.length]);

    const getFreightDelItems = (projectFreightDeliveryId) => {
        if (projectFreightDeliveryId) {
            projectServices
                .getFreightDelItems(projectFreightDeliveryId)
                .then((res) => {
                    setFreightItemList(res.data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleInputChange = (e, index, field) => {
        const newList = [...freightItemList];
        newList[index][field] = e.target.value;
        newList[index].isEdited = true; 
        setFreightItemList(newList);
    };

    const handleCheckboxChange = (index, field) => {
        const newList = [...freightItemList];
        newList[index][field] = !newList[index][field];
        newList[index].isEdited = true;
        setFreightItemList(newList);
    };

    

    const handleCellClick = (row, column) => {
        setEditing({ row, column });
    };

    const handleBlur = () => {
        setEditing({ row: null, column: null });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleBlur();
        }
    };

    useEffect(() => {
        if (editing.row !== null && editing.column !== null && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    const renderCell = (item, i, field) => {
        if (editing.row === i && editing.column === field) {
            return (
                <input
                    ref={inputRef}
                    type="text"
                    value={item[field]}
                    onChange={(e) => handleInputChange(e, i, field)}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />
            );
        }
        return item[field];
    };

    const filterFreightItems = () => {
        return freightItemList
        .filter(item => item.isEdited) // Filter only edited items
        .map(item => ({
          id:item.id,
          itemLoaded: item.itemLoaded,
          itemReceived: item.itemReceived,
          mark: item.mark,
          profile: item.profile,
          quantity: item.quantity,
          weight: item.weight
        }));
    };
    const validateListItems = () => {
        for (let item of freightItemList) {
          if (item.isEdited) {
            if (!item.mark || !item.profile || !item.quantity || !item.weight) {
              return false;
            }
          }
        }
        return true;
      };
    const handleUpdateListItems = () => {
        if (!validateListItems()) {
            createNotification("error",'Fields cannot be left empty.');
            return;
          }
      
        const filteredItems = filterFreightItems();
        props.updateTransportItems(filteredItems,props.projectFreightDeliveryId);

    };
    return (
        <>
            <div className="modal-content transport-list-content">
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => props.closeModal(false)}
                ></button>
                <h5 className='text-center'>Transport items</h5>
                <div>
                    <div>
                        <div className={props.scrollStyle ? "mb-3 table-wrapper-scroll-y my-custom-scrollbar-print" : "mb-3 table-wrapper-scroll-transport my-custom-scrollbar"}>
                            <table className="table table-transport-popup table-fixed headerMinHeight50 table table-bordered table-striped mb-0">
                                <thead className="headerTable">
                                    <tr>
                                        <th className="col-xs-3 col-lg-2">Mark</th>
                                        <th className="col-xs-1 col-lg-2">Quantity</th>
                                        <th className="col-xs-3 col-lg-2">Weight</th>
                                        <th className="col-xs-3 col-lg-2">Profile</th>
                                        <th className="col-xs-3 col-lg-2">
                                            Item Loaded

                                        </th>
                                        <th className="col-xs-3 col-lg-2">
                                            Item Received

                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {freightItemList.length > 0 &&
                                        freightItemList.map((item, i) => (
                                            <tr key={i}>
                                                <td className="col-xs-3 col-lg-2" onClick={() => handleCellClick(i, 'mark')}>
                                                    {renderCell(item, i, 'mark')}
                                                </td>
                                                <td className="col-xs-1 col-lg-2" onClick={() => handleCellClick(i, 'quantity')}>
                                                    {renderCell(item, i, 'quantity')}
                                                </td>
                                                <td className="col-xs-3 col-lg-2" onClick={() => handleCellClick(i, 'weight')}>
                                                    {renderCell(item, i, 'weight')}
                                                </td>
                                                <td className="col-xs-3 col-lg-2" onClick={() => handleCellClick(i, 'profile')}>
                                                    {renderCell(item, i, 'profile')}
                                                </td>
                                                <td className="col-xs-3 col-lg-2">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={item.itemLoaded}
                                                        onChange={() => handleCheckboxChange(i, 'itemLoaded')}
                                                    />
                                                </td>
                                                <td className="col-xs-3 col-lg-2">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={item.itemReceived}
                                                        onChange={() => handleCheckboxChange(i, 'itemReceived')}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="actions clearfix saveBtn text-center">
                    <button
                        href="javascript:void(0)"
                        role="menuitem"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => handleUpdateListItems()}
                    >
                        Update
                    </button>
                </div>
            </div>
        </>
    );
};

export default EditableTransportListItems;
