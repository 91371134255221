import React, { Component } from 'react'
import Pagination from '../../Pagination/Pagination';
import projectServices from '../../../services/projectServices';
import { createNotification } from '../../../../helpers/Notification';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { Pagination } from 'react-bootstrap';
import { Permission } from '../../../../../abac.config';
import { rules } from '../../../../../abac.config';


class MassDeleteInventoryPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shopList: [],
            loader: false,
            selectedShopId: 0,
            slocationId: 0,
            rackArray: [],
            projectInventoryDisplayList: [],
            locationList: [],
            ActiveId: 0,
            selectedRackId: 0,
            isRackOpenPopUp: false,
            isInventoryDeatilsOpenPopUp: false,
            isShowConfirmBox: false,
            counter: 0,
            removeId: 0,
            selectedInventoryId: 0,
            search: "",
            isManuallyCreated: true,
            projectInventoryMaterialData: [],
            suggestionProjectInventory: [],
            isSearch: false,
            suggestionProjectnventoryVar: "",
            isProjectInventorySuggestionSelected: false,
            isLocationSelected: false,
            pageChange: 1,
            //inventories constant--------------------
            selectedIds: [],
            selectAll:false,
            skip: 0,
            limit: 20,
            count: 0,
            sortBy: "",
            label:"Select All",
            sortingArr: [
                {
                    id: "1",
                    name: "Ascending",
                    value: "ASC",
                },
                {
                    id: "2",
                    name: "Descending",
                    value: "DESC",
                }
            ],
            filterData: `?filter[skip]=0&filter[limit]=10`,
        }
    }
    componentDidMount = () => {
       
        let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
        this.getProjectInventories(filters);
        
    }

    getProjectInventoryCount = (countReqParam) => {

        projectServices.getInventoryCount(countReqParam).then((response) => {
            this.setState({ 
                // projectInventorycount: response.data.count
               count : response.data.count 
            })
        })
            .catch((error) => {
                if (error.response.status === 401) {
                    createNotification(
                        "error",
                        "Your login session has been expired!"
                    );
                    localStorage.setItem("user-token", "");
                    this.props.history.push("/login");
                    this.setState({ loader: false });
                }
            })
    }
    getProjectInventories = (filters) => {
        const { isManuallyCreated,sortBy} = this.state;
        let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
        this.getProjectInventoryCount(countReqParam);
        projectServices.getProjectInventoryDetails(filters, isManuallyCreated).then((response) => {
            // console.log({ responsedata: response.data });
            this.setState({ projectInventoryDisplayList: response.data,  filterData: filters, loader: false });
            if(sortBy =="ASC"||sortBy=="DESC"){
               
                this.sortListByOrder();
            }
        })
            .catch(() => {
                // if (error.response.status === 401) {
                //     createNotification(
                //         "error",
                //         "Your login session has been expired!"
                //     );
                //     localStorage.setItem("user-token", "");
                //     this.props.history.push("/login");
                //     this.setState({ loader: false });
                // }
            })
    }
    handleOnPageChange = (arrow, activePage) => {
        let skp = 0;
        let filters = '';

        if (arrow === '<<') {
            skp = (1 - 1) * this.state.limit;
            this.setState({ pageChange: 1, skip: skp })
            filters = `?filter[skip]=${skp}&filter[limit]=${this.state.limit}`;
        } else if (arrow === '<') {
            skp = (((activePage - 1) - 1) * this.state.limit);
            this.setState({ pageChange: (activePage - 1), skip: skp })
            filters = `?filter[skip]=${skp}&filter[limit]=${this.state.limit}`;
        } else if (arrow === '>') {
            // console.log(activePage, "activePage");
            skp = ((activePage + 1) - 1) * this.state.limit;

            this.setState({ pageChange: (activePage + 1), skip: skp })
            filters = `?filter[skip]=${skp}&filter[limit]=${this.state.limit}`;
        } else if (arrow === '>>') {
            let lastPage = Math.ceil(this.state.count / this.state.limit);

            skp = (Number(lastPage) - 1) * this.state.limit;
            // console.log(this.state.count );
            // console.log(this.state.limit);
            // console.log(lastPage,"lastPage");
            // console.log(skp,"skp");
            this.setState({ pageChange: Number(lastPage), skip: skp })
            filters = `?filter[skip]=${skp}&filter[limit]=${this.state.limit}`;
        } else {
            skp = (Number(arrow) - 1) * this.state.limit;
            this.setState({ pageChange: Number(arrow), skip: skp })
            filters = `?filter[skip]=${skp}&filter[limit]=${this.state.limit}`;
        }
        this.getProjectInventories(filters);
    }

    handleonSizePerPageChange = (currentPage, sizePerPage) => {
        // console.log(currentPage, "currentPage");
        // console.log(sizePerPage, "sizePerPage");
        // let skp = (currentPage-1)*sizePerPage;
        let skp = 0;
        this.setState({ pageChange: currentPage, limit: sizePerPage })
        let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
        this.getProjectInventories(filters);
    }
    toggleCheckbox = (id) => {
        this.setState((prevState) => {
          if (prevState.selectedIds.includes(id)) {
            return {
              selectedIds: prevState.selectedIds.filter(
                (selectedId) => selectedId !== id
              )
            };
          } else {
            return {
              selectedIds: [...prevState.selectedIds, id]
            };
          }
        });
        this.setState({selectAll:false,label:'Select All'});
      };
    
      handleMassDelete = () => {
        // Perform mass delete operation using selectedIds array
        const{selectedIds}=this.state;
        // console.log('Selected IDs for deletion:', this.state.selectedIds);
       if(selectedIds.length>0){
        this.props.handleDeleteMassInventory(this.state.selectedIds);
       }else{
        createNotification("error","Please select records to be deleted .")
       }
       
      };
   
      handleCheckboxToggle = (id) => {
        const { selectedIds } = this.state;
        if (selectedIds.includes(id)) {
          this.setState({
            selectedIds: selectedIds.filter((item) => item !== id),
          });
        } else {
          this.setState({
            selectedIds: [...selectedIds, id],
          });
        }
      };
      handleOnChange = (event) => {
         console.log( event.target.value);
      if(event.target.value!=""){
        
        this.setState({sortBy:event.target.value},()=>{
            this.sortListByOrder()
        });
       

      }else{
        
        this.setState({sortBy:""},()=>{
            let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
            this.getProjectInventories(filters);
        });

      }
        
    }
    sortListByOrder = () => {
        const { projectInventoryDisplayList, sortBy } = this.state;
        let result;
    
        if (sortBy === "ASC") {
            // Sort in ascending order
            result = projectInventoryDisplayList.sort((a, b) => {
                if (a.jobNumber && b.jobNumber) {
                    
                    return String(a.jobNumber).localeCompare(String(b.jobNumber), undefined, { numeric: true });
                }
                return -1;  
            });
        } else if (sortBy === "DESC") {
            // Sort in descending order
            result = projectInventoryDisplayList.sort((a, b) => {
                if (a.jobNumber && b.jobNumber) {
                    
                    return String(b.jobNumber).localeCompare(String(a.jobNumber), undefined, { numeric: true });
                }
                return -1;  
            });
        } else {
            
            result = [...projectInventoryDisplayList];
        }
    
        console.log(result,"result");
        
        
        this.setState({ projectInventoryDisplayList: [...result] });
    };
    
    
    toggleSelectAllCheckBoxes = () => {
        const { selectAll, selectedIds, projectInventoryDisplayList } = this.state;
       
        if (!selectAll) {
            let rowId = [];
            for (let ids of projectInventoryDisplayList) {
                rowId.push(ids.id);
            }

            this.setState({ selectAll: !selectAll, selectedIds: rowId,label:'Remove All' });

        }else{
            this.setState({ selectAll: !selectAll, selectedIds: [],label:"Select All" });
        }


    }

    render() {
        const{projectInventoryDisplayList,selectedIds}=this.state;
        

       

        //------------------------------------------------------------------------// 

     
      
        const roleKey = this.props.loginUserData.roleKey;
    //   form-select styled-select select-sm
       
        return (
            <>
                <div>
                    <div className='d-flex justify-content-between align-items-center px-2 mt-2 mb-2'>
                    <button onClick={this.handleMassDelete} className='btn btn-primary   mx-1'>Mass Delete</button>
                    <h4 className='text-center mb-0'>Mass Delete Manual Inventory</h4>
                    {/* <label className="form-label mb-0 ">Sort By</label> */}
                        <select className="form-select w-150" name="stored" value={this.state.sortBy} onChange={(e) => this.handleOnChange(e)}>
                            <option value="">Select</option>
                                                  {this.state.sortingArr.length > 0 && this.state.sortingArr.map((item) => {
                                return (<option value={item.value}>{item.name}</option>)
                            })
                            }
                        </select>
                        <div className='mt-3'>
                        <input type='checkbox' checked={this.state.selectAll} id='selectAll'
                            onChange={this.toggleSelectAllCheckBoxes} className='massDel mx-1'
                        />
                        <label className='massDel mx-1'>{this.state.label}</label>
                        </div>
                    <button
              type="button"
              className="btn-close  mx-1 "
              data-bs-dismiss="modal"
              aria-label="Close"
            //   style={{float:"right"}}
              onClick={this.props.closeModal}
            ></button>
                    </div>
                    
                    {/* handleCloseMassDeleteProjectInventory */}
                    <table className='table table-bordered massdeleteInventory'>
                        <thead>
                            <tr>
                                <th className='massdeleteInventory th' scope="col">Item</th>
                                <th scope="col">Job Number</th>
                                <th scope="col">Length</th>
                                <th scope="col">Heat Number</th>
                                <th scope="col">Action</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.projectInventoryDisplayList.map((record) => (
                                
                                <tr scope="row" key={record.id}>
                                    <td>{record.material}</td>
                                    <td>{record.jobNumber}</td>
                                    <td>{record.length}</td>
                                    <td>{(record.hasOwnProperty('heatNumber')&&record.heatNumber!==""&&record.heatNumber!==null)?record.heatNumber:"N/A"}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={this.state.selectedIds.includes(record.id)}
                                            onChange={() => this.toggleCheckbox(record.id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                  
                </div>
                
                <div className={"row project-detail-row" + (projectInventoryDisplayList.length ? '' : ' h-172')} >
                    {
                        projectInventoryDisplayList && projectInventoryDisplayList.map((item, i) => {
                            return (
                                <>

                                    {i + 1 === this.state.limit || i + 1 === projectInventoryDisplayList.length ?
                                        (<Pagination
                                            totalPage={this.state.count}
                                            currentPage={this.state.pageChange}
                                            limit={this.state.limit}
                                            onPageChange={(arrow, activePage) => this.handleOnPageChange(arrow, activePage)}
                                            onSizePerPageChange={(page, size) => this.handleonSizePerPageChange(page, size)}
                                        />) : (<></>)
                                    }
                                </>
                            );
                        })
                    }
                </div>
            </>
        )
    }
}
const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MassDeleteInventoryPopUp);
