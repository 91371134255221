import React, { Component } from 'react'
//import projectServices-----
import projectServices from '../../services/projectServices';
//importing createNotification ----
import { createNotification } from '../../../helpers/Notification';
//importing redux fucntions
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
//importing rules and permission
import { rules, Permission } from '../../../../abac.config';
//import Allowed to-----
import { AllowedTo } from "react-abac";
//importing loader
import Loader from '../../../common/Loader';
//importing react validator
import SimpleReactValidator from "simple-react-validator";
class AddInventoryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationList: [],
      selectedLocationId: '0',
      item: "",
      length: "",
      quantity: "",
      button: "Save",
      loader: false,
      locationFlag: false,
      locationError: "",
      gradeList: [],
      selectedGradeId: "",
      jobNumber:"",
      heatNumber: "",
      pulledBy: "",
      usedQuantity:"",
      availableQuantity:"",
      gradeError: "",
      gradeFlag: false,
      ischecked:false,
      headingText:"Add Inventory"

    }
    this.validator = new SimpleReactValidator();
  }
  componentDidMount = () => {
    const { inventoryId } = this.props;
    if (inventoryId !== 0) {
      this.setState({ button: "Update", loader: true ,headingText:"Update Inventory"})
      this.getInventoryForSingleItem(inventoryId)
    }
    this.getLocationMasterData();
    this.getGradeMasterData();


  }
  getInventoryForSingleItem = (inventoryId) => {
    projectServices.getSingleInventoryDetail(inventoryId).then((response) => {
      let resData = response.data;
    
      this.setState({
        item: resData.material,
        length: resData.length,
        quantity: resData.quantity,
        selectedLocationId: resData.location,
        loader: false,
        jobNumber: resData.jobNumber !== null ? resData.jobNumber.toString() : "",
        selectedGradeId: resData.gradeId !== null ? resData.gradeId.toString() : "",
        heatNumber: (resData.heatNumber !== null &&resData.heatNumber!=="") ? resData.heatNumber : "",
        pulledBy:resData.pulledBy!==null?resData.pulledBy:"",
        usedQuantity:resData.quantityUsed!==null?resData.quantityUsed:"",
        availableQuantity:resData.quantityLeft!==null?resData.quantityLeft:""
      })

    }).catch((error) => {
      console.log(error);
    })
  }
  getLocationMasterData = () => {
    projectServices
      .getLocation()
      .then((res) => {
        this.setState({ locationList: res.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //get grade list from master data--------------

  getGradeMasterData = () => {
    projectServices
      .getActiveGrade()
      .then((res) => {
        this.setState({ gradeList: res.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleOnchange = (event) => {
    if (event.target.name === "item") {
      this.setState({ item: event.target.value });
    }
    else if (event.target.name === "jobNumber") {
      this.setState({ jobNumber: event.target.value })
    }
    else if (event.target.name === "length") {
      this.setState({ length: event.target.value })
    } else if (event.target.name === "grade") {
      this.setState({ selectedGradeId: event.target.value, })

    }
    else if (event.target.name === "quantity") {
      this.setState({ quantity: event.target.value });
    } else if (event.target.name === "location") {

      this.setState({ selectedLocationId: event.target.value, locationFlag: false })
    } else if (event.target.name === "heatNumber") {
      this.setState({ heatNumber: event.target.value });
    } else if (event.target.name === "pulledBy") {
      this.setState({ pulledBy: event.target.value });
    }
    else if(event.target.name==="usedQuantity"){
      this.setState({ usedQuantity:event.target.value });
    }
  }
  //function to save the inventory  details
  handleSaveInventory = () => {
    const { item, length, quantity, selectedLocationId, selectedGradeId,
      jobNumber,pulledBy,usedQuantity,availableQuantity,
      heatNumber } = this.state;
    if (jobNumber === "") {
      this.setState({ jobNumber: 0 })
    }
    const { isManuallyCreatedFlag } = this.props
    if ((this.validator.allValid()) && (selectedLocationId !== '0')) {
      if (isManuallyCreatedFlag === true) {
        if (this.state.button === "Save") {
          if (Number(quantity) >= Number(usedQuantity)) {
            this.props.saveInventoryDetails(item.trim(), length.trim(), quantity.trim(), selectedLocationId, selectedGradeId,
            jobNumber, heatNumber.trim(), isManuallyCreatedFlag,pulledBy,usedQuantity,availableQuantity);
          }else{
            
          }
          

        } else if (this.state.button === "Update") {
          if (Number(quantity) >= Number(usedQuantity)) {
            this.props.updateInventoryDetails(item, length, quantity, selectedLocationId, selectedGradeId,
              jobNumber, heatNumber, isManuallyCreatedFlag,pulledBy,usedQuantity,availableQuantity);
          }else{
            createNotification("error", "Quantity should not be less then the used quantity.")
          }
          

        }
      }

    } else if (selectedLocationId === "0") {
      this.setState({ locationFlag: true, locationError: "The location field is required." })
      this.validator.showMessages();
      this.forceUpdate();
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }


  }

  handleAvailableQty = (e) => {
    const { quantity, usedQuantity, availableQuantity } = this.state;
    if (e.target.name === "usedQuantity" || e.target.name === "quantity") {

      let value;

      if (Number(quantity) >= Number(usedQuantity)) {
        value = quantity - usedQuantity;
        if (value >= 0) {
        if(value==0){
          this.setState({ availableQuantity:"0" });
        }else{
          this.setState({ availableQuantity: value.toFixed(2) });
        }
          
        } else {
          this.setState({ availableQuantity: "" });
        }
      } else {
        this.setState({ availableQuantity: "" });
        createNotification("error", "Quantity should not be less then the used quantity.")
      }


    }
  }

  render() {
    const roleKey = this.props.loginUserData.roleKey;
    const isAccessableMassdelete= (roleKey==="superadmin"||roleKey==="admin")?true:false
    const isDisable = roleKey !== "" &&
      rules[roleKey][Permission.INVENTORY_WRITE]
      ? ""
      : "disabled";
    const { closeInventoryModal, rackId } = this.props;
    const { locationList, selectedLocationId, loader, selectedGradeId, gradeList } = this.state;
    return (
      <div>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mt-0 text-dark fw-bold text-center" id="myModalLabel">{this.state.headingText} </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => { closeInventoryModal(false) }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-3 accordWrap">
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label className="col-md-4">Item</label>
                  <input required="" autocomplete="off" type="text" className="form-control col-md-2" placeholder="Enter item name here" name="item" onChange={(e) => this.handleOnchange(e)} value={this.state.item} disabled={isDisable} />
                  {this.validator.message(
                    "item",
                    this.state.item,
                    "required|max:100",
                    { className: "text-danger" })}
                  {/* {this.state.mterrorflag === true ? <p className='text-danger'>{this.state.mterrormessage}</p> : ""} */}
                </div>

                <div className="col-md-4 mb-3">
                  <label className="col-md-4">Job:#</label>
                  <input type="number" autocomplete="off" className="form-control col-md-2" placeholder="Enter item name here" name="jobNumber" onChange={(e) => this.handleOnchange(e)} value={this.state.jobNumber} disabled={isDisable} />
                  {this.validator.message('jobNumber', this.state.jobNumber, 'required|integer|min:2|max:6', { className: 'text-danger' })}

                </div>

                <div className="col-md-4 mb-3">
                  <label className="col-md-4">Length</label>
                  <input required="" autocomplete="off" type="text" className="form-control col-md-2" placeholder="Enter item length" name="length" onChange={(e) => this.handleOnchange(e)} value={this.state.length} disabled={isDisable} />
                  {this.validator.message(
                    "length",
                    this.state.length,
                    "required|max:100",
                    { className: "text-danger" })}

                </div>

                <div className="col-md-4 mb-3">
                  <label className="col-md-4">Grade</label>
                  <select className="form-select"
                    name="grade" onChange={(e) => this.handleOnchange(e)} value={selectedGradeId} disabled={isDisable}>

                    <option value="0">Select</option>
                    {gradeList.length && gradeList.map((itemData) => {
                      return (
                        <option value={itemData.id}>
                          {itemData && itemData.name}
                        </option>
                      );
                    })
                    }
                  </select>
                </div>

                <div className="col-md-4 mb-3">
                  <label className="col-md-4">Quantity</label>
                  <input required="" autocomplete="off" type="number" min={"0"} className="form-control col-md-2" placeholder="Enter item quantity" name="quantity" onChange={(e) => this.handleOnchange(e)} onClick={(e) => { this.handleAvailableQty(e) }} onKeyUp={(e)=>{this.handleAvailableQty(e)}} value={this.state.quantity} disabled={isDisable} />
                  {this.validator.message(
                    "Quantity",
                    this.state.quantity,
                    "required|decimal",
                    { className: "text-danger" })
                  }
                  {/* {this.state.quanityerrorflag === true ? <p className='text-danger'>{this.state.quantityerrormessage}</p> : ""} */}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="col-md-4">Location</label>
                  <select className="form-select"
                    name="location" onChange={(e) => this.handleOnchange(e)} value={selectedLocationId} disabled={isDisable}>

                    <option value="0">Select</option>
                    {locationList.length && locationList.map((itemData) => {
                      return (
                        <option value={itemData.id}>
                          {itemData && itemData.name}
                        </option>
                      );
                    })
                    }
                  </select>
                  {this.state.locationFlag === true ? <p className='text-danger'>{this.state.locationError}</p> : ""}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="col-md-4">Heat Number</label>
                  <input required="" autocomplete="off" type="text" className="form-control col-md-2" placeholder="Enter heat number here" name="heatNumber" onChange={(e) => this.handleOnchange(e)} value={this.state.heatNumber} disabled={isDisable} />
                  {this.validator.message(
                    "Heat Number",
                    this.state.heatNumber,
                    "max:100",
                    { className: "text-danger" })}

                </div>
                <div className="col-md-4 mb-3">
                  <label className="col-md-4">Pulled by</label>
                  <input required="" autocomplete="off" type="text" className="form-control col-md-2" placeholder="Enter pulled by User" name="pulledBy" onChange={(e) => this.handleOnchange(e)} value={this.state.pulledBy} disabled={isDisable} />
                  {this.validator.message(
                    "Heat Number",
                    this.state.pulledBy,
                    "max:100",
                    { className: "text-danger" })}

                </div>
            
                <div className="col-md-4 mb-3">
                  <div className="mb-3">
                    <label className="form-label">Used Quantity</label>
                    <input required="" autocomplete="off" type="number" min={"0"} className="form-control col-md-2" placeholder="Enter item quantity" name="usedQuantity" onChange={(e) => this.handleOnchange(e)} onKeyUp={(e)=>{this.handleAvailableQty(e)}} onClick={(e) => { this.handleAvailableQty(e) }} value={this.state.usedQuantity} disabled={isDisable} />
                   
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="mb-3">
                    <label className="form-label">Available Quantity</label>
                    <input required="" autocomplete="off" type="text" className="form-control col-md-2" placeholder="Available quantity" name="pulledBy"  value={this.state.availableQuantity} disabled />
                    
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <button type="button" className="btn btn-danger fw-bold" data-bs-dismiss="modal" aria-label="Close" onClick={() => { closeInventoryModal(false) }}><i className="uil uil-times"></i> Cancel</button>
            <AllowedTo perform={Permission.INVENTORY_WRITE}><button className="btn btn-primary fw-bold" onClick={this.handleSaveInventory}><i className="uil uil-plus"></i> {this.state.button} </button></AllowedTo>

          </div>
        </div>
      </div>

    )
  }
}
const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddInventoryDetails);