import React, { Component } from "react";
import ProjectView from "./ProjectView";
import ProjectDetailModal from "../../common/ProjectDetailModal";
import projectServices from "../services/projectServices";
import { createNotification } from "../../helpers/Notification";
import Loader from "../../common/Loader";
import SimpleReactValidator from "simple-react-validator";
import {
  getApiUrl,
  isValidDocument,
  checkDrawingFileSize,
} from "../../helpers/Util";
import { rules, Permission } from "../../../abac.config";
import { AllowedTo } from "react-abac";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Accordion, Card } from "react-bootstrap";
import ConfirmBox from "../../common/ConfirmBox";
import Dropzone from "react-dropzone";
import { validDocumentArr } from "../../helpers/Util";
import { Link } from "react-router-dom";
class ProjectDocUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenProjectDetail: false,
      projectDoc: [],
      loader: false,
      index:0,
      projectDocUi: [
        {
          title: "",
          fileData: [],
          id: "",
          isDocEdit: false,
          isTitleEdit: false,
        },
      ],
      removeFileId: "",
      removeDocId: "",
      isShowConfirmBox: false,
    };
    this.validator = new SimpleReactValidator();
  }

  handlePrevClick = () => {
    const { projectId, ref } = this.props.match.params;
    if (ref === "projectdoc") {
      this.props.history.push(`/project/project-view/${projectId}`);
    } else {
      this.props.history.push(`/project/field-install/${projectId}`);
    }
  };

  handleProjectModal = (isOpen) => {
    this.setState({ isOpenProjectDetail: isOpen });
  };

  componentDidMount = () => {
    this.getUploadedFile();
  };

  fileChangedHandler = (files, eve, c, attrObj) => {
    if (files.length > 0) {
      const { projectDocUi } = this.state;
      let projectDocUiClone = [...projectDocUi];
      let docId = attrObj.id;
      let docIndex = attrObj.indexval;

      let docFile = files;
      let cloneData = projectDocUiClone[docIndex]
        ? projectDocUiClone[docIndex].fileData
        : [];
      let fileArr = [...cloneData];
      docFile.forEach((itemData, i) => {
        if (isValidDocument(itemData.name.split(".").pop())) {
          if (checkDrawingFileSize(itemData.size)) {
            fileArr.push(itemData);
          } else {
            createNotification("info", "Maximum limit to upload is 400 MB");
            return false;
          }
        } else {
          createNotification("error", "Please select valid file format");
          return false;
        }
      });

      projectDocUiClone[docIndex] = {
        ...projectDocUiClone[docIndex],
        fileData: fileArr,
        isDocEdit: docId !== "" ? true : false,
        id: docId,
      };

      this.setState({ projectDocUi: [...projectDocUiClone] });
    }
  };

  onReject = (file) => {
    if (file && file[0].errors[0].code === "file-invalid-type") {
      createNotification("error", "Please select valid file format");
    } else if (file && file[0].errors[0].code === "file-too-large") {
      createNotification("error", "Maximum limit to upload is 400 MB");
    }
  };

  handleTitleChange = (event) => {
    const { projectDocUi } = this.state;
    let projectDocUiClone = [...projectDocUi];
    let docId = event.target.getAttribute(`id`);
    let docIndex = event.target.getAttribute(`indexval`);

    projectDocUiClone[docIndex] = {
      ...projectDocUiClone[docIndex],
      title: event.target.value,
      isTitleEdit: docId !== "" ? true : false,
    };

    this.setState({ projectDocUi: [...projectDocUiClone] });
  };

  formateProjectDocdata = (data) => {
    if (data && data.length) {
      let docArry = data.map((item) => {
        let docId = item.id;
        return {
          title: item.title,
          fileData: [],
          id: docId,
          projectDocs: item.projectDocs,
          isDocEdit: true,
        };
      });
      let cloneDocArr = [...docArry];
      cloneDocArr.push({
        title: "",
        fileData: [],
        id: "",
        isDocEdit: false,
        projectDocs: [],
      });
      this.setState({ projectDocUi: [...cloneDocArr] });
    } else {
      this.setState({
        projectDocUi: [
          {
            title: "",
            fileData: [],
            id: "",
            isDocEdit: false,
            projectDocs: [],
          },
        ],
      });
    }
  };

  getUploadedFile = () => {
    const { projectId } = this.props.match.params;
    projectServices
      .getProjectDocument(projectId)
      .then((response) => {
        this.formateProjectDocdata(response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          createNotification("error", "Your login session has been expired!");
          localStorage.clear();
          this.props.history.push("/login");
          //this.setState({ loader: false });
        } else {
          createNotification("error", error.response.data.error.message);
          //this.setState({ loader: false });
        }
        console.log({ resetPasswordError: error });
      });
  };

  handleAddMore = () => {
    const { projectDocUi,index } = this.state;
    let projectDocUiClone = [...projectDocUi];
    projectDocUiClone.push({ title: "", fileData: [] });
    this.setState({ projectDocUi: [...projectDocUiClone] });
    this.validator.hideMessages();
    this.forceUpdate();
  };

  handleUploadDocument = () => {
    const { projectDocUi,index } = this.state;
    const { projectId } = this.props.match.params;
    console.log(projectDocUi);
    console.log(projectDocUi[index].projectDocs.length);

    // if (true) {
    if (this.validator.allValid()) {
      this.setState({ loader: true });
      const URL = getApiUrl();
      const url = `${URL}/projects/documents/${projectId}`;
      var fd = new FormData();
      let callSave = true;
      if (projectDocUi.length) {
        console.log(projectDocUi.length);
        projectDocUi.map((item, index) => {
          let fileArr = item.fileData;
          fd.append(`projectDoc[title][${index}]`, item.title);
          if (item.isDocEdit && item.id !== "") {
            fd.append(`projectDoc[id][${index}]`, item.id);
            callSave = true;
          }

          fileArr.length > 0 &&
            fileArr.forEach((itemData, i) => {
              if (item.title !== "" && item.fileData !== "") {
                fd.append(`projectDoc[image][${index}]`, itemData);
                callSave = true;
              }
            });
        });
      } else {
        this.setState({ loader: false });
        return;
      }

      if (!callSave) {
        this.setState({ loader: false });
        return;
      }
      projectServices
        .uploadProjectDocument(projectId, fd)
        .then((response) => {
          createNotification("success", response.data.message);
          this.setState({ loader: false }, () => {
            // this.formateProjectDocdata(response.data.data);
            setTimeout(() => {
              this.getUploadedFile();
            }, 2000);
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            createNotification("error", "Your login session has been expired!");
            localStorage.clear();
            this.props.history.push("/login");
            this.setState({ loader: false });
          } else {
            createNotification("error", error.response.data.error.message);
            this.setState({ loader: false });
          }
          console.log({ resetPasswordError: error });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    // }
  };

  deleteSingleProjectDocument = (documentId, fileId) => {
    const { projectId } = this.props.match.params;
    this.setState({ loader: true });

    projectServices
      .deleteSingleProjectDocument(projectId, documentId, fileId)
      .then((response) => {
        createNotification("success", "This file is deleted successfully");
        this.setState(
          { removeFileId: "", removeDocId: "", loader: false },
          () => {
            this.formateProjectDocdata(response.data.data);
          }
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          createNotification("error", "Your login session has been expired!");
          localStorage.clear();
          this.props.history.push("/login");
          this.setState({ removeFileId: "", removeDocId: "", loader: false });
        } else {
          createNotification("error", error.response.data.error.message);
          this.setState({ removeFileId: "", removeDocId: "", loader: false });
        }
        console.log({ resetPasswordError: error });
      });
  };

  deleteProjectDocument = (documentId, index = "") => {
    const { projectId } = this.props.match.params;
    this.setState({ loader: true });

    projectServices
      .deleteProjectDocument(projectId, documentId)
      .then((response) => {
        createNotification(
          "success",
          "Project document is deleted successfully"
        );
        this.setState({ loader: false }, () => {
          this.formateProjectDocdata(response.data.data);
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          createNotification("error", "Your login session has been expired!");
          localStorage.clear();
          this.props.history.push("/login");
          //this.setState({ loader: false });
          this.setState({ removeFileId: "", removeDocId: "", loader: false });
        } else {
          createNotification("error", error.response.data.error.message);
          // this.setState({ loader: false });
          this.setState({ removeFileId: "", removeDocId: "", loader: false });
        }
        console.log({ resetPasswordError: error });
      });
  };

  removeChoosedFile = (docIndex, fileIndex) => {
    const { projectDocUi } = this.state;
    let projectDocUiClone = [...projectDocUi];
    let docData = projectDocUiClone[docIndex].fileData;
    projectDocUiClone[docIndex].fileData.splice(fileIndex, 1);
    this.setState({ projectDocUi: [...projectDocUiClone] });
  };

  handleConfirmModal = (isYes) => {
    if (isYes) {
      this.setState({
        isShowConfirmBox: !this.state.isShowConfirmBox,
        loader: true,
      });
      const { selectedScope, projectId } = this.props;
      const { removeFileId, removeDocId } = this.state;

      if(removeDocId ===""&& removeFileId ===""){
        this.removeProjectDocument();
      }
      if (removeDocId !== "" && removeFileId === "") {
        this.deleteProjectDocument(removeDocId);
      } else if (removeDocId !== "" && removeFileId !== "") {
        this.deleteSingleProjectDocument(removeDocId, removeFileId);
      }
    } else {
      this.setState({
        removeFileId: "",
        removeDocId: "",
        loader: false,
        isShowConfirmBox: false,
      });
    }
  };
  removeProjectDocument=()=>{
    const { projectDocUi ,index} = this.state;
    let projectDocUiClone = [...projectDocUi];
    projectDocUiClone.splice(index, 1);
    this.setState({ projectDocUi: [...projectDocUiClone],loader:false});
    // this.setState({loader:false})
  }

  render() {
    const { projectDocUi, loader, isShowConfirmBox } = this.state;
    const { projectId } = this.props.match.params;
    let roleKey = this.props.loginUserData.roleKey;
    const isDisable =
      roleKey !== "" && rules[roleKey][Permission.PROJECT_WRITE]
        ? ""
        : "disabled";
    return (
      <div>
        {loader === true ? (
          <Loader />
        ) : (
          <div className="main-content">
            <ConfirmBox
              openModal={isShowConfirmBox}
              confirmMsg="Are you sure to delete this file?"
              handleConfirmModal={this.handleConfirmModal}
            />

            <div className="page-content">
              <div className="container-fluid">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <ol className="breadcrumb m-0">
                    <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                    &nbsp; / &nbsp;
                    <Link to={`/project`}><li className="breadcrumb-item">Projects</li></Link>
                    &nbsp; / &nbsp;
                    <Link to={`/project/project-view/${projectId}`}><li className="breadcrumb-item">View</li></Link>
                    &nbsp; / &nbsp;
                    <Link to={`/project/project-doc-upload/${projectId}/projectdoc`}><li className="breadcrumb-item">Documents</li></Link>
                  </ol>
                </div>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Project Name</h4>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body bgW">
                        <h2 className="card-title mb-4 h4-green">
                          Upload Your Project Document
                        </h2>
                        <div className="wizard clearfix">
                          <div className="content clearfix">
                            <form className="form-project">
                              <fieldset disabled={isDisable}>
                                <h4 className="card-title">Add your files</h4>

                                {projectDocUi.length > 0 &&
                                  projectDocUi.map((data, index) => {
                                    let item = data;
                                    let docId = item.id ? item.id : "";
                                    let filePath = item.filePath
                                      ? item.filePath
                                      : "";
                                    let fileName = item.fileName
                                      ? item.fileName
                                      : "";

                                    let projectDocs = item.projectDocs;
                                    let choosedData = item.fileData;
                                    return (
                                      <div key={index} className="row">
                                        <div className="col-md-12">
                                          <div className="row mb-3">
                                            <div className="col-md-3">
                                              <div className=" d-flex align-items-center justify-content-end">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="File Name"
                                                  id={docId}
                                                  value={item.title}
                                                  onChange={(e) =>
                                                    this.handleTitleChange(e)
                                                  }
                                                  indexval={index}
                                                />
                                              </div>

                                              {projectDocUi.length !==
                                                parseInt(index) + 1 ||
                                              (projectDocUi[
                                                projectDocUi.length - 1
                                              ].fileData.length > 0 &&
                                                projectDocUi[
                                                  projectDocUi.length - 1
                                                ].title === "") ||
                                              projectDocUi[
                                                projectDocUi.length - 1
                                              ].title !== ""
                                                ? this.validator.message(
                                                    "file Name",
                                                    item.title,
                                                    "required|max:200",
                                                    { className: "text-danger" }
                                                  )
                                                : ""}
                                            </div>
                                            <div className="col-md-4">
                                              <div className="dragDropWrap">
                                                <Dropzone
                                                  onDrop={(files, eve, c) =>
                                                    this.fileChangedHandler(
                                                      files,
                                                      eve,
                                                      c,
                                                      {
                                                        indexval: index,
                                                        id: docId,
                                                      }
                                                    )
                                                  }
                                                  accept={validDocumentArr()}
                                                  maxSize={400 * 1024 * 1024}
                                                  onDropRejected={this.onReject}
                                                >
                                                  {({
                                                    getRootProps,
                                                    getInputProps,
                                                  }) => (
                                                    
                                                      <div
                                                        {...getRootProps({
                                                          className: "dropzone",
                                                        })}
                                                      >
                                                        <input
                                                          {...getInputProps({
                                                            docid: docId,
                                                            indexid: index,
                                                          })}
                                                        />
                                                        <div className="imgWrap">
                                                          <img src="/assets/images/drop.png" />
                                                        </div>
                                                        <p>Choose file or drag and drop here<b><br />
                                                          <span>{choosedData.length > 0 ? `${choosedData.length} File selected` : ""}</span></b>
                                                        </p>
                                                      </div>
                                                  )}
                                                </Dropzone>
                                              </div>
                                            
                                            </div>
                                            <div className="col-md-3">
                                              <div className="mb-3 d-flex align-items-left">
                                                <a
                                                 
                                                  onClick={() =>
                                                    window.open(
                                                      filePath,
                                                      "_blank"
                                                    )
                                                  }
                                                >
                                                  {fileName}
                                                </a>
                                              </div>
                                            </div>
                                            <AllowedTo
                                              perform={Permission.PROJECT_WRITE}
                                            >
                                              {projectDocUi.length ===
                                                parseInt(index) + 1 && (
                                                <div className="col-md-2">
                                                  <div className="mb-3 d-flex align-items-left justify-content-end">
                                                    <button
                                                      type="button"
                                                      className="btn btn-plus btn-primary waves-effect waves-light mr-3"
                                                      onClick={() => {
                                                        this.handleAddMore();
                                                      }}
                                                    >
                                                      <i className="uil uil-plus">Add</i>
                                                    </button>
                                                  </div>
                                                </div>
                                              )}

                                              {projectDocUi.length !==
                                                index + 1 && (
                                                <div className="col-md-2">
                                                  <div className="mb-3 d-flex justify-content-end ">
                                                    <button
                                                      type="button"
                                                      className="btn btn-plus btn-danger waves-effect waves-light mr-3"
                                                      // onClick={() =>
                                                      //   this.deleteProjectDocument(
                                                      //     docId,
                                                      //     index
                                                      //   )
                                                      // }

                                                      onClick={() =>
                                                        this.setState({
                                                          removeDocId: docId,
                                                          isShowConfirmBox: true,
                                                          index:index
                                                        })
                                                      }
                                                      // disabled={
                                                      //   filePath === ""
                                                      //     ? "disabled"
                                                      //     : ""
                                                      // }
                                                    >
                                                      <i className="uil uil-minus"></i>
                                                    </button>
                                                  </div>
                                                </div>
                                              )}
                                            </AllowedTo>
                                          </div>
                                        </div>
                                        <Accordion>
                                          <Card>
                                            <Accordion.Toggle
                                              as={Card.Header}
                                              eventKey={index + 1}
                                            >
                                              Uploaded Files
                                            </Accordion.Toggle>

                                            <Accordion.Collapse
                                              eventKey={index + 1}
                                            >
                                              <Card.Body>
                                                <div className="col-md-12">
                                                  <table
                                                    className="accordWrap"
                                                    style={{ width: "100%" }}
                                                  >
                                                    {choosedData &&
                                                      Object.values(
                                                        choosedData
                                                      ).map((itemData, i) => {
                                                        let docIndex = index;
                                                        let fileIndex = i;
                                                        return (
                                                          <tr key={i}>
                                                            <td>
                                                              <span class="unsavedItem">
                                                                {
                                                                  choosedData[i]
                                                                    .name
                                                                }
                                                              </span>
                                                            </td>
                                                            <td
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <button
                                                                type="button"
                                                                className="btn btn-plus btn-danger waves-effect waves-light"
                                                                onClick={() =>
                                                                  this.removeChoosedFile(
                                                                    docIndex,
                                                                    fileIndex
                                                                  )
                                                                }
                                                              >
                                                                Remove
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        );
                                                      })}

                                                    {projectDocs?.length > 0 &&
                                                      projectDocs.map(
                                                        (fileData, i) => {
                                                          let filePath =
                                                            fileData.upload_fkey
                                                              ?.filePath;
                                                          let fileName =
                                                            fileData.upload_fkey
                                                              ?.fileName;
                                                          let fileId =
                                                            fileData.id;
                                                          return (
                                                            <tr key={i}>
                                                              <td>
                                                                {" "}
                                                                <a
                                                                  className="d-inline-block"
                                                                  onClick={() =>
                                                                    window.open(
                                                                      filePath,
                                                                      "_blank"
                                                                    )
                                                                  }
                                                                >
                                                                  {fileName}
                                                                </a>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  textAlign:
                                                                    "right",
                                                                }}
                                                              >
                                                                <a
                                                                  className="btn btn-primary waves-effect waves-light"
                                                                  onClick={() =>
                                                                    window.open(
                                                                      filePath,
                                                                      "_blank"
                                                                    )
                                                                  }
                                                                >
                                                                  View
                                                                </a>
                                                                <button
                                                                  type="button"
                                                                  className="btn btn-plus btn-danger waves-effect waves-light"
                                                                 onClick={() =>
                                                                    this.setState(
                                                                      {
                                                                        removeDocId:
                                                                          docId,
                                                                        removeFileId:
                                                                          fileId,
                                                                        isShowConfirmBox: true,
                                                                      }
                                                                    )
                                                                  }
                                                                >
                                                                  Remove
                                                                </button>
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                  </table>
                                                </div>
                                              </Card.Body>
                                            </Accordion.Collapse>
                                          </Card>
                                        </Accordion>
                                      </div>
                                    );
                                  })}
                              </fieldset>
                            </form>
                          </div>

                          <ProjectDetailModal
                            componentData={
                              <ProjectView
                                match={this.props.match}
                                history={this.props.history}
                                isModalOpen={true}
                              />
                            }
                            openModal={this.state.isOpenProjectDetail}
                            closeModal={this.handleProjectModal}
                          />

                          <div className="actions clearfix">
                            <ul role="menu" aria-label="Pagination">
                              <li>
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => this.handlePrevClick()}
                                  role="menuitem"
                                >
                                  Previous
                                </a>
                              </li>
                              <AllowedTo perform={Permission.PROJECT_WRITE}>
                                <li
                                  aria-hidden="false"
                                  aria-disabled="false"
                                  className=""
                                >
                                  <a
                                    href="javascript:void(0)"
                                    role="menuitem"
                                    onClick={() => this.handleUploadDocument()}
                                  >
                                    Save
                                  </a>
                                </li>
                              </AllowedTo>
                              <li
                                aria-hidden="false"
                                aria-disabled="false"
                                className=""
                              >
                                <a
                                  href="javascript:void(0)"
                                  role="menuitem"
                                  onClick={() => this.handleProjectModal(true)}
                                >
                                  Preview
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDocUpload);
