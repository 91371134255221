import React, { Component } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
//importing create notification from 
import { createNotification } from '../../../../helpers/Notification';
//importing project  services module 
import projectServices from '../../../services/projectServices';
//importing loader component
import Loader from '../../../../common/Loader';
//import rules and abac provider 
import { rules, Permission } from "../../../../../abac.config"
import { AllowedTo } from "react-abac";
//importing custom modal pop ups -----
import ModalPopUp from '../../../../common/ModalPopUp';
//importing rack modal
import BootstrapTable from 'react-bootstrap-table-next';
//importing pagination 
import paginationFactory from "react-bootstrap-table2-paginator";
//import confirm box
import ConfirmBox from "../../../../common/ConfirmBox"
//importing link from react router dom
import { Link } from 'react-router-dom';
//importing Add Inventory rack modal
import AddInventoryDetails from '../AddInventoryDetails';
//importing Save as function -------
import { saveAs } from "file-saver";
import MassDeleteManualInventory from './MassDeleteManualInventory';

class ManualInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shopList: [],
            loader: false,
            selectedShopId: 0,
            slocationId: 0,
            sQuantityOptions: '',
            rackArray: [],
            projectInventoryDisplayList: [],
            locationList: [],
            ActiveId: 0,
            selectedRackId: 0,
            isRackOpenPopUp: false,
            isInventoryDeatilsOpenPopUp: false,
            isShowConfirmBox: false,
            isMassDeleteManualInventoriesFlag: false,
            isMassDeleteManualInventoriesPopUp: false,
            counter: 0,
            removeId: 0,
            isTypeInventoryQuantitySelected: false,
            isTypeInventoryDisabled: false,
            selectedInventoryId: 0,
            search: "",
            isManuallyCreated: true,
            roleKeyManualIventory: "",
            manualInventoryMaterialData: [],
            suggestionManualInventory: [],
            suggestionManualInventoryvar: "",
            isManualInventorySuggestionSelected: false,
            isSearch: false,
            isLocationSelected: false,
            isJobDropDownSelected: false,
            inventoryDropDown: [
                {
                    id: "1",
                    name: "availableInventory",
                    title: "Available Inventory",
                },
                {
                    id: "2",
                    name: "usedInventory",
                    title: "Used Inventory"
                },

            ],
            jobNumberList: [],
            selectedJobNumber: '0',

            pageChange: 1,
            skip: 0,
            limit: 10,
            manualInventorycount: 0,
            resDatalen:0,
            filterData: `?filter[skip]=0&filter[limit]=10`,
        }


    }
    componentDidMount = () => {
        let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
        this.getManualInventoryCount(countReqParam);
        let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
        this.getJobNumberList();
        this.getManualInventories(filters);
        this.getManualInventoriesSuggestions();
        this.getLocationMaster();
    }

    // -----------------
    componentDidUpdate = (prevProps) => {
        if ((this.props.manualInventoryTabFlag !== prevProps.manualInventoryTabFlag) && (this.props.manualInventoryTabFlag === true)) {
            this.setDefaultManualInventoryValues();
            let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
            this.getManualInventoryCount(countReqParam);
            let manualListlimit = 10;
            let manualSkipListVar = 0
            let filters = `?filter[skip]=${manualSkipListVar}&filter[limit]=${manualListlimit}`;
            this.getManualInventories(filters);

        }

    }
    setDefaultManualInventoryValues = () => {
        this.setState({
            pageChange: 1, limit: 10, slocationId: 0, search: "", skip: 0, isTypeInventoryQuantitySelected: false, isJobDropDownSelected: false, selectedJobNumber: "0",
            sQuantityOptions: "", isLocationSelected: false,
            suggestionManualInventory: [], isSearch: false,resDatalen:0
        })
    }
    getManualInventoryCount = (countReqParam) => {

        projectServices.getInventoryCount(countReqParam).then((response) => {
            //    console.log(response);
            this.setState({ manualInventorycount: response.data.count })
        })
            .catch((error) => {
                if (error.response.status === 401) {
                    createNotification(
                        "error",
                        "Your login session has been expired!"
                    );
                    localStorage.setItem("user-token", "");
                    this.props.history.push("/login");
                    this.setState({ loader: false });
                }
            })
    }

    getManualInventories = (filters) => {
        const { isManuallyCreated } = this.state;
        console.log(filters);
        projectServices.getProjectInventoryDetails(filters, isManuallyCreated).then((response) => {
            this.getRecievingData(response.data);
            // console.log(response);
            // this.setState({ filterData: filters, loader: false });
            // this.setState({ projectInventoryDisplayList: response.data, filterData: filters, loader: false });
        })
            .catch(() => {
                // if (error.response.status === 401) {
                //     createNotification(
                //         "error",
                //         "Your login session has been expired!"
                //     );
                //     localStorage.setItem("user-token", "");
                //     this.props.history.push("/login");
                //     this.setState({ loader: false });
                // }
            })
    }

    //function to close Inventory pop up-----------
    handleCloseAddInventoryModal = () => {
        this.setState({ isInventoryDeatilsOpenPopUp: false })
    }


    //funtion to open add Inventory modal popup and set the inventory id to 0 inorder for new inventory addition
    handleOpenAddInventoryModal = () => {
        this.setState({ isInventoryDeatilsOpenPopUp: true, selectedInventoryId: 0 })

    }
    //View Inventory details only
    viewInventoryItemDetails = (InventoryId) => {
        this.setState({ isInventoryDeatilsOpenPopUp: true, selectedInventoryId: InventoryId })
    }

    //delete record fron inventory---------------

    handleConfirmModal = (isYes) => {
       
        if (isYes) {
            this.setState({
                isShowConfirmBox: !this.state.isShowConfirmBox
            });
            const{skip,limit,pageChange,resDatalen,removeId}=this.state;
            let localSkip=0;
            let localPageChange=0;
            
            if(resDatalen===1 && pageChange>1){
                localSkip=skip-limit;
                localPageChange=pageChange-1;
                 this.setState({skip:localSkip,pageChange:localPageChange});
              }
            projectServices.deleteInventoryDetails(removeId).then(() => {
                createNotification("success", "Inventory item deleted successfully!");

                this.setState({
                    //  skip: 0, limit: 10, pageChange: 1, slocationId: 0, search: "", sQuantityOptions: "", selectedJobNumber: "", selectedPONumber: "" ,isTypeInventoryQuantitySelected:false,isLocationSelected:false,isJobDropDownSelected:false,isSearch:false
                    }, () => {
                let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`;

                // this.getManualInventoryCount(countReqParam)
                this.getManualInventoriesSuggestions();
                this.handlePaginationByCondtion(filters);    
                // this.getManualInventories(filters);
                    this.getJobNumberList();
                })
                }).catch((error) => {
                    console.log({ errormessage: error });
                    if (error.response.status === 401) {
                        createNotification("error", "Your login session has been expired!");
                        localStorage.setItem("user-token", "");
                        this.props.history.push("/login");
                    } else {
                        createNotification("error", error.response.data.error.message);
                    }
                    console.log(error);
                })
                // DELETE ACTION WRITTEN HERE--------END
                  // DELETE ACTION WRITTEN HERE--------END
        } else {
            this.setState({ isShowConfirmBox: false });
        } 
    }


        //location from master data--------------
        getLocationMaster = () => {
            projectServices.getLocation().then((response) => {

                this.setState({ locationList: response.data.data })
            }).catch((error) => {
                if (error.response.status === 401) {
                    createNotification("error", "Your login session has been expired!!");
                    localStorage.setItem('user-token', "");
                    this.props.history.push('/login');

                } else {
                    createNotification("error", error.response.data.error.message);
                }
                console.log(error);
            })
        }

        handleSearchInventory = (text) => {
            const { manualInventoryMaterialData } = this.state;
            this.setState({ search: text })
            let matches = [];
            if (text.length > 2) {
                matches = manualInventoryMaterialData.filter(item => {
                    const regex = new RegExp(`${text}`, "gi");
                    return item.material.match(regex);
                })
            }
            this.setState({ suggestionManualInventory: matches, isManualInventorySuggestionSelected: false, isSearch: true, isTypeInventoryDisabled: true, skip: 0, limit: 10, pageChange: 1 })



            // }
            if (text.trim() === "") {

                this.setState({ pageChange: 1, slocationId: 0, isManualInventorySuggestionSelected: false, isSearch: false, skip: 0, limit: 10, isTypeInventoryDisabled: false, isJobDropDownSelected: false, isLocationSelected: false }, () => {
                    let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
                    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                    this.getManualInventories(filters);
                    this.getManualInventoryCount(countReqParam);
                })


            }
        }
        //function to get manual Inventory suggestions------------
        getManualInventoriesSuggestions = () => {
            const { isLocationSelected, slocationId, isManuallyCreated } = this.state;
            let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;

            projectServices.getManualInventorySuggestions(isManuallyCreated).then((response) => {
                this.setState({ manualInventoryMaterialData: response.data })
            }).catch((error) => {
                console.log(error)
            })


        }
        onSuggestionHandler = (item) => {
            this.setState({ search: item, suggestionManualInventory: [], isManualInventorySuggestionSelected: true, isSearch: false }, () => {
                this.getSearchedInventory();
            })

        }
        getSearchedInventory = () => {
            const { search, isManuallyCreated, isManualInventorySuggestionSelected, isLocationSelected, slocationId } = this.state;

            // console.log(filters);
            // if(filters ===undefined){
            //    filters=`?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
            // }

            // queryStr += `filter&include[search]=${search}`;
            let countReqParam;
            if (isManualInventorySuggestionSelected === true) {
                countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[material]=${search}`
            }
            else {
                countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[material][like]=%${search}%`
            }
            if (isLocationSelected && isManualInventorySuggestionSelected === false) {

                countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[location]=${slocationId}&where[material][like]=%${search}%`
            } else if (isLocationSelected && isManualInventorySuggestionSelected === true) {

                countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[location]=${slocationId}&where[material]=%${search}%`
            }
            if (search.trim() !== "" && search.length >= 1) {

                let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                projectServices.getProjectInventoryDetailsBySearching(filters, search.trim(), isManuallyCreated, isManualInventorySuggestionSelected, isLocationSelected, slocationId).then((response) => {
                    this.setState({ suggestionManualInventory: [] }, () => {
                        this.getRecievingData(response.data);
                        this.getManualInventoryCount(countReqParam);
                    });

                }).catch((error) => {
                    if (error.response.status === 401) {
                        createNotification(
                            "error",
                            "Your login session has been expired!"
                        );
                        localStorage.setItem("user-token", "");
                        this.props.history.push("/login");
                        this.setState({ loader: false });
                    }
                })
            }

        }


        //event to handle searching via location dropdown  list
        handleLocationChange = (event) => {
            let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
            let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`;
            if (event.target.name === "location" && event.target.value !== "0") {

                this.setState({ slocationId: event.target.value, skip: 0, pageChange: 1, isLocationSelected: true, limit: 10, isTypeInventoryDisabled: true }, () => {
                    this.getLocationBySearching(`?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`);
                })
            } else {
                this.setState({ slocationId: 0, pageChange: 1, skip: 0, isLocationSelected: false, limit: 10, isTypeInventoryDisabled: false }, () => { this.getManualInventories(`?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`); })
                this.getManualInventoryCount(countReqParam);

            }
        }
        //function to get the filtered records based on their location fields
        getLocationBySearching = (filters) => {
            console.log("abc<><><><>");
            const { slocationId, isManuallyCreated, search } = this.state;
            let queryStr = "&";

            if (slocationId !== "" && slocationId !== 0 && queryStr === "&") {
                queryStr = `${queryStr}filter[where][location]=${Number(slocationId)}`
            } else if (slocationId !== "" && slocationId !== 0 && queryStr !== "&") {
                queryStr = `${queryStr}&filter[where][location]=${Number(slocationId)}`
            }

            let countReqParam = ""
            if (slocationId !== 0) {
                countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[location]=${slocationId}`
            } else {
                if (this.state.search.length > 0) {

                    countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[material][like]=%${this.state.search}%`
                } else {

                    countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
                }

            }
            ;

            //let filters = `?filter[skip]=${skp}&filter[limit]=${limit}`;
            projectServices.getManualInventoriesByLocation(filters, queryStr, isManuallyCreated, search).then((response) => {
                this.getManualInventoryCount(countReqParam);
                this.setState({ loader: true },
                    this.getRecievingData(response.data));
            }).catch((error) => {
                console.log({ errormessage: error });
            })
        }

        handleSaveManualInventoryDetails = (material, length, quantity, selectedLocationId, selectedGradeId, jobNumber, heatNumber, isManuallyCreatedFlag, pulledBy, usedQuantity, availableQuantity) => {

            let mt = material.trim();
            let htNumber =heatNumber.trim();
            let payload = { material: mt, length: length, quantity: quantity, location: Number(selectedLocationId), gradeId: Number(selectedGradeId), jobNumber: Number(jobNumber), heatNumber: htNumber, isManuallyCreated: isManuallyCreatedFlag, pulledBy: pulledBy, quantityUsed:usedQuantity, quantityLeft: availableQuantity }
            projectServices.addInventoryDetails(payload).then(() => {
                createNotification("success", "Item added successfully");
                this.handleCloseAddInventoryModal();

                this.setState(
                    {
                         skip: 0, limit: 10, pageChange: 1, slocationId: 0, search: "", sQuantityOptions: "", selectedJobNumber: "", selectedPONumber: "" 
                }, () => {
                    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                    let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`;
                    this.getManualInventoryCount(countReqParam);
                    this.getManualInventories(filters);
                    this.getJobNumberList();
                });

            })
                .catch((error) => {

                    if (error.response.status === 401) {
                        createNotification(
                            "error",
                            "Your login session has been expired!"
                        );
                        localStorage.setItem("user-token", "");
                        this.props.history.push("/login");
                        this.setState({ loader: false });
                    } else if (error.response.status === 422) {
                        createNotification("error", "job number field should not be string");
                        this.setState({ loader: false });
                    }

                })



        }
        deleteInventoryRecord = (inventoryId) => {
            const roleKey = this.props.loginUserData.roleKey;
            const isDisable =
                roleKey !== "" &&
                    rules[roleKey][Permission.INVENTORY_WRITE]
                    ? true
                    : false;
            if (isDisable === true) {
                this.setState({ removeId: inventoryId, isShowConfirmBox: true });
            } else {
                createNotification("error", "Only superadmin and Inventory manager can delete the records")
            }
        }

        handleDeleteMassInventory = (selectedIds) => {
            projectServices.deleteMassInventories(selectedIds).then((response) => {
                createNotification("success", "Manual inventory items deleted succesfully .");
                this.setState({ isTypeInventoryQuantitySelected: false, isSearch: false, isLocationSelected: false, isJobDropDownSelected: false })
                this.setDefaultManualInventoryValues();
                this.handleCloseMassDeleteManualInventory();
                let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
                this.getManualInventoryCount(countReqParam);
                let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                this.getManualInventories(filters);
            })
                .catch((error) => {
                    console.log({ errormessage: error });
                })
        }

        actionFormat = (cell, row) => {
            //Permission to edit the inventory item or not based on the rules defined
            //const{roleKey}=this.props;
            // const{manualInventoryTabFlag}=this.props;
            // alert(manualInventoryTabFlag);
            return (
                <div>
                    <div>
                        <td>
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item">
                                    <a href="javascript:void(0)" className="text-primary">
                                        <i className="uil uil-pen font-size-18" onClick={() => { this.updateInventoryItem(row.id, row.isManuallyCreated) }}></i>
                                    </a>

                                </li>
                                <AllowedTo perform={Permission.INVENTORY_WRITE}>
                                    <li className="list-inline-item">
                                        <a href="javascript:void(0)" className="text-danger">
                                            <i className="uil uil-trash-alt font-size-18" onClick={() => this.deleteInventoryRecord(row.id)}></i>
                                        </a>
                                    </li>
                                </AllowedTo>
                            </ul>
                        </td>
                    </div>
                </div>

            )
        }
        //function to update the inventory details of the page
        updateInventoryItem = (InventoryId, isManuallyCreatedFlag) => {
            this.setState({ isInventoryDeatilsOpenPopUp: true, selectedInventoryId: InventoryId, isManuallyCreated: isManuallyCreatedFlag })
        }
        //update Inventory for a single record----------------------

        handleUpdateInventory = (material, length, quantity, selectedLocationId, selectedGradeId, jobNumber, heatNumber, isManuallyCreatedFlag, pulledBy, usedQuantity, availableQuantity) => {
            const { selectedInventoryId } = this.state;

            let mt = material.trim();
            let htNumber =heatNumber.trim();
            let payload = { material: mt, length: length, quantity: quantity, location: Number(selectedLocationId), gradeId: Number(selectedGradeId), jobNumber: Number(jobNumber), heatNumber: htNumber, isManuallyCreated: isManuallyCreatedFlag, pulledBy: pulledBy, quantityUsed:usedQuantity, quantityLeft: availableQuantity }
            let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`

            projectServices.editInventoryDetails(selectedInventoryId, payload).then((response) => {
                if (response.status) {
                    createNotification("success", "Inventory Item updated successfully.");
                    this.handleCloseAddInventoryModal();
                    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                    this.setState({ 
                        // skip: 0, limit: 10, pageChange: 1, slocationId: 0, search: "", sQuantityOptions: "", selectedJobNumber: "", selectedPONumber: "" ,isSearch:false,isTypeInventoryQuantitySelected:false,isLocationSelected:false,isJobDropDownSelected:false
                    }, 
                        () => {
                        let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                       this.handlePaginationByCondtion(filters); 
                        // this.getManualInventoryCount(countReqParam);
                        // this.getManualInventories(filters);
                        this.getJobNumberList();
                    });


                }
            })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {

                        createNotification(
                            "error",
                            "Your login session has been expired!"
                        );
                        localStorage.setItem("user-token", "");
                        this.props.history.push("/login");
                        this.setState({ loader: false });
                    } else if (error.response.status === 422) {

                        createNotification("error", "job number field should not be string");
                        this.setState({ loader: false });
                    }
                })
        }
        downloadInventoryReport = () => {
            const { slocationId, isManuallyCreated, search, isSearch } = this.state;
            const URL = process.env.REACT_APP_API_URL;
            projectServices.getInventoryRecord(isManuallyCreated, slocationId, search.trim(), isSearch).then((res) => {
                if (res.data.status) {
                    const fileLink = `${URL}${res.data.data}`;
                    const pathArr = fileLink.split('/');
                    saveAs(
                        // `${process.env.REACT_APP_API_URL}'/'${fileLink}`
                        fileLink
                        ,
                        pathArr[pathArr.length - 1]
                    );
                    createNotification("success", "Manual Inventory record downloaded successfully.")
                }
            }).catch((error) => {
                console.log(error);
                if (error.response.status === 422) {
                    createNotification("error", "No Inventory data exists.");
                }
            })
        }
        handleOpenMassDeleteManuaInventory = () => {
            this.setState({ isMassDeleteManualInventoriesFlag: true, isMassDeleteManualInventoriesPopUp: true })
        }
        handleCloseMassDeleteManualInventory = () => {
            this.setState({ isMassDeleteManualInventoriesPopUp: false })
        }
        handleCalculatedInventory = (event) => {

            const { isManuallyCreated, sQuantityOptions } = this.state;
            if (event.target.value !== "0") {
                let data = event.target.value;
                this.setState({ skip: 0, limit: 10, pageChange: 1 ,sQuantityOptions:data}, () => {
                    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                    this.getUsedAndAvailableInventories(filters, data);
                })


            } else if (event.target.value == "0") {
                this.setState({ sQuantityOptions: event.target.value, isTypeInventoryQuantitySelected: false, skip: 0, limit: 10, pageChange: 1 }, () => {
                    let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
                    this.getManualInventoryCount(countReqParam);
                    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                    this.getManualInventories(filters);
                })

            }

        }
        getUsedAndAvailableInventories = (filters, data) => {
            this.setState({ sQuantityOptions: data, isTypeInventoryQuantitySelected: true }, () => {
                this.getUsedAndAvailableInventoriesQuantitiesCount();
                projectServices.getCalculatedInventoryDetails(filters, data, this.state.isManuallyCreated).then((response) => {
                   
                    this.setState({ loader: true }, () => {
                        this.getRecievingData(response.data)
                    })
                }).catch((error) => {
                    console.log(error);
                })
                // this.getUsedAndAvailableInventories(filters);
            })
        }



        //get used and available inventory counts----
        getUsedAndAvailableInventoriesQuantitiesCount = () => {
            let countReqParam;
            console.log(this.state.sQuantityOptions);
            if (this.state.sQuantityOptions == "usedInventory") {
                countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[quantityUsed][gt]=0`
            } else if (this.state.sQuantityOptions == "availableInventory") {
                countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}&where[quantityLeft][gt]=0`;
            }

            projectServices.getUsedAvailableInventoryCount(countReqParam).then((response) => {
                this.setState({ manualInventorycount: response.data.count });
            }).catch((error) => {
                console.log(error);
            })
        }

        handlePaginationByCondtion = (filters) => {
            const { isLocationSelected, slocationId, isSearch, isManualInventorySuggestionSelected, isTypeInventoryQuantitySelected, selectedJobNumber, selectedPONumber, isJobDropDownSelected } = this.state;
            if (slocationId !== 0 || isLocationSelected) {

                this.getLocationBySearching(filters);
            } else if ((isSearch === true || isManualInventorySuggestionSelected === true)
                // && slocationId !== 0 && isLocationSelected
            ) {

                this.getSearchedInventory();
            }
            else if (isTypeInventoryQuantitySelected === true) {
               
                this.getUsedAndAvailableInventories(filters, this.state.sQuantityOptions)
            }
            else if (isJobDropDownSelected == true) {

                if (selectedJobNumber !== "") {
                    this.getInventoryByFilter("jobNumber", selectedJobNumber)
                } else if (selectedPONumber !== "") {
                    ;
                }
            }
            else {

                // let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
                this.getManualInventoryCount(countReqParam);
                this.getManualInventories(filters);
            }
        }


        getJobNumberList = () => {
            console.log("getJobNumberList");
            const { isManuallyCreated } = this.state;
            projectServices.getProjectJobNumber(isManuallyCreated).then((response) => {

                this.getJobNumberListData(response.data);
                //  this.setState({ jobNumberList: response.data, loader: false });
            })
                .catch((error) => {
                    console.log("error", error);
                })
        }
        getJobNumberListData = (responseData) => {
            const uniqueData = responseData.reduce((accumulator, currentValue) => {
                const existingObject = accumulator.find(
                    item => item.jobNumber === currentValue.jobNumber
                );
                if (!existingObject) {
                    accumulator.push(currentValue);
                }
                return accumulator;
            }, []);
            this.setState({ jobNumberList: uniqueData });
        }
        getJobNumberListData = (responseData) => {
            const uniqueData = responseData.reduce((accumulator, currentValue) => {
                const existingObject = accumulator.find(
                    item => item.jobNumber === currentValue.jobNumber
                );
                if (!existingObject) {
                    accumulator.push(currentValue);
                }
                return accumulator;
            }, []);
            this.setState({ jobNumberList: uniqueData });
        }
        handleOnChange = (event) => {


            this.setState({ loader: true })
            if (event.target.value !== '0' && event.target.value != "") {
                if (event.target.name === "jobNumber") {
                    this.setState({ selectedJobNumber: event.target.value, pageChange: 1, limit: 10, isJobDropDownSelected: true, skip: 0, limit: 10, pageChange: 1 }, () => {
                        this.getInventoryByFilter(event.target.name, event.target.value);
                    })
                }
                //  else if (event.target.name === "poNumber") {
                //     this.setState({ selectedPONumber: event.target.value, pageChange: 1, limit: 10 }, () => {
                //         this.getInventoryByFilter(event.target.name,event.target.value);
                //     })
                // } 

            } else {


                this.setState({
                    pageChange: 1,
                    slocationId: 0,
                    skip: 0,
                    limit: 10,
                    selectedJobNumber: event.target.value,
                    isLocationSelected: false,
                    isTypeInventoryDisabled: false,
                    isJobDropDownSelected: false,
                    isSearch: false,

                    // selectedJobNumber:event.target.value
                })
                let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
                this.getManualInventoryCount(countReqParam);
                let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                this.getJobNumberList();
                this.getManualInventories(filters);
            }

        }
        getInventoryByFilter = (searchBy, searchValue) => {
            const { pageChange, limit, isManuallyCreated } = this.state;

            console.log("getInventoryByFilter");
            let skp = (pageChange - 1) * limit;
            let filters = `?filter[skip]=${skp}&filter[limit]=${limit}`;

            // queryStr = `${queryStr}&filter[where][projectId]=${selectedProjectId}`
            if (searchValue !== '') {

                projectServices.getProjectInventoryDetailsByPoNum(filters, isManuallyCreated, searchBy, searchValue).then((response) => {
                    console.log({ responsedata: response.data });
                    let data1 = response.data;
                    let DataCount = data1.length;
                    console.log("count", DataCount);
                    this.setState({  projectInventorycount: DataCount, pageChange: 1, limit: 10, filterData: filters, loader: false });
                      this.getRecievingData(response.data);  
                })
                    .catch((error) => {
                        console.log("error", error);
                    })

            } else {
               
                let countReqParam = `where[isManuallyCreated]=${this.state.isManuallyCreated}`
                this.getManualInventoryCount(countReqParam);
                let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
                this.getJobNumberList();
                this.getManualInventories(filters);
            }
        }
        getRecievingData = (responsedata) => {

            // const newDataArray = this.state.receivingList;
            let receivingDisplayList = responsedata.map((item) => {

                item.material = item.material !== null ? item.material : 'N/A';
                item.jobNumber = item.jobNumber !== null && item.jobNumber !== '' ? item.jobNumber : 'N/A'
                item.poNumber = (item.fkey_poNumber && item.fkey_poNumber.poNumber !== null) ? item.fkey_poNumber.poNumber : 'N/A';

                item.length = item.length !== null && item.length !== "" ? item.length : "N/A";
                item.grade = (item.fkey_grade && item.fkey_grade.name !== null && item.fkey_grade.name !== "") ? item.fkey_grade.name : 'N/A';

                item.quantity = item.quantity !== null && item.quantity !== "" ? item.quantity : "N/A";
                item.heatNumber = item.heatNumber !== null && item.heatNumber !== "" ? item.heatNumber : "N/A";
                item.location = (item.fkey_location && item.fkey_location.name !== null && item.fkey_location.name !== "") ? item.fkey_location.name : 'N/A';
                return item;
            })


            // if ( true) {
            // console.log("receivingDisplayList",receivingDisplayList);
            this.setState({ projectInventoryDisplayList: receivingDisplayList,resDatalen:receivingDisplayList.length, loader: false });
            //  pageChange: 1, limit: 10,
        }
        render() {
            const { pageChange, limit, manualInventorycount } = this.state;

            const sizePerPageOptions = [
                {
                    text: '10',
                    value: 10,
                },
                {
                    text: '25',
                    value: 25,
                },
                {
                    text: '30',
                    value: 30,
                },
                {
                    text: '50',
                    value: 50,

                },
                {
                    text: '100',
                    value: 100,
                },
                // Add more options as needed
            ];

            let that = this;
            const pagination = paginationFactory({
                page: pageChange,
                sizePerPage: limit,
                lastPageText: '>>',
                firstPageText: '<<',
                nextPageText: '>',
                prePageText: '<',
                totalSize: manualInventorycount,
                sizePerPageList: sizePerPageOptions,
                // showTotal: true,
                alwaysShowAllBtns: true,
                onPageChange: function (page, sizePerPage) {
                    let skp = (page - 1) * sizePerPage;
                    let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
                    that.setState({ pageChange: page, skip: skp, limit: sizePerPage }, () => {
                        that.handlePaginationByCondtion(filters);
                    })
                    //that.getManualInventories(filters);
                    // that.getLocationBySearching(filters);

                },
                onSizePerPageChange: function (sizePerPage, page) {
                    if (document.getElementsByClassName('dropdown-menu show')[0].classList.contains('show') === true) {
                        document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
                    }
                    let skp = (page - 1) * limit;
                    that.setState({ pageChange: page, skip: skp, limit: sizePerPage }, () => {
                        let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
                        //that.getManualInventories(filters);
                        // that.getLocationBySearching(filters);
                        that.handlePaginationByCondtion(filters)
                    })

                }
            });
            //------------------------------------------------------------------------// 

            const handleOnTableChange = (type, newState) => {

                if (type === "sort") {
                    if (newState.sortOrder == "asc") {
                        projectInventoryDisplayList.sort((obj1, obj2) => {
                            return newState.sortField == ("jobNumber" || "quantity" || "heatNumber"||"quantityLeft") ?
                                obj1[newState?.sortField] - obj2[newState?.sortField] :
                                obj1[newState?.sortField]?.trim().localeCompare(obj2[newState?.sortField]?.trim(), undefined, { numeric: true, sensitivity: 'base' })
                                ;

                        });
                    }
                    else {
                        projectInventoryDisplayList.sort((obj2, obj1) => {
                            return newState.sortField == ("jobNumber" || "quantity" || "heatNumber"||"quantityLeft") ?
                                obj1[newState?.sortField] - obj2[newState?.sortField] :
                                obj1[newState?.sortField]?.trim().localeCompare(obj2[newState?.sortField]?.trim(), undefined, { numeric: true, sensitivity: 'base' })
                                ;

                        });
                    }
                }
            }
            const columns = [{
                dataField: "material",
                text: "Item",
                sort: true
            },
            {
                dataField: "jobNumber",
                text: "Job#",
                sort: true

            },
            {
                dataField: "length",
                text: "Length",
                sort: true
            },
            {
                dataField: "grade",
                text: "Grade",
                sort: true
            },

            {
                dataField: "quantity",
                text: "Quantity",
                sort: true
            },
            {
                dataField: "heatNumber",
                text: "Heat Number",
                sort: true
            },

            {
                dataField: "location",
                text: "Location",
                sort: true
            },
            {
                dataField: "quantityLeft",
                text: "Available Quantity",
               
            },
            {
                dataField: "",
                text: "Action",
                formatter: this.actionFormat,
            }
            ]

            const roleKey = this.props.loginUserData.roleKey;
            const isAccessable=(roleKey==="superadmin"||roleKey==="admin")?"":"disabled";
            const isDisable =
                roleKey !== "" &&
                    rules[roleKey][Permission.INVENTORY_WRITE]
                    ? ""
                    : "disabled";

            const { loader, locationList, isShowConfirmBox, projectInventoryDisplayList, suggestionManualInventory, selectedInventoryId, jobNumberList } = this.state;
            // console.log(projectInventoryDisplayList);
            return (
                <div>

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex flex-container">
                                <div className="manual-inventory-block">
                                    <button variant="outline-primary" className='btn btn-primary waves-effect waves-light mb-2' onClick={this.downloadInventoryReport}>Download Report</button>
                                </div>
                                <div className="mb-2">
                                    <button variant="outline-primary mb-2" className='btn btn-primary waves-effect waves-light' onClick={this.handleOpenMassDeleteManuaInventory} disabled={(roleKey==="superadmin"||roleKey==="admin")?"":"disabled"}>Mass Delete Manual Inventory</button>
                                </div>
                                <div className="">
                                    <div className="d-flex mb-2">
                                        <label for="inputEmail3" className=" col-form-label mx-1">Job#:</label>

                                        <div className="col-sm-10 col-lg-9" style={{ flex: '1' }}>
                                            <select className="form-select w-200" onChange={(e) => this.handleOnChange(e)} value={Number(this.state.selectedJobNumber)} name="jobNumber"
                                                disabled={(this.state.isLocationSelected || this.state.isSearch || this.state.isTypeInventoryQuantitySelected) ? true : false}
                                            >
                                                <option value="0">Select Job</option>
                                                {jobNumberList.length && jobNumberList.map((itemData) => {
                                                    return (<option value={itemData.jobNumber} >{itemData.jobNumber} </option>);
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <select className="w-200 form-select inventoryLocationDropDownList mb-2"
                                        onChange={this.handleLocationChange}
                                        value={Number(this.state.slocationId)}
                                        name="location" disabled={(this.state.isTypeInventoryQuantitySelected || this.state.isJobDropDownSelected || this.state.isSearch) ? true : false}>
                                        <option value="0">Select Location</option>
                                        {locationList.length && locationList.map((itemData) => {
                                            return (
                                                <option value={itemData.id}>
                                                    {itemData.name}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className=''>
                                    <div className="d-flex mb-2">
                                        <div className='autocomplete-block' style={{ flex: "1" }}>
                                            <input autoComplete='off' className="form-control w-200" type="text" name="search" placeholder="Search an Inventory" value={this.state.search} onChange={e => this.handleSearchInventory(e.target.value)} disabled={(this.state.isTypeInventoryQuantitySelected || this.state.isJobDropDownSelected || this.state.isLocationSelected) ? true : false} onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    this.getSearchedInventory();
                                                }
                                            }} />
                                            <div class="autocomplete-items">
                                                {suggestionManualInventory && suggestionManualInventory.map((suggestion, i) =>
                                                    <div key={i} className="" onClick={() => this.onSuggestionHandler(suggestion.material)} >
                                                        {suggestion.material}
                                                    </div>)}
                                            </div>
                                        </div>
                                        <button className="btn btn-primary ms-2" onClick={this.getSearchedInventory} disabled={(this.state.isTypeInventoryQuantitySelected || this.state.isJobDropDownSelected) ? true : false}><i class="uil uil-search"></i></button>
                                    </div>
                                </div>
                                <div className=''>
                                    <select className="w-200 form-select inventoryLocationDropDownList mb-2"
                                        onChange={this.handleCalculatedInventory}
                                        value={this.state.sQuantityOptions}
                                        name="usedInventoryDropDown" disabled={(this.state.isLocationSelected || this.state.isJobDropDownSelected || this.state.isSearch) ? true : false}>
                                        <option value="0">All</option>
                                        {this.state.inventoryDropDown.length && this.state.inventoryDropDown.map((itemData) => {
                                            return (
                                                <option value={itemData.name}>
                                                    {itemData.title}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="">
                                    <button className="add-inventory btn btn-primary mb-2" onClick={this.handleOpenAddInventoryModal} disabled={isDisable} ><i className="uil uil-plus"></i>Add a new Inventory</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 table-responsive table-scroll whiteSpacing">
                            <div className="project-scope-block">
                                <div className="react-bootstrap-table">
                                    <div className="card card-block">
                                        <div className="card-body">
                                            <BootstrapTable
                                                remote={true}
                                                keyField='id'
                                                data={(projectInventoryDisplayList && projectInventoryDisplayList.length > 0) ? projectInventoryDisplayList : []}
                                                columns={columns}
                                                onTableChange={handleOnTableChange}
                                                pagination={(projectInventoryDisplayList && projectInventoryDisplayList.length > 0) ? pagination : ''}
                                                striped
                                                hover
                                                noDataIndication={(loader === true ? <Loader /> : <p style={{ color: "red" }}>{'No Data Found'}</p>)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* confirm box for deleting an entry from the table */}
                                <ConfirmBox
                                    openModal={isShowConfirmBox}
                                    confirmMsg="Are you sure to delete this item?"
                                    handleConfirmModal={this.handleConfirmModal} />


                                {/* add Inventory for a specific rack modal pop up */}

                                <ModalPopUp
                                    componentData={
                                        <AddInventoryDetails
                                            inventoryId={selectedInventoryId}
                                            saveInventoryDetails={this.handleSaveManualInventoryDetails}
                                            updateInventoryDetails={this.handleUpdateInventory}
                                            closeInventoryModal={this.handleCloseAddInventoryModal}
                                            isManuallyCreatedFlag={this.state.isManuallyCreated}
                                        />}
                                    openModal={this.state.isInventoryDeatilsOpenPopUp}
                                />
                            </div>
                            <ModalPopUp
                                componentData={
                                    <MassDeleteManualInventory
                                        projectInventoryFlag={this.state.isMassDeleteManualInventoriesFlag}
                                        closeModal={this.handleCloseMassDeleteManualInventory}
                                        handleDeleteMassInventory={this.handleDeleteMassInventory}
                                    />
                                }
                                openModal={this.state.isMassDeleteManualInventoriesPopUp}
                            />
                        </div>
                    </div>

                </div>

            )
        }
}
    const mapStateToProps = (props) => {
        return {
            loginUserData: props.loginUserData,
        }
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManualInventory);

