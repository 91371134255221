import React from 'react'

import { Route,Redirect } from 'react-router-dom';

export default function PrivateRoute({children,...rest}) {

   const checkAuth = () =>{
        return (localStorage.getItem('user-token') && localStorage.getItem('user-token') !=="")? true: false;
    }

    return (
        <Route {...rest} render={() =>{
            return checkAuth()?children:<Redirect to={{
                pathname: "/login",
                state: { isLoggedIn: false }
              }} />
        }} />
           
    )
}
