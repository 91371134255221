export const SET_PO_ITEM                     = "SET_PO_ITEM";
export const SET_MATERIAL_ITEM               = "SET_MATERIAL_ITEM";
export const SET_MATERIAL_LEN_QTY            = "SET_MATERIAL_LEN_QTY";
export const SET_PROJECT_MATERIAL_ID         = "SET_PROJECT_MATERIAL_ID";
export const SET_PROJECT_DATA                = "SET_PROJECT_DATA";
export const SET_ESTIMATION_DATA             = "SET_ESTIMATION_DATA";
export const SET_SELECTED_SCOPE              = "SET_SELECTED_SCOPE";
export const SET_PO_FORM_DATA                = "SET_PO_FORM_DATA";
export const SET_MATERIAL_FORM_DATA          = "SET_MATERIAL_FORM_DATA";
export const SET_SHOPFAB_FORM_DATA           = "SET_SHOPFAB_FORM_DATA";
export const SET_FREIGHT_DEL_FORM_DATA       = "SET_FREIGHT_DEL_FORM_DATA";
export const SET_FIELD_INSTALL_FORM_DATA     = "SET_FIELD_INSTALL_FORM_DATA";
export const SET_FREIGHT_DELIVERY_ID         = "SET_FREIGHT_DELIVERY_ID";

export const SET_LOGIN_USER_DATA             = "SET_LOGIN_USER_DATA";

export const SET_SHOPFAB_ERRORS              = "SET_SHOPFAB_ERRORS";
export const SET_FREIGHTDEL_ERRORS           = "SET_FREIGHTDEL_ERRORS";
export const SET_FCCREW_ERRORS               = "SET_FCCREW_ERRORS";
export const SET_LOCATION_HEATNUMBER         = "SET_LOCATION_HEATnUMBER";
export  const  SET_JOB_NUMBER                = "SET_JOB_NUMBER";
export  const  SET_PROJECT_NAME              = "SET_PROJECT_NAME";
//setting which calendar is clicked-----
export const   SET_CALENDAR_CLICKED_NAME     = "SET_CALENDAR_CLICKED_NAME ";
// field measure action type 
export const  SET_FIELD_MEASURE              = "SET_FIELD_MEASURE";