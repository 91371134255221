//----------------IMPORT SECTION STARTS HERE----------------

//React Parent Component------------------------------------
import React, { Component } from "react";

// All API Calls handled here-------------------------------
import projectServices from "../../services/projectServices";

// Tabs Component included here-----------------------------
import Tabs from "./TabComponent/Tabs";

// Bootstrap Table and Pagination---------------------------
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

// Role based access permission-----------------------------
import { rules, Permission } from "../../../../abac.config";

// Redux elements and functions-----------------------------
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Custome modal components---------------------------------
import ModalPopUp from "../../../common/ModalPopUp";

// Loader for component grid before data rendering----------
import Loader from "../../../common/Loader";

// Modal Pop for Shop maintenance loaded here---------------
import ShopItemPopUp from "./Shop/ShopItemPopUp";

// date format----------------------------------------------
import { dateFormat } from "../../../helpers/Util";

// Notification Message-------------------------------------
import { createNotification } from "../../../helpers/Notification";

// Alert box for confirmation before deleting Item----------
import ConfirmBox from "../../../common/ConfirmBox";

//Importing Field Maintenance module -------
import Fieldmaintenance from "./Field/Fieldmaintenance";

import FlagActiveTab from "./FlagActiveTab";
import FieldItemPopUp from "./Field/FieldItemPopUp";
import { Link } from "react-router-dom";
import fieldMaintenancelist from "../../../FieldMaintenanceTypes/fieldMaintenancelist";
import AccessDenied from "../../../common/AccessDenied";

//----------------IMPORT SECTION ENDS HERE------------------

class Maintenance extends Component {

    constructor(props) {
        super(props);
        this.FieldmaintenanceElement = React.createRef();
        this.state = {
            itemId: 0,
            counter: 0,
            ActiveId: 0,
            shopList: [],
            loader: false,
            detailName: "",
            modalStatus: '',
            shopFirstIndex: 0,
            shopDetailList: [],
            serviceInterval: "",
            isShowConfirmBox: false,
            maintenanceDisplayListArr: [],
            isOpenMaintenancePopUp: false,
            statusFlag: (this.props.location.state !== undefined && this.props.location.state.statusFlag === true ? true : false),

            //FIELD MAINTENANCE VARIABLES:=>
            fieldList: [],
            fieldCounter: 0,
            fieldActiveId: 0,
            maintenanceFieldTypeFieldsArr: [],
            fieldMaintenanceDisplayListArr: [],
            fieldDataforUpdate: [],

            // OverDues :=>
            OverdueData: [],
            isOverduePanelActive: false,
            overdueCustomFieldsVal: [],
            //responseLengthConstants-
            shopMaintenanceResDataLen: 0,
            fieldMaintenanceResDataLen: 0,
            //Pagination :=>
            shopPage: 1,
            shopSkip: 0,
            shopLimit: 10,
            shopCount: 0,
            shopFilterData: `?filter[skip]=0&filter[limit]=10`,

            fieldPage: 1,
            fieldSkip: 0,
            fieldLimit: 10,
            fieldCount: 0,

            //-------filter items---------//
            itemData: [
                {
                    id: "1",
                    name: "Pending",
                    title: "PENDING",
                },
                {
                    id: "2",
                    name: "InProgress",
                    title: "INPROGRESS",
                },
                {
                    id: "3",
                    name: "Done",
                    title: "DONE",
                },
                {
                    id: "4",
                    name: "Overdue",
                    title: "OVERDUE",
                },
            ],
            //shopPersonal Filters---
            TargetedValShopMaintenance: "",
            TargetedValFieldMaintenance: "",
        }
    }

    //---------------------------------------//

    componentDidMount = () => {

        if (localStorage.getItem('shopMaintenancePaginationConstants') !== null) {
            this.getShopsViaConditions();
            this.getOverdues();

        } else if (localStorage.getItem('fieldMaintenancePaginationConstants') !== null) {

            const fieldMaintenancePagination = JSON.parse(localStorage.getItem('fieldMaintenancePaginationConstants'))
            const { fieldLimit, fieldPage, fieldSkip, fieldStatusFlag, TargetedValFieldMaintenance, fieldActiveId } = fieldMaintenancePagination
            if (fieldMaintenancePagination !== null || fieldMaintenancePagination !== undefined) {
                this.setState({
                    fieldLimit: fieldLimit, fieldPage: fieldPage, fieldSkip: fieldSkip, statusFlag: fieldStatusFlag, TargetedValFieldMaintenance: TargetedValFieldMaintenance,
                    fieldActiveId: fieldActiveId
                })
            }

        } else if ((localStorage.getItem('shopMaintenancePaginationConstants') === null) && (localStorage.getItem('fieldMaintenancePaginationConstants') === null)) {
            this.getShops();
        }
        this.getOverdues();
    };

    //---------------------------------------//
    // GET SHOP LIST---
    getShops = () => {
        const { counter } = this.state;
        let filters = `?filter[skip]=${this.state.shopSkip}&filter[limit]=${this.state.shopLimit}`;

        if (counter === 0) {
            this.setState({ loader: true });
            projectServices.getShops().then((res) => {

                this.setState({ shopList: res.data.data, ActiveId: res.data.data[0].id, shopFirstIndex: res.data.data[0].id, loader: false, shopFlterData: filters });
                this.getShopsDetail(res.data.data[0].id, filters);
            }).catch((error) => {
                if (error.response.status === 401) {
                    createNotification("error", "Your login session has been expired!!");
                    localStorage.setItem('user-token', "");
                    this.props.history.push('/login');
                } else {
                    createNotification("error", error.response.data.error.message);
                }
                console.log(error);
            })
        } else {
            projectServices.getShops().then((res) => {
                this.setState({ shopList: res.data.data });
            }).catch((error) => {
                console.log(error);
            })
        }

    }
    //call this api when we are 
    getShopsViaConditions = () => {

        const { counter } = this.state;
        const shopMaintenancePagination = JSON.parse(localStorage.getItem('shopMaintenancePaginationConstants'))
        const { shopActiveId, shopLimit, shopPage, shopStatusFlag, shopSkip, TargetedValShopMaintenance } = shopMaintenancePagination
        if (shopMaintenancePagination !== undefined || shopMaintenancePagination !== null) {
            this.setState({ ActiveId: shopActiveId, shopLimit: shopLimit, shopPage: shopPage, statusFlag: shopStatusFlag, TargetedValShopMaintenance: TargetedValShopMaintenance, shopSkip: shopSkip })
        }

        let filters = `?filter[skip]=${shopSkip}&filter[limit]=${shopLimit}`;
        if (counter === 0) {
            this.setState({ loader: true });
            projectServices.getShops().then((res) => {
                // console.log(res);
                let newCount = (this.state.counter + 1);
                this.setState({ shopList: res.data.data, ActiveId: shopActiveId, shopFirstIndex: res.data.data[0].id, loader: false, shopFlterData: filters, counter: newCount + 1 });
                if (TargetedValShopMaintenance !== "") {
                    this.getDataByStatusShopORField()
                } else {
                    this.getShopsDetail(shopActiveId, filters);
                }

            }).catch((error) => {
                if (error.response.status === 401) {
                    createNotification("error", "Your login session has been expired!!");
                    localStorage.setItem('user-token', "");
                    this.props.history.push('/login');
                } else {
                    createNotification("error", error.response.data.error.message);
                }
                console.log(error);
            })
        } else {
            projectServices.getShops().then((res) => {
                this.setState({ shopList: res.data.data });
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    //---------------------------------------//
    // Set shop id to each shop's row, listed under SHOP tab
    handleShopClick = (id) => {

        let newCount = (this.state.counter + 1);
        this.setState({ ActiveId: id, counter: newCount, shopSkip: 0, shopPage: 1, shopLimit: 10, shopFilterData: `?filter[skip]=0&filter[limit]=10` });

        // Retrieve, parse, modify, and store in one line
        // localStorage.setItem('activeShopId')

    }

    //---------------------------------------//

    // checking if shop id maches to state id, then add a class called "active" to highlight the selection
    checkIsActive = (id) => {
        return (this.state.ActiveId === id && this.state.counter !== 0) ? 'active' : '';
    }

    //---------------------------------------//
    // Get Detail item list against selected SHOP, based on their shop Ids.  
    getShopsDetail = (shopID, filters) => {
        // console.log(shopID);
        this.setState({ loader: true })
        projectServices.getShopsDetail(shopID, filters).then((response) => {
            this.setState({ shopDetailList: response.data.data, shopMaintenanceResDataLen: response.data.data.length, shopCount: response.data.count, loader: false });
            this.getMaintenanceData();
        }).catch((error) => {
            console.log(error);
        })
    }

    //---------------------------------------//

    // Create action buttons for each item details in their respective rows with add,edit and delete functionalities.
    actionFormat = (cell, row, rowIndex, formatExtraData) => {
        const { shopList, fieldList, statusFlag } = this.state;
        let shopName = '';
        let fieldName = '';
        if (statusFlag === false) {
            shopList.map((item) => {
                if (item.id === row.shopId) {
                    shopName = item.name;
                }
            })
        } else if (statusFlag === true) {
            fieldList.map((item) => {
                if (item.id === row.maintenanceFieldTypeId) {
                    fieldName = item.name;
                }
            })
        }

        // ACCESS PERMISSION ROLE KEYS WITH THERE PERMISSION RIGHTS----------------------------------
        let roleKey = this.props.loginUserData.roleKey;
        const isAccessable = (roleKey !== "" && rules[roleKey][Permission.MAINTENANCE_WRITE]) ? true : false;
        // ACCESS PERMISSION ROLE KEYS WITH THERE PERMISSION RIGHTS----------------------------------

        return (
            <div>
                <div style={isAccessable === true ? { display: "block-inline" } : { display: "none" }}>
                    {this.state.statusFlag === false ?
                        // SHOP MAINTENANCE
                        <>
                            <span title="Edit Item" className="uil uil-edit text-info action-btn" onClick={() => { this.updateItems(row); this.openModalonClick("update") }}></span>&nbsp;&nbsp;
                            <span title="Delete Item" className="uil uil-trash-alt text-danger action-btn" onClick={() => { this.deleteItem(row.shopId, row.id) }}></span>&nbsp;&nbsp;
                            {
                                row.status === "Pending" || row.status === "Overdue" ?
                                    (
                                        <>
                                            <span title="Add Maintenance" className="uil uil-focus-add text-success action-btn" onClick={() => { this.props.history.push({ pathname: '/maintenance/add', state: row, search: `${shopName}` }); this.setLocalStorageShopMaintenanceItems() }}></span>&nbsp;&nbsp;
                                            <span title="Edit Maintenance [disabled]" disabled className="uil uil-edit-alt text-secondary disabled action-btn"></span>
                                        </>
                                    )
                                    :
                                    row.status === "In Progress" || row.status === "Overdue" ?
                                        (
                                            <>
                                                <span title="Add Maintenance [disabled]" disabled className="uil uil-focus-add text-secondary disabled action-btn"></span>&nbsp;&nbsp;
                                                <span title="Edit Maintenance" className="uil uil-edit-alt text-primary action-btn" onClick={() => { this.props.history.push({ pathname: '/maintenance/edit', state: row, search: `${shopName}` }); this.setLocalStorageShopMaintenanceItems() }}></span>
                                            </>
                                        )
                                        :
                                        row.status == "Done" ?
                                            (
                                                <>
                                                    <span title="Add Maintenance [disabled]" disabled className="uil uil-focus-add text-secondary disabled action-btn"></span>&nbsp;&nbsp;
                                                    <span title="View Maintenance" className="uil uil-eye text-warning action-btn" onClick={() => { this.props.history.push({ pathname: '/maintenance/view', state: row, search: `${shopName}` }); this.setLocalStorageShopMaintenanceItems() }}></span>
                                                </>
                                            )
                                            :
                                            (<></>)
                            }
                        </>
                        :
                        // FIELD MAINTENANCE    
                        <>
                            <span title="Edit Item" className="uil uil-edit text-info action-btn" onClick={() => { this.updateItems(row); this.openModalonClick("update") }}></span>&nbsp;&nbsp;
                            <span title="Delete Item" className="uil uil-trash-alt text-danger action-btn" onClick={() => { this.deleteItem(row.maintenanceFieldTypeId, row.id) }}></span>&nbsp;&nbsp;
                            {
                                row.status === "Pending" || row.status === "Overdue" ?
                                    (
                                        <>
                                            <span title="Add Maintenance" className="uil uil-focus-add text-success action-btn" onClick={() => { this.props.history.push({ pathname: '/maintenance/field/add', state: row, search: `${fieldName}` }); this.setLocalStorageFieldMaintenanceItems() }}></span>&nbsp;&nbsp;
                                            <span title="Edit Maintenance [disabled]" disabled className="uil uil-edit-alt text-secondary disabled action-btn"></span>
                                        </>
                                    )
                                    :
                                    row.status === "In Progress" || row.status === "Overdue" ?
                                        (
                                            <>
                                                <span title="Add Maintenance [disabled]" disabled className="uil uil-focus-add text-secondary disabled action-btn"></span>&nbsp;&nbsp;
                                                <span title="Edit Maintenance" className="uil uil-edit-alt text-primary action-btn" onClick={() => { this.props.history.push({ pathname: '/maintenance/field/edit', state: row, search: `${fieldName}` }); this.setLocalStorageFieldMaintenanceItems() }}></span>
                                            </>
                                        )
                                        :
                                        row.status == "Done" ?
                                            (
                                                <>
                                                    <span title="Add Maintenance [disabled]" disabled className="uil uil-focus-add text-secondary disabled action-btn"></span>&nbsp;&nbsp;
                                                    <span title="View Maintenance" className="uil uil-eye text-warning action-btn" onClick={() => { this.props.history.push({ pathname: '/maintenance/field/view', state: row, search: `${fieldName}` }); this.setLocalStorageFieldMaintenanceItems() }}></span>
                                                </>
                                            )
                                            :
                                            (<></>)
                            }
                        </>
                    }
                </div>
                <div style={isAccessable === false ? { display: "block-inline" } : { display: "none" }}>
                    {this.state.statusFlag === false ?
                        <span title="View Maintenance" className="uil uil-eye text-warning action-btn" onClick={() => { this.props.history.push({ pathname: '/maintenance/view', state: row, search: `${shopName}` }); this.setLocalStorageShopMaintenanceItems() }}></span>
                        :
                        <span title="View Maintenance" className="uil uil-eye text-warning action-btn" onClick={() => { this.props.history.push({ pathname: '/maintenance/field/view', state: row, search: `${fieldName}` }); this.setLocalStorageFieldMaintenanceItems() }}></span>
                    }
                </div>
            </div>
        )
    }

    setLocalStorageShopMaintenanceItems = () => {
        const { shopLimit, shopSkip, shopPage, ActiveId, TargetedValShopMaintenance } = this.state;
        //    console.log(shopLimit,";;;",shopSkip,";;;",shopPage,";;;",ActiveId);
        localStorage.setItem('shopMaintenancePaginationConstants', JSON.stringify({ shopLimit: shopLimit, shopSkip: shopSkip, shopPage: shopPage, shopStatusFlag: false, TargetedValShopMaintenance: '' }));
        if (ActiveId !== 0) {
            // Retrieve, parse, modify, and store in one line
            localStorage.setItem('shopMaintenancePaginationConstants', JSON.stringify({
                ...JSON.parse(localStorage.getItem('shopMaintenancePaginationConstants')),
                shopActiveId: ActiveId
            }));
        }
        if (TargetedValShopMaintenance !== "") {
            localStorage.setItem('shopMaintenancePaginationConstants', JSON.stringify({
                ...JSON.parse(localStorage.getItem('shopMaintenancePaginationConstants')),
                TargetedValShopMaintenance: TargetedValShopMaintenance
            }));
        }
    }
    // ----------------setting field maintenance constants-------------------//
    setLocalStorageFieldMaintenanceItems = () => {
        const { fieldLimit, fieldSkip, fieldActiveId, TargetedValFieldMaintenance, statusFlag, fieldPage } = this.state;
        localStorage.setItem('fieldMaintenancePaginationConstants', JSON.stringify({ fieldLimit: fieldLimit, fieldSkip: fieldSkip, fieldPage: fieldPage, fieldStatusFlag: true, TargetedValFieldMaintenance: '' }));
        if (fieldActiveId !== 0) {
            // Retrieve, parse, modify, and store in one line
            localStorage.setItem('fieldMaintenancePaginationConstants', JSON.stringify({
                ...JSON.parse(localStorage.getItem('fieldMaintenancePaginationConstants')),
                fieldActiveId: fieldActiveId
            }));
        }
        if (TargetedValFieldMaintenance !== "") {
            localStorage.setItem('fieldMaintenancePaginationConstants', JSON.stringify({
                ...JSON.parse(localStorage.getItem('fieldMaintenancePaginationConstants')),
                TargetedValFieldMaintenance: TargetedValFieldMaintenance
            }));
        }
    }
    //---------------------------------------//

    // Open popup for adding item details when clicked
    addItems = (itemId) => {
        if (itemId != undefined) {
            if (this.state.statusFlag === false) {
                this.setState({ ActiveId: itemId, isOpenMaintenancePopUp: true });
            } else {
                this.setState({ fieldActiveId: itemId, isOpenMaintenancePopUp: true });
            }
        }
    }

    //---------------------------------------//

    //Open popup for updating item details when clicked.
    updateItems = (details) => {
        if (this.state.statusFlag === false) {
            this.setState({
                ActiveId: details.shopId,
                detailName: details.name,
                serviceInterval: details.serviceInterval,
                itemId: details.id,
                isOpenMaintenancePopUp: true
            });
        } else {
            this.setState({
                fieldDataforUpdate: details,
                isOpenMaintenancePopUp: true,
            })
        }
    }

    //---------------------------------------//

    // Maintenance Modal Pop up--------------//
    // Shop Maintenance Modal popup---
    handleCloseModal = () => {
        this.setState({ isOpenMaintenancePopUp: false });
    }

    //---------------------------------------//

    //Shop Maintenance handle click event for  save popup
    handleSaveShopItem = (name, sInterval) => {
        document.querySelector('select[name=statusSearch]').value = '';
        let shopID = this.state.ActiveId;
        let payLoad = { name, serviceInterval: sInterval }
        let filters = `?filter[skip]=${this.state.shopSkip}&filter[limit]=${this.state.shopLimit}`;
        projectServices.addItemDetails(shopID, payLoad).then((res) => {
            createNotification("success", "Shop item added successfully!");
            this.handleCloseModal();
            this.getShopsDetail(shopID, filters);
        }).catch((error) => {
            if (error.response.status === 401) {
                createNotification("error", "Your login session has been expired!");
                localStorage.setItem("user-token", "");
                this.props.history.push("/login");
            } else {
                createNotification("error", error.response.data.error.message);
            }
            console.log(error);
        })
    }

    //Shop Maintenance handle click event for  update popup
    handleUpdateShopItem = (name, sInterval) => {
        document.querySelector('select[name=statusSearch]').value = '';
        let shopID = this.state.ActiveId;
        let itemID = this.state.itemId;
        let payLoad = { name, serviceInterval: sInterval }
        let filters = `?filter[skip]=${this.state.shopSkip}&filter[limit]=${this.state.shopLimit}`;
        projectServices.updateItemDetails(shopID, itemID, payLoad).then((res) => {
            createNotification("success", "Shop item updated successfully!");
            this.handleCloseModal();
            this.getShopsDetail(shopID, filters);
        }).catch((error) => {
            if (error.response.status === 401) {
                createNotification("error", "Your login session has been expired!");
                localStorage.setItem("user-token", "");
                this.props.history.push("/login");
            } else {
                createNotification("error", error.response.data.error.message);
            }
            console.log(error);
        })
    }

    // Delete Item------------
    deleteItem = (parentId, itemId) => {
        if (this.state.statusFlag === false) {//SHOP MAINTENANCE
            this.setState({ ActiveId: parentId, itemId: itemId, isShowConfirmBox: true });
        } else {// FIELD MAINTENANCE
            this.setState({ fieldActiveId: parentId, itemId: itemId, isShowConfirmBox: true });
        }
    }

    //----------------------------------------//

    handleConfirmModal = (isYes) => {
        const { ActiveId, itemId, fieldActiveId, statusFlag } = this.state;
        if (isYes) {
            document.querySelector('select[name=statusSearch]').value = '';
            this.setState({ isShowConfirmBox: !this.state.isShowConfirmBox });
            // DELETE ACTION WRITTEN HERE--------START
            if (statusFlag === false) { // SHOP MAINTENANCE
                const { shopSkip, TargetedValShopMaintenance, shopLimit, shopPage, shopMaintenanceResDataLen } = this.state;
                let localSkip = 0;
                let localPageChange = 0;
                let filters;

                if (shopMaintenanceResDataLen === 1 && shopPage > 1) {
                    localSkip = shopSkip - shopLimit;
                    localPageChange = shopPage - 1;
                    filters = `?filter[skip]=${localSkip}&filter[limit]=${this.state.limit}`
                    this.setState({ shopPage: localPageChange, shopSkip: localSkip })
                } else {
                    filters = `?filter[skip]=${this.state.shopSkip}&filter[limit]=${this.state.shopLimit}`;
                }
                projectServices.deleteShopItem(ActiveId, itemId).then((res) => {
                    createNotification("success", "Shop item deleted successfully!");
                    filters = `?filter[skip]=${this.state.shopSkip}&filter[limit]=${this.state.shopLimit}`;
                    if (TargetedValShopMaintenance !== "") {
                        this.getDataByStatusShopORField()
                    } else {
                        this.getShopsDetail(ActiveId, filters);
                    }

                }).catch((error) => {
                    if (error.response.status === 401) {
                        createNotification("error", "Your login session has been expired!");
                        localStorage.setItem("user-token", "");
                        this.props.history.push("/login");
                    } else {
                        createNotification("error", error.response.data.error.message);
                        this.setState({ isShowConfirmBox: false });
                    }
                    console.log(error);
                })
            } else if (statusFlag === true) { // FIELD MAINTENANCE
                const { fieldSkip, TargetedValFieldMaintenance, fieldLimit, fieldPage, fieldMaintenanceResDataLen } = this.state;
                let localSkip = 0;
                let localPageChange = 0;
                let filters;

                if (fieldMaintenanceResDataLen === 1 && fieldPage > 1) {

                    localSkip = fieldSkip - fieldLimit;
                    localPageChange = fieldPage - 1;
                    filters = `?filter[skip]=${localSkip}&filter[limit]=${this.state.limit}`
                    this.setState({ fieldPage: localPageChange, fieldSkip: localSkip })

                } else {

                    filters = `?filter[skip]=${this.state.fieldSkip}&filter[limit]=${this.state.fieldLimit}`;
                }
                projectServices.deleteFieldItem(fieldActiveId, itemId).then((res) => {
                    createNotification("success", "Field item deleted successfully!");
                    // let filters = `?filter[skip]=${this.state.fieldSkip}&filter[limit]=${this.state.fieldLimit}`;
                    if (TargetedValFieldMaintenance !== "") {

                        this.getDataByStatusShopORField()
                    } else {

                        projectServices.getFieldsDetail(fieldActiveId, filters).then((response) => {
                            let newDataArray = response.data.data.map((item) => {
                                item.name = item.name;
                                item.lastDate = item.lastDate !== null ? dateFormat(item.lastDate) : 'N/A';
                                item.nextDate = item.nextDate !== null ? dateFormat(item.nextDate) : 'N/A';
                                item.status = (
                                    item.status === "PENDING" ? "Pending" :
                                        item.status === "INPROGRESS" ? "In Progress" :
                                            item.status === "DONE" ? "Done" :
                                                item.status === "OVERDUE" ? "Overdue" : 'N/A'
                                );
                                return item;
                            })
                            this.setState({ fieldMaintenanceDisplayListArr: newDataArray, fieldMaintenanceResDataLen: newDataArray.length })
                        }).catch((error) => {
                            if (error.response.status === 401) {
                                createNotification("error", "Your login session has been expired!");
                                localStorage.setItem("user-token", "");
                                this.props.history.push("/login");
                            } else {
                                createNotification("error", error.response.data.error.message);
                                this.setState({ isShowConfirmBox: false });
                            }
                            console.log(error);
                        })
                    }

                })
                // DELETE ACTION WRITTEN HERE--------END
            }
        } else {
            this.setState({ isShowConfirmBox: false });
        }
    }

    //---------------------------------------//

    // Populate details item list against each selected shop in proper format.
    getMaintenanceData = () => {
        const { shopDetailList } = this.state;
        let newDataArray = shopDetailList.map((item) => {
            item.name = item.name;
            item.lastDate = item.lastDate !== null ? dateFormat(item.lastDate) : 'N/A';
            item.nextDate = item.nextDate !== null ? dateFormat(item.nextDate) : 'N/A';
            item.status = (
                item.status === "PENDING" ? "Pending" :
                    item.status === "INPROGRESS" ? "In Progress" :
                        item.status === "DONE" ? "Done" :
                            item.status === "OVERDUE" ? "Overdue" : 'N/A'
            );
            return item;
        })
        this.setState({ maintenanceDisplayListArr: newDataArray, shopMaintenanceResDataLen: newDataArray.length });
    }

    //---------------------------------------//

    // Switch and open modal content based on clicked action, i.e. either "add" or "update"
    openModalonClick = (modalStatusonClick) => {
        if (modalStatusonClick === "add") {
            return this.setState({ modalStatus: modalStatusonClick });
        } else if (modalStatusonClick === "update") {
            return this.setState({ modalStatus: modalStatusonClick });
        } else {
            return this.setState({ modalStatus: "" });
        }
    }

    //-----------------------------------------//

    handleFlag = (flag) => {
        document.querySelector('select[name=statusSearch]').value = '';

        if (flag === false) {
            this.setState({ statusFlag: flag, counter: 0, shopPage: 1, shopLimit: 10, ActiveId: this.state.shopFirstIndex, shopSkip: 0, shopPage: 1, shopLimit: 10, TargetedValShopMaintenance: "", counter: 0 }, () => {
                let shopId = this.state.shopFirstIndex;
                let filters = `?filter[skip]=0&filter[limit]=10`;
                this.getShops();
                // this.getShopsDetail(shopId, filters);
                localStorage.removeItem('fieldMaintenancePaginationConstants')
            });

        } else if (flag === true) {
            // console.log(flag);
            this.setState({ statusFlag: flag, fieldCounter: 0, fieldPage: 1, fieldLimit: 10, fieldSkip: 0, TargetedValFieldMaintenance: "" });
            localStorage.removeItem('shopMaintenancePaginationConstants')
        }
    }

    // FIELD MAINTENANCE-----------------------------------------

    handleFieldList = (fList) => {
        // console.log({fList:fList});
        this.setState({ fieldList: fList });
    }

    handleFieldClick = (id) => {
        //  console.log(id,"id");
        let newCount = (this.state.fieldCounter + 1);
        this.setState({ fieldActiveId: id, fieldCounter: newCount, fieldPage: 1, fieldLimit: 10 });
        let newArray = [];
        this.state.fieldList.map((fieldItem) => {
            if (fieldItem.id === id) {
                newArray = fieldItem.maintenanceFieldTypeFields;
            }
        })
        this.setState({ maintenanceFieldTypeFieldsArr: newArray })
    }

    handleFieldMaintenanceData = (fmData) => {
        //    console.log(fmData,"fmData");
        this.setState({ fieldMaintenanceDisplayListArr: fmData, fieldMaintenanceResDataLen: fmData.length })
    }

    handleFieldCounter = (fieldCounter) => {
        //    console.log(fieldCounter,"fieldCounter");
        this.setState({ fieldCounter: fieldCounter })
    }

    handleSaveFieldItem = (itemName, fieldArr) => {
        document.querySelector('select[name=statusSearch]').value = '';
        let fieldId = this.state.fieldActiveId;
        // console.log(fieldId);
        let payLoad = { name: itemName, fields: fieldArr };
        projectServices.fieldAddItem(fieldId, payLoad).then((response) => {
            if (response.status === 200) {
                createNotification('success', "Field item added successfully!");
                this.handleCloseModal();
                let filters = `?filter[skip]=${this.state.fieldSkip}&filter[limit]=${this.state.fieldLimit}`;
                projectServices.getFieldsDetail(fieldId, filters).then((response) => {
                    let newDataArray = response.data.data.map((item) => {
                        item.name = item.name;
                        item.lastDate = item.lastDate !== null ? dateFormat(item.lastDate) : 'N/A';
                        item.nextDate = item.nextDate !== null ? dateFormat(item.nextDate) : 'N/A';
                        item.status = (
                            item.status === "PENDING" ? "Pending" :
                                item.status === "INPROGRESS" ? "In Progress" :
                                    item.status === "DONE" ? "Done" :
                                        item.status === "OVERDUE" ? "Overdue" : 'N/A'
                        );
                        return item;
                    })
                    this.setState({ fieldMaintenanceDisplayListArr: newDataArray, fieldCount: response.data.count })
                }).catch((error) => {
                    console.log(error);
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    //---------------------------------------//
    handleUpdateFieldItem = (itemName, fieldArr) => {
        document.querySelector('select[name=statusSearch]').value = '';
        let fieldId = (this.state.fieldCounter === 0 ? this.state.fieldList[0].id : this.state.fieldActiveId);
        let fieldItemId = this.state.fieldDataforUpdate.id;
        let payLoad = { name: itemName, fields: fieldArr };
        projectServices.fieldUpdateItem(fieldId, fieldItemId, payLoad).then((response) => {
            if (response.status === 200) {
                createNotification('success', "Field item updated successfully!");
                this.handleCloseModal();
                let filters = `?filter[skip]=${this.state.fieldSkip}&filter[limit]=${this.state.fieldLimit}`;
                projectServices.getFieldsDetail(fieldId, filters).then((response) => {
                    let newDataArray = response.data.data.map((item) => {
                        item.name = item.name;
                        item.lastDate = item.lastDate !== null ? dateFormat(item.lastDate) : 'N/A';
                        item.nextDate = item.nextDate !== null ? dateFormat(item.nextDate) : 'N/A';
                        item.status = (
                            item.status === "PENDING" ? "Pending" :
                                item.status === "INPROGRESS" ? "In Progress" :
                                    item.status === "DONE" ? "Done" :
                                        item.status === "OVERDUE" ? "Overdue" : 'N/A'
                        );
                        return item;
                    })
                    this.setState({ fieldMaintenanceDisplayListArr: newDataArray })
                }).catch((error) => {
                    console.log(error);
                })
            }
        }).catch((error) => {
            createNotification("error", error.response.data.error.message)
            console.log(error);
        })
    }

    handleonChangeFilter = (event) => {
        const { statusFlag, ActiveId, fieldActiveId, fieldCounter, fieldList, TargetedValShopMaintenance } = this.state;
        let TargetedVal = event.target.value.toUpperCase();

        if (statusFlag === false) { // SHOP MAINTENANCE
            let shopID = ActiveId;
            let filters;
            this.setState({ shopSkip: 0, shopLimit: 10, shopPage: 1 })
            filters = `?filter[skip]=${0}&filter[limit]=${10}`;

            projectServices.getFilteredShopData(shopID, filters, TargetedVal).then((response) => {
                let newDataArray = response.data.data.map((item) => {
                    item.name = item.name;
                    item.lastDate = item.lastDate !== null ? dateFormat(item.lastDate) : 'N/A';
                    item.nextDate = item.nextDate !== null ? dateFormat(item.nextDate) : 'N/A';
                    item.status = (
                        item.status === "PENDING" ? "Pending" :
                            item.status === "INPROGRESS" ? "In Progress" :
                                item.status === "DONE" ? "Done" :
                                    item.status === "OVERDUE" ? "Overdue" : 'N/A'
                    );
                    return item;
                })
                this.setState({ maintenanceDisplayListArr: newDataArray, TargetedValShopMaintenance: TargetedVal, shopCount: response.data.count, shopMaintenanceResDataLen: newDataArray.length });
            }).catch((error) => {
                console.log(error);
            })
        } else { // FIELD MAINTENANCE
            console.log(fieldList);
            let fieldID = (fieldCounter === 0 ? fieldList[0].id : fieldActiveId)

            let filters;
            this.setState({ fieldSkip: 0, fieldLimit: 10, fieldPage: 1 })
            filters = `?filter[skip]=${0}&filter[limit]=${10}`;
            projectServices.getFilteredFieldData(fieldID, filters, TargetedVal).then((response) => {
                let newDataArray = response.data.data.map((item) => {
                    item.name = item.name;
                    item.lastDate = item.lastDate !== null ? dateFormat(item.lastDate) : 'N/A';
                    item.nextDate = item.nextDate !== null ? dateFormat(item.nextDate) : 'N/A';
                    item.status = (
                        item.status === "PENDING" ? "Pending" :
                            item.status === "INPROGRESS" ? "In Progress" :
                                item.status === "DONE" ? "Done" :
                                    item.status === "OVERDUE" ? "Overdue" : 'N/A'
                    );
                    return item;
                })
                this.setState({ fieldMaintenanceDisplayListArr: newDataArray, TargetedValFieldMaintenance: TargetedVal, fieldCount: response.data.count })
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    getDataByStatusShopORField = () => {
        const { statusFlag, ActiveId, fieldActiveId, fieldCounter, fieldList, TargetedValShopMaintenance, TargetedValFieldMaintenance } = this.state;
        // let TargetedVal = event.target.value.toUpperCase();

        if (statusFlag === false) { // SHOP MAINTENANCE
            let shopID = ActiveId;
            let filters = `?filter[skip]=${this.state.shopSkip}&filter[limit]=${this.state.shopLimit}`;

            projectServices.getFilteredShopData(shopID, filters, TargetedValShopMaintenance).then((response) => {

                let newDataArray = response.data.data.map((item) => {
                    item.name = item.name;
                    item.lastDate = item.lastDate !== null ? dateFormat(item.lastDate) : 'N/A';
                    item.nextDate = item.nextDate !== null ? dateFormat(item.nextDate) : 'N/A';
                    item.status = (
                        item.status === "PENDING" ? "Pending" :
                            item.status === "INPROGRESS" ? "In Progress" :
                                item.status === "DONE" ? "Done" :
                                    item.status === "OVERDUE" ? "Overdue" : 'N/A'
                    );
                    return item;
                })
                this.setState({ maintenanceDisplayListArr: newDataArray, shopCount: response.data.count, shopMaintenanceResDataLen: newDataArray.length });
            }).catch((error) => {
                console.log(error);
            })
        } else { // FIELD MAINTENANCE
            let fieldID = (fieldCounter === 0 ? fieldList[0].id : fieldActiveId)
            let filters = `?filter[skip]=${this.state.fieldSkip}&filter[limit]=${this.state.fieldLimit}`;
            projectServices.getFilteredFieldData(fieldID, filters, TargetedValFieldMaintenance).then((response) => {
                console.log(response);
                let newDataArray = response.data.data.map((item) => {
                    item.name = item.name;
                    item.lastDate = item.lastDate !== null ? dateFormat(item.lastDate) : 'N/A';
                    item.nextDate = item.nextDate !== null ? dateFormat(item.nextDate) : 'N/A';
                    item.status = (
                        item.status === "PENDING" ? "Pending" :
                            item.status === "INPROGRESS" ? "In Progress" :
                                item.status === "DONE" ? "Done" :
                                    item.status === "OVERDUE" ? "Overdue" : 'N/A'
                    );
                    return item;
                })
                this.setState({ fieldMaintenanceDisplayListArr: newDataArray, fieldCount: response.data.count })
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    ///-------------------------------------------//
    //---------------------------------------//
    handleFieldLoader = (loaderFlag) => {
        console.log(loaderFlag, "loaderFlag");
        this.setState({ loader: loaderFlag });
    }

    //---------------------------------------//

    getOverdues = () => {
        projectServices.getOverdues().then((response) => {
            if (response) {
                let responseDataArr = response.data.data;
                responseDataArr.map((item, index) => {
                    if (item.type === 'Field') {
                        let customFieldValArr = [];
                        projectServices.getCustomfieldData(item.maintenanceFieldTypeId, item.id).then((resp) => {
                            if (resp) {
                                customFieldValArr = resp.data.data;
                            }
                            if (item.id === customFieldValArr.id) {
                                customFieldValArr.maintenanceFieldMasterFields.sort((a, b) => a.maintenanceFieldTypeFieldId - b.maintenanceFieldTypeFieldId)
                                item.maintenanceFieldMasterFields = customFieldValArr.maintenanceFieldMasterFields;
                            }
                        }).catch((error) => {
                            console.log(error);
                        })
                    }
                    return item;
                })
                this.setState({ OverdueData: responseDataArr })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    handleOverduePanel = (flag) => {
        if (flag === true) {
            this.setState({ isOverduePanelActive: flag })
            projectServices.readAllOverdues().then((response) => {
            }).catch((error) => {
                console.log(error);
            })
        } else {
            this.setState({ isOverduePanelActive: flag })
        }
    }

    handleClick = (fieldActiveId, filters) => {
        this.FieldmaintenanceElement.current.getFieldsDetail(fieldActiveId, filters);
    }

    handleFieldRecordCount = (fCount) => {

        this.setState({ fieldCount: fCount })
    }

    handleFieldActiveID = (fID) => {

        if (this.state.fieldCounter === 0) {
            this.setState({ fieldActiveId: fID });
        } else {
            this.setState({ fieldActiveId: fID })
        }
    }
    //---------------------------------------//

    render() {
        // ACCESS PERMISSION ROLE KEYS WITH THERE PERMISSION RIGHTS----------------------------------
        let roleKey = this.props.loginUserData.roleKey;
        const isAccess = (roleKey !== "" && rules[roleKey][Permission.MAINTENANCE_WRITE]) ? true : false;
        // ACCESS PERMISSION ROLE KEYS WITH THERE PERMISSION RIGHTS----------------------------------

        const { shopList, ActiveId, maintenanceDisplayListArr, isShowConfirmBox, loader, statusFlag, fieldMaintenanceDisplayListArr, fieldList, fieldCounter, fieldActiveId, maintenanceFieldTypeFieldsArr, OverdueData, isOverduePanelActive, shopPage, shopLimit, shopCount, fieldPage, fieldLimit, fieldCount, TargetedValFieldMaintenance, TargetedValShopMaintenance } = this.state;
        let overdueNotificationCountforUnread = 0;
        if (OverdueData !== undefined) {
            OverdueData.map((item) => {
                if (item.isRead !== true) {
                    overdueNotificationCountforUnread = overdueNotificationCountforUnread + 1;
                }
                return item
            })
        }

        const sizePerPageOptions = [
            {
                text: '10',
                value: 10,
            },
            {
                text: '25',
                value: 25,
            },
            {
                text: '30',
                value: 30,
            },
            {
                text: '50',
                value: 50,

            },
            {
                text: '100',
                value: 100,
            },
            // Add more options as needed
        ];

        //-Pagination Constant-----------------------------------------------------//
        let that = this;
        //shop
        const shopPagination = paginationFactory({
            page: shopPage,
            sizePerPage: shopLimit,
            lastPageText: '>>',
            firstPageText: '<<',
            nextPageText: '>',
            prePageText: '<',
            totalSize: shopCount,
            // showTotal: true,
            alwaysShowAllBtns: true,
            sizePerPageList: sizePerPageOptions,
            onPageChange: function (page, sizePerPage) {
                let skp = (page - 1) * sizePerPage;
                let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
                if (statusFlag === false) {
                    that.setState({ shopPage: page, maintenanceDisplayListArr: [], shopSkip: skp, }, () => {
                        { that.state.TargetedValShopMaintenance !== "" ? that.getDataByStatusShopORField() : that.getShopsDetail(ActiveId, filters); }
                    })

                }
            },

            onSizePerPageChange: function (sizePerPage, page) {
                if (document.getElementsByClassName('dropdown-menu show')[0].classList.contains('show')) {
                    document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
                }
                let skp = (page - 1) * sizePerPage;

                let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
                if (statusFlag === false) {
                    that.setState({ shopPage: page, shopSkip: skp, shopLimit: sizePerPage, maintenanceDisplayListArr: [] }, () => {
                        { that.state.TargetedValShopMaintenance !== "" ? that.getDataByStatusShopORField() : that.getShopsDetail(ActiveId, filters); }
                    })

                }
            }
        });

        //field
        const fieldPagination = paginationFactory({
            page: fieldPage,
            sizePerPage: fieldLimit,
            lastPageText: '>>',
            firstPageText: '<<',
            nextPageText: '>',
            prePageText: '<',
            totalSize: fieldCount,
            // showTotal: true,
            alwaysShowAllBtns: true,
            sizePerPageList: sizePerPageOptions,
            onPageChange: function (page, sizePerPage) {
                that.setState({ loader: true })
                let skp = (page - 1) * sizePerPage;
                let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
                if (statusFlag === true) {
                    that.setState({ fieldPage: page, fieldMaintenanceDisplayListArr: [], fieldSkip: skp }, () => {
                        TargetedValFieldMaintenance !== "" ? that.getDataByStatusShopORField() : that.handleClick(fieldActiveId, filters);
                    })

                }
            },
            onSizePerPageChange: function (sizePerPage, page) {
                if (document.getElementsByClassName('dropdown-menu show')[0].classList.contains('show')) {
                    document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
                }
                let skp = (page - 1) * sizePerPage;
                let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
                if (statusFlag === true) {
                    that.setState({ fieldPage: page, fieldLimit: sizePerPage, fieldMaintenanceDisplayListArr: [], fieldSkip: skp }, () => {
                        TargetedValFieldMaintenance !== "" ? that.getDataByStatusShopORField() : that.handleClick(fieldActiveId, filters);
                    })
                }
            }
        });
        //------------------------------------------------------------------------//  
        const handleOnTableChange = (type, newState) => {
            if (type === "sort") {
                if (newState.sortOrder == "asc") {
                    maintenanceDisplayListArr.sort((obj1, obj2) => {
                        return obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
                            ;
                    });
                }
                else {
                    maintenanceDisplayListArr.sort((obj2, obj1) => {
                        return obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
                            ;
                    });
                }
            }

        }

        const handleOnTableChangeField = (type, newState) => {
            if (type === "sort") {
                if (newState.sortOrder == "asc") {
                    fieldMaintenanceDisplayListArr.sort((obj1, obj2) => {
                        return obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
                            ;
                    });
                }
                else {
                    fieldMaintenanceDisplayListArr.sort((obj2, obj1) => {
                        return obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
                            ;
                    });
                }
            }
        }


        const columns = [{
            dataField: "name",
            text: "Details",
            sort: true
        }, {
            dataField: "lastDate",
            text: "Last",
            sort: true
        }, {
            dataField: "nextDate",
            text: "Next",
            sort: true
        }, {
            dataField: "status",
            text: ("Status"),
            sort: true
        }, {
            dataField: "",
            text: "Action",
            formatter: this.actionFormat,
        }];

        const rowStyle = (row, rowIndex) => {
            const style = {};
            if (row.status === 'Overdue') {
                style.backgroundColor = '#ffe9ec'
                style.border = '2px solid pink'
            } else if ((row.status === 'Pending') && (row.upcoming === true)) {
                style.backgroundColor = '#f6e7bb'
                style.border = '2px solid yellow'
            }
            return style
        };

        const denyMaintenance = (roleKey !== "" && (roleKey == "fieldmanager" || roleKey == "transport" || roleKey == "shopmanager" || roleKey == "Yard/Receiving Tech")) ? true : false;

        return (
            <>

            {!denyMaintenance ?
         <div>
         <div className="main-content shopPersonal">
             <div className="page-content">
                 <div className="container-fluid">
                     <div className="page-title-box d-flex align-items-center justify-content-between">
                         <ol className="breadcrumb m-0">
                             <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                             &nbsp; / &nbsp;
                             {statusFlag == false ?
                                 <Link to={`/maintenance`}><li className="breadcrumb-item">Shop Maintenance</li></Link>
                                 :
                                 <Link to={`/maintenance`}><li className="breadcrumb-item">Field Maintenance</li></Link>
                             }
                         </ol>
                     </div>
                     <div className="align-items-center d-flex flex-wrap justify-content-between page-title-box">
                         <h4 className="mb-0 text-left">Maintenance</h4>
                         <div className="align-items-center d-flex">
                             <div className="align-items-center d-flex me-3">
                                 <label className="me-2 mb-0">Search</label>
                                 <select name="statusSearch" className="form-control" value={statusFlag === false ? TargetedValShopMaintenance : TargetedValFieldMaintenance} onChange={(event) => this.handleonChangeFilter(event)}>
                                     {/* <option value={''}>Select status</option>
                                 <option value={'Pending'}>Pending</option>
                                 <option value={'InProgress'}>In Progress</option>
                                 <option value={'Done'}>Done</option>
                                 <option value={'Overdue'}>Overdue</option> */}
                                     <option value=''>Select</option>
                                     {this.state.itemData.length &&
                                         this.state.itemData.map((itemData) => {
                                             return (
                                                 <option value={itemData.title}>
                                                     {itemData && itemData.name}
                                                 </option>
                                             );
                                         })}
                                 </select>
                             </div>
                             <div style={isAccess === true ? { display: "block" } : { display: "none" }} >
                                 {statusFlag === false ?
                                     <>
                                         {this.state.counter == 0 ?
                                             (<button className="text-right btn btn-primary" onClick={() => { this.addItems(shopList[0].id); this.openModalonClick("add") }}>Add item</button>)
                                             :
                                             (<button className="text-right btn btn-primary" onClick={() => { this.addItems(ActiveId); this.openModalonClick("add") }}>Add item</button>)
                                         }
                                     </>
                                     :
                                     <>
                                         {this.state.fieldCounter == 0 ?
                                             (<button className="text-right btn btn-primary" onClick={() => { this.addItems(fieldList[0].id); this.openModalonClick("add") }}>Add item</button>)
                                             :
                                             (<button className="text-right btn btn-primary" onClick={() => { this.addItems(fieldActiveId); this.openModalonClick("add") }}>Add item</button>)
                                         }
                                     </>
                                 }
                             </div>
                         </div>
                     </div>
                     <div className="text-right mb-2">
                         <button className="btn btn-primary position-relative" style={{ "background-color": "#ffc0cb", "border-color": "#ff6782", "color": "#000" }} onClick={() => this.handleOverduePanel(true)}>
                             <span className={`notification-number ${overdueNotificationCountforUnread > 99 && overdueNotificationCountforUnread < 1000 ? "<alert>-three-digit" : overdueNotificationCountforUnread > 999 && overdueNotificationCountforUnread < 10000 ? "alert-four-digit" : ""}`} style={overdueNotificationCountforUnread === 0 ? { display: "none" } : { display: "block" }}>
                                 {overdueNotificationCountforUnread}
                             </span>
                             <span className="uil uil-bell action-btn"></span> Overdue alert
                         </button>
                     </div>
                     <div className="row">
                         <div className="col-12">
                             <div className="card">
                                 <div className="card-body">
                                     <div className="project-scope-block">
                                         <div className="project-navigation-block">
                                             <Tabs statusFlag={this.state.statusFlag}>
                                                 <div label="Shop">
                                                     <FlagActiveTab
                                                         flagAction={this.handleFlag}
                                                         flagData={statusFlag}
                                                         flagName={'Shop'}
                                                     />
                                                     {shopList.length === 0 && loader === true ? <Loader /> : statusFlag === false ?
                                                         shopList.length !== 0 ?
                                                             (<div className="list-group">
                                                                 {
                                                                     shopList.map((item, i) => {
                                                                         return (
                                                                             <div className="btn-group dropend">
                                                                                 {i === 0 && this.state.counter == 0 ?
                                                                                     (<span className={`list-group-item list-group-item-action dropdown-toggle active`} >
                                                                                         {item.name}
                                                                                     </span>)
                                                                                     :
                                                                                     (<span className={`list-group-item list-group-item-action dropdown-toggle ${this.checkIsActive(item.id)}`} onClick={() => { this.handleShopClick(item.id); this.getShopsDetail(item.id, this.state.shopFilterData) }} >
                                                                                         {item.name}
                                                                                     </span>)
                                                                                 }
                                                                             </div>
                                                                         )
                                                                     })
                                                                 }
                                                             </div>)
                                                             :
                                                             <p style={{ color: "red", textAlign: "center" }}>{"No Data Found"}</p>
                                                         :
                                                         <></>
                                                     }
                                                 </div>
                                                 <div label="Field">
                                                     <FlagActiveTab
                                                         flagAction={this.handleFlag}
                                                         flagData={statusFlag}
                                                         flagName={'Field'}
                                                     />
                                                     <Fieldmaintenance
                                                         flagData={statusFlag}
                                                         fieldId={(e) => this.handleFieldActiveID(e)}
                                                         fieldMaintenanceData={(e) => this.handleFieldMaintenanceData(e)}
                                                         fieldListData={this.handleFieldList}
                                                         handleFieldClick={this.handleFieldClick}
                                                         fieldCounter={this.handleFieldCounter}
                                                         fieldLoader={this.handleFieldLoader}
                                                         count={(e) => this.handleFieldRecordCount(e)}
                                                         ref={this.FieldmaintenanceElement}
                                                         getDataByStatusShopORField={this.getDataByStatusShopORField}
                                                     />
                                                 </div>
                                             </Tabs>
                                         </div>

                                         <div className="project-details-container">

                                             <div className="alert-sidebar-menu-scroll " style={isOverduePanelActive === true ? { right: "0" } : { right: "" }}>
                                                 <div className="simplebar-wrapper" style={{ margin: "0px", height: "100%", overflow: "scroll" }}>
                                                     <div id="sidebar-menu" className="mm-active">
                                                         <div className="alert-header">
                                                             <h5><span className="uil uil-bell action-btn me-2"></span>Overdue alert</h5>
                                                             <button type="button" className="btn-close" aria-label="Close" onClick={() => this.handleOverduePanel(false)}></button>
                                                         </div>
                                                         <ul className="alert-list">
                                                             {OverdueData.filter((e) => e.isRead !== true) && OverdueData.map((item) => {
                                                                 return (
                                                                     <li>
                                                                         <span>{item.isRead !== true ? <b>{item.type === 'Shop' && item.fkey_shop !== undefined ? item.fkey_shop.name + ' - ' : item.type === 'Field' && item.fkey_maintenanceFieldType !== undefined ? item.fkey_maintenanceFieldType.name + ' - ' : ''} {item.name}</b> : <>{item.type === 'Shop' && item.fkey_shop !== undefined ? item.fkey_shop.name + ' - ' : item.type === 'Field' && item.fkey_maintenanceFieldType !== undefined ? item.fkey_maintenanceFieldType.name + ' - ' : ''} {item.name}</>}</span>
                                                                         <button className="uil uil-eye text-dark action-btn" onClick={() => item.type === 'Shop' && item.fkey_shop !== undefined ? this.props.history.push({ pathname: '/maintenance/add', state: item, search: `${item.fkey_shop.name}` }) : item.type === 'Field' && item.fkey_maintenanceFieldType !== undefined ? this.props.history.push({ pathname: '/maintenance/field/add', state: item, search: `${item.fkey_maintenanceFieldType.name}` }) : ''}> View</button>
                                                                     </li>
                                                                 )
                                                             })}
                                                         </ul>
                                                     </div>
                                                 </div>
                                             </div>
                                             <div className="card card-block">
                                                 <div className="card-body">
                                                     {statusFlag === false ?
                                                         <BootstrapTable
                                                             remote={true}
                                                             keyField="id"
                                                             bordered={false}
                                                             data={(maintenanceDisplayListArr && maintenanceDisplayListArr.length > 0) ? maintenanceDisplayListArr : []}
                                                             columns={columns}
                                                             onTableChange={handleOnTableChange}
                                                             rowStyle={rowStyle}
                                                             pagination={(maintenanceDisplayListArr && maintenanceDisplayListArr.length > 0) ? shopPagination : ''}
                                                             noDataIndication={<p style={{ color: "red" }}>{'No Data Found'}</p>}
                                                         />
                                                         :
                                                         <BootstrapTable
                                                             remote={true}
                                                             keyField="id"
                                                             bordered={false}
                                                             data={(fieldMaintenanceDisplayListArr && fieldMaintenanceDisplayListArr.length > 0) ? fieldMaintenanceDisplayListArr : []}
                                                             columns={columns}
                                                             onTableChange={handleOnTableChangeField}
                                                             rowStyle={rowStyle}
                                                             pagination={(fieldMaintenanceDisplayListArr && fieldMaintenanceDisplayListArr.length > 0) ? fieldPagination : ''}
                                                             noDataIndication={<p style={{ color: "red" }}>{'No Data Found'}</p>}
                                                         />
                                                     }
                                                     <ModalPopUp
                                                         componentData={
                                                             statusFlag === false ?
                                                                 <ShopItemPopUp
                                                                     closeModal={this.handleCloseModal}
                                                                     saveShopItem={this.handleSaveShopItem}
                                                                     modalStatus={this.state.modalStatus}
                                                                     detailName={this.state.detailName}
                                                                     serviceInterval={this.state.serviceInterval}
                                                                     updateShopItem={this.handleUpdateShopItem}
                                                                 />
                                                                 :
                                                                 <FieldItemPopUp
                                                                     closeModal={this.handleCloseModal}
                                                                     saveFieldItem={this.handleSaveFieldItem}
                                                                     fieldId={fieldCounter === 0 ? (fieldList[0] !== undefined ? fieldList[0].id : fieldActiveId) : fieldActiveId}
                                                                     maintenanceFieldTypeFields={fieldCounter === 0 ? (fieldList[0] !== undefined ? fieldList[0].maintenanceFieldTypeFields : maintenanceFieldTypeFieldsArr) : maintenanceFieldTypeFieldsArr}
                                                                     modalStatus={this.state.modalStatus}
                                                                     fieldDataforUpdate={this.state.modalStatus === 'update' ? this.state.fieldDataforUpdate : ''}
                                                                     updateFieldItem={this.handleUpdateFieldItem}
                                                                 />
                                                         }
                                                         openModal={this.state.isOpenMaintenancePopUp}
                                                     />
                                                     <ConfirmBox
                                                         openModal={isShowConfirmBox}
                                                         confirmMsg="Do you still want to delete this item?"
                                                         handleConfirmModal={this.handleConfirmModal}
                                                     />
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>:<AccessDenied/>    
        }
               
            </>

        );
    }
}
const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);