import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { rules, Permission } from "../../abac.config";
import AccessDenied from "../common/AccessDenied";
import SimpleReactValidator from "simple-react-validator";
import { createNotification } from "../helpers/Notification";
import Loader from "../common/Loader";
import ConfirmBox from "../common/ConfirmBox";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination from "../project/component/Pagination/Pagination";
import BootstrapTable from "react-bootstrap-table-next";
import projectServices from "../project/services/projectServices";
import { getLoginToken, getApiUrl } from "../helpers/Util";
import axios from "axios";
import { DropDown } from "../common";

// import { connect } from 'react-redux'

class AddGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      button: "Save",
      certiId: "",
      headingText: "Add",
      loader: false,
      showSearchedNames: true,
      isShowConfirmBox: false,
      currentTab: "empName",
      isShowProjectList: "hide",
      checkAll: true,
      employeeIds: [],
      titlesList: [],
      shopDesignationList: [],
      shopDesignation: 0,
      titles: 0,
      checkedItems: {},
      isCheckedProject: false,
      isDisabledAddBtn: true,
      isCheckedProjects:{},
      titleId:0,
      shopDesignationId:0,

      groupEmployeeList: [],
      excludeGroupEmployeeList: [],
      searchEmployeeName: [],
      searchByTitleEmployeeName: [],
      searchByShopDesgEmployeeName: [],
      allHumanResourceEmployeesOrignal:[],
      search: "",
      groupId: 0,
      isDisable: false,
      //pagination constants
      pageChange: 1,
      skip: 0,
      limit: 10,
      count: 0,
    };
    this.validator = new SimpleReactValidator();
    // let empIds = [];
  }

  componentDidMount = () => {
    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    const { match } = this.props;
    this.setState(
      { loader: true, isShowProjectList: "hide", employeeIds: [] },
      () => {

        this.getLinkedEmployeeCount(filters);
        this.getAllHumanResourceEmployeesOnCheck();
        this.getGroupName();
        this.getAllHumanResourceEmployees();
        this.getTitlesDropdownData();
        this.getShopDesignationDropdownData();

        // this.getUserList();
      }
    );

    if (match.path == "/addGroup/:groupId" && match.params.groupId !== null) {
      this.setState(
        {
          groupId: Number(match.params.groupId),
          isDisable: false,
          headingText: "Edit",
          button: "Edit",
        },
        () => {
          this.getGroupDetailsById(Number(match.params.groupId));
        }
      );
    }
  };
  getGroupDetailsById = (groupId) => {
    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    projectServices
      .getLinkedEmployeesWithCount(groupId, filters)
      .then((response) => {
        this.setState({
          name: response.data.fk_humanresource.employee_name,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getLinkedEmployeeCount = (filters) => {
    const { groupId } = this.state;
    let countReqParam = `where={}`;
    projectServices
      .getLinkedEmployeeCount(groupId, countReqParam)
      .then((response) => {
        this.setState({ count: response.data.count }, () => {
          this.getAllLinkedEmployees(filters);
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          createNotification("error", "Your login session has expired .");
          this.props.history.push("/login");
        }
      });
  };

  getAllLinkedEmployees = (filters) => {
    console.log("getAllLinkedEmployees--1");
    const { groupId } = this.state;
    if (!groupId == 0) {
      projectServices
        .getLinkedEmployeesWithCount(groupId, filters)
        .then((response) => {
          console.log("response.data--", response.data);
          this.setState({
            groupEmployeeList: response.data,
            // loader: false,
          });
          this.getAllHumanResourceEmployees();
        })
        .catch((error) => {
          console.log(error);
        });
        projectServices
        .getLinkedEmployees(groupId)
        .then((response) => {
          console.log("response.data--", response.data);
          this.setState({
            excludeGroupEmployeeList: response.data,
            // loader: false,
          });
          this.getAllHumanResourceEmployees();
        })
        .catch((error) => {
          console.log(error);
        });
      this.getGroupName(groupId);
    } else {
      this.setState({ loader: false });
    }
  };

  getGroupName = (groupId) => {
    if (!groupId == 0) {
      projectServices
        .getGroupDetailsById(groupId)
        .then((response) => {
          this.setState({
            name: response.data.name,
            loader: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ loader: false });
    }
  };

  actionFormat = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <td>
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <a href="javascript:void(0)" className="text-danger">
                <i
                  className="uil uil-trash-alt font-size-18"
                  onClick={() =>
                    this.setState({ removeId: row.id, isShowConfirmBox: true })
                  }
                ></i>
              </a>
            </li>
          </ul>
        </td>
      </div>
    );
  };
  handleResetState = () => {
    console.log("handleResetState");
    this.setState({
      search: "",
      showSearchedNames: false,
      isShowProjectList: "hide",
      employeeIds: [],
      shopDesignation: 0,
      titles: 0,
    });
    
  };
  handleOnChange = (event) => {
    this.setState({ name: event.target.value });
  };
  handleSaveGroup = () => {
    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    const { name, groupId } = this.state;
    const { match } = this.props;
    if (this.validator.allValid()) {
      let exactName = name.trim();
      let payload = {
        name: exactName.trim(),
      };
      if (this.state.button == "Save") {
        projectServices
          .addGroup(payload)
          .then((response) => {
            if (response.status === 200) {
              createNotification("success", "Group name added successfully.");
              this.handleResetState();
              this.setState({
                groupId: response.data.id,
                isDisable: true,
              });
              this.props.history.push("/addGroup");
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              createNotification(
                "error",
                "Your login session has been expired!"
              );
              localStorage.clear();
              this.props.history.push("/login");
            } else {
              createNotification("error", error.response.data.error.message);
            }
          });
      } else {
        projectServices
          .editGroupName(groupId, payload)
          .then((response) => {
            if (response.status === 204) {
              createNotification("success", "Group name updated successfully.");

              this.getAllLinkedEmployees(filters);
              this.handleResetState();
              // this.setState({
              //   groupId: response.data.id,
              // });
              // this.props.history.push("/addGroup");
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              createNotification(
                "error",
                "Your login session has been expired!"
              );
              localStorage.clear();
              this.props.history.push("/login");
            } else {
              createNotification("error", error.response.data.error.message);
            }
          });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleSearchUser = (event) => {
    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    console.log("handleSearchUser",event);

    if (event.target.value !== "") {
      this.setState(
        {
          search: event.target.value,
          isShowProjectList: "show",
          showSearchedNames: true,
          pageChange: 1,
          
        },
        () => {
          this.getSearchedEmployee(filters);
        }
      );
    } else {
      this.setState({ search: "",
      currentTab:'empName',
      isShowProjectList: "hide",
      searchEmployeeName:[]
     }, () => {
        this.getLinkedEmployeeCount(filters);
      });
    }
  };
  



  handleSearchUserBySdAndTitle = (event) =>{
    const {excludeGroupEmployeeList,titleId,shopDesignationId} = this.state;
    console.log("handleSearchUserBySdAndTitle",event.target.name);
     let titleIdPayload = titleId;
     let sdIdPayload = shopDesignationId;

    if(event.target.name == 'titleSearch'){
      sdIdPayload= 0;
    } else {
      titleIdPayload=0
    }

    if (event.target.value !== "") {
    
      this.setState(
        {
          search: event.target.value,
          isShowProjectList: "show",
          showSearchedNames: true,
          pageChange: 1,
          
        },
        () => {
          let excludeIds =
    excludeGroupEmployeeList &&
    excludeGroupEmployeeList.map((data) => {
        return data.humanResourceId;
      });
    projectServices
      .getSearchedHREmployeesBySDAndTitle(event.target.value,excludeIds,titleIdPayload,sdIdPayload)
      .then((response) => {
        this.setState({
          searchEmployeeName: response.data.data,
          count: response.data.count,
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
        }
      );
    } else {
      this.setState({ search: "",
      // currentTab:'empName',
      isShowProjectList: "hide",
      searchEmployeeName:[]
     }, () => {
      if(event.target.name == 'titleSearch'){
        let event= { 
          target : { id: 'title'}
        }
        this.handleTabs(event);
      } else {
        let event= { 
          target : { id: 'shopDesignation'}
        }
        this.handleTabs(event);
      }
        
      });
    }
    
    

  }
  getSearchedEmployee = (filters) => {
    const {excludeGroupEmployeeList} = this.state;
    console.log("getSearchedEmployee", excludeGroupEmployeeList);
    let excludeIds =
    excludeGroupEmployeeList &&
    excludeGroupEmployeeList.map((data) => {
        return data.humanResourceId;
      });
    projectServices
      .getSearchedHumanResourcesEmployees(this.state.search,excludeIds)
      .then((response) => {
        this.setState({
          searchEmployeeName: response.data.data,
          count: response.data.count,
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAddEmployeeToGroup = () => {
    console.log(
      "handleAddEmployeeToGroup employeeIds--  2",
      this.state.employeeIds
    );
    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    const { groupId, groupEmployeeList, employeeIds } = this.state;
    let grpId = this.groupId;
    this.setState({ loader: true });

    console.log("employeeIds", employeeIds);
    // if(employeeIds)

    if (!groupId == 0) {
      let payload = {
        groupId: groupId,
        humanResourceIds: employeeIds,
      };
      let checkEmployee;

      //  new test
      projectServices
        .addEmployeeToGroupCheck(groupId, employeeIds)
        .then((response) => {
          if (response.status === 200) {
            this.checkEmployee = response.data.count;
            this.setState({ loader: false });
            if (true) {
              projectServices
                .addEmployeeToGroup(payload)
                .then((response) => {
                  if (response.status === 200) {
                    createNotification(
                      "success",
                      "Employee added successfully."
                    );
                    this.setState({isDisabledAddBtn:true})
                    this.handleResetState();
                    this.getLinkedEmployeeCount(filters);
                    // this.props.history.push("/addGroup");
                  }
                })
                .catch((error) => {
                  if (error.response.status === 401) {
                    createNotification(
                      "error",
                      "Your login session has been expired!"
                    );
                    localStorage.clear();
                    this.props.history.push("/login");
                  } else {
                    createNotification(
                      "error",
                      error.response.data.error.message
                    );
                  }
                });
            }
          }
        });
    } else {
      createNotification("error", "Please save group name");
      this.setState({
        loader: false,
      });
    }
  };
  getAllHumanResourceEmployeesOnCheck = () => {
    const { allHumanResourceEmployeesOrignal } = this.state;
    console.log("getAllHumanResourceEmployeesOnCheck",allHumanResourceEmployeesOrignal);
    projectServices
      .getHumanResourcesEmployeesOnCheck()
      .then((response) => {
        // console.log("getAllHumanResourceEmployeesOnCheck", response.data);
        this.setState({
          // humanResourceDisplayList: response.data,
          allHumanResourceEmployeesOrignal: response.data,
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getAllHumanResourceEmployees = () => {
    const { excludeGroupEmployeeList,} = this.state;
    let filters = "No";
    let excludeIds =
    excludeGroupEmployeeList &&
    excludeGroupEmployeeList.map((data) => {
        return data.humanResourceId;
      });
      console.log("getAllHumanResourceEmployees");
    projectServices
      .getHumanResourcesEmployees(filters, excludeIds)
      .then((response) => {
        this.setState({
          searchEmployeeName: response.data,
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleTabs = (e) => {
    
    this.getAllHumanResourceEmployees();
    const { searchEmployeeName } = this.state;
    console.log("handleTabs",searchEmployeeName);
    
    let selectedIds = searchEmployeeName && searchEmployeeName.map((data)=>{
      return data.id
    }) 
    const empIds = selectedIds.map(num => num.toString());
    this.getAllHumanResourceEmployees();
    empIds && empIds.map((id)=>{
      this.setState(prevState => ({
        isCheckedProjects: {
          ...prevState.isCheckedProjects,
          [id]: false // Update the checked status for this checkbox by its ID
        }, }));
     })
    this.setState({
      search:"",
      employeeIds: [],
      isCheckedProject: false,
      isDisabledAddBtn: true,
    });
    if (e.target.id === "empName") {
      this.setState({ currentTab: "empName", isShowProjectList: "hide" });
    } else if (e.target.id === "title") {
      this.setState({
        currentTab: "title",
        isShowProjectList: "hide",
        titles: 0,
      });
    } else if (e.target.id === "shopDesignation") {
      this.setState({
        currentTab: "shopDesignation",
        isShowProjectList: "hide",
        shopDesignation: 0,
      });
    }
  };
  handleProjectDropDown = () => {
    console.log("handleProjectDropDown", this.state.isShowProjectList);
    if (this.state.isShowProjectList === "hide") {
      this.setState({ isShowProjectList: "show" });
    } else if (this.state.isShowProjectList === "show") {
      this.setState({ isShowProjectList: "hide", employeeIds: [] });
    }
  };
  checkUncheckProject = (value) => {
    const { searchEmployeeName, checkedItems, isCheckedProject,isCheckedProjects } = this.state;
    console.log("checkUncheckProject searchEmployeeName",value, searchEmployeeName);
    
    let selectedIds = searchEmployeeName && searchEmployeeName.map((data)=>{
      return data.id
    }) 
    const empIds = selectedIds.map(num => num.toString());

    if(value){
      empIds && empIds.map((id)=>{
        this.setState(prevState => ({
          isCheckedProjects: {
            ...prevState.isCheckedProjects,
            [id]: true // Update the checked status for this checkbox by its ID
          }, }));
       })
       if(searchEmployeeName.length > 0){
        this.setState({ employeeIds:empIds,isDisabledAddBtn:false });
       }
        // this.setState({ employeeIds:empIds });
    } else {
      console.log("checkUncheckProject false",empIds);
      empIds && empIds.map((id)=>{
        this.setState(prevState => ({
          isCheckedProjects: {
            ...prevState.isCheckedProjects,
            [id]: false // Update the checked status for this checkbox by its ID
          }, }));
       })
      this.setState({ employeeIds:[],isDisabledAddBtn:true });
    }
  };
  
  uncheckAllOnDelete = () => {
    const { allHumanResourceEmployeesOrignal, } = this.state;
    console.log("uncheckAllOnDelete", allHumanResourceEmployeesOrignal);
    
    let selectedIds = allHumanResourceEmployeesOrignal && allHumanResourceEmployeesOrignal.map((data)=>{
      return data.id
    }) 
    const empIds = selectedIds.map(num => num.toString());

      console.log("checkUncheckProject false",empIds);
      empIds && empIds.map((id)=>{
        this.setState(prevState => ({
          isCheckedProjects: {
            ...prevState.isCheckedProjects,
            [id]: false // Update the checked status for this checkbox by its ID
          }, }));
       })
      this.setState({ employeeIds:[],isDisabledAddBtn:true });
    
  };

  onCheckProject = (event) => {
    console.log("onCheckProject", event.target.id);
    const { searchEmployeeName, checkedItems, isCheckedProject } = this.state;
    const isChecked = event.target.checked;
    let id = event.target.id;
    let employeeIds = [...this.state.employeeIds];
    if (id > 0) {
      this.setState(prevState => ({
        isCheckedProjects: {
          ...prevState.isCheckedProjects,
          [id]: isChecked // Update the checked status for this checkbox by its ID
        }, }));

      if (event.target.checked == true) {
        employeeIds.push(id);
        this.setState({ employeeIds });
      } else if (event.target.checked == false) {
        console.log("else false",id,employeeIds);
        const index = employeeIds.indexOf(id);
        console.log("out",index);
        if (index > -1) {
          console.log("in",index);
          // only splice array when item is found
          employeeIds.splice(index, 1); // 2nd parameter means remove one item only
          this.setState({ employeeIds });
        }
      }
      if(employeeIds.length == 0 ){
        this.setState({isDisabledAddBtn:true})
      } else {
        this.setState({isDisabledAddBtn:false})
      }

      console.log("employeeIds", employeeIds);
    }
  };

  handleConfirmModal = (isYes) => {
    let filters = `?filter[skip]=${this.state.skip}&filter[limit]=${this.state.limit}`;
    if (isYes) {
      this.setState({
        isShowConfirmBox: !this.state.isShowConfirmBox,
        loader: true,
      });
      const { removeId } = this.state;
      projectServices
        .deleteLinkedEmployee(removeId)
        .then((response) => {
          createNotification("success", "Employee deleted successfully .");
          this.setState({ pageChange: 1 });
          this.getLinkedEmployeeCount(filters);
          this.getAllHumanResourceEmployees(filters);
          // this.handleResetState();
          // this.handleTabs();
          this.uncheckAllOnDelete();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ removeId: 0, isShowConfirmBox: false, loader: false });
    }
  };
  handleCloseProjectDropDown = () => {
    this.setState({
      isShowProjectList: "hide",
      employeeIds: [],
      isCheckedProject: false,
    });
  };
  getTitlesDropdownData = () => {
    projectServices
      .getAllTitles()
      .then((response) => {
        let responseData = response.data;
        let listArr = [];
        responseData &&
          responseData.map((item) => {
            listArr.push({ lable: item.name, value: item.id });
          });
        // console.log("lstadata -- ", listArr);
        this.setState({
          titlesList: listArr,
        });

        // console.log("api call response.data.designation", response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          createNotification("error", "Your login session has expired .");
          this.props.history.push("/login");
        }
      });
  };

  handleOnTitleChange = (e) => {
    const { excludeGroupEmployeeList } = this.state;
    console.log(
      "handleOnTitleChange excludeGroupEmployeeList",
      e.target.value
    );
    let excludeIds =
    excludeGroupEmployeeList &&
    excludeGroupEmployeeList.map((data) => {
        return data.humanResourceId;
      });
    this.setState({
      titleId:e.target.value,
      titles: parseInt(e.target.value),
      isCheckedProject: false,
      isDisabledAddBtn: true,
    });
    projectServices
      .getHumanResourcesEmployeesByTitle(e.target.value, excludeIds)
      .then((response) => {
        console.log("response.data.data", response.data);
        this.setState({
          searchEmployeeName: response.data,
          count: response.data.count,
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleOnShopDesignationChange = (e) => {
    const { excludeGroupEmployeeList } = this.state;
    console.log(
      "handleOnTitleChange excludeGroupEmployeeList",
      this.state.excludeGroupEmployeeList
    );

    let excludeIds =
    excludeGroupEmployeeList &&
    excludeGroupEmployeeList.map((data) => {
        return data.humanResourceId;
      });
    this.setState({
      shopDesignationId : e.target.value,
      shopDesignation: parseInt(e.target.value),
      isCheckedProject: false,
      isDisabledAddBtn: true,
    });
    projectServices
      .getHumanResourcesEmployeesByShopDesignation( e.target.value, excludeIds)
      .then((response) => {
        console.log("response.data.data", response.data);
        this.setState({
          searchEmployeeName: response.data,
          count: response.data.count,
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getShopDesignationDropdownData = () => {
    projectServices
      .getAllShopDesignations()
      .then((response) => {
        let responseData = response.data;
        let listArr = [];
        responseData &&
          responseData.map((item) => {
            listArr.push({ lable: item.designation, value: item.id });
          });
        this.setState({
          shopDesignationList: listArr,
        });

        // console.log("api call response.data.designation", response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          createNotification("error", "Your login session has expired .");
          this.props.history.push("/login");
        }
      });
  };
  render() {
    const {
      name,
      headingText,
      loader,
      groupEmployeeList,
      pageChange,
      limit,
      count,
      search,
      showSearchedNames,
      searchEmployeeName,
      searchByTitleEmployeeName,
      searchByShopDesgEmployeeName,
      isShowConfirmBox,
      groupId,
      isDisable,
      currentTab,
      isShowProjectList,
      titlesList,
      shopDesignationList,
      shopDesignation,
      titles,
      checkedItems,
      isCheckedProject,
      isDisabledAddBtn,
      isCheckedProjects,
    } = this.state;
    let roleKey = this.props.loginUserData.roleKey;
    const isAccess =
      roleKey !== "" && rules[roleKey][Permission.MASTER_WRITE] ? true : false;

    //-Pagination Constant-----------------------------------------------------//
    let that = this;
    const pagination = paginationFactory({
      page: pageChange,
      sizePerPage: limit,
      lastPageText: ">>",
      firstPageText: "<<",
      nextPageText: ">",
      prePageText: "<",
      totalSize: count,
      // showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {
        let skp = (page - 1) * sizePerPage;
        let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
        that.setState({
          pageChange: page,
          groupEmployeeList: [],
          loader: true,
        });
        search.length > 0
          ? that.getSearchedEmployee(filters)
          : that.getLinkedEmployeeCount(filters);
        // : that.getAllLinkedEmployees(filters);
      },
      onSizePerPageChange: function (sizePerPage, page) {
        let skp = (page - 1) * limit;
        that.setState({
          pageChange: page,
          limit: sizePerPage,
          groupEmployeeList: [],
          loader: true,
        });
        let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
        search.length > 0
          ? that.getSearchedEmployee(filters)
          : that.getLinkedEmployeeCount(filters);
        // : that.getAllLinkedEmployees(filters);
      },
    });
    //------------------------------------------------------------------------//

    const handleOnTableChange = (type, newState) => {
      if (type == "sort") {
        if (newState.sortOrder === "asc") {
          groupEmployeeList.sort((obj1, obj2) => {
            return obj1.fk_humanresource.employee_name.localeCompare(
              obj2.fk_humanresource.employee_name,
              undefined,
              {
                numeric: true,
                sensitivity: "base",
              }
            );
          });
        } else {
          groupEmployeeList.sort((obj2, obj1) => {
            return obj1.fk_humanresource.employee_name.localeCompare(
              obj2.fk_humanresource.employee_name,
              undefined,
              {
                numeric: true,
                sensitivity: "base",
              }
            );
          });
        }
      }
    };

    const columns = [
      {
        dataField: "fk_humanresource.employee_name",
        text: "Name",
        sort: true,
        headerStyle: () => {
          return { verticalAlign: "top" };
        },
      },
      {
        dataField: "",
        text: "Action",
        formatter: this.actionFormat,
        headerStyle: () => {
          return { width: "30%", verticalAlign: "top" };
        },
      },
    ];
    return (
      <div>
        {loader === true ? (
          <Loader />
        ) : (
          <div className="main-content addUser-form">
            <ConfirmBox
              openModal={isShowConfirmBox}
              confirmMsg="Are you sure to delete this user?"
              handleConfirmModal={this.handleConfirmModal}
            />
            <div className="page-content">
              <div className="container-fluid">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <ol className="breadcrumb m-0">
                    <Link to={`/#`}>
                      <li className="breadcrumb-item">Home</li>
                    </Link>
                    &nbsp; / &nbsp;
                    <Link to={`/employeeContact`}>
                      <li className="breadcrumb-item">Employee Contact</li>
                    </Link>
                    &nbsp; / &nbsp;
                    {this.props.match.path == `/addGroup` ? (
                      <Link to={`/addGroup`}>
                        <li className="breadcrumb-item">{headingText}</li>
                      </Link>
                    ) : (
                      <>
                        <Link to={`/allGroupList`}>
                          <li className="breadcrumb-item">Group List</li>
                        </Link>
                        &nbsp; / &nbsp;
                        <Link
                          to={`/addGroup/${this.props.match.params.groupId}`}
                        >
                          <li className="breadcrumb-item">{headingText}</li>
                        </Link>
                      </>
                    )}
                  </ol>
                </div>
                <h4 className="mb-2">{headingText} Group </h4>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-block">
                      <div className="card-body">
                        <div className="wizard clearfix">
                          <div className="content clearfix">
                            <form className="form-project ">
                              <div className="row align-items-end">
                                <div className="col-md-3">
                                  <label
                                    className="form-label"
                                    for="validationCustom01"
                                  >
                                    Group Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    disabled={isDisable}
                                    type="text"
                                    className="form-control"
                                    id="validationCustom01"
                                    name="name"
                                    onChange={this.handleOnChange}
                                    placeholder="Group Name"
                                    value={name}
                                    style={{ maxWidth: "345px" }}
                                  />
                                  <span style={{ position: "absolute" }}>
                                    {this.validator.message(
                                      "name",
                                      this.state.name,
                                      "required|max:70",
                                      { className: "text-danger" }
                                    )}
                                  </span>
                                </div>

                                <div className="col-md-2">
                                  <div className="">
                                    <div className="actions clearfix ">
                                      <button
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light"
                                        onClick={() => this.handleSaveGroup()}
                                      >
                                        {this.state.button}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr className="mb-3 mt-4"></hr>
                              <div className="row">
                                <div className="col-md-5">
                                  <div className="mb-3">
                                    <label
                                      className="form-label"
                                      for="validationCustom01"
                                    >
                                      Add Employee By{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div className="border mb-2">
                                      <ul class="nav nav-tabs">
                                        <li class="nav-item">
                                          <a
                                            id="empName"
                                            class={
                                              currentTab == "empName"
                                                ? "nav-link active"
                                                : "nav-link"
                                            }
                                            aria-current="page"
                                            href="#"
                                            onClick={this.handleTabs}
                                          >
                                            Name
                                          </a>
                                        </li>
                                        <li class="nav-item">
                                          <a
                                            id="title"
                                            class={
                                              currentTab == "title"
                                                ? "nav-link active"
                                                : "nav-link"
                                            }
                                            href="#"
                                            onClick={this.handleTabs}
                                          >
                                            Title
                                          </a>
                                        </li>
                                        <li class="nav-item ">
                                          <a
                                            id="shopDesignation"
                                            class={
                                              currentTab == "shopDesignation"
                                                ? "nav-link active"
                                                : "nav-link"
                                            }
                                            href="#"
                                            onClick={this.handleTabs}
                                          >
                                            Shop Designation
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    {currentTab == "empName" ? (
                                      <div className="col-md-10 mt-4">
                                        <div className="mb-3 mt-3">
                                          <div className="row align-items-end">
                                            <div className="col-md-8">
                                              <div>
                                                <a
                                                  className=" calendar-drop-down-btn"
                                                  href="javascript:void(0)"
                                                  onClick={() =>
                                                    this.handleProjectDropDown()
                                                  }
                                                >
                                                  <input
                                                    disabled={
                                                      this.button == "Save"
                                                        ? !isDisable
                                                        : false
                                                    }
                                                    type="text"
                                                    className="form-control"
                                                    id="validationCustom01"
                                                    name="name"
                                                    placeholder="Search employee"
                                                    onChange={(e) =>
                                                      this.handleSearchUser(e)
                                                    }
                                                    value={this.state.search}
                                                  />
                                                </a>
                                              </div>
                                            </div>
                                            <div className="col-md-4">
                                              <div className="actions clearfix ">
                                                <button
                                                  style={{ marginLeft: "30px" }}
                                                  type="button"
                                                  className="btn btn-primary waves-effect waves-light"
                                                  onClick={() =>
                                                    this.handleAddEmployeeToGroup()
                                                  }
                                                  disabled={isDisabledAddBtn}
                                                >
                                                  Add
                                                </button>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            style={{ maxHeight: "280px" }}
                                            className={`col-md-6 checkbox-overlay collapse ${isShowProjectList}`}
                                          >
                                            <div className="checkbox-overlay-padding">
                                              <div className="page-title-box d-flex align-items-center justify-content-between">
                                                <div className="checkbox-overlay-links">
                                                  <a
                                                    href="javascript:void(0)"
                                                    onClick={() =>
                                                      this.checkUncheckProject(
                                                        true
                                                      )
                                                    }
                                                  >
                                                    Check All
                                                  </a>
                                                  <a
                                                    href="javascript:void(0)"
                                                    onClick={() =>
                                                      this.checkUncheckProject(
                                                        false
                                                      )
                                                    }
                                                    className="uncheck-all"
                                                  >
                                                    Uncheck All
                                                  </a>
                                                </div>
                                                <div className="checkbox-close">
                                                  <a
                                                    href="javascript:void(0)"
                                                    onClick={() =>
                                                      this.handleCloseProjectDropDown()
                                                    }
                                                  >
                                                    <i className="uil uil-multiply"></i>
                                                  </a>
                                                </div>
                                              </div>
                                              {searchEmployeeName.length > 0 &&
                                                searchEmployeeName.map(
                                                  (data, i) => {
                                                    return (
                                                      <div
                                                        key={i}
                                                        className="form-check"
                                                      >
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={data.id}
                                                          checked={isCheckedProjects[data.id] || false }
                                                          onChange={(e) =>
                                                            this.onCheckProject(
                                                              e
                                                            )
                                                          }
                                                        />
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="formCheck1"
                                                        >
                                                          {" "}
                                                          {
                                                            data.employee_name
                                                          }{" "}
                                                        </label>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : currentTab == "title" ? (
                                      <div>
                                        <div className="col-md-6 mt-4">
                                          <div className="project-filter-dropdown mb-3 align-items-center ">
                                            <DropDown
                                              style="max"
                                              name="titleId"
                                              firstOption={{
                                                lable: "All Titles",
                                                value: 0,
                                              }}
                                              options={titlesList}
                                              onChange={(e) =>
                                                this.handleOnTitleChange(e)
                                              }
                                              defaultSelected={
                                                this.state.titles
                                              }
                                              lable=""
                                              value={titles}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-10 mt-4">
                                          <div className="mb-3 mt-3">
                                            <div className="row align-items-end">
                                              <div className="col-md-8">
                                                <div>
                                                  <a
                                                    className=" calendar-drop-down-btn"
                                                    href="javascript:void(0)"
                                                    onClick={() =>
                                                      this.handleProjectDropDown()
                                                    }
                                                  >
                                                    <input
                                                      disabled={
                                                        this.button == "Save"
                                                          ? !isDisable
                                                          : false
                                                      }
                                                      type="text"
                                                      className="form-control"
                                                      id="validationCustom01"
                                                      name="titleSearch"
                                                      placeholder="Search employee "
                                                      onChange={(e) =>
                                                        this.handleSearchUserBySdAndTitle(e)
                                                      }
                                                      value={this.state.search}
                                                    />
                                                  </a>
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="actions clearfix ">
                                                  <button
                                                    style={{
                                                      marginLeft: "30px",
                                                    }}
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light"
                                                    onClick={() =>
                                                      this.handleAddEmployeeToGroup()
                                                    }
                                                    disabled={isDisabledAddBtn}
                                                  >
                                                    Add
                                                  </button>
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              style={{ maxHeight: "280px" }}
                                              className={`col-md-6 checkbox-overlay collapse ${isShowProjectList}`}
                                            >
                                              <div className="checkbox-overlay-padding">
                                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                                  <div className="checkbox-overlay-links">
                                                    <a
                                                      href="javascript:void(0)"
                                                      onClick={() =>
                                                        this.checkUncheckProject(
                                                          true
                                                        )
                                                      }
                                                    >
                                                      Check All
                                                    </a>
                                                    <a
                                                      href="javascript:void(0)"
                                                      onClick={() =>
                                                        this.checkUncheckProject(
                                                          false
                                                        )
                                                      }
                                                      className="uncheck-all"
                                                    >
                                                      Uncheck All
                                                    </a>
                                                  </div>
                                                  <div className="checkbox-close">
                                                    <a
                                                      href="javascript:void(0)"
                                                      onClick={() =>
                                                        this.handleCloseProjectDropDown()
                                                      }
                                                    >
                                                      <i className="uil uil-multiply"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                                {searchEmployeeName.length >
                                                  0 &&
                                                  searchEmployeeName.map(
                                                    (data, i) => {
                                                      return (
                                                        <div
                                                          key={i}
                                                          className="form-check"
                                                        >
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={data.id}
                                                            checked={isCheckedProjects[data.id] || false }
                                                            onChange={(e) =>
                                                              this.onCheckProject(
                                                                e
                                                              )
                                                            }
                                                          />
                                                          <label
                                                            className="form-check-label"
                                                            htmlFor="formCheck1"
                                                          >
                                                            {" "}
                                                            {
                                                              data.employee_name
                                                            }{" "}
                                                          </label>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="col-md-6 mt-4">
                                          <div className="project-filter-dropdown mb-3 align-items-center">
                                            <DropDown
                                              name="shopDesignationId"
                                              firstOption={{
                                                lable: "All Shop Designation",
                                                value: 0,
                                              }}
                                              options={shopDesignationList}
                                              onChange={(e) =>
                                                this.handleOnShopDesignationChange(
                                                  e
                                                )
                                              }
                                              defaultSelected={
                                                this.state.shopDesignation
                                              }
                                              lable=""
                                              value={shopDesignation}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-10 mt-4">
                                          <div className="mb-3 mt-3">
                                            <div className="row align-items-end">
                                              <div className="col-md-8">
                                                <div>
                                                  <a
                                                    className="calendar-drop-down-btn"
                                                    href="javascript:void(0)"
                                                    onClick={() =>
                                                      this.handleProjectDropDown()
                                                    }
                                                  >
                                                    <input
                                                      disabled={
                                                        this.button == "Save"
                                                          ? !isDisable
                                                          : false
                                                      }
                                                      type="text"
                                                      className="form-control"
                                                      id="validationCustom01"
                                                      name="sdSearch"
                                                      placeholder="Search employee "
                                                      onChange={(e) =>
                                                        this.handleSearchUserBySdAndTitle(e)
                                                      }
                                                      value={this.state.search}
                                                    />
                                                  </a>
                                                </div>
                                              </div>
                                              <div className="col-md-4">
                                                <div className="actions clearfix ">
                                                  <button
                                                    style={{
                                                      marginLeft: "30px",
                                                    }}
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light"
                                                    onClick={() =>
                                                      this.handleAddEmployeeToGroup()
                                                    }
                                                    disabled={isDisabledAddBtn}
                                                  >
                                                    Add
                                                  </button>
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              style={{ maxHeight: "280px" }}
                                              className={`col-md-6 checkbox-overlay collapse ${isShowProjectList}`}
                                            >
                                              <div className="checkbox-overlay-padding">
                                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                                  <div className="checkbox-overlay-links">
                                                    <a
                                                      href="javascript:void(0)"
                                                      onClick={() =>
                                                        this.checkUncheckProject(
                                                          true
                                                        )
                                                      }
                                                    >
                                                      Check All
                                                    </a>
                                                    <a
                                                      href="javascript:void(0)"
                                                      onClick={() =>
                                                        this.checkUncheckProject(
                                                          false
                                                        )
                                                      }
                                                      className="uncheck-all"
                                                    >
                                                      Uncheck All
                                                    </a>
                                                  </div>
                                                  <div className="checkbox-close">
                                                    <a
                                                      href="javascript:void(0)"
                                                      onClick={() =>
                                                        this.handleCloseProjectDropDown()
                                                      }
                                                    >
                                                      <i className="uil uil-multiply"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                                {searchEmployeeName.length >
                                                  0 &&
                                                  searchEmployeeName.map(
                                                    (data, i) => {
                                                      return (
                                                        <div
                                                          key={i}
                                                          className="form-check"
                                                        >
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={data.id}
                                                            checked={isCheckedProjects[data.id] || false }
                                                            onChange={(e) =>
                                                              this.onCheckProject(
                                                                e
                                                              )
                                                            }
                                                          />
                                                          <label
                                                            className="form-check-label"
                                                            htmlFor="formCheck1"
                                                          >
                                                            {" "}
                                                            {
                                                              data.employee_name
                                                            }{" "}
                                                          </label>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>

                          {loader === true ? (
                            <Loader />
                          ) : (
                            <div className="mt-3">
                              <div className="card card-block">
                                <div className="card-body">
                                  <div>
                                    <h4> Group Users</h4>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12 table-responsive">
                                      <div className="mt-2 ">
                                        <BootstrapTable
                                          remote={true}
                                          keyField="id"
                                          data={
                                            groupEmployeeList.length > 0
                                              ? groupEmployeeList
                                              : []
                                          }
                                          onTableChange={handleOnTableChange}
                                          columns={columns}
                                          pagination={pagination}
                                          noDataIndication={
                                            loader === true ? (
                                              <Loader />
                                            ) : (
                                              <p style={{ color: "red" }}>
                                                {"No Data Found"}
                                              </p>
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* /* model to open items in each project and make them editable */}
                              </div>
                            </div>
                          )}
                          {/* <div className="actions clearfix">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={() => this.handleSaveGroup()}
                            >
                              <i className="uil uil-edit"></i>{" "}
                              {this.state.button}
                            </button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (props) => {
  return {
    loginUserData: props.loginUserData,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);
