// IMPORT SECTION STARTS HERE

// parent component
import React, { Component } from "react";
// All API Calls handled here-------------------------------
import projectServices from "../../../services/projectServices";

// Loader for component grid before data rendering----------
import Loader from "../../../../common/Loader";

// date format----------------------------------------------
import { dateFormat } from "../../../../helpers/Util";
import { indexOf } from "lodash";

// IMPORT SECTION ENDS HERE------------------

class Fieldmaintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: 0,
            ActiveId: 0,
            fieldList: [],
            loader: false,
            fieldDetailList: [],
            filters: `?filter[skip]=0&filter[limit]=10`,
        };
    }

    componentDidMount = () => {
        const fieldMaintenancePagination = JSON.parse(localStorage.getItem('fieldMaintenancePaginationConstants'));
        if (fieldMaintenancePagination) {
            let newCount = this.state.counter + 1;
            this.props.fieldCounter(newCount);
            this.getFieldsViaFieldCondition(fieldMaintenancePagination);
        } else {
            this.props.fieldCounter(this.state.counter);
            if (this.state.counter === 0) {
                this.getFields();
            }
        }
    }

    getFields = () => {
        const { counter } = this.state;
        if (counter === 0) {
            this.setState({ loader: true });
            this.props.fieldLoader(true);
            projectServices.getFields().then((response) => {
                const responseData = response.data.data;
                if (responseData.length !== 0) {
                    let nwDataArr = [];
                    const firstFieldId = responseData[0].id;
                    this.setState({ fieldList: responseData, ActiveId: firstFieldId, loader: false });
                    this.props.fieldId(firstFieldId);
                    this.getFieldsDetail(firstFieldId, this.state.filters);
                    if(responseData !== undefined){
                        (responseData).map((item,i) => {
                            projectServices.getDynamicFieldsByFieldId(item.id).then((res) => {
                                nwDataArr[i]=(res.data.data);
                            })
                        })
                    }
                    this.props.fieldListData(nwDataArr);
                } else {
                    this.setState({ loader: false });
                    this.props.fieldLoader(false);
                    this.getFieldsDetail(this.state.ActiveId, this.state.filters);
                }
            }).catch((error) => {
                console.log(error);
            });
        } else {
            projectServices.getFields().then((response) => {
                this.setState({ fieldList: response.data.data });
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    getFieldsViaFieldCondition = (fieldMaintenancePagination) => {
        const { fieldActiveId, TargetedValFieldMaintenance, fieldSkip, fieldLimit } = fieldMaintenancePagination;
        const filters = `?filter[skip]=${fieldSkip}&filter[limit]=${fieldLimit}`;
        this.setState({ loader: true, ActiveId: fieldActiveId });
        this.props.fieldLoader(true);
        projectServices.getFields().then((response) => {
            const responseData = response.data.data;
            if (responseData.length !== 0) {
                let nwDataArr = [];
                this.setState({ fieldList: responseData, loader: false });
                this.props.fieldId(fieldActiveId);
                if (TargetedValFieldMaintenance) {
                    this.props.getDataByStatusShopORField();
                } else {
                    this.getFieldsDetail(fieldActiveId, filters);
                }
                if(responseData !== undefined){
                    (responseData).map((item,i) => {
                        projectServices.getDynamicFieldsByFieldId(item.id).then((res) => {
                            nwDataArr[i]=(res.data.data);
                        })
                    })
                }
                this.props.fieldListData(nwDataArr);
            } else {
                this.setState({ loader: false });
                this.props.fieldLoader(false);
                this.getFieldsDetail(this.state.ActiveId, this.state.filters);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    getFieldsDetail = (fieldID, filters) => {
        if (fieldID) {
            this.setState({ loader: true });
            projectServices.getFieldsDetail(fieldID, filters).then((response) => {
                const fieldDetailList = response.data.data.map((item) => ({
                    ...item,
                    lastDate: item.lastDate ? dateFormat(item.lastDate) : 'N/A',
                    nextDate: item.nextDate ? dateFormat(item.nextDate) : 'N/A',
                    status: item.status === "PENDING" ? "Pending" :
                            item.status === "INPROGRESS" ? "In Progress" :
                            item.status === "DONE" ? "Done" :
                            item.status === "OVERDUE" ? "Overdue" : 'N/A',
                }));
                this.setState({ fieldDetailList, loader: false });
                this.props.count(response.data.count);
                this.getFieldMaintenanceData(fieldDetailList);
            }).catch((error) => {
                console.log(error);
            });
        } else {
            this.setState({ fieldDetailList: [] });
            this.getFieldMaintenanceData([]);
        }
    }

    checkIsActive = (id) => {
        return (this.state.ActiveId === id) ? 'active' : '';
    }

    handleFieldClick = (id) => {
        let newCount = (this.state.counter + 1);
        this.setState({ ActiveId: id, counter: newCount });
        this.props.handleFieldClick(id);
        this.props.fieldCounter(newCount);
    }

    getFieldMaintenanceData = (fieldDetailList) => {
        this.props.fieldMaintenanceData(fieldDetailList);
    }

    render() {
        const { fieldList, loader } = this.state;
        return (
            <div>
                {fieldList.length === 0 && loader === true ? <Loader /> :
                    this.props.flagData === true ?
                        fieldList.length !== 0 ?
                            (<div className="list-group">
                                {
                                    fieldList.map((item, i) => (
                                        <div className="btn-group dropend" key={item.id}>
                                            <span
                                                className={`list-group-item list-group-item-action dropdown-toggle ${this.checkIsActive(item.id)}`}
                                                onClick={() => { this.handleFieldClick(item.id); this.getFieldsDetail(item.id, this.state.filters) }}>
                                                {item.name}
                                            </span>
                                        </div>
                                    ))
                                }
                            </div>)
                            :
                            <p style={{ color: "red", textAlign: "center" }}>{"No Data Found"}</p>
                        :
                        null
                }
            </div>
        );
    }
}

export default Fieldmaintenance;
