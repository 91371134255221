import React, { Component } from "react";
import projectServices from "../services/projectServices";
import { createNotification } from "../../helpers/Notification";
import { rules, Permission } from "../../../abac.config";
import { AllowedTo } from "react-abac";
import { Accordion, Card } from "react-bootstrap";
import Loader from "../../common/Loader";
import ConfirmBox from "../../common/ConfirmBox";
export default class ProjectDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentData: [],
      loader: false,
      isShowConfirmBox: false,
      removeFileId: "",
      removeDocId:"",
    };
  }

  handleOnAddDoc = () => {
    const { projectId } = this.props;
    this.props.props.history.push(
      `/project/project-doc-upload/${projectId}/projectdoc`
    );
  };

  componentDidMount = () => {
    this.getUploadedFile();
  };

  getUploadedFile = () => {
    const { projectId } = this.props;
    projectServices
      .getProjectDocument(projectId)
      .then((response) => {
        if (response.data.data) {
          this.setState({ documentData: response.data.data });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          createNotification("error", "Your login session has been expired!");
          localStorage.clear();
          this.props.props.history.push('/login');
        } else {
          createNotification("error", error.response.data.error.message);
        }
        console.log({ resetPasswordError: error });
      });
  };

  handleConfirmModal = (isYes, id = "") => {
    if (isYes) {
      this.setState({
        isShowConfirmBox: !this.state.isShowConfirmBox,
        loader: true,
      });
      const { selectedScope, projectId } = this.props;
      const { removeFileId,removeDocId } = this.state;
      if(removeDocId !== "" && removeFileId === ""){
        this.deleteProjectDocument(removeDocId);
      }else if(removeDocId !== "" && removeFileId !== ""){
        this.deleteSingleProjectDocument(removeDocId,removeFileId)
      }
    } else {
      this.setState({ removeFileId: "",removeDocId:"" ,loader: false, isShowConfirmBox:false });
    }
  };
  
  deleteSingleProjectDocument = (documentId, fileId) => {
    const { projectId } = this.props;
    this.setState({ loader: true });
    projectServices
      .deleteSingleProjectDocument(projectId, documentId,fileId)
      .then((response) => {
        createNotification("success", "This file is deleted successfully");
        this.setState({ loader: false, removeFileId: "", removeDocId:"" }, () => {
          this.getUploadedFile();
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          createNotification("error", "Your login session has been expired!");
          localStorage.clear();
          this.props.history.push("/login");
          this.setState({ removeFileId: "", removeDocId:"" ,loader: false });
        } else {
          createNotification("error", error.response.data.error.message);
          this.setState({ removeFileId: "", removeDocId:"" ,loader: false });
        }
        console.log({ resetPasswordError: error });
      });
  };

  render() {
    const { documentData,loader,isShowConfirmBox } = this.state;
    return (
      <div>
      {loader === true ? (
          <Loader />
        ) : (

        <div className="project-document-block">
           <ConfirmBox
              openModal={isShowConfirmBox}
              confirmMsg="Are you sure to delete this file?"
              handleConfirmModal={this.handleConfirmModal}
            />
          {!this.props.isModalOpen && (
            <div className="project-doc-row d-flex align-items-center justify-content-end">
              <AllowedTo perform={Permission.PROJECT_WRITE}>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => this.handleOnAddDoc()}
                >
                  <i className="uil uil-plus"></i> Add Project Documents
                </button>
              </AllowedTo>
            </div>
          )}
          {documentData.length > 0 &&
            documentData.map((item, i) => {
              let projectDocs = item.projectDocs;
              let docId = item.id ? item.id : "";
              return (
                <>
                  <Accordion>
                    <div key={i}>
                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey={i + 1}>
                          {item.title}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={i + 1}>
                          <Card.Body>
                           <div className="col-md-12">
                              <table className="accordWrap" style={{ width: "100%" }}>
                                {projectDocs?.length > 0 &&
                                  projectDocs.map((fileData, i) => {
                                    let filePath =
                                      fileData.upload_fkey?.filePath;
                                    let fileName =
                                      fileData.upload_fkey?.fileName;
                                    let fileId = fileData.id;
                                    return (
                                      <tr key={i}>
                                        <td>
                                          {" "}
                                          <a
                                            className=" d-inline-block"
                                            onClick={() =>
                                              window.open(filePath, "_blank")
                                            }
                                          >
                                            {fileName}
                                          </a>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          <a
                                            className="btn btn-primary waves-effect waves-light"
                                            onClick={() =>
                                              window.open(filePath, "_blank")
                                            }
                                          >
                                            View
                                          </a>
                                          <button
                                            type="button"
                                            className="btn btn-plus btn-danger waves-effect waves-light"
                                            // onClick={() =>
                                            //   this.deleteSingleProjectDocument(
                                            //     docId,
                                            //     fileId
                                            //   )
                                            // }
                                            onClick={() =>
                                              this.setState({
                                                removeDocId:docId,
                                                removeFileId:fileId,
                                                isShowConfirmBox: true,
                                              })
                                            }

                                          >
                                            Remove
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </table>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  </Accordion>
                </>
              );
            })}
        </div>
          )}
      </div>
    );
  }
}
