import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Loader from "../common/Loader";
import projectServices from "../project/services/projectServices";

const EmployeeListPopup = (props) => {
  const [popupData, setPopupData] = useState([]);
  const [loader, setLoader] = useState(true);
  //pagination constants

  const [pageChange, setPageChange] = useState(1);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const closeModal = props.closeModal;
  console.log("props - popup", props.groupId);

  useEffect(() => {
    getPopupDataCount();
  },[]);

  const getPopupDataCount = () => {
    let filters = `?filter[skip]=${skip}&filter[limit]=${limit}`;
    // let filters = `?filter[skip]=${skip}&filter[limit]=${limit}`;
    let countReqParam = `where={}`;
    projectServices
      .getLinkedEmployeeCount(props.groupId, countReqParam)
      .then((response) => {
        setCount(response.data.count)
        setLoader(false);
        getPopupData(filters);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getPopupData = (filters) => {
    // let filters = `?filter[skip]=${skip}&filter[limit]=${limit}`;
    projectServices
      .getLinkedEmployees(props.groupId, filters)
      .then((response) => {
        console.log("response.data",response.data);
        setPopupData(response.data)
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const pagination = paginationFactory({
    page: pageChange,
    sizePerPage: limit,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    totalSize: count,
    // showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      let skp = (page - 1) * sizePerPage;
      let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
      setPageChange(page);
      setPopupData([]);
      setLoader(true);
      getPopupData(filters);
    },
    onSizePerPageChange: function (sizePerPage, page) {
      let skp = (page - 1) * limit;
      setPageChange(page);
      setPopupData([]); 
      setLoader(true);
      // getPopupData();
      let filters = `?filter[skip]=${skp}&filter[limit]=${sizePerPage}`;
      setLimit(sizePerPage)

      getPopupData(filters);
    },
  });
  //------------------------------------------------------------------------//

  const handleOnTableChange = (type, newState) => {
    if (type == "sort") {
      if (newState.sortOrder === "asc") {
        popupData.sort((obj1, obj2) => {
          return obj1.fk_humanresource.employee_name
                .trim()
                .localeCompare(obj2.fk_humanresource.employee_name.trim(), undefined, {
                  numeric: true,
                  sensitivity: "base",
                });
        });
      } else {
        popupData.sort((obj2, obj1) => {
          return  obj1.fk_humanresource.employee_name
                .trim()
                .localeCompare(obj2.fk_humanresource.employee_name.trim(), undefined, {
                  numeric: true,
                  sensitivity: "base",
                });
        });
      }
    }
  };

  const columns = [
    {
      dataField: "fk_humanresource.employee_name",
      text: "Employee Name",
      sort: true,
      headerStyle: () => {
        return { verticalAlign: "top", fontWeight: "bold" };
      },
    },
  ];
  return (
    <>
      <div>
        {loader === true ? (
          <Loader />
        ) : (
          <>
          <div className="employeeListPopup" > 
            <div style={{ textAlign:'right'}}>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => closeModal()}
              ></button>
            </div>
            <div className="row">
              <div className="col-lg-12 table-responsive">
                <div className="mt-2 ">
                  <BootstrapTable
                    remote={true}
                    keyField="id"
                    data={popupData.length > 0 ? popupData : []}
                    onTableChange={handleOnTableChange}
                    columns={columns}
                    pagination={pagination}
                    noDataIndication={
                      loader === true ? (
                        <Loader />
                      ) : (
                        <p style={{ color: "red" }}>{"No Data Found"}</p>
                      )
                    }
                  />
                </div>
              </div>
            </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EmployeeListPopup;
