import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from '@fullcalendar/multimonth'
import moment from "moment";
import React, { Component } from "react";
import ModalPopUp from "../../common/ModalPopUp";
import { dbDateFormat, dbDateFormatWithTime } from "../../helpers/Util";
import projectServices from "../../project/services/projectServices";
import { setFreightDelFormData,setFreightDelError ,setCalendarName} from '../../project/redux/projectAction';
import { createNotification } from "../../helpers/Notification";
import { rules, Permission } from "../../../abac.config";
import { getManagerList } from '../../project/Helper'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Buffer } from "buffer";
import Example from "./FDPopup"
import DefaultTansportScopeItemData from "./DefaultTansportScopeItemData";
import ProjectListItemPopUp from "../../project/ProjectListItemPopUp";



class Transport extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isOpenFreightDelPopUp: false,
         isOpenDefaultScopeItem: false,
         isOpenProjectPopUp:false,
         calendarData: [],
         transportData: [],
         selectedProjectList: [],
         projId: 0,
         scopeId: 0,
         onlyProjectList: [],
         onlyProjectListOne: [],
         checkAll: true,
         isShowProjectList: "hide",
         singleFreightDeliveryData: "",
         transportId: 0,
         managerList: [],
         projectManagerData: [],
         selectedPmId: '0',
         isTransportDateSelected:false,
         isProjectManagerSelected: false,
         scope:"",
         initialState: {
            freightDate: "",
            projectName: "",
            scope: "",
            shipFrom: "",
            shipTo: "",
            receiver: "",
            deliveredBy: "",
            onsiteContact: "",
            comments: "",
            shipperDate: "",
            receiverDate: "",
            receiverName:"",
            incompleteLoadDate:"",
         }
      };
   }

   componentDidMount = () => {
      getManagerList().then((response) => {
         this.setState({ managerList: [...response] });
      });
      this.getCalenderProject();
      localStorage.removeItem('calendarName')
      localStorage.removeItem('shopMaintenancePaginationConstants');
      localStorage.removeItem('fieldMaintenancePaginationConstants')
      localStorage.removeItem('projectListConstants');
   };



   generateCalendarData = (checkAll = true) => {
      let calData = [];
      let onlyProjects = [];
      let onlyProjectListOne = [];

      const { projects } = this.state.transportData;
    
      const { selectedProjectList } = this.state;
      if (selectedProjectList.length === 0) {
         if (Object.keys(projects).length > 0) {
            let colors = ["rgb(131, 15, 255)", "rgb(94, 0, 0)", "green"];
            for (const [k, val] of Object.entries(projects)) {
               let combJobIdStr = `${val.project_fkey.jobNumber} ${val.project_fkey.title}`;
               let jobIdLen = combJobIdStr.length;
               let bgColor;
               let red = "rgb(255, 0, 0)";
               let green = "rgb(30, 130, 76)";
               let yellow = "rgb(246,190,0)";
               if ((val.receiverDate === "" || val.receiverDate === null)&&(val.incompleteLoadDate===""||val.incompleteLoadDate===null)) {
                  bgColor = red;

               }else if((val.receiverDate === "" || val.receiverDate === null)&&(val.incompleteLoadDate!==""||val.incompleteLoadDate!==null)){
                  bgColor= yellow;
               }
                else {
                  bgColor = green;
               }
               // let showString = jobIdLen >= 18 ? `${combJobIdStr.slice(0, 15)}...` : combJobIdStr;
               let showString = combJobIdStr;
               let valObj = {
                  title: showString,
                  start: moment(val.shipperDate).format("YYYY-MM-DD"),
                  end: moment(val.shipperDate).add(1, "day").format("YYYY-MM-DD"),
                  description: `Scope Item: ${val.scopeitem_fkey.title}`,
                  // url: `/project/project-view/${val.id}`,
                  backgroundColor: bgColor,
                  extendedProps: {
                     projId: val.projectId,
                     scopeId: val.scopeitem_fkey.id,
                     receiverDate: val.receiverDate,
                     incompleteLoadDate:val.incompleteLoadDate,
                     id: val.id,
                     scopeTitle:val.scopeitem_fkey.title
                  },
               };
               if (checkAll) {
                  calData.push({ ...valObj });
                  onlyProjectListOne.push({ ...val, isCheckedProject: checkAll });
               }

               // onlyProjects.push({ ...val, isCheckedProject: checkAll });
               if (onlyProjects.find((item) => item.projectId === val.projectId)) {

               } else {
                  onlyProjects.push({ ...val, isCheckedProject: checkAll });
               }
            }
         }
         this.setState({
            calendarData: [...calData],
            onlyProjectList: [...onlyProjects],
            onlyProjectListOne: [...onlyProjectListOne]
         });
      } else {
         if (Object.keys(selectedProjectList).length > 0) {
            let colors = ["rgb(131, 15, 255)", "rgb(94, 0, 0)", "green"];

            for (const [k, val] of Object.entries(projects)) {
               let combJobIdStr = `${val.project_fkey.jobNumber} ${val.project_fkey.title}`;
               let jobIdLen = combJobIdStr.length;
               // let showString = jobIdLen >= 18 ? `${combJobIdStr.slice(0, 15)}...` : combJobIdStr;
               let bgColor;
               let red = "rgb(255, 0, 0)";
               let green = "rgb(30, 130, 76)";
               let yellow = "rgb(246,190,0)";
               if ((val.receiverDate === "" || val.receiverDate === null)&&(val.incompleteLoadDate===""||val.incompleteLoadDate===null)) {
                  bgColor = red;

               }else if((val.receiverDate === "" || val.receiverDate === null)&&(val.incompleteLoadDate!==""||val.incompleteLoadDate!==null)){
                  bgColor= yellow;
               }
                else {
                  bgColor = green;
               }
               let showString = combJobIdStr;
               let valObj = {
                  title: showString,
                  start: moment(val.shipperDate).format("YYYY-MM-DD"),
                  end: moment(val.shipperDate).add(1, "day").format("YYYY-MM-DD"),
                  description: `Scope Item: ${val.scopeitem_fkey.title}`,
                  // url: `/project/project-view/${val.id}`,
                  backgroundColor: bgColor,
                  extendedProps: {
                     projId: val.projectId,
                     scopeId: val.scopeitem_fkey.id,
                     receiverDate: val.receiverDate,
                     incompleteLoadDate:val.incompleteLoadDate,
                     id: val.id,
                     scopeTitle:val.scopeitem_fkey.title
                  },
               };
               calData.push({ ...valObj });
               onlyProjectListOne.push({ ...val, isCheckedProject: checkAll });
               // onlyProjects.push({ ...val, isCheckedProject: checkAll });
               if (onlyProjects.find((item) => item.projectId === val.projectId)) {

               } else {
                  onlyProjects.push({ ...val, isCheckedProject: checkAll });
               }
            }
         }
         this.setState({
            calendarData: [...calData],
            onlyProjectList: [...onlyProjects],
            onlyProjectListOne: [...onlyProjectListOne]
         });
      }

   };

   getCalenderProject = () => {
      projectServices
         .getCalenderTransport()
         .then((response) => {
            this.setState({ transportData: response.data.data });
            if (this.state.selectedPmId === '0') {
               this.generateCalendarData(this.state.checkAll)

            } else {
               this.getProjectListByProjectManager(this.state.selectedPmId)
            }
         })
         .catch((error) => {
            if (error.response.status === 401) {
               createNotification("error", "Your login session has been expired!");
               localStorage.setItem("user-token", "");
               this.props.history.push("/login");
            } else {
               createNotification("error", error.response.data.error.message);
            }
            console.log(error);
         });
   };

   handleCloseModal = (selectInfo) => {
      this.setState({
         isOpenFreightDelPopUp: false,
      });
      this.props.setFreightDelFormData({ ...this.state.initialState });
   };


   handleCloseModalDefaultScopeItem = (selectInfo) => {
      this.setState({
         isOpenDefaultScopeItem: false,
      });
      this.props.setFreightDelFormData({ ...this.state.initialState });
      this.props.setFreightDelError({...this.props.freightDelErr,projectNameError:"",receiverDateError:"",shipperDateError:""});
   };
 handleCloseProjectPopUp=()=>{
   this.setState({isOpenProjectPopUp:false});
 }
   handleEventClick = (clickInfo) => {
      console.log("close in handleEventClick");

      const { title, start, end, extendedProps } = clickInfo.event;
      const { projId, scopeId,scopeTitle } = clickInfo.event.extendedProps;

      console.log(clickInfo.event.extendedProps,"<><><><><><><><>");

      this.setState({ projId, scopeId, isOpenFreightDelPopUp: true,scope: scopeTitle})
      // clickInfo.event.close();
   };

   handleCloseEventModal = (eventClickInfo) => {
      console.log("closee handleCloseEventModal");
      eventClickInfo.event.close();
   }
   handleProjectDrop = (eventClick) => {
      // console.log({
      //   currentStart: eventClick.event.startStr,
      //   currentEnd: eventClick.event.endStr,
      //   eventClick: eventClick.event.extendedProps,
      //   // eventClick: eventClick.event,
      // });
      // console.log(eventClick);
      let startDate = eventClick.event.startStr;
      let endDate = eventClick.event.endStr;
      let transportId = eventClick.event.extendedProps.id
      let projectId = eventClick.event.extendedProps.projId;
      let scopeId = eventClick.event.extendedProps.scopeId;
      let receiverDate = eventClick.event.extendedProps.receiverDate;
      let shipperDate = dbDateFormat(startDate);
      let payloadData = {
         shipperDate,
      };

      // 2022-10-31T18:30:00.000Z shipperdate
      //2022-11-02T18:30:00.000Z  receiverdate 

      if (receiverDate && receiverDate !== "") {
         if (Date.parse(shipperDate) > Date.parse(receiverDate)) {
            createNotification(
               "error",
               "Shipper date should be less than Load Complete date"
            );
            this.setState({ checkAll: true, selectedPmId: '0', isProjectManagerSelected: false })
            this.getCalenderProject();
            return;
         }
      }


      projectServices
         .addFreightDelivery(projectId, scopeId, payloadData)
         .then((res) => {
            // this.getCalenderProject();
            createNotification("success", "Transport record updated successfully.")
            this.getTransportRecords();
            this.getCalendarRecord(projectId, scopeId, transportId);

         })
         .catch((error) => {
            if (error.response.status === 401) {
               createNotification("error", "Your login session has been expired!");
               localStorage.setItem("user-token", "");
               this.props.history.push("/login");
            } else {
               createNotification("error", error.response.data.error.message);
            }
            console.log(error);
         });
   };
   getTransportRecords = () => {
      projectServices
         .getCalenderTransport()
         .then((response) => {
            this.setState({ transportData: response.data.data });
         })
         .catch((error) => {
            console.log(error);
         })
   }
   getCalendarRecord = (projectId, scopeId, transportId) => {
      projectServices.getSingleFreightDeliveryRecord(projectId, scopeId, transportId).then((response) => {
         this.setState({ singleFreightDeliveryData: response.data });
         this.updateProjectDropDownList();
      }).catch((error) => {
         console.log(error);
      })
      projectServices.getFreightDelivery(projectId, scopeId).then((response) => {
         this.setState({ singleFreightDeliveryData: response.data });
         this.updateProjectDropDownList();
      }).catch((error) => {
         console.log(error);
      })
   }
   updateProjectDropDownList = () => {
      const { selectedProjectList, onlyProjectList, singleFreightDeliveryData } = this.state
      let transID = singleFreightDeliveryData.id;
      let onlyProjectListClone = [...onlyProjectList];
      let projectObj =
         onlyProjectList &&
         onlyProjectList.find((data) => Number(data.id) === Number(transID));
      let projectIndex =
         onlyProjectList &&
         onlyProjectList.findIndex((data) => Number(data.id) === Number(transID));

      onlyProjectListClone[projectIndex] = {
         ...projectObj,
         receiverDate: singleFreightDeliveryData.receiverDate,
         shipperDate: singleFreightDeliveryData.shipperDate,
      }
      this.setState({
         onlyProjectList: [...onlyProjectListClone],
         selectedProjectList: [...onlyProjectListClone]
      });
   }

   eventDetail = ({ event, el }) => {
      let titleDiv = document.createElement("div");
      titleDiv.className = "fc-event-title fc-sticky";
      let descDiv = document.createElement("div");

      titleDiv.innerHTML = event.title;
      descDiv.innerHTML = event.extendedProps.description;

      let arrayOfDomNodes = [titleDiv, descDiv];
      return { domNodes: arrayOfDomNodes };
   };


   handleSaveFreightDl = (sigCanvas) => {

      let str = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      const URL = Buffer.from(str, "utf8");
      const { freightDate, projectName, scope, shipFrom, shipTo,
         receiver, deliveredBy, onsiteContact,
         comments, shipperDate, receiverDate, signature ,receiverName,incompleteLoadDate} = this.props.freightDelFormData;
        
      const { projId: projectId, scopeId: scopeItemId } = this.state;
      let recName=""
      let payLoad;
      if(receiverName===null){
         payLoad = {
            projectName, scope, shipFrom, shipTo,
            receiver, deliveredBy, onsiteContact, comments, signature,receiverName:recName
         };
      }else{
         payLoad = {
            projectName, scope, shipFrom, shipTo,
            receiver, deliveredBy, onsiteContact, comments, signature,receiverName
         };
      }
       

      if (freightDate !== "" && freightDate !== null) {
         payLoad.freightDate = dbDateFormat(freightDate);
      }
      // if (shipperDate !== "" && shipperDate !== null) {
         payLoad.shipperDate = dbDateFormat(shipperDate);
      // }

      payLoad.receiverDate = dbDateFormat(receiverDate);
      payLoad.incompleteLoadDate=dbDateFormat(incompleteLoadDate);
      
      if (URL) {
         payLoad.signature = URL;
      }
     
        if((dbDateFormat(shipperDate)>dbDateFormat(incompleteLoadDate)||dbDateFormat(shipperDate)>dbDateFormat(receiverDate)&&(incompleteLoadDate!==null ||receiverDate!==null))){
        
         createNotification("error","Shipper date cannot be greater then Incomplete load date or Load complete date .");
      
        
     } else if((dbDateFormat(incompleteLoadDate)>dbDateFormat(receiverDate))){
         createNotification("error","Incomplete load should less then Load complete date or greater then the Shipper date")
     }
     else {
      projectServices.addFreightDelivery(projectId, scopeItemId, payLoad).then((res) => {
         createNotification("success", "Freight Delivery updated successfully!");
         this.handleCloseModal();
         this.getTransportRecords();
         this.getUpdatedTrasnsportRecord(projectId, scopeItemId);
         // this.setState({checkAll:true},()=>
         // {
         //    this.getCalenderProject(this.state.checkAll);
         // })

      }).catch((error) => {
         if (error.response.status === 401) {
            createNotification("error", "Your login session has been expired!");
            localStorage.setItem("user-token", "");
            this.props.history.push("/login");
         } else {
            createNotification("error", error.response.data.error.message);
         }
         console.log(error);
      })
     }
      
   }

   handleSaveDefaultScopeFreightDl = (sigCanvas,projectId,defaultScopeItemId,projectName) => {
   
      let str = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      const URL = Buffer.from(str, "utf8");
      const { freightDate, scope, shipFrom, shipTo,
         receiver, deliveredBy, onsiteContact,
         comments, shipperDate, receiverDate, signature,defaultScopeItemName ,receiverName,incompleteLoadDate} = this.props.freightDelFormData;
      // const { projId: projectId, scopeId: scopeItemId } = this.state;
      let payLoad = {
          projectId:projectId,
          title:defaultScopeItemName,
         projectName, scope, shipFrom, shipTo,
         receiverName,
         receiver, deliveredBy, onsiteContact, comments, signature
      };

      if (freightDate !== "" && freightDate !== null) {
         payLoad.freightDate = dbDateFormat(freightDate);
      }
      // if (shipperDate !== "" && shipperDate !== null) {
         payLoad.shipperDate = dbDateFormat(shipperDate);
      // }
      payLoad.receiverDate = dbDateFormat(receiverDate);
      // if(incompleteLoadDate &&incompleteLoadDate!==null){
         payLoad.incompleteLoadDate=dbDateFormat(incompleteLoadDate);
      // }
      if (URL) {
         payLoad.signature = URL;
      }
      const{setFreightDelError} =this.props;
      let errors={};
      if(!payLoad.shipperDate){
         errors.shipperDateError="Shipper date is required";
      }
      
      // if(!payLoad.receiverDate){
      //    errors.receiverDateError="Receiver date is required";
      // }
      if(projectId ==0 ||projectId ===undefined){
         errors.projectNameError="Project name is required";
      }
      
      if(defaultScopeItemName==""||defaultScopeItemName==undefined||defaultScopeItemName.trim().length==0){
         errors.defaultScopeItemNameError="Scope Item name is required ."
      }
      if((dbDateFormat(shipperDate)>dbDateFormat(incompleteLoadDate)||dbDateFormat(shipperDate)>dbDateFormat(receiverDate)&&(incompleteLoadDate!==null ||receiverDate!==null))){
        
         createNotification("error","Shipper date cannot be greater then Incomplete load date or Load complete date .");
      
        
     } else if((dbDateFormat(incompleteLoadDate)>dbDateFormat(receiverDate))){
         createNotification("error","Incomplete load should less then Load complete date or greater then the Shipper date")
     }else if(Object.entries(errors).length !== 0){
            
			setFreightDelError(errors);
        }else{
        projectServices.addDefaultScopeTransportItem(payLoad).then((res) => {
            createNotification("success", "Default scope transport item added successfully!!");
            this.setState({selectedPmId:'0'})
            this.props.setFreightDelFormData({ ...this.state.initialState });
            this.handleCloseModalDefaultScopeItem();
            this.getCalenderProject()
   
         }).catch((error) => {
            if (error.response.status === 401) {
               createNotification("error", "Your login session has been expired!");
               localStorage.setItem("user-token", "");
               this.props.history.push("/login");
            } else {
               createNotification("error", error.response.data.error.message);
            }
            this.props.setFreightDelFormData({ ...this.state.initialState });
            console.log(error);
         })

        }

    
   }
   getUpdatedTrasnsportRecord = (projectId, scopeItemId) => {
      projectServices.getFreightDelivery(projectId, scopeItemId).then((response) => {
         this.setState({ transportId: response.data[0].id }, () => {
            this.updateCalendarRecord(response.data[0])
         })

      }).catch((error) => {
         console.log(error);
      })
   }
   updateCalendarRecord = (freightDeliveryRecord) => {
      const { onlyProjectList, onlyProjectListOne,calendarData, transportData, transportId } = this.state;
      let onlyProjectListClone = [...onlyProjectListOne];
   
      let projectObj =
         onlyProjectListOne &&
         onlyProjectListOne.find((data) => Number(data.id) === Number(transportId));
      // console.log(projectObj,"???????");
      let projectIndex =
         onlyProjectListOne &&
         onlyProjectListOne.findIndex((data) => Number(data.id) === Number(transportId));

      onlyProjectListClone[projectIndex] = {
         ...projectObj,
         receiverDate: freightDeliveryRecord.receiverDate,
         shipperDate: freightDeliveryRecord.shipperDate,
      }
      // this.setState({
      //    onlyProjectList: [...onlyProjectListClone],
      //    selectedProjectList: [...onlyProjectListClone]
      // });
      let calendarDataClone = [...calendarData];
      let Index =
         calendarData &&
         calendarData.findIndex(
            (data) => Number(data.extendedProps.id) === Number(transportId)
         );
      calendarDataClone.splice(Index, 1);
      let combJobIdStr = `${projectObj.project_fkey.jobNumber} ${projectObj.project_fkey.title}`;
      let jobIdLen = combJobIdStr.length;
      let showString = combJobIdStr;

      let k = Math.floor(Math.random() * 10);
      //let colors = ['rgb(131, 15, 255)', 'rgb(94, 0, 0)', 'green'];
      let bgColor;
      let red = "rgb(255, 0, 0)";
      let green = "rgb(30, 130, 76)";
      let yellow = "rgb(246,190,0)";
      if ((freightDeliveryRecord.receiverDate === "" || freightDeliveryRecord.receiverDate === null)&&(freightDeliveryRecord.incompleteLoadDate===""||freightDeliveryRecord.incompleteLoadDate===null)) {
         bgColor = red;

      }else if((freightDeliveryRecord.receiverDate === "" || freightDeliveryRecord.receiverDate === null)&&(freightDeliveryRecord.incompleteLoadDate!==""||freightDeliveryRecord.incompleteLoadDate!==null)){
         bgColor= yellow;
      }
       else {
         bgColor = green;
      }
      // if (freightDeliveryRecord.receiverDate === "" || freightDeliveryRecord.receiverDate === null) {
      //    bgColor = red;

      // } else {
      //    bgColor = green;
      // }
      let valObj = {
         title: showString,
         start: moment(freightDeliveryRecord.shipperDate).format("YYYY-MM-DD"),
         end: moment(freightDeliveryRecord.shipperDate).add(1, "day").format("YYYY-MM-DD"),
         description: `Scope Item: ${projectObj.scopeitem_fkey.title}`,
         backgroundColor: bgColor,
         extendedProps: {
            id: projectObj.id,
            projId: projectObj.projectId,
            scopeId: projectObj.scopeitem_fkey.id,
            receiverDate: freightDeliveryRecord.receiverDate,
            incompleteLoadDate:projectObj.incompleteLoadDate
         },
      };
      this.setState({ calendarData: [...calendarDataClone, valObj] });
   }

   handleProjectDropDown = () => {
      if (this.state.isShowProjectList === 'hide') {
         this.setState({ isShowProjectList: 'show' })
      } else if (this.state.isShowProjectList === 'show') {
         this.setState({ isShowProjectList: 'hide' })
      }
   }
   checkUncheckProject = (value) => {
      this.setState({
         checkAll: value,
         selectedProjectList: []
      }, () => this.getCalenderProject())
   }

   onCheckProject = (event) => {
      let projectId = event.target.id;
      if (projectId > 0) {
         this.updateCheckList(projectId, event.target.checked);
      }
   };

   updateCheckList = (id, isChecked) => {
      const { onlyProjectList, calendarData, transportData } = this.state;

      let onlyProjectListClone = [...onlyProjectList];
      let projectObj =
         onlyProjectList &&
         onlyProjectList.find((data) => Number(data.projectId) === Number(id));

      let projectIndex =
         onlyProjectList &&
         onlyProjectList.findIndex((data) => Number(data.projectId) === Number(id));

      onlyProjectListClone[projectIndex] = {
         ...projectObj,
         isCheckedProject: isChecked,
      };
      this.setState({
         onlyProjectList: [...onlyProjectListClone],
         selectedProjectList: [...onlyProjectListClone]
      });
      if (isChecked) {
         let { projects } = transportData;
         let calendarDataClone = [];
         for (const [k, val] of Object.entries(projects)) {
            if (val.projectId === Number(id)) {
               let combJobIdStr = `${val.project_fkey.jobNumber} ${val.project_fkey.title}`;
               let jobIdLen = combJobIdStr.length;
               let showString = combJobIdStr;

               let bgColor;
               let red = "rgb(255, 0, 0)";
               let green = "rgb(30, 130, 76)";
               let yellow = "rgb(246,190,0)";
               let colors = ['rgb(131, 15, 255)', 'rgb(94, 0, 0)', 'green'];
               if ((val.receiverDate === "" || val.receiverDate === null)&&(val.incompleteLoadDate===""||val.incompleteLoadDate===null)) {
                  bgColor = red;

               }else if((val.receiverDate === "" || val.receiverDate === null)&&(val.incompleteLoadDate!==""||val.incompleteLoadDate!==null)){
                  bgColor= yellow;
               }
                else {
                  bgColor = green;
               }
               let valObj = {
                  title: showString,
                  start: moment(val.shipperDate).format("YYYY-MM-DD"),
                  end: moment(val.shipperDate).add(1, "day").format("YYYY-MM-DD"),
                  description: `Scope Item: ${val.scopeitem_fkey.title}`,
                  backgroundColor: bgColor,
                  extendedProps: {
                     projId: val.projectId,
                     scopeId: val.scopeitem_fkey.id,
                     receiverDate: val.receiverDate,
                     incompleteLoadDate:val.incompleteLoadDate,
                     id: val.id,
                     scopeTitle:val.scopeitem_fkey.title
                  },
               };
               calendarDataClone.push(valObj);
               // calendarDataClone.push(valObj)
            }
         }

         this.setState((prevState) => ({
            calendarData: [...prevState.calendarData, ...calendarDataClone],
         }));
         // this.setState({ calendarData: [...calendarData, valObj] });
      } else {
         let calendarDataClone = [...calendarData];
         // console.log(calendarDataClone);
         for (let k = calendarDataClone.length - 1; k >= 0; k--) {
            const val = calendarDataClone[k];
            if (val.extendedProps.projId === Number(id)) {
               calendarDataClone.splice(k, 1);
            }
         }
         this.setState({ calendarData: [...calendarDataClone] });

         // let calendarDataClone = [...calendarData];
         // let projectIndex =
         //    calendarData &&
         //    calendarData.findIndex(
         //       (data) => Number(data.extendedProps.id) === Number(id)
         //    );

         // calendarDataClone.splice(projectIndex, 1);
         // this.setState({ calendarData: [...calendarDataClone] });
      }
   };
   handleCloseProjectDropDown = () => {
      this.setState({ isShowProjectList: 'hide' })
   }

   //event handled to change the project manager--------------
   handleProjectManagerOnChange = (e) => {
      if (e.target.value !== '0') {
         this.setState({ selectedPmId: e.target.value, isProjectManagerSelected: true, checkAll: true, ActiveId: 0 }, () => {
            this.getCalenderProject(0);
            //this.getProjectListByProjectManager(e.target.value);
         });
      } else {
         this.setState({ isProjectManagerSelected: false, selectedPmId: e.target.value, checkAll: true, ActiveId: 0 }, () =>
            this.getCalenderProject(0)
         )
      }

   }
   //api call to get the project manager list and their associated projects----------

   getProjectListByProjectManager = (projectManagerId) => {
      const { selectedPmId } = this.state;
      projectServices.getProjectByProjectManagerId(projectManagerId).then((response) => {
         this.setState({ projectManagerData: response.data, calendarData: [] });
         this.updateCalendarByProjectManager(response.data, this.state.checkAll);
      }).catch((error) => {
         console.log(error);
      })
   }
   //updating records on the calendar based on the selection of project manager-----
   updateCalendarByProjectManager = (pmData, checkAll = true) => {
      const { projectManagerData, onlyProjectList } = this.state;
      let calData = [];
      let onlyProjects = [];
      let onlyProjectListOne = [];
      const { projects } = this.state.transportData;
      if (Object.keys(projects).length > 0) {
         let colors = ["rgb(131, 15, 255)", "rgb(94, 0, 0)", "green"];
         for (const [k, val] of Object.entries(projects)) {
            let combJobIdStr = `${val.project_fkey.jobNumber} ${val.project_fkey.title}`;
            let pmListData = pmData.filter(item => item.id === val.projectId)
            if (pmListData.length > 0) {
               let jobIdLen = combJobIdStr.length;
               let bgColor;
               let red = "rgb(255, 0, 0)";
               let green = "rgb(30, 130, 76)";
               let yellow = "rgb(246,190,0)";
               if ((val.receiverDate === "" || val.receiverDate === null)&&(val.incompleteLoadDate===""||val.incompleteLoadDate===null)) {
                  bgColor = red;

               }else if((val.receiverDate === "" || val.receiverDate === null)&&(val.incompleteLoadDate!==""||val.incompleteLoadDate!==null)){
                  bgColor= yellow;
               }
                else {
                  bgColor = green;
               }
               // let showString = jobIdLen >= 18 ? `${combJobIdStr.slice(0, 15)}...` : combJobIdStr;
               let showString = combJobIdStr;
               let valObj = {
                  title: showString,
                  start: moment(val.shipperDate).format("YYYY-MM-DD"),
                  end: moment(val.shipperDate).add(1, "day").format("YYYY-MM-DD"),
                  description: `Scope Item: ${val.scopeitem_fkey.title}`,
                  // url: `/project/project-view/${val.id}`,
                  backgroundColor: bgColor,
                  extendedProps: {
                     projId: val.projectId,
                     scopeId: val.scopeitem_fkey.id,
                     receiverDate: val.receiverDate,
                     incompleteLoadDate:val.incompleteLoadDate,
                     id: val.id,
                     scopeTitle:val.scopeitem_fkey.title
                  },
               };
               if (checkAll) {
                  calData.push({ ...valObj });
                  onlyProjectListOne.push({ ...val, isCheckedProject: checkAll });
               }
               if (onlyProjects.find((item) => item.projectId === val.projectId)) {

               } else {
                  onlyProjects.push({ ...val, isCheckedProject: checkAll });
               }
               // onlyProjects.push({ ...val, isCheckedProject: checkAll });
            }

         }
      }
      this.setState({
         calendarData: [...calData],
         onlyProjectList: [...onlyProjects],
         onlyProjectListOne: [...onlyProjectListOne]
      });
   }
   handleOpenDefaultTransportItemData=()=>{
      this.setState({
         isOpenDefaultScopeItem:true
      })
   }

   handleDateSelect = (selectInfo) => {
     
      let roleKey = this.props?.loginUserData?.roleKey;
      const isAccessable =
		roleKey !== "" &&

			rules[roleKey][Permission.PROJECT_WRITE]
			? true
			: false;
		if(isAccessable){
         localStorage.setItem("calendarName","transportcalendar")
       this.props.setCalendarName('transportcalendar');
      // this.setState({isOpenProjectPopUp:true,isTransportDateSelected:true});
      this.props.history.push(
         '/dropDownPage'
      );
      }
      
   };
   render() {
      if (this.state.isOpenFreightDelPopUp) {
         document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "unset";
      }
      let roleKey = this.props.loginUserData.roleKey;
      const isDisable =
         roleKey !== "" &&
            rules[roleKey][Permission.TRANSPORT_WRITE]
            ? ""
            : "disabled";
       const isAddDefaultScopeItem =   roleKey !== "" &&
       rules[roleKey][Permission.TRANSPORTDEFAULTSCOPE_WRITE]
       ? ""
       : "disabled";  
      const { projId, scopeId, onlyProjectList, isShowProjectList, managerList, calendarData } = this.state;
      return (
         <div>
            <div className="main-content">
               <div className="page-content">
                  <div className="container-fluid">
                     <div className="page-title-box d-flex align-items-center justify-content-between">
                        <ol className="breadcrumb m-0">
                           <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                           &nbsp; / &nbsp;
                           <Link to={`/transport`}><li className="breadcrumb-item">Transport</li></Link>
                        </ol>
                     </div>
                     <h4 className="mb-0">Transport</h4>
                     <div className="row">
                        <div className="col-md-3">
                           <div className="mb-3 mt-3">
                              <select className="form-select  calendar-drop-down-btn"
                                 onChange={(e) => this.handleProjectManagerOnChange(e)}
                                 value={this.state.selectedPmId}
                                 name="Projectmanager">
                                 <option value="0">Select Project Manager</option>
                                 {managerList.length && managerList.map((itemData) => {
                                    return (
                                       <option value={itemData.value}>
                                          {itemData.lable}
                                       </option>
                                    )
                                 })}
                              </select>
                           </div>
                        </div>
                        <div className="col-md-3">
                           <div className="mb-3 mt-3">
                              <a
                                 className="form-select  calendar-drop-down-btn"
                                 href="javascript:void(0)"
                                 onClick={() => this.handleProjectDropDown()}
                              >
                                 Project
                              </a>
                              <div
                                 className={`checkbox-overlay collapse ${isShowProjectList}`}
                              >
                                 <div className="checkbox-overlay-padding">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                       <div className="checkbox-overlay-links">
                                          <a
                                             href="javascript:void(0)"
                                             onClick={() => this.checkUncheckProject(true)}
                                          >
                                             Check All
                                          </a>
                                          <a
                                             href="javascript:void(0)"
                                             onClick={() => this.checkUncheckProject(false)}
                                             className="uncheck-all"
                                          >
                                             Uncheck All
                                          </a>
                                       </div>
                                       <div className="checkbox-close">
                                          <a
                                             href="javascript:void(0)"
                                             onClick={() => this.handleCloseProjectDropDown()}
                                          >
                                             <i className="uil uil-multiply"></i>
                                          </a>
                                       </div>
                                    </div>
                                    {this.state.onlyProjectList.length > 0 &&
                                       this.state.onlyProjectList.map((data, i) => {

                                          return (
                                             <div key={i} className="form-check">
                                                <input
                                                   className="form-check-input"
                                                   type="checkbox"
                                                   id={data.projectId}
                                                   checked={data.isCheckedProject}
                                                   onChange={(e) =>
                                                      this.onCheckProject(e)
                                                   }
                                                />
                                                <label
                                                   className="form-check-label"
                                                   htmlFor="formCheck1"
                                                >
                                                   Job # -{" "}
                                                   {`${data.project_fkey.jobNumber} ${data.project_fkey.title}`}
                                                </label>
                                             </div>
                                          );
                                       })}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div className="mb-3 mt-3">
                           <button className="add-inventory btn btn-primary" disabled={isAddDefaultScopeItem} onClick={this.handleOpenDefaultTransportItemData}>
										Add Default Scope Record
									</button>
                           </div>
                        </div>
                        <div className="col-12">
                           <div className="card card-block">

                              <div className="card-body">
                                 <ModalPopUp
                                    componentData={
                                       // <FreightDeliveryPopUp
                                       //    closeModal={this.handleCloseModal}
                                       //    saveFreightDelivery={this.handleSaveFreightDl}
                                       //    isDisable={isDisable}
                                       //    projectId={projId}
                                       //    selectedScope={scopeId}
                                       // />
                                       <Example
                                          closeModal={this.handleCloseModal}
                                          saveFreightDelivery={this.handleSaveFreightDl}
                                          isDisable={isDisable}
                                          projectId={projId}
                                          selectedScope={scopeId}
                                          scopeName={this.state.scope}
                                       />
                                    }
                                    openModal={this.state.isOpenFreightDelPopUp}
                                 />
                                 <ModalPopUp
                                    componentData={
                                       <DefaultTansportScopeItemData
                                          projectId={projId}
                                          selectedScope={scopeId}
                                          closeModal={this.handleCloseModalDefaultScopeItem}
                                          handleSaveDefaultScopeFreightDl={this.handleSaveDefaultScopeFreightDl}
                                       />
                                    }

                                    openModal={this.state.isOpenDefaultScopeItem}
                                 />
                                 {/* <ModalPopUp
                                  componentData={
                                    <ProjectListingPop
                                    handleCloseProjectPopUp={this.handleCloseProjectPopUp}
                                    isTransportDateSelected={this.state.isTransportDateSelected}
                                    />
                                  }
                                  openModal={this.state.isOpenProjectPopUp}
                                 /> */}
                                 <FullCalendar
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, multiMonthPlugin]}
                                    initialView={"dayGridMonth"}
                                    headerToolbar={{
                                       left: "prev,next today",
                                       center: "title",
                                       right: "dayGridDay,dayGridWeek,dayGridMonth,multiMonthYear",
                                    }}
                                    height="auto"
                                    select={this.handleDateSelect}
                                    navLinks={true} // can click day/week names to navigate views
                                    editable={
                                       roleKey !== "" && rules[roleKey][Permission.TRANSPORT_WRITE]
                                          ? true
                                          : false
                                    }
                                    closeModal={this.handleCloseEventModal}
                                    dayMaxEvents={true}
                                    selectable={true}
                                    selectMirror={true}
                                    nowIndicator={true}
                                    events={this.state.calendarData}
                                    eventClick={this.handleEventClick}
                                    eventContent={this.eventDetail}
                                    //eventResize={this.handleProjectDrop}
                                    eventDrop={this.handleProjectDrop}
                                    eventDurationEditable={false}
                                    longPressDelay={this.handleDateSelect}
                                    buttonText={{
                                       today: "Today",
                                       month: "Month",
                                       week: "Week",
                                       day: "Day",
                                       year: "Year",
                                    }}

                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = (props) => {
   return {
      freightDelFormData: props.freightDelFormData,
      loginUserData: props.loginUserData,
      freightDelErr:props.freightDelErrs,
      calendarName:props.calendarName,
   };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
   setFreightDelFormData: setFreightDelFormData,
   setFreightDelError   : setFreightDelError,
   setCalendarName      : setCalendarName
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Transport);
