import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { rules, Permission } from '../../../abac.config';
import { bindActionCreators } from 'redux'
import projectServices from '../../project/services/projectServices';
import { setSelectedScopeItemID, setProjectData } from '../../project/redux/projectAction';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom";

class ScopeItemListComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scopeItemList: [],
      scopeItemId: 0,
    }
  }

  componentDidMount = () => {
    this.getScopeItemsById();
  }

  getScopeItemsById = () => {
    const { projectId, projectData, setProjectData } = this.props;

    projectServices.getProjectById(projectId).then((response) => {
      console.log(response);
      setProjectData(response.data);
      this.setState({ scopeItemList: response.data.scopeItemData })
    }).catch((error) => {
      console.log(error);
    })
    // projectServices.getScopeItemsByProjectId(projectId).then((response) => {
    //   this.setState({ scopeItemList: response.data })
    // })
    //   .catch((error) => {
    //     console.log(error);
    //   })
  }

  actionFormat = (cell, row, rowIndex, formatExtraData) => {
    // const data = { key: "group" };

    // const{isTransportDateSelected} =this.props
    if (localStorage.getItem('calendarName') == "transportcalendar") {
      return (

        <div>
          {

            <td>
              <ul className="list-inline mb-0">

                <OverlayTrigger
                  key="topEdit"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>View Freight Delivery </Tooltip>
                  }
                >

                  <li className="list-inline-item">

                    <Link to={`/project/freight-delivery/${this.props.projectId}`}>
                      <a href="javascript:void(0)" > <i className="uil uil-eye text-warning action-btn font-size-18"
                        onClick={() => { this.props.setSelectedScopeItemID(row.id) }}
                      >
                      </i></a>
                    </Link>
                  </li>


                </OverlayTrigger>




              </ul>
            </td>
          }

        </div>
      );
    } else if (localStorage.getItem('calendarName') == 'shopcalendar') {
      return (

        <div>
          {

            <td>
              <ul className="list-inline mb-0">

                <OverlayTrigger
                  key="topEdit"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>View Shop Fabrication</Tooltip>
                  }
                >


                  <li className="list-inline-item">

                    <Link to={`/project/shop-fabrication/${this.props.projectId}`}>
                      <a href="javascript:void(0)" >  <i className="uil uil-eye text-warning action-btn font-size-18"
                        onClick={() => { this.props.setSelectedScopeItemID(row.id) }}
                      >
                      </i></a>
                    </Link>
                  </li>


                </OverlayTrigger>




              </ul>
            </td>
          }

        </div>
      );
    } else if (localStorage.getItem('calendarName') == 'fieldcrewcalendar') {
      return (

        <div>
          {

            <td>
              <ul className="list-inline mb-0">

                <OverlayTrigger
                  key="topEdit"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>View Field Install</Tooltip>
                  }
                >


                  <li className="list-inline-item">

                    <Link to={`/project/field-install/${this.props.projectId}`}>
                      <a href="javascript:void(0)" > <i className="uil uil-eye text-warning action-btn font-size-18"
                        onClick={() => { this.props.setSelectedScopeItemID(row.id) }}
                      >
                      </i></a>
                    </Link>
                  </li>


                </OverlayTrigger>




              </ul>
            </td>
          }

        </div>
      );
    }

  };





  render() {
    const { scopeItemList } = this.state;
    const handleOnTableChange = (type, newState) => {

      if (type == 'sort') {
        if (newState.sortOrder == "asc") {
          scopeItemList.sort((obj1, obj2) => {
            return obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
              ;
          });
        }
        else {
          scopeItemList.sort((obj2, obj1) => {
            return obj1[newState.sortField].trim().localeCompare(obj2[newState.sortField].trim(), undefined, { numeric: true, sensitivity: 'base' })
              ;
          });
        }

      }

    }
    const columns = [
      {
        dataField: "title",
        text: "Scope Item Name",
        sort: true,
        headerStyle: () => {
          return { verticalAlign: "top" ,fontSize:20};
        },

      },

      {
        dataField: "",
        text: "Action",
        formatter: this.actionFormat,
        headerStyle: () => {
          return { width: "50%", verticalAlign: "top" ,fontSize:20};
        },
      },
    ];


    let roleKey = this.props.loginUserData.roleKey;
    const isAccess = (roleKey !== "" && rules[roleKey][Permission.MASTER_WRITE]) ? true : false;
    return (
      <div>
           {/* <div className="container"> */}
        {/* <div className=""> */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-block">
              <div className="card-body">
                <div className="row mb-2">


                  <div className="col-md-12">
                    <div className="form-inline  mb-1">
                      <div className="search-box ms-2 d-flex justify-content-between">
                        <h2 style={{flex: '1', textAlign: 'center'}}>Scope Item List </h2>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => { this.props.handleCloseModal() }}
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='scopelisttable'>
                  <BootstrapTable

                    keyField="id"
                    data={this.state.scopeItemList}
                    columns={columns}
                    onTableChange={handleOnTableChange}
                    pagination={paginationFactory({ sizePerPage: 10 })}
                  />
                </div>

              </div>
            </div>
          </div>

        </div>

        {/* </div> */}


      {/* </div > */}
       </div>
   

    )
  }
}
const mapStateToProps = (props) => {
  return {

    loginUserData: props.loginUserData,
    selectedScopeItemID: props.selectedScopeItemID,
    projectData: props.projectData,
    calendarName: props.calendarName,
  }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
  setSelectedScopeItemID: setSelectedScopeItemID,
  setProjectData: setProjectData,
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ScopeItemListComp)