import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function Loader() {
    const style = { background: 'rgba(255,255,255,0.8)', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 500 };
    const spinnerStyle = { position: 'absolute', left: '50%', top: '50%', marginTop: '-12px', marginLeft: '-12px' }
    return (
        <div style={style}>
            <Spinner animation="border" style={spinnerStyle} variant="primary" /> 
        </div>
    );
}