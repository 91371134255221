import React, { Component } from 'react'
//import Container from './Container';
import Header from './header';
import LeftBar from './leftbar';
import Footer from './footer';

class index extends Component {
    constructor(props){
        super(props);
        this.state = {
           
        }
        
    }
    render() {
      
        return (
            <div>
                <Header />
                <LeftBar />
                {this.props.children}
                <Footer />
            </div>
        )
    }
}

export default index
