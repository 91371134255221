import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import AccessDenied from "../common/AccessDenied";
import { AllowedTo } from "react-abac";
import { DropDown } from "../common";
import { createNotification } from "../helpers/Notification";
import { Permission } from "../../abac.config";
import { rules } from "../../abac.config";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ConfirmBox from "../common/ConfirmBox";
import masterDataService from "../services/MasterDataService";
import { add } from "lodash";
import { Link } from "react-router-dom";
class AddFieldMaintenance extends Component{
   constructor(props){
       super(props);
       this.state={
        name:"",
        status: "Active",
        loader: false,
        FieldMaintenanceId: null,
        fields:[],
        headingText:"Add Field Maintenance Item",
        button: "Save",
        isShowConfirmBox:false,
        addItemVal:"" ,
        emptyItemFlag:false,
        lengthLimitFlag:false,
        count:0,
       }
    this.validator= new SimpleReactValidator();
   }
   componentDidMount=()=>{
    const{match}=this.props;
    if(match.path === "/FieldMaintenance/:FieldMaintenanceId" && match.params.FieldMaintenanceId !==null){
      this.setState({
        FieldMaintenanceId:match.params.FieldMaintenanceId,
        headingText:"Edit",
        button:"Update",
      });
      this.getFieldMaintenaceById(match.params.FieldMaintenanceId);
    }
    }
   
   onStatusChange=(e)=>{
    const{match}=this.props;
    if(this.state.status ==="Active" && match.path !=="/AddFieldMaintenance"){
       this.setState({ isShowConfirmBox: true })
    }else{
       this.setState({ status: e.target.value });
     } 
}
getFieldMaintenaceById=(FieldMaintenanceId)=>{
  const {fields}=this.state;
    if(FieldMaintenanceId !== null){
      masterDataService.getFieldMaintenanceItemById(FieldMaintenanceId)
        .then((response)=>{
          if(response.status){
         let resData  =response.data.data;
         const fieldsx=resData.maintenanceFieldTypeFields;
         let fieldItems = []
         for(let sc of fieldsx){
             let sItem = {
                 id : sc.id,
                name: sc.name
             }
             fieldItems.push(sItem);
         }
           this.setState({
             name:resData.name,
             status:resData.status == "ACTIVE"?"Active":"Inactive",
             fields:fieldItems
           });
          }
        })
        .catch((error)=>{
      if(error.response.status === 401){
       createNotification("error", "Your login session has been expired!");
       localStorage.setItem("user-token","");
       this.props.history.push("/login");
      }else{
        createNotification("error", error.response.data.error.message);
      }
  })
 }
}
   handleOnChange =(event)=>{
    if(event.target.name === "name"){
       this.setState({ name: event.target.value });
    }
    else if (event.target.name === "status") {
       this.setState({ status: event.target.value });
     }else if(event.target.name ==="addItem"){
       this.setState({addItemVal:event.target.value,lengthLimitFlag:false});
      }
   }

   handleConfirmModal = (isYes) => {
    if(isYes){
      this.setState({ status: "Inactive",isShowConfirmBox: !this.state.isShowConfirmBox  });
    }else{
      this.setState({ status: "Active",isShowConfirmBox: !this.state.isShowConfirmBox  });
    }
  }

  addMoreItem=()=>{
    const{addItemVal,fields}=this.state;
      if(!this.containsDuplicate()){
        if(addItemVal.trim() !==""){
          if(addItemVal.length<=200){
            let  itemsClone=[...fields];
            let newItemArr = [...itemsClone, { name:addItemVal.trim()}];
            this.setState({ fields: newItemArr,addItemVal:""});
          }else{
         this.setState({lengthLimitFlag:true});
          }
         }
      }else{
        createNotification("error","Item already exists in the list.");
      }
   }
   containsDuplicate = () => {
    const { fields, addItemVal } = this.state;
    //console.log(addRackItemVal);
    let itemsClone = [...fields];
    for (let x of itemsClone) {
       if (x.name === addItemVal.trim()) {
          return (true);
       }
    }
    return (false);
 }

   removeItem = (rowIndex) => {
    const { fields } = this.state;
    let itemArr = [...fields];
    itemArr.splice(rowIndex, 1);
    this.setState({ fields: [...itemArr]});
}
changeItem=(event)=>{
  let rowIndex = event.target.id;
  let val=0;
        let itemId = event.target.getAttribute('dataid');
        console.log(itemId);
        const { fields } = this.state;
        let itemArr = [...fields];
        itemArr[rowIndex] = { name: event.target.value};
        if(itemId !=="" && Number(itemId) !== 0){
            itemArr[rowIndex].id = Number(itemId);
        }
        this.setState({ fields: [...itemArr]});
}
 isfieldItemsBlank=(fields)=>{
  console.log({arraydata:fields});
  let fieldsClone = [...fields];
  for (let x of fieldsClone) {
    if (x.name.trim() ==="") {
       return (true);
    }
 }
 return (false);
}
handleSaveFieldMaintenance = () =>{
    const{name,status,fields,FieldMaintenanceId,emptyItemFlag,count}=this.state;
  const{match}=this.props;
    if (this.state.addItemVal !== "") {
    this.setState({ isShowAlertBox: true })
    return;
    }

if (this.validator.allValid()){
  let nm=name.trim();
  const data={name:nm,status:status,fields};
  if(match.path === "/FieldMaintenance/:FieldMaintenanceId" && FieldMaintenanceId !==null){
   console.log({masterdataitems:data});
   console.log(count);
   if(this.isfieldItemsBlank(fields)){
    createNotification("error","Field items cannot be blank.");
   }else{
     masterDataService.updateFieldMaintenanceItemById(FieldMaintenanceId,data)
     .then((response)=>{
      if(response.status === 200){
       createNotification("success", "Field maintenance item updated successfully!");
       this.handleResetState();
       this.setState({loader:false});
       this.props.history.push("/FieldMaintenance");
      }          
     })
     .catch((error)=>{
      if (error.response.status === 401) {
        createNotification(
          "error",
          "Your login session has been expired!"
        );
        localStorage.setItem("user-token", "");
        this.props.history.push("/login");
        this.setState({ loader: false });
      } else {
        createNotification("error", error.response.data.error.message);
        this.setState({ loader: false });
      }
    });
   }
   
  }else{
  masterDataService.addFieldMaintenanceItem(data)
  .then((response)=>{
    if(response.status === 200){
      createNotification("success","Field maintenance item added successfully");
       this.handleResetState();
       this.setState({loader:false});
       this.props.history.push("/FieldMaintenance");
    }
  })
  .catch((error)=>{
   if (error.response.status === 401) {
     createNotification(
       "error",
       "Your login session has been expired!"
     );
     localStorage.setItem("user-token", "");
     this.props.history.push("/login");
     this.setState({ loader: false });
   } else {
     createNotification("error", error.response.data.error.message);
     this.setState({ loader: false });
   }
  });
}
}else{
  this.validator.showMessages();
  this.forceUpdate();
}
};

handleResetState=()=>{
  this.setState({
    name: "",
    status: "Active",
    FieldMaintenanceId: null,
    headingText: "Add",
    button: "Save",
  });
}


render(){
    const{loader,isShowConfirmBox,fields}=this.state;
    let roleKey=this.props.loginUserData.roleKey;
    const isAccess = (roleKey!=="" && rules[roleKey][Permission.MASTER_WRITE]) ?true:false;
    const isDisable = (roleKey!=="" && rules[roleKey][Permission.PROJECT_WRITE]) ? "":"disabled";
    return(
        <div>
              {!isAccess ?<AccessDenied/>:(
                <div className="main-content addUser-form">
                  <div className="page-content">
                    <div className="container-fluid">
                     <div className="page-title-box d-flex align-items-center justify-content-between">
                        <ol className="breadcrumb m-0">
                          <Link to={`/#`}><li className="breadcrumb-item">Home</li></Link>
                          &nbsp; / &nbsp;
                          <Link to={`/FieldMaintenance`}><li className="breadcrumb-item">Field Maintenance List</li></Link>
                          &nbsp; / &nbsp;
                          {this.props.match.path == `/AddFieldMaintenance` ?
                            <Link to={`/AddFieldMaintenance`}><li className="breadcrumb-item">Add</li></Link>
                          :this.props.match.path == `/FieldMaintenance/:FieldMaintenanceId` ?
                            <Link to={`/FieldMaintenance/${this.props.match.params.FieldMaintenanceId}`}><li className="breadcrumb-item">Edit</li></Link>
                          :''}
                        </ol>
                     </div>
                     <h4 className="mb-0">{this.state.headingText}</h4>
                    <div className="row">
                       <div className="col-lg-12">
                         <div className="card card-block">
                            <div className="card-body">
                              <div className="wizard clearfix">
                               <div className="content-clearfix">
                                 <form className="form-project">
                                 <div className="row">
                                   <div className="col-md-5 col-sm-12">
                                    <div className="mb-3">
                                    <label
                                      className="form-label"
                                      for="validationCustom01"
                                    >
                                      Name{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input type="text" 
                                    className="form-control"
                                    id="validationCustom01"
                                    name="name"
                                    onChange={(e) => this.handleOnChange(e)}
                                    placeholder="Name"
                                    value={this.state.name}
                                   />
                                   {
                                      this.validator.message(
                                        "name",
                                        this.state.name,
                                        "required|max:70",
                                        {className:"text-danger"}
                                      ) 
                                   }
                                    </div>
                                   </div>
                                   <div className="col-md-5 col-sm-12">
                                      <div className="mb-3">
                                        <label className="form-label"
                                        for="validationCustom01">
                                            Status
                                        </label>
                                        <DropDown name="status"
                                          options={[
                                              {lable:"Active",value:"Active"},
                                              {lable:"Inactive",value:"Inactive"},
                                          ]}
                                          onChange={(e)=> this.onStatusChange(e)}
                                          defaultSelected={this.state.status}
                                          lable=""/>
                                      </div>
                                    </div>
                                 </div>
                                  <div className="row">
                                  </div>
                                  {/* <h4 className="card-title">Fields</h4> */}
                                  <label className="form-label" for="">Fields </label>
                                  <div className="row">
                                    <div className="col-md-6">
                                    <div className="mb-3">
                                      <div className="d-flex align-items-center justify-content-end">
                                   <input type="text" className="form-control" placeholder="Add item" name="addItem" id="addItem"  
                                   disabled={isDisable}
                                   onChange={(e) => this.handleOnChange(e)}
                                   value={this.state.addItemVal}
                                   />
                                  <button type="button" className="btn btn-plus btn-primary waves-effect waves-light"
                                  onClick={() => this.addMoreItem()} disabled={isDisable} >
                                 <i className="uil uil-plus"></i></button>
                                 </div>
                                 {this.validator.message('Items', fields, 'required', { className: 'text-danger' })}
                                 {this.state.lengthLimitFlag===true ?<p className="text-danger">Fields cannot be greater then 200 character.</p>:""}
                            </div>
                                    </div>
                                  </div>
                                  {
                                    fields && fields.map((data, index) => {
                                      return (
                                        <div className="row" key={index}>
                                          <div className="col-md-6">
                                            <div className="mb-3 d-flex align-items-center justify-content-end">
                                              <input type="text" className="form-control" placeholder="Add item" name={`item${index}`} value={data.name} id={index} onChange={(e) => this.changeItem(e)}
                                                dataid={data.id}
                                                />
                                             <AllowedTo      
                                              perform={Permission.PROJECT_WRITE}
                                              >
                                              <button type="button" className="btn btn-plus btn-danger waves-effect waves-light"
                                               onClick={() => this.removeItem(index)}>
                                               <i className="uil uil-minus"></i>
                                                </button>
                                                </AllowedTo>
                                                </div>
                                            </div>
                                        </div>
                                       )
                                      })
                                  }
                                 </form>
                               </div>
                               <div className="actions clearfix">
                                  <button type="button"
                                  className="btn btn-primary waves-effect waves-light"
                                  onClick={()=>{this.handleSaveFieldMaintenance()}}>
                                    <i className="uil uil-edit"></i>{" "}
                                    {this.state.button}
                                  </button>
                               </div>
                               <ConfirmBox
                            openModal={isShowConfirmBox}
                            confirmMsg="This entity might being used in one or more projects. Inactivating will remove it from there. Do you still want to continue?"
                            handleConfirmModal={this.handleConfirmModal} />
                              </div>
                            </div>
                         </div>
                       </div>
                     </div>
                    </div>
                  </div>
                </div>
              )}
        </div>
    )
}

}
const mapStateToProps = (props) => {
    return {
        loginUserData: props.loginUserData,
    }
  };
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
  }, dispatch);
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddFieldMaintenance);

