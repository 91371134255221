import { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
class ShopItemPopUp extends Component{
    constructor(props){
        super(props);
        this.state = {
            detailName:this.props.detailName,
            serviceInterval:this.props.serviceInterval,
            nameError:'',
            sIntervalError:'',
            nameErrorStatus:true,
            sIntervalErrorStatus:true,
        }
        this.validator = new SimpleReactValidator();
    }
    
    // Method for Save Item Details-------------------------------
    saveItemDetails = (event) => {
        const {nameErrorStatus,sIntervalErrorStatus} = this.state;
        let name      = event.target.detailName.value;
        let sInterval = event.target.serviceInterval.value;
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;
        
        // if(name !== '' && name.trim().length === 0 || spCharector.test(name) || onlyNumbers.test(name) && !isNaN(name)){
        //     this.setState({nameError:"This is an invalid input",nameErrorStatus:true});
        // }else if(name === ''){
        //     this.setState({nameError:"Item name is required*",nameErrorStatus:true});
        // }else{
        //     this.setState({nameError:"",nameErrorStatus:false});
        // }

        // if(sInterval !== '' && sInterval.trim().length === 0 || spCharector.test(sInterval) || onlyNumbers.test(sInterval) && !isNaN(sInterval)){
        //     this.setState({sIntervalError:"This is an invalid input",sIntervalErrorStatus:true});
        // }else if(sInterval === ''){
        //     this.setState({sIntervalError:"Service interval is required*",sIntervalErrorStatus:true});
        // }else{
        //     this.setState({sIntervalError:"",sIntervalErrorStatus:false});
        // }
        // if(nameErrorStatus === false && sIntervalErrorStatus === false){
        //     this.props.saveShopItem(name.trim(),sInterval.trim());
        // }else if((name !== '' && name.trim().length !== 0 && !spCharector.test(name) && !onlyNumbers.test(name) && isNaN(name)) && (sInterval !== '' && sInterval.trim().length !== 0 && !spCharector.test(sInterval) && !onlyNumbers.test(sInterval) && isNaN(sInterval))){
        //     this.props.saveShopItem(name.trim(),sInterval.trim());
        // }
       if(this.validator.allValid()){
        this.props.saveShopItem(name.trim(),sInterval.trim());
       }else{
        this.validator.showMessages();
        this.forceUpdate();
       }

        event.preventDefault();
    }
    
    // Method for Update Item Details -----------------------------
    updateItemDetails = (event) => {
        const {nameErrorStatus,sIntervalErrorStatus} = this.state;
        let name      = event.target.detailName.value;
        let sInterval = event.target.serviceInterval.value;
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;

        // if(name !== '' && name.trim().length === 0 || spCharector.test(name) || onlyNumbers.test(name) && !isNaN(name)){
        //     this.setState({nameError:"This is an invalid input",nameErrorStatus:true});
        // }else if(name === ''){
        //     this.setState({nameError:"Item name is required*",nameErrorStatus:true});
        // }else{
        //     this.setState({nameError:"",nameErrorStatus:false});
        // }

        // if(sInterval !== '' && sInterval.trim().length === 0 || spCharector.test(sInterval) || onlyNumbers.test(sInterval) && !isNaN(sInterval)){
        //     this.setState({sIntervalError:"This is an invalid input",sIntervalErrorStatus:true});
        // }else if(sInterval === ''){
        //     this.setState({sIntervalError:"Service interval is required*",sIntervalErrorStatus:true});
        // }else{
        //     this.setState({sIntervalError:"",sIntervalErrorStatus:false});
        // }
        // if(nameErrorStatus === false && sIntervalErrorStatus === false){
        //     this.props.updateShopItem(name.trim(),sInterval.trim());
        // }else if((name !== '' && name.trim().length !== 0 && !spCharector.test(name) && !onlyNumbers.test(name) && isNaN(name)) && (sInterval !== '' && sInterval.trim().length !== 0 && !spCharector.test(sInterval) && !onlyNumbers.test(sInterval) && isNaN(sInterval))){
        //     this.props.updateShopItem(name.trim(),sInterval.trim());
        // }
        if(this.validator.allValid()){
            this.props.updateShopItem(name.trim(),sInterval.trim());
           }else{
            this.validator.showMessages();
            this.forceUpdate();
           }
    
        event.preventDefault();
    }

    // Onchange Method for Update Modal fields-------------------

    handleOnchange = (event) => {
        let spCharector = /[^\w\s]/gi;
        let onlyNumbers = /[0-9]/;

        if(event.target.name === "detailName"){
            if(event.target.value !== '' && event.target.value.trim().length === 0 || spCharector.test(event.target.value) || onlyNumbers.test(event.target.value) && !isNaN(event.target.value)){
                this.setState({nameError:"This is an invalid input",nameErrorStatus:true});
            }else if(event.target.value === ''){
                this.setState({nameError:"Item name is required*",nameErrorStatus:true});
            }else{
                this.setState({nameError:"",nameErrorStatus:false});
            }
        }else if(event.target.name === "serviceInterval"){
            if(event.target.value !== '' && event.target.value.trim().length === 0 || spCharector.test(event.target.value) || onlyNumbers.test(event.target.value) && !isNaN(event.target.value)){
                this.setState({sIntervalError:"This is an invalid input",sIntervalErrorStatus:true});
            }else if(event.target.value === ''){
                this.setState({sIntervalError:"Service interval is required*",sIntervalErrorStatus:true});
            }else{
                this.setState({sIntervalError:"",sIntervalErrorStatus:false});
            }
        }

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    //---------------------------------------------------------//
    
    render(){
        const {closeModal,modalStatus} = this.props;
        return (
            <div>
                {modalStatus === "add" ? 
                    // ADD MODAL FOR ITEM DETAILS STARTS HERE:-----
                    (<form onSubmit={this.saveItemDetails}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0 text-dark fw-bold text-center" id="myModalLabel"> Add item </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModal(false)} ></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3 accordWrap">
                                <div className="row" >
                                    <div className="col-md-6">
                                        <label className="col-md-4">Item Name</label>
                                        <input autoComplete="off" type="text" className="form-control col-md-2" placeholder="Please enter the item name." name="detailName" onChange={(e) => {this.handleOnchange(e)}} />
                                        {this.validator.message('Item name', this.state.detailName, 'required|max:100', { className: 'text-danger' })}
                                        {/* <p style={{color:"red"}}>{this.state.nameError}</p> */}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="col-md-4">Service Interval</label>
                                        <input autoComplete="off" type="text" className="form-control col-md-2" placeholder="Please enter service interval." name="serviceInterval" onChange={(e) => {this.handleOnchange(e)}} /> 
                                        {this.validator.message('Service Interval', this.state.serviceInterval, 'required|max:100', { className: 'text-danger' })}
                                        {/* <p style={{color:"red"}}>{this.state.sIntervalError}</p> */}
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button type="button" className="btn btn-danger fw-bold" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModal(false)} >Cancel</button>
                                <button className="btn btn-primary fw-bold"> Save </button>
                            </div>
                        </div>
                    </form>)
                    // ADD MODAL FOR ITEM DETAILS ENDS HERE:-----
                :
                    // UPDATE MODAL FOR ITEM DETAILS STARTS HERE:-------
                    (<form onSubmit={this.updateItemDetails}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0 text-dark fw-bold text-center" id="myModalLabel"> Update item </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModal(false)} ></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3 accordWrap">
                                <div className="row" >
                                    <div className="col-md-6">
                                        <label className="col-md-4">Item Name</label>
                                        <input autoComplete="off" type="text" className="form-control col-md-2" placeholder="Please enter the item name." name="detailName" value={this.state.detailName} onChange={(e) => {this.handleOnchange(e)}} />
                                        {this.validator.message('Item name', this.state.detailName, 'required|max:100', { className: 'text-danger' })}
                                        {/* <p style={{color:"red"}}>{this.state.nameError}</p> */}
                                    </div>
                                    <div className="col-md-6">
                                        <label className="col-md-4">Service Interval</label>
                                        <input autoComplete="off" type="text" className="form-control col-md-2" placeholder="Please enter service interval." name="serviceInterval" value={this.state.serviceInterval} onChange={(e) => {this.handleOnchange(e)}} /> 
                                        {this.validator.message('Service Interval', this.state.serviceInterval, 'required|max:100', { className: 'text-danger' })}
                                        {/* <p style={{color:"red"}}>{this.state.sIntervalError}</p> */}
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button type="button" className="btn btn-danger fw-bold" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeModal(false)} > Cancel</button>
                                <button className="btn btn-primary fw-bold"> Update </button>
                            </div>
                        </div>
                    </form>)
                    // UPDATE MODAL FOR ITEM DETAILS ENDS HERE:-----
                }
            </div>
        )
    }
}

export default ShopItemPopUp;