import React, { Component } from "react";

class FlagActiveTab extends Component{
    constructor(props){
        super(props);
        this.state = {
            flag:this.props.flagData,
            activeLebel:this.props.flagName,
        }
    }

    componentDidMount = () => {
        const {flag,activeLebel} = this.state;
        if(flag === false && activeLebel === 'Field'){
            this.props.flagAction(!flag);
        }else if(flag === true && activeLebel === 'Shop'){
            this.props.flagAction(!flag);
        }
    }

    render(){ return(<></>); }
}

export default FlagActiveTab;