import {NotificationContainer, NotificationManager} from 'react-notifications';

export const createNotification = (type, message, time = 1500) => {
    switch (type) {
      case 'info':
        NotificationManager.info(message, '', time)
        break
      case 'success':
        NotificationManager.success(message, '', time)
        break
      case 'warning':
        NotificationManager.warning(message, '', time)
        break
      case 'error':
        NotificationManager.error(message, '', time)
        break
      default:
        NotificationManager.error('Some error occured, please check your intenet connection or try again later!', '', time)
        break
    }
  }